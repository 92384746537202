import PropTypes from "prop-types";
import React from "react";

const PsqProviderSummaryItem = ({ id, values }) => {

    if (values === undefined || values === null || values.length <= 1) {
        return null;
    }

    let newValues = [];
    for (let i = 1; i < values.length; i++) {
        newValues.push(values[i]);
    }

    return (
        <div>
            {newValues.map(value => <div key={id + value}>{value}<br /></div>)}
        </div>
    );
};

PsqProviderSummaryItem.propTypes = {
    id: PropTypes.number,
    values: PropTypes.array
};

const PsqProviderSummary = ({ checklist }) => {

    let hasAnswers = false;
    let items = [];

    if (checklist !== undefined && checklist !== null && checklist.content !== undefined) {
        let section = checklist
            .content.find(x => x.type === "PsqSection2");

        if (section && Array.isArray(section.items)) {
            for (let i = 1; i < section.items.length; i++) {
                items.push(section.items[i]);
            }
        }

        for (let i = 0; i < items.length; i++) {
            if (items[i].selectedAnswers.length > 0 && (items[i].selectedAnswers[0] !== "" || items[i].selectedAnswers.length > 1)) {
                hasAnswers = true;
            }
        }
    }

    if (!hasAnswers) {
        return(<div>No provider information has been defined.</div>);
    }

    return (
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Provider Type</th>
                </tr>
            </thead>
            <tbody>
                {items.map(item => item.selectedAnswers.length > 0 && item.selectedAnswers[0] !== "" && <tr key={item.id}><td>{item.selectedAnswers[0]}</td><td><PsqProviderSummaryItem id={item.id} values={item.selectedAnswers} /></td></tr>)}
            </tbody>
        </table>
    );
};

PsqProviderSummary.propTypes = {
    checklist: PropTypes.object
};

export default PsqProviderSummary;