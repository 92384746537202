import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_TEST_SNAPSHOTS_SUCCESS action
 * @param {Array} [state] The test snapshots currently in the store, or the initial state of test snapshots
 * @param {Object} action The action to be handled
 * @returns {Array} The test snapshots
 */
export default function testSnapshots(state = initialState.testSnapshots, action) {
    switch (action.type) {
        case actions.LOAD_TEST_SNAPSHOTS_SUCCESS:
            return action.testSnapshots;
        case actions.LOAD_TEST_SNAPSHOTS_FAILURE:
        case actions.UNLOAD_TESTS:
            return initialState.testSnapshots;
        default:
            return state;
    }
}