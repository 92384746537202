import PropTypes from "prop-types";
import React from "react";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter} from '@ey-xd/motif-react';

const ChecklistCompleteModal = ({ handleModalAction, showModal }) => {
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close upload checklist delivery schedules modal',
        ariaLabel: 'Close upload checklist delivery schedules modal',
        dataTestid: 'close',
        value:'CANCEL'
    };

    return (
        
        <MotifModal variant="alt" show={showModal} onClose={handleModalAction}
          data-testid="uploadScheduleDelivery">
        <MotifModalHeader closeButtonProps={closeButtonProps} >
            <HeaderWrapper>
                <span className="ml-2">Approve checklist</span>
            </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>  
       <span className="checklistActionMsg">Are you sure your review is complete? You will not be able to update the checklist once review is completed. Click "Yes" to finalize the review, or "No" to return to editing and reviewing.</span> 
        </MotifModalBody>
        <MotifModalFooter>
            <MotifButton variant="secondary"  type="button"
                    onClick={handleModalAction} value="CANCEL">
                    No
             </MotifButton>
             <MotifButton variant="primary"  type="button"
                    onClick={handleModalAction}
                    value="COMPLETED">
                    Yes
            </MotifButton>
        </MotifModalFooter>
    </MotifModal>
        
    );
};

ChecklistCompleteModal.propTypes = {
    showModal: PropTypes.bool,
    handleModalAction: PropTypes.func.isRequired
};

export default ChecklistCompleteModal;