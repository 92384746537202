import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm, getFormValues,formValueSelector,change } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import { MotifButton, MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader, MotifIcon, MotifErrorMessage } from '@ey-xd/motif-react';
import { IconoirXmark } from '@ey-xd/motif-icon';
import { bindActionCreators } from 'redux';
const CreateClientAccountModal = ({
    showCreateClientAccountModal,
    lookupTypes,
    invalid,
    submitting,
    isDuplicate,
    handleSubmit,
    handleAddClientAccount,
    toggleShowCreateClientAccountModal,
    clearDuplicateChartOfAccountErrorMessage,
    defaultREITTestingAttributeID,
    clientAccountCategoryID,
    change
}) => {
    const clientAccountCategoriesOptions = lookupTypes.clientAccountCategories.filter(cac => cac.clientAccountCategoryID !== 0).map((c) => {
        return {
            key: c.clientAccountCategoryID,
            value: c.clientAccountCategoryID,
            text: c.clientAccountCategoryDescription,
        };
    });

    const reitTestingAttributesOptions = lookupTypes.reitTestingAttributes.filter(rta => rta.reitTestingAttributeID !== 0).map((r) => {
        return {
            key: r.reitTestingAttributeID,
            value: r.reitTestingAttributeID,
            text: r.reitTestingAttributeDescription,
        };
    });

    function clearDropDownValueFromForm(dropdownName)
    { 
        change(dropdownName, '');

    }
    return (
        <MotifModal
            show={showCreateClientAccountModal}
            onClose={toggleShowCreateClientAccountModal}
            variant="alt"
            data-testid="CreateClientAccount"
            size="xl"
        >
            <MotifModalHeader>
                Add Client Account
            </MotifModalHeader>
            <MotifModalBody >
                <form onSubmit={handleSubmit(handleAddClientAccount)} id="createClientAccountForm">
                    <Field
                        name="chartID"
                        type="hidden"
                        component={formHelpers.renderField} />
                   <div className="row">
                   <div className="field col-md-12">
                    <Field
                        name="clientAccountCode"
                        type="text"
                        component={formHelpers.renderField}
                        label="Client Account Number"
                        maxLength={50}
                        onChange={clearDuplicateChartOfAccountErrorMessage}
                        isRequiredField={true}
                        validate={[formValidators.required, formValidators.maxLength50]} />
                    {isDuplicate
                        &&
                        <MotifErrorMessage>This client account number already exists.</MotifErrorMessage>
                    }
                    </div>
                    </div>
                    <div className="row">
                    <div className="field col-md-12">
                    <Field
                        name="clientAccountDescription"
                        className="field"
                        type="text"
                        component={formHelpers.renderField}
                        label="Client Account Name"
                        isRequiredField={true}
                        maxLength={255}
                        validate={[formValidators.required, formValidators.maxLength255]} />
                     </div>
                     </div>
                     <div className="row">
                     <div className="field col-md-12">
                    <Field
                        name="clientAccountCategoryID"
                        label="Category"
                        placeholder="Select..."
                        aria-label="Category select options"
                        options={clientAccountCategoriesOptions}
                        filter
                        icons={{ clear: <MotifIcon iconFunction={IconoirXmark} size='24' fill='none'   onClick={(e) => clearDropDownValueFromForm('clientAccountCategoryID')}  /> }}
                        component={formHelpers.SingleSelectComponent}>
                    </Field>
                    </div>
                    </div>
                    <div className="row">
                    <div className="field col-md-12 mt-5 mb-5">
                    <Field
                        name="defaultREITTestingAttributeID"
                        label="Default REIT Testing Attribute (Required)"
                        placeholder="Select..."
                        options={reitTestingAttributesOptions}
                        filter
                        component={formHelpers.SingleSelectComponent}
                        validate={[formValidators.requiredSelect]}
                        icons={{ clear: <MotifIcon iconFunction={IconoirXmark} size='24' fill='none'   onClick={(e) => clearDropDownValueFromForm('defaultREITTestingAttributeID')}  /> }}
                        >

                    </Field>
                    </div>
                    </div>
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton
                    type="button"
                    onClick={toggleShowCreateClientAccountModal}
                    variant="secondary"
                    form="createClientAccountForm"
                >
                    Cancel
                </MotifButton>
                <MotifButton type="submit"
                    disabled={invalid || submitting}
                    form="createClientAccountForm"
                >
                    Save
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

CreateClientAccountModal.propTypes = {
    showCreateClientAccountModal: PropTypes.bool,
    handleAddClientAccount: PropTypes.func,
    toggleShowCreateClientAccountModal: PropTypes.func,
    initialValues: PropTypes.object,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
};

function mapStateToProps(state) {
    const selector = formValueSelector('createClientAccountModal');
    const createClientAccountFormValues = getFormValues('createClientAccountModal')(state);

    return {
        createClientAccountFormValues,
        defaultREITTestingAttributeID: selector(state, 'defaultREITTestingAttributeID'),
        clientAccountCategoryID:selector(state, 'clientAccountCategoryID'),
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        change: bindActionCreators(change, dispatch),
    };

};

export default connect(
    (state, props) => (mapStateToProps)
)(reduxForm({ form: "createClientAccountModal", enableReinitialize: true })(CreateClientAccountModal));