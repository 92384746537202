import React from 'react';
import PropTypes from 'prop-types';
import AssetDetailTestTable from './AssetDetailTestTable';

const AssetDetails = ({
  assetDetails,
  title,
  properties,
  tbMode,
  clientId,
  reitId,
  periodId,
}) => {
  return (
    <div>
      <div className="rtable">
        <h6 className="pageheader">{title || 'Asset Test Details'}</h6>
      </div>
      <section className="rtable">
        {// ensure there are categories and items within categories
          !assetDetails.every(d =>
            d.categories.length === 0 || d.categories.every(c => c.items.length === 0)) &&
          assetDetails.map(assetDetail => (
            <AssetDetailTestTable
              key={assetDetail.test}
              assetDetail={assetDetail}
              isFourColumn={assetDetail.isFourColumn}
              properties={properties}
              tbMode={tbMode}
              clientId={clientId}
              reitId={reitId}
              periodId={periodId}
            />
          ))}
      </section>
    </div>
  );
};

AssetDetails.propTypes = {
  assetDetails: PropTypes.array.isRequired,
  title: PropTypes.string,
  properties: PropTypes.array,
  tbMode: PropTypes.number,
  clientId: PropTypes.number,
  reitId: PropTypes.number,
  periodId: PropTypes.number,
};

export default AssetDetails;
