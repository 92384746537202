import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MoneyField } from "../shared/formFields";
import { MotifButton, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifIcon } from '@ey-xd/motif-react';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';

const EditTrsForm = ({ handleSubmit, handleCloseForm, initialValues, formPurpose, error, submitting, handleDismissErrorMessage }) => {

    const modalPurpose = formPurpose === "" ? 'Add New' : (formPurpose === "EDIT" ? 'Edit' : 'Delete');
    
    const closeButtonProps = {
        title: 'Close ' + modalPurpose + ' TRS Modal',
        ariaLabel: 'Close ' + modalPurpose + ' TRS Modal',
        dataTestid: 'close',
    };

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
    );

    return (
        <React.Fragment>
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    {formPurpose === "DELETE" && <MotifIcon style={{ color: '#F95D54' }} iconFunction={IconoirWarningCircle} size='24' fill='none'  className="mr-2" />}
                    <span>{(formPurpose === "" && "Add New TRS") || formPurpose === "EDIT" && "Edit TRS" || "Delete TRS?"}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <form onSubmit={handleSubmit} className="trs" id="addEditDeleteTrsForm">
                    <Field name="taxableREITSubsidiaryID" type="hidden" component="input" label="" disabled={(formPurpose === "DELETE") ? "disabled" : ""} />
                    <Field name="reportPeriodID" type="hidden" component="input" label="" disabled={(formPurpose === "DELETE") ? "disabled" : ""} />
                    {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
                    <div className="row field">
                        <div className="field col-md-12">
                            <Field
                                name="trsName"
                                component={formHelpers.renderField}
                                type="text"
                                label="Name"
                                maxLength={50}
                                isRequiredField={true}
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                validate={[formValidators.required, formValidators.noWindowSpecialChars, formValidators.maxLength50]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="field col-md-4">
                            <MoneyField
                                name="trsValue"
                                label="Value"
                                placeholder="0.00"
                                isRequiredField={true}
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                validate={(formPurpose !== "DELETE") ? [formValidators.required, formValidators.number, formValidators.maxValueDecimal] : []}
                            />
                        </div>
                        <div className="field col-md-4">
                            <MoneyField
                                name="trsDebt"
                                label="Debt"
                                placeholder="0.00"
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                validate={(formPurpose !== "DELETE") ? [formValidators.number, formValidators.maxValueDecimal] : []}
                            />
                        </div>
                        <div className="field  col-md-4">
                            <MoneyField
                                name="trsEquity"
                                label="Equity"
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                validate={(formPurpose !== "DELETE") ? [formValidators.number, formValidators.maxValueDecimal] : []}
                            />
                        </div>
                    </div>
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" type="button" form="addEditDeleteTrsForm"
                    onClick={handleCloseForm}>Cancel</MotifButton>

                <MotifButton variant={formPurpose === "DELETE" ? "warn" : "primary"} type="submit" form="addEditDeleteTrsForm"
                    value="Submit" disabled={submitting}>
                    {(formPurpose === "" &&
                        <React.Fragment>Submit</React.Fragment>) ||
                        formPurpose === "EDIT" && <React.Fragment>Save</React.Fragment> ||
                        <React.Fragment>Delete</React.Fragment>}
                </MotifButton>
            </MotifModalFooter>
        </React.Fragment>
    );
};

EditTrsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    trs: PropTypes.arrayOf(PropTypes.shape({
        taxableREITSubsidiaryID: PropTypes.number,
        reportPeriodID: PropTypes.number,
        trsName: PropTypes.string,
        trsValue: PropTypes.number,
        trsDebt: PropTypes.number,
        trsEquity: PropTypes.number
    })),
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    formPurpose: PropTypes.string,
    handleDismissErrorMessage: PropTypes.func
};

export default reduxForm({ form: 'trs' })(EditTrsForm);