import { pendingTask, begin, end } from '../constants/pendingTask';

import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch all the Bulk Process Management data for a given client, calendar year & quarter description. If successful this will dispatch the LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS
 * action, otherwise it will dispatch the LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} calendarYear The selected calendar year.
 * @param {string} quarterDescription The selected quarter.
 * @returns {function} A function that returns a Promise.
 */
export const fetchBulkProcessManagementData = (clientId, calendarYear, quarterDescription) => {
  return (dispatch) => {
    if (!clientId || !calendarYear || !quarterDescription) {
      return dispatch({
        type: actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE,
        bulkProcessManagementData: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/bulkProcessManagement?clientId=${clientId}&calendarYear=${calendarYear}&quarterDescription=${quarterDescription}`)
      .then((response) => {
        return response.json();
      })
      .then((bulkProcessManagementData) => {
        if (actionHelpers.isErrorResponse(bulkProcessManagementData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE,
            null
          );
        }
        dispatch({
          type: actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS,
          bulkProcessManagementData,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE,
          null,
          error
        );
      });
  };
};

/**
 * Initiates the Bulk Process Operation for a given selections. If successful this will dispatch the INITIATE_BULK_PROCESS_SUCCESS
 * action, otherwise it will dispatch the INITIATE_BULK_PROCESS_FAILURE action.
 * @param {number} request The request body.
 * @returns {function} A function that returns a Promise.
 */
export const initiateBulkProcess = (request) => {
  let isErrored = false;
  return (dispatch) => {
    if (!request) {
      isErrored = true;
      dispatch({
        type: actions.INITIATE_BULK_PROCESS_FAILURE,
      });

      return isErrored;
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/bulkProcessManagement/initiateBulkProcess`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((responseResult) => {
        if (actionHelpers.isErrorResponse(responseResult)) {
          isErrored = true;
          actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.INITIATE_BULK_PROCESS_FAILURE,
            null
          );

          return isErrored;
        }
        dispatch({
          type: actions.INITIATE_BULK_PROCESS_SUCCESS,
          [pendingTask]: end,
        });

        return isErrored;
      })
      .catch((error) => {
        isErrored = true;
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.INITIATE_BULK_PROCESS_FAILURE,
          null,
          error
        );

        return isErrored;
      });
  };
};

export function dispatchBulkProcessData(message, action) {
  return function (dispatch) {
    dispatch({ type: action, message });
  };
}