import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

/**
 * This reducer handles the LOAD_DATA_PURGE_DIAGDATA_SUCCESS action and sets data deletion diagnostic data in the store
 * @param {Object} [state] The data deletion diagnostic data currently in the store, or the initial state of data deletion diagnostic data
 * @param {Object} action The action to be handled
 * @returns {Object} The data deletion diagnostic data
 */
export default function dataDeletionDiaognisticDataReducer(state = initialState.dataDeletionDiagnosticData, action) {
    if (action.type == actions.LOAD_DATA_PURGE_DIAGDATA_SUCCESS && Array.isArray(action.dataDeletionDiagnosticData)) {
        return action.dataDeletionDiagnosticData;
    } else if (action.type == actions.LOAD_DATA_PURGE_DIAGDATA_FAILURE) {
        return initialState.dataDeletionDiagnosticData;
    } else {
        return state;
    }
}