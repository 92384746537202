import React from 'react';
import { MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifIcon, MotifButton } from '@ey-xd/motif-react';
import { IconoirWarningTriangle } from '@ey-xd/motif-icon';
import PropTypes from "prop-types";

const DataDeletionWarningModal = ({
    showDataDeletionWarningModal,
    toggleShowDataDeletionWarningModal,
    initiateDataDeletionProcess
}) => {
    return (
        <MotifModal variant="alt" show={showDataDeletionWarningModal} onClose={toggleShowDataDeletionWarningModal} data-testid="DataDeletionWarningModal">
            <MotifModalHeader headerIcon={<MotifIcon class="motif-fab motif-fab-size-small motif-fab-info modal-info" style={{ color: "#FF9831", backgroundColor: "black" }} iconFunction={IconoirWarningTriangle} size="24" strokeWidth="1.5" stroke="#FF9831" fill="transparent" />}>
                <span className="ml-2">Deletion Impact Warning</span>
            </MotifModalHeader>
            <MotifModalBody>
                <span className="checklistActionMsg">Caution: Initiating a deletion for clients with large data sets could temporarily impact system responsiveness for other users. Do you wish to proceed with the deletion now?</span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" onClick={toggleShowDataDeletionWarningModal}>
                    Cancel
                </MotifButton>
                <MotifButton variant="primary" onClick={initiateDataDeletionProcess}>
                    Confirm
                </MotifButton>

            </MotifModalFooter>
        </MotifModal>
    );
};

DataDeletionWarningModal.propTypes = {
    showDataDeletionWarningModal: PropTypes.bool,
    toggleShowDataDeletionWarningModal: PropTypes.func,
    initiateDataDeletionProcess: PropTypes.func
};


export default DataDeletionWarningModal;