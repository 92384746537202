import PropTypes from "prop-types";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { render } from "react-dom";
import { Form, Icon, Popup, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import {
  MotifPagination,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
  MotifTable,
} from "@ey-xd/motif-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ChecklistLinkCellRenderer from "../checklistDeliveryCalendar/customRenderers/ChecklistLinkCellRenderer";
import DateCellRenderer from "../checklistDeliveryCalendar/customRenderers/DateCellRenderer";
import SimpleCellRenderer from "../checklistDeliveryCalendar/customRenderers/SimpleCellRenderer.js";
import CheckboxCellRenderer from "../checklistDeliveryCalendar/customRenderers/CheckboxCellRenderer.js";
import CheckboxHeaderRenderer from "../checklistDeliveryCalendar/customRenderers/CheckboxHeaderRenderer.js";
import DefaultValueGetter from "../checklistDeliveryCalendar/customValueGetters/DefaultValueGetter.js";
import ChecklistTypeValueGetter from "../checklistDeliveryCalendar/customValueGetters/ChecklistTypeValueGetter.js";
import QuestionnaireTemplateValueGetter from "../checklistDeliveryCalendar/customValueGetters/QuestionnaireTemplateValueGetter.js";
import PropertyTypeValueGetter from "../checklistDeliveryCalendar/customValueGetters/PropertyTypeValueGetter.js";
import StatusDateValueGetter from "../checklistDeliveryCalendar/customValueGetters/StatusDateValueGetter.js";
import DateComparator from "../checklistDeliveryCalendar/customComparators/DateComparator.js";
import CustomHeaderTemplate from "../checklistDeliveryCalendar/customHeaders/CustomHeaderTemplate.js";
import NoRecipientCellRenderer from "../checklistDeliveryCalendar/customRenderers/NoRecipientCellRenderer.js";

const ARQCChecklistTable = ({
  formSyncErrors,
  formSubmitErrors,
  filteredManageChecklists,
  clientId,
  gridColumnState,
  handleGridColumnStateChange,
  handleGridChanges,
  handleGridFilteredRowChanges,
  handleRecipientChanges,
  refreshGridChecklistData,
  refreshGridData,
}) => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [rowData, setRowData] = useState([]);
  const rowDataRef = useRef({});
  rowDataRef.current = rowData;
  const [gridFilteredRowData, setGridFilteredRowData] = useState([]);

  const onCellCheckboxChange = (checklistId, rowNode) => {
    let updatedData = rowDataRef.current.map((newUd) => {
      if (
        newUd.checklistID === checklistId &&
        newUd.recipients === rowNode.key
      ) {
        return {
          ...newUd,
          checklistSelection: !newUd.checklistSelection,
        };
      }
      return newUd;
    });

    setRowData(updatedData);
    gridRef.current.api.redrawRows(rowNode);
    gridRef.current.api.refreshHeader();
  };

  const onHeaderCheckboxChange = (isHeaderCheckboxSelected) => {
    let updatedData = rowDataRef.current;
    if (gridRef && gridRef.current && gridRef.current.api) {
      // Select checkbox for filtered rows only
      gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
        // Check if the parent rowNode's key matches with grid's rowdata and update checkbox selection for those matching
        if (rowNode.level === 0) {
          updatedData = updatedData.map((newUd) => {
            if (newUd.recipients === rowNode.key) {
              return {
                ...newUd,
                checklistSelection: !isHeaderCheckboxSelected,
              };
            }
            return newUd;
          });
        }
      });

      setRowData(updatedData);
      gridRef.current.api.redrawRows();
      gridRef.current.api.refreshHeader();
    }
  };

  //Default column settings
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 65,
      filter: true,
      resizable: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const getColumnDefs = () => {
    return [
      {
        field: "checklistSelection",
        headerName: "",
        suppressMenu: true,
        headerComponent: CheckboxHeaderRenderer,
        headerComponentParams: {
          onHeaderCheckboxChange: onHeaderCheckboxChange,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { onCellCheckboxChange: onCellCheckboxChange },
        minWidth: 70,
      },
      {
        field: "checklistLink",
        headerName: "",
        suppressMenu: true,
        minWidth: 70,
        cellRenderer: ChecklistLinkCellRenderer,
        cellRendererParams: { clientId: clientId },
        headerComponentParams: {
          template: CustomHeaderTemplate("checklistLink"),
        },
      },
      {
        field: "reitName",
        headerName: "REIT",
        sortable: true,
        showRowGroup: false,
        hide: false,
        rowGroup: false,
        minWidth: 110,
        valueGetter: (params) => DefaultValueGetter(params, "reitName"),
        filterValueGetter: (params) => {
          return params.data && params.data.reitName;
        },
        headerComponentParams: { template: CustomHeaderTemplate("reit") },
      },
      {
        field: "propertyTypeDescription",
        headerName: "Type",
        sortable: true,
        minWidth: 165,
        valueGetter: (params) => PropertyTypeValueGetter(params),
        filterValueGetter: (params) => {
          return params.data &&
            params.data.checklistType.toLowerCase() === "psq"
            ? params.data.propertyTypeDescription
            : params.data.reitTypeDescription;
        },
        headerComponentParams: { template: CustomHeaderTemplate("type") },
      },
      {
        field: "questionnaireTemplateName",
        headerName: "Template",
        sortable: true,
        minWidth: 120,
        valueGetter: (params) =>
          DefaultValueGetter(params, "questionnaireTemplateName"),
        filterValueGetter: (params) => {
          return params.data && params.data.questionnaireTemplateName;
        },
        headerComponentParams: { template: CustomHeaderTemplate("template") },
      },
      {
        field: "checklistStateDescription",
        headerName: "Status",
        sortable: true,
        showRowGroup: false,
        hide: false,
        rowGroup: false,
        minWidth: 125,
        valueGetter: (params) =>
          DefaultValueGetter(params, "checklistStateDescription"),
        filterValueGetter: (params) => {
          return params.data && params.data.checklistStateDescription;
        },
        headerComponentParams: { template: CustomHeaderTemplate("status") },
      },
      {
        field: "lastUpdatedDate",
        headerName: "Status Date",
        sortable: true,
        minWidth: 125,
        valueGetter: (params) => StatusDateValueGetter(params),
        // Show N/A value in filter if date value is not exists
        filterValueGetter: (params) => {
          return params.data
            ? params.data.lastUpdatedDate
              ? new Date(params.data.lastUpdatedDate + "Z").toLocaleString(
                  "en-US",
                  dateOptions
                )
              : "N/A"
            : null;
        },
        comparator: DateComparator,
        headerComponentParams: { template: CustomHeaderTemplate("statusDate") },
      },
      {
        field: "reopenRequestStatusDescription",
        headerName: "Reopen Request",
        sortable: true,
        showRowGroup: false,
        hide: false,
        rowGroup: false,
        minWidth: 175,
        valueGetter: (params) => {
          return !params.data
            ? params.node &&
              params.node.childrenAfterGroup &&
              params.node.childrenAfterGroup.length > 0 &&
              params.node.childrenAfterGroup[0].data &&
              params.node.childrenAfterGroup[0].data
                .reopenRequestStatusDescription
              ? params.node.childrenAfterGroup[0].data
                  .reopenRequestStatusDescription
              : "N/A"
            : "";
        },
        filterValueGetter: (params) => {
          return params.data && params.data.reopenRequestStatusDescription
            ? params.data.reopenRequestStatusDescription
            : "N/A";
        },
        headerComponentParams: {
          template: CustomHeaderTemplate("reopenRequestStatus"),
        },
      },
      {
        field: "requestDateTime",
        headerName: "Reopen Request Date",
        sortable: true,
        minWidth: 175,
        valueGetter: (params) => {
          return !params.data
            ? params.node &&
              params.node.childrenAfterGroup &&
              params.node.childrenAfterGroup.length > 0 &&
              params.node.childrenAfterGroup[0].data &&
              params.node.childrenAfterGroup[0].data.requestDateTime
              ? new Date(
                  params.node.childrenAfterGroup[0].data.requestDateTime + "Z"
                ).toLocaleString("en-US", dateOptions)
              : "N/A"
            : "";
        },
        // Show N/A value in filter if date value is not exists
        filterValueGetter: (params) => {
          return params.data
            ? params.data.requestDateTime
              ? new Date(params.data.requestDateTime + "Z").toLocaleString(
                  "en-US",
                  dateOptions
                )
              : "N/A"
            : null;
        },
        comparator: DateComparator,
        headerComponentParams: {
          template: CustomHeaderTemplate("requestDateTime"),
        },
      },
      {
        field: "recipients",
        headerName: "Recipients",
        sortable: true,
        rowGroup: true,
        hide: true,
        minWidth: 115,
        headerComponentParams: { template: CustomHeaderTemplate("recipients") },
      },
      // add in a cell renderer params
      {
        headerName: "Recipients",
        field: "recipientEmail",
        minWidth: 400,
        showRowGroup: true,
        sortable: true,
        filterValueGetter: (params) => {
          if (
            params &&
            params.data &&
            params.data.recipientEmail &&
            params.data.recipientEmail.length > 0
          ) {
            return params.data.recipientEmail;
          } else {
            return "No Recipients";
          }
        },
        cellRendererSelector: (params) => {
          if (
            (params &&
              params.node &&
              params.node.allLeafChildren &&
              Array.isArray(params.node.allLeafChildren) &&
              params.node.allLeafChildren.length > 0 &&
              params.node.allLeafChildren[0].data &&
              params.node.allLeafChildren[0].data.recipientEmail &&
              params.node.allLeafChildren[0].data.recipientEmail.length > 0) ||
            (params &&
              params.data &&
              params.data.recipientEmail &&
              params.data.recipientEmail.length > 0)
          ) {
            return {
              component: "agGroupCellRenderer",
            };
          } else {
            return {
              component: NoRecipientCellRenderer,
            };
          }
        },
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellRendererParams: {
          checkbox: true,
          innerRenderer: SimpleCellRenderer,
          innerRendererParams: { label: "Recipients" },
          suppressDoubleClickExpand: true,
          suppressEnterExpand: true,
        },
        headerComponentParams: { template: CustomHeaderTemplate("recipients") },
      },
      {
        field: "senderEmail",
        headerName: "Sent From",
        sortable: true,
        rowGroup: false,
        minWidth: 200,
        headerComponentParams: { template: CustomHeaderTemplate("sentFrom") },
      },
      {
        field: "sentDate",
        headerName: "Sent Date",
        sortable: true,
        rowGroup: false,
        minWidth: 200,
        cellRenderer: DateCellRenderer,
        cellRendererParams: { dateFieldName: "sentDate" },
        // Show N/A value in filter if date value is not exists
        filterValueGetter: (params) => {
          return params.data
            ? params.data.sentDate
              ? new Date(params.data.sentDate + "Z").toLocaleString(
                  "en-US",
                  dateOptions
                )
              : "N/A"
            : null;
        },
        comparator: DateComparator,
        headerComponentParams: { template: CustomHeaderTemplate("sentDate") },
      },
      {
        field: "expirationDate",
        headerName: "Expiration Date",
        sortable: true,
        rowGroup: false,
        minWidth: 190,
        cellRenderer: DateCellRenderer,
        cellRendererParams: { dateFieldName: "expirationDate" },
        // Show N/A value in filter if date value is not exists
        filterValueGetter: (params) => {
          return params.data
            ? params.data.expirationDate
              ? new Date(params.data.expirationDate + "Z").toLocaleString(
                  "en-US",
                  dateOptions
                )
              : "N/A"
            : null;
        },
        comparator: DateComparator,
        headerComponentParams: {
          template: CustomHeaderTemplate("expirationDate"),
        },
      },
      {
        field: "clientUserEmailAddress",
        headerName: "Client Signedoff By",
        sortable: true,
        showRowGroup: false,
        hide: false,
        rowGroup: false,
        minWidth: 172,
        valueGetter: (params) => {
          return !params.data
            ? params.node &&
              params.node.childrenAfterGroup &&
              params.node.childrenAfterGroup.length > 0 &&
              params.node.childrenAfterGroup[0].data &&
              params.node.childrenAfterGroup[0].data.isSignedOff
              ? params.node.childrenAfterGroup[0].data.clientUserEmailAddress
              : ""
            : "";
        },
        filterValueGetter: (params) => {
          return params.data && params.data.isSignedOff
            ? params.data.clientUserEmailAddress
            : "";
        },
        headerComponentParams: {
          template: CustomHeaderTemplate("clientUserEmailAddress"),
        },
      },
      {
        field: "userActionDateTime",
        headerName: "Client Signedoff Date",
        sortable: true,
        showRowGroup: false,
        hide: false,
        rowGroup: false,
        minWidth: 200,
        valueGetter: (params) => {
          return !params.data
            ? params.node &&
              params.node.childrenAfterGroup &&
              params.node.childrenAfterGroup.length > 0 &&
              params.node.childrenAfterGroup[0].data &&
              params.node.childrenAfterGroup[0].data.isSignedOff &&
              params.node.childrenAfterGroup[0].data.userActionDateTime
              ? new Date(
                  params.node.childrenAfterGroup[0].data.userActionDateTime +
                    "Z"
                ).toLocaleString("en-US", dateOptions)
              : ""
            : "";
        },
        filterValueGetter: (params) => {
          return params.data && params.data.isSignedOff
            ? params.data.userActionDateTime
              ? new Date(params.data.userActionDateTime + "Z").toLocaleString(
                  "en-US",
                  dateOptions
                )
              : ""
            : null;
        },
        comparator: DateComparator,
        headerComponentParams: {
          template: CustomHeaderTemplate("userActionDateTime"),
        },
      },
    ];
  };

  const [columnDefs, setColumnDefs] = useState(getColumnDefs());
  const getRowId = useCallback(
    (params) => params.data.recipients + "_" + params.data.recipientEmail,
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  //Grid Global Listner
  const gridGlobalListner = function (type, event) {
    if (type.indexOf("columnVisible") >= 0) {
      handleGridColumnsChanged(event);
    }

    // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
    if (type.indexOf("filterChanged") >= 0) {
      setTimeout(function () { handleFilterChanged(event); }, 0);            
    }

    if (type.indexOf("rowDataUpdated") >= 0) {
      setTimeout(function () { handleFilterChanged(event); }, 0);            
    }   
  };

  //Handle Grid Ready event
  const handleGridReady = (event) => {
    //Remove event for column state change
    event.api.removeEventListener(gridGlobalListner);

    //Add event for column state change
    event.api.addGlobalListener(gridGlobalListner);

    //Apply column state
    var columnState = localStorage.getItem("REITSuiteARQCChecklistColumnState");
    if (columnState) {
      gridRef.current.columnApi.applyColumnState({
        state: JSON.parse(columnState),
        applyOrder: true,
      });
    }

    //Show/Hide Reopen requests columns - If any checklists having reopen requests(pending, approved or denied) then only show Reopen Request columns
    gridRef.current.columnApi.setColumnsVisible(
      ["reopenRequestStatusDescription", "requestDateTime"],
      rowDataRef.current &&
        rowDataRef.current.some((ch) => ch.reopenRequestStatusDescription)
    );

    handleItemsPerPageChange(100); // Setting it 100 to make sure the default number of items on load is always set to 100
  };

  // Handle OnFilterChanged event
  const handleFilterChanged = (event) => {
    let filteredRowData = [];
    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
        if (rowNode.level === 0) {
          let matchedRecord = rowDataRef.current.find(
            (ch) => ch.recipients === rowNode.key
          );
          if (matchedRecord) {
            filteredRowData.push({
              checklistID: matchedRecord.checklistID,
              checklistSelection: matchedRecord.checklistSelection,
              checklistState: matchedRecord.checklistState,
              checklistType: matchedRecord.checklistType,
              quarterStatus: matchedRecord.quarterStatus,
              checklistStateDescription:
                matchedRecord.checklistStateDescription,
              reopenRequestStatusDescription:
                matchedRecord.reopenRequestStatusDescription,
              requestDateTime: matchedRecord.requestDateTime,
            });
          }
        }
      });

      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
      if (currentPage > calcTotalPages) {
        if (calcTotalPages == 0) {
          setTimeout(function () {
            setCurrentPage(calcTotalPages);
          }, 0);
        } else {
          setCurrentPage(calcTotalPages);
        }
      }
      const gridCurrentPage =
        gridRef.current.api.paginationGetCurrentPage() + 1; // Adding 1 since it returns zero based index
      if (currentPage < gridCurrentPage && calcTotalPages != 0) {
        setCurrentPage(gridCurrentPage);
      }

      // Show the expected value as 1 for current page 
      if (currentPage <= 1 &&  gridCurrentPage == 1 && calcTotalPages != 0) {
        setCurrentPage(gridCurrentPage);
      }    
    }

    setGridFilteredRowData(filteredRowData);
  };

  //Handle First Data Renderered event
  const handleFirstDataRendered = (event) => {
    gridRef.current.columnApi.autoSizeAllColumns();
    updateRowNodeSelectableProperty();
  };

  //  Update Selectable property of RowNode of type 'No Recipient' to false
  const updateRowNodeSelectableProperty = () => {
    const gridApi = gridRef && gridRef.current && gridRef.current.api;
    if (gridApi) {
      gridApi.forEachNodeAfterFilter((row) => {
        if (
          row.level === 0 &&
          row.childrenAfterFilter &&
          row.childrenAfterFilter.length > 0 &&
          row.childrenAfterFilter[0].data
        ) {
          if (
            !row.childrenAfterFilter[0].data.recipientEmail ||
            row.childrenAfterFilter[0].data.recipientEmail.length == 0
          ) {
            row.selectable = false;
            row.selected = false;
          } else if (row.selectable == false) {
            row.selectable = true;
          }
        }
      });
    }
  };

  //Handle Grid Columns changed event
  const handleGridColumnsChanged = (event) => {
    var currentColumnState = gridRef.current.columnApi.getColumnState();
    var localStorageColumnState = localStorage.getItem(
      "REITSuiteARQCChecklistColumnState"
    );

    // If the column state details are not exists in local storage then save the changed Column state data
    if (
      currentColumnState &&
      currentColumnState.filter((col) => col.hide == true)?.length > 0 &&
      !localStorageColumnState
    ) {
      saveUserPreferenceDetails();
    } else if (localStorageColumnState) {
      // If the column state details are exists in local storage then save the changed Column state data only if difference is found
      var columnsStateArray = JSON.parse(localStorageColumnState);
      if (
        columnsStateArray &&
        columnsStateArray.length > 0 &&
        currentColumnState &&
        currentColumnState.length > 0 &&
        columnsStateArray.filter((col) => col.hide == true)?.length !=
          currentColumnState.filter((col) => col.hide == true)?.length
      ) {
        //If column state mismatch found then only save column state data to avoid saving same state
        saveUserPreferenceDetails();
      }
    }

    //Trigger Grid Column state change (of parent) to sync column state data
    handleGridColumnStateChange(currentColumnState);

    gridRef &&
      gridRef.current &&
      gridRef.current.columnApi &&
      gridRef.current.columnApi.autoSizeAllColumns();
  };

  //Save User Preference details (Column state)
  const saveUserPreferenceDetails = useCallback(() => {
    var columnState = gridRef.current.columnApi.getColumnState();
    if (columnState) {
      localStorage.setItem(
        "REITSuiteARQCChecklistColumnState",
        JSON.stringify(columnState)
      );
    }
  }, []);

  //Handle Grid Items per Page change event
  const handleItemsPerPageChange = (val) => {
    gridRef.current.api.paginationGoToFirstPage();
    gridRef.current.api.paginationSetPageSize(Number(val));
    setCurrentPage(1);
    setItemsPerPage(val);
    // Get total pages from Grid's default pagination control and apply it to custom pagination control
    if (gridRef && gridRef.current && gridRef.current.api) {
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
      //If total page is zero then set the current page as zero
      if (calcTotalPages == 0) {
        setCurrentPage(calcTotalPages);
      }
    }
  };

  //Handle Grid Page change event
  const handlePageChange = (val) => {
    setCurrentPage(val);
    gridRef.current.api.paginationGoToPage(val - 1);
  };

  useEffect(() => {
    handleGridChanges(
      rowData,
      gridRef && gridRef.current ? gridRef.current.api : null
    );
    updateRowNodeSelectableProperty();
  }, [rowData]);

  useEffect(() => {
    // Refresh Grid
    const gridApi = gridRef && gridRef.current && gridRef.current.api;
    if (gridApi) {
      var rows = [];
      if (
        refreshGridChecklistData &&
        refreshGridChecklistData.checklists &&
        refreshGridChecklistData.checklists.length > 0
      ) {
        gridApi.forEachNodeAfterFilter((row) => {
          if (
            row.level === 0 &&
            refreshGridChecklistData.checklists.some(
              (ch) =>
                row.key.toLowerCase() ===
                ch.checklistID + "_" + ch.checklistType.toLowerCase()
            )
          ) {
            if (
              row.allLeafChildren &&
              Array.isArray(row.allLeafChildren) &&
              row.allLeafChildren.length > 0 &&
              row.allLeafChildren[0].data
            ) {
              if (
                !row.allLeafChildren[0].data.recipientEmail ||
                row.allLeafChildren[0].data.recipientEmail.length === 0
              ) {
                row.setExpanded(false);
              }
            }
            rows.push(row);
          }
        });

        //Redraw rows
        if (rows.length > 0) {
          setTimeout(function () {
            gridApi.redrawRows({ rowNodes: rows });
          }, 0);
        }
      }

      gridApi.refreshHeader();
      gridApi.refreshCells();
      gridApi.deselectAll();
    }
  }, [refreshGridChecklistData]);

  useEffect(() => {
    setRowData(filteredManageChecklists);

    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshHeader();
      // Get total pages from Grid's default pagination control and apply it to custom pagination control
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);

      //Show/Hide Reopen requests columns - If any checklists having reopen requests(pending, approved or denied) then only show Reopen Request columns
      gridRef.current.columnApi.setColumnsVisible(
        ["reopenRequestStatusDescription", "requestDateTime"],
        filteredManageChecklists &&
          filteredManageChecklists.some(
            (ch) => ch.reopenRequestStatusDescription
          )
      );
    }
  }, [filteredManageChecklists]);

  useEffect(() => {
    if (refreshGridData > 0) {
      // Refresh Grid
      let updatedData = filteredManageChecklists;
      setRowData(updatedData);
      if (gridRef && gridRef.current && gridRef.current.api) {
        gridRef.current.api.setRowData(updatedData);
        gridRef.current.api.refreshCells();
        gridRef.current.api.refreshHeader();
        gridRef.current.api.forEachNodeAfterFilter((row) => {
          if (
            row.level === 0 &&
            row.allLeafChildren &&
            Array.isArray(row.allLeafChildren) &&
            row.allLeafChildren.length > 0 &&
            row.allLeafChildren[0].data
          )
            if (
              !row.allLeafChildren[0].data.recipientEmail ||
              row.allLeafChildren[0].data.recipientEmail.length === 0
            ) {
              row.setExpanded(false);
            }
        });
      }
    }
  }, [refreshGridData]);

  useEffect(() => {
    //gridFilteredRowData contains filtered Row data
    handleGridFilteredRowChanges(gridFilteredRowData);
  }, [gridFilteredRowData]);

  const handleSelectionChanged = (event) => {
    handleRecipientChanges(
      gridRef && gridRef.current ? gridRef.current.api : null
    );
  };

  // Handle Row Group Open event(Expand/Collapse of Group Icon)
  const handleRowGroupOpened = (event) => {
    if (
      event &&
      event.expanded &&
      gridRef &&
      gridRef.current &&
      gridRef.current.columnApi
    ) {
      //Auto size Recipient Email and Sender Email columns
      gridRef.current.columnApi.autoSizeColumns([
        "recipientEmail",
        "senderEmail",
      ]);
    }
  };

  // Handle Expand/Collapse All
  const handleExpandOrCollapseAll = (event) => {
    if (event && event.source && event.source.toLowerCase() == "expandall") {
      const gridApi = gridRef && gridRef.current && gridRef.current.api;
      if (gridApi) {
        gridApi.forEachNodeAfterFilter((row) => {
          if (
            row.level === 0 &&
            row.allLeafChildren &&
            Array.isArray(row.allLeafChildren) &&
            row.allLeafChildren.length > 0 &&
            row.allLeafChildren[0].data
          )
            if (
              !row.allLeafChildren[0].data.recipientEmail ||
              row.allLeafChildren[0].data.recipientEmail.length === 0
            ) {
              row.setExpanded(false);
            }
        });
      }
    }
  };

  // Handle Row data updated event
  const handleRowDataUpdated = () => {
    if (gridRef && gridRef.current && gridRef.current.columnApi) {
      gridRef.current.columnApi.autoSizeAllColumns();
    }
  };

  useEffect(() => {
    //Reset Grid to default column state
    //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
    var localStorageColumnState = localStorage.getItem(
      "REITSuiteARQCChecklistColumnState"
    );
    if (
      !localStorageColumnState &&
      gridRef &&
      gridRef.current &&
      gridRef.current.api
    ) {
      gridRef.current.api.setColumnDefs([]);
      gridRef.current.api.setColumnDefs(getColumnDefs());
    }
  }, [gridColumnState]);

  const rowHeight = 76; 
  const headerHeight = 45; 
  const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

  return (
    <div className="row mb-3 marginTop">
      {filteredManageChecklists && filteredManageChecklists.length > 0 && (
        <div className="col mt-3">
          <div className="ag-theme-quartz motif-table table-bd-full-height-width">
            <MotifTable zebra={true} compact={true}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              accentedSort={true}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              onGridReady={handleGridReady}
              onFirstDataRendered={handleFirstDataRendered}
              onSelectionChanged={handleSelectionChanged}
              onRowGroupOpened={handleRowGroupOpened}
              onExpandOrCollapseAll={handleExpandOrCollapseAll}
              onRowDataUpdated={handleRowDataUpdated}
              ref={gridRef}
              pagination={true}
              suppressPaginationPanel={true}
              paginationPageSize="100"
              getRowId={getRowId}
              groupDisplayType={"custom"}
              groupSelectsChildren={true}
              groupDefaultExpanded={0}
              onFilterChanged={handleFilterChanged}
              data-testid="manageChecklistGrid"
            />
             <div className="mt-3 mb-3 right" style={{ marginBottom: '12rem' }}>
              <MotifPagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                min={1}
                max={totalPages}
              >
                <MotifPaginationSelect>
                  <MotifPaginationSelectItem
                    onClick={() => handleItemsPerPageChange(50)}
                  >
                    Show 50
                  </MotifPaginationSelectItem>
                  <MotifPaginationSelectItem
                    selected
                    onClick={() => handleItemsPerPageChange(100)}
                  >
                    Show 100
                  </MotifPaginationSelectItem>
                  <MotifPaginationSelectItem
                    onClick={() => handleItemsPerPageChange(150)}
                  >
                    Show 150
                  </MotifPaginationSelectItem>
                  <MotifPaginationSelectItem
                    onClick={() => handleItemsPerPageChange(200)}
                  >
                    Show 200
                  </MotifPaginationSelectItem>
                </MotifPaginationSelect>
              </MotifPagination>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ARQCChecklistTable.propTypes = {};

export default reduxForm({ form: "arqcChecklist" })(ARQCChecklistTable);
