import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_QUESTION_COMMENTS_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The question comments data currently in the store, or the initial state of question comments data
 * @param {Object} action The action to be handled
 * @returns {Object} The question comments data
 */
export default function questionCommentsReducer(state = initialState.questionComments, action) {
    if (action.type == actions.LOAD_QUESTION_COMMENTS_SUCCESS && Array.isArray(action.questionComments)) {
        return action.questionComments;
    } else if (action.type == actions.LOAD_QUESTION_COMMENTS_FAILURE) {
        return initialState.questionComments;
    } else {
        return state;
    }
}