import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the SET_PSQ_ACTIVE_CHECKLIST_SUCCESS action and sets the PSQ checklist in the store
 * @param {Object} [state] The PSQ checklist currently in the store, or the initial state of the PSQ checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function psqActiveChecklistReducer(state = initialState.activePsqChecklist, action) {
    if (action.type === actions.SET_PSQ_ACTIVE_CHECKLIST_SUCCESS) {
        return action.activeChecklist;
    } else if (action.type == actions.UNLOAD_TESTS) {
        return initialState.activePsqChecklist;
    } else {
        return state;
    }
}