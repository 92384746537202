import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the SET_ARQC_ACTIVE_CHECKLIST_SUCCESS action and sets the ARQC checklist in the store
 * @param {Object} [state] The ARQC checklist currently in the store, or the initial state of the ARQC checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function deletedChecklistReducer(state = initialState.deletedChecklist, action) {
    if (action.type === actions.DELETE_CHECKLIST_SUCCESS) {
        return action.checklist;
    } if (action.type === actions.DELETE_CHECKLIST_CLEAR) {
        return null;
    } else {
        return state;
    }
}