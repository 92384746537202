import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_TB_ITEM_SUCCESS action and sets the TB ITEM in the store
 * @param {Object} [state] The item currently in the store, or the initial state of the TB item
 * @param {Object} action The action to be handled
 * @returns {Object} The summary
 */
export default function trialBalanceItemReducer(state = initialState.trialBalanceItem, action) {
    if (action.type === actions.LOAD_TB_ITEM_SUCCESS) {
        return action.trialBalanceItem;
    } else if (action.type == actions.UNLOAD_PERIOD || action.type === actions.LOAD_TB_ITEM_FAILURE) {
        return initialState.trialBalanceItem;
    } else {
        return state;
    }
}