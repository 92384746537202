import React from "react";
import PropTypes from "prop-types";
import PropertyDetailRow from "./PropertyDetailRow";

const PropertyDetailTable = ({ propertyDetail }) => {
    return (
        <table className="table mb-0 table-bordered">
            <tbody>
                <tr>
                    <th>&nbsp;</th>
                    <th colSpan="3" className="text-center">Current Period Amounts</th>
                    <th colSpan="3" className="text-center">Prior Year Amounts</th>
                    <th colSpan="3" className="text-center">Average</th>
                    <th>&nbsp;</th>
                </tr>
                <tr>
                    <th width="10%">
                        Property Name
                    </th>
                    <th className="text-center" width="9">
                        PP
                    </th>
                    <th className="text-center" width="9%">
                        Building
                    </th>
                    <th className="text-center" width="9%">
                        Land
                    </th>
                    <th className="text-center" width="9%">
                        PP
                    </th>
                    <th className="text-center" width="9%">
                        Building
                    </th>
                    <th className="text-center" width="9%">
                        Land
                    </th>
                    <th className="text-center" width="9%">
                        PP
                    </th>
                    <th className="text-center" width="9%">
                        Building
                    </th>
                    <th className="text-center" width="9%">
                        Land
                    </th>
                    <th className="tright" width="9%">
                        15% Result
                    </th>
                </tr>
                {propertyDetail && propertyDetail.properties.map(item => <PropertyDetailRow key={item.propertyId} item={item} />)}
            </tbody>
        </table>
    );
};

PropertyDetailTable.propTypes = {
    propertyDetail: PropTypes.object
};

export default PropertyDetailTable;