import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import ErrorSummaryItem from './ErrorSummaryItem';
import { MotifToast } from '@ey-xd/motif-react';

const ErrorSummary = ({ errorResponse, handleDismiss }) => {
    if (!errorResponse) {
        return (<span />);
    }
    //errorResponse = [{message: "error"},{message: "error"},{message: "error"}];
    if (Array.isArray(errorResponse.validationErrors) && errorResponse.validationErrors.filter(x => x.message).length > 0) {
        return (
            <MotifToast className="mb-2 mt-3" variant="error" onClose={handleDismiss}>
                <p style={{ fontWeight: "bold" }} className='mb-0 pb-0'>The following error(s) occurred:</p>
                <ul className='ml-4'>
                    {errorResponse.validationErrors.map((x, i) => <li className='mt-0 mb-0 pt-0 pb-0' key={i}>{x.message}</li>)}
                </ul>
            </MotifToast>
        );
    }

    return (
        <MotifToast className="mb-2" variant="error" onClose={handleDismiss}>
            {errorResponse.message || errorResponse.Message}
        </MotifToast>
    );
};

ErrorSummary.propTypes = {
    errorResponse: PropTypes.shape({
        validationError: PropTypes.shape({
            message: PropTypes.string.isRequired
        })
    }),
    handleDismiss: PropTypes.func
};

export default ErrorSummary;