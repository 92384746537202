// Set up your root reducer here...
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import pendingTasks from './pendingTasksReducer';
import assetDetails from './assetDetailReducer';
import assetDetailsForYear from './assetDetailsReducer';
import distributionDetails from './distributionDetailReducer';
import incomeDetails from './incomeDetailReducer';
import propertyDetails from './propertyDetailReducer';
import propertyItsi from './propertyItsiReducer';
import assetSummary from './assetSummaryReducer';
import clients from './clientsReducer';
import client from './clientReducer';
import allReits from './allReitsReducer';
import reits from './reitsReducer';
import externalReits from './reitsExternalReducer'
import reit from './reitReducer';
import reitsForMO from './reitsForMOReducer';
import period from './periodReducer';
import periods from './periodsReducer';
import isAuthenticated from './isAuthenticatedReducer';
import availableReportItems from './availableReportItemsReducer';
import securitiesSnapshots from './securitiesSnapshotReducer';
import taxableReitSubsidiaries from './taxableReitSubsidiariesReducer';
import incomeTestSummary from './incomeTestSummaryReducer';
import distributionTestSummary from './distributionTestSummaryReducer';
import distributionTaxableIncome from './distributionTaxableIncomeReducer';
import arqcActiveChecklistReducer from './arqcActiveChecklistReducer';
import arqcLoadChecklistsReducer from './arqcLoadChecklistsReducer';
import arqcPreviousChecklistReducer from './arqcPreviousChecklistReducer';
import psqLoadChecklistsReducer from './psqLoadChecklistsReducer';
import psqActiveChecklistReducer from './psqActiveChecklistReducer';
import psqPreviousChecklistReducer from './psqPreviousChecklistReducer';
import checklistFilesReducer from './checklistFilesReducer';
import reportPeriodVisibilityConstraintReducer from './reportPeriodVisibilityConstraintReducer';
import externalChecklistListReducer from './externalChecklistListReducer';
import errorResponse from './errorResponseReducer';
import dismissUsersMessage from './usersMessageReducer';
import tbUploadSuccess from './tbUploadSuccessReducer';
import properties from './propertiesReducer';
import property from './propertyReducer';
import deletePropertyDetail from './deletePropertyDetailReducer';
import varianceDetails from './varianceReducer';
import externalPsq from './externalPsqReducer';
import externalArqc from './externalArqcReducer';
import services from './servicesReducer';
import files from './filesReducer';
import testSnapshots from './testSnapshotsReducer';
import fileUploadSuccess from './fileUploadSuccessReducer';
import lastTrialBalance from './lastTrialBalanceReducer';
import trialBalanceSummary from './trialBalanceSummaryReducer';
import trialBalanceAdjustments from './trialBalanceAdjustmentsReducer';
import trialBalanceItem from './trialBalanceItemReducer';
import trialBalanceItems from './trialBalanceItemsReducer';
import lastConsolidatedTrialBalance from './lastConsolidatedTrialBalanceReducer';
import lastPropertyTrialBalance from './lastPropertyTrialBalanceReducer';
import finalReport from './finalReportReducer';
import finalReportUploadSuccess from './finalReportUploadSuccessReducer';
import { reducer as oidcReducer } from 'redux-oidc';
import auth from './authReducer';
import authorizations from './authorizationsReducer';
import currentUserAuthorizations from './currentUserAuthorizationsReducer';
import deletedChecklist from './deletedChecklistReducer';
import periodMappings from './periodMappingsReducer';
import periodMappingsBalances from './periodMappingsBalancesReducer';
import mapping from './mappingReducer';
import chartOfAccounts from './chartOfAccountsReducer';
import coaDgms from './coaDetailGroupMappingReducer';
import detailGroupMappings from './detailGroupMappingsReducer';
import trialBalanceStatus from './signalRReducers/trialBalanceStatusReducer';
import notifications from './notificationsReducer';
import showNotifications from './showNotificationsReducer';
import showBulkUploadModal from './showBulkUploadModalReducer';
import uploadProgress from './uploadProgressReducer';
import uploadQueue from './uploadQueueReducer';
import uploadProcesses from './uploadProcessesReducer';
import processingQueueCount from './processingQueueCountReducer';
import navigationServices from './navigationServicesReducer';
import authentication from './authenticationReducer';
import lastClickedTabChecklistQuestionId from './lastClickedTabChecklistQuestionIdReducer';
import checklistDeliveryCalendar from './checklistDeliveryCalendarReducer';
import checklistCalendarYears from './checklistCalendarYearsReducer';
import authenticationScope from './authenticationScopeReducer';
import customQuestionnaire from './customQuestionnaireReducer';
import psqTemplates from './psqTemplateReducer';
import bulkReportPackages from './bulkReportPackageReducer';
import bulkReportPackagesETA from './bulkReportPackageETAReducer';
import customFdrTemplate from './customFdrTemplateReducer';
import chartOfAccountsManager from './chartOfAccountsManagerReducer';
import chartMappings from './chartMappingsReducer';
import lookupTypes from './lookupTypesReducer';
import reitFiles from './reitFilesReducer';
import manageChecklists from './manageChecklistReducer';
import externalChecklists from './externalChecklistReducer';
import externalChecklistsClients from './externalChecklistsClientsReducer';
import checklistCalendarYearsByType from './checklistCalendarYearsByTypeReducer';
import refreshManageChecklistClient from './signalRReducers/refreshManageChecklistClientReducer';
import customPsqTemplate from './customPsqTemplatesReducers/customPsqTemplateReducer';
import availableQuestion from './availableQuestionReducer';
import availableQuestionById from './availableQuestionByIdReducer';
import createCustomPsqTemplate from './customPsqTemplatesReducers/createCustomPsqTemplateReducer';
import checklistDeliverySchedules from './checklistDeliverySchedulesReducer';
import attachmentDetail from './attachmentDetailReducer';
import customPsqTemplateContent from './customPsqTemplatesReducers/customPsqTemplateContentReducer';
import customPsqTemplateDetails from './customPsqTemplatesReducers/customPsqTemplateDetailsReducer';
import questionComments from './questionCommentsReducer';
import checklistsCounter from './checklistsCounterReducer';
import discrepanciesDetail from './questionDiscrepanciesReducer';
import lastServiceProviderQuestionId from './lastServiceProviderQuestionIdReducer';
import userProfilePicture from './microsoftGraphApiReducers/userProfilePictureReducer';
import customQuestionIntentLockUpdateResult from './customPsqTemplatesReducers/customQuestionIntentLockUpdateResultReducer';
import customQuestionLockStatusResultReducer from './customPsqTemplatesReducers/customQuestionLockStatusResultReducer';
import bulkProcessManagementData from './bulkProcessManagementReducers/bulkProcessManagementDataReducer';
import bulkRollForwardData from './bulkRollForwardReducers/bulkRollForwardDataReducer';
import themeServices from './themeServicesReducer';
import dataRetentionManagementData from './dataRetentionManagementReducers/dataRetentionManagementDataReducer';
import dataPurgeConfigurationData from './dataPurgeReducer/dataPurgeConfigurationDataReducer';
import dataPurgeUserAcknowledgementHistoryData from './dataPurgeReducer/dataPurgeUserAcknowledgementHistoryDataReducer';
import upcomingDataDeletionNoticeData from './dataPurgeReducer/upcomingDataDeletionNoticeDataReducer';
import featureManagementData from './featureManagementReducers';
import dataDeletionDiagnosticData from './dataPurgeReducer/dataDeletionDiaognisticDataReducer';
import externalUserVisibilityData from './externalUserDataVisibilityReducer/externalUserDataVisibilityReducer';
/**
 * The parent or root reducer that combines all other reducers to form a single state store
 */
const createRootReducer  = () => combineReducers({
    arqcActiveChecklist: arqcActiveChecklistReducer,
    arqcChecklists: arqcLoadChecklistsReducer,
    previousArqcChecklist: arqcPreviousChecklistReducer,
    psqActiveChecklist: psqActiveChecklistReducer,
    psqChecklists: psqLoadChecklistsReducer,
    previousPsqChecklist: psqPreviousChecklistReducer,
    checklistFiles: checklistFilesReducer,
    checklistList: externalChecklistListReducer,
    allowUpdateReportPeriodVisibilityConstraintResult: reportPeriodVisibilityConstraintReducer,
    assetDetails,
    assetDetailsForYear,
    distributionDetails,
    incomeDetails,
    propertyDetails,
    propertyItsi,
    deletePropertyDetail,
    assetSummary,
    form: formReducer,
    clients,
    client,
    allReits,
    reits,
    externalReits,
    reit,
    reitsForMO,
    period,
    periods,
    taxableReitSubsidiaries,
    isAuthenticated,
    availableReportItems,
    routing: routerReducer,
    securitiesSnapshots,
    incomeTestSummary,
    distributionTestSummary,
    distributionTaxableIncome,
    errorResponse,
    dismissUsersMessage,
    tbUploadSuccess,
    properties: properties,
    property,
    varianceDetails,
    externalPsq,
    externalArqc,
    services,
    navigationServices,
    files,
    testSnapshots,
    lastTrialBalance,
    lastPropertyTrialBalance,
    lastConsolidatedTrialBalance,
    finalReport,
    fileUploadSuccess,
    finalReportUploadSuccess,
    oidc: oidcReducer,
    pendingTasks,
    trialBalanceSummary,
    trialBalanceAdjustments,
    trialBalanceItems,
    trialBalanceItem,
    auth,
    authorizations,
    currentUserAuthorizations,
    deletedChecklist,
    periodMappings,
    periodMappingsBalances,
    mapping,
    chartOfAccounts,
    chartOfAccountsManager,
    trialBalanceStatus,
    notifications,
    showNotifications,
    showBulkUploadModal,
    uploadProgress,
    uploadQueue,
    uploadProcesses,
    coaDgms,
    detailGroupMappings,
    processingQueueCount,
    authentication,
    lastClickedTabChecklistQuestionId,
    checklistDeliveryCalendar,
    checklistCalendarYears,
    authenticationScope,
    customQuestionnaire,
    psqTemplates,
    bulkReportPackages,
    bulkReportPackagesETA,
    customFdrTemplate,
    chartMappings,
    lookupTypes,
    reitFiles,
    manageChecklists,
    externalChecklists,
    externalChecklistsClients,
    checklistCalendarYearsByType,
    refreshManageChecklistClient,
    customPsqTemplate,
    availableQuestion,
    createCustomPsqTemplate,
    availableQuestionById,
    checklistDeliverySchedules,
    attachmentDetail,
    customPsqTemplateContent,
    customPsqTemplateDetails,
    questionComments,
    checklistsCounter,
    discrepanciesDetail,
    lastServiceProviderQuestionId,
    userProfilePicture,
    customQuestionIntentLockUpdateResult,
    customQuestionLockStatusResultReducer,
    bulkProcessManagementData,
    bulkRollForwardData,
    themeServices,
    dataRetentionManagementData,
    dataPurgeUserAcknowledgementHistoryData,
    upcomingDataDeletionNoticeData,
    featureManagementData,
    dataPurgeConfigurationData,
    dataDeletionDiagnosticData,
    externalUserVisibilityData
});

export default createRootReducer;