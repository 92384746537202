import React from 'react';

import { MotifTooltip } from "@ey-xd/motif-react";

export default (props) => {
    const isReitAvailableForSelection = props.data ? props.data.isReitAvailableForSelection : null;
    const isReitActive = props.data ? props.data.isReitActive : null;
    const reitName = props.data ? props.data.reitName : null;

    if (props.data && !isReitActive) {
        return (
            <MotifTooltip
                placement="auto"
                trigger={<span>{reitName}</span>}
            >
                This REIT is Inactive and unavailable for selection.
            </MotifTooltip>
        );
    }

    if (props.data && !isReitAvailableForSelection) {
        return (
            <MotifTooltip
                placement="auto"
                trigger={<span>{reitName}</span>}
            >
                This REIT is currently unavailable for selection as the bulk update or roll forward is in progress.
            </MotifTooltip>
        );
    }

    if (props.data && isReitAvailableForSelection) {
        return (
            <span>
                {reitName}
            </span>
        );
    }

    return null;
};