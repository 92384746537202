import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_PROPERTY_ITSI_SUCCESS action and sets a client in the store
 * @param {Object} [state] The property ITSI object currently in the store, or the initial state of property ITSI object
 * @param {Object} action The action to be handled
 * @returns {Object} The property ITSI object
 */
export default function propertyItsi(state = initialState.propertyItsi, action) {
    switch (action.type) {
    case actions.LOAD_PROPERTY_ITSI_SUCCESS:
        return action.propertyItsi;
    case actions.LOAD_PROPERTY_ITSI_FAILURE:
    case actions.UNLOAD_PERIOD:
        return initialState.propertyItsi;
    default:
        return state;
    }
}