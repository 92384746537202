import React from "react";

import { MotifIcon } from '@ey-xd/motif-react';
import { IconoirCheck } from '@ey-xd/motif-icon';

export default (props) => {
    const value = props.data ? props.data[props.colDef.field] : null;

    if (props.data && value) {
        return (
            <span>
                <MotifIcon iconFunction={IconoirCheck} size='24' fill='none' />
            </span>
        );
    }

    return null;
}