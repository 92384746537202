import React from 'react';
import {  MotifCheckbox } from "@ey-xd/motif-react";

export default (props) => {
  let rowsChecked = 0;
  let totalRows = 0;

  // Check for total rows and rows checked within filtered rows
  props.api.forEachNodeAfterFilter((rowNode) => {
    if (rowNode.data && rowNode.data.isReitAvailableForSelection && rowNode.data.isReitActive) {
      totalRows++;
      if (rowNode.data.reitSelection){
        rowsChecked++;
      }
    }
  });

  let headerCheckboxValue = false;
  let indeterminate = false;
  const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
  let checkboxClass = defaultCheckboxClass;

  if (rowsChecked === 0) {
    headerCheckboxValue = false;
  }
  else if (totalRows === rowsChecked) {
    headerCheckboxValue = true;
    checkboxClass = defaultCheckboxClass + " ag-checked";
  }
  else {
    headerCheckboxValue = false;
    indeterminate = true;
    checkboxClass = defaultCheckboxClass + " ag-indeterminate";
  }

  return (
    <div>
        <MotifCheckbox
            type="checkbox"
            name="header-checkbox"
            id="ag-header-checkbox"
            checked={headerCheckboxValue}
            data-testid="selectCheckboxHeader"
            aria-label="Press Space to toggle row selection"
            tabindex="-1"
            onClick={() => props.onHeaderCheckboxChange(headerCheckboxValue)}
            indeterminate={indeterminate}>
        </MotifCheckbox>
        <div className="customHeaderLabel">{props.displayName}</div>
    </div>
  );
};