import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_COA_SUCCESS action and sets a periods mappings in the store
 * @param {Object} [state] The chart of accounts currently in the store, or the initial state of period mappings
 * @param {Object} action The action to be handled
 * @returns {Object} The chart of accounts
 */
export default function chartOfAccounts(state = initialState.chartOfAccounts, action) {
    switch (action.type) {
    case actions.LOAD_COA_SUCCESS:
        return action.chartOfAccounts;
    case actions.LOAD_COA_FAILURE:
    case actions.UNLOAD_FILE_MANAGEMENT:
        return initialState.chartOfAccounts;
    default:
        return state;
    }
}