import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles Authorization
 * @param {Object} [state] Authorization currently in the store
 * @param {Object} action The action to be handled
 * @returns {Object} Auth
 */
export default function authReducer(state = initialState.authorization, action) {
    switch (action.type) {
        case actions.LOAD_AUTHORIZATION_RESOURCE_SUCCESS:
            return action.authorization || {};
        case actions.LOAD_MANAGED_RESOURCES_SUCCESS:
            return action.managedResources || {};
        case actions.LOAD_UNMANAGED_RESOURCES_SUCCESS:
            return action.unmanagedResources || {};
        case actions.LOAD_MANAGED_ROLES_SUCCESS:
            return action.managedRoles || {};
        case actions.LOAD_UNMANAGED_ROLES_SUCCESS:
            return action.unmanagedRoles || {};
        case actions.LOAD_AUTHORIZATION_SUCCESS:
            return action.authorization || {};
        case actions.CREATE_AUTHORIZATION_SUCCESS:
            return initialState.authorization; //??
        case actions.UPDATE_AUTHORIZATION_SUCCESS:
            return initialState.authorization; //??
        case actions.REMOVE_AUTHORIZATION_SUCCESS:
            return initialState.authorization; //??
        default:
            return state;
    }
}