import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import * as actionHelpers from '../scripts/actionHelpers';
import { fetchFromAuthApi as fetch } from './fetchFromApi';

/**
 * Fetch all the Data Retention Management  Data for a given client, calendar year. If successful this will dispatch the LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS
 * action, otherwise it will dispatch the LOAD_DATA_RETENTION_MANAGEMENT_DATA_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */

export const fetchExternalUserDataVisibility = () => {
  return (dispatch) => {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/externaluserdatavisibility/getExternalUserDataVisibility`)
      .then((response) => {
        return response.json();
      })
      .then((externalUserVisibilityData) => {
        if (actionHelpers.isErrorResponse(externalUserVisibilityData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_External_User_Data_Visibility_FAILURE,
            null
          );
        }
        dispatch({
          type: actions.LOAD_External_User_Data_Visibility_SUCCESS,
          externalUserVisibilityData,
          [pendingTask]: end,
        });

        return externalUserVisibilityData;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_External_User_Data_Visibility_FAILURE,
          null,
          error
        );
      });
  };
  
}

export function hideExternalUserDataVisibility(requestData) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

      return fetch(`/externaluserdatavisibility/hideReportPeriodForExternalUsers`, {
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(requestData)
      })
          .then(response => {
              return response.text();
          })
          .then(response => {
              const jsonResponse = response ? JSON.parse(response) : {};
              if (actionHelpers.isErrorResponse(jsonResponse)) {
                  return actionHelpers.dispatchErrorAndEndTask(
                      dispatch,
                      actions.ACTION_External_User_Data__Hide_Visibility_SUCCESS,
                      jsonResponse,
                  );
              }

              dispatch({ type: actions.TASK_END, [pendingTask]: end });
              return jsonResponse;
          })
          .catch(error => {
              actionHelpers.dispatchErrorAndEndTask(
                  dispatch,
                  actions.ACTION_External_User_Data__Hide_Visibility_FAILURE,
                  null,
                  error,
              );
          });
  };
}


export function unHideExternalUserDataVisibility(requestData) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

      return fetch(`/externaluserdatavisibility/unhideReportPeriodForExternalUsers`, {
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(requestData)
      })
          .then(response => {
              return response.text();
          })
          .then(response => {
              const jsonResponse = response ? JSON.parse(response) : {};
              if (actionHelpers.isErrorResponse(jsonResponse)) {
                  return actionHelpers.dispatchErrorAndEndTask(
                      dispatch,
                      actions.ACTION_External_User_Data__UnHide_Visibility_SUCCESS,
                      jsonResponse,
                  );
              }

              dispatch({ type: actions.TASK_END, [pendingTask]: end });
              return jsonResponse;
          })
          .catch(error => {
              actionHelpers.dispatchErrorAndEndTask(
                  dispatch,
                  actions.ACTION_External_User_Data__UnHide_Visibility_FAILURE,
                  null,
                  error,
              );
          });
  };
}

export function fetchReportPeriodVisibilityManagerDataById(clientId,reitId,calendarYear,periodId,checklistGuid=null, checklistType=null) {
  const authHeader = checklistGuid ? actionHelpers.buildAuthHeader(checklistGuid, checklistType) : null;

  return function (dispatch) {
    if (!clientId ) {
      return dispatch({
        type: actions.LOAD_External_User_Data_Visibility_SUCCESS,
        externalUserVisibilityData: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/externalUserDataVisibility/getReportPeriodVisibilityManagerDataById?clientId=${clientId}&reportPeriodID=${periodId}&reitID=${reitId}&calendarYear=${calendarYear}`,
    {
      headers:  (authHeader && new Headers({ Authorization: authHeader })) || null,
      method: "GET",
    })    
      .then((response) => {
        return response.json();
      })
      .then((externalUserVisibilityData) => {
        if (actionHelpers.isErrorResponse(externalUserVisibilityData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_External_User_Data_Visibility_FAILURE,
            externalUserVisibilityData
          );
        }
        dispatch({
          type: actions.LOAD_External_User_Data_Visibility_SUCCESS,
          externalUserVisibilityData,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_External_User_Data_Visibility_FAILURE,
          null,
          error
        );
      });
  };
}


   