export const getQuarter = d => {
    d = d || new Date();
    const q = [1, 2, 3, 4];
    return q[Math.floor(d.getMonth() / 3)];
};

export const getYear = d => {
    d = d || new Date();
    return d.getFullYear();
};

const formatDigits = val => {
    val = val.toString();
    return val.length == 1 ? "0" + val : val;
};

export const getDateTime = (time, addZ) => {
    var dateWithZ = addZ ? `${new Date(time)}Z` : new Date(time);
    const weekdays = new Array(
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    );
    const dt = new Date(dateWithZ);
    let res = "";
    res += `${weekdays[dt.getDay()]}, `;
    res += formatDigits(dt.getMonth() + 1);
    res += "/";
    res += formatDigits(dt.getDate());
    res += "/";
    res += formatDigits(dt.getFullYear());
    res += " ";
    res += formatDigits(dt.getHours() > 12 ? dt.getHours() - 12 : dt.getHours());
    res += ":";
    res += formatDigits(dt.getMinutes());
    res += ":";
    res += formatDigits(dt.getSeconds());
    res += " " + dt.getHours() > 11 ? " PM" : " AM";
    return res;
};


export const relativeDate = (time, addZ) => {
    var dateWithZ = addZ ? `${new Date(time)}Z` : new Date(time);

    //var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " "));
    var date = new Date(dateWithZ),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);
    var year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate();

    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
        return (
            year.toString() + '-'
            + ((month < 10) ? '0' + month.toString() : month.toString()) + '-'
            + ((day < 10) ? '0' + day.toString() : day.toString())
        );

    var r =
        (
            (
                day_diff == 0 &&
                (
                    (diff < 60 && "just now")
                    || (diff < 120 && "1 minute ago")
                    || (diff < 3600 && Math.floor(diff / 60) + " minutes ago")
                    || (diff < 7200 && "1 hour ago")
                    || (diff < 86400 && Math.floor(diff / 3600) + " hours ago")
                )
            )
            || (day_diff == 1 && "Yesterday")
            || (day_diff < 7 && day_diff + " days ago")
            || (day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago")
        );
    return r;
};

//function secondsToString(timestamp) {
//    var startDate = new Date(timestamp);
//    var endDate = new Date();
//    var t = (endDate.getTime() - startDate.getTime()) / 1000;
//    return parseInt(t / 86400) + ' day(s) ' + (new Date(t % 86400 * 1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1 hour(s) $2 min(s) $3 second(s)");
//}

export const getPreviousFridayOrGivenDate= (givenDate)=>{
    const dateValue = new Date(givenDate);
if (dateValue.getDay() === 0 || dateValue.getDay() === 6) {
    // If it's a weekend, find the date for the previous Friday
    dateValue.setDate(dateValue.getDate() - (dateValue.getDay() + 2) % 7);
    return dateValue;
} else {
    return dateValue;
}
}
