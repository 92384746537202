import React from 'react';
import { PropTypes } from "prop-types";
import { Link } from 'react-router-dom';
import { MotifIcon } from '@ey-xd/motif-react';
import { IconoirHelpCircle } from '@ey-xd/motif-icon';

const DataStewardRoleManagementHeader = ({
    clientId
}) => {
    return (
        <>
            <h2 id="information">Add Data Steward<Link to={'/helpGuide/'} target="_blank" rel="noopener noreferrer" >
                <MotifIcon className="ml-2 mt-2 mr-5 dataretentionIcon" iconFunction={IconoirHelpCircle} size="24" fill="none" />
            </Link> </h2>
            <p className="text-danger" id="informationText">
                The Data Deletion Policy for REITSuite instructs that at least one authorized user must be designated as a Data Steward to proceed with further access to the application. For more information, please click on the help icon.
            </p>
        </>
    );
};

DataStewardRoleManagementHeader.propTypes = {
    clientId: PropTypes.number.isRequired,
};

export default DataStewardRoleManagementHeader;
