import React from "react";
import PropTypes from "prop-types";
import PropertyItsiRow from "./PropertyItsiRow";

const PropertyItsiTable = ({ itsi }) => {
    return (
        <table className="table mb-0">
            <tbody>
                <tr>
                    <th width="16%">
                        Property Name
                    </th>
                    <th className="tcenter" width="12%">
                        Owned
                    </th>
                    <th className="tright" width="12%">
                        Rents from Real Property
                    </th>
                    <th className="tright" width="12%">
                        ITSI Income
                    </th>
                    <th className="tright" width="12%">
                        ITSI Expense
                    </th>
                    <th className="tright" width="12%">
                        Markup Of ITSI
                    </th>
                    <th className="tright" width="12%">
                        TOTAL ITSI
                    </th>
                    <th className="tright" width="12%">
                        1% ITSI Result
                    </th>
                </tr>
                {itsi && itsi.properties.map(item => <PropertyItsiRow key={item.propertyId} item={item} />)}
            </tbody>
        </table>
    );
};

PropertyItsiTable.propTypes = {
    itsi: PropTypes.object
};

export default PropertyItsiTable;