import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_NAVIGATION_SERVICES_SUCCESS action and sets data in the store
 * @param {Object} [state] The tabbed navigation services data currently in the store, or the initial state of tabbed navigation services data
 * @param {Object} action The action to be handled
 * @returns {Object} The tabbed navigation services data
 */
export default function navigationServices(state = initialState.navigationServices, action) {
    switch (action.type) {
        case actions.LOAD_NAVIGATION_SERVICES_SUCCESS:
            return action.navigationServices;
        case actions.UNLOAD_NAVIGATION_SERVICES:
        case actions.UNLOAD_PERIOD:
            return initialState.navigationServices;
        default:
            return state;
    }
}