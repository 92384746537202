import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS & CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE action and sets created custom template id to the store
 * @param {Object} [state] The custom psq template data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The custom psq template data
 */

export default function createCustomPsqTemplateReducer(
  state = initialState.createdCustomTemplateId,
  action
) {
  switch (action.type) {
    case actions.CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS:
    case actions.CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE:
      return action.templateId;
    default:
      return state;
  }
}
