import React from 'react';
import { MotifTooltip } from '@ey-xd/motif-react';

export default (props) => {
  const bulkReportPackageName =
    props.node && props.node.data ? props.node.data['bulkReportPackageName'] : '';

    if (bulkReportPackageName.length > 25) {
    return (
      <div>
        <span>{bulkReportPackageName.substring(0, 25)}</span>
        <span className="ml-1" style={{ cursor: 'pointer' }}>
          <MotifTooltip
            id="bulkReportPackageNameTooltip"
            allowClick
            allowHover
            placement="auto"
            trigger={<span style={{ whiteSpace: 'nowrap' }}>...</span>}
            variant="alt"
            style={{ margin: '0px' }}
            hideCloseButton={true}
          >
            <span style={{ margin: '0px', padding: '0px', maxWidth: '1000px' }}>{bulkReportPackageName}</span>
          </MotifTooltip>
        </span>
      </div>
    );
  } else {
    return (
      <div>
            <span>{bulkReportPackageName}</span>
      </div>
    );
  }
};
