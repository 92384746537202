import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_RIET_SUCCESS action and sets a REIT in the store
 * @param {Object} [state] The REIT currently in the store, or the initial state of REIT
 * @param {Object} action The action to be handled
 * @returns {Object} The REIT
 */
export default function period(state = initialState.period, action) {
    switch (action.type) {
        case actions.LOAD_PERIOD_SUCCESS:
        case actions.UPDATE_PERIOD_STATUS_SUCCESS:
            return action.period || {};
        case actions.LOAD_CLIENTS_SUCCESS:
        case actions.UNLOAD_PERIOD:
        case actions.LOAD_PERIOD_FAILURE:
        case actions.UPDATE_PERIOD_STATUS_FAILURE:
            return initialState.period;
        default:
            return state;
    }
}