import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifLabel, MotifFormField, MotifToggleSwitch } from '@ey-xd/motif-react';

const PSQCompletionRequirement = ({  
    isPSQChecklistCompletionRequirementEnabled,
    handlePSQCompletionRequirementChange
}) => {
    return <div>
        <div style={{ minHeight: '7px' }}></div>
            <MotifFormField style={{ display: 'inline-block', width: 'auto', float: 'right' }}>
                <div style={{ minHeight: '2px' }}></div>
                <MotifToggleSwitch
                    name="switchPSQCompletionRequirement"
                    id="switchPSQCompletionRequirement"
                    data-testid="switchPSQCompletionRequirement"
                    onChange={handlePSQCompletionRequirementChange}
                    checked={isPSQChecklistCompletionRequirementEnabled}
            />
            </MotifFormField>
            <MotifLabel
                htmlFor="switchPSQCompletionRequirement"
                style={{ display: 'inline-block', width: 'auto', marginRight: '0.5rem',marginTop: '0.1rem', float: 'right' }}>
                PSQ Completion Requirement:
            </MotifLabel>
    </div>;
};

PSQCompletionRequirement.propTypes = {
    handlePSQCompletionRequirementChange: PropTypes.func
};

export default reduxForm({ form: "psqCompletionRequirement" })(PSQCompletionRequirement);