import React from 'react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { IconoirTrash, IconoirDownload } from '@ey-xd/motif-icon';

export default (props) => {
    return (
        <div>
            <div className="justify-content-end align-items-center mt-2">

                <MotifIconButton aria-label="Download File" type="button"
                    onClick={()=>props.onDownload(encodeURIComponent(props?.data.fileName))}
                    data-testId="downloadFile">
                    <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' title="Download File" />
                </MotifIconButton>

                {props.canUpload &&
                    <MotifIconButton aria-label="Delete File" type="button"
                        onClick={() => props.onDelete(props?.data.fileName)}
                        data-testId="delete">
                        <MotifIcon iconFunction={IconoirTrash} size='24' fill='none'
                            title="Delete File" />
                    </MotifIconButton>
                    ||
                    <MotifIconButton aria-label="Can't Delete File" type="button"
                        data-testId="delete"
                        disabled>
                        <MotifIcon iconFunction={IconoirTrash} size='24' fill='none'
                        />
                    </MotifIconButton>
                }

            </div>

        </div>
    );
}