import PropTypes from "prop-types";
import React from "react";
import { reduxForm } from "redux-form";
import { MotifFormField, MotifCheckbox, MotifLabel, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifButton } from "@ey-xd/motif-react";

const ReminderToSaveFilesOutsideOfREITSuiteModal = ({
    showReminderToSaveFilesOutsideOfREITSuiteMsg,
    toggleReminderToSaveFilesOutsideOfREITSuiteMsg,
    reminderToSaveFilesAckChecked,
    handleReminderToSaveFiles,
    handleUserPreferenceSelectedForReminderToSaveFiles
}) => {
    return (
        <form>
            <MotifModal variant="info" show={showReminderToSaveFilesOutsideOfREITSuiteMsg} onClose={toggleReminderToSaveFilesOutsideOfREITSuiteMsg} data-testid="ReminderToSaveFiles">
                <MotifModalHeader closeModalButton={<span></span>}>
                    REITSuite Data Management Alert
                </MotifModalHeader>
                <MotifModalBody>
                    Alert: Please be aware that REITSuite does not serve as the permanent file repository. All necessary engagement
                    documents should be transferred to the designated systems in accordance with the firm's policy promptly after your engagement
                    completes.
                    <br></br>
                    <MotifFormField>
                        <MotifCheckbox
                            type="checkbox"
                            data-testid="chkbxReminderToSaveFilesAck"
                            name="reminderToSaveFilesAck"
                            id="reminderToSaveFilesAck"
                            checked={reminderToSaveFilesAckChecked}
                            onChange={handleUserPreferenceSelectedForReminderToSaveFiles}
                            className="mt-3"
                        >
                            <MotifLabel>I understand and do not show this message again</MotifLabel>
                        </MotifCheckbox>
                    </MotifFormField>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton variant="primary" className="right" type="button" data-testid="Confirm" 
                        onClick={() => handleReminderToSaveFiles()}>
                        Confirm
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </form >
    );
};

ReminderToSaveFilesOutsideOfREITSuiteModal.propTypes = {
    showReminderToSaveFilesOutsideOfREITSuiteMsg: PropTypes.bool,
    reminderToSaveFilesAckChecked: PropTypes.bool,
    toggleReminderToSaveFilesOutsideOfREITSuiteMsg: PropTypes.func,
    handleReminderToSaveFiles: PropTypes.func,
    handleUserPreferenceSelectedForReminderToSaveFiles: PropTypes.func
};

export default reduxForm({ form: "reminderToSaveFilesOutsideOfREITSuite", enableReinitialize: true })(
    ReminderToSaveFilesOutsideOfREITSuiteModal
);
