import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReportApi as fetch } from './fetchFromApi';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Retrieves and dispatches test snapshots for the period.
 * @param {any} periodId The ID of the period to retrieve test snapshots for.
 * @returns {any} A function that returns a Promise.
 */
export function fetchUploadedTestSnapshots(periodId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/testSummarySnapshot?periodid=${periodId}`).then(response => {
            return response.json();
        }).then(testSnapshots => {
            if (actionHelpers.isErrorResponse(testSnapshots)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TEST_SNAPSHOTS_FAILURE, testSnapshots);
            }
            dispatch({ type: actions.LOAD_TEST_SNAPSHOTS_SUCCESS, testSnapshots, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TEST_SNAPSHOTS_FAILURE, null, error);
        });
    };
}

/**
 * Downloads an existing test snapshot.
 * @param {any} periodId The ID of the period that the test snapshot belongs to.
 * @param {any} fileName The filename of the test snapshot.
 * @returns {any} A Promise.
 */
export function downloadTestSnapshot(periodId, fileName) {
    return fetch(`/testSummarySnapshot?periodid=${periodId}&fileName=${fileName}`)
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
}

/**
 * Generates a new test snapshot and downloads it.
 * @param {any} periodId The ID of the period to create the test snapshot for.
 * @returns {any} A Promise.
 */
export function downloadCurrentTestSnapshot(periodId, fileName) {
    return fetch(`/testSummarySnapshot/now?periodid=${periodId}`)
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
}

/**
 * Deletes a test snapshot.
 * @param {any} periodId The ID of the period that the test snapshot belongs to.
 * @param {any} fileName The filename of the test snapshot.
 * @returns {any} A Promise.
 */
export function deleteTestSnapshot(periodId, fileName) {
    let body = JSON.stringify({ periodId, fileName: decodeURI(decodeURIComponentSafe(fileName,1)) });
    return fetch(`/testSummarySnapshot?periodid=${periodId}&fileName=${fileName}`,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        }).then(response => {
            if (response.ok) {
                return null;
            } else {
                return response.json();
            }
        });
}