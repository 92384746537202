import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_LAST_PROPERTY_TRIAL_BALANCE_SUCCESS action and sets the last trial balance for the period in the store
 * @param {Array} [state] The clients currently in the store, or the initial state of clients
 * @param {Object} action The action to be handled
 * @returns {Array} The clients
 */
export default function lastPropertyTrialBalance(state = initialState.lastPropertyTrialBalance, action) {
    switch (action.type) {
        case actions.LOAD_LAST_PROPERTY_TRIAL_BALANCE_SUCCESS:
            if (action.lastPropertyTrialBalance === undefined) {
                return null;
            }

            return action.lastPropertyTrialBalance;
        case actions.LOAD_LAST_PROPERTY_TRIAL_BALANCE_FAILURE:
        case actions.UPLOAD_TB_SUCCESS:
        case actions.TB_PROCESSING_COMPLETE:
        case actions.UNLOAD_FILE_MANAGEMENT:
            return initialState.lastPropertyTrialBalance;
        default:
            return state;
    }
}