import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the CUSTOM_QUESTION_FETCH_LOCK_STATUS_SUCCESS & CUSTOM_QUESTION_FETCH_LOCK_STATUS_FAILURE actions and sets the lock status result data for a custom question.
 * @param {Object} [state] The initial state of the lock status result
 * @param {Object} action The action to be handled
 * @returns {Object} lock status result data
 */

export default function customQuestionLockStatusResultReducer(
  state = initialState.customQuestionLockStatus,
  action
) {
  switch (action.type) {
    case actions.CUSTOM_QUESTION_FETCH_LOCK_STATUS_SUCCESS:
      return action.customQuestionLockStatus;
    case actions.CUSTOM_QUESTION_FETCH_LOCK_STATUS_FAILURE:
    default:
      return state;
  }
}
