import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

const FormattedPercent = ({ percentage, minimumFractionDigits = 3, maximumFractionDigits = 3 }) => {
    const divisor = Math.pow(10, maximumFractionDigits);
    let value = Math.trunc(percentage * divisor) / divisor;

    return (<FormattedNumber value={value / 100} style="percent" minimumFractionDigits={minimumFractionDigits} maximumFractionDigits={maximumFractionDigits} />);
};

FormattedPercent.propTypes = {
    percentage: PropTypes.number.isRequired,
    digits: PropTypes.number
};

export default FormattedPercent;