import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_PSQ_TEMPLATES_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function psqTemplateReducer(state = initialState.psqTemplates, action) {
    if (action.type == actions.LOAD_PSQ_TEMPLATES_SUCCESS && Array.isArray(action.psqTemplates)) {
        return action.psqTemplates;
    } else if (action.type == actions.LOAD_PSQ_TEMPLATES_FAILURE) {
        return initialState.psqTemplates;
    } else {
        return state;
    }
}