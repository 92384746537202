import React from 'react';
import { MotifTooltip } from '@ey-xd/motif-react';

export default (props) => {
  const clientRecipients =
    props.node && props.node.data ? props.node.data['clientRecipients'] : '';

  if (clientRecipients.length > 16) {
    return (
      <div>
        <span>{clientRecipients.substring(0, 15)}</span>
        <span className="ml-1" style={{ cursor: 'pointer' }}>
          <MotifTooltip
            id="client-recipients-tooltip"
            allowClick
            allowHover
            placement="auto"
            trigger={<span style={{ whiteSpace: 'nowrap' }}>...</span>}
            variant="alt"
            style={{ margin: '0px' }}
            hideCloseButton={true}
          >
            <span style={{ margin: '0px', padding: '0px', maxWidth: '1000px' }}>
              {clientRecipients}
            </span>
          </MotifTooltip>
        </span>
      </div>
    );
  } else {
    return (
      <div>
        <span>{clientRecipients}</span>
      </div>
    );
  }
};
