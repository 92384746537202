import React from "react";
import PropTypes from "prop-types";

const ToastifyProgressBar = ({ percent }) => (
    <div>
        <div className="toastify-progress-title">Processing Changes</div>
        <div className="progress">
            <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                style={{
                    width: `${percent}%`,
                    backgroundColor: '#FFE600',
                    color: '#050505',
                }}
            >
                {percent}%
            </div>
        </div></div>
); 

ToastifyProgressBar.propTypes = {
    percent: PropTypes.number,
};

export default ToastifyProgressBar;