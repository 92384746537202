import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

/**
 * This reducer handles the LOAD_External_User_Data_Visibility_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function externalUserDataVisibilityReducer(state = initialState.externalUserVisibilityData, action) {
    if (action.type == actions.LOAD_External_User_Data_Visibility_SUCCESS && Array.isArray(action.externalUserVisibilityData)) {
        return action.externalUserVisibilityData;
    } else if (action.type == actions.LOAD_External_User_Data_Visibility_FAILURE) {
        return initialState.externalUserVisibilityData;
    } else {
        return state;
    }
}