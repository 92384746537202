import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from "semantic-ui-react";
import { MotifIconButton, MotifIcon, MotifTextLink } from "@ey-xd/motif-react";
import { contentIcUndo24px } from '@ey-xd/motif-react/assets/icons';

const ChecklistListRow = ({ checklistRow, clientId, handleRevertChecklistState }) => {
    const getCheckListItemStateName = (state) => {
        switch (state) {
            case "InProgress":
                return "In Progress";
            case "PendingEyReview":
                return "Pending EY Review";
            default:
                return state;
        }
    };

    let stateName = getCheckListItemStateName(checklistRow.status);

    return (
        <tr>
            <td>{checklistRow.period}</td>
            <td><Link tabIndex="-1" to={`/ext/reits/${clientId}/checklists/${checklistRow.reitId}/arqc/${checklistRow.arqcCheckListId}`}><MotifTextLink variant="alt">ARQC</MotifTextLink></Link></td>
            <td style={{ textAlign: "center" }}>                
                <MotifIconButton aria-label="Revert ARQC State" type="button"
                    onClick={() => handleRevertChecklistState(checklistRow)}
                    disabled={stateName === 'Completed' || stateName === 'Created'}>
                    <MotifIcon src={contentIcUndo24px}
                        title="Revert ARQC State" />
                </MotifIconButton>
            </td>
            <td>{stateName}</td>
        </tr>
    );
};

ChecklistListRow.propTypes = {
    checklistRow: PropTypes.object.isRequired
};

export default ChecklistListRow;