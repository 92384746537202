import React from "react";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import BulkProcessSignOffModal from "../../components/bulkProcessManagement/BulkProcessSignOffModal";
import BulkProcessUpdateServiceAlertModal from '../../components/bulkProcessManagement/BulkProcessUpdateServiceAlertModal'
import BulkProcessManagementHeader from "../../components/bulkProcessManagement/BulkProcessManagementHeader";
import BulkProcessManagementTable from "../../components/bulkProcessManagement/BulkProcessManagementTable";
import * as Constants from '../../constants/other';
import * as bulkProcessManagementActions from "../../actions/bulkProcessManagementActions";
import ScopedServicesModal from "../../components/reits/ScopedServicesModal";
import * as reitActions from "../../actions/reitActions";
import * as actions from '../../actions/actionTypes';
import * as authActions from "../../actions/authActions";
import { withRouter } from "../../common/withRouter";

/**
 * BulkProcessManagementContainer component
 * @extends {React.Component}
 */
export class BulkProcessManagementContainer extends React.Component {
    /**
    * Creates a new BulkReportManagementContainer
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);
        this.handleCalendarYearSelection = this.handleCalendarYearSelection.bind(this);
        this.handleReportPeriodSelection = this.handleReportPeriodSelection.bind(this);
        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.setGridColumnState = this.setGridColumnState.bind(this);
        this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
        this.toggleShowSignOffModal = this.toggleShowSignOffModal.bind(this);
        this.handleSignOffSave = this.handleSignOffSave.bind(this);
        this.toggleServiceScopeModal = this.toggleServiceScopeModal.bind(this);
        this.handleServiceScopeSaveAction = this.handleServiceScopeSaveAction.bind(this);
        this.handleServiceScopeCancelAction = this.handleServiceScopeCancelAction.bind(this);
        this.handleServiceSelected = this.handleServiceSelected.bind(this);
        this.toggleShowUpdateServiceScopeInfo=this.toggleShowUpdateServiceScopeInfo.bind(this);

        this.state = {
            calendarYears: [],
            selectedCalanderYear: null,
            selectedReportPeriod: Constants.reportPeriodQuarter[0].value,
            bulkProcessManagementData: [],
            gridCurrentState: [],
            gridColumnState: [],
            showSignOffModal: false,
            showServiceScopeModal: false,
            selectedServices: Constants.defaultSelectedServiceScope,
            selectedSignOffStatus: null,
            resetSignOffRadioButtons: false,
            bulkProcessActionId: null,
            diagnosticMessage: '',
            showUpdateServiceScopeInfo: false,
        };
    }

    /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
    componentDidMount() {
        // Set the calendar years
        this.setState({ bulkProcessManagementData: this.props.bulkProcessManagementData }, this.setCalendarYears);
        this.setGridColumnState(); // Set default state for grid columns
        if (this.props.reitActions) {
            this.props.reitActions.fetchReitLookupTypes();
        }
    }

    /**
     * Invoked when a new props is recived
     * @returns {undefined}
     */
    
    componentDidUpdate(prevProps) {
        if (
            this.props.bulkProcessManagementData &&
            this.props.bulkProcessManagementData !== prevProps.bulkProcessManagementData
        ) {
            this.setState({ bulkProcessManagementData: this.props.bulkProcessManagementData });
        }
    }

    /**
     * Invoked immediately before unmounting occurs
     * @returns {undefined}
     */
    componentWillUnmount() {
        // On unmount, clear the state to conserve memory
        this.setState({ bulkProcessManagementData: [] });

        // Clearing the redux state for bulk process management data
        this.props.bulkProcessManagementActions.fetchBulkProcessManagementData();
    }

    setCalendarYears() {

        // Get the current year
        // Fetch calendar years for current client
        const currentYear = new Date().getFullYear();

        // Calculate the previous year
        const previousYear = currentYear - 1;

        // Create an array containing the current and previous years
        const yearsArray = [currentYear, previousYear];

        this.setState({
            calendarYears: yearsArray,
            selectedCalanderYear: yearsArray[0]
        }, this.getBulkProcessManagementData);
    }

    handleCalendarYearSelection(calendarYear) {
        this.setState({
            selectedCalanderYear: calendarYear
        }, this.getBulkProcessManagementData);
    }

    handleReportPeriodSelection(reportPeriod) {
        this.setState({
            selectedReportPeriod: reportPeriod
        }, this.getBulkProcessManagementData);
    }

    getBulkProcessManagementData() {
        this.props.bulkProcessManagementActions.fetchBulkProcessManagementData(
            this.props.clientId,
            this.state.selectedCalanderYear,
            this.state.selectedReportPeriod
        );
    }

    /**
    * Set AG-Grid column state   
    * @returns {undefined}
    */
    setGridColumnState() {
        var columnState = localStorage.getItem('REITSuiteBulkProcessManagementColumnState');
        if (columnState) {
            this.setState({ gridColumnState: JSON.parse(columnState) });
        }
        else {
            this.setState({ gridColumnState: [{ colId: '-1' }] });
        }
    }

    /**
    * Handle Grid column state chagne
    * @returns {undefined}
    */
    handleGridColumnStateChange(columnState) {
        this.setState({ gridColumnState: columnState });
    }

    toggleShowSignOffModal() {
        this.setState({
            showSignOffModal: !this.state.showSignOffModal, resetSignOffRadioButtons: !this.state.resetSignOffRadioButtons
        });
    }

    toggleShowUpdateServiceScopeInfo(){
        this.setState({
            showUpdateServiceScopeInfo: !this.state.showUpdateServiceScopeInfo
        });
    }
    handleSignOffSave(selectedOption) {
        // Set the Bulk Process Action ID to 1
        const requestedAction= Constants.updateSignOffStatusAction.find(x=>x.value==selectedOption).action;
        this.setState({ bulkProcessActionId: selectedOption,selectedServices:null,selectedSignOffStatus : requestedAction }, this.initiateBulkProcess);
    }

    handleServiceScopeSaveAction() {
        // Set the Bulk Process Action ID to 1
        this.setState({ bulkProcessActionId: 1 }, this.initiateBulkProcess);
    }

    handleServiceScopeCancelAction() {
        this.setState({ selectedServices: Constants.defaultSelectedServiceScope });
        this.toggleServiceScopeModal();
    }

    toggleServiceScopeModal() {
        const currentUserIsEngagementUser= authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId);
        
        if(currentUserIsEngagementUser  && this.hasCompleteOrClosedToReportingReits()){
           this.toggleShowUpdateServiceScopeInfo();
            return;
        }
        this.setState({ selectedServices: Constants.defaultSelectedServiceScope });
        this.setState({
            showServiceScopeModal: !this.state.showServiceScopeModal
        });
    }

    handleServiceSelected(service, isSelected) {
        if (isSelected) {
            const selectedServicesSet = new Set([...this.state.selectedServices, service]);
            this.setState({ selectedServices: [...selectedServicesSet] });
        } else {
            const selectedServices = this.state.selectedServices.filter(s => s != service);
            this.setState({ selectedServices });
        }
    }

    handleGridChanges(gridRowData) {
        let rowData = [];
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
            return {
                reitID: x.reitid,
                isReitAvailableForSelection: x.isReitAvailableForSelection,
                reitSelection: x.isReitAvailableForSelection ? x.reitSelection : false,
                assetTesting: x.assetTesting,
                incomeTesting: x.incomeTesting,
                distributionTesting: x.distributionTesting,
                psq: x.psq,
                arqc: x.arqc,
                leaseReview: x.leaseReview,
                signOffStatus: x.signOffStatus,
            }
        });

        this.setState({ gridCurrentState: rowData });
    }

    initiateBulkProcess() {
        const request = {
            clientId: this.props.clientId,
            calendarYear: this.state.selectedCalanderYear,
            quarterDescription: this.state.selectedReportPeriod,
            reitIds: this.getSelectedReits(),
            bulkProcessActionId: this.state.bulkProcessActionId,
            diagnosticMessage: this.state.diagnosticMessage,
            bulkProcessRequestJSON: this.getBulkProcessRequestJSON(),
        };

        const currentGridState = [...this.state.gridCurrentState];
        const currentBulkProcessManagementDataState = [...this.state.bulkProcessManagementData];

        this.updateCheckboxsState(request.reitIds);

        this.props.bulkProcessManagementActions.initiateBulkProcess(request)
            .then((isErrored) => {
                if (isErrored) {
                    this.setState({ currentGridState: currentGridState, bulkProcessManagementData: currentBulkProcessManagementDataState });
                }
            })
            .catch((isErrored) => {
                if (isErrored) {
                    this.setState({ currentGridState: currentGridState, bulkProcessManagementData: currentBulkProcessManagementDataState });
                }
            });

        this.resetModalsToggleState();
    }

    updateCheckboxsState(reitsToBeUpdated) {
        const rowData = this.state.gridCurrentState.map(row => {
            if (reitsToBeUpdated.find(r => r === row.reitID)) {
                return {
                    ...row,
                    isReitAvailableForSelection: false,
                    reitSelection: false,
                };
            } else {
                return row;
            }
        });

        const updatedBulkProcessManagementData = this.state.bulkProcessManagementData.map(data => {
            if (reitsToBeUpdated.find(r => r === data.reitid)) {
                return {
                    ...data,
                    isReitAvailableForSelection: false,
                    reitSelection: false,
                };
            } else {
                return data;
            }
        });

        this.props.bulkProcessManagementActions.dispatchBulkProcessData(updatedBulkProcessManagementData, actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_REFRESH);
        this.setState({ gridCurrentState: rowData });
    }

    resetModalsToggleState() {
        this.setState({ showServiceScopeModal: false, showSignOffModal: false });
    }

    getSelectedReits() {
        return this.state.gridCurrentState.filter(r => r.reitSelection).map(r => r.reitID);
    }

    hasCompleteOrClosedToReportingReits() {
        return this.state.gridCurrentState.some(r => (r.signOffStatus == 'Complete' || r.signOffStatus == 'Reporting Closed' || r.signOffStatus == 'Testing Closed') && r.reitSelection);
       
    }

    getBulkProcessRequestJSON() {
        
        return {
            services: this.state.bulkProcessActionId == 1 && !this.state.selectedServices.length? Constants.emptySelectedServiceScope: this.state.selectedServices,
            requestedSignOffStatus: this.state.selectedSignOffStatus,
        };
    }

    /**
    * Render a React element
    * @returns {Object} A reference to the component
    */
    render() {
        return (
            <>
                <BulkProcessManagementHeader
                    calendarYears={this.state.calendarYears}
                    handleCalendarYearSelection={this.handleCalendarYearSelection}
                    handleReportPeriodSelection={this.handleReportPeriodSelection}
                    rowsAffected={this.state.rowsAffected}
                    gridCurrentState={this.state.gridCurrentState}
                    toggleShowSignOffModal={this.toggleShowSignOffModal}
                    toggleServiceScopeModal={this.toggleServiceScopeModal}
                    isAuditOnlyClient={this.props.client.isAudit}
                />
                <BulkProcessManagementTable
                    bulkProcessManagementData={this.state.bulkProcessManagementData}
                    handleGridChanges={this.handleGridChanges}
                    gridColumnState={this.state.gridColumnState}
                    handleGridColumnStateChange={this.handleGridColumnStateChange}
                />
                {(this.props.bulkProcessManagementData == null || this.props.bulkProcessManagementData.length === 0) &&
                    <div>No REITs found for the selected calendar year and the report period.</div>}
                <BulkProcessSignOffModal
                    showSignOffModal={this.state.showSignOffModal}
                    toggleShowSignOffModal={this.toggleShowSignOffModal}
                    handleSignOffSave={this.handleSignOffSave}
                    resetSignOffRadioButtons={this.state.resetSignOffRadioButtons}
                    currentUserIsEngagementUser={authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)}
                />
                <ScopedServicesModal
                    services={this.state.selectedServices}
                    showServicesModal={this.state.showServiceScopeModal}
                    handleSave={this.handleServiceScopeSaveAction}
                    handleCancel={this.handleServiceScopeCancelAction}
                    lookupTypes={this.props.lookupTypes}
                    handleServiceSelected={this.handleServiceSelected}
                    isBulkProcess={true}
                />
                  <BulkProcessUpdateServiceAlertModal
                    showUpdateServiceScopeInfo={this.state.showUpdateServiceScopeInfo}
                    toggleShowUpdateServiceScopeInfo={this.toggleShowUpdateServiceScopeInfo}
                />
            </>
        );
    }
}

BulkProcessManagementContainer.propTypes = {
    clientId: PropTypes.number,
    calendarYears: PropTypes.array,
    bulkProcessManagementData: PropTypes.array,
    reitActions: PropTypes.object,
    currentUserIsEngagementUser: PropTypes.bool.isRequired,
    currentUserAuthorizations: PropTypes.array,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: Number.parseInt(ownProps.router.params.clientId),
        bulkProcessManagementData: state.bulkProcessManagementData,
        lookupTypes: state.lookupTypes,
        currentUserAuthorizations: state.currentUserAuthorizations,
        client: state.client
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        bulkProcessManagementActions: bindActionCreators(bulkProcessManagementActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch),
        authActions:bindActionCreators(reitActions, dispatch)
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        reduxForm({ form: "bulkProcessManagementForm", enableReinitialize: true })(BulkProcessManagementContainer)
    )
);
