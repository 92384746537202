import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReitsExternal from "../../components/reits/ReitsExternal";
import * as reitActions from "../../actions/reitActions";
import { withRouter } from "../../common/withRouter";

/**
 * ReitsExternalPage container component
 * @extends {React.Component}
 */
export class ReitsExternalPage extends React.Component {
    /**
        * Creates a new ReitsExternalPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);
        this.state = {
            reitsAreLoaded: false
        };
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchExternalReits(this.props.clientId)
            .then(() => this.setState({ reitsAreLoaded: true }));
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <ReitsExternal externalReits={this.props.externalReits} reitsAreLoaded={this.state.reitsAreLoaded} clientId={this.props.clientId} clientName={this.props.client.legalName}/>
            </React.Fragment>
        );
    }
}

ReitsExternalPage.propTypes = {
    clientId: PropTypes.string.isRequired,
    actions: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: ownProps.router.params.clientId,
        externalReits: state.externalReits,
        client: state.client
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(reitActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReitsExternalPage));