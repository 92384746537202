import { pendingTask, begin, end } from '../constants/pendingTask';
import { stopSubmit } from 'redux-form';
import * as actions from './actionTypes';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Clears the error from state.
 * @returns {any} A function that dispatches an action
 */
export function clearErrorResponse() {
    return function (dispatch) {
        dispatch({ type: actions.CLEAR_ERROR_RESPONSE });
    };
}

/**
 * Change the dismissUsersMessage state.
 * @returns {any} A function that dispatches an action
 */
export function dismissUsersMessage() {
    return function (dispatch) {
        dispatch({ type: actions.CLEAR_USERS_MESSAGE });
    };
}

/**
 * Increases the running task count (for the loading spinner)
 * @returns {any} A function that dispatches an action
 */
export function beginTask() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    };
}

/**
 * Decreases the running task count (for the loading spinner)
 * @returns {any} A function that dispatches an action
 */
export function endTask() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
    };
}

/**
 * Clears the form of any errors.
 * @param {string} formName The name of the form.
 * @returns {any} A function that dispatches an action
 */
export function clearFormErrors(formName) {
    return function (dispatch) {
        dispatch(stopSubmit(formName, {}));
    };
}

/**
 * Dispatches an error and decreases the running task count.
 * @param {any} error The error to dispatch.
 * @returns {any} A function that dispatches an action
 */
export function dispatchErrorAndEndTask(error) {
    return function (dispatch) {
        actionHelpers.dispatchErrorAndEndTask(dispatch, actions.ERROR, null, error);
    };
}