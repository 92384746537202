import * as actions from "../actions/actionTypes";
import initialState from './initialState';

/**
 * This reducer handles any action that has an dismissUserMessage property and resets flag for messsage display
 * @param {Object} [state] The errorResponse currently in the store, or the initial state of errorResponse
 * @param {Object} action The action to be handled
 * @returns {Object} The errorResponse
 */
export default function usersMessage(state = initialState.dismissUsersMessage, action) {

    if (action.type === actions.CLEAR_USERS_MESSAGE) {
        return true;
    }
    return state;
}