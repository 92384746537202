import * as actions from "../actions/actionTypes";
import initialState from "./initialState";
//  
// /**
//  * This reducer handles the SET_THEME action and sets data in the store
//  * @param {Object} [state] The theme data currently in the store, or the initial state of theme data
//  * @param {Object} action The action to be handled
//  * @returns {Object} The theme data
//  */
export default function themeServices(state = initialState.motifThemes, action) {
    switch (action.type) {
        case actions.MOTIF_THEME:
            return action.motifThemes;
        default:
            return state;
    }
}
