import axios from 'axios';

/**
 * Check whether the user who has edited the template is active 
 * @param {clientId} number e.g. 21
 * @param {checklistTemplateId} number e.g. 101
 */
export const isUserActiveOfEditedTemplate = function (clientId, checklistTemplateId) {

    return axios
        .post(
            `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/isUserActiveOfEditedTemplate`,
            {
                clientId: clientId,
                checklistTemplateId: checklistTemplateId,
            },
            {
                headers: {},
            },
        )
        .then((response) => {
            return response ? response.data : null;
        });
}