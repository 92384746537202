import React from 'react';
import PropTypes from 'prop-types';

import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from '@ey-xd/motif-react';

const ClientUnsignoffModal = ({
  showClientUnsignoffModal,
  modalTitle,
  modalContent,
  selectedChecklists,
  toggleShowClientUnsignoffModal,
  handleClientUnsignoff,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close client unsign off modal',
      'aria-label': 'Close client unsign off modal',
    'data-testid': 'close',
  };

    const showHideClientUnsignoffModal = () => {
        toggleShowClientUnsignoffModal();
  };

  return (
    <MotifModal
      variant="alt"
    show={showClientUnsignoffModal}
    onClose={showHideClientUnsignoffModal}
      size="xl"
      data-testid="ClientUnsignoffModal"
    >
      <MotifModalHeader
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <span className="ml-0">{modalTitle}</span>
        </HeaderWrapper>
      </MotifModalHeader>
      <MotifModalBody>
        <span className="checklistActionMsg">{modalContent}</span>
        <br />
        <span className="checklistActionMsg">You have selected {selectedChecklists?.length} checklist(s).</span>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          variant="secondary"
          className="right"
          data-testid="Cancel"
          type="button"
                  onClick={showHideClientUnsignoffModal}
        >
          Cancel
        </MotifButton>
        <MotifButton
          className="right"
          data-testid="Submit"
          type="button"
                  onClick={() => handleClientUnsignoff()}
        >
          Submit
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

ClientUnsignoffModal.propTypes = {
  showClientUnsignoffModal: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  selectedChecklists: PropTypes.array,
  toggleShowClientUnsignoffModal: PropTypes.bool,
  handleClientUnsignoff: PropTypes.func,

};

export default ClientUnsignoffModal;
