import React from "react";
import PropTypes from "prop-types";
import ChecklistListRow from "./ChecklistListRow";

const ChecklistList = ({ checklistList, clientId, handleRevertChecklistState }) => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    <section className="table-responsive rtable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Period</th>
                                    <th>Checklist</th>
                                    <th style={{ textAlign: "center" }}>Revert State</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checklistList.map(item => <ChecklistListRow key={item.arqcCheckListId} checklistRow={item} clientId={clientId} handleRevertChecklistState={handleRevertChecklistState} />)}
                            </tbody>
                        </table>
                        {(checklistList && checklistList.length === 0 && <div><h5>No Checklists are currently available for this REIT.</h5></div>)
                            || null}
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};

ChecklistList.propTypes = {
    checklistList: PropTypes.array.isRequired
};

export default ChecklistList;