import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_TB_ITEMS_SUCCESS action and sets the TB ITEMs in the store
 * @param {Object} [state] The items currently in the store, or the initial state of the TB items
 * @param {Object} action The action to be handled
 * @returns {Object} The summary
 */
export default function trialBalanceItemsReducer(state = initialState.trialBalanceItems, action) {
    if (action.type === actions.LOAD_TB_ITEMS_SUCCESS) {
        return action.trialBalanceItems;
    } else if (action.type == actions.UNLOAD_PERIOD || action.type === actions.LOAD_TB_ITEMS_FAILURE) {
        return initialState.trialBalanceItems;
    } else {
        return state;
    }
}