import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { withMsal } from "@azure/msal-react";

/**
 * CallbackPage container component
 * @extends {React.Component}
 */
class CallbackPage extends React.Component {
    /**
     * Creates a new CallbackPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.state = {
            message: "Redirecting..."
        };
    }

    render() {
        return (
            <react-fragment>
            <div className="m-3">
                <h5>{this.state.message}</h5>
            </div>
            </react-fragment>
        );
    }
}

CallbackPage.contextTypes = {
    router: PropTypes.object
};

export default connect()(withMsal(CallbackPage));