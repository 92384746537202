import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import { MotifButton, MotifLabel, MotifTooltip } from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';
import * as formHelpers from '../../scripts/formHelpers';

const BulkRollForwardHeader = ({
    bulkRollForwardFormValues,
    enableSubmit,
    handleSubmit
}) => {
    useEffect(() => {
        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", 'type', 'button');
    });

    const quarterOptionValues = [
        "Quarter 1",
        "Quarter 2",
        "Quarter 3",
        "Quarter 4",
    ];

    const quarterOptions = quarterOptionValues.map((quarterOption) => {
        return {
            key: quarterOption,
            text: `Q${quarterOption.slice(-1)}`,
            value: `Q${quarterOption.slice(-1)}`,
        };
    });

    const currentYear = new Date();
    let yearOptionValues = [];

    for (let i = 2000; i < currentYear.getFullYear() + 11; i++) {
        yearOptionValues.push(i.toString());
    }

    const yearOptions = yearOptionValues.map((yearOption) => {
        return { key: yearOption, text: yearOption, value: yearOption };
    });

    return (
        <>
            {/* Header area */}
            <h2>Bulk Roll Forward</h2>
            <div style={{ minHeight: '24px' }}></div>
            {/* Form area */}
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <MotifLabel className='mt-auto mb-auto mr-2' style={{ flex: '0.7' }}><strong>Roll Forward To:</strong></MotifLabel>
                    <div className='mt-auto ml-0 mr-2' style={{ flex: '1.3' }}>
                        <Field
                            name="rollForwardToSelectionType"
                            component={formHelpers.renderField}
                            type="radio"
                            value="nextAvailablePeriod"
                            label="Next Available Report Period"
                            dataTestId="radioRollForwardToNextAvailablePeriod"
                        />
                    </div>
                    <div className='mt-auto mr-2' style={{ flex: '1' }}>
                        <Field
                            name="rollForwardToSelectionType"
                            component={formHelpers.renderField}
                            type="radio"
                            value="customPeriod"
                            label="Select Report Period"
                            dataTestId="radioRollForwardToCustomPeriod"
                        />
                    </div>
                    <div className='mr-3 mt-auto' style={{ flex: '1' }}>
                        <Field
                            name="quarterSelection"
                            label="Quarter"
                            options={quarterOptions}
                            component={formHelpers.SingleSelectComponent}
                            disabled={bulkRollForwardFormValues?.rollForwardToSelectionType !== 'customPeriod'}
                            dataTestId="selectQuarter"
                        />
                    </div>
                    <div className='mt-auto' style={{ flex: '1' }}>
                        <Field
                            name="yearSelection"
                            label="Year"
                            options={yearOptions}
                            component={formHelpers.SingleSelectComponent}
                            disabled={bulkRollForwardFormValues?.rollForwardToSelectionType !== 'customPeriod'}
                            dataTestId="selectYear"
                        />
                    </div>
                    <div className='mb-auto' style={{ flex: '2' }}>
                        <MotifButton
                            className='ml-auto'
                            disabled={!enableSubmit}
                        >
                            {
                                enableSubmit && <span>Roll Forward REIT(s)</span>
                            }
                            {
                                !enableSubmit &&
                                <MotifTooltip
                                    placement="auto"
                                    trigger={<span>Roll Forward REIT(s)</span>}
                                >
                                    You have to select the "Roll Forward To" option and a REIT in order to perform this action.
                                </MotifTooltip>
                            }
                        </MotifButton>
                    </div>
                </div>
            </form >
        </>
    );
}

BulkRollForwardHeader.propTypes = {
    enableSubmit: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

export default connect((state) => ({
    bulkRollForwardFormValues: getFormValues('bulkRollForwardForm')(state),
}))(
    reduxForm({ form: 'bulkRollForwardForm' })(
        BulkRollForwardHeader
    )
);