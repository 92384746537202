import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS action and sets the previous PSQ checklist in the store
 * @param {Object} [state] The previous PSQ checklist currently in the store, or the initial state of the previous PSQ checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function psqPreviousChecklistReducer(state = initialState.previousPsqChecklist, action) {
    switch (action.type) {
        case actions.LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS:
            if (action.psqChecklist) {
                return action.psqChecklist;
            } else {
                return null;
            }
        case actions.LOAD_PREVIOUS_PSQ_CHECKLIST_FAILURE:
        case actions.UNLOAD_TESTS:
            return null; // Clear previous checklist on failure
        default:
            return state;
    }
}