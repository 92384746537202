import React from 'react';
import {
    MotifHeader,
    MotifIcon,
    MotifHeaderLogo
} from "@ey-xd/motif-react";

const ExternalNavBar = () => {
  return (
    //   <MotifHeader style={{ backgroundColor: '#1b1a24'}}
      <MotifHeader
                  fixed
                  logo={
                      <MotifHeaderLogo >
                          <a href="/" aria-label="EY Logo">
                              <MotifIcon 
                                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTYxcHgiIGhlaWdodD0iNTc0cHgiIHZpZXdCb3g9IjAgMCA1NjEgNTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2My4xICg5MjQ1MikgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+RVlfTG9nb19CZWFtX1doaXRlX1llbGxvd19DX0NNWUs8L3RpdGxlPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIwLjA2IDAuOTc0IDU2MC4zOTQgMC45NzQgNTYwLjM5NCA1NzQgMC4wNiA1NzQiPjwvcG9seWdvbj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJFWV9Mb2dvX0JlYW1fZGVmYXVsdExvZ29XaGl0ZV9ZZWxsb3dfQ19DTVlLIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWxpbmUgaWQ9IkZpbGwtMSIgZmlsbD0iI0ZGRkZGRSIgcG9pbnRzPSI4OS45MTYgNDYyLjAzMSAxOTIuNDE3IDQ2Mi4wMzEgMTkyLjQxNyA0MDIuNTExIDg5LjkxNiA0MDIuNTExIDg5LjkxNiAzNTUuNzM0IDIwMy4zMDQgMzU1LjczNCAxNjUuNjg1IDI5MC41MzcgNC45MDMgMjkwLjUzNyA0LjkwMyA1NzQgMjMxLjY2NyA1NzQgMjMxLjY2NyA1MDguODAzIDg5LjkxNiA1MDguODAzIDg5LjkxNiA0NjIuMDMxIj48L3BvbHlsaW5lPgogICAgICAgIDxnIGlkPSJHcm91cC01Ij4KICAgICAgICAgICAgPG1hc2sgaWQ9Im1hc2stMiIgZmlsbD0id2hpdGUiPgogICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgPC9tYXNrPgogICAgICAgICAgICA8ZyBpZD0iQ2xpcC0zIj48L2c+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iRmlsbC0yIiBmaWxsPSIjRkZGRkZFIiBtYXNrPSJ1cmwoI21hc2stMikiIHBvaW50cz0iMzgyLjU4OSAyOTAuNTM3IDMzNC40MzkgMzgzLjA0MiAyODYuNDA1IDI5MC41MzcgMTkyLjQxMSAyOTAuNTM3IDI5MS40NTIgNDYyLjAzMSAyOTEuNDUyIDU3NCAzNzYuMjA2IDU3NCAzNzYuMjA2IDQ2Mi4wMzEgNDc1LjM3OCAyOTAuNTM3IDM4Mi41ODkgMjkwLjUzNyI+PC9wb2x5bGluZT4KICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJGaWxsLTQiIGZpbGw9IiNGQkQwMjAiIG1hc2s9InVybCgjbWFzay0yKSIgcG9pbnRzPSI1NjAuMzk0IDAuOTc0IDAgMjA1LjUwNSA1NjAuMzk0IDEwNi41MTEgNTYwLjM5NCAwLjk3NCI+PC9wb2x5bGluZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                              />
                              <span className="hide-for-accessibility">Home</span>
                          </a>
                      </MotifHeaderLogo>
                  }
                  appHeaderName="REITSuite"               
              >
              </MotifHeader>
  );
};

export default ExternalNavBar;
