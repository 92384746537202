import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormValues, getFormSyncErrors, getFormSubmitErrors, SubmissionError, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import * as clientActions from "../../actions/clientActions";
import * as authActions from "../../actions/authActions";
import { arraysEqual } from "../../scripts/arrayHelpers";
import AssignReitsTable from "../../components/customFdrTemplate/AssignReitsTable";
import * as customFdrTemplateActions from "../../actions/customFdrTemplateActions";
import { MotifTabNavigation, MotifTabControl } from "@ey-xd/motif-react";
import { Link } from 'react-router-dom';
import { withRouter } from "../../common/withRouter";

/**
 * DefaultFdrTemplatesContainer container component
 * @extends {React.Component}
 */
class DefaultFdrTemplatesContainer extends React.Component {
    /**
    * Creates a new DefaultFdrTemplatesContainer
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.handleSaveDefaultFdrTemplates = this.handleSaveDefaultFdrTemplates.bind(this);
        this.updateInitialValues = this.updateInitialValues.bind(this);
        this.handleCopyField = this.handleCopyField.bind(this);
        this.handlePasteField = this.handlePasteField.bind(this);
        this.handlePrePopulateCheckAll = this.handlePrePopulateCheckAll.bind(this);
        this.filterPsqArqc = this.filterPsqArqc.bind(this);
        this.filterDisabled = this.filterDisabled.bind(this);
        this.handleEscape = this.handleEscape.bind(this);

        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            savedInitialValues: {},
            saved: false,
            copiedFieldName: null,
            psqArqc: 'ALL', // the PSQ/ARQC filter field is not part of redux-form, so we do not affect the dirty/pristine flags when changing this field
            filteredCalendar: [],
            quarterDateError: null,
            dateModalQuarter: '',
            dateModalOpen: false,
            dateModalQuarterValue: null,
            prePopulateCheckAll: true,
            tabBar1: 0,
            customQuestionnaireTemplates: []
        };

        // Generate calendar years starting in 2019
        // and going up to the current year
        this.calendarYears = [2019];
        const currentYear = parseInt(new Date().getFullYear());

        while (this.calendarYears.slice(-1) < currentYear) {
            this.calendarYears.push(parseInt(this.calendarYears.slice(-1)) + 1);
        }
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        // this.props.customQuestionActions.fetchCustomQuestionnaireTemplates(this.props.clientId).then(() => {
        //     const list = Object.assign([], this.props.customQuestionnaireTemplates);
        //     this.setState({ customQuestionnaireTemplates: list });
        // });
        this.props.customFdrTemplateActions
            .fetchDefaultFdrTemplates(this.props.clientId)
            .then(() => {
                this.props.customFdrTemplateActions.fetchCustomFdrTemplates(this.props.clientId).then(() => {
                    this.updateInitialValues();
                });
            });

        //Add Key Down event listner
        document.addEventListener("keydown", this.handleEscape, false);
    }

    /**
     * Invoked immediately before unmounting occurs
     * @returns {undefined}
     */
    componentWillUnmount() {
        // On unmount, clear the calendar data from state to conserve memory
        // Calling this action with no argument will clear from redux-state
        //this.props.checklistActions.fetchChecklistDeliveryCalendar();

        // Also clear the local state
        this.setState({ filteredCalendar: [] });

        //Remove Key Down event listner
        document.removeEventListener("keydown", this.handleEscape, false);
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.customQuestionnaireTemplates !== this.props.customQuestionnaireTemplates && this.props.customQuestionnaireTemplates) {
        //     const list = Object.assign([], this.props.customQuestionnaireTemplates);
        //     this.setState({ customQuestionnaireTemplates: list });
        // }
    }

    /**
     * Handle Escape key press event
     */
    handleEscape(event) {
        if (event.key === "Escape") {
            //Reset copiedFieldName to null 
            this.setState({ copiedFieldName: null });
        }
    }

    updateInitialValues() {
        // We just received new default FDR template data from API.
        // New data should be in this.props.customFdrTemplate.defaultFdrTemplates
        // Let's initialize the form values.
        const initialValues = {
            clientId: this.props.clientId
        };

        // Loop through all records to set field values for each quarter (default to -1 for standard template)
        this.props.customFdrTemplate.defaultFdrTemplates.forEach(dft => {
            initialValues[`q1_${dft.reitId}`] = dft.defaultFdrTemplateQ1 ? dft.defaultFdrTemplateQ1 : -1;
            initialValues[`q2_${dft.reitId}`] = dft.defaultFdrTemplateQ2 ? dft.defaultFdrTemplateQ2 : -1;
            initialValues[`q3_${dft.reitId}`] = dft.defaultFdrTemplateQ3 ? dft.defaultFdrTemplateQ3 : -1;
            initialValues[`q4_${dft.reitId}`] = dft.defaultFdrTemplateQ4 ? dft.defaultFdrTemplateQ4 : -1;
        });

        // initialize is a function that is injected from redux-form
        // this is how it can keep up with which fields are dirty/modified
        this.props.dispatch(this.props.initialize(
            initialValues,
            { options: { updateUnregisteredFields: true } }
        ));

        // save the initial values to state, so we can compare later and only submit the changed records
        // null out the copiedEmailFieldName
        this.setState({
            savedInitialValues: initialValues,
            copiedFieldName: null
        });
    }

    /**
     * Save the default fdr template data
     * @returns {undefined}
     */
    handleSaveDefaultFdrTemplates(values, dispatch, props) {
        // Get initial values from state
        const initialValues = this.state.savedInitialValues;

        // Find the dirty/modified fields only
        const dirty_fields_only = Object.keys(values).filter((key) => {
            if (Array.isArray(initialValues[key]) || Array.isArray(values[key])) {
                return !arraysEqual(values[key] || [], initialValues[key] || [])
            } else {
                return !(values[key] == initialValues[key]);
            }
        });

        // get just the id portion from the field name
        // for example, q1_123 turns into 123 (which is the REIT ID)
        let dirtyIds = dirty_fields_only.map(f => {
            const split_ids = f.split('_');
            return split_ids[1];
        });
        // remove duplicate ids
        dirtyIds = [...new Set(dirtyIds)];

        // Now build an array of the data we need to send to the save default fdr templates API call with just the modified records
        // Flip the -1 values into NULL
        const requestData = dirtyIds.map(id => {
            return {
                reitId: parseInt(id),
                defaultFdrTemplateQ1: values[`q1_${id}`] == -1 ? null : values[`q1_${id}`],
                defaultFdrTemplateQ2: values[`q2_${id}`] == -1 ? null : values[`q2_${id}`],
                defaultFdrTemplateQ3: values[`q3_${id}`] == -1 ? null : values[`q3_${id}`],
                defaultFdrTemplateQ4: values[`q4_${id}`] == -1 ? null : values[`q4_${id}`],
            };
        });

        this.props.customFdrTemplateActions.saveDefaultFdrTemplates(requestData).then(() => {
            this.props.customFdrTemplateActions
                .fetchDefaultFdrTemplates(this.props.clientId)
                .then(() => {
                    this.props.customFdrTemplateActions.fetchCustomFdrTemplates(this.props.clientId).then(() => {
                        this.updateInitialValues();
                        //Reset copiedFieldName to null 
                        this.setState({ saved: true, copiedFieldName: null });
                        setTimeout(() => this.setState({ saved: false }), 2500);
                    });
                });
        });
    }

    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    //Set current tab index
    handleClick(index) {
        this.setTabBar1(index);
    }

    handleCopyField(fieldName) {
        this.setState({ copiedFieldName: fieldName });
    }

    // Only pass one value or the other
    handlePasteField(singleFieldName, allFieldsPrefix) {
        if (allFieldsPrefix) {
            const fieldsToUpdate = Object
                .keys(this.props.defaultFdrTemplatesValues)
                .filter(key => key.includes(allFieldsPrefix));
            // .filter(key => this.filterDisabled(key));

            fieldsToUpdate.forEach(fieldName => {
                if (this.state.copiedFieldName.includes('col_')) {
                    const copiedQuarterPrefix = this.state.copiedFieldName.replace('col_', '');
                    const copiedField = `${copiedQuarterPrefix}${fieldName.substring(2)}`;
                    this.props.dispatch(this.props.change(fieldName, this.props.defaultFdrTemplatesValues[copiedField]));
                } else {
                    this.props.dispatch(this.props.change(fieldName, this.props.defaultFdrTemplatesValues[this.state.copiedFieldName]));
                }
            });
        } else {
            if (singleFieldName.includes('_') && !singleFieldName.includes('col_')) {
                // Trying to paste into a single field
                this.props.dispatch(this.props.change(singleFieldName, this.props.defaultFdrTemplatesValues[this.state.copiedFieldName]));
            } else if (!singleFieldName.includes('_')) {
                // Trying to paste into entire row
                // Now check if entire row is copied or single field
                if (this.state.copiedFieldName.includes('_')) {
                    // Paste same value into all fields for the reit row
                    const fieldsToUpdate = Object
                        .keys(this.props.defaultFdrTemplatesValues)
                        .filter(key => key.includes(`_${singleFieldName}`));

                    fieldsToUpdate.forEach(fieldName =>
                        this.props.dispatch(this.props.change(fieldName, this.props.defaultFdrTemplatesValues[this.state.copiedFieldName]))
                    );
                } else if (!this.state.copiedFieldName.includes('_')) {
                    this.props.dispatch(this.props.change(`q1_${singleFieldName}`, this.props.defaultFdrTemplatesValues[`q1_${this.state.copiedFieldName}`]));
                    this.props.dispatch(this.props.change(`q2_${singleFieldName}`, this.props.defaultFdrTemplatesValues[`q2_${this.state.copiedFieldName}`]));
                    this.props.dispatch(this.props.change(`q3_${singleFieldName}`, this.props.defaultFdrTemplatesValues[`q3_${this.state.copiedFieldName}`]));
                    this.props.dispatch(this.props.change(`q4_${singleFieldName}`, this.props.defaultFdrTemplatesValues[`q4_${this.state.copiedFieldName}`]));
                }
            }
        }
    }

    handlePrePopulateCheckAll() {
        const allFieldsPrefix = 'prePopulateAnswers_';

        // Take into account the PSQ/ARQC filter to only apply updates to ALL, PSQ, or ARQC
        const fieldsToUpdate = Object.keys(this.props.checklistDeliveryCalendarValues).filter(key => this.filterPsqArqc(key, allFieldsPrefix));

        fieldsToUpdate.filter(this.filterDisabled).forEach(fieldName =>
            this.props.dispatch(this.props.change(fieldName, this.state.prePopulateCheckAll))
        );

        this.setState({ prePopulateCheckAll: !this.state.prePopulateCheckAll });
    }

    filterPsqArqc(key, allFieldsPrefix) {
        const { psqArqc } = this.state;

        return psqArqc === 'ALL' ? key.includes(allFieldsPrefix) : // ALL records
            psqArqc === 'ARQC' ? key.includes(allFieldsPrefix) && key.includes('_arqc') : // only ARQC records
                key.includes(allFieldsPrefix) && !key.includes('_arqc'); // only PSQ (exclude ARQC records)
    }

    filterDisabled(key) {
        // Find the calendar entry and see if there is not a disabledReason
        // We don't want to mass-update the disabled records
        const split_id = key.split('_');
        const isProperty = split_id[2] !== 'arqc';

        return !this.props.checklistDeliveryCalendar.filter(cdc => {
            return cdc.reitId == parseInt(split_id[1])
                && cdc.propertyTrackingId == (isProperty ? split_id[2] : null)
                && (cdc.propertyType == (isProperty ? split_id[3] : null) // handle null propertyType
                    || cdc.propertyType == (isProperty ? split_id[3] : '')) // handle blank string propertyType
        })[0].disabledReason;
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '250px',
                textAlign: 'center'
            }
        };

        let customTemplateOptions = this.props.customFdrTemplate ? this.props.customFdrTemplate.templates.map(t => {
            return { key: t.fdrTemplateID, value: t.fdrTemplateID, text: t.fdrTemplateName };
        }) : [];
        customTemplateOptions.sort(function (a, b) { return a.fdrTemplateName - b.fdrTemplateName });
        customTemplateOptions.unshift({ key: -1, text: 'Standard Template', value: -1 });

        return (
            <React.Fragment>
                <h2>Custom FDR Template</h2>
                <MotifTabNavigation variant="default">
                    <Link to={`/client/${this.props.clientId}/customFdrTemplate`}
                        tabIndex="-1">
                        <MotifTabControl
                            role="tab"
                            onClick={() => this.handleClick(1)}>
                            Templates
                        </MotifTabControl>
                    </Link>
                    <Link to={`/client/${this.props.clientId}/customFdrTemplate/assignReits`} tabIndex="-1">
                        <MotifTabControl className="motif-active"
                            role="tab"
                            autoFocus
                            onClick={() => this.handleClick(2)}>
                            Assign Defaults
                        </MotifTabControl>
                    </Link>
                </MotifTabNavigation>
                <AssignReitsTable
                    defaultFdrTemplates={this.props.customFdrTemplate.defaultFdrTemplates}
                    selectedClient={this.props.clientId}
                    selectedPsqArqc={this.state.psqArqc}
                    onSubmit={this.handleSaveDefaultFdrTemplates}
                    handleReset={this.props.reset}
                    formSyncErrors={this.props.formSyncErrors}
                    formSubmitErrors={this.props.formSubmitErrors}
                    saved={this.state.saved}
                    handleCopyField={this.handleCopyField}
                    handlePasteField={this.handlePasteField}
                    copiedFieldName={this.state.copiedFieldName}
                    hasWriteAccess={
                        (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                            || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId))
                    }
                    customTemplateOptions={customTemplateOptions} />
            </React.Fragment >
        );
    }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.router.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }

    return {
        clientId: cid,
        customFdrTemplate: state.customFdrTemplate,
        defaultFdrTemplatesValues: getFormValues('defaultFdrTemplates')(state),
        // initialValues: {
        //     calendarYear: parseInt(new Date().getFullYear()) // default the year dropdown to current year
        // },
        currentUserAuthorizations: state.currentUserAuthorizations,
        formSyncErrors: getFormSyncErrors('defaultFdrTemplates')(state),
        formSubmitErrors: getFormSubmitErrors('defaultFdrTemplates')(state)
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        clientActions: bindActionCreators(clientActions, dispatch),
        customFdrTemplateActions: bindActionCreators(customFdrTemplateActions, dispatch),
    };
}

DefaultFdrTemplatesContainer.propTypes = {
    clientId: PropTypes.number,
    customFdrTemplate: PropTypes.object,
    defaultFdrTemplatesValues: PropTypes.object,
    initialValues: PropTypes.object,
    clientActions: PropTypes.object,
    customFdrTemplateActions: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    initialize: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({ form: "defaultFdrTemplates", enableReinitialize: true })(
            DefaultFdrTemplatesContainer
        )
    )
);
