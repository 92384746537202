import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_ASSET_TEST_SUMMARY_SUCCESS action and sets a client in the store
 * @param {Object} [state] The client currently in the store, or the initial state of client
 * @param {Object} action The action to be handled
 * @returns {Object} The client
 */
export default function distributionTestSummary(state = initialState.distributionTestSummary, action) {
    switch (action.type) {
        case actions.LOAD_DISTRIBUTION_TEST_SUMMARY_SUCCESS:
            return action.distributionTestSummary;
        case actions.UNLOAD_TESTS:
            return initialState.distributionTestSummary;
        default:
            return state;
    }
}