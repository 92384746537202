let timer;

/**
 * Avoid calling this function in Component's useEffect method, after Motif Team resolves issue of MotifSelect component
 * Add Attribute for DOM element based on selector. e.g. Adds "type='button'" attribute for all button elements having "[role = 'combobox']" attribute
 * @param {selector} string e.g. button[role = 'combobox']
 * @param {attributeName} string e.g. type
 * @param {attributeValue} string e.g. button
 */
export const addAttribute = function (selector, attributeName, attributeValue) {    

    //Clear Timeout
    clearTimeout(timer);

    timer = setTimeout(() => {        

        //Get the elements based on selector
        const elementList = document.querySelectorAll(selector);

        if (elementList && elementList.length > 0) {           

            [...elementList].forEach(function (element) {
                
                if (!element.hasAttribute([attributeName]) || element[attributeName] != attributeValue) {
                    //Add attribute
                    element[attributeName] = attributeValue;
                }
            });
        }        
    }, 250)
    return () => {
        clearTimeout(timer)
    }
}