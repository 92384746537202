import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_CHECKLIST_FILES_SUCCESS action and sets the checklist list for external user dashboard in the store
 * @param {Object} [state] The checklist list currently in the store, or the initial state of the checklist list
 * @param {Object} action The action to be handled
 * @returns {Array} The checklist files
 */
export default function externalChecklistListReducer(state = initialState.checklistList, action) {
    if (action.type === actions.LOAD_EXTERNAL_CHECKLIST_LIST_SUCCESS) {
        return action.checklistList;
    } else {
        return state;
    }
}