import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the TOGGLE_NOTIFICATIONS_DISPLAY action and sets the show notifications in the store
 * @param {Object} [state] The show notifications currently in the store, or the initial state of the show notifications
 * @param {Object} action The action to be handled
 * @returns {Object} The show notifications
 */
export default function showNotifications(state = initialState.showNotifications, action) {
    switch (action.type) {
        case actions.TOGGLE_NOTIFICATIONS_DISPLAY:
            return !state;
        default:
            return state;
    }
}