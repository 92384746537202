import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_SERVICES_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function availableQuestionReducer(state = initialState.availableQuestion, action) {
    switch (action.type) {
        case actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_SUCCESS:
            return Object.assign([], state, {
                templates: action.availableQuestion,
                availableQuestionDetail:action.availableQuestion
            });
        default:
            return state;
    }
}