import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import { withMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

/**
 * LoginPage container component
 * @extends {React.Component}
 */
export class LogoffPage extends React.Component {
    /**
     * Creates a new LoginPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.cookies = new Cookies();
    }

    componentDidMount() {
        const instance = this.props.msalContext.instance;

        instance.logoutRedirect({
            account: instance.getAllAccounts()[0],
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        });

        // Clear cookie on log out
        //this.cookies.remove('authenticationScope', { path: '/' });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <div style={{ padding: '20px' }}>
                <h2 style={{ marginBottom: '10px' }}>Logging Out...</h2>
                {/* <p>Thank you for using REITSuite. Please <Link to="/">click here to log back in.</Link></p> */}
            </div>
        );
    }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        authenticationScope: state.authenticationScope
    };
}

LogoffPage.propTypes = {
    authenticationScope: PropTypes.string
};

export default connect(mapStateToProps, null)(withMsal(LogoffPage));