import React from 'react';
import {
    MotifLabel,
    MotifIcon, 
    MotifButton,
} from '@ey-xd/motif-react';
import { IconoirPlus, IconoirXmark } from '@ey-xd/motif-icon';
import { Field } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as reportExtractGroups from "../../constants/reportExtractGroups";
import PropTypes from "prop-types";

const GroupingofReportExtract = ({
    reportExtractGroupByList,
    showGroupingofExtract,
    handleAddLevelGroupBy,
    handleDeleteLevelGroupBy,
    handleGroupBySelection
}) => {    

    const groupofControls = [];
    for (let i = 0; i <= reportExtractGroupByList.length; i++) {        
        groupofControls.push(<React.Fragment>
            {showGroupingofExtract && <div className="row" style={{marginTop: '20px'}}>
                {/* <div className="field col-md-3 margin-top-4" style={{marginTop: '30px'}}>
                    {i < reportExtractGroupByList.length &&
                        <MotifLabel
                            htmlFor="grpByReportExtract">
                            {i == 0 ? 'Group By' : 'Then By'}
                    </MotifLabel>}
                </div> */}
                
                <div className="field col-md-5 margin-top-4">
                    {i < reportExtractGroupByList.length &&
                        <Field
                            name={`groupingofRPExtract${i}`}
                            id={`groupingofRPExtract${i}`}
                            label={i == 0 ? 'Group By' : 'Then By'}
                            placeholder="Select one"
                            isRequiredField={false}
                            options={reportExtractGroups.groupOptions}
                            optionValue={(opt) => opt.groupingOfReportExtractID}
                            optionText={(opt) => opt.groupingOfReportExtractDescription}
                            component={formHelpers.SingleSelectComponent}                        
                            defaultValue={reportExtractGroupByList[i].groupingOfReportExtractID}
                            testingData={i == 0 ? { label: "lblGroupBy", div: "divGroupBy" } : { label: "lblThenBy", div: "divThenBy" }}
                            onChange={(e) => handleGroupBySelection(e, i)}
                        />
                       }
                </div>          

                <div className="field col-md-4 margin-top-4" style={{marginTop: '20px'}}>
                    {i < reportExtractGroupByList.length && reportExtractGroupByList.length != 1 && <div style={{ marginTop: '22px' }}>                        
                        <MotifButton variant="text"
                            name={`deleteGroupLevelBy${i}`}
                            id={`deleteGroupLevelBy${i}`}
                            aria-label="Delete Group Level" type="button"
                            onClick={() => handleDeleteLevelGroupBy(i)}
                            data-testid="btnDeleteLevelGroupBy"
                            style={{fontSize: '12px', }}>
                            <MotifIcon iconFunction={IconoirXmark} size='24' fill='none'  className="deleteReportManagementGroupLevelIcon" title="Delete Group Level" />
                            Delete Group
                        </MotifButton>
                    </div>
                    }                    
                    {((i == reportExtractGroupByList.length && reportExtractGroupByList.length != 1) ||  (i != reportExtractGroupByList.length && reportExtractGroupByList.length == 1))
                        && reportExtractGroupByList.length < 3 &&
                        <div style={{ marginTop: '6px' }}>                            
                            <MotifButton variant="text"
                                aria-label="Add Level to Group By"
                                type="button"
                                disabled={reportExtractGroupByList && reportExtractGroupByList.length > 2}
                                onClick={handleAddLevelGroupBy}
                                data-testid="btnAddLevelGroupBy"
                                style={{fontSize: '12px'}}>
                                <MotifIcon iconFunction={IconoirPlus} size='24' fill='none' title="Add Level to Group By" />
                                Add Group 
                            </MotifButton>                    
                            
                        </div>
                    }
                </div>
            </div>
            }
                </React.Fragment>
        );
    }


    return (
        <div className="row">
            <div className="field col-md-6">{groupofControls}</div>
         </div>
       

        );
};

GroupingofReportExtract.propTypes = {
    reportExtractGroupByList: PropTypes.array,
    showGroupingofExtract: PropTypes.bool,
    handleAddLevelGroupBy: PropTypes.func,
    handleDeleteLevelGroupBy: PropTypes.func,
    handleGroupBySelection: PropTypes.func,
};

export default GroupingofReportExtract;
