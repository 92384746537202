export const phoneParser = (number) => {
    if(number){
        number = number.toString();
        number = number.replace(/[-\(\)\s]/g, '');
        number = number.replace(/[^\-\d\.]/g, '');
    } else{
        number = "";
    }

    return number;
};

export const numberParser = (value) => {
    value = value.toString();

    value = value.replace(/[^\-\d\.]/g, '');

    // Does not accept more that one minus sign (-)
    value = value.replace(/[^-\d.]/g, '')?.replace(/(?!^)-/g, '');

    return value;
};

// This is intended for NUMBER text field that only accepts positive numbers and decimals
export const positiveNumberParser = (value) => {
    value = value.toString();

    value = value.replace(/[^\d\.]/g, '');

    return value;
};

// This is intended for NUMBER text field that only accepts positive numbers
export const positiveWholeNumberParser = (value) => {
    value = value.toString();

    value = value.replace(/[^\d]/g, '');

    return value;
};

export const positiveCurrencyParser = (value) => {
    value = value.toString();

    // Remove anything that is not a number or a decimal
    value = value.replace(/[^\d\.]/g, '');

    const dotIndex = value.indexOf('.');

    // If decimal is included, remove anything past 2 decimal places
    if (dotIndex !== -1) {
        value = value.substring(0, dotIndex + 3);
    }

    return value;
};

export const percentageOfOwnership = (value) => {
    value = value.trim();
  
    if (value === "") {
        return "";
    }

    if (value.length > 1 && isNaN(value)) {
        return
    }

    if ((value > 99.99999999999 && value.length > 14) || (value >= 10 && value <= 99.99999999999 && value.length > 13) || (value < 10 && value.length > 12)) {
        return;
    }

    let indexOfDot = value.indexOf('.');

    if ((indexOfDot === -1) && (value.length === 1) && (isNaN(value))) {
        return "";
    }

    if (value > 100.000) {
        return "100";
    }
    //if (value < 1) {
    //    return "1";
    //}

    let numStr = String(value);
    if (Math.abs(value) < 1.0) {
            let e = parseInt(value.toString().split('e-')[1]);
            if (e) {
                let negative = value < 0;
                if (negative) value *= -1
                value *= Math.pow(10, e - 1);
                numStr = '0.' + (new Array(e)).join('0') + value.toString().substring(2);
                if (negative) numStr = "-" + numStr;
            }

            if(numStr.split(".")[1])
            {
                let a = numStr.split(".")[1].length;
                let dgt = 10;    
                if (a <= 10)
                { 
                    dgt = a;
                }
                numStr=Number.parseFloat(numStr).toFixed(dgt);
            }
        }
        else {
            let e = parseInt(value.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                value /= Math.pow(10, e);
                numStr = value.toString() + (new Array(e + 1)).join('0');
            }
        } 

        if(value.length > 1 && value.startsWith("0")){
            numStr = numStr.replace(/^0+(?=\d)/, '');
        }   

    return numStr;
};
