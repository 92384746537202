import React  ,{useState}from 'react';
import { Link, useMatches,useLocation, useNavigate, useParams, Outlet  } from 'react-router-dom';
import { MotifBreadcrumb, MotifBreadcrumbItem } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';
import DataStewardAssignmentRequiredModal from '../../components/dataStewardRoleManagement/dataStewardAssignmentRequiredModal';
import * as clientActions from '../../actions/clientActions';
import * as authActions from "../../actions/authActions";
const Breadcrumbs = ({ theme, client, reit, period, currentUserAuthorizations }) => {
    const matches = useMatches();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const endsWithOptionalSlash = (str, endsWithStr) => {
        return str.endsWith(endsWithStr) || str.endsWith(`${endsWithStr}/`);
    };

    const redirectToBreadCrumbPath = (path) => (e) => {
        if ((!authActions.isSystemAdministrator(currentUserAuthorizations)) && (authActions.isEngagementAdministrator(currentUserAuthorizations,params.clientId)) && location.pathname.includes('dataStewardRoleManagement')&& (path!=='/')) {
            e.preventDefault();
            clientActions.fetchClient(params.clientId)
                .then(client => {
                    if (client && client.isLockedForNotHavingDataSteward !== false) {
                        e.preventDefault();
                        toggleShowMinimumDataStewardWarningModal();
                        return;
                    }
                    else {
                        e.preventDefault();
                        navigate(path);
                    }
                })
                .catch(error => {
                    console.error('Error fetching client data:', error);
                    return false;
                });
        }
        else {
            e.preventDefault();
            navigate(path);
        }          
    };

    const [showEngagementAdminForDataStewardAssignmentModal, setShowEngagementAdminForDataStewardAssignmentModal] = useState(false);
    const toggleShowMinimumDataStewardWarningModal= () => {
        setShowEngagementAdminForDataStewardAssignmentModal(!showEngagementAdminForDataStewardAssignmentModal);
      };
     
    const getDisplayName = (match) => {
        const { params, pathname } = match;

        // Check if the pathname includes the expected pattern with dynamic segments
        if (endsWithOptionalSlash(pathname, `/client/${params.clientId}`)) {
            return client && client.name ? client.name : '...';
        } else if (endsWithOptionalSlash(pathname, `/reit/${params.reitId}`)) {
            return reit && reit.reitName ? reit.reitName : '...';
        } else if (endsWithOptionalSlash(pathname, `/period/${params.periodId}`)) {
            return period && period.reportPeriodDescription ? period.reportPeriodDescription : '...';
        } else {
            // If the route has a name, use it; otherwise, use the last segment of the pathname (which really should never happen)
            return match.handle?.name || pathname.split('/').pop();
        }
    };
    
    let breadcrumbs = [];
    if (matches) {
        // Filter out matches that don't have a name (e.g., the root match)
        const filteredMatches = matches.filter((match) => match.handle?.name);
        breadcrumbs = filteredMatches.filter(x => x.handle?.name).map((match, index) => {
            const isLastItem = index === filteredMatches.length - 1;
            const displayName = getDisplayName(match);
            const path = match.pathname;

            return (
                <MotifBreadcrumbItem key={index}>
                    {isLastItem ? (
                        displayName
                    )  :(
                        <Link className={theme} onClick={redirectToBreadCrumbPath(path)} >
                            {displayName}
                        </Link>
                     )}
                      {
                      <DataStewardAssignmentRequiredModal
showEngagementAdminForDataStewardAssignmentModal={showEngagementAdminForDataStewardAssignmentModal}
toggleShowEngagementAdminForDataStewardAssignmentModal={toggleShowMinimumDataStewardWarningModal}
dataStewardAssignmentWarningHeader={Constants.dataStewardDeletionWarningHeader}
dataStewardAssignmentWarningBody={Constants.dataStewardDeletionWarningBody}
/>
        }
                </MotifBreadcrumbItem>
            );
        });
    }

    return (
        <MotifBreadcrumb separator="">
            {breadcrumbs}
        </MotifBreadcrumb>
    );
};

export default Breadcrumbs;
