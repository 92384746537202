// errorSummaryHelper.js

// Helper function to determine if the main error summary should be hidden
const shouldHideMainErrorSummary = (location) => {
    // Define regex patterns for paths where the main error summary should be hidden
    const hideErrorSummaryPatterns = [
        /^\/client\/[^/]+\/reit\/[^/]+\/files\/?$/i,
        /^\/client\/[^/]+\/reit\/[^/]+\/period\/[^/]+\/files\/?$/i,
        /^\/client\/[^/]+\/reit\/[^/]+\/period\/[^/]+\/uploadtb\/[^/]+\/?$/i,
    ];

    // Check if the current location's pathname matches any of the hideErrorSummaryPatterns
    return hideErrorSummaryPatterns.some(pattern => pattern.test(location.pathname));
};

export default shouldHideMainErrorSummary;