import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";
import { fetchFromReitApi as fetch, fetchFromTrialBalanceApi as fetchTb } from "./fetchFromApi";
import { getTrialBalancesByPeriod } from "./trialBalanceActions";
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch the distribution test summary belonging to the client. If successful this will dispatch the LOAD_DISTRIBUTION_TEST_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_DISTRIBUTION_TEST_SUMMARY_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} authHeader The Authorization header to use.  This will be generated if not provided.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchDistributionTestSummary(periodId, authHeader) {    
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/distributions/test?reportPeriodID=${periodId}`,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            }).then(response => {
            return response.json();
        }).then(distributionTestSummary => {
            if (actionHelpers.isErrorResponse(distributionTestSummary)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DISTRIBUTION_TEST_SUMMARY_FAILURE, distributionTestSummary);
            }

            dispatch({ type: actions.LOAD_DISTRIBUTION_TEST_SUMMARY_SUCCESS, distributionTestSummary, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DISTRIBUTION_TEST_SUMMARY_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the distribution test summary belonging to the client. If successful this will dispatch the LOAD_DISTRIBUTION_TEST_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_DISTRIBUTION_TEST_SUMMARY_FAILURE action.
 * @param {number} periodId The ID of the period.
 * @param {string} checklistId The checklist identifier.
 * @param {string} checklistType The checklist type.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalDistributionTestSummaryByChecklistId(periodId, checklistId, checklistType) {
    return fetchDistributionTestSummary(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}

/**
 * Fetch the distribution taxable income belonging to the client. If successful this will dispatch the LOAD_DISTRIBUTION_TAXABLE_INCOME_SUCCESS
 * action, otherwise it will dispatch the LOAD_DISTRIBUTION_TAXABLE_INCOME_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} authHeader The Authorization header to use.  This will be generated if not provided.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchDistributionTaxableIncome(periodId, authHeader) {    
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/distributions/taxableincome?reportPeriodID=${periodId}`,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            }).then(response => {
            return response.json().catch(error => {
                return null;
            });
        }).then(distributionTaxableIncome => {
            if (actionHelpers.isErrorResponse(distributionTaxableIncome)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DISTRIBUTION_TAXABLE_INCOME_FAILURE, distributionTaxableIncome);
            }

            dispatch({ type: actions.LOAD_DISTRIBUTION_TAXABLE_INCOME_SUCCESS, distributionTaxableIncome, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DISTRIBUTION_TAXABLE_INCOME_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the distribution taxable income belonging to the client. If successful this will dispatch the LOAD_DISTRIBUTION_TAXABLE_INCOME_SUCCESS
 * action, otherwise it will dispatch the LOAD_DISTRIBUTION_TAXABLE_INCOME_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} checklistId The checklist identifier.
 * @param {string} checklistType The checklist type.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalDistributionTaxableIncomeByChecklistId(periodId, checklistId, checklistType) {
    return fetchDistributionTaxableIncome(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}

/**
 * Fetch the distribution test details belonging to the client. If successful this will dispatch the LOAD_DISTRIBUTION_DETAIL_SUCCESS
 * action, otherwise it will dispatch the LOAD_DISTRIBUTION_DETAIL_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} authHeader The Authorization header to use.  This will be generated if not provided.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchDistributionDetail(periodId, authHeader) {    
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/distributions?reportPeriodID=${periodId}`,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            }).then(response => {
            return response.json();
        }).then(distributionDetails => {
            if (actionHelpers.isErrorResponse(distributionDetails)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DISTRIBUTION_DETAIL_FAILURE, distributionDetails);
            }

            dispatch({ type: actions.LOAD_DISTRIBUTION_DETAIL_SUCCESS, distributionDetails, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DISTRIBUTION_DETAIL_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the distribution test details belonging to the client. If successful this will dispatch the LOAD_DISTRIBUTION_DETAIL_SUCCESS
 * action, otherwise it will dispatch the LOAD_DISTRIBUTION_DETAIL_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} checklistId The checklist identifier.
 * @param {string} checklistType The checklist type.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalDistributionDetailByChecklistId(periodId, checklistId, checklistType) {
    return fetchDistributionDetail(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}

/**
 * Saves a distribution taxable income to the REIT API database.
 * @param {any} periodId The period ID
 * @param {any} value The taxable incomve value.
 * @returns {Promise} A Promise
 * http://localhost:5000/api/distributions/taxableincome?periodId=2&value=62514.35
 */
export function saveDistributionTaxableIncome(periodId, distributionREITTaxableIncomeAmount, distributionDividendsSum) {    
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });        
        const payload = {
            "reportPeriodID": periodId,
            "distributionREITTaxableIncomeAmount": distributionREITTaxableIncomeAmount,
            "distributionDividendsSum": distributionDividendsSum
        };

        return fetch("/distributions/taxableincome",
            {
                headers: {
                    'Accept':
                        "application/json",
                    'Content-Type': "application/json"
                },
                method: "POST",
                body: JSON.stringify(payload)
            }).then(response => {
                return response.json();
            }).then(distributionTaxableIncome => {
                if (actionHelpers.isErrorResponse(distributionTaxableIncome)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_DISTRIBUTION_TAXABLE_INCOME_FAILURE, distributionTaxableIncome);
                }                
                fetchDistributionTestSummary(periodId)(dispatch);
                fetchDistributionTaxableIncome(periodId)(dispatch);
                dispatch({ type: actions.CREATE_DISTRIBUTION_TAXABLE_INCOME_SUCCESS, distributionTaxableIncome, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_DISTRIBUTION_TAXABLE_INCOME_FAILURE, null, error);
            });
    };
}

/**
 * Saves a distribution to the REIT API database.
 * @param {any} periodId The period ID
 * @param {any} distribution The distribution to save.
 * @returns {Promise} A Promise
 * http://localhost:5000/api/distributions/taxableincome?periodId=2&value=62514.35
 */
export function saveDistribution(periodId, distribution) {    
    const payload = {
        "class": distribution.class,
        "distributionDate": distribution.distributionDate,
        "fairMarketValue": distribution.fairMarketValue,
        "reportPeriodID": distribution.reportPeriodID,
        "value": distribution.value
    };
    
    return fetch("/distributions",
        {
            headers: {
                'Accept':
                    "application/json",
                'Content-Type': "application/json"
            },
            method: "POST",
            body: JSON.stringify(payload)
        }).then(response => {
            
            if (response.ok) {
                return null;
            }

            return response.json();
        });
}

/**
 * Updates a distribution to the REIT API database.
 * @param {any} distribution The destribution
 * @returns {Promise} A Promise
 * http://localhost:5000/api/distributions/3
 */
export function updateDistribution(distribution) {    
    const payload = {
        "distributionID": distribution.distributionID,
        "class": distribution.class,
        "distributionDate": distribution.distributionDate,
        "fairMarketValue": distribution.fairMarketValue,
        "reportPeriodID": distribution.reportPeriodID,
        "value": distribution.value
    };

    return fetch(`/distributions/${distribution.id}`,
        {
            headers: {
                'Accept':
                "application/json",
                'Content-Type': "application/json"
            },
            method: "PUT",
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                return null;
            }

            return response.json();
        });
}

/**
 * Deletes a distribution to the REIT API database.
 * @param {any} distribution The distribution to delete.
 * @returns {Promise} A Promise
 * http://localhost:5000/api/distributions/3
 */
export function deleteDistribution(distribution) {    
    return fetch(`/distributions/${distribution.distributionID}`,
        {
            headers: {
                'Accept':
                    "application/json",
                'Content-Type': "application/json"
            },
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return null;
            } else {
                return response.json();
            }
        });
}

/**
 * Adds all the distributions fair market value and dollar value and returns the total.
 * @param {any} distributions The distributions to aggregate.
 * @returns {number} The total of all distributions.
 */
export function sumDistributionValues(distributions) {
    if (!distributions) {
        return 0;
    }
    let total = 0;
    for (let i = 0; i < distributions.length; i++) {
        total = total + distributions[i].fairMarketValue + distributions[i].value;
    }

    return total;
}