import React from 'react';
import PropTypes from 'prop-types';

import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from '@ey-xd/motif-react';

const ClientSignoffModal = ({
  showClientSignoffModal,
  modalTitle,
  modalContent,
  selectedChecklists,
  toggleShowClientSignoffModal,
  handleClientSignoff,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close client sign off modal',
      'aria-label': 'Close client sign off modal',
    'data-testid': 'close',
  };

    const showHideClientSignoffModal = () => {
        toggleShowClientSignoffModal();
  };

  return (
    <MotifModal
      variant="alt"
    show={showClientSignoffModal}
    onClose={showHideClientSignoffModal}
      size="xl"
      data-testid="ClientSignoffModal"
    >
      <MotifModalHeader
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <span className="ml-0">{modalTitle}</span>
        </HeaderWrapper>
      </MotifModalHeader>
      <MotifModalBody>
        <span className="checklistActionMsg">{modalContent}</span>
        <br />
        <span className="checklistActionMsg">You have selected {selectedChecklists?.length} checklist(s).</span>
      </MotifModalBody>
      <MotifModalFooter>
        
        <MotifButton
          variant="secondary"
          className="right"
          data-testid="Cancel"
          type="button"
                  onClick={showHideClientSignoffModal}
        >
          Cancel
        </MotifButton>
        <MotifButton
          className="right"
          data-testid="Submit"
          type="button"
                  onClick={() => handleClientSignoff()}
        >
          Submit
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

ClientSignoffModal.propTypes = {
  showClientSignoffModal: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  selectedChecklists: PropTypes.array,
  toggleShowClientSignoffModal: PropTypes.bool,
  handleClientSignoff: PropTypes.func,

};

export default ClientSignoffModal;
