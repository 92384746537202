import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button } from "semantic-ui-react";
import EditChecklistSection from "./EditChecklistSection";
import { MotifButton, MotifIcon } from "@ey-xd/motif-react";

const ArqcChecklistDetailsForm = ({ handleSubmit, handleFormSubmit, checklist, isEnabled,
    activeItem, handleItemAnswered, handleComments,
    isExternal, isArqc, showAllQuestions, showUnansweredQuestions, previousArqcChecklist, showConfirmationModal, taxableReitSubsidiaries, initialValues,
    tbMode, periodId, checklistId, canEdit, user, checklistFiles,checkReportPeriodVisibilityConstraint,
    handleSaveComment, handleDeleteComment, handleEditComment, handleDeleteAttachment, handleDownloadChecklistFile, externalEmail,questionComments }) => {
       // console.log('#checklist',checklist);
    return (
        <form onSubmit={handleSubmit}>
            <Field type="hidden" component="input" name="isExternal" />
            {checklist && checklist.content.map(section =>
                <EditChecklistSection checklistId={checklistId} periodId={periodId}
                    handleSubmitReduxForm={handleSubmit} shouldSubmitReduxFormOnChange={true}
                    key={section.id} tbMode={tbMode}
                    isEnabled={isEnabled} section={section}
                    isVisible={section.title === activeItem.title}
                    handleItemAnswered={handleItemAnswered} handleComments={handleComments}
                    isExternal={isExternal} isArqc={isArqc}
                    showAllQuestions={showAllQuestions} previousChecklistFound={previousArqcChecklist && previousArqcChecklist.content}
                    showUnansweredQuestions={showUnansweredQuestions}
                    prePopulateAnswerOptionID={checklist.prePopulateAnswerOptionID}
                    taxableReitSubsidiaries={taxableReitSubsidiaries}
                    canEdit={canEdit}
                    user={user}
                    checklistFiles={checklistFiles}
                    externalEmail={externalEmail}
                    checkReportPeriodVisibilityConstraint={checkReportPeriodVisibilityConstraint}
                    handleSaveComment={handleSaveComment}
                    handleDeleteComment={handleDeleteComment}
                    handleEditComment={handleEditComment}
                    handleDeleteAttachment={handleDeleteAttachment}
                    handleDownloadChecklistFile={handleDownloadChecklistFile}
                    questionComments={questionComments}
                    />)}
        </form>
    );
};

ArqcChecklistDetailsForm.propTypes = {
    periodId: PropTypes.number,
    handleSubmit: PropTypes.func,
    checklist: PropTypes.object,
    isEnabled: PropTypes.bool,
    activeItem: PropTypes.object,
    handleItemAnswered: PropTypes.func,
    handleComments: PropTypes.func,
    showAllQuestions: PropTypes.bool,
    showUnansweredQuestions: PropTypes.bool,
    previousArqcChecklist: PropTypes.object,
    showConfirmationModal: PropTypes.func,
    initialValues: PropTypes.object,
    tbMode: PropTypes.number,
    isExternal: PropTypes.bool,
    isArqc: PropTypes.bool,
    taxableReitSubsidiaries: PropTypes.array,
    handleFormSubmit: PropTypes.func,
    checklistId: PropTypes.string,
    user: PropTypes.string
};

export default reduxForm({
    form: "checklist",
    enableReinitialize: true
})(ArqcChecklistDetailsForm);