import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MotifTabNavigation, MotifTabControl } from "@ey-xd/motif-react";
import { withRouter } from "../../common/withRouter";
import '../../css/index.scss';

const TabbedNavigation = ({ clientId, reitId, periodId, currentLocation, navigationServices, clientIsAudit, router }) => {
    const reitLink = `/client/${clientId}/reit/${reitId}`;
    const baseLink = `${reitLink}/period/${periodId}/`;

    //Set current index to 2, i.e. Set "TB Import" tab as active tab
    let currentIndex = 2;

    //Set tab index based on current location
    if (currentLocation && baseLink) {
        switch (currentLocation.toLowerCase()) {
            case baseLink + "uploadtb/adjusted":
                currentIndex = 2;
                break;
            //If user has clicked on period link - e.g. /client/3/reit/3/period/13
            case baseLink.slice(0, -1):
                currentIndex = 2;
                break;
            case baseLink + "assettest":
                currentIndex = 3;
                break;
            case baseLink + "incometest":
                currentIndex = 4;
                break;
            case baseLink + "distributiontest":
                currentIndex = 5;
                break;
            case baseLink + "leasereview":
                currentIndex = 2;
                break;
            case baseLink + "psq":
                currentIndex = 7;
                break;
            case baseLink + "arqc":
                currentIndex = 8;
                break;
            case baseLink + "report":
                currentIndex = 9;
                break;
            default:
                currentIndex = -1;
        }
    }

    const [tabBar1, setTabBar1] = useState(currentIndex);
    const handleActiveTab1 = index => ((tabBar1 === index) ? 'motif-active' : 'tab-inactive');

    const handleClick = (index) => {
        switch (index) {
            case 1:
                router.navigate(reitLink);
                break;
            case 2:
                router.navigate(baseLink + "uploadtb/adjusted");
                break;
            case 3:
                router.navigate(baseLink + "assettest");
                break;
            case 4:
                router.navigate(baseLink + "incometest");
                break;
            case 5:
                router.navigate(baseLink + "distributiontest");
                break;
            case 6:
                window.open(process.env.EXTERNAL_LEASE_REVIEW_URL, "_blank");
                break;
            case 7:
                router.navigate(baseLink + "psq");
                break;
            case 8:
                router.navigate(baseLink + "arqc");
                break;
            case 9:
                router.navigate(baseLink + "report");
                break;
            default:
                currentIndex = -1;
        }

        //Avoid setting tab bar index for Lease Review selection
        if (index !== 6) {
            setTabBar1(index);
        }
    }

    //Sync tab button with currentIndex
    if (tabBar1 !== currentIndex && typeof handleClick === "function") {
        handleClick(currentIndex);
    }

    return (
        <div>
            <MotifTabNavigation variant="default">
                <MotifTabControl className={handleActiveTab1(1)} onClick={() => handleClick(1)} role="tab" tabIndex="0">
                    Overall
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(2)} onClick={() => handleClick(2)}
                    disabled={clientIsAudit} tabIndex="0">
                    TB Import
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(3)} onClick={() => handleClick(3)}
                    disabled={!navigationServices || !Array.isArray(navigationServices) || !navigationServices.includes("Asset Testing") || clientIsAudit} tabIndex="0">
                    Asset Test
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(4)} onClick={() => handleClick(4)}
                    disabled={!navigationServices || !Array.isArray(navigationServices) || !navigationServices.includes("Income Testing")} tabIndex="0">
                    Income Test
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(5)} onClick={() => handleClick(5)}
                    disabled={!navigationServices || !Array.isArray(navigationServices) || !navigationServices.includes("Distribution Testing") || clientIsAudit} tabIndex="0">
                    Distribution Test
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(6)} onClick={() => handleClick(6)}
                    disabled={!navigationServices || !Array.isArray(navigationServices) || !navigationServices.includes("Lease Review") || clientIsAudit}
                    tabIndex="0">
                    Lease Review
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(7)} onClick={() => handleClick(7)}
                    disabled={!navigationServices || !Array.isArray(navigationServices) || !navigationServices.includes("PSQ") || clientIsAudit} tabIndex="0">
                    PSQ
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(8)} onClick={() => handleClick(8)}
                    disabled={!navigationServices || !Array.isArray(navigationServices) || !navigationServices.includes("ARQC")} tabIndex="0">
                    ARQC
                </MotifTabControl>
                <MotifTabControl role="tab" className={handleActiveTab1(9)} onClick={() => handleClick(9)} tabIndex="0">
                    Report
                </MotifTabControl>
            </MotifTabNavigation>
        </div>
    );

};

TabbedNavigation.propTypes = {
    clientId: PropTypes.number.isRequired,
    reitId: PropTypes.number.isRequired,
    periodId: PropTypes.number.isRequired,
    currentLocation: PropTypes.string.isRequired,
    services: PropTypes.array,
    navigationServices: PropTypes.array,
    clientIsAuditOnly: PropTypes.bool
};

export default withRouter(TabbedNavigation);