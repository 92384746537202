import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Form, Icon } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifIconButton, MotifIcon, MotifButton } from "@ey-xd/motif-react";
import { addAttribute } from "../../common/Attribute";
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

const CustomFdrTemplateHeader = ({
    clientId,
    customFdrTemplates,
    customFdrTemplateFormValues,
    pristine,
    submitting,
    invalid,
    onSubmit,
    handleSubmit,
    onCancel,
    toggleShowCreateTemplateModal,
    toggleShowDeleteTemplateModal,
    editorUsed,
    handleTemplateSelection
}) => {

    
    useEffect(() => {        
         
        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const preventSubmitOnEnter = (e) => {
        if (e.key === 'Enter') {
            //Avoid preventing key press for buttons
            if (e.target && e.target.type !== 'button') {
                e.preventDefault();
            }
        }
    };

    const templateOptions =
        customFdrTemplates.templates
            .map(t => {
                return { key: t.fdrTemplateID, value: t.fdrTemplateID, text: t.fdrTemplateName }
            });
    templateOptions.unshift({ key: -2, value: -2, text: 'Standard Template' });
    //templateOptions.unshift({ key: -1, value: -1, text: 'Select an Existing Template' });

    const template = customFdrTemplateFormValues
        && customFdrTemplateFormValues.templateId
        && customFdrTemplateFormValues.templateId !== -1 ?
        customFdrTemplates.templates.find(t => t.fdrTemplateID == customFdrTemplateFormValues.templateId) : null;

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            onKeyPress={preventSubmitOnEnter}>
            <div style={{ minHeight: '24px' }}></div>
            <div className="row">
                <div className="col">
                    To customize a template, choose one of the following options
                    to view the template details.
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <div className="d-flex flex-row align-items-center">
                        <MotifButton
                            variant="primary"
                            type="button"
                            onClick={() =>
                                toggleShowCreateTemplateModal("create")
                            }>
                            Create New Template
                        </MotifButton>
                        <h6 className="ml-3 mr-3">OR</h6>
                        <div style={{ minWidth: "275px" }}>
                            <Field
                                name="templateId"
                                label="Select an Existing Template"
                                options={templateOptions}
                                component={formHelpers.SingleSelectComponent}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {customFdrTemplateFormValues &&
                customFdrTemplateFormValues.templateId !== -1 &&
                template && (
                    <React.Fragment>
                        <div className="ui divider mt-4"></div>
                        <div className="row mt-3">
                            <div className="col margin-top-5">
                                <h5 className="d-inline mr-1">
                                    {template.fdrTemplateName}
                                </h5>
                                <MotifIconButton
                                    aria-label="Edit this Template"
                                    type="button"
                                    onClick={() =>
                                        toggleShowCreateTemplateModal("edit")
                                    }>
                                    <MotifIcon
                                        iconFunction={IconoirEditPencil} size='24' fill='none'
                                        title="Edit this Template"
                                    />
                                </MotifIconButton>
                                <MotifIconButton
                                    aria-label="Delete this Template"
                                    type="button"
                                    onClick={toggleShowDeleteTemplateModal}>
                                    <MotifIcon
                                        iconFunction={IconoirTrash} size='24' fill='none'
                                        title="Delete this Template"
                                    />
                                </MotifIconButton>
                            </div>
                            <div className="d-flex flex-row-reverse align-items-center margin-top-5">
                                <MotifButton
                                    variant="primary right ml-2"
                                    type="submit"
                                    disabled={!editorUsed || submitting}>
                                    Save
                                </MotifButton>
                                <MotifButton
                                    variant="primary-alt"
                                    className="right ml-2"
                                    type="button"
                                    onClick={() =>
                                        toggleShowCreateTemplateModal("saveas")
                                    }
                                    disabled={submitting}>
                                    Save As
                                </MotifButton>
                                <MotifButton
                                    variant="secondary"
                                    className="right"
                                    type="button"
                                    onClick={onCancel}
                                    disabled={!editorUsed}>
                                    Cancel
                                </MotifButton>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            <div className={`${(customFdrTemplateFormValues &&
                customFdrTemplateFormValues.templateId !== -1 &&
                template) ? '' : 'margin-bottom-5'}`}></div>
                    
        </Form>
    );
};

CustomFdrTemplateHeader.propTypes = {
    //checklistList: PropTypes.array.isRequired
};

export default reduxForm({ form: "customFdrTemplateForm" })(CustomFdrTemplateHeader);