import React from 'react';
import PropTypes from 'prop-types';
import { MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader, MotifButton } from '@ey-xd/motif-react';

const DeleteLastSectionWarningModal = ({
    showDeleteLastSectionWarningModal,
    toggleShowDeleteLastSectionWarningModal
}) => {
    return (
        <MotifModal
            variant="alt"
            show={showDeleteLastSectionWarningModal}
            onClose={toggleShowDeleteLastSectionWarningModal}
            data-testid="closeDeleteLastSectionWarningModal"
        >
            <MotifModalHeader>
                Delete Section
            </MotifModalHeader>
            <MotifModalBody>
                <span>
                    Checklist always requires at least one section within the template. Please add a section in order to delete an existing section.
                </span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton
                    type="button"
                    onClick={toggleShowDeleteLastSectionWarningModal}
                    data-testid="btnCloseDeleteLastSectionWarningModal"
                >
                    OK
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

DeleteLastSectionWarningModal.propTypes = {
    showDeleteLastSectionWarningModal: PropTypes.bool,
    toggleShowDeleteLastSectionWarningModal: PropTypes.func
};

export default DeleteLastSectionWarningModal;