import React from 'react';
import { MotifCheckbox } from "@ey-xd/motif-react";
import { DELETION_STATUSES } from '../../../constants/other';
export default (props) => {
  let rowsChecked = 0;
  let totalRows = 0;
  let allRowsInActive = true; // Variable to track if all rows are invalid
  let totalRowsOfActiveRecords = 0;
  let checkedRecords = 0;
  // Check for total rows and rows checked within filtered rows
  props.api.forEachNodeAfterFilter((rowNode) => {
    if (rowNode.data) {
      totalRows++;
      if (rowNode.data.reitSelection){
        rowsChecked++;
      } 
      // Check if the row status is not one of 'failed', 'deleted', or 'inprogress'
      const statusesToCheck = [
        DELETION_STATUSES.DELETED,
        DELETION_STATUSES.FAILED,
        DELETION_STATUSES.IN_PROGRESS
      ];
      
      const { stage2DeletionStatus, stage3DeletionStatus } = rowNode.data;

      if (!statusesToCheck.includes(stage2DeletionStatus) && !statusesToCheck.includes(stage3DeletionStatus)) {
        allRowsInActive = false;
        totalRowsOfActiveRecords++;
        if(rowNode.data.reitSelection){
          checkedRecords++;
        }
      }
     
    }
  });

  let headerCheckboxValue = false;
  let indeterminate = false;
  const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
  let checkboxClass = defaultCheckboxClass;

  if (rowsChecked === 0) {
    headerCheckboxValue = false;
  }
  else if (totalRowsOfActiveRecords === rowsChecked) {
    headerCheckboxValue = true;
    checkboxClass = defaultCheckboxClass + " ag-checked";
  }
  else if(totalRowsOfActiveRecords === checkedRecords){
    headerCheckboxValue = true;
    checkboxClass = defaultCheckboxClass + " ag-checked";
  }
  else {
    headerCheckboxValue = false;
    indeterminate = true;
    checkboxClass = defaultCheckboxClass + " ag-indeterminate";
  }

  return (
    <div>
        <MotifCheckbox
            type="checkbox"
            name="header-checkbox"
            id="ag-header-checkbox"
            checked={headerCheckboxValue}
            data-testid="selectCheckboxHeader"
            aria-label="Press Space to toggle row selection"
            tabindex="-1"
            onClick={() => props.onHeaderCheckboxChange(headerCheckboxValue)}
            indeterminate={indeterminate}
            disabled={allRowsInActive}
        >
        </MotifCheckbox>
        <div className="customHeaderLabel">{props.displayName}</div>
    </div>
  );
};