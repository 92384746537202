import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import {MotifTooltip, MotifButton, MotifFormField, MotifCheckbox, MotifToggleSwitch, MotifLabel, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifErrorMessage } from "@ey-xd/motif-react";

const EditClientAuthModal = ({
  showEditClientAuthModal,
  handleSubmit,
  toggleShowEditClientAuthModal,
  initialValues,
  clientAuthFormValues,
  receiveDailyNewsletter,
  handleDailyNewsletter,
  receiveWeeklyNewsletter,
  handleWeeklyNewsletter,
  receiveRealTimeNotification,
  handleRealTimeNotification,
  receiveRealTimeNotificationForNewPsqArqcSent,
  handleInternalReviewNotification,
  isDataSteward,
  handleDataSteward,
  dataStewardCheckboxDisplay,
  dataStewardValue,
  showDSError
}) => {
  let email = "";
  if (initialValues && initialValues.userId) {
    email = initialValues.userId;
  }
  let initialRole = "";
  if (initialValues && initialValues.role) {
    initialRole = initialValues.role;
  }
  let newRole = "";
  if (clientAuthFormValues && clientAuthFormValues.role) {
    newRole = clientAuthFormValues.role;
  }
  let changedToInvalidRole = false;
  if (
    (initialRole === "4" && newRole !== "4") ||
    (initialRole !== "4" && newRole === "4")
  ) {
    changedToInvalidRole = true;
  }

  let errorMessageDisplay = false;
  if (dataStewardCheckboxDisplay == true && dataStewardValue == false) {
    errorMessageDisplay = true;
  }

  return (
    <MotifModal show={showEditClientAuthModal} onClose={toggleShowEditClientAuthModal} variant="alt" className={`edit-client-auth-motif-modal`}>
      <MotifModalHeader>Edit User Authorization</MotifModalHeader>
      <form onSubmit={handleSubmit} className="clientAuth">
        <MotifModalBody>
          <Field
            name="userId"
            type="hidden"
            component={formHelpers.renderField}
            label=""
          />
          <Field
            name="resource"
            type="hidden"
            component={formHelpers.renderField}
            label=""
          />
          <Field
            name="id"
            type="hidden"
            component={formHelpers.renderField}
            label=""
          />
          <MotifTooltip
            placement="auto"
            trigger={<h4 className="truncateEmail"> {email}</h4>}
          >

            {email}
          </MotifTooltip>

          <br />
          <br />
          <label>
            <Field
              name="role"
              component={formHelpers.renderField}
              type="radio"
              value="1"
              label="Engagement Administrator"
            />
          </label>
          <br />
          <label>
            <Field
              name="role"
              component={formHelpers.renderField}
              type="radio"
              value="2"
              label="User"
            />
          </label>
          <br />
          <label>
            <Field
              name="role"
              component={formHelpers.renderField}
              type="radio"
              value="4"
              label="External Checklist User"
            />
          </label>
          <br />
          {changedToInvalidRole && (
            <React.Fragment>
              <MotifErrorMessage variant="error">This email domain cannot change into this Role.</MotifErrorMessage>
              <br />
            </React.Fragment>
          )}

          <section className="clientAuthsSubscription">
            {(newRole == "1") && (
              <div className="row">
                <div className="col-md-4 mt-2">
                  <MotifFormField>
                    <MotifCheckbox
                      className="dataSteward-checkbox-space"
                      data-testid="chkbxDataSteward"
                      type="checkbox"
                      name="dataSteward"
                      id="dataSteward"
                      checked={isDataSteward}
                      onChange={handleDataSteward}
                      disabled={dataStewardCheckboxDisplay == true && dataStewardValue == false ? true : false}
                    >
                      {
                        (!errorMessageDisplay) && <span>Data Steward</span>
                      }

                      {
                        (errorMessageDisplay) &&
                        <MotifTooltip
                          placement="auto"
                          trigger={<span>Data Steward</span>}
                        >
                          Maximum limit for Data Stewards reached. Please unassign a current Data Steward or contact your System Administrator to increase the limit.
                        </MotifTooltip>
                      }

                    </MotifCheckbox>
                  </MotifFormField>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-5">
                <MotifLabel
                  className="clientAuths-Subscription-text"
                  data-testid="receiveChecklistNewletter"
                >
                  Receive Checklist Newsletter
                </MotifLabel>
              </div>
              <div className="clientAuths-Subscription-checkbox">
                <div className="col-md-4">
                  <MotifFormField>
                    <MotifCheckbox
                      type="checkbox"
                      data-testid="chkbxDaily"
                      name="dailyNewsletter"
                      id="dailyNewsletter"
                      checked={receiveDailyNewsletter}
                      onChange={handleDailyNewsletter}
                    >
                      <MotifLabel>Daily</MotifLabel>
                    </MotifCheckbox>
                  </MotifFormField>
                </div>
                <div className="col-md-2">
                  <MotifFormField>
                    <MotifCheckbox
                      type="checkbox"
                      data-testid="chkbxWeekly"
                      name="weeklyNewsletter"
                      id="weeklyNewsletter"
                      checked={receiveWeeklyNewsletter}
                      onChange={handleWeeklyNewsletter}
                    >
                      <MotifLabel>Weekly</MotifLabel>
                    </MotifCheckbox>
                  </MotifFormField>
                </div>
              </div>
            </div>
            <div>
              <div className="clientAuths-Subscription-tgl">
                <div className="realTimeText">
                  <MotifLabel
                    data-testid="lblReceiveRealtimeNotification"
                    style={{ fontWeight: "bold" }}
                  >
                    Receive Real Time Notification(s):
                  </MotifLabel>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9">
                  <MotifLabel id="labelForReopenChecklists" htmlFor="switchReopenChecklists" data-testid="lblReopenChecklists">
                    1. Reopen Submitted or Closed Checklist(s) {receiveRealTimeNotification ? 'on' : 'off'}:
                  </MotifLabel>
                </div>
                <div className="col-md-3" style={{ margin: "auto" }}>
                  <MotifFormField>
                    <MotifToggleSwitch
                      name="switchRealtimeNotification"
                      id="switchRealtimeNotification"
                      data-testid="chkbxRealtimeNotification"
                      onChange={handleRealTimeNotification}
                      checked={receiveRealTimeNotification}
                      className="clsswitchInternalNotification"
                      offLabel={<MotifLabel>Off</MotifLabel>}
                      onLabel={<MotifLabel>On</MotifLabel>}
                      aria-labelledby="labelForReopenChecklists"
                    />
                  </MotifFormField>
                </div>
              </div>
              {(newRole == "4") && (
                <div className="row"> 
                <div className="col-md-9">
                  <MotifLabel id="labelForNewPSQARQCSentInternalReview" htmlFor="switchInternalNotification" data-testid="lblNewPSQARQCSentInternalReview">
                    2. New PSQ/ARQC Sent/Internal Review Notification {receiveRealTimeNotificationForNewPsqArqcSent ? 'on' : 'off'}:
                  </MotifLabel>
                </div>
                <div className="col-md-3" style={{ margin: "auto" }}>
                  <MotifFormField>
                    <MotifToggleSwitch
                      name="switchInternalNotification"
                      id="switchInternalNotification"
                      data-testid="chkbxNewPSQARQCSentInternalReview"
                      onChange={handleInternalReviewNotification}
                      checked={receiveRealTimeNotificationForNewPsqArqcSent}
                      className="clsswitchInternalNotification"
                      offLabel={<MotifLabel>Off</MotifLabel>}
                      onLabel={<MotifLabel>On</MotifLabel>}
                      aria-labelledby="labelForNewPSQARQCSentInternalReview"
                    />
                  </MotifFormField>
                </div>
                </div>
              )}
            </div>
          </section>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton
            variant="secondary"
            className="right"
            type="button"
            data-testid="cancel"
            onClick={toggleShowEditClientAuthModal}
          >
            Cancel
          </MotifButton>
          <MotifButton
            variant="primary"
            className="right"
            type="submit"
            data-testid="submit"
            disabled={changedToInvalidRole}
          >
            Submit
          </MotifButton>
        </MotifModalFooter>
      </form>
    </MotifModal>
  );
};

EditClientAuthModal.propTypes = {
  showEditClientAuthModal: PropTypes.bool,
  handleEditClientAuthModalAction: PropTypes.func,
  handleSubmit: PropTypes.func,
  toggleShowEditClientAuthModal: PropTypes.func,
  initialValues: PropTypes.object,
  clientAuthFormValues: PropTypes.object,
  receiveDailyNewsletter: PropTypes.bool,
  handleDailyNewsletter: PropTypes.func,
  receiveWeeklyNewsletter: PropTypes.bool,
  handleWeeklyNewsletter: PropTypes.func,
  receiveRealTimeNotification: PropTypes.bool,
  handleRealTimeNotification: PropTypes.func,
  receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
  handleInternalReviewNotification: PropTypes.func,
  isDataSteward: PropTypes.bool,
  handleDataSteward: PropTypes.func,
  dataStewardCheckboxDisplay: PropTypes.bool,
  dataStewardValue: PropTypes.bool,
  showDSError: PropTypes.bool,
};

export default reduxForm({ form: "clientAuth", enableReinitialize: true })(
  EditClientAuthModal
);
