import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as detailTestTableHelpers from '../../scripts/detailTestTableHelpers';
import { Link } from 'react-router-dom';
import { MotifButton } from '@ey-xd/motif-react';
import {withWindowDimensions} from '../../common/withWindowDimensions';
/**
 * AssetDetailTestTable container component
 * @extends {React.Component}
 */
export class AssetDetailTestTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      height: window.innerHeight,
      width: window.innerWidth - (window.innerWidth < 680 ? 0 : 47),
    };

  }

  handleResize = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth - (window.innerWidth < 680 ? 0 : 47),
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
   const { width, height } = this.props;
    const trsLinkBuilder = test => {
      if (test === '20% TRS' && (this.props.authenticationScope && this.props.authenticationScope !== 'external') && this.props.clientId) {
        const trsLink = `/client/${this.props.clientId}/reit/${
          this.props.reitId
          }/period/${this.props.periodId}/trs`;

        return (
          <Link to={trsLink}>            
            <span style={{ marginLeft: '0.5em' }}>
                <MotifButton variant="primary" type="button">
                    Taxable REIT Subsidiaries
                </MotifButton>
            </span>
          </Link>
        );
      } else {
        return null;
      }
    };

    const securitiesSnapshotLinkBuilder = test => {
      if (test === '10% Securities (Value & Vote)' && (this.props.authenticationScope && this.props.authenticationScope !== 'external') && this.props.clientId) {
        const securitiesSnapshotLink = `/client/${this.props.clientId}/reit/${
          this.props.reitId
          }/period/${this.props.periodId}/securitiesSnapshot`;

          return (
            
          <Link to={securitiesSnapshotLink}>            
            <span style={{ marginLeft: '0.5em' }}>
                <MotifButton variant="primary" type="button">
                          Securities Snapshot
                </MotifButton>
            </span>
          </Link>
        );
      } else {
        return null;
      }
    };

    return (    
      <div style={{ marginBottom: '20px', overflowX: 'auto' }}>
        <table
          className="table-responsive rtable detail-test-table"
          style={{
            width: `${width}px`,
            tableLayout: 'fixed',
          }}
        >
          <thead>
            <tr>
                <th>
                    <div>
                        <span> {this.props.assetDetail.test}{' '} </span>
                        {trsLinkBuilder(this.props.assetDetail.test)} 
                        {securitiesSnapshotLinkBuilder(this.props.assetDetail.test)}
                    </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {detailTestTableHelpers.getInnerTables(
                  this.props.assetDetail,
                  this.props.properties,
                  this.props.tbMode,
                  width,
                  height,
                  'ASSET',
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

AssetDetailTestTable.propTypes = {
  assetDetail: PropTypes.object,
  properties: PropTypes.array,
  tbMode: PropTypes.number,
  clientId: PropTypes.number,
  reitId: PropTypes.number,
  periodId: PropTypes.number,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {
    authenticationScope: state.authenticationScope
  };
}
export default withWindowDimensions(connect(mapStateToProps)(AssetDetailTestTable));

