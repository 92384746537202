import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

/**
 * This reducer handles the LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS action and sets user acknowledgement history data in the store
 * @param {Object} [state] The user acknowledgement history data currently in the store, or the initial state of user acknowledgement history data
 * @param {Object} action The action to be handled
 * @returns {Object} The user acknowledgement history data
 */
export default function dataPurgeUserAcknowledgementHistoryDataReducer(state = initialState.dataPurgeUserAcknowledgementHistoryData, action) {
    if (action.type == actions.LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS && Array.isArray(action.dataPurgeUserAcknowledgementHistoryData)) {
        return action.dataPurgeUserAcknowledgementHistoryData;
    } else if (action.type == actions.LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE) {
        return initialState.dataPurgeUserAcknowledgementHistoryData;
    } else {
        return state;
    }
}