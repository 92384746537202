import * as React from "react";
import { EditorUtils } from "@progress/kendo-react-editor";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Tags from '../../constants/customFdrTags';

export class InsertTagTool extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);
        this.defaultItem = { tag: props.defaultValue };
        this.valueTags = Tags.filter(x => x.type === 'value');
        this.sectionTags = Tags.filter(x => x.type === 'section');
    }

    handleChange(event) {
        if (!event.target.value.tag) {
            return;
        }

        const { view } = this.props;
        const tr = view.state.tr.insertText(event.target.value.tag);
        const newState = view.state.apply(tr);
        view.updateState(newState);

        view.focus();
    }

    render() {
        const { view } = this.props;
        const nodeType = view && view.state.schema.nodes.text;
        const canInsert = view && EditorUtils.canInsert(view.state, nodeType);

        return (
            <DropDownList
                data={this.defaultItem.tag === 'Insert Single Value' ? this.valueTags : this.sectionTags}
                textField="tag"
                dataItemKey="tag"
                defaultItem={this.defaultItem}
                value={this.defaultItem}
                disabled={!canInsert}
                onChange={this.handleChange}
                style={{ userSelect: "none" }}
                popupSettings={{ width: 'fit-content' }}
            />
        );
    }
}