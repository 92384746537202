import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the TOGGLE_NOTIFICATIONS_DISPLAY action and sets the show notifications in the store
 * @param {Object} [state] The show notifications currently in the store, or the initial state of the show notifications
 * @param {Object} action The action to be handled
 * @returns {Object} The show notifications
 */
export default function showBulkUploadModal(state = initialState.showBulkUploadModal, action) {
    switch (action.type) {
        case actions.SHOW_BULK_UPLOAD_MODAL:
            return true;
        case actions.HIDE_BULK_UPLOAD_MODAL:
            return false;
        default:
            return state;
    }
}