import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the TB_ADD_FILES_TO_QUEUE and TB_UPLOAD_START actions and sets the upload queue in the store
 * @param {Object} [state] The upload queue currently in the store, or the initial state of the upload queue
 * @param {Object} action The action to be handled
 * @returns {Object} The upload queue
 */
export default function uploadQueue(state = initialState.uploadQueue, action) {
    switch (action.type) {
        case actions.TB_ADD_FILES_TO_QUEUE:
            if (Array.isArray(action.filesToProcess)) {
                return state.concat(action.filesToProcess);
            }
            return state;
        case actions.TB_UPLOAD_START:
            return [...state.slice(1)]; // If begin is greater than the length of the sequence, an empty array is returned.
        default:
            return state;
    }
}