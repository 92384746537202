import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Table, ColumnGroup, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import FormattedCurrency from '../components/shared/FormattedCurrency';

/**
 * The breakout balance column control
 * @param {object} item The breakout item
 * @param {number} propertyID The property identifier
 * @param {boolean} isConsolidated True if the column is consolidated, otherwise false
 * @returns {object} The breakout balance column
 */
export const BreakoutBalanceColumn = ({
  item,
  propertyID,
  isConsolidated,
  ...props
}) => {
  const breakoutItem =
    (isConsolidated && item) ||
    item.breakout.find(
      x => (!propertyID && !x.propertyID) || x.propertyID === propertyID,
    );
  const balance = (breakoutItem && breakoutItem.balance) || 0;

  return (
    <Cell {...props} className="tright">
      <FormattedCurrency
        value={balance}
        currency="USD"
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    </Cell>
  );
};

BreakoutBalanceColumn.propTypes = {
  item: PropTypes.object,
  propertyID: PropTypes.number,
  isConsolidated: PropTypes.bool,
};

/**
 * The breakout balance percentage column control
 * @param {object} item The breakout item
 * @param {number} propertyID The property identifier
 * @param {boolean} isConsolidated True if the column is consolidated, otherwise false
 * @returns {object} The breakout balance percentage column
 */
export const BreakoutPercentageColumn = ({
  item,
  propertyID,
  isConsolidated,
  ...props
}) => {
  const breakoutItem =
    (isConsolidated && item) ||
    item.breakout.find(
      x => (!propertyID && !x.propertyID) || x.propertyID === propertyID,
    );
  let percent = 0;
  if (breakoutItem && breakoutItem.percentage) {
    percent = breakoutItem.percentage / 100;
  } else if (breakoutItem && breakoutItem.percentOfGrossTotal) {
    percent = breakoutItem.percentOfGrossTotal / 100;
  }

  return (
    <Cell {...props} className="tright">
      <FormattedNumber
        value={percent}
        style="percent"
        minimumFractionDigits={3}
        maximumFractionDigits={3}
      />
    </Cell>
  );
};

BreakoutPercentageColumn.propTypes = {
  item: PropTypes.object,
  propertyID: PropTypes.number,
  isConsolidated: PropTypes.bool,
};

/**
 * Gets the inner tables
 * @param {object} incomeDetail The income detail
 * @param {Array} properties The properties
 * @param {number} tbMode The trial balance mode
 * @param {number} containerWidth The width of the container
 * @param {number} containerHeight The height of the container
 * @param {string} testType The type of the test
 * @returns {Array} The inner tables
 */
export function getInnerTables(
  incomeDetail,
  properties,
  tbMode,
  containerWidth,
  containerHeight,
  testType,
) {
  let tables = [];

  if (Array.isArray(incomeDetail.categories)) {
    for (let i = 0; i < incomeDetail.categories.length; i++) {
      tables.push(
        getDetailTable(
          i,
          incomeDetail.categories[i],
          (tbMode === 1 && properties) || null,
          incomeDetail.isFourColumn,
          containerWidth,
          containerHeight,
          testType,
        ),
      );
    }
  }

  return tables;
}

/**
 * Gets the variable column width
 * @param {number} columnCount The count of columns
 * @param {number} containerWidth The width of the container
 * @param {number} firstColumnWidth The width of the first column
 * @returns {number} The variable column width
 */
export function getVariableColumnWidth(
  columnCount,
  containerWidth,
  firstColumnWidth,
) {
  let variableColumnCount = columnCount - 1;
  let variableColumnWidth =
    (containerWidth - firstColumnWidth) / variableColumnCount;

  let absoluteMinimumColumnWidth = 110;

  if (variableColumnWidth < absoluteMinimumColumnWidth) {
    variableColumnWidth = absoluteMinimumColumnWidth;
  }

  return variableColumnWidth;
}

/**
 * Gets the table width
 * @param {number} columnCount The count of columns
 * @param {number} containerWidth The width of the container
 * @param {number} firstColumnWidth The width of the first column
 * @returns {number} The table width
 */
export function getTableWidth(columnCount, containerWidth, firstColumnWidth) {
  let tableWidth = 0;

  let variableColumnCount = columnCount - 1;

  let sumOfColumnsWidth =
    firstColumnWidth +
    variableColumnCount *
    getVariableColumnWidth(
      columnCount,
      containerWidth,
      firstColumnWidth,
    );
  tableWidth = containerWidth;
  if (sumOfColumnsWidth < tableWidth) {
    tableWidth = sumOfColumnsWidth;
  }

  return tableWidth;
}

/**
 * Gets the header height
 * @param {number} headerHeight The height of the header
 * @param {number} columnCount The count of columns
 * @param {number} containerWidth The width of the container
 * @param {number} firstColumnWidth The width of the first column
 * @param {Array} properties The properties
 * @returns {number} The header height
 */
export function getHeaderHeight(
  headerHeight,
  columnCount,
  containerWidth,
  firstColumnWidth,
  properties,
) {
  if (
    properties === undefined ||
    properties === null ||
    (properties && properties.length === 0)
  ) {
    return headerHeight;
  }

  let maxLength = 0;
  for (let i = 0; i < properties.length; i++) {
    if (properties[i].propertyName.length > maxLength) {
      maxLength = properties[i].propertyName.length;
    }
  }

  // 13 letters fit in 100 width, add new row height based on that ratio
  let columnWidth = getVariableColumnWidth(
    columnCount,
    containerWidth,
    firstColumnWidth,
  );
  let divideBy = (columnWidth * 13) / 100;
  let additionalRowsNeeded = Math.floor(maxLength / divideBy);

  let additionalHeight = additionalRowsNeeded * headerHeight;

  return headerHeight + additionalHeight;
}

/**
 * Gets the group header height
 * @param {number} headerHeight The height of the header
 * @param {number} columnCount The count of columns
 * @param {number} containerWidth The width of the container
 * @param {number} firstColumnWidth The width of the first column
 * @param {Array} properties The properties
 * @returns {number} The header height
 */
export function getGroupHeaderHeight(
  headerHeight,
  columnCount,
  containerWidth,
  firstColumnWidth,
  properties,
) {
  if (
    properties === undefined ||
    properties === null ||
    (properties && properties.length === 0)
  ) {
    return headerHeight;
  }

  let maxLength = 0;
  for (let i = 0; i < properties.length; i++) {
    if (properties[i].propertyName.length > maxLength) {
      maxLength = properties[i].propertyName.length;
    }
  }

  // 13 letters fit in 100 width, add new row height based on that ratio
  let columnWidth = getVariableColumnWidth(
    columnCount - 1,
    containerWidth,
    firstColumnWidth,
  );

  // Group header covers 2 columns
  let groupColumnsWidth = 2 * columnWidth;
  let divideBy = (groupColumnsWidth * 13) / 100;
  let additionalRowsNeeded = Math.floor(maxLength / divideBy);
  let additionalHeight = 0;

  additionalHeight = additionalRowsNeeded * headerHeight;

  return headerHeight + additionalHeight;
}

/**
 * Gets the detail table
 * @param {number} index The index of the table
 * @param {object} category The category
 * @param {Array} properties The properties
 * @param {boolean} isFourColumn True if the table is four-column, otherwise false
 * @param {number} containerWidth The width of the container
 * @param {number} containerHeight The height of the container
 * @param {string} testType The type of test
 * @returns {object} The detail table
 */
export function getDetailTable(
  index,
  category,
  properties,
  isFourColumn,
  containerWidth,
  containerHeight,
  testType,
) {
  containerWidth = containerWidth - 2;
  const rows = (Array.isArray(category.items) && category.items) || [];
  const rowHeight = 60;

  let pctGrossColumnTitle = '% Gross Assets';
  if (testType === 'INCOME') {
    pctGrossColumnTitle = '% Gross Income';
  }

  let columnCount = 0;
  if (isFourColumn) {
    columnCount = 5;
  } else if (Array.isArray(properties)) {
    columnCount = 5 + properties.length * 2;
  } else {
    columnCount = 3;
  }

  const firstColumnWidth = 500;
  let columnWidth = getVariableColumnWidth(
    columnCount,
    containerWidth,
    firstColumnWidth,
  );

  const rowCount = category.items.length;
  let headerHeight = getHeaderHeight(
    40,
    columnCount,
    containerWidth,
    firstColumnWidth,
    properties,
  );
  let tableHeight = rowHeight * rowCount + headerHeight + 53;

  if (rowCount === 0) {
    tableHeight = rowHeight + 53;
  }

  const getDescriptionCell = ({ rowIndex, ...props }) => {
    const data = rows[rowIndex].description;
    return <Cell {...props}>{data}</Cell>;
  };

  getDescriptionCell.propTypes = {
    rowIndex: PropTypes.number,
  };

  if (isFourColumn) {
    if (rows.length === 0) {
      return (
        <Table
          rowHeight={rowHeight}
          rowsCount={1}
          width={containerWidth}
          height={tableHeight}
          headerHeight={50}
          key={'table-' + index}
        >
          <Column
            header={<Cell>{category.categoryName}</Cell>}
            cell={
              <Cell>
                <i>No securities to show</i>
              </Cell>
            }
            width={firstColumnWidth}
          />
          <Column
            header={<Cell className="tright">Balance</Cell>}
            cell={<Cell>&nbsp;</Cell>}
            width={columnWidth}
          />
          <Column
            header={<Cell className="tright">All Issued</Cell>}
            cell={<Cell>&nbsp;</Cell>}
            width={columnWidth}
          />
          <Column
            header={<Cell className="tright">Value Result</Cell>}
            cell={<Cell>&nbsp;</Cell>}
            width={columnWidth}
          />
          <Column
            header={<Cell className="tright">Vote Result</Cell>}
            cell={<Cell>&nbsp;</Cell>}
            width={columnWidth}
          />
        </Table>
      );
    }

    const getBalanceCell = ({ rowIndex, ...props }) => {
      const data = rows[rowIndex].balance;
      return (
        <Cell {...props} className="tright">
          <FormattedCurrency
            value={data}
            currency="USD"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </Cell>
      );
    };

    getBalanceCell.propTypes = {
      rowIndex: PropTypes.number,
    };

    const getAllIssuedCell = ({ rowIndex, ...props }) => {
      const data = rows[rowIndex].allIssued;
      return (
        <Cell {...props} className="tright">
          <FormattedCurrency
            value={data}
            currency="USD"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </Cell>
      );
    };

    getAllIssuedCell.propTypes = {
      rowIndex: PropTypes.number,
    };

    const getValueCell = ({ rowIndex, ...props }) => {
      const data =
        (rows[rowIndex].valueResult && rows[rowIndex].valueResult / 100) || 0;
      return (
        <Cell {...props} className="tright">
          <FormattedNumber
            value={data}
            style="percent"
            minimumFractionDigits={3}
            maximumFractionDigits={3}
          />
        </Cell>
      );
    };

    getValueCell.propTypes = {
      rowIndex: PropTypes.number,
    };

    const getVoteCell = ({ rowIndex, ...props }) => {
      const data =
        (rows[rowIndex].percentage && rows[rowIndex].percentage / 100) || 0;
      return (
        <Cell {...props} className="tright">
          <FormattedNumber
            value={data}
            style="percent"
            minimumFractionDigits={3}
            maximumFractionDigits={3}
          />
        </Cell>
      );
    };

    getVoteCell.propTypes = {
      rowIndex: PropTypes.number,
    };

    return (
      <Table
        rowHeight={rowHeight}
        rowsCount={rowCount}
        width={containerWidth}
        height={tableHeight}
        headerHeight={50}
        key={'table-' + index}
      >
        <Column
          header={<Cell>{category.categoryName}</Cell>}
          cell={getDescriptionCell}
          width={firstColumnWidth}
        />
        <Column
          header={<Cell className="tright">Balance</Cell>}
          cell={getBalanceCell}
          width={columnWidth}
        />
        <Column
          header={<Cell className="tright">All Issued</Cell>}
          cell={getAllIssuedCell}
          width={columnWidth}
        />
        <Column
          header={<Cell className="tright">Value Result</Cell>}
          cell={getValueCell}
          width={columnWidth}
        />
        <Column
          header={<Cell className="tright">Vote Result</Cell>}
          cell={getVoteCell}
          width={columnWidth}
        />
      </Table>
    );
  }

  const getConsolidatedBreakoutBalanceColumn = ({ rowIndex, ...props }) => {
    return (
      <BreakoutBalanceColumn
        item={rows[rowIndex]}
        isConsolidated={true}
        {...props}
      />
    );
  };

  getConsolidatedBreakoutBalanceColumn.propTypes = {
    rowIndex: PropTypes.number,
  };

  const getConsolidatedBreakoutPercentageColumn = ({ rowIndex, ...props }) => {
    return (
      <BreakoutPercentageColumn
        item={rows[rowIndex]}
        isConsolidated={true}
        {...props}
      />
    );
  };

  getConsolidatedBreakoutPercentageColumn.propTypes = {
    rowIndex: PropTypes.number,
  };

  if (
    Array.isArray(properties) &&
    properties.length > 0 &&
    category.items.some(x => Array.isArray(x.breakout) && x.breakout.length > 0)
  ) {
    let columns = [];

    columns.push(
      <ColumnGroup fixed={true} header={<Cell>&nbsp;</Cell>} key="col-group-0">
        <Column
          fixed={true}
          header={<Cell>{category.categoryName}</Cell>}
          cell={getDescriptionCell}
          width={firstColumnWidth}
        />
      </ColumnGroup>,
    );

    const getBreakoutBalanceColumn = ({ rowIndex, ...props }) => {
      return (
        <BreakoutBalanceColumn
          item={rows[rowIndex]}
          isConsolidated={false}
          {...props}
        />
      );
    };

    getBreakoutBalanceColumn.propTypes = {
      rowIndex: PropTypes.number,
    };

    const getBreakoutPercentageColumn = ({ rowIndex, ...props }) => {
      return (
        <BreakoutPercentageColumn
          item={rows[rowIndex]}
          isConsolidated={false}
          {...props}
        />
      );
    };

    getBreakoutPercentageColumn.propTypes = {
      rowIndex: PropTypes.number,
    };

    columns.push(
      <ColumnGroup
        header={<Cell className="tcenter">Consolidated</Cell>}
        key="col-group-1"
      >
        <Column
          header={<Cell className="tright">Balance</Cell>}
          cell={getConsolidatedBreakoutBalanceColumn}
          width={columnWidth}
        />
        <Column
          header={<Cell className="tright">{pctGrossColumnTitle}</Cell>}
          cell={getConsolidatedBreakoutPercentageColumn}
          width={columnWidth}
        />
      </ColumnGroup>,
    );

    columns.push(
      <ColumnGroup
        header={<Cell className="tcenter">REIT</Cell>}
        key="col-group-2"
      >
        <Column
          header={<Cell className="tright">Balance</Cell>}
          cell={getBreakoutBalanceColumn}
          width={columnWidth}
        />
        <Column
          header={<Cell className="tright">{pctGrossColumnTitle}</Cell>}
          cell={getBreakoutPercentageColumn}
          width={columnWidth}
        />
      </ColumnGroup>,
    );

    for (let i = 0; i < properties.length; i++) {
      const property = properties[i];

      const getBreakoutBalanceColumnForProp = ({ rowIndex, ...props }) => {
        return (
          <BreakoutBalanceColumn
            item={rows[rowIndex]}
            propertyID={property.propertyID}
            isConsolidated={false}
            {...props}
          />
        );
      };

      getBreakoutBalanceColumnForProp.propTypes = {
        rowIndex: PropTypes.number,
      };

      const getBreakoutPercentageColumnForProp = ({ rowIndex, ...props }) => {
        return (
          <BreakoutPercentageColumn
            item={rows[rowIndex]}
            propertyID={property.propertyID}
            isConsolidated={false}
            {...props}
          />
        );
      };

      getBreakoutPercentageColumnForProp.propTypes = {
        rowIndex: PropTypes.number,
      };

      columns.push(
        <ColumnGroup
          header={<Cell className="tcenter">{property.propertyName}</Cell>}
          key={'col-group-' + (3 + i)}
        >
          <Column
            header={<Cell className="tright">Balance</Cell>}
            cell={getBreakoutBalanceColumnForProp}
            width={columnWidth}
          />
          <Column
            header={<Cell className="tright">{pctGrossColumnTitle}</Cell>}
            cell={getBreakoutPercentageColumnForProp}
            width={columnWidth}
          />
        </ColumnGroup>,
      );
    }

    return (
      <Table
        rowHeight={rowHeight}
        rowsCount={category.items.length}
        width={getTableWidth(
          columnCount,
          containerWidth,
          firstColumnWidth,
        )}
        height={tableHeight}
        headerHeight={35}
        groupHeaderHeight={getGroupHeaderHeight(
          30,
          columnCount,
          containerWidth,
          firstColumnWidth,
          properties,
        )}
        key={'table-' + index}
      >
        {columns}
      </Table>
    );
  }

  return (
    <Table
      rowHeight={rowHeight}
      rowsCount={category.items.length}
      width={containerWidth}
      height={tableHeight}
      headerHeight={50}
      key={'table-' + index}
    >
      <Column
        header={<Cell>{category.categoryName}</Cell>}
        cell={getDescriptionCell}
        width={firstColumnWidth}
      />
      <Column
        header={<Cell className="tright">Balance</Cell>}
        cell={getConsolidatedBreakoutBalanceColumn}
        width={columnWidth}
      />
      <Column
        header={<Cell className="tright">{pctGrossColumnTitle}</Cell>}
        cell={getConsolidatedBreakoutPercentageColumn}
        width={columnWidth}
      />
    </Table>
  );
}
