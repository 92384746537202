import React from "react";
import PropTypes from "prop-types";
import VarianceBalanceDetailsRow from "./VarianceBalanceDetailsRow";
import VariancePercentageDetailsRow from "./VariancePercentageDetailsRow";
import NewOrRemovedAccountDetailsRow from "./NewOrRemovedAccountDetailsRow";

let noPercentageVariance = null;
let noBalanceVariance = null;
let noNewAccounts = null;
let noRemovedAccounts = null;

const VarianceDetailsTable = ({ testHeader, varianceDetails }) => {    
    let typeInUse = 1; //Asset type
    if (testHeader && testHeader.toLowerCase().indexOf("asset") === -1) {
        typeInUse = 2; //Income type
    }
    const percentageThreshold = Math.abs(varianceDetails.percentageThreshold);
    const balanceThreshold = Math.abs(varianceDetails.balanceThreshold);   

    const filteredModifiedAccountsPercent = varianceDetails.modifiedAccounts.filter(ma => ma.before.reitTestingAttributeCategoryTypeID === typeInUse && Math.abs(ma.balanceDeltaPercentage) > percentageThreshold);
    const filteredModifiedAccountsBalance = varianceDetails.modifiedAccounts.filter(ma => ma.before.reitTestingAttributeCategoryTypeID === typeInUse && Math.abs(ma.balanceDelta) > balanceThreshold);
    const filteredAddedAccounts = varianceDetails.addedAccounts.filter(cad => cad.reitTestingAttributeCategoryTypeID === typeInUse);
    const filteredRemovedAccounts = varianceDetails.removedAccounts.filter(cad => cad.reitTestingAttributeCategoryTypeID === typeInUse);

    if (!filteredModifiedAccountsPercent || filteredModifiedAccountsPercent.length === 0) {
        noPercentageVariance = <tr>
            <td colSpan="2">No variance above percentage threshold.</td>
        </tr>;
    } else {
        noPercentageVariance = null;
    }

    if (!filteredModifiedAccountsBalance || filteredModifiedAccountsBalance.length === 0) {
        noBalanceVariance = <tr>
            <td colSpan="2">No variance above balance threshold.</td>
        </tr>;
    } else {
        noBalanceVariance = null;
    }

    if (!filteredAddedAccounts || filteredAddedAccounts.length === 0) {
        noNewAccounts = <tr>
            <td colSpan="2">No new accounts.</td>
        </tr>;
    } else {
        noNewAccounts = null;
    }

    if (!filteredRemovedAccounts || filteredRemovedAccounts.length === 0) {
        noRemovedAccounts = <tr>
            <td colSpan="2">No removed accounts.</td>
        </tr>;
    } else {
        noRemovedAccounts = null;
    }

    return (
        <table tabIndex="0">
            <thead>
                <tr>
                    <th colSpan="2">Percentage Delta Accounts </th>
                </tr>
            </thead>
            <tbody className="variance-detail-table-tbody">                
                {noPercentageVariance}
                {filteredModifiedAccountsPercent.map(
                    (row, index) => <VariancePercentageDetailsRow key={index} row={row
                    } />)}
            </tbody>
            <thead>  
                <tr>
                    <th colSpan="2">Balance Delta Accounts </th>
                </tr>
            </thead>
            <tbody className="variance-detail-table-tbody">
                {noBalanceVariance}
                {filteredModifiedAccountsBalance.map(
                    (row, index) => <VarianceBalanceDetailsRow key={index} row={row} />)
                }
            </tbody>
            <thead>
                <tr>
                    <th colSpan="2">New Accounts </th>
                </tr>
            </thead>
            <tbody className="variance-detail-table-tbody">
                {noNewAccounts}
                {filteredAddedAccounts.map(
                    (row, index) => <NewOrRemovedAccountDetailsRow key={index} row={row} />)}
            </tbody>
            <thead>
                <tr>
                    <th colSpan="2">Removed Accounts </th>
                </tr>
            </thead>
            <tbody className="variance-detail-table-tbody">
                {noRemovedAccounts}
                {filteredRemovedAccounts.map(
                    (row, index) => <NewOrRemovedAccountDetailsRow key={index} row={row} />)}
            </tbody>
        </table>
    );
};

const clientAccountDataShape = PropTypes.shape({
    balance: PropTypes.number,
    clientAccountDescription: PropTypes.string,
    clientAccountCode: PropTypes.string,
    reitTestingAttributeCode: PropTypes.string,
    reitTestingAttributeDescription: PropTypes.string,
    type: PropTypes.number
});

VarianceDetailsTable.propTypes = {
    varianceDetails: PropTypes.shape({
        addedAccounts: PropTypes.arrayOf(clientAccountDataShape),
        modifiedAccounts: PropTypes.arrayOf(PropTypes.shape({
            after: clientAccountDataShape,
            balanceDelta: PropTypes.number,
            balanceDeltaPercentage: PropTypes.number,
            before: clientAccountDataShape
        }).isRequired),
        removedAccounts: PropTypes.arrayOf(clientAccountDataShape)
    }),
    testHeader: PropTypes.string
};

export default VarianceDetailsTable;