import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Message } from 'semantic-ui-react';
import ErrorSummary from '../shared/ErrorSummary';
import { MotifToast } from '@ey-xd/motif-react';

const FileNotifications = ({ errorResponse, tbUploadSuccess, fileUploadSuccess, handleDismissErrorMessage, handleDismissSuccessMessage }) => {
    return (
        <div>
            {(errorResponse && <ErrorSummary errorResponse={errorResponse} handleDismiss={handleDismissErrorMessage} />) || (tbUploadSuccess && (
                <MotifToast className="mt-2 mb-3" variant="success" onClose={handleDismissSuccessMessage}>Your trial balance has been uploaded to REITSuite for processing.</MotifToast>
            )) || (fileUploadSuccess && (
                <MotifToast className="mt-2 mb-3" variant="success" onClose={handleDismissSuccessMessage}>File Uploaded Successfully</MotifToast>
            ))}
        </div>
    );
};

FileNotifications.propTypes = {
    errorResponse: PropTypes.object,
    tbUploadSuccess: PropTypes.bool,
    fileUploadSuccess: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func,
    handleDismissSuccessMessage: PropTypes.func
};

export default FileNotifications;


