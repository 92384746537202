/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifAccordionGroup,
} from '@ey-xd/motif-react';
import QuestionAccordion from './QuestionAccordion';

const QuestionsComponent = ({
  questions,
  internalSectionId = 0,
  handleDeleteClick,
  handleEditClick,
  hasSection = false,
  interalParentQuestionId = 0,
  handleAddNewChildQuestion,
  handleSectionQuestionDragStart,
  handleQuestionDropComponent,
  handleAvailableQuestionDragStart,
  handleSectionChildQuestionDragStart,
  handleSectionCommentDragStart,
  dragSectionId = -1,
  dropCategory,
  handleOpenAccordion,
  handleCloseAccordion,
  visibleQuestionTypes,
  isTemplateEditedByOtherUser,
  handleDownloadAttachmentClick
}) => {
  const [isChildQuestion, setIsChildQuestion] = useState(false);

  useEffect(() => {
    if (
      questions.length > 0 &&
      'parentQuestionID' in questions[0] &&
      questions[0].parentQuestionID !== null
    ) {
      setIsChildQuestion(true);
    } else {
      setIsChildQuestion(false);
    }
  }, [hasSection, questions.length > 0]);

  return (
    <MotifAccordionGroup>
      {questions.map((question, index) => (
        <div
          key={question.questionID}
          onDragStart={(e) =>
            dropCategory == 'sectionquestion'
              ? handleSectionQuestionDragStart(e, question.questionID)
              : dropCategory == 'sectionchildquestion'
                ? handleSectionChildQuestionDragStart(e, question.questionID)
                : handleAvailableQuestionDragStart(e, question.questionID)
          }
          draggable
          className="draggable"
          style={{ minWidth: '500px' }}
          onDrop={(e) => {
            handleQuestionDropComponent(e, dropCategory, question.questionID);
          }}
        >
          <QuestionAccordion
            index={index}
            question={question}
            internalSectionId={internalSectionId}
            hasSection={hasSection}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            interalParentQuestionId={interalParentQuestionId}
            handleAddNewChildQuestion={handleAddNewChildQuestion}
            handleQuestionDropComponent={handleQuestionDropComponent}
            handleAvailableQuestionDragStart={handleAvailableQuestionDragStart}
            handleSectionChildQuestionDragStart={
              handleSectionChildQuestionDragStart
            }
            dropCategory={dropCategory}
            handleOpenAccordion={handleOpenAccordion}
            handleCloseAccordion={handleCloseAccordion}
                  visibleQuestionTypes={visibleQuestionTypes}
            isTemplateEditedByOtherUser={isTemplateEditedByOtherUser}
            handleDownloadAttachmentClick={handleDownloadAttachmentClick}
          />
        </div>
      ))}

      {hasSection && !isChildQuestion ? (
        <div
          key={dragSectionId}
          onDragStart={(e) => handleSectionCommentDragStart(e, dragSectionId)}
          draggable
          className="draggable"
          style={{ minWidth: '500px' }}
          onDrop={(e) => {
            handleQuestionDropComponent(e, 'sectioncomment', dragSectionId);
          }}
        >
          <MotifAccordion name="questionAccordion">
            <MotifAccordionTrigger>Section Comments</MotifAccordionTrigger>
            <MotifAccordionContent className="psq-custom-accordion-question-list">
              <div className="container-fluid">
                <ul name="questionAccordionContent">
                  <li />
                  <li>
                    <label>
                      <h6>Question Text</h6>
                    </label>
                    <span>Section Comments</span>
                  </li>
                  <li>
                    <label>
                      <h6>Help Text</h6>
                    </label>
                    <span />
                  </li>
                  <li>
                    <label>
                      <h6>Question Tag</h6>
                    </label>
                    <span />
                  </li>
                  <li>
                    <label>
                      <h6>Type</h6>
                    </label>
                    <span>Text</span>
                  </li>
                </ul>
              </div>
            </MotifAccordionContent>
          </MotifAccordion>
        </div>
      ) : (
        <></>
      )}
    </MotifAccordionGroup>
  );
};

QuestionsComponent.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      isLocked: PropTypes.bool.isRequired,
      picklistItems: PropTypes.array,
    })
  ).isRequired,
  internalSectionId: PropTypes.number,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  hasSection: PropTypes.bool,
  handleAddNewChildQuestion: PropTypes.func,
  handleOpenAccordion: PropTypes.func,
  handleCloseAccordion: PropTypes.func,
  interalParentQuestionId: PropTypes.number,
  handleSectionQuestionDragStart: PropTypes.func,
  handleQuestionDropComponent: PropTypes.func,
  handleAvailableQuestionDragStart: PropTypes.func,
  handleSectionChildQuestionDragStart: PropTypes.func,
  handleSectionCommentDragStart: PropTypes.func,
  dragSectionId: PropTypes.number,
  dropCategory: PropTypes.string,
  visibleQuestionTypes: PropTypes.array,
  handleDownloadAttachmentClick:PropTypes.func
};

export default QuestionsComponent;
