import { configureStore } from '@reduxjs/toolkit';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createRootReducer from '../reducers';
import { createBrowserHistory } from 'history';
import * as actions from '../actions/actionTypes';

/**
 * Configure the state store in production mode by applying middleware and wiring up the {@link rootReducer}.
 * This method will only be triggered if process.env.NODE_ENV is set to 'production'. See {@link configureStoreDev}
 * @param {Object} initialState The initial state of the store
 * @returns {Store<any>} The configured state store
 */

export const history = createBrowserHistory();


/**
 * Middleware that handles pending tasks in the Redux store.
 * @param {Object} store - The Redux store object.
 * @returns {Function} - The middleware function.
 */
const pendingTasksMiddleware = store => next => action => {
  // Check if the action type is not TASK_BEGIN, TASK_END, and if action.pendingTasks is defined
  if (action.type !== actions.TASK_BEGIN && action.type !== actions.TASK_END && action.pendingTask !== undefined) {
    // Dispatch an action to increment or decrement the pending tasks count
    store.dispatch({ type: actions.UPDATE_PENDING_TASKS, payload: action.pendingTask });
  }

  // Dispatch the action
  let result = next(action);

  // Return the result
  return result;
};

function configureStoreProd(initialState) {
  const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: true }).prepend(pendingTasksMiddleware),
    preloadedState: initialState,
  });

  return store;
}

/**
 * Configure the state store in development mode by applying middleware and wiring up the {@link rootReducer}.
 * This method will be triggered as long as process.env.NODE_ENV is not set to 'production'. See {@link configureStoreProd}
 * @param {Object} initialState The initial state of the store
 * @returns {Store<any>} The configured state store
 */
function configureStoreDev(initialState) {
  // We are using reduxImmutableStateInvariant middleware in DEV to ensure that the state in Redux remains immutable, preventing accidental mutations.
  const immutableStateMiddleware = reduxImmutableStateInvariant({
    ignore: ['uploadQueue']
  });

  const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: true })
        .prepend(immutableStateMiddleware)
        .prepend(pendingTasksMiddleware),
    preloadedState: initialState,
    devTools: window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  });

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('../reducers', () => {
      const newRootReducer = require('../reducers').default;
      store.replaceReducer(newRootReducer());
    })
  }
  return store;
}


export const store = process.env.NODE_ENV === 'production' ? configureStoreProd() : configureStoreDev();

