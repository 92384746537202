import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_MAPPING_SUCCESS action and sets a periods mappings in the store
 * @param {Object} [state] The mapping currently in the store, or the initial state of period mappings
 * @param {Object} action The action to be handled
 * @returns {Object} The mapping
 */
export default function mapping(state = initialState.mapping, action) {
    switch (action.type) {
    case actions.LOAD_MAPPING_SUCCESS:
        return action.mapping;
    case actions.LOAD_MAPPING_FAILURE:
    case actions.UNLOAD_FILE_MANAGEMENT:
        return initialState.mapping;
    default:
        return state;
    }
}