import React from 'react';
import PropTypes from 'prop-types';
import IncomeDetailTestTable from "./IncomeDetailTestTable";
import PropertyDetailTestTable from "./PropertyDetailTestTable";
import PropertyItsiTestTable from "./PropertyItsiTestTable";

const IncomeDetails = ({ incomeDetails, propertyDetails, propertyItsi, properties, tbMode }) => {
    return (
        <div>
            <div className="rtable">
                <h6 className="pageheader">Income Test Details</h6>
            </div>
            <section className="rtable">
                {// ensure there are categories and items within categories
                    !incomeDetails.every(d =>
                        !Array.isArray(d.categories) || d.categories.length === 0 || d.categories.every(c => !Array.isArray(c.items) || c.items.length === 0)) &&
                    incomeDetails.map(incomeDetail =>
                        <IncomeDetailTestTable key={incomeDetail.test} detail={incomeDetail} properties={properties} tbMode={tbMode} />)}
            </section>
            <section className="rtable">
                <PropertyItsiTestTable itsi={propertyItsi} />
            </section>
            <section className="rtable">
                <PropertyDetailTestTable detail={propertyDetails} />
            </section>
        </div>
    );
};

IncomeDetails.propTypes = {
    incomeDetails: PropTypes.array.isRequired,
    propertyDetails: PropTypes.object,
    propertyItsi: PropTypes.object,
    properties: PropTypes.array,
    tbMode: PropTypes.number
};

export default IncomeDetails;