import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import FormattedCurrency from "../shared/FormattedCurrency";

const PropertyDetailRow = ({ item }) => {
    if (item.hasTrialBalance) {
        return (
            <tr>
                <td width="10%">{item.propertyName}</td>
                <td className="tright" width="9%"><FormattedCurrency value={item.personalPropertyValue} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="9%"><FormattedCurrency value={item.buildingValue} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="9%"><FormattedCurrency value={item.landValue} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="9%"> {item.priorTo2020 ? "N/A" : <FormattedCurrency value={item.personalPropertyValuePrior} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />}</td>
                <td className="tright" width="9%"> {item.priorTo2020 ? "N/A" : <FormattedCurrency value={item.buildingValuePrior} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />}</td>
                <td className="tright" width="9%"> {item.priorTo2020 ? "N/A" : <FormattedCurrency value={item.landValuePrior} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />}</td>
                <td className="tright" width="9%"> {item.priorTo2020 ? "N/A" : <FormattedCurrency value={item.personalPropertyValueAverage} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />}</td>
                <td className="tright" width="9%"> {item.priorTo2020 ? "N/A" : <FormattedCurrency value={item.buildingValueAverage} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />}</td>
                <td className="tright" width="9%"> {item.priorTo2020 ? "N/A" : <FormattedCurrency value={item.landValueAverage} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />}</td>
                <td className="tright" width="9%"><FormattedNumber value={(item.result != 0 && item.result / 100) || 0} style="percent" minimumFractionDigits={3} maximumFractionDigits={3} /></td>
            </tr>
        );
    }
    return (
        <tr>
            <td width="10%">{item.propertyName}</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
            <td className="tright" width="9%">N/A</td>
        </tr>
    );
};

PropertyDetailRow.propTypes = {
    item: PropTypes.object
};

export default PropertyDetailRow;