import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ExternalMain from '../containers/ExternalMain';
import PermExternalNavBar from './PermExternalNavBar';
import MaintainLoginModal from './MaintainLoginModal';
import * as authActions from '../actions/authActions';
import * as authenticationActions from '../actions/authenticationActions';
import * as actions from '../actions/actionTypes';
import { store } from '../store/configureStore';
import { LicenseManager } from 'ag-grid-enterprise';
import { withRouter } from '../common/withRouter';

import {
  withMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

//Set AG Grid license key
LicenseManager.setLicenseKey(process.env.AG_GRID_UI_LICENSE);

/**
 * The ExternalApp class.  This is the parent component for all routes.
 * @extends {React.Component}
 */
class PermExternalApp extends React.Component {
  /**
   * Creates a new ExternalApp
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.establishedUser = false;
    this.createdRouterListener = false;
    this.fetchAuthorizations = false;
    this.lastPathname = '';

    this.state = {
      showMaintainLoginModal: false,
      message: 'Authentication in progress...',
    };

    this.useIdToken = this.useIdToken.bind(this);
    this.handleUserModalLogin = this.handleUserModalLogin.bind(this);
    this.handleUserLogOut = this.handleUserLogOut.bind(this);
  }

  componentDidMount() {
      this.useIdToken();
  }

  componentWillUnmount() {
    clearTimeout(this.idTokenRefreshTimer);
  }

  componentDidUpdate(prevProps) {
    if (!this.establishedUser && this.props.currentUser) {
      this.establishedUser = true;

      // Token renewal modal toggle timeout
      this.idTokenRefreshTimer = setTimeout(() => {
        this.setState({
          showMaintainLoginModal: true,
        });
      }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);

      if (!this.createdRouterListener) {
        this.createdRouterListener = true;

        if (this.props.router.location.pathname) {
          const pathname = location.pathname;
          if (pathname !== this.lastPathname) {
            this.lastPathname = pathname;
            const accounts = this.props.msalContext.instance.getAllAccounts();
            if (accounts[0]?.idTokenClaims.exp) {
              // Look for page changes here to trigger that user is still "active"
              const exp = accounts[0].idTokenClaims.exp;

              let expDate = new Date(0);
              expDate.setUTCSeconds(exp);

              let curDate = new Date();

              const diff = expDate.getTime() - curDate.getTime();

              //console.log(`ROUTE CHANGED: Token Exp: ${expDate}. Diff: ${diff}. ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS: ${process.env.ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS}`)
              if (diff < process.env.ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS) {
                this.useIdToken().then(() => {
                  clearTimeout(this.idTokenRefreshTimer);
                  this.idTokenRefreshTimer = setTimeout(() => {
                    this.setState({
                      showMaintainLoginModal: true,
                    });
                  }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);
                });
              }
            }
          }
        }
      }

      // if (nextProps.currentUser) {
      //     notificationActions.pollNotifications(store.dispatch, nextProps.currentUser);
      // }
    }
    // If user is logged in as internal, but still on the "PermExternalApp" container, we need to route to internal app.
    // This can happen if user manually navigates to an external URL, then clicks that they are an EY employee.
    if (this.props.authenticationScope === 'internal') {
      this.props.router.navigate('/');
      return;
    }

    // if we have not already fetched the authorizations
    // and both idToken and currentUser are populated
    // then fetch authorizations
    if (
      !this.fetchAuthorizations &&
      this.props.idToken &&
      this.props.currentUser
    ) {
      this.fetchAuthorizations = true;
      
      // Fetch authorizations
      this.props.authActions
        .fetchAuthorizationsForCurrentUser(this.props.currentUser)
        .then((currentUserAuthorizations) => {
          // If authorizations contain any External Permanent User role (role == 4)
          if (currentUserAuthorizations.some((x) => x.role === 4)) {
            this.props.authenticationActions.setAuthenticationScope('external');
          } else {
            this.props.authenticationActions.setAuthenticationScope('internal');
          }         

          // If they have any authorizations, continue with redirect
          // else, they do not have authorization to the App
          if (currentUserAuthorizations.length > 0  && currentUserAuthorizations.some((x) => x.role !== 3)) {
            // If authorizations found, clear message
            this.setState({ message: null });
          } else {
            this.setState({
              message:
                'You are not authorized to use this application. Please contact your Administrator to obtain access.',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ message: 'Failed to authorize. Please try again.' });
        });
    }
  }

  async useIdToken() {
    const { instance } = this.props.msalContext;
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    // If inProgress is not None, then we are probably in the middle of a redirect login. Wait until status is back to None before continuing.
    while (this.props.msalContext.inProgress !== InteractionStatus.None) {
      await sleep(100);
    }

    const accounts = instance.getAllAccounts();

    //console.log('useIdToken: accounts', accounts)
    if (accounts.length > 0) {
      const request = {
        scopes: ['user.read', 'openid', 'profile', 'offline_access'],
        account: accounts[0],
        forceRefresh: true,
        //state: window.location.href
      };
      //console.log('useIdToken: Calling acquireTokenSilent')
      const silentResponse = await instance
        .acquireTokenSilent(request)
        .then((response) => {
          //console.log('useIdToken: acquireTokenSilent response: ', response);
          return response;
        })
        .catch(async (error) => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            return await instance.acquireTokenRedirect(request);
          } else {
            throw error;
          }
        });

      //console.log('silentResponse', silentResponse);
      
      // Setting the ID_Token to the store
      await store.dispatch({
        type: actions.SET_ID_TOKEN,
        idToken: silentResponse.idToken,
      });

      // Setting the Access_Token to the store
      await store.dispatch({
        type: actions.SET_ACCESS_TOKEN,
        accessToken: silentResponse.accessToken,
      });

      await this.props.authenticationActions.setCurrentUser(
        silentResponse.idTokenClaims,
      );
    } else {
      const authRequest = {
        scopes: ['user.read', 'openid', 'profile', 'offline_access'],
        state: window.location.href,
      };

      instance.loginRedirect(authRequest);
    }
  }
  
  toggleShowMaintainTemplateModal() {
    this.setState({
      showMaintainLoginModal: !this.state.showMaintainLoginModal,
    });
  }

  handleUserModalLogin(modalExpired) {
    if (!modalExpired) {
      this.toggleShowMaintainTemplateModal();

      // Calling useIdToken, which will issue silent renew or redirect if needed
      this.useIdToken().then(() => {
        clearTimeout(this.idTokenRefreshTimer);
        this.idTokenRefreshTimer = setTimeout(() => {
          this.setState({
            showMaintainLoginModal: true,
          });
        }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);
      });
    } else {
      // We are expired. Reload entire page to login again.
      window.location.reload();
    }
  }

  handleUserLogOut() {
    this.props.router.navigate(`/external/logoff`);
  }

  render() {
    return (
      <IntlProvider locale="en">
        {this.state.message ? (
          <p>{this.state.message}</p>
        ) : (
          <React.Fragment>
            {this.props.idToken ? (
              <AuthenticatedTemplate>
                <div className="container-fluid no-padding">
                  <MaintainLoginModal
                    showMaintainTemplateModal={
                      this.state.showMaintainLoginModal
                    }
                    handleYes={this.handleUserModalLogin}
                    handleNo={this.handleUserLogOut}
                    toggleShowMaintainTemplateModal={
                      this.toggleShowMaintainTemplateModal
                    }
                  />
                  <PermExternalNavBar />
                  <ExternalMain isPermExternalApp={true}><Outlet /></ExternalMain>
                </div>
              </AuthenticatedTemplate>
            ) : (
              <p>Authentication in progress...</p>
            )}
            <UnauthenticatedTemplate>
              Authentication in progress...
            </UnauthenticatedTemplate>
          </React.Fragment>
        )}
      </IntlProvider>
    );
  }
}

PermExternalApp.propTypes = {
  user: PropTypes.object,
  authenticationScope: PropTypes.string,
  currentUserAuthorizations: PropTypes.array,
  authActions: PropTypes.object,
  authenticationActions: PropTypes.object,
  idToken: PropTypes.string,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {
    authenticationScope: state.authenticationScope,
    currentUser: state.authentication.currentUser,
    idToken: state.authentication.idToken,
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMsal(PermExternalApp)));
