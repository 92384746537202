import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_FINAL_REPORT_SUCCESS action
 * @param {Array} [state] The clients currently in the store, or the initial state of clients
 * @param {Object} action The action to be handled
 * @returns {Array} The clients
 */
export default function finalReport(state = initialState.finalReport, action) {
    switch (action.type) {
        case actions.LOAD_FINAL_REPORT_SUCCESS:
            if (action.finalReport === undefined) {
                return null;
            }

            return action.finalReport;
        case actions.LOAD_FINAL_REPORT_FAILURE:
        case actions.UPLOAD_FINAL_REPORT_SUCCESS:
        case actions.UNLOAD_FILE_MANAGEMENT:

            return initialState.finalReport;
        default:
            return state;
    }
}