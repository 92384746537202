import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import AddClientAuthModal from "./AddClientAuthModal";
import EditClientAuthModal from "./EditClientAuthModal";
import {
    MotifFormField, MotifSearch, MotifLabel, MotifButton, MotifToggleSwitch,
    MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem,
    MotifTable
} from '@ey-xd/motif-react';
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged, handleFirstDataRendered, handleGridReady, tableHeaderClass, handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomPagination from '../../common/MotifPagination';
import AuthCellRenderer from './customRenderer/AuthCellRenderer';

const ClientAuthList = ({ auths, handleSearchChange, handleClearSearchText, clientUserSearchText, handleEditButtonClick, handleDeleteButtonClick,
    handleNewButtonClick, showAddClientAuthModal, handleAddClientAuthModalAction, auth, toggleShowAddClientAuthModal, currentUserId, showEditClientAuthModal,
    handleEditClientAuthModalAction, toggleShowEditClientAuthModal, currentUserIsSysAdmin, addAuthMessageText,maxLimitMessageText, numberOfEngagementAdmins, validateDomain,
    validateDomainWithRole, clientAuthFormValues,
    handleAddClientAuthModalValidateAction, validatingUser, myEyError, handleDailyNewsletter, receiveDailyNewsletter, receiveWeeklyNewsletter, handleWeeklyNewsletter, receiveRealTimeNotification
    , handleRealTimeNotification, receiveRealTimeNotificationForNewPsqArqcSent,handleGoToClientOverview,clientId,
    handleInternalReviewNotification, handleOnRoleTypeChange, handleGridChanges, isDataSteward, handleDataSteward, isAuthorizedUserSearchBoxVisible, dataStewardCheckboxDisplay, showDSError, dataStewardValue, buttonVarient, parentComponent
}) => {

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const getColumnDefs = () => {
        return [
            {
                field: 'userId', headerName: 'User', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 350,
                comparator: (a, b) => {
                    var valA = a.toString().toLowerCase();
                    var valB = b.toString().toLowerCase();
                    if (valA === valB) return 0;
                    return valA > valB ? 1 : -1;
                }, 	
            },
            {
                field: 'userId', headerName: 'Role', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 120,maxWidth: 120,
                valueGetter: (params) => {
                    return getRoleName(params.data.role);
                },
                wrapText: true,
            },          
            {
                field: 'isDataSteward', headerName: 'Data Steward', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 120,
                valueGetter: (params) => {
                    const value = params.data.isDataSteward ? "Yes" : "No";
                    return value
                },
            },
            {
                field: 'receiveDailyNewsletter', headerName: 'Daily Newsletter', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 120,
                valueGetter: (params) => {
                    const value =
                        params.data.receiveDailyNewsletter ? "Yes" : "No";
                    return value
                },
            },
            {
                field: 'receiveWeeklyNewsletter', headerName: 'Weekly Newsletter', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 120,
                valueGetter: (params) => {
                    const value = params.data.receiveWeeklyNewsletter ? "Yes" : "No";
                    return value;
                },
            },
            {
                field: 'receiveRealTimeNotification', headerName: 'Reopen Submitted or Closed Checklist', 
                sortable: true, showRowGroup: false, hide: false, rowGroup: false,  minWidth: 200,
                valueGetter: (params) => {
                    const value =
                        params.data.receiveRealTimeNotification ? "Yes" : "No";
                    return value
                },         
            },   
            
            {
                field: 'receiveRealTimeNotificationForNewPsqArqcSent', headerName: 'PSQ/ARQC Sent/Internal Review Notification', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 200,maxWidth: 200,
                valueGetter: (params) => {
                    const value =params.data.receiveRealTimeNotificationForNewPsqArqcSent ? "Yes" : "No";
                    return value
                },
            },
            {
                headerName: '', suppressMenu: true, showRowGroup: false, rowGroup: false,
                minWidth: 150,
                cellRenderer: AuthCellRenderer, cellRendererParams: { handleEditButtonClick, handleDeleteButtonClick},
            }];
    };

    const getRoleName = (role) => {
        if (role === 1) {
            if (auth.resource === 1) {
                role = "System Administrator";
            } else {
                role = "Engagement Administrator";
            }
        }
        else if (role == 4) {
            role = "External Checklist User";
        }
        else {
            role = "User"
        }
        return role;
    };

    //Handle deselect all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);      
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
        if (rowData.length > 0 && gridRef?.current?.api !== undefined) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);

    useEffect(() => {
        setRowData(auths);
        if (gridRef && gridRef.current && gridRef.current.api) {

            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [auths]);

    const rowHeight = 87; 
    const headerHeight = 75; 
    const totalHeight = (rowData?.length <= 3 ? rowData?.length : 3) * rowHeight + headerHeight; // set 3 rows as default

    return (
        <section className="clientAuths">
            <div className="row">
                <div className="col-md-4">
                    <h2>Authorized Users</h2>
                </div>
                {(!isAuthorizedUserSearchBoxVisible &&
                <div className="col-md-4">
                    </div>
                )}
                {(isAuthorizedUserSearchBoxVisible &&
                <div className="col-md-4">              
                    <MotifFormField>                        
                        <MotifSearch
                            type="text"
                            onChange={handleSearchChange}
                            value={clientUserSearchText}
                            onSelect={handleClearSearchText}
                            title="Type in a user name"
                            aria-label="Search for User"                           
                            placeholder="Search for User"
                            searchButtonProps={{ tabIndex: "-1" }} />
                    </MotifFormField>                         
                </div>
                )}               
               <div className="col-md-4">
               <MotifButton variant={buttonVarient + "  right ml-2"} type="button" className="right"
                        onClick={handleNewButtonClick}>
                        Add Authorized User
                    </MotifButton>                    
                    {(!isAuthorizedUserSearchBoxVisible && parentComponent !== "DataSteward" &&
                    <MotifButton variant="secondary right ml-2" data-testid="goToClientOverview"                      
                    type="button"   onClick={() => handleGoToClientOverview(clientId)}>
                    Go to Client Overview
                    </MotifButton>
                )}         
                </div>          
            </div>
            <React.Fragment>
                <div className="mt-2">
                    {(rowData.length > 0) &&
                        <div>
                            <div>
                                <div className="row">
                                    <div className="col">
                                    <div className="ag-theme-quartz motif-table" style={{height : totalHeight}}>
                                            <MotifTable zebra={true} compact={true}
                                                rowData={rowData}
                                                columnDefs={getColumnDefs()}
                                                defaultColDef={defaultColDef}
                                                accentedSort={true}
                                                rowSelection="multiple"
                                                suppressRowClickSelection={true}
                                                onGridReady={event => handleGridReady(event, gridRef, setCurrentPage, setItemsPerPage, setTotalPages, 'REITSuiteClientListColumnState', handleDeselectAll)}
                                                onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                                ref={gridRef}
                                                pagination={true}
                                                suppressPaginationPanel={true}
                                                paginationPageSize={100}
                                                groupDisplayType={'custom'}
                                                groupSelectsChildren={true}
                                                groupDefaultExpanded={0}
                                                onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                                data-testid="divClientAuthListGrid"
                                                deltaRowDataMode={true}
                                                immutableData={true}
                                                getRowNodeId={(data) => data.id}
                                            />

                                            <div className="mt-3">                 
                                                <span className="right mb-3">
                                                    <CustomPagination
                                                        currentPage={currentPage}
                                                        handlePageChange={handlePageChange}
                                                        totalPages={totalPages}
                                                        handleItemsPerPageChange={handleItemsPerPageChange}
                                                        gridRef={gridRef}
                                                        setCurrentPage={setCurrentPage}
                                                        setItemsPerPage={setItemsPerPage}
                                                        setTotalPages={setTotalPages} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    }
                    {(auths && auths.length === 0 && clientUserSearchText === "" && <div><h5>No authorized user has been added for this client.</h5></div>) || ((auths && auths.length === 0 && clientUserSearchText !== "") && <div><h5>No authorized user match your search text &apos;{clientUserSearchText}&apos;</h5></div>) || null}

                </div>
            </React.Fragment>
            <AddClientAuthModal
                showAddClientAuthModal={showAddClientAuthModal}
                onSubmit={handleAddClientAuthModalAction}
                toggleShowAddClientAuthModal={toggleShowAddClientAuthModal}
                initialValues={auth}
                addAuthMessageText={addAuthMessageText}
                maxLimitMessageText={maxLimitMessageText}
                validateDomainWithRole={validateDomainWithRole}
                clientAuthFormValues={clientAuthFormValues}
                handleAddClientAuthModalValidateAction={handleAddClientAuthModalValidateAction}
                validatingUser={validatingUser}
                myEyError={myEyError}
                handleDailyNewsletter={handleDailyNewsletter}
                receiveDailyNewsletter={receiveDailyNewsletter}
                handleWeeklyNewsletter={handleWeeklyNewsletter}
                receiveWeeklyNewsletter={receiveWeeklyNewsletter}
                handleRealTimeNotification={handleRealTimeNotification}
                receiveRealTimeNotification={receiveRealTimeNotification}
                receiveRealTimeNotificationForNewPsqArqcSent={receiveRealTimeNotificationForNewPsqArqcSent}
                handleInternalReviewNotification={handleInternalReviewNotification}
                handleOnRoleTypeChange={handleOnRoleTypeChange}
                isDataSteward={isDataSteward}
                handleDataSteward={handleDataSteward}
                dataStewardCheckboxDisplay={dataStewardCheckboxDisplay}
                showDSError={showDSError}
                handleGoToClientOverview={handleGoToClientOverview}
            />
            <EditClientAuthModal
                showEditClientAuthModal={showEditClientAuthModal}
                onSubmit={handleEditClientAuthModalAction}
                toggleShowEditClientAuthModal={toggleShowEditClientAuthModal}
                initialValues={auth}
                clientAuthFormValues={clientAuthFormValues}
                handleDailyNewsletter={handleDailyNewsletter}
                receiveDailyNewsletter={receiveDailyNewsletter}
                handleWeeklyNewsletter={handleWeeklyNewsletter}
                receiveWeeklyNewsletter={receiveWeeklyNewsletter}
                handleRealTimeNotification={handleRealTimeNotification}
                receiveRealTimeNotification={receiveRealTimeNotification}
                receiveRealTimeNotificationForNewPsqArqcSent={receiveRealTimeNotificationForNewPsqArqcSent}
                handleInternalReviewNotification={handleInternalReviewNotification}
                isDataSteward={isDataSteward}
                handleDataSteward={handleDataSteward}
                dataStewardCheckboxDisplay={dataStewardCheckboxDisplay}
                dataStewardValue={dataStewardValue}
                showDSError={showDSError}
                handleGoToClientOverview={handleGoToClientOverview}
            />
        </section>
    );
};

ClientAuthList.propTypes = {
    auths: PropTypes.array.isRequired,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    showAddClientAuthModal: PropTypes.bool,
    handleAddClientAuthModalAction: PropTypes.func,
    auth: PropTypes.object,
    toggleShowAddClientAuthModal: PropTypes.func,
    currentUserId: PropTypes.string,
    showEditClientAuthModal: PropTypes.bool,
    handleEditClientAuthModalAction: PropTypes.func,
    toggleShowEditClientAuthModal: PropTypes.func,
    currentUserIsSysAdmin: PropTypes.bool,
    addAuthMessageText: PropTypes.string,
    maxLimitMessageText: PropTypes.string,
    numberOfEngagementAdmins: PropTypes.number,
    validateDomain: PropTypes.func,
    clientAuthFormValues: PropTypes.object,
    handleAddClientAuthModalValidateAction: PropTypes.func,
    validatingUser: PropTypes.bool,
    myEyError: PropTypes.string,
    handleDailyNewsletter: PropTypes.func,
    receiveDailyNewsletter: PropTypes.bool,
    handleWeeklyNewsletter: PropTypes.func,
    receiveWeeklyNewsletter: PropTypes.bool,
    receiveRealTimeNotification: PropTypes.bool,
    handleRealTimeNotification: PropTypes.func,
    receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
    handleInternalReviewNotification: PropTypes.func,
    handleOnRoleTypeChange: PropTypes.func,
    isDataSteward: PropTypes.bool,
    handleDataSteward: PropTypes.func,
    dataStewardCheckboxDisplay: PropTypes.bool,
    dataStewardValue: PropTypes.bool,
    showDSError: PropTypes.bool,
    clientId: PropTypes.number,
    parentComponent: PropTypes.string,
};

export default ClientAuthList;