import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifButton,
    MotifIcon
} from '@ey-xd/motif-react';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';
import { addAttribute } from '../../common/Attribute';

const IntentToEditLockExtensionModal = ({
    showIntentToEditLockExtensionModal,
    handleCancelIntentExtent,
    handleAcceptIntentExtension,
    timerDuration,
}) => {
    useEffect(() => {
        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", 'type', 'button');
    });
    let timer = 0;

    const [delay, setDelay] = useState(timerDuration);
    const minutes = Math.floor(delay / 60);
    const seconds = Math.floor(delay % 60).toString().padStart(2, '0');
    useEffect(() => {
        if (showIntentToEditLockExtensionModal && timerDuration > 0) {
            timer = setInterval(() => {
                setDelay(delay - 1);
            }, 1000);

            if (delay === 0) {
                cancelIntentExtension();
            }

            return () => {
                clearInterval(timer);
            };
        }
    });

    const cancelIntentExtension = () => {
        clearInterval(timer);
        handleCancelIntentExtent();
    }

    const acceptIntentExtension = () => {
        clearInterval(timer);
        handleAcceptIntentExtension();
    }

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Standard Child Questions Rearrange Warning Modal',
        ariaLabel: 'Close Standard Child Questions Rearrange Warning Modal',
        dataTestid: 'close',
    };

    return (
        <React.Fragment>
            <MotifModal
                variant="alt"
                show={showIntentToEditLockExtensionModal}
                onClose={handleCancelIntentExtent}
                data-testid="IntentToEditLockExtensionModal"
            >
                <MotifModalHeader
                    data-testid="h3IntentToEditLockExtensionModalHeader"
                    closeButtonProps={closeButtonProps}
                >
                    <HeaderWrapper>
                        <MotifIcon style={{ color: '#F95D54' }} iconFunction={IconoirWarningCircle} size='24' fill='none'  />
                        <span className="ml-2">Edit Question Session Information</span>
                    </HeaderWrapper>
                </MotifModalHeader>
                <MotifModalBody>
                    <p>
                        We have noticed that you are editing the question for a while. Do you need more time?
                    </p>
                    {timerDuration > 0 && (<p>Your question will auto close in {minutes}:{seconds}</p>)}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton
                        variant="secondary"
                        className="right"
                        data-testid="btnCancel"
                        type="button"
                        onClick={cancelIntentExtension}
                    >
                        Cancel Editing
                    </MotifButton>
                    <MotifButton
                        variant="primary"
                        className="right"
                        data-testid="btnAccept"
                        type="button"
                        onClick={acceptIntentExtension}
                    >
                        Keep Editing
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </React.Fragment>
    );
};

IntentToEditLockExtensionModal.propTypes = {
    showIntentToEditLockExtensionModal: PropTypes.bool,
    handleCancelIntentExtent: PropTypes.func,
    handleAcceptIntentExtension: PropTypes.func,
    timerDuration: PropTypes.number,
};

export default IntentToEditLockExtensionModal;