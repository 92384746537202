import React from 'react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

export default (props) => {

  return (
    <div>
      <div className="justify-content-end align-items-center mt-2">

        <MotifIconButton
          aria-label="Edit"
          type="button"
          data-testId="edit"
          disabled={false}
          onClick={() => props.handleEditButtonClick(props.node.data)} >
          <MotifIcon iconFunction={IconoirEditPencil} size='24' fill='none' title={`Edit "${props.node.data.userId}"`} />
        </MotifIconButton>
        
          <MotifIconButton aria-label="Delete"
            type="button"
            data-testId="delete"
            disabled={false}
            onClick={() => props.handleDeleteButtonClick(props.node.data)}>
            <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title={`Delete "${props.node.data.userId}"`} />
          </MotifIconButton>
        
      </div>

    </div>
  );
}