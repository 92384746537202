import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/assetActions";
import * as periodActions from "../../actions/periodActions";
import AssetDetails from "../../components/tb/AssetDetails";

/**
 * ChecklistAssetDetailsContainer container component
 * @extends {React.Component}
 */
export class ChecklistAssetDetailsContainer extends React.Component {
    /**
      * Creates a new ChecklistAssetDetailsContainer
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);

        this.state = { tbMode: 1 };
    }

    componentDidMount() {
        if (this.props.periodId && this.props.isSectionVisible) {

            if (!this.props.isExternal || this.props.authenticationScope === 'external') {
                this.props.actions.fetchAssetDetailsForYear(this.props.periodId);
                periodActions.fastFetchPeriodById(this.props.periodId).then((period) => {
                    if (period && period.trialBalanceModeID === 2) {
                        this.setState({ tbMode: 2 });
                    }
                });
            }

            if (this.props.isExternal && this.props.checklistId && this.props.isArqc) {
                this.props.actions.fetchAssetDetailsForYear(this.props.periodId, this.props.checklistId, "ARQC");
                periodActions.fastFetchExternalPeriodById(this.props.periodId, this.props.checklistId, "ARQC").then((period) => {
                    if (period && period.trialBalanceModeID === 2) {
                        this.setState({ tbMode: 2 });
                    }
                });
            }
        }
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        if (!this.props.assetDetailsForYear) {
            return null;
        }

        return (
            <div>
                {this.props.assetDetailsForYear.map(x => (Array.isArray(x.assetDetails) && x.assetDetails.length > 0 && <AssetDetails key={"AssetTestDetailsQ" + x.quarter} assetDetails={x.assetDetails} title={"Asset Test Details - Quarter " + x.quarter} clientId={this.props.clientId} reitId={this.props.reitId} periodId={this.props.periodId} />) || null)}
            </div>
        );
    }
}

ChecklistAssetDetailsContainer.propTypes = {
    actions: PropTypes.object,
    periodId: PropTypes.number,
    clientId: PropTypes.number,
    reitId: PropTypes.number,
    assetDetailsForYear: PropTypes.array,
    isSectionVisible: PropTypes.bool,
    checklistId: PropTypes.string,
    isExternal: PropTypes.bool,
    isArqc: PropTypes.bool,
    authenticationScope: PropTypes.string
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: ownProps.periodId,
        checklistId: ownProps.checklistId,
        clientId: state.client ? state.client.id : null,
        reitId: state.reit ? state.reit.reitid : null,
        isSectionVisible: ownProps.isSectionVisible,
        isExternal: ownProps.isExternal,
        assetDetailsForYear: state.assetDetailsForYear,
        isArqc: ownProps.isArqc,
        authenticationScope: state.authenticationScope
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistAssetDetailsContainer);