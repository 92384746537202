import PropTypes from "prop-types";
import React from "react";
import { Button, Header } from "semantic-ui-react";
import { MotifButton,MotifModal,MotifModalHeader,MotifModalBody,MotifModalFooter, MotifLabel } from "@ey-xd/motif-react";


const YesNoModal = ({ showModal, handleYesAction, handleNoAction, identifier, title, question }) => {
       
    const onYesClicked = () => {
        handleYesAction(identifier);
    };

    const onNoClicked = () => {
        handleNoAction(identifier);
    };

    return (    
            <MotifModal aria-label={title} show={showModal} onClose={onNoClicked}>
            <MotifModalHeader>{title}</MotifModalHeader>            
            <MotifModalBody>
            <form>
                <MotifLabel>{question}</MotifLabel>   
            </form>
            </MotifModalBody>
            <MotifModalFooter>
            <MotifButton variant="secondary" className="right" type="button"
                        onClick={onNoClicked}>
                        No
            </MotifButton>  
            <MotifButton variant="primary" className="right ml-2" type="button"
                        id="openButton"
                        onClick={onYesClicked}
                        data-key={identifier}>
                        Yes
            </MotifButton>           
            </MotifModalFooter>
            </MotifModal>
    );
};

YesNoModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleYesAction: PropTypes.func.isRequired,
    handleNoAction: PropTypes.func.isRequired,
    identifier: PropTypes.number,
    title: PropTypes.string,
    question: PropTypes.string,
    pendingTasks: PropTypes.number
};

export default YesNoModal;