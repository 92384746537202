import PropTypes from 'prop-types';
import React from 'react';
import {
  MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifButton, MotifInlineMessage
} from '@ey-xd/motif-react';


const CustomQuestionAttachmentListUploadStatus = ({
  uploadedAttachmentStatus,
  showAttachmentStatusModal,
  toggleShowAttachmentStatus,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center'
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'close attachment status modal',
    ariaLabel: 'close attachment status modal',
    dataTestid: 'close'
  };
  return (
    <React.Fragment>
      <MotifModal
        onClose={toggleShowAttachmentStatus}
        variant="alt"
        size='lg'
        show={showAttachmentStatusModal}
      >
        <MotifModalHeader closeButtonProps={closeButtonProps} >
          <HeaderWrapper>
            <span className="ml-2">Attachment(s) Status</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <div>
            {
              uploadedAttachmentStatus &&
              uploadedAttachmentStatus.length > 0 &&
              uploadedAttachmentStatus.map((attachmentData) => (
                <div className='row'>
                  <div className='col-md-9 checklistActionMsg attachmentsListSpace attachmentName' title={attachmentData.attachmentName}>{attachmentData.attachmentName}</div>
                  <div className='col-md-3'>
                    <MotifInlineMessage variant={attachmentData.variant}>{attachmentData.status}</MotifInlineMessage>
                  </div>
                </div>
              ))}
          </div>
        </MotifModalBody>
        <MotifModalFooter>

          <MotifButton
            type="button"
            onClick={toggleShowAttachmentStatus}
            variant="secondary"
          >
            Close
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </React.Fragment>
  );
};

CustomQuestionAttachmentListUploadStatus.propTypes = {
  uploadedAttachmentStatus: PropTypes.array,
  handleCloseAttachmentStatusModal: PropTypes.func
};

export default CustomQuestionAttachmentListUploadStatus;