import { pendingTask, begin, end } from '../constants/pendingTask';

import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch all the relevant REIT(s) - Bulk Roll Forward data for a given client. If successful this will dispatch the LOAD_BULK_ROLL_FORWARD_DATA_SUCCESS
 * action, otherwise it will dispatch the LOAD_BULK_ROLL_FORWARD_DATA_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export const fetchBulkRollForwardData = (clientId) => {
  return (dispatch) => {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_BULK_ROLL_FORWARD_DATA_SUCCESS,
        bulkRollForwardData: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/bulkRollForward?clientId=${clientId}`)
      .then((response) => {
        return response.json();
      })
      .then((bulkRollForwardData) => {
        if (actionHelpers.isErrorResponse(bulkRollForwardData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_BULK_ROLL_FORWARD_DATA_FAILURE,
            null
          );
        }
        dispatch({
          type: actions.LOAD_BULK_ROLL_FORWARD_DATA_SUCCESS,
          bulkRollForwardData,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_BULK_ROLL_FORWARD_DATA_FAILURE,
          null,
          error
        );
      });
  };
};

/**
 * Initiates the Bulk Process Operation for a given selections. If successful this will dispatch the INITIATE_BULK_PROCESS_SUCCESS
 * action, otherwise it will dispatch the INITIATE_BULK_PROCESS_FAILURE action.
 * @param {number} request The request body.
 * @returns {function} A function that returns a Promise.
 */
export const initiateBulkRollForwardProcess = (request, failureDataState) => {
  let isErrored = false;
  return (dispatch) => {
    if (!request) {
      isErrored = true;
      dispatch({
        type: actions.INITIATE_BULK_ROLL_FORWARD_PROCESS_FAILURE,
        failureDataState
      });

      return isErrored;
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/bulkRollForward/initiateBulkRollForwardProcess`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((responseResult) => {
        if (actionHelpers.isErrorResponse(responseResult)) {
          isErrored = true;
          actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.INITIATE_BULK_ROLL_FORWARD_PROCESS_FAILURE,
            failureDataState
          );

          return isErrored;
        }
        dispatch({
          type: actions.INITIATE_BULK_ROLL_FORWARD_PROCESS_SUCCESS,
          [pendingTask]: end,
        });

        return isErrored;
      })
      .catch((error) => {
        isErrored = true;
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.INITIATE_BULK_ROLL_FORWARD_PROCESS_FAILURE,
          failureDataState,
          error
        );

        return isErrored;
      });
  };
};

export const dispatchBulkRollForwardData = (message, action) => {
  return (dispatch) => {
      dispatch({ type: action, message});
  };
};