import { Provider } from 'react-redux';
import ReactModal from 'react-modal';
import App from './components/App';
import {store, history} from './store/configureStore';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import Root from "./components/Root";
ReactModal.setAppElement('div#app');
const root = ReactDOM.createRoot(document.getElementById('app'));

import '@progress/kendo-theme-bootstrap/dist/all.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import './css/styles.css';
import './css/tables.css';
import './css/index.scss';
import 'react-toastify/dist/ReactToastify.css';

root.render(
<Root store={store} history={history} /> 
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewApp = require('./components/Root').default;
    root.render(

        <NewApp store={store} history={history} />

    );
  });
}