import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import * as clearActions from './clearDependentsActions';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';

/**
 * Fetch the REITs belonging to the client. If successful this will dispatch the LOAD_REITS_SUCCESS
 * action, otherwise it will dispatch the LOAD_REITS_FAILURE action.
 * @param {number} clientId The id of the client that owns the REITs.
 * @param {bool} includeInactive A flag to include inactive REITs.
 * @returns {function} A function that returns a Promise.
 */
export function fetchReits(clientId, includeInactive) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const includeInactiveParam =
      includeInactive == undefined
        ? ''
        : includeInactive
          ? '&includeInactive=true'
          : '&includeInactive=false';
    return fetch('/reits?clientId=' + clientId + includeInactiveParam)
      .then(response => {
        return response.json();
      })
      .then(reits => {
        if (actionHelpers.isErrorResponse(reits)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_REITS_FAILURE,
            reits,
          );
        }

        dispatch({
          type: actions.LOAD_REITS_SUCCESS,
          reits,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_REITS_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the REITs belonging to the client. If successful this will dispatch the LOAD_REITS_FOR_MO_SUCCESS
 * action, otherwise it will dispatch the LOAD_REITS_FAILURE action.
 * @param {number} clientId The id of the client that owns the REITs.
 * @param {bool} includeInactive A flag to include inactive REITs.
 * @param {bool} includeFileName A flag to include trial balance file name.
 * @returns {function} A function that returns a Promise.
 */
export function fetchReitsForMO(
  clientId,
  includeInactive,
  includeTrialBalanceFileName,
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const includeInactiveParam =
      includeInactive == undefined
        ? ''
        : includeInactive
          ? '&includeInactive=true'
          : '&includeInactive=false';
    const includeTrialBalanceFileNameParam =
      includeTrialBalanceFileName == undefined
        ? ''
        : includeTrialBalanceFileName
          ? '&includeTrialBalanceFileName=true'
          : '&includeTrialBalanceFileName=false';
    return fetch(
      '/reits?clientId=' +
      clientId +
      includeInactiveParam +
      includeTrialBalanceFileNameParam,
    )
      .then(response => {
        return response.json();
      })
      .then(reitsForMO => {
        if (actionHelpers.isErrorResponse(reitsForMO)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_REITS_FOR_MO_FAILURE,
            reitsForMO,
          );
        }

        dispatch({
          type: actions.LOAD_REITS_FOR_MO_SUCCESS,
          reitsForMO,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_REITS_FOR_MO_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the external REIT names belonging to the client. If successful this will dispatch the LOAD_REITS_EXTERNAL_SUCCESS
 * action, otherwise it will dispatch the LOAD_REITS_EXTERNAL_FAILURE action.
 * @param {number} clientId The id of the client that owns the REITs.
 * @returns {function} A function that returns a Promise.
 */
export function fetchExternalReits(clientId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`reits/reitsExternal/${clientId}`)
      .then(response => {
        return response.json();
      })
      .then(externalReits => {
        if (actionHelpers.isErrorResponse(externalReits)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_REITS_EXTERNAL_FAILURE,
            externalReits,
          );
        }

        dispatch({
          type: actions.LOAD_REITS_EXTERNAL_SUCCESS,
          externalReits,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_REITS_EXTERNAL_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the REIT by period id.
 * @param {any} periodId The period id.
 * @param {any} checklistId The checklist id.
 * @param {any} checklistType The checklist type.
 * @returns {function} A Promise.
 */
export function fetchReitByPeriodId(periodId, checklistId, checklistType) {
  let authHeader = null;
  if (checklistId && checklistType) {
    authHeader = actionHelpers.buildAuthHeader(checklistId, checklistType);
  }
  return fetch(`/reits?periodId=${periodId}`, {
    headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (Array.isArray(data)) {
        return data[0];
      }

      return data;
    });
}

/**
 * Fetch the REIT by id. If successful this will dispatch the LOAD_RIET_SUCCESS action,
 * otherwise it will dispatch the LOAD_REIT_FAILURE action.
 * @param {number} id The id of the REIT
 * @returns {function} A function that returns a Promise
 */
export function fetchReit(id) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/reits/' + id)
      .then(response => {
        return response.json();
      })
      .then(reit => {
        if (actionHelpers.isErrorResponse(reit)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_REIT_FAILURE,
            reit,
          );
        }

        clearActions.clearPeriod(dispatch);
        dispatch({ type: actions.LOAD_RIET_SUCCESS, reit, [pendingTask]: end });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_REIT_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Create a new REIT.
 * @param {any} reit The REIT
 * @returns {function} A Promise.
 */
export function createReit(reit) {
  return fetch('/reits', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(reit),
  }).then(response => {
    return response.json();
  });
}

/**
 * Updates an existing REIT.
 * @param {any} reit The REIT
 * @returns {function} A Promise.
 */
export function updateReit(reit) {
  return fetch('/reits', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(reit),
  }).then(response => {
    if (response.ok) {
      return null;
    }

    return response.json();
  });
}

/**
 * Toggles the isActive property on the REIT and reloads REITs
 * @param {any} clientId The client id
 * @param {any} reitId The REIT id
 * @returns {any} A function that returns a Promise.
 */
export function toggleActiveFlagAndReloadReits(clientId, reitId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/reits/' + reitId)
      .then(response => {
        return response.json();
      })
      .then(reit => {
        if (actionHelpers.isErrorResponse(reit)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.TOGGLE_REIT_ACTIVE_FLAG_FAILURE,
            reit,
          );
        }

        if (reit.isActive == null) {
          reit.isActive = false;
        } else {
          reit.isActive = !reit.isActive;
        }

        return updateReit(reit);
      })
      .then(data => {
        if (actionHelpers.isErrorResponse(data)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.TOGGLE_REIT_ACTIVE_FLAG_FAILURE,
            data,
          );
        }

        fetchReits(clientId, true)(dispatch);
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
      });
  };
}

/**
 * Downloads a list of tracking IDs
 * @param {any} clientId The client id
 * @param {any} showInactive True to include inactive clients, otherwise false
 * @returns {any} A Promise
 */
export function downloadIdList(clientId, showInactive, fileName) {
  return fetch(
    `/trackingids/${clientId}/download?showInactive=${showInactive}`,
  ).then(function (response) {
    return response.blob().then(function (blob) {
      download(blob, decodeURIComponentSafe(fileName,1));
    });
  });
}

/**
 * Downloads the TB Templates for the REIT and all Properties of a client. The template reporting period will be the real-time current quarter, unless a period ID is provided.
 * @param {number} clientId The client ID.
 * @param {bool} showInactive A flag to show inactive REITs along with the active.
 * @param {any} periodId The optional period ID to determine the template reporting period and properities of that period.
 * @returns {function} A Promise.
 */
export function downTbTemplates(clientId, showInactive, fileName, periodId) {
  let url = `/trackingids/${clientId}/templates?showInactive=${showInactive}&fileName=${fileName}`;
  if (periodId) {
    url += `&periodId=${periodId}`;
  }

  return fetch(url).then(function (response) {
    return response.blob().then(function (blob) {
      download(blob, decodeURIComponentSafe(fileName,1));
    });
  });
}

/**
 * Fetch all reit lookup data
 * @returns {Promise} A Promise
 */
 export function fetchReitLookupTypes() {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/reits/lookupData`,
      {
          method: "GET"
      }).then(response => {
          return response.json();
      })
      .then(fetchedTypes => {
          dispatch({
              type: actions.LOAD_ALL_REIT_LOOKUPTYPES_SUCCESS,
              fetchedTypes,
              [pendingTask]: end,
          });
      });
  }
}

/**
 * Fetch all reit lookup data for External user
 * @returns {Promise} A Promise
 */
 export function fetchExternalReitLookupTypes(checklistId, checklistType) {
  return function (dispatch) {
    const authHeader = actionHelpers.buildAuthHeader(checklistId, checklistType);
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/reits/lookupData`,
      {
          method: "GET",
          headers: authHeader && new Headers({ 'Authorization': authHeader }) 
      }).then(response => {
          return response.json();
      })
      .then(fetchedTypes => {
          dispatch({
              type: actions.LOAD_ALL_REIT_LOOKUPTYPES_SUCCESS,
              fetchedTypes,
              [pendingTask]: end,
          });
      });
  }
}


/**
 * Fetch all the reits that have at least one report period in the given calendar year
 * @param {number} calendarYear The calendar year
 * @param {number} clientId The client ID.
 * @returns {function} A Promise.
 */
export function fetchCalendarYearReits(calendarYear, clientId){
  return function (dispatch) {
    dispatch({ type:actions.TASK_BEGIN, [pendingTask]: begin });
    
    return fetch(`/reits/calendarYearReits?calendarYear=${calendarYear}&clientId=${clientId}`, {method: "GET"})
      .then(response => { return response.json(); })
      .then(calendarYearReits => {
        if (actionHelpers.isErrorResponse(calendarYearReits)) {
          return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CALENDAR_YEAR_REITS_FAILURE, calendarYearReits);
        }
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return calendarYearReits;
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CALENDAR_YEAR_REITS_FAILURE, null, error);
      });
  }
}