import React from 'react';
import PropTypes from 'prop-types';

import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from '@ey-xd/motif-react';

const SubmitForReviewModal = ({
  showSubmitForReviewModal,
  downloadType,
  modalTitle,
  modalContent,
  selectedChecklists,
  toggleShowSubmitForEYReviewModal,
  handleSubmitForReview,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close submit for EY review modal',
    'aria-label': 'Close submit for EY review modal',
    'data-testid': 'close',
  };

  const showHideSubmitForReviewModal = () => {
    toggleShowSubmitForEYReviewModal();
  };

  return (
    <MotifModal
      variant="alt"
      show={showSubmitForReviewModal}
      onClose={showHideSubmitForReviewModal}
      size="xl"
      data-testid="SubmitForEyReviewModal"
    >
      <MotifModalHeader
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <span className="ml-0">{modalTitle}</span>
        </HeaderWrapper>
      </MotifModalHeader>
      <MotifModalBody>
        <span className="checklistActionMsg">{modalContent}</span>
        <br />
        <span className="checklistActionMsg">You have selected {selectedChecklists?.length} checklist(s).</span>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          variant="secondary"
          className="right"
          data-testid="Cancel"
          type="button"
          onClick={showHideSubmitForReviewModal}
        >
          Cancel
        </MotifButton>
        <MotifButton
          className="right"
          data-testid="Submit"
          type="button"
          onClick={() => handleSubmitForReview()}
        >
          Submit
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

SubmitForReviewModal.propTypes = {
  showSubmitForReviewModal: PropTypes.bool,
  downloadType: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  selectedChecklists: PropTypes.array,
  toggleShowSubmitForReviewModal: PropTypes.bool,
  handleSubmitForReview: PropTypes.func,

};

export default SubmitForReviewModal;
