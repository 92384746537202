import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import {
  MotifButton,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from '@ey-xd/motif-react';
import { addAttribute } from '../../common/Attribute';

const CustomPSQTemplateDeleteTemplateValidationMessageModal = ({
  showCustomPSQTemplateDeleteTemplateValidationMessageModal,
  handleSubmit,
  toggleCustomPSQTemplateDeleteTemplateValidationMessageModal,
  validationMessage,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Validation message modal',
    ariaLabel: 'Close Validation message modal',
    dataTestid: 'close',
  };

  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  return (
    <MotifModal
      variant="alt"
      show={showCustomPSQTemplateDeleteTemplateValidationMessageModal}
      onClose={() =>
        toggleCustomPSQTemplateDeleteTemplateValidationMessageModal()
      }
      size="xl"
      data-testid="customPSQTemplateDeleteTemplateValidationMessageModal"
    >
      <MotifModalHeader
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <span className="ml-2">Delete Template</span>
        </HeaderWrapper>
      </MotifModalHeader>

      <MotifModalBody>
        <form onSubmit={handleSubmit} className="auth">
          <span className="checklistActionMsg">{validationMessage}</span>
          <br />
        </form>
      </MotifModalBody>

      <MotifModalFooter>
        <MotifButton
          type="button"
          onClick={() =>
            toggleCustomPSQTemplateDeleteTemplateValidationMessageModal()
          }
          className="right"
          data-testid="Submit"
        >
          OK
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

CustomPSQTemplateDeleteTemplateValidationMessageModal.propTypes = {
  showCustomPSQTemplateDeleteTemplateValidationMessageModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggleCustomPSQTemplateDeleteTemplateValidationMessageModal: PropTypes.func,
  validationMessage: PropTypes.object,
};

export default reduxForm({
  form: 'customPSQTemplateDeleteTemplateValidationMessage',
  enableReinitialize: true,
})(CustomPSQTemplateDeleteTemplateValidationMessageModal);
