import { pendingTask, begin, end } from '../constants/pendingTask';
import * as ErrorResponses from '../constants/errorResponses';

export const isErrorResponse = responseBody => {
  if (responseBody && (responseBody.errorCode || responseBody.errorResponse || responseBody.ErrorCode || responseBody.ErrorResponse)) {
    return true;
  }

  return false;
};

export const dispatchErrorAndEndTask = (
  dispatch,
  actionType,
  errorResponse,
  error,
) => {
  if (error) {
    console.error(error); // eslint-disable-line no-console
  }

  errorResponse = errorResponse || ErrorResponses.defaultErrorResponse;

  console.error(
    '%s: (%s) %s',
    actionType,
    errorResponse.errorCode || errorResponse.ErrorCode,
    errorResponse.message || errorResponse.Message,
  ); // eslint-disable-line no-console

  return dispatch({
    type: actionType,
    errorResponse: errorResponse || ErrorResponses.defaultErrorResponse,
    [pendingTask]: end,
  });
};

export const dispatchError = (dispatch, actionType, errorResponse, error) => {
  if (error) {
    console.error(error); // eslint-disable-line no-console
  }

  errorResponse = errorResponse || ErrorResponses.defaultErrorResponse;

  console.error(
    '%s: (%s) %s',
    actionType,
    errorResponse.errorCode,
    errorResponse.message,
  ); // eslint-disable-line no-console

  return dispatch({
    type: actionType,
    errorResponse: errorResponse || ErrorResponses.defaultErrorResponse,
  });
};

export const buildAuthHeader = (checklistId, checklistType) =>
  `ChecklistId=${checklistId}&ChecklistType=${checklistType}`;
