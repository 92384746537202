import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download } from '../scripts/fileHelpers';
/**
 * Fetch all the Data Retention Management Data for a given client If successful this will dispatch the LOAD_DATA_RETENTION_MANAGEMENT_DATA_SUCCESS
 * action, otherwise it will dispatch the LOAD_DATA_RETENTION_MANAGEMENT_DATA_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export const fetchDataRetentionManagementData = (clientId) => {
  return (dispatch) => {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_DATA_RETENTION_MANAGEMENT_DATA_FAILURE,
        dataRetentionManagementData: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/dataRetentionManagement?clientId=${clientId}`)
      .then((response) => {
        return response.json();
      })
      .then((dataRetentionManagementData) => {
        if (actionHelpers.isErrorResponse(dataRetentionManagementData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_DATA_RETENTION_MANAGEMENT_DATA_FAILURE,
            null
          );
        }
        dispatch({
          type: actions.LOAD_DATA_RETENTION_MANAGEMENT_DATA_SUCCESS,
          dataRetentionManagementData,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_DATA_RETENTION_MANAGEMENT_DATA_FAILURE,
          null,
          error
        );
      });
  };
}

export function dispatchDataRetentionManagementData(message, action) {
  return function (dispatch) {
    dispatch({ type: action, message });
  };
}

/**
 * Download Data Deletion Summary.
 * @returns {function} A Promise.
 */
export function exportDataDeletionSummary(clientId, approvedDates) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    
      // Convert the array into a json string to pass in [FromQuery] parameter
      const approvedDatesParam = JSON.stringify(approvedDates);
      return fetch(`/dataRetentionManagement/exportDataDeletionSummary?clientId=${clientId}&approvedDates=${approvedDatesParam}`, {
    headers: {
        'Content-Type': 'application/json'
    },

}) .then(function (response) {
  if (!response.ok) {
      throw new Error('Unexpected error');
  }

  return response.blob().then(function (blob) {
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition.split(';')[1].split('=')[1].trim();
      download(blob, filename);
      dispatch({ type: actions.TASK_END, [pendingTask]: end });
    
  });
}).catch(error => {
  return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.TASK_END, null, error);
});
}
}

export function approveDataDeletion(requestData) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

      return fetch(`/dataRetentionManagement/updateDataPurgeReportStatus`, {
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(requestData)
      })
          .then(response => {
              return response.text();
          })
          .then(response => {
              const jsonResponse = response ? JSON.parse(response) : {};
              if (actionHelpers.isErrorResponse(jsonResponse)) {
                  return actionHelpers.dispatchErrorAndEndTask(
                      dispatch,
                      actions.ACTION_DATA_RETENTION_MANAGEMENT_FAILURE,
                      jsonResponse,
                  );
              }

              dispatch({ type: actions.TASK_END, [pendingTask]: end });
              return jsonResponse;
          })
          .catch(error => {
              actionHelpers.dispatchErrorAndEndTask(
                  dispatch,
                  actions.ACTION_DATA_RETENTION_MANAGEMENT_FAILURE,
                  null,
                  error,
              );
          });
  };
}

/**
 * Fetch calendar years from client's report periods. If successful this will dispatch the TASK_END and return calendarYears
 * otherwise it will dispatch the LOAD_CALENDAR_YEARS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchDataRetentionCalendarYears(clientId) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
      return fetch(`/dataRetentionManagement/calendarYears?clientID=${clientId}`)
          .then(response => {
              return response.json();
          }).then(calendarYears => {
              if (actionHelpers.isErrorResponse(calendarYears)) {
                  return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CALENDAR_YEARS_FAILURE, calendarYears);
              }

              dispatch({ type: actions.TASK_END, [pendingTask]: end });
              return calendarYears;
          }).catch(error => {
              actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CALENDAR_YEARS_FAILURE, null, error);
          });
  };
}




