import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import * as formParsers from "../../scripts/formParsers";
import FormattedCurrency from "../shared/FormattedCurrency";

const PropertyDetailRow = ({ item }) => {
    if (item.hasTrialBalance) {
        return (
            <tr>
                <td width="16%">{item.propertyName}</td>
                <td className="tcenter" width="12%">{item.percentageOfOwnership ? formParsers.percentageOfOwnership(item.percentageOfOwnership.toString()) : "" }%</td>
                <td className="tright" width="12%"><FormattedCurrency value={item.rentsFromRealProperty} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="12%"><FormattedCurrency value={item.incomeRelatedToImpTenServ} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="12%"><FormattedCurrency value={item.expenseRelatedToImpTenServ} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="12%"><FormattedCurrency value={item.markupOfItsi} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="12%"><FormattedCurrency value={item.totalItsi} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                <td className="tright" width="12%"><FormattedNumber value={(item.result != 0 && item.result / 100) || 0} style="percent" minimumFractionDigits={3} maximumFractionDigits={3} /></td>
            </tr>
        );
    }
    return (
        <tr>
            <td width="16%">{item.propertyName}</td>
            <td className="tcenter" width="12%">N/A</td>
            <td className="tright" width="12%">N/A</td>
            <td className="tright" width="12%">N/A</td>
            <td className="tright" width="12%">N/A</td>
            <td className="tright" width="12%">N/A</td>
            <td className="tright" width="12%">N/A</td>
            <td className="tright" width="12%">N/A</td>
        </tr>
    );
};

PropertyDetailRow.propTypes = {
    item: PropTypes.object.isRequired
};

export default PropertyDetailRow;