import React from "react";
import PropTypes from "prop-types";
import { Icon } from 'semantic-ui-react';
import { FormattedNumber } from 'react-intl';
import { black, white } from "chalk";
import { MotifBadge } from '@ey-xd/motif-react';

const TestSummaryItem = ({ test, totalItems }) => {
    let testTitle = test.testTitle || "";
    const colSize = Math.floor(12 / totalItems);
    const className = `summary col-md-${colSize}`;
    const re = /(\d+%)(.*)/;
    const matches = testTitle.match(re);

    if (Array.isArray(matches) && matches.length === 3) {
        testTitle = <span>{matches[1]}{matches[2]}</span>;
    }

    return (
        <div className={className}>
            <div className="testname">{testTitle}</div>
            <div className={"teststatus testresults"}>
                {<FormattedNumber value={(test.testResultPercent !== 0 && test.testResultPercent / 100) || 0} style="percent" minimumFractionDigits={3} maximumFractionDigits={3} />}
            </div>           
            <MotifBadge className="roundedCornerDefault" variant={test.isPassing ? "success" : "error"}
                value={test.isPassing ? "PASS" : "FAIL"} data-testid="badgeTestSummary" />
        </div>       
    );
};

TestSummaryItem.propTypes = {
    test: PropTypes.shape({
        testTitle: PropTypes.string,
        isPassing: PropTypes.bool,
        testResultPercent: PropTypes.number
    }),
    totalItems: PropTypes.number.isRequired
};

export default TestSummaryItem;