import React from 'react';
import PropTypes from 'prop-types';
import PropertyDetailTable from "./PropertyDetailTable";

const PropertyDetailTestTable = ({ detail }) => {
    return (
        <table className="table detail-test-table">
            <thead>
                <tr>
                    <th>
                        {(detail && detail.test) || null}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <PropertyDetailTable
                            propertyDetail={detail}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

PropertyDetailTestTable.propTypes = {
    detail: PropTypes.object
};

export default PropertyDetailTestTable;