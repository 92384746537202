import React from 'react';
import { MotifCheckbox } from "@ey-xd/motif-react";

export default (props) => {
  const value = props.data ? props.data[props.colDef.field] : null;
  const reportPeriodID = props.data ? props.data.reportPeriodID : null;

  // Display checkbox if the reit is available for selection
    const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
    let checkboxClass = value ? defaultCheckboxClass + " ag-checked" : defaultCheckboxClass;

      return (
      <span>
        <MotifCheckbox
          type="checkbox"
          name="cell-checkbox"
          id={`ag-cell-checkbox-${reportPeriodID}`}
          checked={value}
          tabindex="-1"
          aria-label="Press Space to toggle row selection"
          onClick={() => props.onCellCheckboxChange(reportPeriodID, props.node)}>
        </MotifCheckbox>
      </span>
    );
 
};