import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_SUCCESS action and sets the allow update result in the store
 * @param {Object} [state] The allow update result currently in the store, or the initial state of the allow update result
 * @param {Object} action The action to be handled
 * @returns {Object} The allow update result
 */
export default function reportPeriodVisibilityConstraintReducer(state = initialState.allowUpdateReportPeriodVisibilityConstraintResult, action) {
    if (action.type === actions.ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_SUCCESS) {
        return action.allowUpdateReportPeriodVisibilityConstraintResult;
    } else {
        return state;
    }
}