/* eslint-disable react/prop-types */

import React, { useEffect } from 'react';

import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { MotifButton, MotifLabel,MotifToast } from '@ey-xd/motif-react';

import * as formHelpers from '../../scripts/formHelpers';
import { addAttribute } from '../../common/Attribute';
import * as formValidators from '../../scripts/formValidators';

const PsqCustomChecklistTemplateHeader = (props) => {
  const {
    isEditMode,
    valid,
    dirty,
    existingTemplates,
    isTemplateDirty,
    handleSubmit,
    handleTemplateReset,
    isCommitted,
    handleOnChangeOfTemplateName,
    hasTemplateNameChanged,
    readOnlyModeForUser
  } = props;
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  let existingTemplateOptions = [];
  if (existingTemplates.length > 0) {
    existingTemplateOptions = existingTemplates.map((c) => {
      return {
        key: c.checklistTemplateID,
        value: c.checklistTemplateID,
        text: c.checklistTemplateName,
      };
    });
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        {/* Create Mode */}
        {!isEditMode && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="col-md-6 pl-0">
              <div className="col-md-8 pl-0 mb-2">
                <Field
                  name="templateName"
                  label="Template Name (Required)"
                  component={formHelpers.renderField}
                  type="text"
                  maxLength={255}
                  validate={[
                    formValidators.required,
                    formValidators.maxLength255,
                  ]}
                  dataTestId="txtTemplateName"
                />
              </div>
              <div className="col-md-8 pl-0">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'inline' }} className="col-md-6 pl-0">
                    <Field
                      name="templateInitializationType"
                      component={formHelpers.renderField}
                      type="radio"
                      value="blankTemplate"
                      label="Create Blank Template"
                      disabled={isEditMode}
                      dataTestId="radioCreateBlankTemplate"
                    />
                  </div>
                  <div
                    style={{ display: 'inline-flex', justifyContent: 'end' }}
                    className="col-md-6 pl-0 pr-0"
                  >
                    {existingTemplates.length > 0 && (
                      <Field
                        name="templateInitializationType"
                        component={formHelpers.renderField}
                        type="radio"
                        value="existingTemplate"
                        label="Copy an existing Template"
                        disabled={isEditMode}
                        dataTestId="radioCopyAnExistingTemplate"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-8 pl-0">
                {props.psqCustomChecklistTemplateValues
                  .templateInitializationType === 'existingTemplate' &&
                  !isEditMode && (
                    <Field
                      name="existingTemplate"
                      label="Select an existing template to copy from"
                      options={existingTemplateOptions}
                      component={formHelpers.SingleSelectComponent}
                      disabled={
                        isEditMode ||
                        !(
                          props.psqCustomChecklistTemplateValues
                            .templateInitializationType === 'existingTemplate'
                        )
                      }
                      testingData={{
                        label: '',
                        div: 'selectAnExistingTemplate',
                      }}
                    />
                  )}
                {props.psqCustomChecklistTemplateValues
                  .templateInitializationType === 'existingTemplate' &&
                  isEditMode && (
                    <MotifLabel>
                      <span style={{ fontWeight: 'bold' }}>
                        Copied From Template:
                      </span>{' '}
                      {props.psqCustomChecklistTemplateValues.existingTemplate}
                    </MotifLabel>
                  )}
              </div>
            </div>
            <div
              className="col-md-6 pr-0 pb-3"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <MotifButton
                variant="secondary"
                type="button"
                style={{ marginLeft: 'auto', marginRight: '0.8rem' }}
                disabled={
                  !valid ||
                  props.psqCustomChecklistTemplateValues
                    .templateInitializationType === '' ||
                  props.psqCustomChecklistTemplateValues
                    .templateInitializationType === undefined ||
                  (props.psqCustomChecklistTemplateValues
                    .templateInitializationType === 'existingTemplate' &&
                    (props.psqCustomChecklistTemplateValues.existingTemplate ===
                      '' ||
                      props.psqCustomChecklistTemplateValues
                        .existingTemplate === undefined)) ||
                  (!dirty && !isTemplateDirty && isEditMode)
                }
                data-testid="btnCancel"
                onClick={handleTemplateReset}
              >
                Cancel
              </MotifButton>
              <MotifButton
                type="submit"
                disabled={
                  !valid ||
                  props.psqCustomChecklistTemplateValues
                    .templateInitializationType === '' ||
                  props.psqCustomChecklistTemplateValues
                    .templateInitializationType === undefined ||
                  (props.psqCustomChecklistTemplateValues
                    .templateInitializationType === 'existingTemplate' &&
                    (props.psqCustomChecklistTemplateValues.existingTemplate ===
                      '' ||
                      props.psqCustomChecklistTemplateValues
                        .existingTemplate === undefined)) ||
                  (!dirty && !isTemplateDirty && isEditMode)
                }
                data-testid="btnSave"
              >
                Save
              </MotifButton>
            </div>
          </div>
        )}
        {/* Edit Mode */}
        {isEditMode && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="col-md-4 pl-0">
              <Field
                name="templateName"
                label="Template Name (required)"
                component={formHelpers.renderField}
                type="text"
                maxLength={255}
                validate={[
                  formValidators.required,
                  formValidators.maxLength255,
                ]}
                onChange={(e, value) => handleOnChangeOfTemplateName(e, value)}
                disabled={readOnlyModeForUser}
                dataTestId="txtTemplateName"
              />
               {props.psqCustomChecklistTemplateValues
                .templateInitializationType === 'existingTemplate' &&
                isEditMode && (
                  <MotifLabel className="word-break-text-with-line-clamp">
                    <span style={{ fontWeight: 'bold' }}>
                      Copied From Template:
                    </span>{' '}
                    {
                      props.psqCustomChecklistTemplateValues
                        .copiedFromTemplateName
                    }
                  </MotifLabel>
                )}
            </div>
            <div
              className="col-md-6 pl-0"
            >
              {props.psqCustomChecklistTemplateValues
                .templateInitializationType === 'existingTemplate' &&
                !isEditMode && (
                  <Field
                    name="existingTemplate"
                    label="Select an existing template to copy from"
                    options={existingTemplateOptions}
                    component={formHelpers.SingleSelectComponent}
                    disabled={
                      isEditMode ||
                      !(
                        props.psqCustomChecklistTemplateValues
                          .templateInitializationType === 'existingTemplate'
                      )
                    }
                    testingData={{
                      label: '',
                      div: 'selectAnExistingTemplate',
                    }}
                  />
                )}
             
                <MotifToast  variant="info" data-testid="eyToastMsg" hideclosebutton="true" className="removePadding">
                      <span style={{fontSize:'11px'}}>A change to the template (left panel on the page) gets saved only on the Submit button click.</span>
                      <span style={{fontSize:'11px'}}>On Submit, a change made to the question(s) within the template will be reflected on other templates using the same question(s).</span>      
                     </MotifToast>           

            </div>
            <div
              className="col-md-2  pr-0 pb-3"
              style={{
                display: 'flex',
                flexDirection: 'row',               
              }}
            >
              <MotifButton
                variant="secondary"
                type="button"
                style={{ marginLeft: 'auto', marginRight: '0.8rem' }}
                // disabled={isCommitted && !hasTemplateNameChanged} Keeping the button always enabled
                disabled={readOnlyModeForUser}
                data-testid="btnCancel"
                onClick={handleTemplateReset}
              >
                Abandon
              </MotifButton>
              <MotifButton
                type="submit"
                // disabled={isCommitted && !hasTemplateNameChanged} Keeping the button always enabled
                disabled={readOnlyModeForUser}
                data-testid="btnSave"
              >
                Submit
              </MotifButton>
            </div>
          </div>
        )}
      </form>
    </React.Fragment>
  );
};

PsqCustomChecklistTemplateHeader.propTypes = {
  valid: PropTypes.bool,
  dirty: PropTypes.bool,
  isEditMode: PropTypes.bool,
  existingTemplates: PropTypes.array,
  isTemplateDirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleTemplateReset: PropTypes.func,
  isCommitted: PropTypes.bool,
  handleOnChangeOfTemplateName: PropTypes.func,
  hasTemplateNameChanged: PropTypes.bool,
};

export default connect((state) => ({
  psqCustomChecklistTemplateValues: getFormValues('psqCustomChecklistTemplate')(
    state
  ),
}))(
  reduxForm({ form: 'psqCustomChecklistTemplate' })(
    PsqCustomChecklistTemplateHeader
  )
);
