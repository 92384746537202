import React, { Fragment, useState, useEffect } from "react";
import { FormattedDate } from "react-intl";
import { PropTypes } from "prop-types";
import { addAttribute } from "../../common/Attribute";

import {
    MotifFormField,
    MotifCheckbox,
    MotifLabel,
    MotifSelect,
    MotifOption,
    MotifToast
} from "@ey-xd/motif-react";
const TABLE_STYLE = { width: '400px', marginLeft: 'auto', marginRight: 'auto' };

const INNER_TABLE_STYLE = {
    width: 'auto',
    minWidth: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
};
const ITALIC_FONT = { fontStyle: 'italic' };
const ZERO_PADDING = { padding: 0 };
const INCLUDE_COMMENTS_TEXT = 'Include Comments and Attachments';

const isNonEmptyArray = _arr => Array.isArray(_arr) && _arr.length > 0;

const getItemDisabledReason = (item, {
    hasTrialBalance,
    psqChecklists,
    hasArqc,
    scopedServices,
    clientIsAudit
}) => {
    if (!hasTrialBalance && (item.type === 'AssetTestResults' || item.type === 'IncomeTestResults')) {
        return "A trial balance has not been uploaded and one is required for this report item.";
    }

    if (!isNonEmptyArray(psqChecklists) && item.type === 'PsqResults') {
        return "There are no Property Survey Questionnaires and one is required for this report item.";
    }

    if (!hasArqc && item.type === 'ArqcResults') {
        return "There are no Annual REIT Qualification Checklists and one is required for this report item.";
    }

    if (item.scopedService && !scopedServices.includes(item.scopedService)) {
        return "Service not in scope for this reporting period.";
    }

    if (clientIsAudit && item.type === 'ClientAccountMappings') {
        return 'Client is "Audit - ARQC Checklist Only';
    }

    return null;
}

const PsqTable = ({
    psqChecklists,
    excludedPsqs,
    setPsqInclusion,
    includePsqComments,
    setIncludePsqComments
}) => {
    const psqIsIncluded = psq => !excludedPsqs.includes(psq.checklistID);

    return (<table style={INNER_TABLE_STYLE}>
        <tbody>
            {psqChecklists.map(psq => {
                const included = psqIsIncluded(psq);

                const ariaDescription =
                    `Property Name ${psq.propertyName}` +
                        psq.propertyType ?
                        ` (Of type ${psq.propertyTypeDescription})` :
                        '';

                return (
                    <tr key={psq.checklistID}>
                        <td>
                            <MotifFormField onClick={() => setPsqInclusion(psq.checklistID, !included)}>
                                <MotifCheckbox
                                    checked={included}
                                    onChange={() => setPsqInclusion(psq.checklistID, !included)}
                                    aria-label={ariaDescription}>
                                    <span style={ZERO_PADDING}>
                                        {psq.propertyName} {psq.propertyType ? `(${psq.propertyTypeDescription})` : ''}
                                    </span>
                                    {psq.createdDate &&
                                        <Fragment>
                                            <br />
                                            <span style={{ ...ZERO_PADDING, ...ITALIC_FONT }}>
                                                Created on <FormattedDate value={psq.createdDate} />
                                            </span>
                                        </Fragment>}
                                </MotifCheckbox>
                            </MotifFormField>
                        </td>
                    </tr>)
            })}

            <tr>
                <td>
                    <MotifFormField onClick={() => setIncludePsqComments(!includePsqComments)}>
                        <MotifCheckbox
                            checked={includePsqComments}
                            onChange={() => setIncludePsqComments(!includePsqComments)}>
                            {INCLUDE_COMMENTS_TEXT}
                        </MotifCheckbox>
                    </MotifFormField>
                </td>
            </tr>
        </tbody>
    </table>);
}

const ArqcTable = ({ includeArqcComments, setIncludeArqcComments }) => {
    return (
        <table style={INNER_TABLE_STYLE}>
            <tbody>
                <tr>
                    <td>
                        <MotifFormField onClick={() => setIncludeArqcComments(!includeArqcComments)}>
                            <MotifCheckbox
                                checked={includeArqcComments}
                                onChange={() => setIncludeArqcComments(!includeArqcComments)}>
                                {INCLUDE_COMMENTS_TEXT}
                            </MotifCheckbox>
                        </MotifFormField>
                    </td>
                </tr>
            </tbody>
        </table>);
}

const TemplateSelector = ({
    customFdrTemplates,
    selectedTemplate,
    handleTemplateSelect,
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const customTemplateOptions = customFdrTemplates.templates.map((t) => ({
        value: t.fdrTemplateID,
        label: t.fdrTemplateName,
    }));

    const templateOptions = [
        { value: -2, label: "Standard Template" },
        ...customTemplateOptions,
    ];

    const currentTemplate = selectedTemplate
        ? templateOptions.find(
              (to) => to.value === selectedTemplate.templateId
          ) || templateOptions[0]
        : templateOptions[0];

    const onTemplateSelected = (v) => {
        const templateId = parseInt(v);

        const templateOption = templateOptions.find(
            (to) => to.value === templateId
        );

        handleTemplateSelect(templateOption);
    };

    const [renderDropdown, setRenderDropdown] = useState(false);

    useEffect(() => {
        setRenderDropdown(false);
        setTimeout(() => setRenderDropdown(true));
    }, [currentTemplate.value]);

    return (
        <div className="row d-flex justify-content-center">
            <div
                className="mb-2"
                style={{ textAlign: "initial", width: 400}}>
                <MotifFormField>
                    <MotifLabel>Select an FDR Template</MotifLabel>
                    {renderDropdown && (
                        <MotifSelect
                            aria-label="Select an FDR template dropdown"
                            value={String(currentTemplate.value)}
                            onChange={onTemplateSelected}>
                            {templateOptions.map((o, i) => (
                                <MotifOption key={i} value={String(o.value)}>
                                    {String(o.label).replace(/\s+/g, "\u2800")}
                                </MotifOption>
                            ))}
                        </MotifSelect>
                    )}
                </MotifFormField>
            </div>
        </div>
    );
};

const ReportItemRow = ({
    title,
    onClick,
    item,
    selected,
    disabled,
    setItemSelection,
    parentProps
}) => {
    return (
        <tr key={item.type}>
            <td>
                <MotifFormField
                    title={title}
                    onClick={onClick}>
                    <MotifCheckbox
                        name={item.type}
                        checked={selected}
                        disabled={disabled}
                        onChange={() => setItemSelection(item.type, !selected)}>
                        {item.displayName}
                    </MotifCheckbox>
                </MotifFormField>
                {
                    item.type === 'PsqResults' &&
                    selected &&
                    <PsqTable {...parentProps} />
                }
                {
                    item.type === 'ArqcResults' &&
                    selected &&
                    <ArqcTable {...parentProps} />
                }
            </td>
        </tr>);
}

const ReportItemList = (props) => {
    const [containsDisabledItems, setContainsDisabledItems] = useState(false);
    const [hideWarningMessage, setHideWarningMessage] = useState(false);

    const {
        availableReportItems,
        selectedItems,
        setItemSelection,
        selectedTemplate
    } = props;

    const itemIsSelected = type => selectedItems.includes(type);

    const itemFilter =
        selectedTemplate.templateId < 0 ?
            () => true :
            item => ['PsqResults', 'ArqcResults'].includes(item.type);

    const filteredItems = availableReportItems.filter(itemFilter);

    return (
        <Fragment>
            {
                containsDisabledItems &&
                !hideWarningMessage &&
                <MotifToast
                    className="mt-2 mb-3"
                    variant="info"
                    onClose={() => setHideWarningMessage(true)}>
                    One or more report items have been disabled.  Hover over the disabled item to find out why.
                </MotifToast>
            }

            <div style={{ textAlign: 'center', width: '100%' }}>
                <label>Configure Report</label>
                <TemplateSelector {...props} />

               
                <table style={{width: '400px', marginLeft: 'auto', marginRight: 'auto', borderCollapse: 'separate'}} className="motif-table-standard tbd-b-rad margin-top-5">
                    <tbody>
                        {filteredItems.map(item => {
                            const selected = itemIsSelected(item.type);
                            const disabledReason = getItemDisabledReason(item, props);

                            if (disabledReason && !containsDisabledItems) {
                                setContainsDisabledItems(true);
                            }

                            const title = disabledReason ?
                                `${item.displayName} disabled: ${disabledReason}` :
                                item.isRequired ?
                                    `${item.displayName} is required for all reports.` :
                                    item.displayName;

                            const disabled = item.isRequired || disabledReason;

                            const onClick = disabled ?
                                () => { } :
                                () => setItemSelection(item.type, !selected);

                            return (
                                <ReportItemRow
                                    key={item.type}
                                    title={title}
                                    onClick={onClick}
                                    item={item}
                                    selected={selected}
                                    disabled={disabled}
                                    setItemSelection={setItemSelection}
                                    parentProps={props} />);
                        })}
                    </tbody>
                </table>               
            </div>
        </Fragment>);
};

ReportItemList.propTypes = {
    availableReportItems: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string,
        type: PropTypes.string
    })),
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    setItemSelection: PropTypes.func,
    psqChecklists: PropTypes.array,
    excludedPsqs: PropTypes.arrayOf(PropTypes.number),
    setPsqInclusion: PropTypes.func,
    includePsqComments: PropTypes.bool,
    setIncludePsqComments: PropTypes.func,
    includeArqcComments: PropTypes.bool,
    setIncludeArqcComments: PropTypes.func,
    customFdrTemplates: PropTypes.object,
    selectedTemplate: PropTypes.object,
    handleTemplateSelect: PropTypes.func
}

export default ReportItemList;