import React from 'react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { IconoirTrash, IconoirDownload } from '@ey-xd/motif-icon';

export default (props) => {
    const bulkReportPackageId = props.data ? props.data.bulkReportPackageID : -1;
    const bulkReportPackageName = props.data ? props.data.bulkReportPackageName : '';
    const bulkReportPackageZipFileGUID = props.data ? props.data.bulkReportPackageZipFileGUID : '';
    const isSystemAdministrator = props.currentUserIsSysAdmin ? props.currentUserIsSysAdmin : false;
    const isEngagementAdmin = props.currentUserIsEngAdmin ? props.currentUserIsEngAdmin : false;
    const isEngagementUser = props.currentUserIsEngagementUser ? props.currentUserIsEngagementUser : false;
    const status = props.data ? props.data.bulkReportStatusDescription : '';

    return (
        <div>
            {(isSystemAdministrator || isEngagementAdmin || isEngagementUser)  &&
                <div>   
<div className="d-flex justify-content-between align-items-center mt-2">
        {status.toLowerCase() === 'completed' ?
            <MotifIconButton
                aria-label="Download"
                type="button"
                data-testid="btnDownloadIcon"
                disabled={false}
                onClick={() => props.handleDownload(bulkReportPackageId, bulkReportPackageName, bulkReportPackageZipFileGUID)} >
                <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' title="Download" />
            </MotifIconButton>
            :
            <div style={{ width: '45px', height: '24px' }}></div> // Placeholder div
        }  
    {(status.toLowerCase() === 'completed' || status.toLowerCase() === 'unexpected error') &&
        <MotifIconButton aria-label="Delete"
            type="button"
            data-testid="btnDeleteIcon"
            disabled={false}
            onClick={() => props.handleDelete(bulkReportPackageId, bulkReportPackageName)}>
            <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title="Delete" />
        </MotifIconButton>
    }
</div>
                </div>
            }</div>);
};