import React from 'react';
import PropTypes from 'prop-types';
import { MotifButton } from '@ey-xd/motif-react';

/**
 * The ReportExport component.
 * @extends {React.Component}
 */
export default class ReportExport extends React.Component {
    /**
     * Creates a new ReportExport
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.onExportClick = this.onExportClick.bind(this);
    }

    /**
     * Fire the onExportClick function in props
     * @param {any} e The element that triggered this function
     */
    onExportClick(e) {
        e.preventDefault();
        this.props.onExportClick(this.props.periodId, this.props.selectedReportItems, this.props.excludedPsqs);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <MotifButton variant="primary" className="right mt-2 mb-4" type="button"
                onClick={this.onExportClick}
                disabled={!this.props.canReport}>
                Export
            </MotifButton>
        );
    }
}

ReportExport.propTypes = {
    periodId: PropTypes.number.isRequired,
    selectedReportItems: PropTypes.array,
    excludedPsqs: PropTypes.array,
    onExportClick: PropTypes.func.isRequired,
    canReport: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};
