import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_RIET_SUCCESS action and sets a REIT in the store
 * @param {Object} [state] The REIT currently in the store, or the initial state of REIT
 * @param {Object} action The action to be handled
 * @returns {Object} The REIT
 */
export default function reit(state = initialState.reit, action) {
    switch(action.type) {
        case actions.LOAD_RIET_SUCCESS:
            return action.reit;
        case actions.LOAD_CLIENTS_SUCCESS:
        case actions.UNLOAD_REIT:
            return initialState.reit;
        default:
            return state;
    }
}