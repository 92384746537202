import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_PROPERTIES_SUCCESS action and sets the properties in the store
 * @param {Object} [state] The properties currently in the store, or the initial state of the properties
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function propertiesReducer(state = initialState.properties, action) {
    if (action.type === actions.LOAD_PROPERTIES_SUCCESS) {
        return action.properties;
    } else if (action.type == actions.UNLOAD_PERIOD) {
        return initialState.properties;
    } else {
        return state;
    }
}