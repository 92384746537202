import React from 'react';
import useWindowDimensions from './useWindowDimensions';

export function withWindowDimensions(WrappedComponent, subtractHeight=0) {
    return function WithWindowDimensionsComponent(props) {
        const windowDimensions = useWindowDimensions(subtractHeight);
        return <WrappedComponent {...props} {...windowDimensions} />;
    }
}



