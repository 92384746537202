import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReportApi as fetch } from './fetchFromApi';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Download the report
 * @param {any} periodId The period id
 * @param {any} reportItems The report components to include in the report
 * @param {any} excludedPsqs The PSQs to exclude from the report
 * @returns {Promise} A Promise
 */
export function downloadReport(
  periodId,
  reportItems,
  excludedPsqs,
  includePsqComments,
  includeArqcComments
) {
  const payload = {
    periodId,
    reportItems,
    excludedPsqs,
    includePsqComments,
    includeArqcComments,
  };

  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/analysisReport', {
      // wonder why I had to specify headers here, but not in the downloadTrialBalance action ??
      headers: {
        Accept:
          'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        return response.blob().then(function (blob) {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          const filename = contentDisposition
            .split(';')[1]
            .split('=')[1]
            .trim();
          dispatch({ type: actions.TASK_END, [pendingTask]: end });
          download(blob, filename);
        });
      })
      .catch((error) => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.TASK_END,
          null,
          error
        );
      });
  };
}
/**
 * Fetch the report items
 * @param {any} periodId The report period id
 * @returns {any} A Promise
 */
export function fetchReportItems(periodId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/reportItems?periodId=' + periodId)
      .then((response) => {
        return response.json();
      })
      .then((items) => {
        if (actionHelpers.isErrorResponse(items)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_REPORT_ITEMS_FAILURE,
            items
          );
        }

        dispatch({
          type: actions.LOAD_REPORT_ITEMS_SUCCESS,
          items,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_REPORT_ITEMS_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Build download checklist headers based on user type
 * @param {any} checklistType The checklist type
 * @param {any} external Is external user
 * @param {any} checklistGuid The checklist guid in case of unregistered external user
 */
function buildDownloadChecklistHeaders(checklistType, external, checklistGuid) {
  if (external && checklistGuid) {
    // For unregistered external user add Authorization header
    let authHeader = null;
    if (checklistGuid && checklistType) {
        authHeader = actionHelpers.buildAuthHeader(checklistGuid, checklistType);
    }

    return {
      Accept: 'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'Content-Type': 'application/json',
      'Authorization': authHeader
    }
  }

  // For all other users use these headers
  return {
      Accept: 'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'Content-Type': 'application/json'
  }
}


/**
 * Download the checklist
 * @param {any} reitNamePeriod The REIT Name and Period
 * @param {any} periodId The period id
 * @param {any} checklistType Type of checklist PSQ or ARQC
 * @param {any} checklistId The checklist ID to download
 * @param {any} downloadChecklistType With or Without Answers/Comments
 * @param {any} filename The filename to download
 * @param {any} external Is external user
 * @param {any} checklistGuid The checklist guid in case of unregistered external user
 * @returns {Promise} A Promise
 */
export function downloadChecklist(
  reitNamePeriod,
  periodId,
  checklistType,
  checklistId,
  downloadChecklistType,
  filename,
  external,
  checklistGuid
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    
    return fetch(
      `/checklistsDownload/${periodId}/${checklistType}/${checklistId}/${downloadChecklistType}${
        external ? '?external=true' : ''
      }`,
      {
        headers: buildDownloadChecklistHeaders(checklistType, external, checklistGuid),
        method: 'POST',
        body: JSON.stringify(reitNamePeriod),
      }
    )
      .then(function (response) {
        return response.blob().then(function (blob) {
          let filename = '';

          if (checklistType === 'ARQC') {
            filename =
              reitNamePeriod.split('=')[2].trim().replace(' ', '') +
              '_' +
              reitNamePeriod.split('=')[1].trim().split(',')[0].trim() +
              '_' +
              checklistType +
              '.docx';
          } else {
            const contentDisposition = response.headers.get(
              'Content-Disposition'
            );
            const propName = contentDisposition
              .split(';')[1]
              .split('=')[1]
              .trim();
            filename =
              reitNamePeriod.split('=')[2].trim().replace(' ', '') +
              '_' +
              reitNamePeriod.split('=')[1].trim().split(',')[0].trim() +
              '_' +
              propName +
              '_' +
              checklistType +
              '.docx';
          }

          dispatch({ type: actions.TASK_END, [pendingTask]: end });
          download(blob, filename);
        });
      })
      .catch((error) => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.TASK_END,
          null,
          error
        );
      });
  };
}

/**
 * Download the checklist Without Answer
 * @param {any} reitNamePeriod The REIT Name and Period
 * @param {any} periodId The period id
 * @param {any} checklistType Type of checklist PSQ or ARQC
 * @param {any} checklistId The checklist ID to download
 * @param {any} downloadChecklistType With or Without Answers/Comments/Attachments
 * @param {any} filename The filename to download
 * @returns {Promise} A Promise
 */
export function downloadChecklistWithoutAnswer(
  reitNamePeriod,
  periodId,
  checklistType,
  checklistId,
  downloadChecklistType,
  filename
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(
      `/checklistsDownload/${periodId}/${checklistType}/${checklistId}/${downloadChecklistType}`,
      {
        headers: {
          Accept:
            'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(reitNamePeriod),
      }
    )
      .then(function (response) {
        return response.blob().then(function (blob) {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          const propName = contentDisposition
            .split(';')[1]
            .split('=')[1]
            .trim();
          let filename = '';

          if (checklistType === 'ARQC') {
            filename =
              reitNamePeriod.split('=')[2].trim().replace(' ', '') +
              '_' +
              reitNamePeriod.split('=')[1].trim().split(',')[0].trim() +
              '_' +
              checklistType +
              '.docx';
          } else {
            filename =
              reitNamePeriod.split('=')[2].trim().replace(' ', '') +
              '_' +
              reitNamePeriod.split('=')[1].trim().split(',')[0].trim() +
              '_' +
              propName +
              '_' +
              checklistType +
              '.docx';
          }

          dispatch({ type: actions.TASK_END, [pendingTask]: end });
          download(blob, filename);
        });
      })
      .catch((error) => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.TASK_END,
          null,
          error
        );
      });
  };
}

/**
 * Downloads the selected checklists on the external checklist dashboard
 * @param {any} request The request body for the api call
 * @param {any} checklistType The type of checklist (PSQ/ARQC)
 * @param {any} downloadType Type of checklist download
 * @returns {Promise} A Promise
 */
export function downloadChecklists(request, checklistType, downloadType) {
  return (dispatch) => {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/checklistsDownload/downloadChecklists/${checklistType}/${downloadType}`,
      {
        headers: {
          Accept: 'application/octet-stream',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(request),
      }
    )
      .then((response) => {
        return response.blob().then((blob) => {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          let filename = contentDisposition.split(';')[1].split('=')[1].trim();
          download(blob, filename);
          dispatch({ type: actions.TASK_END, [pendingTask]: end });
        });
      })
      .catch((error) => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.TASK_END,
          null,
          error
        );
      });
  };
}

/**
 * Fetch the report items for client
 * @param {any} clientId The client id
 * @returns {any} A Promise
 */
export function fetchReportItemsForClient(clientId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/reportItems/client/${clientId}`)
            .then((response) => {
                return response.json();
            })
            .then((items) => {
               if (actionHelpers.isErrorResponse(items)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_REPORT_ITEMS_FOR_CLIENT_FAILURE,
                        items
                    );
               }

               dispatch({
                    type: actions.LOAD_REPORT_ITEMS_FOR_CLIENT_SUCCESS,
                    items,
                    [pendingTask]: end,
               });
            })
            .catch((error) => {
               return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_REPORT_ITEMS_FOR_CLIENT_FAILURE,
                    null,
                    error
               );
            });
    };
}
