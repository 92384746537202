import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the GET_NOTIFICATIONS_SUCCESS action and sets the notifications in the store
 * @param {Object} [state] The notifications currently in the store, or the initial state of the notifications
 * @param {Object} action The action to be handled
 * @returns {Object} The notifications
 */
export default function notifications(state = initialState.notifications, action) {
    switch (action.type) {
        case actions.GET_NOTIFICATIONS_SUCCESS:
            return action.notifications;
        default:
            return state;
    }
}