import PropTypes from "prop-types";
import React from "react";
import YesNoModal from "../shared/YesNoModal";

const ReactivateClientModal = ({ showReactivateClientModal, handleReactivateClient, handleCancel, clientData, pendingTasks }) => {

    let question = `Are you sure you want to reactivate client ${clientData.legalName}?`;
    return (
        <YesNoModal
            showModal={showReactivateClientModal}
            handleYesAction={handleReactivateClient}
            handleNoAction={handleCancel}
            identifier={clientData.id}
            title="Reactivate Inactive Client"
            question={question}
        />
    );
};

ReactivateClientModal.propTypes = {
    showReactivateClientModal: PropTypes.bool.isRequired,
    handleReactivateClient: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    clientData: PropTypes.object.isRequired,
    pendingTasks: PropTypes.number
};

export default ReactivateClientModal;