import { pendingTask, begin, end } from '../constants/pendingTask';

import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download } from '../scripts/fileHelpers';

/**
 * Fetch all the Bulk report packages for a client. If successful this will dispatch the LOAD_BULK_REPORT_PACKAGES_SUCCESS
 * action, otherwise it will dispatch the LOAD_BULK_REPORT_PACKAGES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchBulkReportPackages(clientId) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_BULK_REPORT_PACKAGES_SUCCESS,
        bulkReportPackages: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
      return fetch(`/bulkReportPackage/client/${clientId}`)
      .then((response) => {
        return response.json();
      })
        .then((bulkReportPackages) => {
            if (actionHelpers.isErrorResponse(bulkReportPackages)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
              actions.LOAD_BULK_REPORT_PACKAGES_FAILURE,
              bulkReportPackages
          );
        }
            
        dispatch({
            type: actions.LOAD_BULK_REPORT_PACKAGES_SUCCESS,
            bulkReportPackages,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
            actions.LOAD_BULK_REPORT_PACKAGES_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Fetch ETA details of all the Bulk report packages for a client. If successful this will dispatch the LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS
 * action, otherwise it will dispatch the LOAD_BULK_REPORT_PACKAGES_ETA_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchBulkReportPackagesETA(clientId) {
    return function (dispatch) {
        if (!clientId) {
            return dispatch({
                type: actions.LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS,
                bulkReportPackagesETA: [],
            });
        }
       
        return fetch(`/bulkReportPackage/eta/${clientId}`)
            .then((response) => {
                return response.json();
            })
            .then((bulkReportPackagesETA) => {
                if (actionHelpers.isErrorResponse(bulkReportPackagesETA)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_BULK_REPORT_PACKAGES_ETA_FAILURE,
                        bulkReportPackagesETA
                    );
                }
                
                dispatch({
                    type: actions.LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS,
                    bulkReportPackagesETA,                    
                });

                return bulkReportPackagesETA;
            })
            .catch((error) => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_BULK_REPORT_PACKAGES_ETA_FAILURE,
                    null,
                    error
                );
            });
    };
}

/**
* Creates a Bulk Report Package.
 * If successful this will return response,
 * otherwise it will dispatch the CREATE_BULK_REPORT_PACKAGES_FAILURE action.
* @param {any} request The Bulk Report package request
* @returns {Promise} A Promise
*/
export function createBulkReportPackage(request) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin }); 
        
        return fetch(`/bulkReportPackage`,
            {
                headers: {
                    'Accept':
                        "application/json",
                    'Content-Type': "application/json"
                },
                method: "POST",
                body: JSON.stringify(request)
            }).then(response => {
                return response.json();
            }).then(result => {
                if (actionHelpers.isErrorResponse(result)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_BULK_REPORT_PACKAGES_FAILURE, result);
                }                

                dispatch({ type: actions.CREATE_BULK_REPORT_PACKAGES_SUCCESS, result, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_BULK_REPORT_PACKAGES_FAILURE, null, error);
            });
    };
}

/**
 * Downloads the bulk report package.
 * @param {any} clientId The client id.
 * @param {any} bulkReportPackageId The bulk report package id.
 * @param {any} bulkReportPackageName The bulk report package name.
 * @param {any} bulkReportPackageZipFileGUID The bulk report package zip file guid.
 * @returns {Promise} A Promise
 */
export function downloadBulkReportPackage(clientId, bulkReportPackageId, bulkReportPackageName, bulkReportPackageZipFileGUID) {
    return (dispatch) => {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(
            `/bulkReportPackage/downloadBulkReportPackage?clientId=${clientId}&bulkReportPackageId=${bulkReportPackageId}&bulkReportPackageName=${bulkReportPackageName}&bulkReportPackageZipFileGUID=${bulkReportPackageZipFileGUID}`,
            {
                headers: {
                    Accept: 'application/octet-stream',
                    'Content-Type': 'application/json',
                },
                method: 'POST'
            }
        )
            .then((response) => {
                return response.blob().then((blob) => {
                    const contentDisposition = response.headers.get(
                        'Content-Disposition'
                    );
                    let filename = contentDisposition.split(';')[1].split('=')[1].trim();
                    download(blob, filename);
                    dispatch({ type: actions.TASK_END, [pendingTask]: end });
                });
            })
            .catch((error) => {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.TASK_END,
                    null,
                    error
                );
            });
    };
}

export function deleteBulkReportPackage(clientID, bulkReportPackageID) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin }); 
      
      return fetch(`/bulkReportPackage/deleteBulkReportPackage?clientID=${clientID}&bulkReportPackageID=${bulkReportPackageID}`,
          {
              headers: {
                  'Accept':
                      "application/json",
                  'Content-Type': "application/json"
              },
              method: "DELETE",
          }).then(response => {
              return response.json();
          }).then(result => {
              if (actionHelpers.isErrorResponse(result)) {
                  return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DELETE_BULK_REPORT_PACKAGES_FAILURE, result);
              }                

              dispatch({ type: actions.DELETE_BULK_REPORT_PACKAGES_FAILURE, result, [pendingTask]: end });
          }).catch(error => {
              actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DELETE_BULK_REPORT_PACKAGES_FAILURE, null, error);
          });
  };
}