import { pendingTask, begin, end } from '../constants/pendingTask';

import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';

/**
 * Fetch all the PSQ templates for a client. If successful this will dispatch the LOAD_PSQ_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_PSQ_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchPSQTemplates(clientId) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_PSQ_TEMPLATES_SUCCESS,
        psqTemplates: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/psqTemplates/${clientId}`)
      .then((response) => {
        return response.json();
      })
      .then((psqTemplates) => {
        if (actionHelpers.isErrorResponse(psqTemplates)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_PSQ_TEMPLATES_FAILURE,
            psqTemplates
          );
        }
        dispatch({
          type: actions.LOAD_PSQ_TEMPLATES_SUCCESS,
          psqTemplates,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_PSQ_TEMPLATES_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Create a new custom psq template for a client. If successful this will dispatch the CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS
 * action, otherwise it will dispatch the CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE action.
 * @param {object} request The request model.
 * @returns {function} A function that returns a Promise.
 */
export function createCustomPsqTemplate(request) {
  return async function (dispatch) {
    if (!request) {
      return dispatch({
        type: actions.CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE,
        templateId: 0,
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    await fetch(`/customChecklistTemplate/psq`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    })
      .then((response) => {
        return response.json();
      })
      .then((createTemplateResponse) => {
        if (actionHelpers.isErrorResponse(createTemplateResponse)) {
          const templateId = 0;

          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE,
            templateId
          );
        }

        const templateId = createTemplateResponse.templateId;

        dispatch({
          type: actions.CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS,
          templateId,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        const templateId = 0;

        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE,
          templateId,
          error
        );
      });
  };
}

/**
 * Update an existing custom psq template for a client and template id. If successful this will dispatch the CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS
 * action, otherwise it will dispatch the CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE action.
 * @param {object} request The request model.
 * @returns {function} A function that returns a Promise.
 */
export function upsertCustomPsqTemplate(request) {
  return async function (dispatch) {
    if (!request) {
      return dispatch({
        type: actions.UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE,
        templateId: 0,
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    await fetch(
      `/customChecklistTemplate/psq?clientId=${request.clientId}&templateId=${request.templateId}&templateName=${request.templateName}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(request),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((createTemplateResponse) => {
        if (actionHelpers.isErrorResponse(createTemplateResponse)) {
          const templateId = 0;

          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE,
            templateId
          );
        }

        const templateId = request.templateId;

        dispatch({
          type: actions.UPSERT_CUSTOM_PSQ_TEMPLATE_SUCCESS,
          templateId,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        const templateId = 0;

        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE,
          templateId,
          error
        );
      });
  };
}

/**
 * Fetch details of a custom psq template for a given client & template id. If successful this will dispatch the LOAD_CUSTOM_PSQ_TEMPLATE_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_PSQ_TEMPLATE_FAILURE action.
 * @param {number} templateId The id of the checklist template.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomPsqTemplateById(clientId, templateId) {
  return (dispatch) => {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_CUSTOM_PSQ_TEMPLATE_SUCCESS,
        customPsqTemplate: customPsqTemplateDefaultState,
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(
      `/customChecklistTemplate/psq?clientId=${clientId}&templateId=${templateId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((customPsqTemplate) => {
        if (actionHelpers.isErrorResponse(customPsqTemplate)) {
          const customPsqTemplate = customPsqTemplateDefaultState;

          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_PSQ_TEMPLATE_FAILURE,
            customPsqTemplate
          );
        }

        dispatch({
          type: actions.LOAD_CUSTOM_PSQ_TEMPLATE_SUCCESS,
          customPsqTemplate: transformTemplateQuestions(customPsqTemplate),
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        const customPsqTemplate = customPsqTemplateDefaultState;

        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CUSTOM_PSQ_TEMPLATE_FAILURE,
          customPsqTemplate,
          error
        );
      });
  };
}

export const customPsqTemplateDefaultState = {
  clientId: 0,
  templateId: 0,
  templateName: '',
  templateInitializationType: 'blankTemplate',
  copiedFromTemplateName: '',
  isCommitted: true,
  sections: [
    {
      sectionId: 0,
      sectionName: '',
      sectionSortOrder: 1,
      sectionIsLocked: false,
      questions: [
        {
          questionId: 0,
          questionSortOrder: 1,
          parentQuestionId: null,
          questionText: '',
          questionHelpText: '',
          questionTag: '',
          questionTypeId: 1,
          questionTypeDescription: '',
          systemRuleJSON: null,
          childQuestionDisplayRuleJSON: {},
          sectionDisplayRuleJSON: null,
          isLocked: false,
          picklistItemName: '',
        },
      ],
    },
  ],
};

export function fetchAvailableQuestion(clientId, checklistTypeID, templateId) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_SUCCESS,
        availableQuestion: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/customChecklistTemplate/availableQuestion/${clientId}?checklistTypeID=${checklistTypeID}&checklistTemplateID=${templateId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((availableQuestion) => {
        if (actionHelpers.isErrorResponse(availableQuestion)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_FAILURE,
            availableQuestion
          );
        }

        dispatch({
          type: actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_SUCCESS,
          availableQuestion: transformAvailableQuestions(availableQuestion),
          [pendingTask]: end,
        });
        return availableQuestion;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Transform the Available questions to format required by Drag and Drop
 * @param {*} availableQuestion
 * @returns Transformed Available questions
 */
function transformAvailableQuestions(availableQuestion) {
  availableQuestion &&
    availableQuestion.map((item) => {
      item['category'] = 'availablequestion';
      item['sectionID'] = -1;
      item['sectionName'] = '';
      item['isAccordionOpen'] = false;

      item.childQuestions.map((childItem) => {
        childItem['isAccordionOpen'] = false;
      });
    });
  return availableQuestion;
}

/**
 * Transform the Template questions to format required by Drag and Drop
 * @param {*} customPsqTemplate
 * @returns Transformed Template questions
 */
function transformTemplateQuestions(customPsqTemplate) {
  if (
    customPsqTemplate &&
    customPsqTemplate.sections &&
    customPsqTemplate.sections.length > 0
  ) {
    customPsqTemplate.sections.map((sectionItem) => {
      sectionItem['isAccordionOpen'] = false;
      sectionItem['isNewSection'] = false;
      sectionItem['sectionHighlightClass'] = '';

      sectionItem.questions.map((item) => {
        //Update category, section details for Drag and Drop functionality
        item['category'] = 'sectionquestion';
        item['sectionID'] = sectionItem.sectionID;
        item['sectionName'] = sectionItem.sectionName;
        item['sectionIsLocked'] = sectionItem.sectionIsLocked;
        item['sectionSortOrder'] = sectionItem.sectionSortOrder;
        item['isAccordionOpen'] = false;

        item.childQuestions &&
          item.childQuestions.map((childItem) => {
            // let randomNumber = parseInt(Math.random() * (99999999 - 1)) + 1;

            childItem['category'] = 'sectionquestion';
            // childItem['questionID'] = randomNumber;
            childItem['isAccordionOpen'] = false;
          });
      });
    });
  }
  return customPsqTemplate;
}

export function fetchQuestionById(
  clientId,
  checklistTypeID,
  templateId,
  questionId
) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_SUCCESS,
        availableQuestionByID: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/customChecklistTemplate/availableQuestion/${clientId}?checklistTypeID=${checklistTypeID}&checklistTemplateID=${templateId}&questionID=${questionId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((availableQuestionByID) => {
        if (actionHelpers.isErrorResponse(availableQuestionByID)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_FAILURE,
            availableQuestionByID
          );
        }
        dispatch({
          type: actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_SUCCESS,
          availableQuestionByID,
          [pendingTask]: end,
        });
        return availableQuestionByID;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_FAILURE,
          null,
          error
        );
      });
  };
}

export function dispatchAvailableQuestion(availableQuestion) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return dispatch({
      type: actions.LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_SUCCESS,
      availableQuestion,
      [pendingTask]: end,
    });
  };
}

/**
 * Fetch all custom checklist template lookup types data
 * @returns {Promise} A Promise
 */
export function fetchCustomChecklistTemplateLookupTypes() {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/customChecklistTemplate/lookupData`, {
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((fetchedTypes) => {
        dispatch({
          type: actions.LOAD_ALL_CUSTOM_CHECKLIST_TEMPLATE_LOOKUPTYPES_SUCCESS,
          fetchedTypes,
          [pendingTask]: end,
        });
      });
  };
}

/**
 * Fetch contents of a custom psq template for a given client & template id. If successful this will dispatch the LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_FAILURE action.
 * @param {number} templateId The id of the checklist template.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomPsqTemplateContentById(clientId, templateId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(
      `/customChecklistTemplate/preview?clientId=${clientId}&templateId=${templateId}`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((customPsqTemplateContent) => {
        if (actionHelpers.isErrorResponse(customPsqTemplateContent)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_FAILURE,
            customPsqTemplateContent
          );
        }

        dispatch({
          type: actions.LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_SUCCESS,
          customPsqTemplateContent,
          [pendingTask]: end,
        });
        return customPsqTemplateContent;
      });
  };
}

/**
 * Fetch Checklist Template record by Checklist Template Id
 * action, otherwise it will dispatch the LOAD_CHECKLIST_TEMPLATE_FAILURE action.
 * @param {object} request The request model.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChecklistTemplateByChecklistTemplateId(request) {

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/customChecklistTemplate/template?clientId=${request.clientId}&templateId=${request.templateId}`)
            .then(response => {
                return response.json();
            })
            .then(checklistTemplateResponse => {
                
                if (actionHelpers.isErrorResponse(checklistTemplateResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_CHECKLIST_TEMPLATE_FAILURE,
                        checklistTemplateResponse,
                    );
                }

                dispatch({
                  type: actions.LOAD_CHECKLIST_TEMPLATE_SUCCESS,
                  checklistTemplateResponse,
                  [pendingTask]: end,
                });
                
                return checklistTemplateResponse;
            })
            .catch(error => {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_CHECKLIST_TEMPLATE_FAILURE,
                    null,
                    error,
                );
            });
    };
}


/**
 * Lock or Unclok Checklist Template
 * action, otherwise it will dispatch the UPDATE_CHECKLIST_TEMPLATE_TO_LOCK_UNLOCK_FAILURE action.
 * @param {object} request The request model.
 * @returns {function} A function that returns a Promise.
 */
export function updateChecklistTemplateToLockOrUnlock(request) {    

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/customChecklistTemplate/lockTemplate?clientId=${request.clientId}&templateId=${request.templateId}&lockTemplate=${request.lockTemplate}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                method: 'PUT'
            })
            .then(response => {
                return response.json();
            })
            .then(updateTemplateResponse => {
                if (actionHelpers.isErrorResponse(updateTemplateResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.UPDATE_CHECKLIST_TEMPLATE_TO_LOCK_UNLOCK_FAILURE,
                        updateTemplateResponse,
                    );
                }
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return updateTemplateResponse;
            })
            .catch(error => {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPDATE_CHECKLIST_TEMPLATE_TO_LOCK_UNLOCK_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Enable enable mode of Checklist Template
 * action, otherwise it will dispatch the UPDATE_CHECKLIST_TEMPLATE_TO_ENABLE_EDIT_MODE_FAILURE action.
 * @param {object} request The request model.
 * @returns {function} A function that returns a Promise.
 */
export function updateChecklistTemplateToEnableEditMode(request) {

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/customChecklistTemplate/editModeTemplate?clientId=${request.clientId}&templateId=${request.templateId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                method: 'PUT'
            })
            .then(response => {
                return response.json();
            })
            .then(updateTemplateResponse => {
                if (actionHelpers.isErrorResponse(updateTemplateResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.UPDATE_CHECKLIST_TEMPLATE_TO_ENABLE_EDIT_MODE_FAILURE,
                        updateTemplateResponse,
                    );
                }
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return updateTemplateResponse;
            })
            .catch(error => {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPDATE_CHECKLIST_TEMPLATE_TO_ENABLE_EDIT_MODE_FAILURE,
                    null,
                    error,
                );
            });
    };
}

export function persistUncommittedTemplateSectionChanges(request) {
  return function (dispatch) {
    if (!request) {
      return actionHelpers.dispatchErrorAndEndTask(
        dispatch,
        actions.PERSIST_UNCOMMITTED_TEMPLATE_SECTION_CHANGES_FAILURE,
        null
      );
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/customChecklistTemplate/uncommitted/section`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(request),
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((res) => {
        const jsonResponse = res ? JSON.parse(res) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {

          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.PERSIST_UNCOMMITTED_TEMPLATE_SECTION_CHANGES_FAILURE,
            null
          );
        }
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.PERSIST_UNCOMMITTED_TEMPLATE_SECTION_CHANGES_FAILURE,
          null,
          error
        );
      });
  };
}

export function commitCustomTemplateTemplateChanges(request) {
  return (dispatch) => {
    if (!request) {
      return actionHelpers.dispatchErrorAndEndTask(
        dispatch,
        actions.COMMIT_TEMPLATE_SECTION_CHANGES_FAILURE,
        null
      );
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/customChecklistTemplate/commit`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(request),
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((res) => {
        const jsonResponse = res ? JSON.parse(res) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {

          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.COMMIT_TEMPLATE_SECTION_CHANGES_FAILURE,
            null
          );
        }
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.COMMIT_TEMPLATE_SECTION_CHANGES_FAILURE,
          null,
          error
        );
      });
  };
}

export function abandonCustomTemplateTemplateChanges(request) {
  return (dispatch) => {
    if (!request) {
      return actionHelpers.dispatchErrorAndEndTask(
        dispatch,
        actions.ABANDON_TEMPLATE_SECTION_CHANGES_FAILURE,
        null
      );
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/customChecklistTemplate/abandon`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(request),
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((res) => {
        const jsonResponse = res ? JSON.parse(res) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {

          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.ABANDON_TEMPLATE_SECTION_CHANGES_FAILURE,
            null
          );
        }
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.ABANDON_TEMPLATE_SECTION_CHANGES_FAILURE,
          null,
          error
        );
      });
  };
}
