import React from 'react';
import { MotifCheckbox } from "@ey-xd/motif-react";

export default (props) => {
  const id = props.node.key;
  const value = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data[props.colDef.field] : null;
  const checklistId = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data["checklistID"] : null;

  if (!props.data && props.node.childrenAfterGroup){
    const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
    let checkboxClass = value ? defaultCheckboxClass + " ag-checked" : defaultCheckboxClass;
    
    return (
      <span>
        <MotifCheckbox
          type="checkbox"
          name="cell-checkbox"
          id={`ag-cell-checkbox-${id}`}
          checked={value}
          tabindex="-1"
          data-testid="selectCheckboxHeader"
          aria-label="Press Space to toggle row selection"
          onClick={() => props.onCellCheckboxChange(checklistId, props.node)}>
        </MotifCheckbox>
      </span>
    );
  }

  return null;
};