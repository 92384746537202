import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";

import { MotifButton,MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter, MotifLabel } from "@ey-xd/motif-react";

const EditSaveModalMessage = ({
    showEditSaveModalMessage,
    toggleShowEditSaveModalMessage,
    handleAccountUpdateConfirmation,
    theme
}) => {
    // const listModalColor = theme === 'motifDark' ? '#a4a3b1' : '#656579';
    return (
        <MotifModal aria-label="Edit Account Notification" show={showEditSaveModalMessage} onClose={toggleShowEditSaveModalMessage}>
            <MotifModalHeader>Edit Client Accounts Confirmation</MotifModalHeader>
            <MotifModalBody>
                <form>
                    <MotifLabel className="motifLabelh6">Changes will be processed as follows on submit:</MotifLabel>
                    <ol>
                        <li>New accounts will be all created on all assignments for this chart (including closed periods).</li>
                        <li>Updates to account numbers and account descriptions will be processed to all assignments for this chart (including closed periods).</li>
                        <li>Changes to the default REIT testing attribute will not be pushed to any assignments for this chart – default mappings are only assigned at REIT creation or can be restored on the Account Mappings screen for each REIT period.</li>
                    </ol>
                    <span><b>Note:</b> If you do not want to make changes to a historical period, please create a new chart.</span>
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={toggleShowEditSaveModalMessage} >
                    Cancel
                </MotifButton>
                <MotifButton variant="primary" className="right" type="button"
                    onClick={handleAccountUpdateConfirmation}>
                    Submit
                </MotifButton>                
            </MotifModalFooter>
        </MotifModal>
    );
};

EditSaveModalMessage.propTypes = {
    showEditSaveModalMessage: PropTypes.bool,
    toggleShowEditSaveModalMessage: PropTypes.func,
    handleAccountUpdateConfirmation: PropTypes.func,
    theme: PropTypes.string,
};

export default EditSaveModalMessage;