import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as authActions from '../actions/authActions';

const RoleGuard = ({ systemAdminFlag, engagementAdminFlag, engagementUserFlag, children }) => {
    const location = useLocation();
    const currentUserAuthorizations = useSelector(state => state.currentUserAuthorizations);
    const isSystemAdmin = authActions.isSystemAdministrator(currentUserAuthorizations);

    // Function to extract clientId from the URL
    const getClientIdFromPath = (path) => {
        const clientRegex = /\/client\/(\d+)/i;
        const match = path.match(clientRegex);
        return match ? parseInt(match[1], 10) : -1;
    };

    // Get clientId from the current location
    const clientId = getClientIdFromPath(location.pathname);

    // Determine if the navigation is invalid based on roles and flags
    let invalid = false;
    if (isSystemAdmin && !systemAdminFlag) {
        invalid = true;
    } else if (!isSystemAdmin && clientId > 0 && authActions.isEngagementAdministrator(currentUserAuthorizations, clientId) && !engagementAdminFlag) {
        invalid = true;
    } else if (!isSystemAdmin && clientId > 0 && authActions.isUser(currentUserAuthorizations, clientId) && !engagementUserFlag) {
        invalid = true;
    }

    // Redirect to unauthorized if the user's role is not allowed
    if (invalid) {
        return <Navigate to="/unauthorized" replace />;
    }

    // If the user's role is allowed, render the children components
    return children;
};

export default RoleGuard;
