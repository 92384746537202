import * as React from 'react';
import { EditorUtils } from '@progress/kendo-react-editor';
import { ComboBox } from '@progress/kendo-react-dropdowns';

const wordFontSizes =
    [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

const comboBoxFontSize = '10pt';

const roundToNearestHalf = function (n) {
    return Math.round(n * 2) / 2;
}

/* Ensure font size doesn't have more than one decimal place. */
const filterFontSize = function (fontSize) {
    if (fontSize < 1)
        fontSize = 1;

    if (fontSize > 1638)
        fontSize = 1638;

    fontSize = roundToNearestHalf(fontSize);

    if (fontSize % 1 === 0) {
        return fontSize.toFixed();
    } else {
        return fontSize.toFixed(1);
    }
}

const selectedProps = {
    style: {
        backgroundColor: '#007bff',
        color: 'white',
        fontSize: comboBoxFontSize
    },
    selected: true
};

const nonSelectedProps = {
    style: {
        fontSize: comboBoxFontSize
    }
};

const CustomFontSizeTool = (props) => {
    const { view } = props;
    const styleQuery = { name: 'font-size', value: /^\d+(\.\d+)?pt$/ };

    const currentFontSizes =
        view ? EditorUtils.getInlineStyles(view.state, styleQuery) : [];

    const singleFontSizeSelected = new Set(currentFontSizes).size === 1;

    const currentFontSize =
        singleFontSizeSelected ? `${parseFloat(currentFontSizes[0])}` : '';

    const onChange = (event) => {
        const fontSize = parseFloat(event.value);

        if (!isNaN(fontSize)) {
            EditorUtils.applyInlineStyle(
                view,
                { style: 'font-size', value: `${filterFontSize(fontSize)}pt` });

            setTimeout(() => view.focus());
        }
    };

    const itemRender = (li, itemProps) => {
        const itemMatchesFontSize =
            itemProps.dataItem.toString() === currentFontSize;


        const newItemProps =
            itemMatchesFontSize ? selectedProps : nonSelectedProps;

        return React.cloneElement(li, newItemProps);
    }

    return (
        <ComboBox
            data={wordFontSizes}
            allowCustom={true}
            onChange={onChange}
            value={currentFontSize}
            style={{ fontSize: comboBoxFontSize }}
            itemRender={itemRender} />
    );
};

export { CustomFontSizeTool };