import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Form, Icon, Popup, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { Link } from 'react-router-dom';
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem, MotifToast, MotifTextLink, MotifTable } from "@ey-xd/motif-react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DateComparator from './customComparators/DateComparator.js';
import CustomHeaderTemplate from './customHeaders/CustomHeaderTemplate.js';
import ClientsRecipientsCellRenderer from './customRenderers/ClientRecipientsCellRenderer';

const ScheduleChecklistTable = ({
    filteredScheduleChecklists,
    clientId,
    gridColumnState,
    handleGridColumnStateChange,
    handleGridChanges,
    isSystemOrEngagementAdmin
}) => {
    const gridRef = useRef();
    const [hideInfoMessage, setHideInfoMessage] = useState(false);
    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
        };
    }, []);

    //Show date in M-D-YY format
    const dateOptions = {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit'
    };

    const getColumnDefs = () => {
        return [
            {
                field: 'checklistType', headerName: 'Checklist Type', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 130, maxWidth: 130,
                style: { 'display': 'inline' },
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['checklistType'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.checklistType; },
                headerComponentParams: { template: CustomHeaderTemplate('checklistType') }
            },
            {
                field: 'reitName', headerName: 'REIT', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 200, maxWidth: 200,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['reitName'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.reitName; },
                headerComponentParams: { template: CustomHeaderTemplate('reitName') }
            },
            {
                field: 'propertyName', headerName: 'Property Name', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 130, maxWidth: 300,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['propertyName'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.propertyName; },
                headerComponentParams: { template: CustomHeaderTemplate('propertyName') }
            },
            {
                field: 'reitOrPropertyTypeDescription', headerName: 'Type', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 110, maxWidth: 110,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['reitOrPropertyTypeDescription'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.reitOrPropertyTypeDescription; },
                headerComponentParams: { template: CustomHeaderTemplate('type') }
            },

            {
                field: 'templateName', headerName: 'Template', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 230, maxWidth: 230,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['templateName'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.templateName; },
                headerComponentParams: { template: CustomHeaderTemplate('templateName') }
            },
            {
                field: 'prePopulateAnswerOptionDescription', headerName: 'Pre-Populate Answers? / Show Reference Answers?', sortable: true,
                minWidth: 180, maxWidth: 180,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['prePopulateAnswerOptionDescription'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.prePopulateAnswerOptionDescription; },
                headerComponent: 'CustomHeaderForLongHeader'
                // headerComponentParams: { template: CustomHeaderTemplate('prepopulatedShowReferenceAnswers') }
            },
            {
                field: 'rollingForwardQuestionDescription', headerName: 'Roll Forward Question Comments? / Attachments?', sortable: true,
                minWidth: 180, maxWidth: 180,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['rollingForwardQuestionDescription'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.prePopulateAnswerOptionDescription; },
                headerComponent: 'CustomHeaderForLongHeader'
                // headerComponentParams: { template: CustomHeaderTemplate('prepopulatedShowReferenceAnswers') }
            },
            {
                field: 'clientRecipients', headerName: 'Client Recipient', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 200, maxWidth: 200,
                cellRenderer: ClientsRecipientsCellRenderer,
                headerComponentParams: { template: CustomHeaderTemplate('clientRecepients') }
            },
            {
                field: 'sendDateQ1', headerName: 'Q1', sortable: true, minWidth: 80, maxWidth: 80,
                // Q1 => If Report Period is closed then show 'Closed' Text instead date scheduled for that quarter
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data["isReportPeriodClosedForQ1"]
                            ? 'Closed'
                            : params.node.data["sendDateQ1"] ? (new Date(params.node.data["sendDateQ1"])).toLocaleDateString('en-US', dateOptions) : ''
                        : null;
                },
                filterValueGetter: params => {
                    return params.data
                        ? params.data["isReportPeriodClosedForQ1"]
                            ? 'Closed'
                            : params.data["sendDateQ1"] ? (new Date(params.data.sendDateQ1)).toLocaleDateString('en-US', dateOptions) : 'N/A'
                        : null;
                },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('q1') }
            },
            {
                field: 'sendDateQ2', headerName: 'Q2', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 80, maxWidth: 80,
                // Q2 => If Report Period is closed then show 'Closed' Text instead date scheduled for that quarter
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data["isReportPeriodClosedForQ2"]
                            ? 'Closed'
                            : params.node.data["sendDateQ2"] ? (new Date(params.node.data["sendDateQ2"])).toLocaleDateString('en-US', dateOptions) : ''
                        : null;
                },
                filterValueGetter: params => {
                    return params.data
                        ? params.data["isReportPeriodClosedForQ2"]
                            ? 'Closed'
                            : params.data["sendDateQ2"] ? (new Date(params.data.sendDateQ2)).toLocaleDateString('en-US', dateOptions) : 'N/A'
                        : null;
                },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('q2') }
            },
            {
                field: 'sendDateQ3', headerName: 'Q3', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 80, maxWidth: 80,
                // Q3 => If Report Period is closed then show 'Closed' Text instead date scheduled for that quarter
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data["isReportPeriodClosedForQ3"]
                            ? 'Closed'
                            : params.node.data["sendDateQ3"] ? (new Date(params.node.data["sendDateQ3"])).toLocaleDateString('en-US', dateOptions) : ''
                        : null;
                },
                filterValueGetter: params => {
                    return params.data
                        ? params.data["isReportPeriodClosedForQ3"]
                            ? 'Closed'
                            : params.data["sendDateQ3"] ? (new Date(params.data.sendDateQ3)).toLocaleDateString('en-US', dateOptions) : 'N/A'
                        : null;
                },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('q3') }
            },
            {
                field: 'sendDateQ4', headerName: 'Q4', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 80, maxWidth: 80,
                // Q4 => If Report Period is closed then show 'Closed' Text instead date scheduled for that quarter
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data["isReportPeriodClosedForQ4"]
                            ? 'Closed'
                            : params.node.data["sendDateQ4"] ? (new Date(params.node.data["sendDateQ4"])).toLocaleDateString('en-US', dateOptions) : ''
                        : null;
                },
                filterValueGetter: params => {
                    return params.data
                        ? params.data["isReportPeriodClosedForQ4"]
                            ? 'Closed'
                            : params.data["sendDateQ4"] ? (new Date(params.data.sendDateQ4)).toLocaleDateString('en-US', dateOptions) : 'N/A'
                        : null;
                },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('q4') }
            }

        ];
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }

        // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
        if (type.indexOf("filterChanged") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }

        if (type.indexOf("rowDataUpdated") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }        
    }

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem('REITSuiteScheduleChecklistColumnState');
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }

        // Set the page size and total pages on the first load
        handleItemsPerPageChange(100); // Setting it 100 to make sure the default number of items on load is always set to 100
    }

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            if (currentPage > calcTotalPages) {
                if (calcTotalPages == 0) {
                    setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
                }
                else {
                    setCurrentPage(calcTotalPages);
                }
            }
            const gridCurrentPage = gridRef.current.api.paginationGetCurrentPage() + 1; // Adding 1 since it returns zero based index
            if (currentPage < (gridCurrentPage) && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }
            
            // Show the expected value as 1 for current page 
            if (currentPage <= 1 &&  gridCurrentPage == 1 && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }            
        }
    }

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {

        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem('REITSuiteScheduleChecklistColumnState');

        // If the column state details are not exists in local storage then save the changed Column state data
        if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
            saveUserPreferenceDetails();
        }
        else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (columnsStateArray && columnsStateArray.length > 0
                && currentColumnState && currentColumnState.length > 0
                && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {
        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem('REITSuiteScheduleChecklistColumnState', JSON.stringify(columnState));
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {

        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
    }, [rowData]);

    useEffect(() => {

        setRowData(filteredScheduleChecklists);

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshHeader();
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }

    }, [filteredScheduleChecklists]);

    // Handle Row data updated event 
    const handleRowDataUpdated = () => {
        if (gridRef && gridRef.current && gridRef.current.columnApi) {
            gridRef.current.columnApi.autoSizeAllColumns();
        }
    }

    useEffect(() => {

        //Reset Grid to default column state
        //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
        var localStorageColumnState = localStorage.getItem('REITSuiteScheduleChecklistColumnState');
        if (!localStorageColumnState && gridRef && gridRef.current && gridRef.current.api) {

            gridRef.current.api.setColumnDefs([]);
            gridRef.current.api.setColumnDefs(getColumnDefs());
        }

    }, [gridColumnState]);

    const rowHeight = 76;
    const headerHeight = 45;
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return <div className="row mb-3 margin-top-5">
        {filteredScheduleChecklists && filteredScheduleChecklists.length > 0 &&
            <div className="col mt-3">
                <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                    <MotifTable zebra={true} compact={true}
                        headerHeight={60}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        accentedSort={true}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        ref={gridRef}
                        pagination={true}
                        suppressPaginationPanel={true}
                        paginationPageSize="100"
                        groupDisplayType={'custom'}
                        onFirstDataRendered={handleFirstDataRendered}
                        onGridReady={handleGridReady}
                        onFilterChanged={handleFilterChanged}
                        onRowDataUpdated={handleRowDataUpdated}
                    />
                    <div className="mt-3">
                        <span className="left" style={{ minWidth: '0' }}>
                            {!hideInfoMessage && isSystemOrEngagementAdmin &&
                                <MotifToast className="mb-3" onClose={setHideInfoMessage} data-testid="eyToastMsg">
                                    EY Employees will receive email based on their notification preference settings.
                                    Click&nbsp;
                                    <Link tabIndex="-1" to={`/client/${clientId}/edit`} target="_blank" rel="noopener noreferrer" >
                                        <MotifTextLink variant="alt" href={`/client/${clientId}/edit`}
                                            target="_blank" rel="noopener noreferrer" > here </MotifTextLink>
                                    </Link>
                                    &nbsp;to view/edit their settings.
                                </MotifToast>
                            }
                        </span>

                        <span className="right mb-4">
                            <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
                                <MotifPaginationSelect>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(50)}>
                                        Show 50
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem selected onClick={() => handleItemsPerPageChange(100)}>
                                        Show 100
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(150)}>
                                        Show 150
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(200)}>
                                        Show 200
                                    </MotifPaginationSelectItem>
                                </MotifPaginationSelect>
                            </MotifPagination>
                        </span>
                    </div>
                </div>
            </div>
        }
    </div>;
};

ScheduleChecklistTable.propTypes = {

};

export default reduxForm({ form: "scheduleChecklist" })(ScheduleChecklistTable);