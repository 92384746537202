import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReportPeriodList from "./ReportPeriodList";
import { Button } from "semantic-ui-react";
import ScopedServicesContainer from "../../containers/reits/ScopedServicesContainer";
import CreatePeriod from "../../components/reits/CreatePeriod";
import ClosePeriodContainer from "../../containers/reits/ClosePeriodContainer";
import OpenPeriodContainer from "../../containers/reits/OpenPeriodContainer";

import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import PeriodTbModeModal from "../../components/reits/PeriodTbModeModal";


export const ReitDetails = ({ clientId, reit, activePeriodId, handleActivePeriodIdChanged, showServicesModal, toggleShowServicesModal, showNewPeriodModal, toggleShowNewPeriodModal, handleCreateNewPeriod, 
    createTypes, handleCreateTypeSelection, showClosePeriodModal, toggleShowClosePeriodModal, handleClosePeriod, handleQuarterSelection, handleYearSelection, selectedQuarter, selectedYear, handleOpenPeriod, 
    showOpenPeriodModal, toggleShowOpenPeriodModal, tbMode, toggleShowModeModal, showModeModal, handleCloseModeModal, handleSubmitTbMode, activePeriod, chartExistsForClient, clientIsAudit, currentUserAuthorizations,
    trialBalances, handleTrialBalancesByPeriod,hideTbModeLinkList,trialBalancesList, hideTestSnapshotLinkList, hasIncompleteChecklistsList, handleDownloadCurrentTestSnapshot, handleDownloadTbTemplates,
    handleDownloadMappingTemplatePackage  }) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-10">
                    <h2 className="pb-0 mb-0">Report Periods</h2>
                </div>
                <div className="col-md-2 d-flex align-items-center justify-content-end">
                    <CreatePeriod toggleShowNewPeriodModal={toggleShowNewPeriodModal} showModal={showNewPeriodModal} handleModalAction={handleCreateNewPeriod} createTypes={createTypes} handleQuarterSelection={handleQuarterSelection} handleYearSelection={handleYearSelection} selectedQuarter={selectedQuarter} selectedYear={selectedYear}/>
                </div>
            </div>
            <ReportPeriodList clientId={clientId} reitId={reit.reitid} reit={reit} reportPeriods={reit.periods} activePeriodId={activePeriodId} handleActivePeriodIdChanged={
                handleActivePeriodIdChanged} toggleShowClosePeriodModal={toggleShowClosePeriodModal} toggleShowOpenPeriodModal={toggleShowOpenPeriodModal} tbMode={tbMode} toggleShowModeModal={toggleShowModeModal} chartExistsForClient={chartExistsForClient} clientIsAudit={clientIsAudit}
                currentUserAuthorizations={currentUserAuthorizations} showClosePeriodModal={showClosePeriodModal} trialBalances={trialBalances} handleTrialBalancesByPeriod={handleTrialBalancesByPeriod}
                hideTbModeLinkList={hideTbModeLinkList} trialBalancesList={trialBalancesList} hideTestSnapshotLinkList={hideTestSnapshotLinkList} hasIncompleteChecklistsList={hasIncompleteChecklistsList}
                handleDownloadCurrentTestSnapshot={handleDownloadCurrentTestSnapshot} handleDownloadTbTemplates={handleDownloadTbTemplates} handleDownloadMappingTemplatePackage={handleDownloadMappingTemplatePackage}/>
            <ScopedServicesContainer periodId={activePeriodId} reitId={reit.reitid} showServicesModal={showServicesModal} toggleShowServicesModal={toggleShowServicesModal} />
            <ClosePeriodContainer showClosePeriodModal={showClosePeriodModal} toggleShowClosePeriodModal={toggleShowClosePeriodModal} handleClosePeriod={handleClosePeriod} handleCancel={toggleShowClosePeriodModal} periodId={activePeriodId} />
            <OpenPeriodContainer showOpenPeriodModal={showOpenPeriodModal} toggleShowOpenPeriodModal={toggleShowOpenPeriodModal} handleOpenPeriod={handleOpenPeriod} handleCancel={toggleShowOpenPeriodModal} periodId={activePeriodId} />
            <PeriodTbModeModal showModeModal={showModeModal} onSubmit={handleSubmitTbMode} handleCloseModeModal={handleCloseModeModal} initialValues={activePeriod} />
        </div>
    );
};

ReitDetails.propTypes = {
    clientId: PropTypes.number.isRequired,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        periods: PropTypes.array
    }),
    activePeriodId: PropTypes.number.isRequired,
    showServicesModal: PropTypes.bool,
    toggleShowServicesModal: PropTypes.func,
    showNewPeriodModal: PropTypes.bool,
    toggleShowNewPeriodModal: PropTypes.func,
    handleCreateNewPeriod: PropTypes.func,
    createTypes: PropTypes.array,
    handleQuarterSelection: PropTypes.func,
    handleYearSelection: PropTypes.func,
    handleActivePeriodIdChanged: PropTypes.func,
    handleCreateTypeSelection: PropTypes.func,
    showClosePerodModal: PropTypes.bool,
    toggleShowClosePeriodModal: PropTypes.func,
    handleClosePeriod: PropTypes.func,
    selectedQuarter: PropTypes.string,
    selectedYear: PropTypes.string,
    handleOpenPeriod: PropTypes.func,
    showOpenPeriodModal: PropTypes.bool,
    toggleShowOpenPeriodModal: PropTypes.func,
    tbMode: PropTypes.number,
    toggleShowModeModal: PropTypes.func,
    showModeModal: PropTypes.bool,
    handleCloseModeModal: PropTypes.func,
    handleSubmitTbMode: PropTypes.func,
    activePeriod: PropTypes.object,
    showClosePeriodModal: PropTypes.bool,
    trialBalances: PropTypes.array,
    handleTrialBalancesByPeriod: PropTypes.func,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        handleActivePeriodIdChanged: ownProps.handleActivePeriodIdChanged,
        toggleShowClosePeriodModal: ownProps.toggleShowClosePeriodModal,
        
    };
}

export default connect(mapStateToProps)(ReitDetails);