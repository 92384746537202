import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";
import { fetchFromReportApi as fetch, fetchFromTrialBalanceApi as fetchTb } from "./fetchFromApi";
import { getTrialBalancesByPeriod } from "./trialBalanceActions";
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch the income test summary belonging to the client. If successful this will dispatch the LOAD_INCOME_TEST_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_INCOME_TEST_SUMMARY_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} authHeader The authorization header to use. Supplied only for external users.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchIncomeTestSummary(periodId, authHeader) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return getTrialBalancesByPeriod(periodId, "adjusted", "consolidated", null, authHeader).then(tb => {
            
            let trialBalanceId = (tb.length > 0 && tb[0].trialBalanceID || "-1");
            return fetch(`/incomeTest?trialBalanceId=${trialBalanceId}`,
                {
                    headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
                }).then(response => {
                return response.json();
            }).then(incomeTestSummary => {
                if (actionHelpers.isErrorResponse(incomeTestSummary)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_INCOME_TEST_SUMMARY_FAILURE, incomeTestSummary);
                }

                if (Array.isArray(incomeTestSummary.details)) {
                    dispatch({ type: actions.LOAD_INCOME_DETAIL_SUCCESS, incomeDetails: incomeTestSummary.details });
                }

                dispatch({ type: actions.LOAD_INCOME_TEST_SUMMARY_SUCCESS, incomeTestSummary, [pendingTask]: end });
            });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_INCOME_TEST_SUMMARY_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the Property income test details.
 * If successful this will dispatch the LOAD_INCOME_TEST_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_INCOME_TEST_SUMMARY_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} authHeader The authorization header to use. Supplied only for external users.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchPropertyDetails(periodId, authHeader) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/incomeTest/property?periodId=${periodId}`,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            }).then(response => {
            return response.json();
        }).then(propertyTestDetails => {
            if (actionHelpers.isErrorResponse(propertyTestDetails)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTY_DETAIL_FAILURE, propertyTestDetails);
            }

            dispatch({ type: actions.LOAD_PROPERTY_DETAIL_SUCCESS, propertyDetails: propertyTestDetails, [pendingTask]: end });
        });
    };
}

/**
 * Fetch the Property income test details.
 * If successful this will dispatch the LOAD_INCOME_TEST_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_INCOME_TEST_SUMMARY_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} authHeader The authorization header to use. Supplied only for external users.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchPropertyItsi(periodId, authHeader) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/incomeTest/itsi?periodId=${periodId}`,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            }).then(response => {
            return response.json();
        }).then(propertyItsi => {
            if (actionHelpers.isErrorResponse(propertyItsi)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTY_ITSI_FAILURE, propertyItsi);
            }
            
            dispatch({ type: actions.LOAD_PROPERTY_ITSI_SUCCESS, propertyItsi: propertyItsi, [pendingTask]: end });
        });
    };
}

/**
 * Fetch the external income test summary belonging to the checklist. If successful this will dispatch the LOAD_INCOME_TEST_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_INCOME_TEST_SUMMARY_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} checklistId The id of the external checklist.
 * @param {string} checklistType The type of the external checklist.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalIncomeSummaryByChecklistId(periodId, checklistId, checklistType) {
    return fetchIncomeTestSummary(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}

/**
 * Fetch the external Property income test details belonging to the checklist.
 * If successful this will dispatch the LOAD_INCOME_TEST_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_INCOME_TEST_SUMMARY_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} checklistId The id of the external checklist.
 * @param {string} checklistType The type of the external checklist.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalPropertyDetailsByChecklistId(periodId, checklistId, checklistType) {
    return fetchPropertyDetails(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}

/**
 * Fetch the external Property itsi tests belonging to the checklist.
 * If successful this will dispatch the LOAD_PROPERTY_ITSI_SUCCESS
 * action, otherwise it will dispatch the LOAD_PROPERTY_ITSI_FAILURE action.
 * @param {number} periodId The id of the REIT period.
 * @param {string} checklistId The id of the external checklist.
 * @param {string} checklistType The type of the external checklist.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalPropertyItsiByChecklistId(periodId, checklistId, checklistType) {
    return fetchPropertyItsi(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}
