export const camelize = str => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

export const toTitleCase = str => {
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

export const stringToHash = str => { 
    let hash = 0; 
    let i = 0;
    let char = "";
    str = str.trim();
  
    if (str === "" && str.length == 0) {
        return hash;
    }

    for (i = 0; i < str.length; i++) { 
    char = str.charCodeAt(i); 
    hash = ((hash << 5) - hash) + char; 
    hash = hash & hash; 
    } 
    return hash; 
};
