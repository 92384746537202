import PropTypes from "prop-types";
import React from "react";
import { Button, Header } from "semantic-ui-react";

import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react";

const ChecklistReadyModal = ({ handleModalAction, showModal }) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
  return (
    <MotifModal
      variant="alt"
      size="sm"
      aria-label="Submit checklist for EY review"
      show={showModal}
      onClose={() => handleModalAction({ target: { value: "CANCEL" } })} 
      data-testid="ChecklistReady"
    >
      <MotifModalHeader
      closeButtonProps={() => handleModalAction({ target: { value: "CANCEL" } })} 
      >
        <HeaderWrapper>
          <span className="ml-2">Submit checklist for EY review</span>
        </HeaderWrapper>
      </MotifModalHeader>

      <MotifModalBody>
        <p>
          Are you sure your checklist is ready? You will not be able to update
          the checklist once it is submitted for EY review. Click
          &quot;Yes&quot; to submit to EY, or &quot;No&quot; to return to
          editing.
        </p>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          variant="secondary"
          className="right"
          type="button"
          onClick={handleModalAction}
          value="INPROGRESS"
        >
          No
        </MotifButton>
        <MotifButton
          variant="primary"
          className="right"
          type="button"
          onClick={handleModalAction}
          value="PENDINGEYREVIEW"
        >
          Yes
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

ChecklistReadyModal.propTypes = {
  showModal: PropTypes.bool,
  handleModalAction: PropTypes.func.isRequired,
};

export default ChecklistReadyModal;
