export default(valueA, valueB) => {
    if(valueA !== null && valueB === null) return -1;
    
    // The format is "Qx YYYY" where the last 4 characters are the year
    // and the quarter is denoted by a single digit following a 'Q'
    
    // Extract year and quarter from valueA
    let yearA = parseInt(valueA.slice(-4));
    let quarterA = parseInt(valueA.charAt(valueA.indexOf('Q') + 1));
    
    // Extract year and quarter from valueB
    let yearB = parseInt(valueB.slice(-4));
    let quarterB = parseInt(valueB.charAt(valueB.indexOf('Q') + 1));
    
    // Compare years first
    if (yearA !== yearB) {
        return yearB - yearA;
    }
    
    // If years are the same, compare quarters
    return  quarterB - quarterA;
}