import React from 'react';
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem } from '@ey-xd/motif-react'; // replace 'your-library' with the actual library name

const CustomPagination = ({ currentPage, handlePageChange, totalPages, handleItemsPerPageChange, gridRef, setCurrentPage, setItemsPerPage, setTotalPages }) => {
  const itemsPerPageOptions = [50, 100, 150, 200];

  return (
    <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
      <MotifPaginationSelect data-testid="itemsPerPage">
        {itemsPerPageOptions.map((option, index) => (
          <MotifPaginationSelectItem
            key={index}
            selected={option === 100} // adjust this condition based on your needs
            onClick={() => handleItemsPerPageChange(option, gridRef, setCurrentPage, setItemsPerPage, setTotalPages)}
          >
            {`Show ${option}`}
          </MotifPaginationSelectItem>
        ))}
      </MotifPaginationSelect>
    </MotifPagination>
  );
};

export default CustomPagination;