import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the TB_UPLOAD_START, TB_UPLOAD_COMPLETE, and TB_UPLOAD_FAILED actions and sets the upload processes in the store
 * @param {Object} [state] The upload processes currently in the store, or the initial state of the upload processes
 * @param {Object} action The action to be handled
 * @returns {Object} The upload processes
 */
export default function uploadProcesses(state = initialState.uploadProcesses, action) {
    switch (action.type) {
        case actions.TB_UPLOAD_START:
            return ++state;
        case actions.TB_UPLOAD_COMPLETE:
        case actions.TB_UPLOAD_FAILED:
            return --state;
        default:
            return state;
    }
}