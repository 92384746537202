import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the lookup types loading actions and sets an array of types in the store
 * @param {Array} [state] The lookupTypes currently in the store, or the initial state of REITs
 * @param {Object} action The action to be handled
 * @returns {Array} The types
 */
export default function lookupTypesReducer(state = initialState.lookupTypes, action) {
    switch (action.type) {
        //This two cases is an example of how we can use a reducer to assign types individualy
        case actions.LOAD_PROPERTY_TYPES_SUCCESS:
            return Object.assign({}, state, {
                propertyTypes: action.propertyTypesArray
            });
        case actions.LOAD_PSQ_PROPERTY_TYPES_SUCCESS:
            return Object.assign({}, state, {
                psqPropertyTypes: action.psqPropertyTypesArray
            });
        /*This is an example on how we can load more than one state on one enum fetch from the 
        api we just pas the object with the types and its arrays assigned, see how we dispatch this for the reducer
        on the propertyActions
        NOTE: If more property types are fetched by the api here they will be assigned
        As an example the actual fetch for the properties enums returns this object
            {
                "PropertyTypes": [{"PropertyTypeID": 1, "PropertyTypeDescription": "Office"}...],

                "PSQPropertyTypes": [{"PSQPropertyTypeID": 1, "PSQPropertyTypeDescription": "Office"}...],
            }
        */ 
        case actions.LOAD_ALL_PROPERTY_TYPES_SUCCESS:
            return Object.assign({}, state, {
                propertyTypes: action.fetchedTypes.propertyTypes,
                psqPropertyTypes: action.fetchedTypes.psqPropertyTypes,
                propertyClasses: action.fetchedTypes.propertyClasses,
                propertyParkingTypes: action.fetchedTypes.propertyParkingTypes,
                tenantTypes: action.fetchedTypes.tenantTypes
            });
        case actions.LOAD_ALL_REIT_LOOKUPTYPES_SUCCESS:
            return Object.assign({}, state, {
                reitTypes: action.fetchedTypes.reitTypes,
                organizationTypes: action.fetchedTypes.organizationTypes,
                services: action.fetchedTypes.services,
                prePopulateAnswerOptions: action.fetchedTypes.prePopulateAnswerOptions
            });
        case actions.LOAD_ALL_CHARTOFACCOUNT_TYPES_SUCCESS:
            return Object.assign({}, state, {
                clientAccountCategories: action.fetchedTypes.clientAccountCategories,
                reitTestingAttributes: action.fetchedTypes.reitTestingAttributes
            });
        case actions.LOAD_ALL_CUSTOM_CHECKLIST_TEMPLATE_LOOKUPTYPES_SUCCESS:
            return Object.assign({}, state, {
                questionTypes: action.fetchedTypes.questionTypes
            });
        default:
            return state;
    }
}