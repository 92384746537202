import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the TB_UPLOAD_COMPLETE, TB_UPLOAD_PROGRESS, TB_UPLOAD_FAILED, and TB_UPLOAD_CLEAR actions and sets the upload progress in the store
 * @param {Object} [state] The upload progress currently in the store, or the initial state of the upload progress
 * @param {Object} action The action to be handled
 * @returns {Object} The upload progress
 */
export default function uploadProgress(state = initialState.uploadProgress, action) {
    const addOrUpdateItem = (item) => {
        let newState = Object.assign([], state);

        const idx = newState.findIndex(e => e.fileName === item.fileName);
        if (idx >= 0) {
            newState[idx] = item;
        } else {
            newState.push(item);
        }

        return newState;
    };

    let item = {};

    switch (action.type) {
        case actions.TB_UPLOAD_COMPLETE:
            item = {
                fileName: action.fileName,
                progress: 100,
                status: 'upload complete'
            };

            return addOrUpdateItem(item);
        case actions.TB_UPLOAD_PROGRESS:
            item = {
                fileName: action.fileName,
                progress: action.progress,
                status: 'uploading'
            };

            return addOrUpdateItem(item);
        case actions.TB_UPLOAD_FAILED:
            item = {
                fileName: action.fileName,
                progress: 0,
                status: 'failed'
            };

            return addOrUpdateItem(item);
        case actions.TB_UPLOAD_CLEAR:
            return initialState.uploadProgress;
        default:
            return state;
    }
}