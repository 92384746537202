
/**
 * Converts number to a fixed-point representation, removing trailing 0s,
 * unlike Number.toFixed()
 * @param {Number} number 
 */
export const toFixedVariable = function (number) {
    if (typeof number !== 'number') {
        return NaN.toFixed();
    }

    // Handle numbers less than 1, otherwise, number will be converted to string appropriately
    if (Math.abs(number) < 1.0) {
        var exponent = parseInt(number.toString().split('e-')[1]);
        if (exponent) {
            number *= Math.pow(10,exponent-1);
            number = '0.' + (new Array(exponent)).join('0') + number.toString().substring(2);
        }
    }

    return number;
}

/**
 * Converts number to a percentage representation.
 * @param {Number} number 
 */
export const toPercentage = function (number) {
    return `${toFixedVariable(number)}%`;
}