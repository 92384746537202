import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";

import EditSecuritiesSnapshotTable from "./EditSecuritiesSnapshotTable";
import EditSecuritiesSnapshotForm from "./EditSecuritiesSnapshotForm";
import { MotifModal, MotifButton } from '@ey-xd/motif-react';

const editSecuritiesSnapshotComponent = ({
    securitiesSnapshots,
    showForm,
    handleCloseForm,
    handleNewButtonClick,
    handleSubmit,
    formPurpose,
    selectedSecuritiesSnapshot,
    handleEditButtonClick,
    handleDeleteButtonClick,
    canEdit,
    handleDismissErrorMessage
}) => {
    const initialValues = {
        ownedSharesValue: '0.00',
        percentOfVote: '0.00'
    };

    return (
        <div className="edit-securitiesSnapshot-component">
            <div className="row">
                <div className="pl-0 pr-0 col-md-12 d-flex">
                    <div className="col-md-10">
                        <h2>Securities Snapshot {canEdit ? "" : "- Testing Closed"} </h2>
                    </div>
                    <div className="col-md-2 align-self-center">                        
                        <MotifButton variant="primary" className="right" type="button"
                            onClick={handleNewButtonClick}
                            disabled={!canEdit}>
                            New Snapshot
                        </MotifButton>
                    </div>
                </div>
            </div>
            <div>
                <EditSecuritiesSnapshotTable securitiesSnapshots={securitiesSnapshots} showForm={showForm} handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick} canEdit={canEdit} />
            </div>
            <MotifModal size="lg" variant="alt" show={showForm} area-label="Add or Edit Snapshot"
                onClose={handleCloseForm} data-testid="AddEditSnapshotModal">
                <EditSecuritiesSnapshotForm onSubmit={handleSubmit} handleCloseForm={handleCloseForm} initialValues={selectedSecuritiesSnapshot} formPurpose={formPurpose} selectedSecuritiesSnapshot={selectedSecuritiesSnapshot} handleDismissErrorMessage={handleDismissErrorMessage} />
            </MotifModal>
        </div>
    );
};

editSecuritiesSnapshotComponent.propTypes = {
    securitiesSnapshots: PropTypes.arrayOf(PropTypes.shape({
        allSharesFromIssuerValue: PropTypes.number,
        class: PropTypes.string,
        securitiesSnapshotID: PropTypes.number,
        issuer: PropTypes.string,
        securityName: PropTypes.string,
        reitOwnedSharesValue: PropTypes.number,
        percentOfVote: PropTypes.number,
        reportPeriodID: PropTypes.number,
    })),
    showForm: PropTypes.bool,
    handleCloseForm: PropTypes.func.isRequired,
    handleNewButtonClick: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formPurpose: PropTypes.string,
    selectedSecuritiesSnapshot: PropTypes.object,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleDeleteButtonClick: PropTypes.func.isRequired,
    canEdit: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func
};

export default editSecuritiesSnapshotComponent;