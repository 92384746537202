/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getFormValues,
  reduxForm,
  getFormSyncErrors,
  getFormSubmitErrors,
  reset,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import { MotifOption } from '@ey-xd/motif-react';
import AvailableQuestions from '../../components/customChecklistQuestions/AvailableQuestions';
import PsqCustomChecklistTemplateHeader from '../../components/customChecklistQuestions/PsqCustomChecklistTemplateHeader';
import PsqCustomChecklistTemplate from '../../components/customChecklistQuestions/PsqCustomChecklistTemplate';
import PsqCustomChecklistTemplateFooter from '../../components/customChecklistQuestions/PsqCustomChecklistTemplateFooter';
import * as customChecklistTemplateActions from '../../actions/customChecklistTemplateActions';
import * as customQuestionActions from '../../actions/customQuestionActions';
import AddEditCustomQuestionModal from '../../components/customChecklistQuestions/AddEditCustomQuestionModal';
import AddSectionModal from '../../components/customChecklistQuestions/AddSectionModal';
import { store } from '../../store/configureStore';
import DeleteTemplateQuestionModal from '../../components/customChecklistQuestions/DeleteTemplateQuestionModal';
import TemplateConfirmEditQuestionModal from '../../components/customChecklistQuestions/TemplateConfirmEditQuestionModal';
import DeleteLastSectionWarningModal from '../../components/customChecklistQuestions/DeleteLastSectionWarningModal';
import { isUserActiveOfEditedTemplate } from "../../common/SignalRFunction";
import * as fileActions from "../../actions/fileActions";
import CustomQuestionAttachmentListUploadStatus from '../../components/customChecklistQuestions/CustomQuestionAttachmentListUploadStatus';
import QuestionLockedInfoModal from '../../components/customChecklistQuestions/QuestionLockedInfoModal';
import StandardChildQuestionsRearrangeWarningModal from '../../components/customChecklistQuestions/StandardChildQuestionsRearrangeWarningModal';
import { withRouter } from "../../common/withRouter";

/**
 * PsqCustomChecklistTemplate container component
 * @extends {React.Component}
 */
export class PsqCustomChecklistTemplateContainer extends React.Component {
  /**
   * Creates a new PsqChecklistPage
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */

  constructor(props, context) {
    super(props, context);

    this.state = {
      isEditMode: this.props.router?.params.templateId ? true : false,
      templateId: this.props.router?.params.templateId
        ? props.router.params.templateId
        : 0,
      templateName: '',
      selectedExistingTemplateId: 0,
      showStandardQuestions: true,
      searchQuestionText: '',
      selectedAvailableQuestionSearchBy: '2',
      selectedQuestions: this.questions,
      templateSections: [],
      checklistTypeID: 2,
      availableQuestionDetail: [],
      enableNavigation: false,
      isTemplateDirty: false,
      showAddEditCustomQuestionModal: false,
      questionFormPurpose: 'UNKNOWN',
      isChildQuestion: false,
      currentQuestionPicklistItems: [],
      parentQuestionPicklistItems: [],
      parentQuestionID: null,
      parentQuestionTypeID: null,
      showAddSectionModal: false,
      questionToDelete: null,
      questionModalInitialValues: {},
      isQuestionFormDirty: false,
      sectionDetail: {},
      expandAllAccordionsOnTemplate: false,
      expandAllAccordionsOnAvailableQuestions: false,
      allAvailableQuestions: [],
      selectedSectionId: 0,
      selectedSectionName: '',
      isEditLayoutMode: false,
      isNewQuestionForSectionEnabled: false,
      hasTemplateNameChanged: false,
      showDeleteTemplateQuestionModal: false,
      showEditTemplateQuestionModal: false,
      templateQuestionToBeDeletedHasChildQuestions: false,
      templateQuestionToBeDeleted: {},
      templateQuestionToBeEdited: {},
      isTemplateEditedByOtherUser: false,
      isTemplateEditedUserActive: false,
      showDeleteLastSectionWarningModal: false,
      fileAttachmentList: [],
      attachmentData: [],
      uploadedAttachmentStatus: [],
      showQuestionLockedInfoModal: false,
      headerMessageForQuestionLockedInfoModal: '',
      contentForQuestionLockedInfoModal: '',
      showStandardChildQuestionsRearrangeWarningModal: false,
      tempTemplateStateOnChildQuestionRearrange: {},
      tempisTemplateDirtyStateOnChildQuestionRearrange: false,
      startIntentLockTimer: false,
      intentLockExpirationTime: null,
      currentQuestionIdinWorks: null,
      parentQuestionOfTheChildQuestionsRearranged: null,
      isSaving: false,
    };

    console.log('params', props.router.params)
    console.log('templateId initial state: ', this.state.templateId)

    this.fetchTemplateDataById = this.fetchTemplateDataById.bind(this);
    this.isTemplateUserActive = this.isTemplateUserActive.bind(this);
    this.executeIsUserActiveOfEditedTemplate = this.executeIsUserActiveOfEditedTemplate.bind(this);
    this.handleShowStandardQuestions =
      this.handleShowStandardQuestions.bind(this);
    this.handleSearchQuestion = this.handleSearchQuestion.bind(this);
    this.createAvailableQuestionsSearchBy =
      this.createAvailableQuestionsSearchBy.bind(this);
    this.handleAvailableQuestionsSearchBy =
      this.handleAvailableQuestionsSearchBy.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleTemplateSave = this.handleTemplateSave.bind(this);
    this.handleAddSection = this.handleAddSection.bind(this);
    this.handleTemplateReset = this.handleTemplateReset.bind(this);
    this.toggleShowAddEditCustomQuestionModal =
      this.toggleShowAddEditCustomQuestionModal.bind(this);
    this.handleOnClickCreateNewQuestion =
      this.handleOnClickCreateNewQuestion.bind(this);
    this.handleSubmitCustomQuestionModalAction =
      this.handleSubmitCustomQuestionModalAction.bind(this);
    this.handleAddNewChildQuestion = this.handleAddNewChildQuestion.bind(this);
    this.handleToggleAddSectionModal =
      this.handleToggleAddSectionModal.bind(this);
    this.handleChangePicklistItem = this.handleChangePicklistItem.bind(this);

    this.handleAvailableQuestionDragStart =
      this.handleAvailableQuestionDragStart.bind(this);
    this.handleAvailableQuestionDragOver =
      this.handleAvailableQuestionDragOver.bind(this);
    this.handleQuestionDropComponent =
      this.handleQuestionDropComponent.bind(this);
    this.handleQuestionDropPanel = this.handleQuestionDropPanel.bind(this);

    this.handleSectionQuestionDragStart =
      this.handleSectionQuestionDragStart.bind(this);
    this.handleSectionQuestionDragOver =
      this.handleSectionQuestionDragOver.bind(this);

    this.handleSectionDragStart = this.handleSectionDragStart.bind(this);
    this.handleSectionDragOver = this.handleSectionDragOver.bind(this);

    this.handleSectionChildQuestionDragStart =
      this.handleSectionChildQuestionDragStart.bind(this);
    this.handleSectionCommentDragStart =
      this.handleSectionCommentDragStart.bind(this);

    this.handleQuestionDropComponentForSectionCategory =
      this.handleQuestionDropComponentForSectionCategory.bind(this);
    this.handleQuestionDropComponentForSectionQuestionCategory =
      this.handleQuestionDropComponentForSectionQuestionCategory.bind(this);
    this.handleQuestionDropComponentForAvailableQuestionCategory =
      this.handleQuestionDropComponentForAvailableQuestionCategory.bind(this);
    this.handleQuestionDropComponentForSectionChildQuestionCategory =
      this.handleQuestionDropComponentForSectionChildQuestionCategory.bind(
        this
      );
    this.handleQuestionDropPanelForSectionQuestionCategory =
      this.handleQuestionDropPanelForSectionQuestionCategory.bind(this);
    this.handleQuestionDropPanelForAvailableQuestionCategory =
      this.handleQuestionDropPanelForAvailableQuestionCategory.bind(this);
    this.handleQuestionDropComponentForSectionCommentCategory =
      this.handleQuestionDropComponentForSectionCommentCategory.bind(this);
    this.handleToggleExpandCollapseAllAcordionsOnTemplate =
      this.handleToggleExpandCollapseAllAcordionsOnTemplate.bind(this);
    this.handleOpenAccordionOnTemplate =
      this.handleOpenAccordionOnTemplate.bind(this);
    this.handleCloseAccordionOnTemplate =
      this.handleCloseAccordionOnTemplate.bind(this);
    this.handleToggleExpandCollapseAllAcordionsOnAvailableQuestions =
      this.handleToggleExpandCollapseAllAcordionsOnAvailableQuestions.bind(
        this
      );
    this.handleOpenAccordionOnAvailableQuestions =
      this.handleOpenAccordionOnAvailableQuestions.bind(this);
    this.handleCloseAccordionOnAvailableQuestions =
      this.handleCloseAccordionOnAvailableQuestions.bind(this);
    this.reRenderTemplateSectionOnAddNewChildQuestion =
      this.reRenderTemplateSectionOnAddNewChildQuestion.bind(this);
    this.reRenderTemplateSectionOnEditParentQuestion =
      this.reRenderTemplateSectionOnEditParentQuestion.bind(this);
    this.reRenderTemplateSectionOnEditChildQuestion =
      this.reRenderTemplateSectionOnEditChildQuestion.bind(this);
    this.reRenderTemplateSectionOnAddNewParentQuestion =
      this.reRenderTemplateSectionOnAddNewParentQuestion.bind(this);
    this.reRenderAvailableQuestionsOnAddNewParentQuestion =
      this.reRenderAvailableQuestionsOnAddNewParentQuestion.bind(this);
    this.reRenderAvailableQuestionsOnAddNewChilQuestion =
      this.reRenderAvailableQuestionsOnAddNewChilQuestion.bind(this);
    this.reRenderAvailableQuestionsOnEditParentQuestion =
      this.reRenderAvailableQuestionsOnEditParentQuestion.bind(this);
    this.reRenderAvailableQuestionsOnEditChilQuestion =
      this.reRenderAvailableQuestionsOnEditChilQuestion.bind(this);
    this.getQuestionById = this.getQuestionById.bind(this);
    this.commitCustomPsqTemplateChanges =
      this.commitCustomPsqTemplateChanges.bind(this);
    this.abandonCustomPsqTemplateChanges =
      this.abandonCustomPsqTemplateChanges.bind(this);
    this.handleOnChangeOfTemplateName =
      this.handleOnChangeOfTemplateName.bind(this);
    this.handleCancelDeleteTemplateQuestion =
      this.handleCancelDeleteTemplateQuestion.bind(this);
    this.handleDeleteTemplateQuestion =
      this.handleDeleteTemplateQuestion.bind(this);
    this.handleCancelEditTemplateQuestion =
      this.handleCancelEditTemplateQuestion.bind(this);
    this.handleEditTemplateQuestion =
      this.handleEditTemplateQuestion.bind(this);
    this.getPreventDropFlagOnDropEvent = this.getPreventDropFlagOnDropEvent.bind(this);
    this.preventDragAndDrop = this.preventDragAndDrop.bind(this);
    this.toggleShowDeleteLastSectionWarningModal = this.toggleShowDeleteLastSectionWarningModal.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDropRejected = this.handleDropRejected.bind(this);
    this.handleDeleteExistingFile = this.handleDeleteExistingFile.bind(this);
    this.handleDownloadAttachmentClick = this.handleDownloadAttachmentClick.bind(this);
    this.toggleShowAttachmentStatus = this.toggleShowAttachmentStatus.bind(this);
    this.handleCloseOnQuestionInfoModal = this.handleCloseOnQuestionInfoModal.bind(this);
    this.toggleShowAddEditCustomQuestionModalAndReleaseIntentLock = this.toggleShowAddEditCustomQuestionModalAndReleaseIntentLock.bind(this);
    this.handleContinueChildQuestionsRearrangeWarningModal = this.handleContinueChildQuestionsRearrangeWarningModal.bind(this);
    this.handleCancelStandardChildQuestionsRearrangeWarningModal = this.handleCancelStandardChildQuestionsRearrangeWarningModal.bind(this);
    this.handleClearSearchText = this.handleClearSearchText.bind(this);
  }

  componentDidMount() {
    this.props.customChecklistTemplateActions
      .fetchPSQTemplates(this.props.clientId)
      .then(() => { });

    this.props.customChecklistTemplateActions.fetchCustomChecklistTemplateLookupTypes();

    if (this.state.isEditMode) {
      this.fetchTemplateDataById(this.state.templateId);
      this.isTemplateUserActive(this.state.templateId);
    }
  }

  componentDidUpdate(prevProps) {
    const templateId = this.props.router?.params.templateId
      ? this.props.router.params.templateId
      : 0;
    if (templateId !== this.state.templateId) {
      this.setState({ templateId: templateId });
      this.setState({ isEditMode: true });
    }
    if (this.props.templateSections != prevProps.templateSections) {
      this.setState({
        templateSections: this.props.templateSections,

      });
    }
    
    if (
      this.props.psqCustomChecklistTemplateValues.templateName !=
      this.state.templateName
    ) {
      this.setState({
        templateName: this.props.psqCustomChecklistTemplateValues.templateName,
      });
    }
  }

  componentWillUnmount() {
    this.props.customChecklistTemplateActions.fetchPSQTemplates();
    this.props.customChecklistTemplateActions.fetchCustomPsqTemplateById();
  }

  fetchTemplateDataById(templateId) {
    this.props.customChecklistTemplateActions
      .fetchCustomPsqTemplateById(this.props.clientId, templateId)
      .then(() => {
        this.setState({
          isTemplateDirty: false,
          templateName:
            this.props.psqCustomChecklistTemplateValues.templateName,
          selectedSectionId: 0,
          selectedSectionName: '',
          isNewQuestionForSectionEnabled: false,
        });

        this.getAvailableSection();
      });
  }

  // Check whether the user is active or not
  executeIsUserActiveOfEditedTemplate(clientId, checklistTemplateId) {
    return isUserActiveOfEditedTemplate(clientId, checklistTemplateId).then((response) => {
      return response;
    });
  }

  // Get user details who has edited the template and also check that user is still active
  isTemplateUserActive(checklistTemplateId) {
    // Check whether the user who edited the template is active
    let currentUser = this.props.authentication.currentUser
      ? this.props.authentication.currentUser.toLowerCase()
      : '';
    if (currentUser) {
      // Call SignalR function to check the user is active or not
      this.executeIsUserActiveOfEditedTemplate(this.props.clientId, checklistTemplateId).then((response) => {
        if (response) {
          let beingEditedBy = response.beingEditedBy ? response.beingEditedBy : '';
          let isTemplateEditedByOtherUser = currentUser != beingEditedBy.toLowerCase();
          // Redirect user to Custom PSQ Dasboard when the other user who is edited the template is currently active
          if (isTemplateEditedByOtherUser && response.isUserActive) {
            this.props.router.navigate(
              `/client/${this.props.clientId}/customPsqTemplate?showValidationMessage=true&beingEditedBy=${beingEditedBy}`
            );
          }
          else {
            // Set state variables - checklist template is edited by someone other user than current user, and that other user is still active
            this.setState({ isTemplateEditedByOtherUser: isTemplateEditedByOtherUser, isTemplateEditedUserActive: response.isUserActive });
          }
        }
      });
    }
  }

  createCustomPsqTemplate(formValues) {
    const request = {
      clientId: this.props.clientId,
      templateName: formValues.templateName,
      copiedFromTemplateId:
        (formValues.existingTemplate !== '' && formValues.templateInitializationType === 'existingTemplate') ? formValues.existingTemplate : 0,
    };

    this.props.customChecklistTemplateActions
      .createCustomPsqTemplate(request)
      .then(() => {
        store.dispatch(reset('psqCustomChecklistTemplate'));
        this.props.router.navigate(
          `/client/${this.props.clientId}/customPsqTemplate`
        );
      });
  }

  saveEditedCustomPsqTemplate() {
    const formValues = this.props.psqCustomChecklistTemplateValues;

    // Save/update action
    const sectionsRequestData = this.state.templateSections.map((section) => {
      let data = [];
      if (section.questions.length <= 0) {
        const dataObj = {
          checklistTemplateSectionID: section.isNewSection
            ? null
            : section.sectionID,
          sectionName: section.sectionName,
          sectionSortOrder: section.sectionSortOrder,
          checklistTemplateSectionQuestionID: null,
          questionID: null,
          questionSortOrder: null,
        };
        data.push(dataObj);
      } else {
        section.questions.forEach((element) => {
          const dataObj = {
            checklistTemplateSectionID: section.isNewSection
              ? null
              : section.sectionID,
            sectionName: section.sectionName,
            sectionSortOrder: section.sectionSortOrder,
            checklistTemplateSectionQuestionID:
              element.checklistTemplateSectionQuestionID,
            questionID: element.questionID,
            questionSortOrder: element.questionSortOrder,
          };
          data.push(dataObj);

          // Pushing the sort order changes only with respect to parent questions

          // For child sort order changes, refer to () function -- TODO
        });
      }
      return [...data];
    });

    const request = {
      clientId: this.props.clientId,
      templateId: this.state.templateId,
      templateName: formValues.templateName,
      checklistTemplateDetailTableType: sectionsRequestData.flat(),
    };

    this.props.customChecklistTemplateActions
      .upsertCustomPsqTemplate(request)
      .then(() => {
        // Once successful, reinitializw the page with updated data
        store.dispatch(reset('psqCustomChecklistTemplate'));
        this.fetchTemplateDataById(this.state.templateId);
      });
  }

  updateChildQuestionSortOrder() {
    const parentQuestion = this.state.tempTemplateStateOnChildQuestionRearrange.filter(s => s.sectionID === this.state.parentQuestionOfTheChildQuestionsRearranged.sectionID)[0].questions.filter(q => q.questionID === this.state.parentQuestionOfTheChildQuestionsRearranged.questionID)[0];
    let childQuestionsRequestData = [];
    parentQuestion.childQuestions.forEach((element) => {
      const dataObj =
      {
        questionID: element.questionID,
        questionSortOrder: element.questionSortOrder,
      };
      childQuestionsRequestData.push(dataObj);
    });

    const request = {
      clientId: this.props.clientId,
      templateId: this.state.templateId,
      parentQuestionId: this.state.parentQuestionOfTheChildQuestionsRearranged.questionID,
      checklistTypeId: this.state.checklistTypeID,
      isStandardQuestion: this.state.parentQuestionOfTheChildQuestionsRearranged.isLocked,
      checklistTemplateDetailTableType: [...childQuestionsRequestData].flat(),
    };

    this.props.customQuestionActions
      .updateChildQuestionSortOrder(request)
      .then(() => {
        // Once successful, reinitialize the page with updated data
        this.setState({
          templateSections: this.state.tempTemplateStateOnChildQuestionRearrange,
          isTemplateDirty: this.state.tempisTemplateDirtyStateOnChildQuestionRearrange,
          parentQuestionOfTheChildQuestionsRearranged: null,
          tempTemplateStateOnChildQuestionRearrange: {},
          tempisTemplateDirtyStateOnChildQuestionRearrange: false,
          showStandardChildQuestionsRearrangeWarningModal: false
        }, this.reloadTemplate);
      })
      .catch(() => {
        this.resetTempStatePostChildQuestionsRearrange();
      });
  }

  resetTempStatePostChildQuestionsRearrange() {
    this.setState({
      parentQuestionOfTheChildQuestionsRearranged: null,
      tempTemplateStateOnChildQuestionRearrange: {},
      tempisTemplateDirtyStateOnChildQuestionRearrange: false,
      showStandardChildQuestionsRearrangeWarningModal: false
    });
  }

  reloadTemplate() {
    store.dispatch(reset('psqCustomChecklistTemplate'));
    this.fetchTemplateDataById(this.state.templateId);
  }

  commitCustomPsqTemplateChanges(formValues) {
    const requestObj = {
      clientId: parseInt(this.props.clientId),
      checklistTemplateId: parseInt(this.state.templateId),
      checklistTemplateName: formValues.templateName,
    };

    this.props.customChecklistTemplateActions
      .commitCustomTemplateTemplateChanges(requestObj)
      .then(() => {
        this.props.router.navigate(
          `/client/${this.props.clientId}/customPsqTemplate`
        );
      });
  }

  abandonCustomPsqTemplateChanges() {
    const requestObj = {
      clientId: parseInt(this.props.clientId),
      checklistTemplateId: parseInt(this.state.templateId),
    };

    this.props.customChecklistTemplateActions
      .abandonCustomTemplateTemplateChanges(requestObj)
      .then(() => {
        this.props.router.navigate(
          `/client/${this.props.clientId}/customPsqTemplate`
        );
      });
  }

  createAvailableQuestionsSearchBy() {
    let items = [];
    const searchBy = [
      {
        id: 1,
        name: 'Question Tag',
      },
      {
        id: 2,
        name: 'Question Text',
      },
      {
        id: 3,
        name: 'Question Type',
      },
    ];

    searchBy.forEach((x) => {
      items.push(
        <MotifOption key={x.id} value={String(x.id)}>
          {String(x.name).replace(/\s+/g, '\u2800')}
        </MotifOption>
      );
    });

    return items;
  }

  getAvailableSection() {
    this.props.customChecklistTemplateActions
      .fetchAvailableQuestion(
        this.props.clientId,
        this.state.checklistTypeID,
        this.state.templateId
      )
      .then((availableQuestions) => {
        let questions = this.filterSearchQuestions(
          this.state.selectedAvailableQuestionSearchBy,
          this.state.searchQuestionText,
          availableQuestions
        );
        questions = this.filterShowStandardQuestion(
          this.state.showStandardQuestions,
          questions
        );

        this.setState({
          availableQuestionDetail: questions,
          allAvailableQuestions: availableQuestions,
        });
      });
  }

  //  Get Prevent Drop flag on Drop Event
  getPreventDropFlagOnDropEvent(category, questionId) {
    let shouldPreventDrop = false;
    if (category == 'section') {
      // Commenting the below code as every section can be rearranged
      // shouldPreventDrop = this.preventDragAndDrop(questionId);
    }
    else if (category == 'sectionquestion') {
      // Get the section id based on question id
      let sections = this.state.templateSections;
      if (sections && sections.length > 0) {
        for (var sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
          let sectionItem = sections[sectionIndex];
          if (sectionItem.questions && sectionItem.questions.length > 0) {
            // Get the dropped section details
            let matchedSection = sectionItem.questions.find(si => si.questionID == questionId);
            if (matchedSection) {
              let matchedSectionId = matchedSection.sectionID;
              shouldPreventDrop = this.preventDragAndDrop(matchedSectionId);
              break;
            }
          }
        }
      }
    }
    else if (category == 'sectionchildquestion') {
      // Get section id based on Child Question Id
      let sections = this.state.templateSections;
      if (sections && sections.length > 0) {
        for (var secIndex = 0; secIndex < sections.length; secIndex++) {
          let sectionItem = sections[secIndex];
          if (sectionItem.questions && sectionItem.questions.length > 0) {
            for (var questionIndex = 0; questionIndex < sectionItem.questions.length; questionIndex++) {
              let questionItem = sectionItem.questions[questionIndex];
              if (questionItem.childQuestions && questionItem.childQuestions.length > 0) {
                // Get the dropped section details
                let matchedSection = questionItem.childQuestions.find(qi => qi.questionID == questionId);
                if (matchedSection) {
                  let matchedSectionId = sectionItem.sectionID;
                  shouldPreventDrop = this.preventDragAndDrop(matchedSectionId);
                  break;
                }
              }
            }
          }
        }
      }
    }
    else if (category == 'sectioncomment') {
      shouldPreventDrop = this.preventDragAndDrop(questionId);
    }
    return shouldPreventDrop;
  }

  // Checks whether to prevent Drag and Drop for given Section Id
  preventDragAndDrop(sectionId) {
    //  Prevent Drag or Drop if Section containing Question of type "psq section 2 additional services" or "psq section 2 property manager" found
    const preventQuestionTypeList = ['psq section 2 property manager', 'psq section 2 additional services'];
    let shouldPreventDragAndDrop = false;
    let sections = this.state.templateSections;
    if (sectionId > 0 && sections && sections.length > 0) {
      // Get section details
      let matchedSection = sections.find(s => s.sectionID === sectionId);
      if (matchedSection && matchedSection.questions && matchedSection.questions.length > 0 &&
        matchedSection.questions.some(si => preventQuestionTypeList.includes(si.questionTypeDescription.toLowerCase()))) {
        shouldPreventDragAndDrop = true;
      }
    }
    return shouldPreventDragAndDrop;
  }

  // Handle onDragStart event for Section
  handleSectionDragStart(ev, sectionId) {
    ev.dataTransfer.setData('sectionId', sectionId);

    if (this.state.isTemplateEditedByOtherUser) {
      ev.preventDefault();
      return;
    }
  }

  // Handle onDragStart event for Section Child Question
  handleSectionChildQuestionDragStart(ev, questionId) {
    if (this.state.isTemplateEditedByOtherUser) {
      ev.preventDefault();
      return;
    }
    ev.dataTransfer.setData('childQuestionId', questionId);
  }

  // Handle onDragStart event for Section Child Question
  handleSectionCommentDragStart(ev, sectionId) {
    ev.dataTransfer.setData('sectionReviewCommentId', sectionId);
    ev.preventDefault();
  }

  // Handle onDragOver event for Section
  handleSectionDragOver(ev) {
    ev.preventDefault();
  }

  // Handle onDragStart event for Section Question
  handleSectionQuestionDragStart(ev, id) {
    if (this.state.isTemplateEditedByOtherUser) {
      ev.preventDefault();
      return;
    }
    ev.dataTransfer.setData('id', id);
  }

  // Handle onDragOver event for Section Question
  handleSectionQuestionDragOver(ev) {
    ev.preventDefault();
  }

  // Handle onDragStart event for Available Question
  handleAvailableQuestionDragStart(ev, id) {
    if (this.state.isTemplateEditedByOtherUser) {
      ev.preventDefault();
      return;
    }
    ev.dataTransfer.setData('id', id);
  }

  // Handle onDragOver event for Available Question
  handleAvailableQuestionDragOver(ev) {
    ev.preventDefault();
  }

  // Handle onDrop event for Section category
  handleQuestionDropComponentForSectionCategory(
    questionId,
    draggedId,
    draggedSectionId,
    draggedChildQuestionId,
    sectionQuestions
  ) {
    if (!this.preventDrop) {
      let sQuestions = sectionQuestions;

      // Prevent dropping child question on Section
      if (draggedChildQuestionId) {
        this.preventDrop = true;
      }
      // Drop available question category on Section
      else if (!draggedSectionId || draggedSectionId <= 0) {
        // Get index based on section id
        let toIndex = sQuestions.findIndex((q) => q.sectionID == questionId);
        let sectionQuestionsCount = sQuestions.filter(
          (q) => q.sectionID == questionId
        ).length;
        if (toIndex >= 0) {
          let aQuestions = this.state.availableQuestionDetail;
          let draggedQuestion = {
            ...aQuestions.find((q) => q.questionID == draggedId),
          };
          if (draggedQuestion) {
            draggedQuestion.category = 'sectionquestion';
            draggedQuestion.sectionID = sQuestions[toIndex].sectionID;
            draggedQuestion.sectionName = sQuestions[toIndex].sectionName;
            draggedQuestion.sectionIsLocked =
              sQuestions[toIndex].sectionIsLocked;
            draggedQuestion.isNewSection = sQuestions[toIndex].isNewSection;
            draggedQuestion.sectionHighlightClass =
              sQuestions[toIndex].sectionHighlightClass;

            // Check to not allow drop if the source section contains a restricted question
            if (this.preventDragAndDrop(draggedQuestion.sectionID)) {
              this.preventDrop = true;
              return;
            }

            // Move available question to section at end
            sQuestions.splice(
              toIndex + sectionQuestionsCount,
              0,
              draggedQuestion
            );

            // Check target section is having empty question
            if (sQuestions[toIndex].isEmptySection) {
              // Remove emtpy question from Section as new question is dropped on Section
              sQuestions.splice(toIndex, 1);
            }
          }

          let uniqueSections = [
            ...new Set(sQuestions.map((item) => item.sectionID)),
          ];

          let updatedSectionQuestions = [];
          uniqueSections &&
            uniqueSections.length > 0 &&
            uniqueSections.forEach((s) => {
              let sectionWiseQuestions = sQuestions.filter(
                (sq) => sq.sectionID == s
              );
              if (
                sectionWiseQuestions &&
                sectionWiseQuestions.some((sq) => sq.isEmptySection)
              ) {
                //Handle Section is having empty question
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [],
                });
              } else {
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [...sectionWiseQuestions],
                });
              }
            });

          // Remove Question from other Category
          aQuestions = aQuestions.filter((q) => q.questionID != draggedId);

          this.preventDrop = true;

          let sectionOrder = 0;

          const sortedSections = updatedSectionQuestions.map((section) => {
            let questions = [];
            let questionOrder = 0;
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                let childQuestions = [];
                let childQuestionOrder = 0;
                if (question.childQuestions.length > 0) {
                  childQuestions = question.childQuestions.map(
                    (childQuestion) => {
                      return {
                        ...childQuestion,
                        questionSortOrder: ++childQuestionOrder,
                      };
                    }
                  );
                }

                return {
                  ...question,
                  questionSortOrder: ++questionOrder,
                  childQuestions: childQuestions,
                };
              });
            }

            return {
              ...section,
              sectionSortOrder: ++sectionOrder,
              questions: questions,
            };
          });

          const isTemplateDirty =
            JSON.stringify(sortedSections) !==
            JSON.stringify(this.props.templateSections);

          this.setState(
            {
              templateSections: [...sortedSections],
              availableQuestionDetail: [...aQuestions],
              isTemplateDirty: isTemplateDirty,
            },
            this.saveEditedCustomPsqTemplate
          );

          let updatedQuestions = this.state.allAvailableQuestions.slice();
          const index = updatedQuestions.findIndex(
            (q) => q.questionID === draggedQuestion.questionID
          );
          updatedQuestions.splice(index, 1);

          updatedQuestions.sort(
            (a, b) => parseInt(a.questionID) - parseInt(b.questionID)
          );

          this.setState({ allAvailableQuestions: updatedQuestions });
        } else {
          this.preventDrop = true;
        }
      }
      // Drop question from section category on Section
      else if (draggedId && sQuestions.some((q) => q.questionID == draggedId)) {
        let fromIndex = sQuestions.findIndex((q) => q.questionID == draggedId);

        // Get index based on section id
        let toIndex = sQuestions.findIndex((q) => q.sectionID == questionId);
        let sectionQuestionsCount = sQuestions.filter(
          (q) => q.sectionID == questionId
        ).length;

        if (fromIndex >= 0 && toIndex >= 0) {
          //Dropping question from one Section to another section

          let fromSectionId = sQuestions[fromIndex].sectionID;
          let toSectionId = sQuestions[toIndex].sectionID;
          let emptyFromSectionIndex = -1;
          let emptyFromSection = {};
          let updatedSQuestions = [];

          // Check to not allow drop if either the source or the target section contains a restricted question
          if (this.preventDragAndDrop(fromSectionId) || this.preventDragAndDrop(toSectionId)) {
            this.preventDrop = true;
            return;
          }

          if (
            fromSectionId > 0 &&
            toSectionId > 0 &&
            fromSectionId != toSectionId
          ) {
            //If section (of Dragged question) having single question
            if (
              sQuestions.filter((sq) => sq.sectionID == fromSectionId).length ==
              1
            ) {
              //Get unique sections
              let uniqueDroppableSections = [
                ...new Set(sQuestions.map((item) => item.sectionID)),
              ];
              emptyFromSectionIndex = uniqueDroppableSections.findIndex(
                (s) => s == fromSectionId
              );
              if (
                this.state.templateSections &&
                this.state.templateSections[emptyFromSectionIndex]
              ) {
                emptyFromSection = {
                  ...this.state.templateSections[emptyFromSectionIndex],
                };
              }
            }
          }

          const toIndexSQuestion = sQuestions.filter(
            (_, index) => index === toIndex
          )[0];

          updatedSQuestions = sQuestions.map((sQuestion, index) => {
            if (index === fromIndex) {
              return {
                ...sQuestion,
                sectionID: toIndexSQuestion.sectionID,
                sectionName: toIndexSQuestion.sectionName,
                sectionIsLocked: toIndexSQuestion.sectionIsLocked,
                isNewSection: toIndexSQuestion.isNewSection,
                sectionHighlightClass: toIndexSQuestion.sectionHighlightClass,
              };
            } else {
              return sQuestion;
            }
          });

          updatedSQuestions.splice(
            toIndex < fromIndex
              ? toIndex + sectionQuestionsCount
              : toIndex + sectionQuestionsCount - 1,
            0,
            updatedSQuestions.splice(fromIndex, 1)[0]
          );

          // Check target section is having empty question
          let emptyToSectionIndex = updatedSQuestions.findIndex(
            (sq) => sq.sectionID == toSectionId && sq.isEmptySection
          );
          if (emptyToSectionIndex >= 0) {
            // Remove emtpy question from Section as new question is dropped on Section
            updatedSQuestions.splice(emptyToSectionIndex, 1);
          }

          let uniqueSections = [
            ...new Set(updatedSQuestions.map((item) => item.sectionID)),
          ];
          let updatedSectionQuestions = [];

          uniqueSections &&
            uniqueSections.length > 0 &&
            uniqueSections.forEach((s) => {
              let sectionWiseQuestions = updatedSQuestions.filter(
                (sq) => sq.sectionID == s
              );
              if (
                sectionWiseQuestions &&
                sectionWiseQuestions.some((sq) => sq.isEmptySection)
              ) {
                //Handle Section is having empty question
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [],
                });
              } else {
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [...sectionWiseQuestions],
                });
              }
            });

          if (emptyFromSectionIndex >= 0) {
            emptyFromSection.questions = [];
            updatedSectionQuestions.splice(
              emptyFromSectionIndex,
              0,
              emptyFromSection
            );
          }

          this.preventDrop = true;

          let sectionOrder = 0;

          const sortedSections = updatedSectionQuestions.map((section) => {
            let questions = [];
            let questionOrder = 0;
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                let childQuestions = [];
                let childQuestionOrder = 0;
                if (question.childQuestions.length > 0) {
                  childQuestions = question.childQuestions.map(
                    (childQuestion) => {
                      return {
                        ...childQuestion,
                        questionSortOrder: ++childQuestionOrder,
                      };
                    }
                  );
                }

                return {
                  ...question,
                  questionSortOrder: ++questionOrder,
                  childQuestions: childQuestions,
                };
              });
            }

            return {
              ...section,
              sectionSortOrder: ++sectionOrder,
              questions: questions,
            };
          });

          const isTemplateDirty =
            JSON.stringify(sortedSections) !==
            JSON.stringify(this.props.templateSections);

          this.setState(
            {
              templateSections: [...sortedSections],
              isTemplateDirty: isTemplateDirty,
            },
            this.saveEditedCustomPsqTemplate
          );
        }
      } else {
        // Drop section on section
        let sections = this.state.templateSections.slice();
        let toIndex = sections.findIndex((q) => q.sectionID == questionId);
        let fromIndex = sections.findIndex(
          (q) => q.sectionID == draggedSectionId
        );

        if (fromIndex == toIndex) {
          this.preventDrop = true;
        } else if (fromIndex >= 0 && toIndex >= 0 && fromIndex != toIndex) {
          sections.splice(toIndex, 0, sections.splice(fromIndex, 1)[0]);

          this.preventDrop = true;

          let sectionOrder = 0;

          const sortedSections = sections.map((section) => {
            let questions = [];
            let questionOrder = 0;
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                let childQuestions = [];
                let childQuestionOrder = 0;
                if (question.childQuestions.length > 0) {
                  childQuestions = question.childQuestions.map(
                    (childQuestion) => {
                      return {
                        ...childQuestion,
                        questionSortOrder: ++childQuestionOrder,
                      };
                    }
                  );
                }

                return {
                  ...question,
                  questionSortOrder: ++questionOrder,
                  childQuestions: childQuestions,
                };
              });
            }

            return {
              ...section,
              sectionSortOrder: ++sectionOrder,
              questions: questions,
            };
          });

          const isTemplateDirty =
            JSON.stringify(sortedSections) !==
            JSON.stringify(this.props.templateSections);

          this.setState(
            {
              templateSections: sortedSections,
              isTemplateDirty: isTemplateDirty,
            },
            this.saveEditedCustomPsqTemplate
          );
        }
      }
    }
  }

  // Handle onDrop event for Section Question category
  handleQuestionDropComponentForSectionQuestionCategory(
    questionId,
    draggedId,
    draggedSectionId,
    draggedChildQuestionId,
    sectionQuestions
  ) {
    if (!this.preventDrop) {
      //let sQuestions = this.state.sectionQuestions;
      let sQuestions = sectionQuestions;
      let fromIndex = sQuestions.findIndex((q) => q.questionID == draggedId);
      let toIndex = sQuestions.findIndex((q) => q.questionID == questionId);

      // Prevent dropping of section on Question
      if (
        draggedSectionId &&
        draggedSectionId > 0 &&
        !draggedId &&
        !draggedChildQuestionId
      ) {
        this.preventDrop = true;
      }
      // Prevent dropping child question on Question
      else if (draggedChildQuestionId) {
        this.preventDrop = true;
      } else if (fromIndex == toIndex) {
        // When a dragged component is dropped on same component within same category(SECTION)
        this.preventDrop = true;
      } else if (fromIndex >= 0 && toIndex >= 0 && fromIndex != toIndex) {
        // Check whether section is different of dragged question and dropped question

        let fromSectionId = sQuestions[fromIndex].sectionID;
        let toSectionId = sQuestions[toIndex].sectionID;
        let emptyFromSectionIndex = -1;
        let emptyFromSection = {};
        let updatedSQuestions = [];

        // Check to not allow drop if either the source or the target section contains a restricted question
        if (this.preventDragAndDrop(fromSectionId) || this.preventDragAndDrop(toSectionId)) {
          this.preventDrop = true;
          return;
        }

        if (
          fromSectionId > 0 &&
          toSectionId > 0 &&
          fromSectionId != toSectionId
        ) {
          //If section (of Dragged question) having single question
          if (
            sQuestions.filter((sq) => sq.sectionID == fromSectionId).length == 1
          ) {
            //Get unique sections
            let uniqueDroppableSections = [
              ...new Set(sQuestions.map((item) => item.sectionID)),
            ];
            emptyFromSectionIndex = uniqueDroppableSections.findIndex(
              (s) => s == fromSectionId
            );
            if (
              this.state.templateSections &&
              this.state.templateSections[emptyFromSectionIndex]
            ) {
              emptyFromSection = {
                ...this.state.templateSections[emptyFromSectionIndex],
              };
            }
          }
        }

        const toIndexSQuestion = sQuestions.filter(
          (_, index) => index === toIndex
        )[0];

        updatedSQuestions = sQuestions.map((sQuestion, index) => {
          if (index === fromIndex) {
            return {
              ...sQuestion,
              sectionID: toIndexSQuestion.sectionID,
              sectionName: toIndexSQuestion.sectionName,
              sectionIsLocked: toIndexSQuestion.sectionIsLocked,
              isNewSection: toIndexSQuestion.isNewSection,
              sectionHighlightClass: toIndexSQuestion.sectionHighlightClass,
            };
          } else {
            return sQuestion;
          }
        });

        updatedSQuestions.splice(
          toIndex,
          0,
          updatedSQuestions.splice(fromIndex, 1)[0]
        );

        // Check target section is having empty question
        let emptyToSectionIndex = updatedSQuestions.findIndex(
          (sq) => sq.sectionID == toSectionId && sq.isEmptySection
        );
        if (emptyToSectionIndex >= 0) {
          // Remove emtpy question from Section as new question is dropped on Section question
          updatedSQuestions.splice(emptyToSectionIndex, 1);
        }

        this.preventDrop = true;

        let uniqueSections = [
          ...new Set(updatedSQuestions.map((item) => item.sectionID)),
        ];
        let updatedSectionQuestions = [];
        uniqueSections &&
          uniqueSections.length > 0 &&
          uniqueSections.forEach((s) => {
            let sectionWiseQuestions = updatedSQuestions.filter(
              (sq) => sq.sectionID == s
            );
            if (
              sectionWiseQuestions &&
              sectionWiseQuestions.some((sq) => sq.isEmptySection)
            ) {
              //Handle Section is having empty question
              updatedSectionQuestions.push({
                sectionID: sectionWiseQuestions[0].sectionID,
                sectionName: sectionWiseQuestions[0].sectionName,
                sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                isNewSection: sectionWiseQuestions[0].isNewSection,
                sectionHighlightClass:
                  sectionWiseQuestions[0].sectionHighlightClass,
                questions: [],
              });
            } else {
              updatedSectionQuestions.push({
                sectionID: sectionWiseQuestions[0].sectionID,
                sectionName: sectionWiseQuestions[0].sectionName,
                sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                isNewSection: sectionWiseQuestions[0].isNewSection,
                sectionHighlightClass:
                  sectionWiseQuestions[0].sectionHighlightClass,
                questions: [...sectionWiseQuestions],
              });
            }
          });

        if (emptyFromSectionIndex >= 0) {
          emptyFromSection.questions = [];
          updatedSectionQuestions.splice(
            emptyFromSectionIndex,
            0,
            emptyFromSection
          );
        }

        let sectionOrder = 0;

        const sortedSections = updatedSectionQuestions.map((section) => {
          let questions = [];
          let questionOrder = 0;
          if (section.questions.length > 0) {
            questions = section.questions.map((question) => {
              let childQuestions = [];
              let childQuestionOrder = 0;
              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    return {
                      ...childQuestion,
                      questionSortOrder: ++childQuestionOrder,
                    };
                  }
                );
              }

              return {
                ...question,
                questionSortOrder: ++questionOrder,
                childQuestions: childQuestions,
              };
            });
          }

          return {
            ...section,
            sectionSortOrder: ++sectionOrder,
            questions: questions,
          };
        });

        const isTemplateDirty =
          JSON.stringify(sortedSections) !==
          JSON.stringify(this.props.templateSections);

        this.setState(
          {
            templateSections: [...sortedSections],
            isTemplateDirty: isTemplateDirty,
          },
          this.saveEditedCustomPsqTemplate
        );
      } else if (fromIndex == -1 && toIndex >= 0) {
        // When a dragged component is dropped in different category(AVAILABLE QUESTION TO SECTION category)
        // and that dragged component has not dropped on another component in different category
        // but that that dragged component has dropped on empty area of panel
        let aQuestions = this.state.availableQuestionDetail;
        let otherCategoryFromIndex =
          aQuestions && aQuestions.findIndex((q) => q.questionID == draggedId);
        if (otherCategoryFromIndex >= 0) {
          let sQuestions = sectionQuestions;

          let draggedQuestion = {
            ...aQuestions.find((q) => q.questionID == draggedId),
          };
          if (draggedQuestion) {
            draggedQuestion.category = 'sectionquestion';
            draggedQuestion.sectionID = sQuestions[toIndex].sectionID;
            draggedQuestion.sectionName = sQuestions[toIndex].sectionName;
            draggedQuestion.sectionIsLocked =
              sQuestions[toIndex].sectionIsLocked;
            draggedQuestion.isNewSection = sQuestions[toIndex].isNewSection;
            draggedQuestion.sectionHighlightClass =
              sQuestions[toIndex].sectionHighlightClass;
            sQuestions.splice(toIndex, 0, draggedQuestion);
          }

          let uniqueSections = [
            ...new Set(sQuestions.map((item) => item.sectionID)),
          ];
          let updatedSectionQuestions = [];
          uniqueSections &&
            uniqueSections.length > 0 &&
            uniqueSections.forEach((s) => {
              let sectionWiseQuestions = sQuestions.filter(
                (sq) => sq.sectionID == s
              );
              if (
                sectionWiseQuestions &&
                sectionWiseQuestions.some((sq) => sq.isEmptySection)
              ) {
                //Handle Section is having empty question
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [],
                });
              } else {
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [...sectionWiseQuestions],
                });
              }
            });

          // Remove Question from other Category
          aQuestions = aQuestions.filter((q) => q.questionID != draggedId);

          this.preventDrop = true;

          let sectionOrder = 0;

          const sortedSections = updatedSectionQuestions.map((section) => {
            let questions = [];
            let questionOrder = 0;
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                let childQuestions = [];
                let childQuestionOrder = 0;
                if (question.childQuestions.length > 0) {
                  childQuestions = question.childQuestions.map(
                    (childQuestion) => {
                      return {
                        ...childQuestion,
                        questionSortOrder: ++childQuestionOrder,
                      };
                    }
                  );
                }

                return {
                  ...question,
                  questionSortOrder: ++questionOrder,
                  childQuestions: childQuestions,
                };
              });
            }

            return {
              ...section,
              sectionSortOrder: ++sectionOrder,
              questions: questions,
            };
          });

          const isTemplateDirty =
            JSON.stringify(sortedSections) !==
            JSON.stringify(this.props.templateSections);

          this.setState(
            {
              templateSections: [...sortedSections],
              availableQuestionDetail: [...aQuestions],
              isTemplateDirty: isTemplateDirty,
            },
            this.saveEditedCustomPsqTemplate
          );

          let updatedQuestions = this.state.allAvailableQuestions.slice();
          const index = updatedQuestions.findIndex(
            (q) => q.questionID === draggedQuestion.questionID
          );
          updatedQuestions.splice(index, 1);

          updatedQuestions.sort(
            (a, b) => parseInt(a.questionID) - parseInt(b.questionID)
          );

          this.setState({ allAvailableQuestions: updatedQuestions });
        }
      }
    }
  }

  // Handle onDrop event for Available Question category
  handleQuestionDropComponentForAvailableQuestionCategory(
    questionId,
    draggedId,
    draggedSectionId,
    draggedChildQuestionId,
    sectionQuestions
  ) {
    let aQuestions = this.state.availableQuestionDetail;
    let fromIndex = aQuestions.findIndex((q) => q.questionID == draggedId);
    let toIndex = aQuestions.findIndex((q) => q.questionID == questionId);

    // Prevent dropping section on available question
    if (!draggedId) {
      this.preventDrop = true;
    } else if (
      draggedId <= 0 &&
      fromIndex == -1 &&
      !sectionQuestions.some((q) => q.questionID == draggedId)
    ) {
      this.preventDrop = true;
    }
    // Prevent dropping child question on available question
    else if (draggedChildQuestionId) {
      this.preventDrop = true;
    }
    // When a dragged component is dropped on same component within same category(AVAILABLE QUESTION)
    else if (fromIndex == toIndex) {
      this.preventDrop = true;
    }
    // When a dragged component is dropped on different component within same category(AVAILABLE QUESTION)
    else if (fromIndex >= 0 && toIndex >= 0 && fromIndex != toIndex) {
      aQuestions.splice(toIndex, 0, aQuestions.splice(fromIndex, 1)[0]);

      this.preventDrop = true;

      this.setState({
        availableQuestionDetail: [...aQuestions],
      });
    } else if (fromIndex == -1 && toIndex >= 0) {
      // When a dragged component is dropped in different category(SECTION TO AVAILABLE QUESTION category)

      let sQuestions = sectionQuestions;
      let otherCategoryFromIndex = sQuestions.findIndex(
        (q) => q.questionID == draggedId
      );
      if (otherCategoryFromIndex >= 0) {
        let aQuestions = this.state.availableQuestionDetail;
        let emptyFromSectionIndex = -1;
        let emptyFromSection = {};

        let draggedQuestion = {
          ...sQuestions.find((q) => q.questionID == draggedId),
        };
        if (draggedQuestion) {
          let fromSectionId = draggedQuestion.sectionID;

          // Check to not allow drop if the source section contains a restricted question
          if (this.preventDragAndDrop(fromSectionId)) {
            this.preventDrop = true;
            return;
          }

          //If section (of Dragged question) having single question
          if (
            sQuestions.filter((sq) => sq.sectionID == fromSectionId).length == 1
          ) {
            //Get unique sections
            let uniqueDroppableSections = [
              ...new Set(sQuestions.map((item) => item.sectionID)),
            ];
            emptyFromSectionIndex = uniqueDroppableSections.findIndex(
              (s) => s == fromSectionId
            );
            if (
              this.state.templateSections &&
              this.state.templateSections[emptyFromSectionIndex]
            ) {
              emptyFromSection = {
                ...this.state.templateSections[emptyFromSectionIndex],
              };
            }
          }

          draggedQuestion.category = 'availablequestion';
          aQuestions.splice(toIndex, 0, draggedQuestion);
        }

        // Remove Question from other Category
        sQuestions = sQuestions.filter((q) => q.questionID != draggedId);

        let uniqueSections = [
          ...new Set(sQuestions.map((item) => item.sectionID)),
        ];
        let updatedSectionQuestions = [];
        uniqueSections &&
          uniqueSections.length > 0 &&
          uniqueSections.forEach((s) => {
            let sectionWiseQuestions = sQuestions.filter(
              (sq) => sq.sectionID == s
            );
            if (
              sectionWiseQuestions &&
              sectionWiseQuestions.some((sq) => sq.isEmptySection)
            ) {
              //Handle Section is having empty question
              updatedSectionQuestions.push({
                sectionID: sectionWiseQuestions[0].sectionID,
                sectionName: sectionWiseQuestions[0].sectionName,
                sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                isNewSection: sectionWiseQuestions[0].isNewSection,
                sectionHighlightClass:
                  sectionWiseQuestions[0].sectionHighlightClass,
                questions: [],
              });
            } else {
              updatedSectionQuestions.push({
                sectionID: sectionWiseQuestions[0].sectionID,
                sectionName: sectionWiseQuestions[0].sectionName,
                sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                isNewSection: sectionWiseQuestions[0].isNewSection,
                sectionHighlightClass:
                  sectionWiseQuestions[0].sectionHighlightClass,
                questions: [...sectionWiseQuestions],
              });
            }
          });
        if (emptyFromSectionIndex >= 0) {
          emptyFromSection.questions = [];
          updatedSectionQuestions.splice(
            emptyFromSectionIndex,
            0,
            emptyFromSection
          );
        }

        this.preventDrop = true;

        let sectionOrder = 0;

        const sortedSections = updatedSectionQuestions.map((section) => {
          let questions = [];
          let questionOrder = 0;
          if (section.questions.length > 0) {
            questions = section.questions.map((question) => {
              let childQuestions = [];
              let childQuestionOrder = 0;
              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    return {
                      ...childQuestion,
                      questionSortOrder: ++childQuestionOrder,
                    };
                  }
                );
              }

              return {
                ...question,
                questionSortOrder: ++questionOrder,
                childQuestions: childQuestions,
              };
            });
          }

          return {
            ...section,
            sectionSortOrder: ++sectionOrder,
            questions: questions,
          };
        });

        const isTemplateDirty =
          JSON.stringify(sortedSections) !==
          JSON.stringify(this.props.templateSections);

        this.setState(
          {
            templateSections: [...sortedSections],
            availableQuestionDetail: [...aQuestions],
            isTemplateDirty: isTemplateDirty,
          },
          this.saveEditedCustomPsqTemplate
        );

        let updatedQuestions = this.state.allAvailableQuestions.slice();
        updatedQuestions.push(draggedQuestion);

        updatedQuestions.sort(
          (a, b) => parseInt(a.questionID) - parseInt(b.questionID)
        );

        this.setState({ allAvailableQuestions: updatedQuestions });
      }
    }
  }

  // Handle onDrop event for Child Question category
  handleQuestionDropComponentForSectionChildQuestionCategory(
    questionId,
    draggedId,
    draggedSectionId,
    draggedChildQuestionId,
    sectionQuestions
  ) {
    let sQuestions = sectionQuestions;

    // Get child questions list based on parent question id
    let parentQuestion =
      sQuestions && sectionQuestions.find((sq) => sq.questionID == draggedId);
    //Prevent dropping of Section on Child question
    if (
      draggedSectionId &&
      draggedSectionId > 0 &&
      !draggedId &&
      !draggedChildQuestionId
    ) {
      this.preventDrop = true;
    } else if (
      parentQuestion &&
      parentQuestion.childQuestions &&
      parentQuestion.childQuestions.length > 0 &&
      parentQuestion.childQuestions.some((cq) => cq.questionID == questionId)
    ) {
      let fromIndex = parentQuestion.childQuestions.findIndex(
        (q) => q.questionID == draggedChildQuestionId
      );
      let toIndex = parentQuestion.childQuestions.findIndex(
        (q) => q.questionID == questionId
      );

      // When a dragged component is dropped on same component within same category(CHILD QUESTION)
      if (fromIndex == toIndex) {
        this.preventDrop = true;
      }
      // When a dragged component is dropped on different component within same category(CHILD QUESTION)
      else if (fromIndex >= 0 && toIndex >= 0 && fromIndex != toIndex) {

        const updatedChildQuestions = parentQuestion.childQuestions.slice();
        updatedChildQuestions.splice(
          toIndex,
          0,
          updatedChildQuestions.splice(fromIndex, 1)[0]
        );

        const updatedSQuestions = sQuestions.map((sQuestion) => {
          if (sQuestion.questionID === parseInt(draggedId)) {
            return {
              ...sQuestion,
              childQuestions: updatedChildQuestions,
            };
          } else {
            return sQuestion;
          }
        });

        this.preventDrop = true;

        let uniqueSections = [
          ...new Set(updatedSQuestions.map((item) => item.sectionID)),
        ];
        let updatedSectionQuestions = [];
        uniqueSections &&
          uniqueSections.length > 0 &&
          uniqueSections.forEach((s) => {
            let sectionWiseQuestions = updatedSQuestions.filter(
              (sq) => sq.sectionID == s
            );
            if (
              sectionWiseQuestions &&
              sectionWiseQuestions.some((sq) => sq.isEmptySection)
            ) {
              //Handle Section is having empty question
              updatedSectionQuestions.push({
                sectionID: sectionWiseQuestions[0].sectionID,
                sectionName: sectionWiseQuestions[0].sectionName,
                sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                isNewSection: sectionWiseQuestions[0].isNewSection,
                sectionHighlightClass:
                  sectionWiseQuestions[0].sectionHighlightClass,
                questions: [],
              });
            } else {
              updatedSectionQuestions.push({
                sectionID: sectionWiseQuestions[0].sectionID,
                sectionName: sectionWiseQuestions[0].sectionName,
                sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                isNewSection: sectionWiseQuestions[0].isNewSection,
                sectionHighlightClass:
                  sectionWiseQuestions[0].sectionHighlightClass,
                questions: [...sectionWiseQuestions],
              });
            }
          });
        let sectionOrder = 0;

        const sortedSections = updatedSectionQuestions.map((section) => {
          let questions = [];
          let questionOrder = 0;
          if (section.questions.length > 0) {
            questions = section.questions.map((question) => {
              let childQuestions = [];
              let childQuestionOrder = 0;
              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    return {
                      ...childQuestion,
                      questionSortOrder: ++childQuestionOrder,
                    };
                  }
                );
              }

              return {
                ...question,
                questionSortOrder: ++questionOrder,
                childQuestions: childQuestions,
              };
            });
          }

          return {
            ...section,
            sectionSortOrder: ++sectionOrder,
            questions: questions,
          };
        });

        const isTemplateDirty =
          JSON.stringify(sortedSections) !==
          JSON.stringify(this.props.templateSections);

        const successStateObj = {
          tempTemplateStateOnChildQuestionRearrange: [...sortedSections],
          tempisTemplateDirtyStateOnChildQuestionRearrange: isTemplateDirty,
          parentQuestionOfTheChildQuestionsRearranged: parentQuestion
        };

        if (parentQuestion.isLocked) {
          successStateObj.showStandardChildQuestionsRearrangeWarningModal = true;
          this.setState(successStateObj);
        } else {
          this.getLockStatusForQuestion(questionId, this.state.templateId, this.props.clientId, 'Rearrange children', successStateObj, this.updateChildQuestionSortOrder);
        }
      }
    } else {
      // Both children questions are not from same parent question
      this.preventDrop = true;
    }
  }

  // Handle onDrop event for Section/ Available Question - When Question component is dropped on another Question component
  handleQuestionDropComponent(ev, category, questionId) {
    if (!this.preventDrop) {
      // Get dragged component(Question) id
      let draggedId = ev.dataTransfer.getData('id');
      // Get dragged component(Section) id
      let draggedSectionId = ev.dataTransfer.getData('sectionId');
      // Get dragged component(Child question) id
      let draggedChildQuestionId = ev.dataTransfer.getData('childQuestionId');

      // Prevent dropping of component on section(or its questions) having questions of type "psq section 2 additional services" or "psq section 2 property manager"
      let shouldPreventDrop = this.getPreventDropFlagOnDropEvent(category, questionId);
      if (shouldPreventDrop) {
        this.preventDrop = true;
        return;
      }

      // Transform question to format required by Drag and Drop components
      let sections = this.state.templateSections;

      let sectionQuestions = [];
      if (sections && sections.length > 0) {
        sections.map((sectionItem) => {
          if (!sectionItem.questions || sectionItem.questions.length == 0) {
            sectionQuestions.push({
              category: 'sectionquestion',
              sectionID: sectionItem.sectionID,
              sectionName: sectionItem.sectionName,
              sectionIsLocked: sectionItem.sectionIsLocked,
              isNewSection: sectionItem.isNewSection,
              sectionHighlightClass: sectionItem.sectionHighlightClass,
              questionID: '-1',
              isEmptySection: true,
            });
          }
          sectionQuestions.push(...sectionItem.questions);
        });
      }

      if (category == 'section') {
        this.handleQuestionDropComponentForSectionCategory(
          questionId,
          draggedId,
          draggedSectionId,
          draggedChildQuestionId,
          sectionQuestions
        );
      } else if (category == 'sectionquestion') {
        this.handleQuestionDropComponentForSectionQuestionCategory(
          questionId,
          draggedId,
          draggedSectionId,
          draggedChildQuestionId,
          sectionQuestions
        );
      } else if (category == 'availablequestion') {
        this.handleQuestionDropComponentForAvailableQuestionCategory(
          questionId,
          draggedId,
          draggedSectionId,
          draggedChildQuestionId,
          sectionQuestions
        );
      } else if (category == 'sectionchildquestion') {
        this.handleQuestionDropComponentForSectionChildQuestionCategory(
          questionId,
          draggedId,
          draggedSectionId,
          draggedChildQuestionId,
          sectionQuestions
        );
      } else if (category == 'sectioncomment') {
        this.handleQuestionDropComponentForSectionCommentCategory(
          questionId,
          draggedId,
          draggedSectionId,
          draggedChildQuestionId,
          sectionQuestions
        );
      }
    }
  }

  // Handle Drop Panel for Section Question Category
  handleQuestionDropPanelForSectionQuestionCategory(
    id,
    draggedSectionId,
    draggedChildQuestionId,
    sectionQuestions
  ) {
    // If component is dropped on template panel then
    // Avoid dropping question, child question from template panel or quetion from available question
    // And only allow Section to drop on template pane;
    if (
      !id ||
      (!sectionQuestions.some((q) => q.questionID == id) &&
        !this.state.availableQuestionDetail.some((q) => q.questionID == id))
    ) {
      // Drop Section at end of all Sections
      let sections = this.state.templateSections;
      let toIndex = (sections && sections.length - 1) || 0;
      let fromIndex =
        sections && sections.findIndex((q) => q.sectionID == draggedSectionId);

      if (fromIndex >= 0 && toIndex >= 0 && fromIndex != toIndex) {
        sections.splice(toIndex, 0, sections.splice(fromIndex, 1)[0]);

        let sectionOrder = 0;

        const sortedSections = sections.map((section) => {
          let questions = [];
          let questionOrder = 0;
          if (section.questions.length > 0) {
            questions = section.questions.map((question) => {
              let childQuestions = [];
              let childQuestionOrder = 0;
              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    return {
                      ...childQuestion,
                      questionSortOrder: ++childQuestionOrder,
                    };
                  }
                );
              }

              return {
                ...question,
                questionSortOrder: ++questionOrder,
                childQuestions: childQuestions,
              };
            });
          }

          return {
            ...section,
            sectionSortOrder: ++sectionOrder,
            questions: questions,
          };
        });

        const isTemplateDirty =
          JSON.stringify(sortedSections) !==
          JSON.stringify(this.props.templateSections);

        this.setState(
          {
            templateSections: [...sortedSections],
            isTemplateDirty: isTemplateDirty,
          },
          this.saveEditedCustomPsqTemplate
        );
      }
    }
  }

  // Handle Drop Panel for Available Question Category
  handleQuestionDropPanelForAvailableQuestionCategory(
    category,
    id,
    draggedSectionId,
    draggedChildQuestionId,
    sectionQuestions
  ) {
    //If section is dropped on available question then avoid drop
    if (
      draggedSectionId &&
      draggedSectionId > 0 &&
      (!id || !sectionQuestions.some((q) => q.questionID == id))
    ) {
      // Prevent dropping of section on available question panel
    } else if (draggedChildQuestionId) {
      // Prevent dropping of child question on available question panel
    }

    // When a dragged component is dropped in same category (AVAILABLE QUESTION)
    // and that dragged component has not dropped on another component in same category
    // but that that dragged component has dropped on empty area of panel
    else if (
      this.state.availableQuestionDetail &&
      this.state.availableQuestionDetail.some((q) => q.questionID == id)
    ) {
      let aQuestions = this.state.availableQuestionDetail;

      let draggedQuestionIndex = this.state.availableQuestionDetail.findIndex(
        (q) => q.questionID == id
      );
      if (draggedQuestionIndex >= 0) {
        aQuestions.push(aQuestions.splice(draggedQuestionIndex, 1)[0]);

        this.setState({
          availableQuestionDetail: [...aQuestions],
        });
      }
    } else {
      // When a dragged component is dropped from one category(SECTION) to another category(AVAILABLE QUESTION)
      // and that dragged component has not dropped on another component in another category
      // but that that dragged component has dropped on empty area of panel

      let sQuestions = sectionQuestions;
      let aQuestions = this.state.availableQuestionDetail
        ? this.state.availableQuestionDetail
        : [];
      let emptyFromSectionIndex = -1;
      let emptyFromSection = {};
      let draggedQuestion = { ...sQuestions.find((q) => q.questionID == id) };
      if (draggedQuestion) {
        let fromSectionId = draggedQuestion.sectionID;

        // Check to not allow drop if the source section contains a restricted question
        if (this.preventDragAndDrop(fromSectionId)) {
          this.preventDrop = true;
          return;
        }

        //If section (of Dragged question) having single question
        if (
          sQuestions.filter((sq) => sq.sectionID == fromSectionId).length == 1
        ) {
          //Get unique sections
          let uniqueDroppableSections = [
            ...new Set(sQuestions.map((item) => item.sectionID)),
          ];
          emptyFromSectionIndex = uniqueDroppableSections.findIndex(
            (s) => s == fromSectionId
          );
          if (
            this.state.templateSections &&
            this.state.templateSections[emptyFromSectionIndex]
          ) {
            emptyFromSection = {
              ...this.state.templateSections[emptyFromSectionIndex],
            };
          }
        }

        draggedQuestion.category = category;
        draggedQuestion.sectionID = -1;
        draggedQuestion.sectionName = '';
        aQuestions.push(draggedQuestion);
      }

      // Remove Question
      sQuestions = sQuestions.filter((q) => q.questionID != id);
      let uniqueSections = [
        ...new Set(sQuestions.map((item) => item.sectionID)),
      ];
      let updatedSectionQuestions = [];
      uniqueSections &&
        uniqueSections.length > 0 &&
        uniqueSections.forEach((s) => {
          let sectionWiseQuestions = sQuestions.filter(
            (sq) => sq.sectionID == s
          );
          if (
            sectionWiseQuestions &&
            sectionWiseQuestions.some((sq) => sq.isEmptySection)
          ) {
            //Handle Section is having empty question
            updatedSectionQuestions.push({
              sectionID: sectionWiseQuestions[0].sectionID,
              sectionName: sectionWiseQuestions[0].sectionName,
              sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
              isNewSection: sectionWiseQuestions[0].isNewSection,
              sectionHighlightClass:
                sectionWiseQuestions[0].sectionHighlightClass,
              questions: [],
            });
          } else {
            updatedSectionQuestions.push({
              sectionID: sectionWiseQuestions[0].sectionID,
              sectionName: sectionWiseQuestions[0].sectionName,
              sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
              isNewSection: sectionWiseQuestions[0].isNewSection,
              sectionHighlightClass:
                sectionWiseQuestions[0].sectionHighlightClass,
              questions: [...sectionWiseQuestions],
            });
          }
        });
      if (emptyFromSectionIndex >= 0) {
        emptyFromSection.questions = [];
        updatedSectionQuestions.splice(
          emptyFromSectionIndex,
          0,
          emptyFromSection
        );
      }

      let sectionOrder = 0;

      const sortedSections = updatedSectionQuestions.map((section) => {
        let questions = [];
        let questionOrder = 0;
        if (section.questions.length > 0) {
          questions = section.questions.map((question) => {
            let childQuestions = [];
            let childQuestionOrder = 0;
            if (question.childQuestions.length > 0) {
              childQuestions = question.childQuestions.map((childQuestion) => {
                return {
                  ...childQuestion,
                  questionSortOrder: ++childQuestionOrder,
                };
              });
            }

            return {
              ...question,
              questionSortOrder: ++questionOrder,
              childQuestions: childQuestions,
            };
          });
        }

        return {
          ...section,
          sectionSortOrder: ++sectionOrder,
          questions: questions,
        };
      });

      const isTemplateDirty =
        JSON.stringify(sortedSections) !==
        JSON.stringify(this.props.templateSections);

      this.setState(
        {
          templateSections: sortedSections,
          availableQuestionDetail: [...aQuestions],
          isTemplateDirty: isTemplateDirty,
        },
        this.saveEditedCustomPsqTemplate
      );

      let updatedQuestions = this.state.allAvailableQuestions.slice();
      updatedQuestions.push(draggedQuestion);

      updatedQuestions.sort(
        (a, b) => parseInt(a.questionID) - parseInt(b.questionID)
      );

      this.setState({ allAvailableQuestions: updatedQuestions });
    }
  }

  // Handle onDrop event for Section Comment category
  handleQuestionDropComponentForSectionCommentCategory(
    questionId,
    draggedId,
    draggedSectionId,
    draggedChildQuestionId,
    sectionQuestions
  ) {
    if (!this.preventDrop) {
      let sQuestions = sectionQuestions;

      // Prevent dropping child question on Section coment
      if (draggedChildQuestionId) {
        this.preventDrop = true;
      }
      // If question from available question category is dropped on Section comment
      else if (!draggedSectionId || draggedSectionId <= 0) {
        // Get index based on section id
        let toIndex = sQuestions.findIndex((q) => q.sectionID == questionId);
        let sectionQuestionsCount = sQuestions.filter(
          (q) => q.sectionID == questionId
        ).length;
        if (toIndex >= 0) {
          let aQuestions = this.state.availableQuestionDetail;
          let draggedQuestion = {
            ...aQuestions.find((q) => q.questionID == draggedId),
          };
          if (draggedQuestion) {
            draggedQuestion.category = 'sectionquestion';
            draggedQuestion.sectionID = sQuestions[toIndex].sectionID;
            draggedQuestion.sectionName = sQuestions[toIndex].sectionName;
            draggedQuestion.sectionIsLocked =
              sQuestions[toIndex].sectionIsLocked;
            draggedQuestion.isNewSection = sQuestions[toIndex].isNewSection;
            draggedQuestion.sectionHighlightClass =
              sQuestions[toIndex].sectionHighlightClass;
            sQuestions.splice(
              toIndex + sectionQuestionsCount,
              0,
              draggedQuestion
            );
          }

          // Check target section is having empty question
          if (sQuestions[toIndex].isEmptySection) {
            // Remove emtpy question from Section as new question is dropped on Section
            sQuestions.splice(toIndex, 1);
          }

          let uniqueSections = [
            ...new Set(sQuestions.map((item) => item.sectionID)),
          ];

          let updatedSectionQuestions = [];
          uniqueSections &&
            uniqueSections.length > 0 &&
            uniqueSections.forEach((s) => {
              let sectionWiseQuestions = sQuestions.filter(
                (sq) => sq.sectionID == s
              );
              if (
                sectionWiseQuestions &&
                sectionWiseQuestions.some((sq) => sq.isEmptySection)
              ) {
                //Handle Section is having empty question
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [],
                });
              } else {
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [...sectionWiseQuestions],
                });
              }
            });

          // Remove Question from other Category
          aQuestions = aQuestions.filter((q) => q.questionID != draggedId);

          this.preventDrop = true;

          let sectionOrder = 0;

          const sortedSections = updatedSectionQuestions.map((section) => {
            let questions = [];
            let questionOrder = 0;
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                let childQuestions = [];
                let childQuestionOrder = 0;
                if (question.childQuestions.length > 0) {
                  childQuestions = question.childQuestions.map(
                    (childQuestion) => {
                      return {
                        ...childQuestion,
                        questionSortOrder: ++childQuestionOrder,
                      };
                    }
                  );
                }

                return {
                  ...question,
                  questionSortOrder: ++questionOrder,
                  childQuestions: childQuestions,
                };
              });
            }

            return {
              ...section,
              sectionSortOrder: ++sectionOrder,
              questions: questions,
            };
          });

          const isTemplateDirty =
            JSON.stringify(sortedSections) !==
            JSON.stringify(this.props.templateSections);

          this.setState(
            {
              templateSections: [...sortedSections],
              availableQuestionDetail: [...aQuestions],
              isTemplateDirty: isTemplateDirty,
            },
            this.saveEditedCustomPsqTemplate
          );

          let updatedQuestions = this.state.allAvailableQuestions.slice();
          const index = updatedQuestions.findIndex(
            (q) => q.questionID === draggedQuestion.questionID
          );
          updatedQuestions.splice(index, 1);

          updatedQuestions.sort(
            (a, b) => parseInt(a.questionID) - parseInt(b.questionID)
          );

          this.setState({ allAvailableQuestions: updatedQuestions });
        } else {
          this.preventDrop = true;
        }
      }
      // Question from section category is dropped on Section comment
      else if (draggedId && sQuestions.some((q) => q.questionID == draggedId)) {
        let fromIndex = sQuestions.findIndex((q) => q.questionID == draggedId);
        // Get index based on section id
        let toIndex = sQuestions.findIndex((q) => q.sectionID == questionId);
        let sectionQuestionsCount = sQuestions.filter(
          (q) => q.sectionID == questionId
        ).length;

        // Update toIndex
        toIndex = toIndex + (sectionQuestionsCount - 1);

        if (fromIndex == toIndex) {
          // Prevent drop as Index is same
          this.preventDrop = true;
        } else if (fromIndex >= 0 && toIndex >= 0 && fromIndex != toIndex) {
          // Section comment index is different

          let fromSectionId = sQuestions[fromIndex].sectionID;
          let toSectionId = sQuestions[toIndex].sectionID;
          let emptyFromSectionIndex = -1;
          let emptyFromSection = {};
          let updatedSQuestions = [];

          // Check to not allow drop if either the source or the target section contains a restricted question
          if (this.preventDragAndDrop(fromSectionId) || this.preventDragAndDrop(toSectionId)) {
            this.preventDrop = true;
            return;
          }

          if (
            fromSectionId > 0 &&
            toSectionId > 0 &&
            fromSectionId != toSectionId
          ) {
            //If section (of Dragged question) having single question
            if (
              sQuestions.filter((sq) => sq.sectionID == fromSectionId).length ==
              1
            ) {
              //Get unique sections
              let uniqueDroppableSections = [
                ...new Set(sQuestions.map((item) => item.sectionID)),
              ];
              emptyFromSectionIndex = uniqueDroppableSections.findIndex(
                (s) => s == fromSectionId
              );
              if (
                this.state.templateSections &&
                this.state.templateSections[emptyFromSectionIndex]
              ) {
                emptyFromSection = {
                  ...this.state.templateSections[emptyFromSectionIndex],
                };
              }
            }
          }

          const toIndexSQuestion = sQuestions.filter(
            (_, index) => index === toIndex
          )[0];

          updatedSQuestions = sQuestions.map((sQuestion, index) => {
            if (index === fromIndex) {
              return {
                ...sQuestion,
                sectionID: toIndexSQuestion.sectionID,
                sectionName: toIndexSQuestion.sectionName,
                sectionIsLocked: toIndexSQuestion.sectionIsLocked,
                isNewSection: toIndexSQuestion.isNewSection,
                sectionHighlightClass: toIndexSQuestion.sectionHighlightClass,
              };
            } else {
              return sQuestion;
            }
          });

          updatedSQuestions.splice(
            toIndex < fromIndex
              ? toIndex + sectionQuestionsCount
              : toIndex + sectionQuestionsCount - 1,
            0,
            updatedSQuestions.splice(fromIndex, 1)[0]
          );

          // Check target section is having empty question
          let emptyToSectionIndex = updatedSQuestions.findIndex(
            (sq) => sq.sectionID == toSectionId && sq.isEmptySection
          );
          if (emptyToSectionIndex >= 0) {
            // Remove emtpy question from Section as new question is dropped on Section
            updatedSQuestions.splice(emptyToSectionIndex, 1);
          }

          let uniqueSections = [
            ...new Set(updatedSQuestions.map((item) => item.sectionID)),
          ];
          let updatedSectionQuestions = [];
          uniqueSections &&
            uniqueSections.length > 0 &&
            uniqueSections.forEach((s) => {
              let sectionWiseQuestions = updatedSQuestions.filter(
                (sq) => sq.sectionID == s
              );
              if (
                sectionWiseQuestions &&
                sectionWiseQuestions.some((sq) => sq.isEmptySection)
              ) {
                //Handle Section is having empty question
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [],
                });
              } else {
                updatedSectionQuestions.push({
                  sectionID: sectionWiseQuestions[0].sectionID,
                  sectionName: sectionWiseQuestions[0].sectionName,
                  sectionIsLocked: sectionWiseQuestions[0].sectionIsLocked,
                  isNewSection: sectionWiseQuestions[0].isNewSection,
                  sectionHighlightClass:
                    sectionWiseQuestions[0].sectionHighlightClass,
                  questions: [...sectionWiseQuestions],
                });
              }
            });

          if (emptyFromSectionIndex >= 0) {
            emptyFromSection.questions = [];
            updatedSectionQuestions.splice(
              emptyFromSectionIndex,
              0,
              emptyFromSection
            );
          }

          let sectionOrder = 0;

          const sortedSections = updatedSectionQuestions.map((section) => {
            let questions = [];
            let questionOrder = 0;
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                let childQuestions = [];
                let childQuestionOrder = 0;
                if (question.childQuestions.length > 0) {
                  childQuestions = question.childQuestions.map(
                    (childQuestion) => {
                      return {
                        ...childQuestion,
                        questionSortOrder: ++childQuestionOrder,
                      };
                    }
                  );
                }

                return {
                  ...question,
                  questionSortOrder: ++questionOrder,
                  childQuestions: childQuestions,
                };
              });
            }

            return {
              ...section,
              sectionSortOrder: ++sectionOrder,
              questions: questions,
            };
          });

          this.preventDrop = true;

          const isTemplateDirty =
            JSON.stringify(sortedSections) !==
            JSON.stringify(this.props.templateSections);

          this.setState(
            {
              templateSections: [...sortedSections],
              isTemplateDirty: isTemplateDirty,
            },
            this.saveEditedCustomPsqTemplate
          );
        }
      } else if (draggedSectionId && draggedSectionId > 0) {
        // Prevent dropping Section on Section Comment
        this.preventDrop = true;
      } else {
        this.preventDrop = true;
      }
    }
  }

  // Handle Drop Panel
  handleQuestionDropPanel(ev, category) {
    if (this.preventDrop) {
      // Prevent onDrop of Parent
      this.preventDrop = false;
    } else {
      // Get id of dragged component(Question) id
      let id = ev.dataTransfer.getData('id');
      // Get id of dragged component(Section) id
      let draggedSectionId = ev.dataTransfer.getData('sectionId');
      // Get id of dragged component(Child Question) id
      let draggedChildQuestionId = ev.dataTransfer.getData('childQuestionId');
      let sections = this.state.templateSections;
      let sectionQuestions = [];

      if (sections && sections.length > 0) {
        sections.map((sectionItem) => {
          if (!sectionItem.questions || sectionItem.questions.length == 0) {
            sectionQuestions.push({
              category: 'sectionquestion',
              sectionID: sectionItem.sectionID,
              sectionName: sectionItem.sectionName,
              sectionIsLocked: sectionItem.sectionIsLocked,
              isNewSection: sectionItem.isNewSection,
              sectionHighlightClass: sectionItem.sectionHighlightClass,
              questionID: '-1',
              isEmptySection: true,
            });
          }
          sectionQuestions.push(...sectionItem.questions);
        });
      }

      if (category == 'sectionquestion') {
        this.handleQuestionDropPanelForSectionQuestionCategory(
          id,
          draggedSectionId,
          draggedChildQuestionId,
          sectionQuestions
        );
      } else if (category == 'availablequestion') {
        this.handleQuestionDropPanelForAvailableQuestionCategory(
          category,
          id,
          draggedSectionId,
          draggedChildQuestionId,
          sectionQuestions
        );
      }
    }
  }

  getQuestionById(item) {
    this.props.customChecklistTemplateActions
      .fetchQuestionById(
        this.props.clientId,
        this.state.checklistTypeID,
        item.category === 'sectionquestion' ? this.state.templateId : null,
        item.questionID
      )
      .then((data) => {
        if (item.accordionCategory == 'childQuestion') {
          // If child question then fetch the parent question
          this.props.customChecklistTemplateActions
            .fetchQuestionById(
              this.props.clientId,
              this.state.checklistTypeID,
              item.category === 'sectionquestion'
                ? this.state.templateId
                : null,
              item.parentQuestionID
            )
            .then((parentData) => {
              const parentQuestion = parentData[0];
              const [first] = data;
              if (
                first.childQuestionDisplayRule.enabledByParentAnswers !==
                null &&
                first.childQuestionDisplayRule.enabledByParentAnswers
              ) {
                const selectedParentPicklistItems =
                  first.childQuestionDisplayRule.enabledByParentAnswers.filter(
                    (c) =>
                      parentQuestion.picklistItems
                        .map((item) => item['picklistItemName'])
                        .includes(c)
                  );
                first.childQuestionDisplayRule.enabledByParentAnswers =
                  selectedParentPicklistItems;
              }
              this.setState(
                {
                  isChildQuestion: true,
                  parentQuestionID: item.parentQuestionID,
                  parentQuestionTypeID: parentQuestion.questionTypeID,
                  parentQuestionPicklistItems: parentQuestion.picklistItems,
                },
                () => {
                  const successStateObj = this.setDataForEditQuestionModal(first, item.sectionID, item.sectionName);
                  if (item.isLocked) {
                    this.setState(successStateObj); // Do not try to acquire an intent lock for a standard question
                  } else {
                    this.acquireIntentLockForCustomQuestion(item.questionID, this.state.templateId, this.props.clientId, "EDIT", successStateObj);
                  }
                }
              );
            });
        } else {
          const [first] = data;
          const successStateObj = this.setDataForEditQuestionModal(first, item.sectionID, item.sectionName);
          if (item.isLocked) {
            this.setState(successStateObj); // Do not try to acquire an intent lock for a standard question
          } else {
            this.acquireIntentLockForCustomQuestion(item.questionID, this.state.templateId, this.props.clientId, "EDIT", successStateObj);
          }
        }
      });
  }

  setDataForEditQuestionModal(question, sectionID, sectionName) {
    let showChildQuestion;
    let selectedParentPicklistItems;
    if (question.childQuestionDisplayRule) {
      showChildQuestion =
        question.childQuestionDisplayRule.showChildQuestion == 'conditional'
          ? 'no'
          : 'yes';
      selectedParentPicklistItems = question.childQuestionDisplayRule
        .enabledByParentAnswers
        ? question.childQuestionDisplayRule.enabledByParentAnswers
        : null;
    }

    const successStateObj = {
      showAddEditCustomQuestionModal:
        !this.state.showAddEditCustomQuestionModal,
      currentQuestionPicklistItems: question.picklistItems,
      questionFormPurpose: 'UPDATE',
      questionModalInitialValues: {
        questionID: question.questionID,
        questionText: question.questionText,
        questionHelpText: question.questionHelpText,
        questionTag: question.questionTag,
        questionTypeID: question.questionTypeID,
        picklistItems: question.picklistItems,
        isLocked: question.isLocked,
        showChildQuestionAlways: showChildQuestion,
        parentPicklistItems: selectedParentPicklistItems,
        sectionID: sectionID,
        sectionName: sectionName,
      },
      showEditTemplateQuestionModal: !this.state.showEditTemplateQuestionModal,
      templateQuestionToBeEdited: {},
      attachmentData: question.questionAttachments,
      currentQuestionIdinWorks: question.questionID,
    };

    return successStateObj;
  }

  handleShowStandardQuestions(e) {
    const showStandard = !this.state.showStandardQuestions;
    this.setState({ showStandardQuestions: showStandard });
    let question = Object.assign([], this.state.allAvailableQuestions);
    question = this.filterSearchQuestions(
      this.state.selectedAvailableQuestionSearchBy,
      this.state.searchQuestionText,
      question
    );
    question = this.filterShowStandardQuestion(showStandard, question);
    this.setState({ availableQuestionDetail: question });
  }

  filterShowStandardQuestion(showStandard, question) {
    if (!showStandard) {
      question = question.filter((x) => !x.isLocked);
    }
    return question;
  }

  filterSearchQuestions(searchBy, searctText, questions) {
    const showStandard = this.state.showStandardQuestions;
    let question = Object.assign([], questions);
    if (searctText != '' && searchBy == 1) {
      question = question.filter(
        (x) =>
          (x.questionTag &&
            x.questionTag
              .toLocaleLowerCase()
              .includes(searctText.toLocaleLowerCase())) > 0 ||
          (x.childQuestions.length &&
            x.childQuestions.find(
              (y) =>
                y.questionTag &&
                y.questionTag
                  .toLocaleLowerCase()
                  .includes(searctText.toLocaleLowerCase())
            ))
      );
    } else if (searctText != '' && searchBy == 2) {
      question = question.filter(
        (x) =>
          (x.questionText &&
            x.questionText
              .toLocaleLowerCase()
              .includes(searctText.toLocaleLowerCase())) > 0 ||
          (x.childQuestions.length &&
            x.childQuestions.find(
              (y) =>
                y.questionText &&
                y.questionText
                  .toLocaleLowerCase()
                  .includes(searctText.toLocaleLowerCase())
            ))
      );
    } else if (searctText != '' && searchBy == 3) {
      question = question.filter(
        (x) =>
          (x.questionTypeDescription &&
            x.questionTypeDescription
              .toLocaleLowerCase()
              .includes(searctText.toLocaleLowerCase())) > 0 ||
          (x.childQuestions.length &&
            x.childQuestions.find(
              (y) =>
                y.questionTypeDescription &&
                y.questionTypeDescription
                  .toLocaleLowerCase()
                  .includes(searctText.toLocaleLowerCase())
            ))
      );
    }
    return question;
  }

  handleSearchQuestion(e) {
    this.setState({ searchQuestionText: e.target.value });
    let question = [];
    question = this.filterShowStandardQuestion(
      this.state.showStandardQuestions,
      Object.assign([], this.state.allAvailableQuestions)
    );
    question = this.filterSearchQuestions(
      this.state.selectedAvailableQuestionSearchBy,
      e.target.value,
      question
    );
    this.setState({ availableQuestionDetail: question });
  }

  handleAvailableQuestionsSearchBy(_value) {
    if (this.state.selectedAvailableQuestionSearchBy != _value)
      this.setState({ selectedAvailableQuestionSearchBy: _value });
    this.setState({ searchQuestionText: '' });
    const questions = this.filterShowStandardQuestion(
      this.state.showStandardQuestions,
      Object.assign([], this.state.allAvailableQuestions)
    );

    this.setState({ availableQuestionDetail: questions });
  }

  handleDeleteClick(item) {
    let isTemplateDirty;
    switch (item.accordionCategory) {
      case 'childQuestion': {
        let checklistTemplateSectionID = 0;
        const sections = this.state.templateSections.slice();

        for (let i = 0; i < sections.length; i++) {
          if (sections[i].questions?.length > 0) {
            const question = sections[i].questions.filter(
              (q) => q.questionID === item.parentQuestionID
            );
            if (question.length === 1) {
              const [filteredQuestion] = question;
              const childQuestions = filteredQuestion.childQuestions.filter(
                (c) => c.questionID === item.questionID
              );
              if (childQuestions.length === 1) {
                checklistTemplateSectionID = sections[i].sectionID;
                break;
              }
            }
          }
        }

        if (checklistTemplateSectionID !== 0) {
          const successStateObj = {
            showDeleteTemplateQuestionModal:
              !this.state.showDeleteTemplateQuestionModal,
            templateQuestionToBeDeleted: {
              questionDeleteCategory: 'sectionQuestions',
              checklistTemplateSectionID: checklistTemplateSectionID,
              questionID: item.questionID,
              parentQuestionID: item.parentQuestionID,
            },
            templateQuestionToBeDeletedHasChildQuestions: false,
          };

          this.getLockStatusForQuestion(item.questionID, this.state.templateId, this.props.clientId, 'Delete', successStateObj);
        }
        break;
      }

      case 'parentQuestion': {
        const successStateObj = {
          showDeleteTemplateQuestionModal:
            !this.state.showDeleteTemplateQuestionModal,
          templateQuestionToBeDeleted: {
            questionDeleteCategory: 'sectionQuestions',
            checklistTemplateSectionID: item.sectionID,
            questionID: item.questionID,
            parentQuestionID: item.parentQuestionID,
          },
          templateQuestionToBeDeletedHasChildQuestions:
            item.childQuestions.length <= 0 ? false : true,
        };

        this.getLockStatusForQuestion(item.questionID, this.state.templateId, this.props.clientId, 'Delete', successStateObj);
        break;
      }

      case 'section': {
        if (this.state.templateSections.length === 1) {
          this.setState({ showDeleteLastSectionWarningModal: !this.state.showDeleteLastSectionWarningModal });
          break;
        }
        let questions = [];
        const availableQuestions = this.state.availableQuestionDetail.slice();
        const allAvailableQuestions = this.state.allAvailableQuestions.slice();
        const availableQuestionsBySection = this.state.templateSections.filter(
          (_, i) => i === item.internalSectionId
        );

        availableQuestionsBySection.map((section) => {
          questions = section.questions.map((question) => {
            return {
              ...question,
              isAccordionOpen: false,
            };
          });
        });

        availableQuestions.push(...questions);
        allAvailableQuestions.push(...questions);
        allAvailableQuestions.sort(
          (a, b) => parseInt(a.questionID) - parseInt(b.questionID)
        );

        const sections = this.state.templateSections.filter(
          (_, i) => i !== item.internalSectionId
        );
        isTemplateDirty =
          JSON.stringify(sections) !==
          JSON.stringify(this.props.templateSections);
        this.setState({
          templateSections: sections,
          isTemplateDirty: isTemplateDirty,
          availableQuestionDetail: availableQuestions,
          allAvailableQuestions: allAvailableQuestions,
        });

        if (this.state.selectedSectionId === item.sectionID) {
          this.setState({
            selectedSectionId: 0,
            selectedSectionName: '',
            isNewQuestionForSectionEnabled: false,
          });
        }

        const [first] = availableQuestionsBySection;

        const requestObj = {
          clientId: this.props.clientId,
          checklistTemplateId: this.state.templateId,
          checklistTemplateSectionId: first.sectionID,
          sectionName: first.sectionName,
          sectionSortOrder: first.sectionSortOrder,
          dmlOperation: 'D',
        };

        this.props.customChecklistTemplateActions
          .persistUncommittedTemplateSectionChanges(requestObj)
          .then(() => {
            this.fetchTemplateDataById(this.state.templateId);
          });

        break;
      }
    }
  }

  removeChildQuestion(question, parentQuestionID, questionID) {
    let questionsDetail = JSON.parse(JSON.stringify(question));
    questionsDetail.forEach((x) => {
      if (x.questionID == parentQuestionID) {
        x.childQuestions = x.childQuestions.filter(
          (x) => x.questionID != questionID
        );
      }
    });
    return questionsDetail;
  }

  handleEditClick(item) {
    switch (item.accordionCategory) {
      case 'childQuestion':
      case 'parentQuestion': {
        const successStateObj = {
          showEditTemplateQuestionModal:
            !this.state.showEditTemplateQuestionModal,
          templateQuestionToBeEdited: item,
        };
        if (item.isLocked) {
          this.setState(successStateObj);
        } else {
          this.getLockStatusForQuestion(item.questionID, this.state.templateId, this.props.clientId, 'Edit', successStateObj);
        }
        break;
      }
      case 'section':
        {
          this.setState({ sectionDetail: item });
          this.setState({
            showAddSectionModal: !this.state.showAddSectionModal,
          });
        }
        break;
    }
  }

  getLockStatusForQuestion(questionID, checklistTemplateId, clientId, actionPerformed, successStateObj, successSetStateCallBack) {
    this.props.customQuestionActions.getLockStatusForQuestion(questionID, checklistTemplateId, clientId)
      .then((lockStatusResult) => {
        if (lockStatusResult && lockStatusResult.isQuestionAvailableForEdit) {
          this.setState(successStateObj, successSetStateCallBack);
        } else {
          let headerMessage = '';
          switch (actionPerformed) {
            case 'Edit':
            default:
              headerMessage = 'Edit Question';
              break;
            case 'Add':
              headerMessage = 'Add Question'
              break;
            case 'Delete':
              headerMessage = 'Delete Question';
              break;
            case 'Rearrange children':
              headerMessage = 'Rearrange Child Question';
              break;
          }
          this.setState({
            showQuestionLockedInfoModal: true,
            headerMessageForQuestionLockedInfoModal: headerMessage,
            contentForQuestionLockedInfoModal: lockStatusResult.validationResult,
            showEditTemplateQuestionModal: false,
            templateQuestionToBeEdited: {},
          });
        }
      });
  }

  acquireIntentLockForCustomQuestion(questionID, checklistTemplateId, clientId, actionPerformed, successStateObj, successSetStateCallBack) {
    let request = {
      questionID: questionID,
      checklistTemplateID: checklistTemplateId,
      clientID: clientId
    }

    this.props.customQuestionActions.acquireIntentLockForCustomQuestion(request)
      .then((intentLockUpdateResult) => {
        if (intentLockUpdateResult && intentLockUpdateResult.isIntentLockUpdateSuccessful) {
          successStateObj.startIntentLockTimer = true;
          successStateObj.intentLockExpirationTime = intentLockUpdateResult.lockExpirationDateTime;
          this.setState(successStateObj, successSetStateCallBack);
        } else if (intentLockUpdateResult && !intentLockUpdateResult.isIntentLockUpdateSuccessful) {
          let headerMessage = '';
          switch (actionPerformed) {
            case 'Edit':
            default:
              headerMessage = 'Edit Question';
              break;
            case 'Add':
              headerMessage = 'Add Child Question'
              break;
            case 'Delete':
              headerMessage = 'Delete Question';
              break;
            case 'Rearrange children':
              headerMessage = 'Rearrange Child Question';
              break;
          }
          this.setState({
            showQuestionLockedInfoModal: true,
            headerMessageForQuestionLockedInfoModal: headerMessage,
            contentForQuestionLockedInfoModal:intentLockUpdateResult.validationResult
          });
        }
      });
  }

  releaseIntentLockForCustomQuestion(questionID, checklistTemplateId, clientId, successStateObj, successSetStateCallBack) {
    let request = {
      questionID: questionID,
      checklistTemplateID: checklistTemplateId,
      clientID: clientId
    }

    this.props.customQuestionActions.releaseIntentLockForCustomQuestion(request)
      .then(() => {
        this.setState(successStateObj, successSetStateCallBack);
      });
  }

  handleCloseOnQuestionInfoModal() {
    this.setState({
      showQuestionLockedInfoModal: false,
      headerMessage: '',
      content: '',
      showEditTemplateQuestionModal: false,
      templateQuestionToBeEdited: {},
    });
  }

  handleToggleAddSectionModal() {
    this.setState({ sectionDetail: null });
    this.setState({ showAddSectionModal: !this.state.showAddSectionModal });
  }

  handleAddSection(newSection, sectionSortOrderId) {
    let randomNumber = parseInt(Math.random() * (99999999 - 1)) + 1;
    let existingSections = [];
    let requestObj = {};
    if (sectionSortOrderId > 0) {
      existingSections = this.state.templateSections.map((section, i) => {
        if (section.sectionSortOrder === sectionSortOrderId) {
          // Request for database persistence (Uncommitted)
          requestObj = {
            clientId: this.props.clientId,
            checklistTemplateId: this.state.templateId,
            checklistTemplateSectionId: section.sectionID,
            sectionName: newSection,
            sectionSortOrder: section.sectionSortOrder,
            dmlOperation: 'U',
          };

          return {
            ...section,
            sectionName: newSection,
          };
        } else {
          return section;
        }
      });
    } else {
      existingSections = this.state.templateSections.slice();
      let newSectionSortOrder = existingSections.length + 1;
      existingSections.push({
        sectionName: newSection,
        sectionSortOrder: newSectionSortOrder,
        questions: [],
        sectionID: randomNumber,
        isNewSection: true,
        sectionHighlightClass: '',
        sectionIsLocked: false,
      });

      // Request for database persistence (Uncommitted)
      requestObj = {
        clientId: this.props.clientId,
        checklistTemplateId: parseInt(this.state.templateId),
        checklistTemplateSectionId: null,
        sectionName: newSection,
        sectionSortOrder: parseInt(newSectionSortOrder),
        dmlOperation: 'I',
      };
    }

    const isTemplateDirty =
      JSON.stringify(existingSections) !==
      JSON.stringify(this.props.templateSections);

    this.setState({
      templateSections: existingSections,
      isTemplateDirty: isTemplateDirty,
    });

    this.handleToggleAddSectionModal();

    this.props.customChecklistTemplateActions
      .persistUncommittedTemplateSectionChanges(requestObj)
      .then(() => {
        this.fetchTemplateDataById(this.state.templateId);
      });
  }

  handleTemplateReset() {
    this.abandonCustomPsqTemplateChanges();
  }

  handleTemplateSave(values) {
    if (!this.state.isEditMode) {
      this.createCustomPsqTemplate(values);
    } else {
      this.commitCustomPsqTemplateChanges(values);
    }
  }

  toggleShowAddEditCustomQuestionModal() {
    this.setState(
      {
        questionModalInitialValues: {},
        showAddEditCustomQuestionModal:
          !this.state.showAddEditCustomQuestionModal,
        fileAttachmentList: [],
        attachmentData: [],
        showAttachmentStatusModal: false,
        uploadedAttachmentStatus: [],
        isSaving: false,
      }, this.clearQuestionForm);
  }

  toggleShowAddEditCustomQuestionModalAndReleaseIntentLock() {
    const successStateObj = {
      questionModalInitialValues: {},
      showAddEditCustomQuestionModal: false,
      fileAttachmentList: [],
      attachmentData: [],
      showAttachmentStatusModal: false,
      uploadedAttachmentStatus: []
    };

    const questionId = this.state.questionModalInitialValues.questionID ? this.state.questionModalInitialValues.questionID : this.state.parentQuestionID;

    if (!questionId) { // For new parent question questions
      this.toggleShowAddEditCustomQuestionModal();
    } else { // For all edit scenarios & add new question scenarios
      this.releaseIntentLockForCustomQuestion(questionId, this.state.templateId, this.props.clientId, successStateObj, this.clearQuestionForm);
    }
  }

  clearQuestionForm() {
    if (!this.state.showAddEditCustomQuestionModal) {
      this.setState({
        questionFormPurpose: 'UNKNOWN',
        isChildQuestion: false,
        parentQuestionID: null,
        parentQuestionTypeID: null,
        currentQuestionPicklistItems: [],
        parentQuestionPicklistItems: [],
        isQuestionFormDirty: false,
        startIntentLockTimer: false,
        intentLockExpirationTime: null,
        currentQuestionIdinWorks: null,
        isSaving: false,
      });
      store.dispatch(reset('customQuestionForm'));
    }
  }

  handleOnClickCreateNewQuestion(newQuestionSource) {
    let selectedSectionId = 0;
    let selectedSectionName = '';

    if (newQuestionSource === 'templateSections') {
      selectedSectionId = this.state.selectedSectionId;
      selectedSectionName = this.state.selectedSectionName;
    }

    this.setState({
      showAddEditCustomQuestionModal:
        !this.state.showAddEditCustomQuestionModal,
      questionFormPurpose: 'CREATE',
      questionModalInitialValues: {
        sectionID: selectedSectionId,
        sectionName: selectedSectionName,
      },
    });
  }

  handleAddNewChildQuestion(parentQuestion, hasSection) {
    const successStateObj = {
      showAddEditCustomQuestionModal:
        !this.state.showAddEditCustomQuestionModal,
      isChildQuestion: true,
      parentQuestionID: parentQuestion.questionID,
      parentQuestionTypeID: parentQuestion.questionTypeID,
      parentQuestionPicklistItems: parentQuestion.picklistItems,
      questionFormPurpose: 'CREATE',
      questionModalInitialValues: {
        isLocked: parentQuestion.isLocked,
        sectionID: parentQuestion.sectionID,
        sectionName: parentQuestion.sectionName,
      },
      currentQuestionIdinWorks: parentQuestion.questionID,
    };

    if (parentQuestion.isLocked) {
      this.setState(successStateObj);
    } else {
      this.acquireIntentLockForCustomQuestion(parentQuestion.questionID, this.state.templateId, this.props.clientId, 'Add', successStateObj);
    }
  }

  handleChangePicklistItem(picklistItems) {
    this.setState({
      currentQuestionPicklistItems: picklistItems,
      isQuestionFormDirty: true,
    });
  }

  handleSubmitCustomQuestionModalAction(values) {
    this.setState({ isSaving: true });

    let customQuestion = {
      parentQuestionID: this.state.parentQuestionID,
      clientID: this.props.clientId,
      checklistTypeID: 2, //PSQ
      questionText: values.questionText,
      questionHelpText: values.questionHelpText,
      questionTag: values.questionTag,
      questionTypeID: values.questionTypeID,
      isLocked: values.isLocked,
      questionPicklistItems:
        values.questionTypeID == 3 || values.questionTypeID == 4
          ?
          {
            PicklistItems: this.state.currentQuestionPicklistItems.map(
              (item) => item.picklistItemName
            )
          }
          : null,
      childQuestionDisplayRuleJSON: this.state.isChildQuestion
        ? JSON.stringify({
          showChildQuestion:
            values.showChildQuestionAlways == 'yes'
              ? 'always'
              : 'conditional',
          enabledByParentAnswers:
            values.showChildQuestionAlways == 'yes'
              ? null
              : values.parentPicklistItems,
        })
        : null,
      checklistTemplateSectionID:
        values.sectionID !== 0 ? values.sectionID : null,
      questionAttachments: [...this.state.attachmentData, ...this.setNewFiles()],
      checklistTemplateID: this.state.templateId,
    };

    if (this.state.questionFormPurpose === 'CREATE') {
      this.props.customQuestionActions
        .createCustomQuestion(customQuestion)
        .then((data) => {
          this.toggleShowAddEditCustomQuestionModal();

          // Refreshing the content of the whole page after successful addition of a new question
          this.fetchTemplateDataById(this.state.templateId);
          if (data && data != null && data.some(d => d.variant === 'error')) {
            this.setState({
              uploadedAttachmentStatus: data
            });
            this.toggleShowAttachmentStatus();
          }
        });
    }

    if (this.state.questionFormPurpose === 'UPDATE') {
      customQuestion.questionID = values.questionID;
      customQuestion.isLocked = values.isLocked;
      this.props.customQuestionActions
        .updateCustomQuestion(customQuestion)
        .then((data) => {
          this.toggleShowAddEditCustomQuestionModal();

          // Refreshing the content of the whole page after successful addition of a new question
          this.fetchTemplateDataById(this.state.templateId);
          if (data && data != null && data.some(d => d.variant === 'error')) {
            this.setState({
              uploadedAttachmentStatus: data
            });
            this.toggleShowAttachmentStatus();
          }
        });
    }
  }

  setNewFiles() {
    const attachmentData = [];
    this.state.fileAttachmentList.forEach((element) => {
      if (!Object.prototype.hasOwnProperty.call(element, 'errors')) {
        const data = {
          questionAttachmentID: null,
          questionVersionID: null,
          attachmentGuid: null,
          attachmentName: element.name,
          file: element,
          isNew: true,
          isDeleted: false
        }
        attachmentData.push(data);
      }

    });
    return attachmentData;
  }



  handleToggleExpandCollapseAllAcordionsOnTemplate(event, expandOrCollapse) {
    if (expandOrCollapse === 'expand') {
      const templateSections = this.state.templateSections.map((section) => {
        let questions = [];

        if (section.questions.length > 0) {
          questions = section.questions.map((question) => {
            let childQuestions = [];
            if (question.childQuestions.length > 0) {
              childQuestions = question.childQuestions.map((childQuestion) => {
                return {
                  ...childQuestion,
                  isAccordionOpen: true,
                };
              });
            }

            return {
              ...question,
              isAccordionOpen: true,
              childQuestions: childQuestions,
            };
          });
        }

        return {
          ...section,
          isAccordionOpen: true,
          questions: questions,
        };
      });

      this.setState({
        expandAllAccordionsOnTemplate: true,
        templateSections: templateSections,
      });
    } else {
      const templateSections = this.state.templateSections.map((section) => {
        let questions = [];

        if (section.questions.length > 0) {
          questions = section.questions.map((question) => {
            let childQuestions = [];
            if (question.childQuestions.length > 0) {
              childQuestions = question.childQuestions.map((childQuestion) => {
                return {
                  ...childQuestion,
                  isAccordionOpen: false,
                };
              });
            }

            return {
              ...question,
              isAccordionOpen: false,
              childQuestions: childQuestions,
            };
          });
        }

        return {
          ...section,
          isAccordionOpen: false,
          questions: questions,
        };
      });

      this.setState({
        expandAllAccordionsOnTemplate: false,
        templateSections: templateSections,
      });
    }
  }

  handleOpenAccordionOnTemplate(
    event,
    isAccordionOpen,
    sectionID,
    questionID,
    parentQuestionID
  ) {
    let selectedSectionName = '';
    if (!isAccordionOpen) {
      let updatedSections = [];
      // Case - Section
      if (sectionID && !questionID && !parentQuestionID) {
        updatedSections = this.state.templateSections.map((section) => {
          if (section.sectionID === sectionID) {
            const sectionHighlightClass = this.state.isEditLayoutMode
              ? ''
              : 'highlightSelectedSection';
            selectedSectionName = section.sectionName;
            return {
              ...section,
              isAccordionOpen: true,
              sectionHighlightClass: sectionHighlightClass,
            };
          } else {
            return {
              ...section,
              sectionHighlightClass: '',
            };
          }
        });
      } else if (sectionID && questionID && !parentQuestionID) {
        // Case - Parent Question
        updatedSections = this.state.templateSections.map((section) => {
          if (section.sectionID === sectionID) {
            let questions = [];
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                if (question.questionID === questionID) {
                  return {
                    ...question,
                    isAccordionOpen: true,
                  };
                } else {
                  return question;
                }
              });
            }
            return {
              ...section,
              questions: questions,
            };
          } else {
            return section;
          }
        });
      } else if (sectionID && questionID && parentQuestionID) {
        // Case - Child Question
        updatedSections = this.state.templateSections.map((section) => {
          if (section.sectionID === sectionID) {
            let questions = [];
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                if (question.questionID === parentQuestionID) {
                  let childQuestions = [];
                  if (question.childQuestions.length > 0) {
                    childQuestions = question.childQuestions.map(
                      (childQuestion) => {
                        if (childQuestion.questionID === questionID) {
                          return {
                            ...childQuestion,
                            isAccordionOpen: true,
                          };
                        } else {
                          return childQuestion;
                        }
                      }
                    );
                  }

                  return {
                    ...question,
                    childQuestions: childQuestions,
                  };
                } else {
                  return question;
                }
              });
            }

            return {
              ...section,
              questions: questions,
            };
          } else {
            return section;
          }
        });
      }

      this.setState({
        templateSections: updatedSections
      });

      // Check whether selected Section is containing Question of type "psq section 2 additional services" or "psq section 2 property manager"
      // If yes then disable the "New Question" button
      let preventAddingQuestion = this.preventDragAndDrop(sectionID)
      this.setState({
        isNewQuestionForSectionEnabled: !this.state.isEditLayoutMode && !preventAddingQuestion
      });

      if (selectedSectionName !== '') {
        this.setState({
          selectedSectionId: sectionID,
          selectedSectionName: selectedSectionName,
        });
      }
    }
  }

  handleCloseAccordionOnTemplate(
    event,
    isAccordionOpen,
    sectionID,
    questionID,
    parentQuestionID
  ) {
    let selectedSectionName = '';
    if (isAccordionOpen) {
      let updatedSections = [];
      // Case - Section
      if (sectionID && !questionID && !parentQuestionID) {
        updatedSections = this.state.templateSections.map((section) => {
          if (section.sectionID === sectionID) {
            const sectionHighlightClass = this.state.isEditLayoutMode
              ? ''
              : 'highlightSelectedSection';
            selectedSectionName = section.sectionName;
            return {
              ...section,
              isAccordionOpen: false,
              sectionHighlightClass: sectionHighlightClass,
            };
          } else {
            return {
              ...section,
              sectionHighlightClass: '',
            };
          }
        });
      } else if (sectionID && questionID && !parentQuestionID) {
        // Case - Parent Question
        updatedSections = this.state.templateSections.map((section) => {
          if (section.sectionID === sectionID) {
            let questions = [];
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                if (question.questionID === questionID) {
                  return {
                    ...question,
                    isAccordionOpen: false,
                  };
                } else {
                  return question;
                }
              });
            }
            return {
              ...section,
              questions: questions,
            };
          } else {
            return section;
          }
        });
      } else if (sectionID && questionID && parentQuestionID) {
        // Case - Child Question
        updatedSections = this.state.templateSections.map((section) => {
          if (section.sectionID === sectionID) {
            let questions = [];
            if (section.questions.length > 0) {
              questions = section.questions.map((question) => {
                if (question.questionID === parentQuestionID) {
                  let childQuestions = [];
                  if (question.childQuestions.length > 0) {
                    childQuestions = question.childQuestions.map(
                      (childQuestion) => {
                        if (childQuestion.questionID === questionID) {
                          return {
                            ...childQuestion,
                            isAccordionOpen: false,
                          };
                        } else {
                          return childQuestion;
                        }
                      }
                    );
                  }

                  return {
                    ...question,
                    childQuestions: childQuestions,
                  };
                } else {
                  return question;
                }
              });
            }

            return {
              ...section,
              questions: questions,
            };
          } else {
            return section;
          }
        });
      }
      this.setState({
        templateSections: updatedSections
      });

      // Check whether selected Section is containing Question of type "psq section 2 additional services" or "psq section 2 property manager"
      // If yes then disable the "New Question" button
      let preventAddingQuestion = this.preventDragAndDrop(sectionID)
      this.setState({
        isNewQuestionForSectionEnabled: !this.state.isEditLayoutMode && !preventAddingQuestion
      });

      if (selectedSectionName !== '') {
        this.setState({
          selectedSectionId: sectionID,
          selectedSectionName: selectedSectionName,
        });
      }
    }
  }

  handleToggleExpandCollapseAllAcordionsOnAvailableQuestions(
    event,
    expandOrCollapse
  ) {
    if (expandOrCollapse === 'expand') {
      const availableQuestions = this.state.availableQuestionDetail.map(
        (question) => {
          let childQuestions = [];

          if (question.childQuestions.length > 0) {
            childQuestions = question.childQuestions.map((childQuestion) => {
              return {
                ...childQuestion,
                isAccordionOpen: true,
              };
            });
          }

          return {
            ...question,
            isAccordionOpen: true,
            childQuestions: childQuestions,
          };
        }
      );

      const allAvailableQuestions = this.state.allAvailableQuestions.map(
        (question) => {
          let childQuestions = [];

          if (question.childQuestions.length > 0) {
            childQuestions = question.childQuestions.map((childQuestion) => {
              return {
                ...childQuestion,
                isAccordionOpen: true,
              };
            });
          }

          return {
            ...question,
            isAccordionOpen: true,
            childQuestions: childQuestions,
          };
        }
      );

      this.setState({
        expandAllAccordionsOnAvailableQuestions: true,
        availableQuestionDetail: availableQuestions,
        allAvailableQuestions: allAvailableQuestions,
      });
    } else {
      const availableQuestions = this.state.availableQuestionDetail.map(
        (question) => {
          let childQuestions = [];

          if (question.childQuestions.length > 0) {
            childQuestions = question.childQuestions.map((childQuestion) => {
              return {
                ...childQuestion,
                isAccordionOpen: false,
              };
            });
          }

          return {
            ...question,
            isAccordionOpen: false,
            childQuestions: childQuestions,
          };
        }
      );

      const allAvailableQuestions = this.state.allAvailableQuestions.map(
        (question) => {
          let childQuestions = [];

          if (question.childQuestions.length > 0) {
            childQuestions = question.childQuestions.map((childQuestion) => {
              return {
                ...childQuestion,
                isAccordionOpen: false,
              };
            });
          }

          return {
            ...question,
            isAccordionOpen: false,
            childQuestions: childQuestions,
          };
        }
      );

      this.setState({
        expandAllAccordionsOnAvailableQuestions: false,
        availableQuestionDetail: availableQuestions,
        allAvailableQuestions: allAvailableQuestions,
      });
    }
  }

  handleOpenAccordionOnAvailableQuestions(
    event,
    isAccordionOpen,
    questionID,
    parentQuestionID
  ) {
    if (!isAccordionOpen) {
      let updatedAvailableQuestions = [];
      let updatedAllAvailableQuestions = [];
      // Case - Parent Question
      if (questionID && !parentQuestionID) {
        updatedAvailableQuestions = this.state.availableQuestionDetail.map(
          (question) => {
            if (question.questionID === questionID) {
              return {
                ...question,
                isAccordionOpen: true,
              };
            } else {
              return question;
            }
          }
        );

        updatedAllAvailableQuestions = this.state.allAvailableQuestions.map(
          (question) => {
            if (question.questionID === questionID) {
              return {
                ...question,
                isAccordionOpen: true,
              };
            } else {
              return question;
            }
          }
        );
      }
      // Case - Child Question
      else if (questionID && parentQuestionID) {
        updatedAvailableQuestions = this.state.availableQuestionDetail.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = [];

              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    if (childQuestion.questionID === questionID) {
                      return {
                        ...childQuestion,
                        isAccordionOpen: true,
                      };
                    } else {
                      return childQuestion;
                    }
                  }
                );

                return {
                  ...question,
                  childQuestions: childQuestions,
                };
              }
            } else {
              return question;
            }
          }
        );

        updatedAllAvailableQuestions = this.state.allAvailableQuestions.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = [];

              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    if (childQuestion.questionID === questionID) {
                      return {
                        ...childQuestion,
                        isAccordionOpen: true,
                      };
                    } else {
                      return childQuestion;
                    }
                  }
                );

                return {
                  ...question,
                  childQuestions: childQuestions,
                };
              }
            } else {
              return question;
            }
          }
        );
      }

      this.setState({
        availableQuestionDetail: updatedAvailableQuestions,
        allAvailableQuestions: updatedAllAvailableQuestions,
      });
    }
  }

  handleCloseAccordionOnAvailableQuestions(
    _event,
    isAccordionOpen,
    questionID,
    parentQuestionID
  ) {
    if (isAccordionOpen) {
      let updatedAvailableQuestions = [];
      let updatedAllAvailableQuestions = [];
      // Case - Parent Question
      if (questionID && !parentQuestionID) {
        updatedAvailableQuestions = this.state.availableQuestionDetail.map(
          (question) => {
            if (question.questionID === questionID) {
              return {
                ...question,
                isAccordionOpen: false,
              };
            } else {
              return question;
            }
          }
        );

        updatedAllAvailableQuestions = this.state.allAvailableQuestions.map(
          (question) => {
            if (question.questionID === questionID) {
              return {
                ...question,
                isAccordionOpen: false,
              };
            } else {
              return question;
            }
          }
        );
      }
      // Case - Child Question
      else if (questionID && parentQuestionID) {
        updatedAvailableQuestions = this.state.availableQuestionDetail.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = [];

              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    if (childQuestion.questionID === questionID) {
                      return {
                        ...childQuestion,
                        isAccordionOpen: false,
                      };
                    } else {
                      return childQuestion;
                    }
                  }
                );

                return {
                  ...question,
                  childQuestions: childQuestions,
                };
              }
            } else {
              return question;
            }
          }
        );

        updatedAllAvailableQuestions = this.state.allAvailableQuestions.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = [];

              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    if (childQuestion.questionID === questionID) {
                      return {
                        ...childQuestion,
                        isAccordionOpen: false,
                      };
                    } else {
                      return childQuestion;
                    }
                  }
                );

                return {
                  ...question,
                  childQuestions: childQuestions,
                };
              }
            } else {
              return question;
            }
          }
        );
      }

      this.setState({
        availableQuestionDetail: updatedAvailableQuestions,
        allAvailableQuestions: updatedAllAvailableQuestions,
      });
    }
  }

  reRenderTemplateSectionOnAddNewChildQuestion(
    questionID,
    parentQuestionID,
    isQuestionLocked
  ) {
    let isQuestionAvailableInSection = false;
    let availableQuestionSectionId = 0;

    for (let i = 0; i < this.state.templateSections.length; i++) {
      if (this.state.templateSections[i].questions?.length > 0) {
        const questions = this.state.templateSections[i].questions.filter(
          (q) => q.questionID === parentQuestionID
        );
        if (questions.length === 1) {
          isQuestionAvailableInSection = true;
          availableQuestionSectionId = this.state.templateSections[i].sectionID;
          break;
        }
      }
    }

    if (isQuestionAvailableInSection) {
      this.props.customChecklistTemplateActions
        .fetchQuestionById(
          this.props.clientId,
          this.state.checklistTypeID,
          this.state.templateId,
          questionID
        )
        .then((data) => {
          const updatedSections = this.state.templateSections.map((section) => {
            if (section.sectionID === availableQuestionSectionId) {
              const questions = section.questions.map((question) => {
                if (question.questionID === parentQuestionID) {
                  const currentQuestionSortOrder = question.questionSortOrder;
                  if (isQuestionLocked) {
                    // Standing question scenario
                    const [first] = data;
                    let updatedQuestion = { ...first };
                    for (
                      let index = 0;
                      index < updatedQuestion.childQuestions.length;
                      index++
                    ) {
                      updatedQuestion.childQuestions[index].questionSortOrder =
                        index + 1;
                    }
                    updatedQuestion.questionSortOrder =
                      currentQuestionSortOrder;
                    return updatedQuestion;
                  } else {
                    // Custom Question Scenario
                    const childQuestions = question.childQuestions.slice();
                    const questionSortOrder = childQuestions.length + 1;
                    data[0].parentQuestionID = parentQuestionID;
                    data[0].questionSortOrder = questionSortOrder;
                    childQuestions.push(...data);

                    return {
                      ...question,
                      childQuestions: childQuestions,
                    };
                  }
                } else {
                  return question;
                }
              });
              return {
                ...section,
                questions: questions,
              };
            } else {
              return section;
            }
          });

          this.setState({
            templateSections: updatedSections,
            isTemplateDirty: true,
          });
        });
    }
  }

  reRenderTemplateSectionOnEditParentQuestion(
    questionID,
    convertedParentQuestionID,
    isStandardQuestion
  ) {
    let isQuestionAvailableInSection = false;
    let availableQuestionSectionId = 0;
    let retainedChilQuestions = [];

    for (let i = 0; i < this.state.templateSections.length; i++) {
      if (this.state.templateSections[i].questions?.length > 0) {
        const questions = this.state.templateSections[i].questions.filter(
          (q) => q.questionID === questionID
        );
        if (questions.length === 1) {
          const [filteredQuestion] = questions;
          retainedChilQuestions = filteredQuestion.childQuestions.slice();
          isQuestionAvailableInSection = true;
          availableQuestionSectionId = this.state.templateSections[i].sectionID;
          break;
        }
      }
    }

    if (isQuestionAvailableInSection) {
      this.props.customChecklistTemplateActions
        .fetchQuestionById(
          this.props.clientId,
          this.state.checklistTypeID,
          this.state.templateId,
          isStandardQuestion ? convertedParentQuestionID : questionID
        )
        .then((data) => {
          const updatedSections = this.state.templateSections.map((section) => {
            if (section.sectionID === availableQuestionSectionId) {
              const questions = section.questions.map((question) => {
                if (question.questionID === questionID) {
                  const currentQuestionSortOrder = question.questionSortOrder;
                  if (isStandardQuestion) {
                    const [first] = data;
                    const updatedQuestion = { ...first };
                    updatedQuestion.questionSortOrder =
                      currentQuestionSortOrder;

                    for (
                      let index = 0;
                      index < updatedQuestion.childQuestions.length;
                      index++
                    ) {
                      updatedQuestion.childQuestions[index].questionSortOrder =
                        index + 1;
                    }

                    return updatedQuestion;
                  } else {
                    const [first] = data;
                    const updatedQuestion = { ...first };
                    updatedQuestion.questionSortOrder =
                      currentQuestionSortOrder;
                    updatedQuestion.childQuestions = retainedChilQuestions;
                    return updatedQuestion;
                  }
                } else {
                  return question;
                }
              });

              return {
                ...section,
                questions: questions,
              };
            } else {
              return section;
            }
          });

          this.setState({
            templateSections: updatedSections,
            isTemplateDirty: true,
          });
        });
    }
  }

  reRenderTemplateSectionOnEditChildQuestion(
    questionID,
    parentQuestionID,
    convertedParentQuestionID,
    isStandardQuestion
  ) {
    let isChildQuestionAvailableInSection = false;
    let availableQuestionSectionId = 0;
    let checklistTemplateSectionQuestionID = 0;
    let questionSortOrder = 0;

    for (let i = 0; i < this.state.templateSections.length; i++) {
      if (this.state.templateSections[i].questions?.length > 0) {
        const question = this.state.templateSections[i].questions.filter(
          (q) => q.questionID === parentQuestionID
        );
        if (question.length === 1) {
          const [filteredQuestion] = question;
          const childQuestions = filteredQuestion.childQuestions.filter(
            (c) => c.questionID === questionID
          );
          if (childQuestions.length === 1) {
            isChildQuestionAvailableInSection = true;
            availableQuestionSectionId =
              this.state.templateSections[i].sectionID;
            checklistTemplateSectionQuestionID =
              childQuestions[0].checklistTemplateSectionQuestionID;
            questionSortOrder = childQuestions[0].questionSortOrder;
            break;
          }
        }
      }
    }

    if (isChildQuestionAvailableInSection) {
      this.props.customChecklistTemplateActions
        .fetchQuestionById(
          this.props.clientId,
          this.state.checklistTypeID,
          this.state.templateId,
          isStandardQuestion ? convertedParentQuestionID : questionID
        )
        .then((data) => {
          const updatedSections = this.state.templateSections.map((section) => {
            if (section.sectionID === availableQuestionSectionId) {
              const questions = section.questions.map((question) => {
                if (question.questionID === parentQuestionID) {
                  const currentQuestionSortOrder = question.questionSortOrder;
                  if (isStandardQuestion) {
                    const [first] = data;
                    const convertedQuestion = { ...first };
                    convertedQuestion.questionSortOrder =
                      currentQuestionSortOrder;

                    for (
                      let index = 0;
                      index < convertedQuestion.childQuestions.length;
                      index++
                    ) {
                      convertedQuestion.childQuestions[
                        index
                      ].questionSortOrder = index + 1;
                    }

                    return convertedQuestion;
                  } else {
                    let childQuestions = [];
                    if (question.childQuestions.length > 0) {
                      childQuestions = question.childQuestions.map(
                        (childQuestion) => {
                          if (childQuestion.questionID === questionID) {
                            const [first] = data;
                            const editedQuestion = { ...first };
                            editedQuestion.parentQuestionID = parentQuestionID;
                            editedQuestion.checklistTemplateSectionQuestionID =
                              checklistTemplateSectionQuestionID;
                            editedQuestion.questionSortOrder =
                              questionSortOrder;
                            return editedQuestion;
                          } else {
                            return childQuestion;
                          }
                        }
                      );
                    }

                    return {
                      ...question,
                      childQuestions: childQuestions,
                    };
                  }
                } else {
                  return question;
                }
              });

              return {
                ...section,
                questions: questions,
              };
            } else {
              return section;
            }
          });

          this.setState({ templateSections: updatedSections });
        });
    }
  }

  reRenderTemplateSectionOnAddNewParentQuestion(questionID, sectionID) {
    this.props.customChecklistTemplateActions
      .fetchQuestionById(
        this.props.clientId,
        this.state.checklistTypeID,
        this.state.templateId,
        questionID
      )
      .then((data) => {
        const updatedSections = this.state.templateSections.map((section) => {
          if (section.sectionID === sectionID) {
            const questions = section.questions.slice();
            const sectionsLength = section.questions.length;

            const [first] = data;
            const questionTobeAdded = { ...first };
            questionTobeAdded.questionSortOrder = sectionsLength + 1;
            questionTobeAdded.category = 'sectionquestion';
            questionTobeAdded.sectionID = section.sectionID;
            questionTobeAdded.sectionName = section.sectionName;
            questionTobeAdded.sectionSortOrder = section.sectionSortOrder;
            questionTobeAdded.isAccordionOpen = false;

            questions.push(questionTobeAdded);

            return {
              ...section,
              questions: questions,
            };
          } else {
            return section;
          }
        });

        this.setState({
          templateSections: updatedSections,
          isTemplateDirty: true,
        });
      });
  }

  reRenderAvailableQuestionsOnAddNewParentQuestion(questionID, sectionID) {
    this.props.customChecklistTemplateActions
      .fetchQuestionById(
        this.props.clientId,
        this.state.checklistTypeID,
        sectionID !== null ? this.state.templateId : null,
        questionID
      )
      .then((data) => {
        const availableQuestionsDetail =
          this.state.availableQuestionDetail.slice();
        availableQuestionsDetail.push(...data);
        this.setState({ availableQuestionDetail: availableQuestionsDetail });

        // Updating the entire question collection
        const allAvailableQuestions = this.state.allAvailableQuestions.slice();
        allAvailableQuestions.push(...data);

        this.setState({ allAvailableQuestions: allAvailableQuestions });
      });
  }

  reRenderAvailableQuestionsOnAddNewChilQuestion(
    questionID,
    parentQuestionID,
    sectionID
  ) {
    this.props.customChecklistTemplateActions
      .fetchQuestionById(
        this.props.clientId,
        this.state.checklistTypeID,
        sectionID !== null ? this.state.templateId : null,
        questionID
      )
      .then((data) => {
        // Updating the local filtered array
        const availableQuestions = this.state.availableQuestionDetail.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = question.childQuestions.slice();
              const [first] = data;
              first.parentQuestionID = parentQuestionID;
              childQuestions.push(first);
              return {
                ...question,
                childQuestions: childQuestions,
              };
            } else {
              return question;
            }
          }
        );
        this.setState({ availableQuestionDetail: availableQuestions });

        // Updating the entire questions collection
        const allAvailableQuestions = this.state.allAvailableQuestions.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = question.childQuestions.slice();
              const [first] = data;
              first.parentQuestionID = parentQuestionID;
              childQuestions.push(first);
              return {
                ...question,
                childQuestions: childQuestions,
              };
            } else {
              return question;
            }
          }
        );

        this.setState({ allAvailableQuestions: allAvailableQuestions });
      });
  }

  reRenderAvailableQuestionsOnEditParentQuestion(questionID, sectionID) {
    this.props.customChecklistTemplateActions
      .fetchQuestionById(
        this.props.clientId,
        this.state.checklistTypeID,
        sectionID !== null ? this.state.templateId : null,
        questionID
      )
      .then((data) => {
        const availableQuestionsDetail = this.state.availableQuestionDetail.map(
          (question) => {
            if (question.questionID === questionID) {
              const [first] = data;
              return first;
            } else {
              return question;
            }
          }
        );

        this.setState({ availableQuestionDetail: availableQuestionsDetail });

        // Updating the entire question collection
        const allAvailableQuestions = this.state.allAvailableQuestions.map(
          (question) => {
            if (question.questionID === questionID) {
              const [first] = data;
              return first;
            } else {
              return question;
            }
          }
        );

        this.setState({ allAvailableQuestions: allAvailableQuestions });
      });
  }

  reRenderAvailableQuestionsOnEditChilQuestion(
    questionID,
    parentQuestionID,
    sectionID
  ) {
    this.props.customChecklistTemplateActions
      .fetchQuestionById(
        this.props.clientId,
        this.state.checklistTypeID,
        sectionID !== null ? this.state.templateId : null,
        questionID
      )
      .then((data) => {
        const availableQuestionsDetail = this.state.availableQuestionDetail.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = [];
              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    if (childQuestion.questionID === questionID) {
                      const [first] = data;
                      first.parentQuestionID = parentQuestionID;
                      return first;
                    } else {
                      return childQuestion;
                    }
                  }
                );
              }

              return {
                ...question,
                childQuestions: childQuestions,
              };
            } else {
              return question;
            }
          }
        );

        this.setState({ availableQuestionDetail: availableQuestionsDetail });

        // Updating the entire question collection
        const allAvailableQuestions = this.state.allAvailableQuestions.map(
          (question) => {
            if (question.questionID === parentQuestionID) {
              let childQuestions = [];
              if (question.childQuestions.length > 0) {
                childQuestions = question.childQuestions.map(
                  (childQuestion) => {
                    if (childQuestion.questionID === questionID) {
                      const [first] = data;
                      return first;
                    } else {
                      return childQuestion;
                    }
                  }
                );
              }

              return {
                ...question,
                childQuestions: childQuestions,
              };
            } else {
              return question;
            }
          }
        );

        this.setState({ allAvailableQuestions: allAvailableQuestions });
      });
  }

  handleOnChangeOfTemplateName(event, value) {
    if (this.props.initialTemplateName !== value) {
      this.setState({ hasTemplateNameChanged: true });
    } else {
      this.setState({ hasTemplateNameChanged: false });
    }
  }

  handleDeleteExistingFile(attachmentId) {

    let attachmentFiles = JSON.parse(JSON.stringify(this.state.attachmentData));
    attachmentFiles.find(file => file.questionAttachmentID == attachmentId).isDeleted = true;
    this.setState({ attachmentData: attachmentFiles, isQuestionFormDirty: true });

  }

  handleDownloadAttachmentClick(attachmentGuid, attachmentName) {
    return fileActions.downloadCustomQuestionAttachment(this.props.clientId, attachmentGuid, encodeURIComponent(attachmentName), null);

  }

  handleCancelDeleteTemplateQuestion() {
    const successStateObj = {
      showDeleteTemplateQuestionModal:
        !this.state.showDeleteTemplateQuestionModal,
      templateQuestionToBeDeleted: {},
      templateQuestionToBeDeletedHasChildQuestions: false,
    };

    this.releaseIntentLockForCustomQuestion(this.state.templateQuestionToBeDeleted.questionID,
      this.state.templateId,
      this.props.clientId,
      successStateObj);
  }

  handleDeleteTemplateQuestion() {
    const successStateObj = {};
    this.getLockStatusForQuestion(this.state.templateQuestionToBeDeleted.questionID, this.state.templateId, this.props.clientId, 'Delete', successStateObj, this.deleteTemplateQuestion);
  }

  deleteTemplateQuestion() {
    const checklistTemplateSectionID = this.state.templateQuestionToBeDeleted.checklistTemplateSectionID;
    const questionID = this.state.templateQuestionToBeDeleted.questionID;
    const parentQuestionID = this.state.templateQuestionToBeDeleted.parentQuestionID;

    this.props.customQuestionActions
      .deleteCustomTemplateQuestion(
        questionID,
        parentQuestionID,
        this.props.clientId,
        checklistTemplateSectionID
      )
      .then(() => {
        this.setState({
          showDeleteTemplateQuestionModal:
            false,
          templateQuestionToBeDeleted: {},
          templateQuestionToBeDeletedHasChildQuestions: false,
        });
        this.fetchTemplateDataById(this.state.templateId);
      })
      .catch(() =>
        this.setState({
          showDeleteTemplateQuestionModal:
            false,
          templateQuestionToBeDeleted: {},
          templateQuestionToBeDeletedHasChildQuestions: false,
        })
      );
  }

  handleCancelEditTemplateQuestion() {
    this.setState({
      showEditTemplateQuestionModal: !this.state.showEditTemplateQuestionModal,
      templateQuestionToBeEdited: {},
    });
  }

  handleEditTemplateQuestion() {
    this.getQuestionById(this.state.templateQuestionToBeEdited);
  }

  toggleShowDeleteLastSectionWarningModal() {
    this.setState({ showDeleteLastSectionWarningModal: !this.state.showDeleteLastSectionWarningModal });
  }

  toggleShowAttachmentStatus() {
    this.setState({ showAttachmentStatusModal: !this.state.showAttachmentStatusModal });
  }


  addFilesToQueueUnique(newFiles) {
    const prevQueue = Object.assign([], this.state.fileAttachmentList);
    const ids = new Set(prevQueue.map(prevFile => prevFile.path));
    const merged = [...prevQueue, ...newFiles.filter(newFile => !ids.has(newFile.path))];
    return merged;
  }

  /**
   * Updates the queue with the new files dropped in
   *
   * @param {array} newFiles - new files to be added to the queue
   * @param {func} setQueue - function that updates the queue
   */
  handleDrop(newFiles) {
    const list = this.addFilesToQueueUnique(newFiles.filter(file => file.error === undefined || file.error === ''));
    this.setState({ fileAttachmentList: list, isQuestionFormDirty: true });
  }

  /**
   * Updates the queue with the rejected new files dropped in
   *
   * @param {array} newFiles - new files to be added to the queue
   * @param {func} setQueue - function that updates the queue
   */
  handleDropRejected(newFiles) {
    const list = this.addFilesToQueueUnique(newFiles);
    this.setState({ fileAttachmentList: list, isQuestionFormDirty: true });
  }

  /**
   * Removes a single file from the queue
   *
   * @param {array} queue - current list of files to be uploaded
   * @param {func} setQueue - function that updates the queue
   * @param {Object} removedFile - the file to be removed from the queue
   */
  removeFile(removedFile) {
    const prevQueue = Object.assign([], this.state.fileAttachmentList);
    const nextQueue = prevQueue.filter(file => (file.path !== undefined ? file.path : file.file?.path) !== (removedFile.path !== undefined ? removedFile.path : removedFile.file?.path));
    this.setState({ fileAttachmentList: nextQueue });
    if ((!nextQueue.length && !this.state.attachmentData.length) || (!nextQueue.length && !this.state.attachmentData.filter(x => x.isDeleted == true).length)) {
      this.setState({ isQuestionFormDirty: false });
    }
  }

  handleContinueChildQuestionsRearrangeWarningModal() {
    this.updateChildQuestionSortOrder();
  }

  handleCancelStandardChildQuestionsRearrangeWarningModal() {
    this.setState({
      showStandardChildQuestionsRearrangeWarningModal: false,
      parentQuestionOfTheChildQuestionsRearranged: null,
      tempTemplateStateOnChildQuestionRearrange: {},
      tempisTemplateDirtyStateOnChildQuestionRearrange: false
    });
  }

  handleClearSearchText() {
    this.setState({ searchQuestionText: '' });
    let question = this.filterShowStandardQuestion(
      this.state.showStandardQuestions,
      Object.assign([], this.state.allAvailableQuestions)
    );
    this.setState({ availableQuestionDetail: question });
  }

  render() {
    if (!this.state.isEditMode) {
      return (
        <div>
          <PsqCustomChecklistTemplateHeader
            className="psq-custom-checklist-template-header"
            isEditMode={this.state.isEditMode}
            existingTemplates={this.props.existingTemplates}
            isTemplateDirty={this.state.isTemplateDirty}
            onSubmit={this.handleTemplateSave}
            handleTemplateReset={this.handleTemplateReset}
            readOnlyModeForUser={this.state.isTemplateEditedByOtherUser && this.state.isTemplateEditedUserActive}
          />
        </div>
      );
    } else {
      return (
        <div>
          <PsqCustomChecklistTemplateHeader
            className="psq-custom-checklist-template-header"
            isEditMode={this.state.isEditMode}
            existingTemplates={this.props.existingTemplates}
            isTemplateDirty={this.state.isTemplateDirty}
            onSubmit={this.handleTemplateSave}
            handleTemplateReset={this.handleTemplateReset}
            isCommitted={this.props.isCommitted}
            handleOnChangeOfTemplateName={this.handleOnChangeOfTemplateName}
            hasTemplateNameChanged={this.state.hasTemplateNameChanged}
            readOnlyModeForUser={this.state.isTemplateEditedByOtherUser && this.state.isTemplateEditedUserActive}
          />
          <div className="pt-2" style={{ display: 'flex' }}>
            <PsqCustomChecklistTemplate
              templateName={this.state.templateName}
              sectionsWithQuestions={this.state.templateSections}
              handleDeleteClick={this.handleDeleteClick}
              handleEditClick={this.handleEditClick}
              handleAddNewChildQuestion={this.handleAddNewChildQuestion}
              handleSectionQuestionDragStart={
                this.handleSectionQuestionDragStart
              }
              handleDownloadAttachmentClick={this.handleDownloadAttachmentClick}
              handleSectionQuestionDragOver={this.handleSectionQuestionDragOver}
              handleQuestionDropComponent={this.handleQuestionDropComponent}
              handleQuestionDropPanel={this.handleQuestionDropPanel}
              handleSectionDragStart={this.handleSectionDragStart}
              handleSectionDragOver={this.handleSectionDragOver}
              handleAvailableQuestionDragStart={
                this.handleAvailableQuestionDragStart
              }
              handleSectionChildQuestionDragStart={
                this.handleSectionChildQuestionDragStart
              }
              handleSectionCommentDragStart={this.handleSectionCommentDragStart}
              dropCategory="sectionquestion"
              expandAllAccordionsOnTemplate={
                this.state.expandAllAccordionsOnTemplate
              }
              handleToggleExpandCollapseAllAcordionsOnTemplate={
                this.handleToggleExpandCollapseAllAcordionsOnTemplate
              }
              handleOpenAccordionOnTemplate={this.handleOpenAccordionOnTemplate}
              handleCloseAccordionOnTemplate={
                this.handleCloseAccordionOnTemplate
              }
              visibleQuestionTypes={this.props.lookupTypes.questionTypes || []}
              isTemplateEditedByOtherUser={this.state.isTemplateEditedByOtherUser}
            />
            <AvailableQuestions
              handleShowStandardQuestions={this.handleShowStandardQuestions}
              showStandardQuestions={this.state.showStandardQuestions}
              handleSearchQuestion={this.handleSearchQuestion}
              searchQuestionText={this.state.searchQuestionText}
              selectedAvailableQuestionSearchBy={
                this.state.selectedAvailableQuestionSearchBy
              }
              createAvailableQuestionsSearchBy={
                this.createAvailableQuestionsSearchBy
              }
              handleAvailableQuestionsSearchBy={
                this.handleAvailableQuestionsSearchBy
              }
              questions={this.state.availableQuestionDetail}
              handleAddNewChildQuestion={this.handleAddNewChildQuestion}
              handleEditClick={this.handleEditClick}
              handleAvailableQuestionDragStart={
                this.handleAvailableQuestionDragStart
              }
              handleAvailableQuestionDragOver={
                this.handleAvailableQuestionDragOver
              }
              handleQuestionDropComponent={this.handleQuestionDropComponent}
              handleQuestionDropPanel={this.handleQuestionDropPanel}
              handleSectionQuestionDragStart={
                this.handleSectionQuestionDragStart
              }
              dropCategory="availablequestion"
              expandAllAccordionsOnAvailableQuestions={
                this.state.expandAllAccordionsOnAvailableQuestions
              }
              handleToggleExpandCollapseAllAcordionsOnAvailableQuestions={
                this.handleToggleExpandCollapseAllAcordionsOnAvailableQuestions
              }
              handleOpenAccordionOnAvailableQuestions={
                this.handleOpenAccordionOnAvailableQuestions
              }
              handleCloseAccordionOnAvailableQuestions={
                this.handleCloseAccordionOnAvailableQuestions
              }
              visibleQuestionTypes={this.props.lookupTypes.questionTypes || []}
              isTemplateEditedByOtherUser={this.state.isTemplateEditedByOtherUser}
              handleDownloadAttachmentClick={this.handleDownloadAttachmentClick}
              handleClearSearchText={this.handleClearSearchText}
            />
          </div>
          <div className="psq-custom-checklist-template-footer">
            <PsqCustomChecklistTemplateFooter
              toggleAddSectionModal={this.handleToggleAddSectionModal}
              handleOnClickCreateNewQuestion={
                this.handleOnClickCreateNewQuestion
              }
              isNewQuestionForSectionEnabled={
                this.state.isNewQuestionForSectionEnabled
              }
              isTemplateEditedByOtherUser={this.state.isTemplateEditedByOtherUser}
            />
          </div>
          <AddEditCustomQuestionModal
            showAddEditCustomQuestionModal={
              this.state.showAddEditCustomQuestionModal
            }
            onSubmit={this.handleSubmitCustomQuestionModalAction}
            toggleShowAddEditCustomQuestionModalAndReleaseIntentLock={
              this.toggleShowAddEditCustomQuestionModalAndReleaseIntentLock
            }
            toggleShowAddEditCustomQuestionModal={this.toggleShowAddEditCustomQuestionModal}
            formPurpose={this.state.questionFormPurpose}
            isChildQuestion={this.state.isChildQuestion}
            currentPicklistItems={this.state.currentQuestionPicklistItems}
            parentQuestionTypeID={this.state.parentQuestionTypeID}
            parentQuestionPicklistItems={this.state.parentQuestionPicklistItems}
            questionTypes={this.props.lookupTypes.questionTypes}
            handleChangePicklistItem={this.handleChangePicklistItem}
            isQuestionFormDirty={this.state.isQuestionFormDirty}
            initialValues={this.state.questionModalInitialValues}
            fileAttachmentList={this.state.fileAttachmentList}
            handleDrop={this.handleDrop}
            handleDropRejected={this.handleDropRejected}
            removeFile={this.removeFile}
            handleDeleteExistingFile={this.handleDeleteExistingFile}
            handleDownloadAttachmentClick={this.handleDownloadAttachmentClick}
            existingAttachmentData={this.state.attachmentData}
            startIntentLockTimer={this.state.startIntentLockTimer}
            intentLockExpirationTime={this.state.intentLockExpirationTime}
            currentQuestionIdinWorks={this.state.currentQuestionIdinWorks}
            checklistTemplateID={this.state.templateId}
            clientID={this.props.clientId}
            isSaving={this.state.isSaving}
          />
          <AddSectionModal
            showAddSectionModal={this.state.showAddSectionModal}
            handleAddSection={this.handleAddSection}
            toggleAddSectionModal={this.handleToggleAddSectionModal}
            sectionDetail={this.state.sectionDetail}
          />

          <DeleteTemplateQuestionModal
            showDeleteTemplateQuestionModal={
              this.state.showDeleteTemplateQuestionModal
            }
            hasChildQuestions={
              this.state.templateQuestionToBeDeletedHasChildQuestions
            }
            handleCancelDeleteTemplateQuestion={
              this.handleCancelDeleteTemplateQuestion
            }
            handleDeleteTemplateQuestion={this.handleDeleteTemplateQuestion}
          />
          <TemplateConfirmEditQuestionModal
            showEditTemplateQuestionModal={
              this.state.showEditTemplateQuestionModal
            }
            handleCancelEditTemplateQuestion={
              this.handleCancelEditTemplateQuestion
            }
            handleEditTemplateQuestion={this.handleEditTemplateQuestion}
          />
          <DeleteLastSectionWarningModal
            showDeleteLastSectionWarningModal={this.state.showDeleteLastSectionWarningModal}
            toggleShowDeleteLastSectionWarningModal={this.toggleShowDeleteLastSectionWarningModal}
          />
          <CustomQuestionAttachmentListUploadStatus
            showAttachmentStatusModal={this.state.showAttachmentStatusModal}
            toggleShowAttachmentStatus={this.toggleShowAttachmentStatus}
            uploadedAttachmentStatus={this.state.uploadedAttachmentStatus}
          />
          <QuestionLockedInfoModal
            showInfoModal={this.state.showQuestionLockedInfoModal}
            headerMessage={this.state.headerMessageForQuestionLockedInfoModal}
            content={this.state.contentForQuestionLockedInfoModal}
            handleCloseOnQuestionInfoModal={this.handleCloseOnQuestionInfoModal}
          />
          <StandardChildQuestionsRearrangeWarningModal
            showStandardChildQuestionsRearrangeWarningModal={this.state.showStandardChildQuestionsRearrangeWarningModal}
            handleContinueChildQuestionsRearrangeWarningModal={this.handleContinueChildQuestionsRearrangeWarningModal}
            handleCancelStandardChildQuestionsRearrangeWarningModal={this.handleCancelStandardChildQuestionsRearrangeWarningModal}
          />
        </div>
      );
    }
  }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
const mapStateToProps = (state, ownProps) => {
  let clientId = Number.parseInt(ownProps.router.params.clientId);
  if (isNaN(clientId)) {
    clientId = 0;
  }

  return {
    clientId: clientId,
    psqCustomChecklistTemplateValues: getFormValues(
      'psqCustomChecklistTemplate'
    )(state),
    formSyncErrors: getFormSyncErrors('psqCustomChecklistTemplate')(state),
    formSubmitErrors: getFormSubmitErrors('psqCustomChecklistTemplate')(state),
    initialValues: {
      templateId: state.customPsqTemplate.templateID,
      templateName: state.customPsqTemplate.templateName,
      templateInitializationType:
        state.customPsqTemplate.templateInitializationType,
      copiedFromTemplateName: state.customPsqTemplate.copiedFromTemplateName,
    },
    isCommitted: state.customPsqTemplate.isCommitted,
    existingTemplates: state.psqTemplates,
    templateSections: state.customPsqTemplate.sections,
    availableQuestions: state.availableQuestion.templates,
    createdTemplateId: state.createCustomPsqTemplate,
    lookupTypes: state.lookupTypes,
    availableQuestionByID: state.availableQuestionByID,
    initialTemplateName: state.customPsqTemplate.templateName,
    authentication: state.authentication,
    intentLockUpdateResult: state.customQuestionIntentLockUpdateResult,
    lockStatusResult: state.customQuestionLockStatusResultReducer,
  };
};

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
const mapDispatchToProps = (dispatch) => {
  return {
    customChecklistTemplateActions: bindActionCreators(
      customChecklistTemplateActions,
      dispatch
    ),
    customQuestionActions: bindActionCreators(customQuestionActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
  };
};

// Props for the container
PsqCustomChecklistTemplateContainer.propTypes = {
  clientId: PropTypes.number,
  psqCustomChecklistTemplateValues: PropTypes.object,
  initialValues: PropTypes.object,
  existingTemplates: PropTypes.array,
  templateSections: PropTypes.array,
  customChecklistTemplateActions: PropTypes.object,
  customQuestionActions: PropTypes.object,
  availableQuestions: PropTypes.array,
  createdTemplateId: PropTypes.number,
  isCommitted: PropTypes.bool,
  initialTemplateName: PropTypes.String,
  authentication: PropTypes.string,
  fileActions: PropTypes.object,
  intentLockUpdateResult: PropTypes.object,
  lockStatusResult: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
      form: 'psqCustomChecklistTemplate',
      enableReinitialize: true,
    })(PsqCustomChecklistTemplateContainer)
  )
);
