import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_BULK_REPORT_PACKAGES_SUCCESS action and sets bulk report packages data in the store
 * @param {Object} [state] The bulk report packages data currently in the store, or the initial state of  bulk report packages data
 * @param {Object} action The action to be handled
 * @returns {Object} The bulk report packages data
 */

function formatETA(etaInSeconds) {
    if (etaInSeconds === 0) {
        return "wrapping up";
    }

    const minutes = Math.floor(etaInSeconds / 60);
    const seconds = etaInSeconds % 60;
    const formattedETA = [];

    if (minutes > 0) {
        formattedETA.push(`${minutes} min`);
    }

    formattedETA.push(`${seconds} sec`);

    return `${formattedETA.join(' ')} remain`;
}

export default function bulkReportPackageReducer(state = initialState.bulkReportPackages, action) {
    if (action.type == actions.LOAD_BULK_REPORT_PACKAGES_SUCCESS && Array.isArray(action.bulkReportPackages)) {
        return {
            ...state,
            bulkReportPackages: action.bulkReportPackages
        };
    } else if (action.type == actions.LOAD_BULK_REPORT_PACKAGES_FAILURE) {
        return {
            ...state,
            bulkReportPackages: initialState.bulkReportPackages
        };
    } else if (action.type == actions.RECEIVE_BULK_REPORT_PACKAGE_MESSAGE) {
        // When we receive a signalR message, update the status for that exact row
        // And if the row is not in the current list of bulk report packages, then toggle the reload flag, otherwise keep the flag the same.
        const foundPackage = state.bulkReportPackages.find(row => row.bulkReportPackageID === action.message.BulkReportPackageID);
        const toggleReloadBulkReportPackages = foundPackage ? state.toggleReloadBulkReportPackages : !state.toggleReloadBulkReportPackages;
       
        return {
            ...state,
            bulkReportPackages: state.bulkReportPackages.map(row => {
                if (row.bulkReportPackageID === action.message.BulkReportPackageID) {
                    return {
                        ...row,
                        bulkReportPackageStatusID: action.message.BulkReportPackageStatusID,
                        bulkReportStatusDescription: action.message.BulkReportStatusDescription
                    };
                }
                return row;
            }),
            toggleReloadBulkReportPackages
        };
    }
    else if (action.type == actions.UPDATE_BULK_REPORT_PACKAGE_ETA) {
        return {
            ...state,
            bulkReportPackages: state.bulkReportPackages.map(row => {
                const etaBulkReportPackage = action.etas.find((eta) => {
                    return eta.bulkReportPackageID == row.bulkReportPackageID
                });

                if (etaBulkReportPackage && (
                    row.bulkReportStatusDescription.toLowerCase().includes('queued') || row.bulkReportStatusDescription.toLowerCase().includes('in progress'))
                ) {

                    const etaIndex = action.etas.findIndex(item => item.bulkReportPackageID == row.bulkReportPackageID);
                    let eta = 0;                   

                    if (etaIndex === 0) {
                        eta = etaBulkReportPackage.estimatedTimeRemainingSeconds;
                    } else {
                        eta = etaBulkReportPackage.estimatedTimeRemainingSeconds;
                        for (let i = 0; i < etaIndex; i++) {
                            eta = eta + action.etas[i].estimatedTimeRemainingSeconds;
                        }
                    }

                    return {
                        ...row,
                        bulkReportStatusDescription: row.bulkReportStatusDescription.split('-')[0].trim() + ' - ' + formatETA(eta),
                    };
                }

                return row;
            })           
        };
    }
    else {
        return state;
    }
}
