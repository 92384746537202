import React from 'react';
import { Link } from 'react-router-dom';
import { MotifTextLink } from '@ey-xd/motif-react';

const NotFoundPage = () => {
    return (
        <div>
            <h5>
                404 Page Not Found
            </h5>
            <Link to="/"> <MotifTextLink variant='alt'>Go back to homepage</MotifTextLink></Link>
        </div>
    );
};

export default NotFoundPage;
