import React from 'react';
import { MotifTabNavigation, MotifTabControl} from '@ey-xd/motif-react';
import { Link } from 'react-router-dom';
import { reduxForm } from "redux-form";
import { withRouter } from "../../common/withRouter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../actions/authActions";
import * as externalUserDataVisibilityActions from '../../actions/externalUserDataVisibilityActions';
import ExternalUserDataVisibilityTable from '../../components/externalUserDataVisibility/ExternalUserDataVisibilityTable';
import ExternalUserDataVisibilityHeader from '../../components/externalUserDataVisibility/ExternalUserDataVisibilityHeader';
import PropTypes from 'prop-types';

export class ExternalUserDataVisibilityContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabBar1: 0,
            gridCurrentState: [],
            refreshGridexternalUserVisibilityData: {
              refreshGridOnCompletedAction: -1
            },
            externalUserVisibilityData: [],
            isSysAdmin: false,            
        };
        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.setGridColumnState = this.setGridColumnState.bind(this);
        this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
        this.handleHideExternalUserDataVisibility = this.handleHideExternalUserDataVisibility.bind(this);
        this.handleUnHideExternalUserDataVisibility = this.handleUnHideExternalUserDataVisibility.bind(this);
     
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */

    componentDidMount() {
      this.checkUserAuthorization();
      this.props.externalUserDataVisibilityActions.fetchExternalUserDataVisibility();
    }
    componentDidUpdate(prevProps) {
      if (this.props.externalUserVisibilityData && this.props.externalUserVisibilityData !== prevProps.externalUserVisibilityData) {
        this.setState({ externalUserVisibilityData: this.props.externalUserVisibilityData });
    }
    }
  
    /**
    * Invoked immediately before unmounting occurs
    * @returns {undefined}
    */
    componentWillUnmount() {
      // On unmount, clear the state to conserve memory
      this.setState({ externalUserVisibilityData: [] });
  
      // Clearing the redux state for external User Data Visibility
      this.props.externalUserDataVisibilityActions.fetchExternalUserDataVisibility();
    }
  

    checkUserAuthorization() {
        let isSysAdmin = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations);
        }
        this.setState({isSysAdmin: isSysAdmin});      
    }
  
    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    //Set current tab index
    handleClick(index) {
        this.setTabBar1(index);
    }  

    setGridColumnState() {
      var columnState = localStorage.getItem('ExternalUSerDataVisibilityColumnState');
      if (columnState) {
        this.setState({ gridColumnState: JSON.parse(columnState) });
      }
      else {
        this.setState({ gridColumnState: [{ colId: '-1' }] });
      }
    }

    getExternalUserDataVisibilityData() {
      this.props.externalUserDataVisibilityActions.fetchExternalUserDataVisibility();
  }

    refreshGrid(selectedData=[]) {  
      this.getExternalUserDataVisibilityData();
  
      const randomNumber = parseInt(Math.random() * (99999999 - 1)) + 1;
        if (
          selectedData &&
          Array.isArray(selectedData) &&
          selectedData.length > 0
        ) {
          this.setState({
            refreshGridexternalUserVisibilityData: {
              refreshGridOnCompletedAction: randomNumber
            },
          });
        } else {
          this.setState({
            refreshGridexternalUserVisibilityData: {
              refreshGridOnCompletedAction: randomNumber
            },
          });
        }
    }

    handleGridColumnStateChange(columnState) {
      this.setState({ gridColumnState: columnState });
  }

  handleGridChanges(gridRowData) {
    let rowData = [];
    rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
      return {
        clientID: x.clientID,
        clientName: x.clientName,
        reitid: x.reitid,
        reitName: x.reitName,
        reportPeriodID : x.reportPeriodID,
        reportPeriodDescription : x.reportPeriodDescription,
        isReportPeriodVisibleToExternalUsers:x.isReportPeriodVisibleToExternalUsers,
        isReportPeriodEnabledForSelection: x.isReportPeriodEnabledForSelection,
        reitSelection: x.reitSelection
      }
    });
    this.setState({ gridCurrentState: rowData });
  }

  
  handleHideExternalUserDataVisibility() {      
    let selectedData = this.state.gridCurrentState.filter(gridData => gridData.reitSelection)
        .map(gridData => {
            return {  
                reportPeriodID: gridData.reportPeriodID        
            }
        });
    var requestData = [];
    selectedData.forEach(function (item) {
        requestData.push({     
            id: item.reportPeriodID,         
        });
    });

    this.props.externalUserDataVisibilityActions.hideExternalUserDataVisibility(requestData).then(() => {     
        this.refreshGrid(selectedData);
    });
}

handleUnHideExternalUserDataVisibility() {    
    let selectedData = this.state.gridCurrentState.filter(gridData => gridData.reitSelection)
        .map(gridData => {
            return {  
                reportPeriodID: gridData.reportPeriodID        
            }
        });
    var requestData = [];
    selectedData.forEach(function (item) {
        requestData.push({       
            id: item.reportPeriodID,           
        });
    });  

    this.props.externalUserDataVisibilityActions.unHideExternalUserDataVisibility(requestData).then(() => {     
        this.refreshGrid(selectedData);
    });
}

    render() {
        // Non sys admins are unauthorized to access system admin page
        if (!this.state.isSysAdmin) {
          this.props.router.navigate(`/unauthorized`);
          return <span />;
        }
        return (
          <React.Fragment>
            <h2>System Administrators</h2>
            <MotifTabNavigation variant="default">
              <Link to={`/adminauth`} tabIndex="-1">
                <MotifTabControl role="tab" onClick={() => this.handleClick(1)}>
                  Manage System Administrators
                </MotifTabControl>
              </Link>
              <Link to={`/adminauth/dataDeletionDiagnostic`} >
                <MotifTabControl              
                  role="tab"                  
                  onClick={() => this.handleClick(2)}
                >
                  Data Deletion Diagnostic
                </MotifTabControl>
              </Link>
              <Link to={`/adminauth/externalUserDataVisibility`} tabIndex="-1">
                <MotifTabControl
                  className="motif-active"
                  role="tab"
                  autoFocus
                  onClick={() => this.handleClick(3)}
                >
                  External User Data Visibility Manager
                </MotifTabControl>
              </Link>
            </MotifTabNavigation>            
            <>
            <ExternalUserDataVisibilityHeader
            gridCurrentState={this.state.gridCurrentState}    
            handleHideExternalUserDataVisibility={this.handleHideExternalUserDataVisibility}
            handleUnHideExternalUserDataVisibility={this.handleUnHideExternalUserDataVisibility}/>
           <ExternalUserDataVisibilityTable
            externalUserVisibilityData={this.props.externalUserVisibilityData}
            handleGridChanges={this.handleGridChanges}
            gridColumnState={this.state.gridColumnState}
            handleGridColumnStateChange={this.handleGridColumnStateChange}
            refreshGridexternalUserVisibilityData={this.state.refreshGridexternalUserVisibilityData}/>
        {(this.props.externalUserVisibilityData == null || this.props.externalUserVisibilityData.length === 0) &&
          <div>No record found for external user data visibility.</div>}
      </>
          </React.Fragment>
        );
      }
    }

    ExternalUserDataVisibilityContainer.propTypes = {  
      clientId: PropTypes.number,
      externalUserVisibilityData: PropTypes.array,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    const clients = state.clients;   
    return {
        currentUserAuthorizations: state.currentUserAuthorizations,   
        externalUserVisibilityData: state.externalUserVisibilityData,     
    };
}
/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
      authActions: bindActionCreators(authActions, dispatch) ,
      externalUserDataVisibilityActions: bindActionCreators(externalUserDataVisibilityActions, dispatch),

  };
}


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        reduxForm({ form: "ExternalUserDataVisibilityContainer", enableReinitialize: true })(ExternalUserDataVisibilityContainer)
    )
);
    