import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles Authorization
 * @param {Object} [state] Authorization currently in the store
 * @param {Object} action The action to be handled
 * @returns {Object} Auth
 */
export default function currentUserAuthorizations(state = initialState.currentUserAuthorizations, action) {
    switch (action.type) {
        case actions.LOAD_CURRENT_USER_AUTHORIZATIONS_SUCCESS:
            
            return action.authorizations || [];
        case actions.LOAD_CURRENT_USER_AUTHORIZATIONS_FAILURE:
            return initialState.currentUserAuthorizations;
        default:
            return state;
    }
}