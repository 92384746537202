import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import ShortDate from "../shared/ShortDate";
import { FormattedNumber } from "react-intl";
import FormattedCurrency from "../shared/FormattedCurrency";
import PropertyAmenitiesTableContainer from "../../containers/shared/PropertyAmenitiesTableContainer";
import { MotifButton, MotifToast, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';
import { Fragment } from "react";

const editPropertyComponent = ({
    property,
    lookupTypes,
    showForm,
    handleCloseForm,
    handleCloseComponent,
    handleNewButtonClick,
    handleSubmit,
    handleEditButtonClick,
    handleDeleteButtonClick,
    showSuccess,
    canEdit,
    handleDismissErrorMessage,
    handleDismissSuccessMessage,
    periodPropertyCount,
    taxableReitSubsidiaries,
    initialValues,
    handleShowEndForm,
    handleShowClearEndDateForm
}) => {
    let address = "No address has been entered.";
    if (property && property.locationAddressStreet) {
        address = `${property.locationAddressStreet}, ${property.locationAddressCity}, ${property.locationAddressState} ${property.locationAddressZipCode}`;
    }
    
    const reitModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            height: '100%', // <-- This sets the height
            overlfow: 'scroll' // <-- This tells the modal to scrol
        }
    };

    const onEndClick = () => {
        handleShowEndForm(property.propertyID);
    };

    const onClearEndDateClick = () => {
        handleShowClearEndDateForm(property.propertyID);
    };

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Property Modal',
        ariaLabel: 'Close Property Modal',
        dataTestid: 'close',
    };

    return (
        <React.Fragment>
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span>Property</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                {property && property.propertyName && <div className="edit-property-component">
                    <div className="row">
                        <div className="col-md-12 d-flex"> 
                            {showSuccess && <MotifToast className="mt-2 mb-2" variant="success" onClose={handleDismissSuccessMessage}>Property updated successfully!</MotifToast>}
                        </div>
                        <div className="col-md-12 font-weight-bold">
                            {!canEdit && <span>Reporting Closed&nbsp;</span>}
                        </div>
                    </div>
                    <div>
                        <section className="property">
                            <div className="row">
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="propertyName">Name: </label>
                                    <p className="word-break-text">{property.propertyName}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="purchasePrice">Purchase Price: </label>
                                    <p><FormattedCurrency value={property.purchasePrice} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="purchaseDate">Purchase Date: </label>
                                    <p><ShortDate value={property.purchaseDate} /></p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="purchaseDate">End Date: </label>
                                    <p>{property.endDate &&
                                    <span> <ShortDate value={property.endDate} /> </span> || <span> Active Property </span>                                    
                                    }</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="field col-md-6">
                                    <br />
                                    <label htmlFor="locationAddressStreet">Street Address: </label>
                                    <p className="word-break-text">{address}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="percentageOfOwnership">Ownership: </label>
                                    <p><FormattedNumber value={property.percentageOfOwnership * 1000.00 / 100000.00} style="percent" minimumFractionDigits={4} maximumFractionDigits={10} /></p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="rentableSquareFootage">Rentable Sq Ft: </label>
                                    <p><FormattedNumber value={property.rentableSquareFootage} style="decimal" minimumFractionDigits={0} maximumFractionDigits={0} /></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="propertyType">Type: </label>
                                    <p>{lookupTypes.propertyTypes.find(pt => pt.propertyTypeID === property.propertyTypeID).propertyTypeDescription}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="psqPropertyTypes">PSQ Property Type(s): </label>
                                    <p>{property.psqPropertyTypes.map(p => p.replace("MultiFamily", "Multi-Family")).join(", ")}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="class">Class: </label>
                                    <p>{lookupTypes.propertyClasses.find(pt => pt.propertyClassID === property.propertyClassID).propertyClassDescription}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="tenantType">Tenant Type: </label>
                                    <p>{lookupTypes.tenantTypes.find(pt => pt.tenantTypeID === property.tenantTypeId).tenantTypeDescription}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="propertyParkingType">Parking: </label>
                                    <p>{lookupTypes.propertyParkingTypes.find(pt => pt.propertyParkingTypeID === property.propertyParkingTypeID).propertyParkingTypeDescription}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="field col-md-12">
                                    <br />
                                    <label htmlFor="managementCompany">Management Company: </label>
                                    <p className="word-break-text">{property.managementCompany}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="managerName">Manager Name: </label>
                                    <p className="word-break-text">{property.managerName}</p>
                                </div>
                                <div className="field col-md-6">
                                    <br />
                                    <label htmlFor="managerEmail">Manager Email: </label>
                                    <p className="word-break-text">{property.managerEmail}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="managerPhone">Manager Phone: </label>
                                    <p>{property.managerPhone}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="accountantName">Accountant Name: </label>
                                    <p className="word-break-text">{property.accountantName}</p>
                                </div>
                                <div className="field col-md-6">
                                    <br />
                                    <label htmlFor="accountantEmail">Accountant Email: </label>
                                    <p className="word-break-text">{property.accountantEmail}</p>
                                </div>
                                <div className="field col-md-3">
                                    <br />
                                    <label htmlFor="accountantPhone">Accountant Phone: </label>
                                    <p>{property.accountantPhone}</p>
                                </div>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="field col-md-12">
                                        <br />
                                        <label>Tenant Services / Amenities:</label>
                                        <div>
                                            <PropertyAmenitiesTableContainer formName="readProperty" readOnly={true} taxableReitSubsidiaries={taxableReitSubsidiaries} />
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="row">
                                <div className="field col-md-12">
                                    <br />
                                    <label>Tracking ID:</label>
                                    <div>
                                        {property.propertyTrackingID}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div> || <h4>No property found for this report period.</h4>}
            </MotifModalBody>
            <MotifModalFooter>
                {property && property.propertyName && 
                    <Fragment>
                        <MotifButton variant="secondary" type="button"
                            onClick={handleCloseComponent}>
                            Cancel
                        </MotifButton>
                        {property.endDate &&                                
                            <MotifButton variant="primary-alt" type="button"
                                onClick={onClearEndDateClick}
                                title="Click to clear this property's end date.">
                                Clear End Date
                            </MotifButton>
                            ||
                            <MotifButton variant="primary-alt" type="button"
                                onClick={onEndClick}
                                title="Click to enter this property's end date.">
                                End Property
                            </MotifButton>
                        }
                        <MotifButton variant="primary" type="button"
                            onClick={handleEditButtonClick}
                            disabled={!canEdit}>
                            Edit
                        </MotifButton> 
                        
                    </Fragment>
                }
            </MotifModalFooter>
        </React.Fragment>
    );
};

editPropertyComponent.propTypes = {
    property: PropTypes.object,
    showForm: PropTypes.bool,
    handleCloseForm: PropTypes.func.isRequired,
    handleCloseComponent: PropTypes.func.isRequired,
    handleNewButtonClick: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleDeleteButtonClick: PropTypes.func,
    showSuccess: PropTypes.bool,
    canEdit: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func,
    handleDismissSuccessMessage: PropTypes.func,
    periodPropertyCount: PropTypes.number,
    taxableReitSubsidiaries: PropTypes.array,
    initialValues: PropTypes.object,
    handleShowEndForm: PropTypes.func.isRequired,
    handleShowClearEndDateForm: PropTypes.func.isRequired
};

export default reduxForm({ form: "readProperty", enableReinitialize: true })(editPropertyComponent);