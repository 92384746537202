import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

/**
 * This reducer handles the LOAD_PSQ_TEMPLATES_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function dataPurgeConfigurationDataReducer(state = initialState.dataPurgeConfigurationData, action) {
    if (action.type == actions.LOAD_DATA_PURGE_DATA_SUCCESS && Array.isArray(action.dataPurgeConfigurationData)) {
        return action.dataPurgeConfigurationData;
    } else if (action.type == actions.LOAD_DATA_PURGE_DATA_FAILURE) {
        return initialState.dataPurgeConfigurationData;
    } else {
        return state;
    }
}