import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the LOAD_ASSET_DETAIL_SUCCESS action and sets a client in the store
 * @param {Object} [state] The client currently in the store, or the initial state of client
 * @param {Object} action The action to be handled
 * @returns {Object} The client
 */
export default function refreshManageChecklistClient(state = initialState.refreshManageChecklistClientId, action) {
    switch (action.type) {
        case actions.REFRESH_MANAGE_CHECKLIST_CLIENT:
            return action.clientId;
        default:
            return state;
    }
}