import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as notificationActions from '../actions/notificationActions';
import * as microsoftGraphApiActions from '../actions/microsoftGraphApiActions';
import Menu, { Item as MenuItem } from 'rc-menu';
import { Icon } from 'semantic-ui-react';
import {
    MotifHeader,
    MotifIconButton,
    MotifIcon,
    MotifHeaderLogo
} from "@ey-xd/motif-react";
import { IconoirBellNotification } from '@ey-xd/motif-icon';
import { withMsal } from "@azure/msal-react";
import { withRouter } from '../common/withRouter';

import UserAvatarComponent from './shared/navbar/userAvatarComponent';

/**
 * NavBar container component
 * @extends {React.Component}
 */
export class NavBar extends React.Component {
    /**
     * Creates a new NavBar
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = { userId: "", isUserDropdownOpen: false };
    }

    componentDidMount() {
        // Microsoft Graph API call to fetch the profile picture of the logged in user
        this.props.microsoftGraphApiActions.fetchUserProfilePictureV1();
    }

    render() {
        let self = this;
        let user = '';
        const userId = this.props.authentication.currentUser;

        let name = this.props.msalContext.accounts[0].idTokenClaims.name;
        if (name === undefined || name === null || name === "") {
            name = "";
            let firstName = this.props.msalContext.accounts[0].idTokenClaims.given_name;
            if (firstName === undefined || firstName === null) {
                firstName = "";
            }
            let lastName = this.props.msalContext.accounts[0].idTokenClaims.family_name;
            if (lastName === undefined || lastName === null) {
                lastName = "";
            }
            if (firstName !== "") {
                name += firstName;
            }
            if (lastName != "") {
                if (name !== "") {
                    name += " ";
                }
                name += lastName;
            }
        }

        if (name !== "") {
            user += name;
        }
        else {
            user += userId;
        }

        /**
         * Handles a navigation click
         * @param {Object} info The information
         */
        function handleClick(info) {
            switch (info.key) {
                case "Logoff":
                    navToLogoff();
                    break;
            }
        }

        function handleNotificationToggleDisplay() {
            self.props.notificationActions.toggleNotificationsDisplay();
        }        

        const navToLogoff = () => {
            notificationActions.stopNotificationPolling();
            this.props.router.navigate(`/external/logoff`);
        };

        const handleToggleUserDropdown = () => {
            this.setState({ isUserDropdownOpen: !this.state.isUserDropdownOpen });
        };

        const handleToggleUserDropdownWhenClickedOutside = () => {
            if (this.state.isUserDropdownOpen === true) {
                this.setState({ isUserDropdownOpen: false });
            }
        };

        return (
            // <MotifHeader class="motif-header-app-name" style={{ backgroundColor: '#1b1a24'}}
            <MotifHeader
                fixed
                logo={
                    <MotifHeaderLogo >
                        <a href="/" aria-label="EY Logo">
                            <MotifIcon 
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTYxcHgiIGhlaWdodD0iNTc0cHgiIHZpZXdCb3g9IjAgMCA1NjEgNTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2My4xICg5MjQ1MikgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+RVlfTG9nb19CZWFtX1doaXRlX1llbGxvd19DX0NNWUs8L3RpdGxlPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIwLjA2IDAuOTc0IDU2MC4zOTQgMC45NzQgNTYwLjM5NCA1NzQgMC4wNiA1NzQiPjwvcG9seWdvbj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJFWV9Mb2dvX0JlYW1fZGVmYXVsdExvZ29XaGl0ZV9ZZWxsb3dfQ19DTVlLIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWxpbmUgaWQ9IkZpbGwtMSIgZmlsbD0iI0ZGRkZGRSIgcG9pbnRzPSI4OS45MTYgNDYyLjAzMSAxOTIuNDE3IDQ2Mi4wMzEgMTkyLjQxNyA0MDIuNTExIDg5LjkxNiA0MDIuNTExIDg5LjkxNiAzNTUuNzM0IDIwMy4zMDQgMzU1LjczNCAxNjUuNjg1IDI5MC41MzcgNC45MDMgMjkwLjUzNyA0LjkwMyA1NzQgMjMxLjY2NyA1NzQgMjMxLjY2NyA1MDguODAzIDg5LjkxNiA1MDguODAzIDg5LjkxNiA0NjIuMDMxIj48L3BvbHlsaW5lPgogICAgICAgIDxnIGlkPSJHcm91cC01Ij4KICAgICAgICAgICAgPG1hc2sgaWQ9Im1hc2stMiIgZmlsbD0id2hpdGUiPgogICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgPC9tYXNrPgogICAgICAgICAgICA8ZyBpZD0iQ2xpcC0zIj48L2c+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iRmlsbC0yIiBmaWxsPSIjRkZGRkZFIiBtYXNrPSJ1cmwoI21hc2stMikiIHBvaW50cz0iMzgyLjU4OSAyOTAuNTM3IDMzNC40MzkgMzgzLjA0MiAyODYuNDA1IDI5MC41MzcgMTkyLjQxMSAyOTAuNTM3IDI5MS40NTIgNDYyLjAzMSAyOTEuNDUyIDU3NCAzNzYuMjA2IDU3NCAzNzYuMjA2IDQ2Mi4wMzEgNDc1LjM3OCAyOTAuNTM3IDM4Mi41ODkgMjkwLjUzNyI+PC9wb2x5bGluZT4KICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJGaWxsLTQiIGZpbGw9IiNGQkQwMjAiIG1hc2s9InVybCgjbWFzay0yKSIgcG9pbnRzPSI1NjAuMzk0IDAuOTc0IDAgMjA1LjUwNSA1NjAuMzk0IDEwNi41MTEgNTYwLjM5NCAwLjk3NCI+PC9wb2x5bGluZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                            />
                            <span className="hide-for-accessibility">Home</span>
                        </a>
                    </MotifHeaderLogo>
                }
                appHeaderName="REITSuite"
                iconsContainer={
                    <div style={{ width: '100%' }} >
                        <span style={{ float: 'right' }}>
                            <span>
                                <MotifIconButton aria-label="Notifications" type="button"
                                    className={this.props.showNotifications ? "motif-icon-active" : ""}
                                    onClick={handleNotificationToggleDisplay} >
                                    <div style={{ position: 'relative' }}>
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <MotifIcon iconFunction={IconoirBellNotification} title="Notifications" size='24' fill='none' />
                                        </div>
                                        <div >
                                            {(Array.isArray(this.props.notifications) && this.props.notifications.length > 0 &&
                                                <Label color="red" circular floating >{this.props.notifications.length}</Label>) || null}
                                        </div>
                                    </div>
                                </MotifIconButton>
                            </span>
                            <span style={{ float: 'right' }}>
                                <UserAvatarComponent
                                    isUserDropdownOpen={this.state.isUserDropdownOpen}
                                    handleToggleUserDropdownWhenClickedOutside={handleToggleUserDropdownWhenClickedOutside}
                                    handleToggleUserDropdown={handleToggleUserDropdown}
                                    userName={name}
                                    userEmail={userId}
                                    userRole="External Checklist User"
                                    userProfilePictureUrl={this.props.userProfilePictureUrl}
                                    navToLogoff={navToLogoff}>
                                </UserAvatarComponent>
                            </span >
                        </span>
                    </div>
                }
            >
            </MotifHeader>
        );
    }
}

NavBar.propTypes = {
    user: PropTypes.object,
    showNotifications: PropTypes.bool.isRequired,
    notificationActions: PropTypes.object,
    microsoftGraphApiActions: PropTypes.object,
    authentication: PropTypes.object,
    userProfilePictureUrl: PropTypes.string
};

NavBar.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let result = {
        showNotifications: state.showNotifications,
        authentication: state.authentication,
        userProfilePictureUrl: state.userProfilePicture
    };

    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        notificationActions: bindActionCreators(notificationActions, dispatch),
        microsoftGraphApiActions: bindActionCreators(microsoftGraphApiActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withMsal(NavBar)));
