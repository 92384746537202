import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import RelatedReitsRow from './RelatedReitsRow';
import { MotifButton, MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem, MotifTable} from '@ey-xd/motif-react';
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged,handleFirstDataRendered,handleGridReady,handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import  RelatedReitsCellRenderer  from './customRenderer/RelatedReitsCellRenderer';
import CustomHeaderTemplate from '../shared/customHeaders/CustomHeaderTemplate.js';
import CustomPagination from '../../common/MotifPagination';

const RelatedReits = ({ handleAddReitClick, handleEditClick, handleDeleteClick, reits, canAddReit}) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    const getColumnDefs = () => {
        return [
            {
                field: 'reitName', headerName: 'Name', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 380,
                headerComponentParams: {
                    template: CustomHeaderTemplate("reitName"),
                  },
            },
            {
                field: 'type', headerName: 'Type', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 130,
                cellRenderer: RelatedReitsCellRenderer,
                headerComponentParams: {
                    template: CustomHeaderTemplate("type"),
                  },
                valueGetter: params => { return params.data && params.data.reitTypeID === 1 ? "Mortgage" : "Equity" },
            },
            {
                field: 'percentageThreshold', headerName: 'Trial Balance Threshold', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 250,
                headerComponentParams: {
                    template: CustomHeaderTemplate("percentageThreshold"),
                  },
                cellRenderer: RelatedReitsCellRenderer,
                valueGetter: params => { return params.data && params.data.percentageThreshold + "% / $" + params.data.balanceThreshold},
            },
            {
                field: 'ein', headerName: 'EIN', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 160,headerComponentParams: {
                    template: CustomHeaderTemplate("ein"),
                  },
            },
            {
                field: 'action', headerName: '', suppressMenu: true, showRowGroup: false, rowGroup: false,
                minWidth: 80, 
                cellRenderer: RelatedReitsCellRenderer,
                cellRendererParams: { handleEditClick: handleEditClick},
            },
        ];
    };

    //Handle deselect all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);      
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val-1);
    };

    const handleGridChanges = (gridRefApi) => {
        if (gridRefApi) {
            gridRefApi.refreshCells();
        }
    }

    useEffect(() => {
        handleGridChanges(gridRef && gridRef.current ? gridRef.current.api : null);
        if(rowData.length > 0 && gridRef.current.api !== undefined){
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);

    useEffect(() => {
        setRowData(reits);
        if (gridRef && gridRef.current && gridRef.current.api) {
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [reits]);

    const rowHeight = 49; 
    const headerHeight = 55; 
    const totalHeight = (rowData?.length <= 3 ? rowData?.length : 3) * rowHeight + headerHeight; // set 3 rows as default

    return (
        <section className="related-reits">
            <div className="row">
                <div className="col-md-12">
                    <h2>Related REITs
                        <MotifButton variant="secondary" className="right" type="button"
                            onClick={handleAddReitClick}
                            disabled={!canAddReit}>
                            Add REIT
                        </MotifButton>
                    </h2>
                </div>
                {!canAddReit && <div className="col-md-12" style={{ textAlign: 'right', fontStyle: 'italic' }}>The Add REIT button has been disabled because there are unsaved changes to the client</div>}
            </div>

            {(rowData.length > 0 ) &&
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="ag-theme-quartz motif-table" style={{height: totalHeight}}>
                            <MotifTable zebra={true} compact={true}
                                    rowData={rowData}
                                    columnDefs={getColumnDefs()}
                                    defaultColDef={defaultColDef}
                                    accentedSort={true}
                                    suppressRowClickSelection={true}
                                    onGridReady={event => handleGridReady(event, gridRef,setCurrentPage,setItemsPerPage,setTotalPages,'REITSuiteRelatedREITsListColumnState', handleDeselectAll)}
                                    onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                    ref={gridRef}
                                    pagination={true}
                                    suppressPaginationPanel={true}
                                    paginationPageSize={100}
                                    groupDisplayType={'custom'}
                                    groupSelectsChildren={true}
                                    groupDefaultExpanded={0}
                                    onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                    data-testid="divRelatedReitsListGrid"
                                    deltaRowDataMode={true}
                                    immutableData={true}
                                    getRowNodeId={(data) => data.reitid}
                                />
                                <div className="mt-3">                 
                                    <span className="right">
                                        <CustomPagination
                                            currentPage={currentPage}
                                            handlePageChange={handlePageChange}
                                            totalPages={totalPages}
                                            handleItemsPerPageChange={handleItemsPerPageChange}
                                            gridRef={gridRef}
                                            setCurrentPage={setCurrentPage}
                                            setItemsPerPage={setItemsPerPage}
                                            setTotalPages={setTotalPages}
                                            />
                                    </span>
                                </div>
                            </div>
                    </div>
                </div>
                
            </div>
            }

            {(rowData.length === 0) &&
                <div className="row mt-3 mr-4">
                    <div className="col">
                        No Related REITs for this client.
                    </div>
                </div>
            }
        </section>
    );
};

RelatedReits.propTypes = {
    handleAddReitClick: PropTypes.func.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    reits: PropTypes.array.isRequired,
    canAddReit: PropTypes.bool
};

export default RelatedReits;