let Tags = [
    { tag: '[clientContactName]', type: 'value' },
    { tag: '[reportCreationDate]', type: 'value' },
    { tag: '[reitLegalName]', type: 'value' },
    { tag: '[periodEndDate]', type: 'value' },
    { tag: '[reitShortName]', type: 'value' },
    { tag: '[quarterOrdinal]', type: 'value' },
    { tag: '[numberOfPreferredShareholders]', type: 'value' },
    { tag: '[yearStartDate]', type: 'value' },
    { tag: '[trsSumOfValueAllOwned]', type: 'value' },
    { tag: '[yearEndDate]', type: 'value' },
    { tag: '[reitDateOfElection]', type: 'value' },
    { tag: '[reitStateOrganized]', type: 'value' },
    { tag: '[reitFormationDate]', type: 'value' },
    { tag: '[reitOrgType]', type: 'value' },
    { tag: '[numberOfSharesClasses]', type: 'value' },

    { tag: '[TableOfContents]', type: 'section' },
    { tag: '[TrsSummary]', type: 'section' },
    { tag: '[SecuritiesOverview]', type: 'section' },
    { tag: '[AssetTrsInfo]', type: 'section' },
    { tag: '[AssetSummaryResults]', type: 'section' },
    { tag: '[AssetDetailResults]', type: 'section' },
    { tag: '[IncomeSummaryResults]', type: 'section' },
    { tag: '[IncomeDetailResults]', type: 'section' },
    { tag: '[DistributionSummaryResults]', type: 'section' },
    { tag: '[DistributionDetailResults]', type: 'section' },
    { tag: '[MappingsTable]', type: 'section' },
];

// Sort alphabetically
Tags = Tags.sort((a, b) => {
    if (a.tag < b.tag) { return -1; }
    if (a.tag > b.tag) { return 1; }
    return 0;
});

export default Tags;