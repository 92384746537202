import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifErrorMessage} from '@ey-xd/motif-react';

const AddSysAdminModal = ({ showAddSysAdminModal, handleSubmit, toggleShowAddSysAdminModal, initialValues, addAuthMessageText, validateDomain }) => {
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close add system administrator modal',
        ariaLabel: 'Close add system administrator modal',
        dataTestid: 'close'
    };
    return (
        <MotifModal variant="alt" size="sm" show={showAddSysAdminModal} onClose={toggleShowAddSysAdminModal} data-testid="AddSystemAdmin">
            <MotifModalHeader closeButtonProps={closeButtonProps} >
                <HeaderWrapper>
                    <span className="ml-2">Add System Administrator</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <form onSubmit={handleSubmit} className="auth">
                <MotifModalBody>
                    <Field
                        name="userId"
                        component={formHelpers.renderField}
                        type="email"
                        data-testid="emailAddress"
                        label="User's email address"
                        aria-label="User's email address"
                        maxLength={255}
                        isRequiredField={true}
                        validate={[formValidators.email, validateDomain, formValidators.required, formValidators.maxLength255]}
                    />
                    {(addAuthMessageText && <MotifErrorMessage variant="error">{addAuthMessageText}</MotifErrorMessage>) || null}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton type="button" data-testid="cancel" onClick={toggleShowAddSysAdminModal} variant="secondary">Cancel</MotifButton>
                    <MotifButton type="submit" data-testid="submit" variant="primary">Submit</MotifButton>
                </MotifModalFooter>
            </form>
        </MotifModal>
    );
};

AddSysAdminModal.propTypes = {
    showAddSysAdminModal: PropTypes.bool,
    handleAddSysAdminModalAction: PropTypes.func,
    handleSubmit: PropTypes.func,
    toggleShowAddSysAdminModal: PropTypes.func,
    initialValues: PropTypes.object,
    addAuthMessageText: PropTypes.string,
    validateDomain: PropTypes.func
};

export default reduxForm({ form: "auth", enableReinitialize: true })(AddSysAdminModal);