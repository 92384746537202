import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import * as formatters from "../../scripts/formFormatters";
import * as parsers from "../../scripts/formParsers";
import * as formHelpers from "../../scripts/formHelpers";

export const EinField = ({ ...props }) => {
    let properties = { ...props };

    if (!properties.parse) {
        properties.parse = parsers.phoneParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "##-#######";
    }

    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 9;
    }

    if(!properties.validation){
        properties.validation= properties.validate;
    }

    if(!properties.customFieldName){
        properties.customFieldName = "EIN";
    }
    
    if (!properties.formatField) {
        properties.formatField =  formatters.formatEin;
    }

    if (!properties.formParser) {
        properties.formParser =  properties.parse;
    }

    if (!properties.formattedMaxLength) {
        properties.formattedMaxLength = 10;
    }

    return (
        <Field {...properties} />
    );
};

export const DateField = ({ ...props }) => {
    let properties = { ...props };

    if (!properties.format) {
        properties.format = formatters.formatDate;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "MM/DD/YYYY";
    }

    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 10;
    }

    if(!properties.validation){
        properties.validation= properties.validate;
    }

    if(!properties.customFieldName){
        properties.customFieldName = "DATE";
    }

    if (!properties.formatField) {
        properties.formatField =  properties.format;
    }
        
    return (        
        <Field {...properties} />
    );
};

export const MoneyField = ({ ...props }) => {
    let properties = { ...props };

    if (!properties.parse) {
        properties.parse = parsers.numberParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "$0.00";
    }

    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 15;
    }

    if(!properties.validation){
        properties.validation= properties.validate;
    }

    if(!properties.customFieldName){
        properties.customFieldName = "MONEY";
    }

    if (!properties.formatField) {
        properties.formatField =  formatters.formatCurrency;
    }

    if (!properties.formParser) {
        properties.formParser =  properties.parse;
    }

    if (!properties.formattedMaxLength) {
        properties.formattedMaxLength = 20;
    }
    
    return (
        <Field {...properties} />
    );
};

export const PhoneField = ({ ...props }) => {
    let properties = { ...props };

    if (!properties.parse) {
        properties.parse = parsers.phoneParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "(###) ###-####";
    }

    if (!properties.type) {
        properties.type = "tel";
    }

    if (!properties.maxLength) {
        properties.maxLength = 10;
    }

    if(!properties.validation){
        properties.validation= properties.validate;
    }

    if(!properties.customFieldName){
        properties.customFieldName = "PHONE";
    }

    if (!properties.formatField) {
        properties.formatField =  formatters.formatPhone;
    }

    if (!properties.formParser) {
        properties.formParser =  properties.parse;
    }

    if (!properties.formattedMaxLength) {
        properties.formattedMaxLength = 14;
    }

    return (
        <Field {...properties} />
    );
};

export const NumberField = ({ ...props }) => {
    let properties = { ...props };
    let format = properties.format;
    if (!properties.format) {
        
        if (properties.allowDecimal && properties.allowNegativeValues && properties.allowLessThanZero) {
            format = formatters.formatNumberAllowNegativesAndLessThanZero;
        }
        else if (properties.allowDecimal && properties.allowNegativeValues) {
            format = formatters.formatNumberAllowNegativesAndDecimal;
        } else if (properties.allowDecimal) {
            format = formatters.formatNumberAllowDecimal;
        } else if (properties.allowNegativeValues) {
            format = formatters.formatNumberAllowNegatives;
        } else {
            format = formatters.formatNumber;
        }
    }

    delete properties.allowDecimal;
    delete properties.allowNegativeValues;

    if (!properties.parse) {
        properties.parse = parsers.numberParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }
    
    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 16;
    }

    if(!properties.validation){
        properties.validation= properties.validate;
    }

    if(!properties.customFieldName){
        properties.customFieldName = "NUMBER";
    }

    if (!properties.formatField) {
        properties.formatField =  format;
    }

    if (!properties.formParser) {
        properties.formParser =  properties.parse;
    }

    // this is added to get the field value that is not in the form state
    if(properties.value !== undefined){
        properties.defaultValue = properties.value;
    }

    if (!properties.formattedMaxLength) {
        properties.formattedMaxLength = 20;
    }

    if(properties.isUpdatedDefault !== undefined){
        properties.isUpdatedDefaultValue = properties.isUpdatedDefault;
    }

    return (
        <Field {...properties} />
    );
};

export const FileField = ({ ...props }) => {
    let properties = { ...props };

    if (!properties.type) {
        properties.type = "file";
    }

    let extensions = process.env.WHITELISTED_EXTENSIONS;
    if (!properties.accept && Array.isArray(extensions)) {
        properties.accept = extensions.join();
    }

    if (properties.allowMultiple) {
        delete properties.allowMultiple;

        return <input {...properties} multiple />;
    }

    return (
        <input {...properties} />
        );
};