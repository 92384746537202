import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged,handleFirstDataRendered,handleGridReady,tableHeaderClass,handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomPagination from '../../common/MotifPagination';
import TrsCellRenderer from "./customRenderer/TrsCellRenderer";
import TrsCurrencyCellRenderer from "./customRenderer/TrsCurrencyCellRenderer";
import CustomHeaderTemplate from "../checklistDeliveryCalendar/customHeaders/CustomHeaderTemplate.js";
import { MotifTable } from "@ey-xd/motif-react";

const EditTrsTable = ({ taxableReitSubsidiaries, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit }) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    const FormattedCurrency = ({ value }) => {
        return Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(value);
    };

    const getColumnDefs = () => {
        return [
            {
                field: 'trsName', headerName: 'Name', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 350,
                headerComponentParams: {template: CustomHeaderTemplate("trsName"),},
            },
            {
                field: 'trsValue', headerName: '$ Value', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 180,
                 cellRenderer: TrsCurrencyCellRenderer,
                headerComponentParams: {template: CustomHeaderTemplate("trsValue"),},
                valueGetter: (params) => {
                    return FormattedCurrency({ value: params.data.trsValue });
                }
            },
            {
                field: 'trsDebt', headerName: '$ Debt', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 180,
                cellRenderer: TrsCurrencyCellRenderer,
                headerComponentParams: {template: CustomHeaderTemplate("trsDebt"),},
                valueGetter: (params) => {
                    return FormattedCurrency({ value: params.data.trsDebt });
                }
            },
            {
                field: 'trsEquity', headerName: '$ Equity', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 180,
                cellRenderer: TrsCurrencyCellRenderer,
                headerComponentParams: {template: CustomHeaderTemplate("trsEquity"),},
                valueGetter: (params) => {
                    return FormattedCurrency({ value: params.data.trsEquity });
                }
            },
            {
                field: 'actions', headerName: '', suppressMenu: true, showRowGroup: false, rowGroup: false,
                minWidth: 120, 
                cellRenderer:TrsCellRenderer,
                cellRendererParams: { handleEditButtonClick, handleDeleteButtonClick },
                valueGetter: params => { return params.data},
                hide: !canEdit
            }
        ];
    };

    //Handle deselect all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);      
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val-1);
    };
    
    useEffect(() => {
        if(rowData.length > 0 && gridRef.current.api !== undefined){
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);
    
    useEffect(() => {
        setRowData(taxableReitSubsidiaries);
        if (gridRef && gridRef.current && gridRef.current.api) {
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            
            gridRef.current.api.refreshCells();
        }
    }, [taxableReitSubsidiaries]);
    
    const rowHeight = 76; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return (
        <div>   
            {(rowData.length > 0 ) &&
                <div>
                    <div className="row">
                        <div className="col">
                            <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                                <MotifTable zebra={true} compact={true}
                                    rowData={rowData}
                                    columnDefs={getColumnDefs()}
                                    defaultColDef={defaultColDef}
                                    accentedSort={true}
                                    rowSelection="multiple"
                                    suppressRowClickSelection={true}
                                    onGridReady={event => handleGridReady(event, gridRef,setCurrentPage,setItemsPerPage,setTotalPages,'REITSuiteTRSListColumnState', handleDeselectAll)}
                                    onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                    ref={gridRef}
                                    pagination={true}
                                    suppressPaginationPanel={true}
                                    paginationPageSize={100}
                                    groupDisplayType={'custom'}
                                    groupSelectsChildren={true}
                                    groupDefaultExpanded={0}
                                    onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                    data-testid="divTRSListGrid"
                                    deltaRowDataMode={true}
                                    getRowNodeId={(data) => data.taxableREITSubsidiaryID}  
                                />
                                <div className="mt-3">                 
                                    <span className="right mb-3">
                                    <CustomPagination
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                        totalPages={totalPages}
                                        handleItemsPerPageChange={handleItemsPerPageChange}
                                        gridRef={gridRef}
                                        setCurrentPage={setCurrentPage}
                                        setItemsPerPage={setItemsPerPage}
                                        setTotalPages={setTotalPages}/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            }

            {(rowData.length === 0) &&
                <div className="row mt-5 mr-4">
                    <div className="col">
                        No Taxable REIT Subsidiaries found for this period.
                    </div>
                </div>
            }
        </div>
    );
};

EditTrsTable.propTypes = {
    taxableReitSubsidiaries: PropTypes.arrayOf(PropTypes.shape({
        taxableREITSubsidiaryID: PropTypes.number,
        reportPeriodID: PropTypes.number,
        trsName: PropTypes.string,
        trsValue: PropTypes.number,
        trsDebt: PropTypes.number,
        trsEquity: PropTypes.number
    })),
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleDeleteButtonClick: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
};

export default EditTrsTable;