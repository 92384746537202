import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import * as formValidators from "../../scripts/formValidators";
import * as formHelpers from "../../scripts/formHelpers";
import $ from "jquery";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifErrorMessage,
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifTooltip,
  MotifIcon
} from "@ey-xd/motif-react";
import * as Constants from "../../constants/other";
import { IconoirInfoCircle } from '@ey-xd/motif-icon';
// import construct from "core-js/fn/reflect/construct";
const PsqCreateChecklistModal = ({
  handleSubmit,
  handleFieldChanged,
  handleClientEmailChanged,
  handleEyEmailChanged,
  handlePopulatePreviousAnswersChanged,
  showModal,
  isSendEmailEnabled,
  isCreateOnlyEnabled,
  setCreateAction,
  handleCancelAction,
  properties,
  error,
  handleDismissErrorMessage,
  clientEmailsRef,
  selectedPropertyId,
  checklist,
  customQuestionnaireTemplates,
  lookupTypes,
  clientEmail,
  createChecklistCustomErrorMessage,
  resetDropDowns,
  isInitialLoad,
  handleSetInitialEmail,
  externalUserVisibilityData,
}) => {
  let isReportPeriodVisible=true;

  if(externalUserVisibilityData && externalUserVisibilityData.length>0){
    isReportPeriodVisible = externalUserVisibilityData[0].isReportPeriodVisibleToExternalUsers;
  }

  let propertyOptions = [];
  const [selectedProperty, setSelectedProperty] = useState(propertyOptions[0]);
  const [emailInput, setEmailInput] = useState('');
  if (properties !== undefined) {
    propertyOptions = properties.map((property) => {
      return {
        key: property.propertyID,
        text: property.propertyName,
        value: property.propertyID,
      };
    });
  }
  let propertyTypeOptions = [];
  const [selectedPropertyType, setSelectedPropertyType] = useState(
    propertyTypeOptions[0]
  );
  if (lookupTypes !== undefined) {
    propertyTypeOptions = lookupTypes.propertyTypes
      .filter((ppt) => ppt.propertyTypeID != 0)
      .map((propertyType) => {
        return {
          key: propertyType.propertyTypeID,
          value: propertyType.propertyTypeID,
          text: propertyType.propertyTypeDescription,
        };
      });
  }

  let customTemplateOptions = [];
  const [selectedOption, setSelectedOption] = useState(
    customTemplateOptions[0]
  );

  if (customQuestionnaireTemplates) {
    customTemplateOptions = customQuestionnaireTemplates.map((item) => {
      return {
        key: item.questionnaireTemplateID,
        text: item.questionnaireTemplateName,
        value: item.questionnaireTemplateID,
      };
    });
  }

  customTemplateOptions.sort(function (a, b) {
    return a.key - b.key;
  });

  let prePopulateOptions = Constants.prePopulateOptions;
  const [selectedPopulateAns, setSelectedPopulateAns] = useState(
    prePopulateOptions[0]
  );

  const rollForwardQuestionComment = Constants.rollForwardQuestionComment;
  const [selectedRollForwadAns, setSelectedRollForwadAns] = useState(
    rollForwardQuestionComment[0]
  );

  const preventSubmitOnEnter = (e) => {
    if (e.key === "Enter") {
      //Avoid preventing key press for buttons
      if (
        e.target &&
        e.target.type !== "button" &&
        e.target.type !== "submit"
      ) {
        e.preventDefault();
      }
    }
  };

  const handleCreateButtonClick = () => {
    setCreateAction("CREATE");
  };

  const handleCreateAndSendButtonClick = () => {
    setCreateAction("CREATEANDSEND");
  };

  const validateEmails = () => {
    let sendToFieldValue = $("#sendToField :input").val() || emailInput;

    if (sendToFieldValue !== "") {
      throw new SubmissionError({
        _error: "'" + sendToFieldValue + "' is not a valid email address.",
      });
    }

    return handleSubmit();
  };

  const handleOnInputChange = (email) => { 
      const splitArray = email.trim().split("@");
    if(formValidators.email(email) !== undefined && splitArray[1] !== Constants.RejectedEyDomain  && !splitArray[1]?.includes(Constants.RejectedOtherEYDomain)){  
      setEmailInput(email);
    } 
    else{
      setEmailInput("");
    }
  }
  const modalStyle = {
    content: {
      "max-height": "100vh",
      "overflow-y": "auto",
      left: "15%",
      width: "70%",
    },
  };
  let propertyData = properties.find(
    (property) => property.propertyID == selectedPropertyId
  );

  //clear my dropdowns once after the modal is closed
  useEffect(() => {
    setSelectedProperty(propertyOptions[0]);
    setSelectedPropertyType(propertyTypeOptions[0]);
    setSelectedOption(customTemplateOptions[0]);
    setSelectedPopulateAns(prePopulateOptions[0]);
    setSelectedRollForwadAns(rollForwardQuestionComment[0]);
  }, [resetDropDowns]);

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: "Close add new PSQ modal",
    ariaLabel: "Close add new PSQ modal",
    dataTestid: "close",
  };

  const useHandleFieldChanged = (v) => {
    handleFieldChanged(v, customTemplateOptions, "customTemplateField");

    setSelectedOption(v);
  };
  const useHandleFieldChangedPropertyType = (v) => {
    handleFieldChanged(v, propertyTypeOptions, "propertyTypeField");

    setSelectedPropertyType(v);
  };
  const useHandleFieldChangedProperty = (v) => {
    handleFieldChanged(v, propertyOptions, "propertyField");

    setSelectedProperty(v);
  };

  const useHandleFieldChangedPopulateAns = (v) => {
    handleFieldChanged(v, prePopulateOptions, "populatePreviousAnswersField");

    setSelectedPopulateAns(v);
  };

  const useHandleFieldChangedRollForwardQuestions = (v) => {
    handleFieldChanged(
      v,
      rollForwardQuestionComment,
      "rollForwardQuestionCommentsField"
    );

    setSelectedRollForwadAns(v);
  };

  useEffect(() => {     
    if(isInitialLoad){
      handleSetInitialEmail();
    }
}, [clientEmail]);

  return (
    <MotifModal
      variant="alt"
      size="xl"
      show={showModal}
      onClose={handleCancelAction}
      data-testid="addPSQModal"
    >
      <MotifModalHeader
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <span className="ml-2">Create a new PSQ</span>
        </HeaderWrapper>
      </MotifModalHeader>

      <MotifModalBody>
        <form
          onSubmit={handleSubmit(validateEmails)}
          id="createPSQ"
          onKeyPress={preventSubmitOnEnter}
        >
          {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
          <div className="field">
            <MotifFormField>
              <MotifLabel data-testid="lblSelectProperty">
                Select Property (Required)
              </MotifLabel>
              <MotifSelect
                data-testid="divSelectProperty"
                name="propertyField"
                placeholder="Select a property..."
                required={true}
                value={selectedProperty}
                validate={[formValidators.required]}
                onChange={useHandleFieldChangedProperty}
              >
                {propertyOptions.map((co) => (
                  <MotifOption
                    className="motif-option-text-space"
                    key={co.value}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
          <div className="field margin-top-6">
            <MotifFormField>
              <MotifLabel data-testid="lblSelectPropertytype">
                Select Property Type (Required)
              </MotifLabel>
              <MotifSelect
                data-testid="divSelectPropertyType"
                name="propertyTypeField"
                placeholder="Select a property type..."
                required={true}
                value={selectedPropertyType}
                validate={[formValidators.required]}
                onChange={useHandleFieldChangedPropertyType}
              >
                {propertyTypeOptions.map((co) => (
                  <MotifOption
                    className="motif-option-text-space"
                    key={co.value}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
          <div className="field margin-top-6">
            <MotifFormField>
              <MotifLabel data-testid="lblSelectTemplate">
                Select Template (Required)
              </MotifLabel>
              <MotifSelect
                data-testid="divSelectTemplate"
                name="customTemplateField"
                placeholder="Select a template..."
                required={true}
                value={selectedOption}
                validate={[formValidators.required]}
                onChange={useHandleFieldChanged}
              >
                {customTemplateOptions.map((co) => (
                  <MotifOption
                    className="motif-option-text-space"
                    key={co.value}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>

          <div className="field margin-top-6">
            <MotifFormField>
              <MotifLabel data-testid="lblPrePopulateAnswers">
                Pre-Populate Answers? / Show Reference Answers?
              </MotifLabel>
              <MotifSelect
                data-testid="divPrePopulateAns"
                name="populatePreviousAnswersField"
                value={Constants.defaultPrepopulateAnswer}
                onChange={useHandleFieldChangedPopulateAns}
              >
                {prePopulateOptions.map((co) => (
                  <MotifOption
                    className="motif-option-text-space"
                    key={co.value}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>

          <div className="field margin-top-6">
            <MotifFormField>
              <MotifLabel data-testid="lblRollForwardQuestion">
                Roll Forward Question Comments? / Attachments?
              </MotifLabel>
              <MotifSelect
                data-testid="divRollForwardQuestion"
                name="rollForwardQuestionCommentsField"
                value={Constants.defaultRollFowardValue}
                onChange={useHandleFieldChangedRollForwardQuestions}
              >
                {rollForwardQuestionComment.map((co) => (
                  <MotifOption
                    className="motif-option-text-space"
                    key={co.value}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>


          <div className="field margin-top-6">
            <MotifLabel data-testid="lblRollForwardQuestion">
              Send to (Multiple email addresses are supported if separated by a semi-colon)
            </MotifLabel>
            <Field
              component={formHelpers.renderReactMultiEmail}
              id="sendToField"
              name="sendToField"
              domRef={clientEmailsRef}
              handleChange={handleClientEmailChanged}
              emails={clientEmail}
              removeEYEmail={true}
              validate={[
                formValidators.multipleEmails,
                formValidators.maxLength255,
              ]}
              handleOnInputChange={handleOnInputChange}
            />
          </div>
          {createChecklistCustomErrorMessage &&
            createChecklistCustomErrorMessage.length > 0 && (
              <MotifErrorMessage variant="error">
                {createChecklistCustomErrorMessage}
              </MotifErrorMessage>
            )}
        </form>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          variant="secondary"
          className="right"
          type="button"
          onClick={handleCancelAction}
        >
          Cancel
        </MotifButton>
        <MotifButton
            variant="primary-alt"
            className="right"
            type="submit"
            form="createPSQ"
            onClick={handleCreateAndSendButtonClick}
            disabled={!isSendEmailEnabled || !isCreateOnlyEnabled || !isReportPeriodVisible} 
              >
        {!isReportPeriodVisible && 
            <MotifTooltip 
                trigger={
                    <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' className="mt-1"/>
                }>
                    <div>Due to EY's independence policy, the create and send checklist is disabled<br/>
                         as the client no longer has access to this report period. <br />
                         Please email 'reitsuite@ey.com' for additional support. 
                    </div>
            </MotifTooltip> }
            Create &amp; Send
        </MotifButton> 
        <MotifButton
          variant="primary"
          className="right"
          type="submit"
          form="createPSQ"
          onClick={handleCreateButtonClick}
          disabled={!isCreateOnlyEnabled}
        >
          Create Only
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

PsqCreateChecklistModal.propTypes = {
  showModal: PropTypes.bool,
  properties: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleFieldChanged: PropTypes.func,
  handleClientEmailChanged: PropTypes.func,
  handleEyEmailChanged: PropTypes.func,
  handlePopulatePreviousAnswersChanged: PropTypes.func,
  isSendEmailEnabled: PropTypes.bool,
  isCreateOnlyEnabled: PropTypes.bool,
  setCreateAction: PropTypes.func,
  handleCancelAction: PropTypes.func,
  error: PropTypes.string,
  handleDismissErrorMessage: PropTypes.func,
  domRef: PropTypes.func,
  clientEmailsRef: PropTypes.func,
  selectedPropertyId: PropTypes.number,
  selectedPropertyName: PropTypes.string,
  //selectedPropertyType: PropTypes.string,
  checklist: PropTypes.object,
  customQuestionnaireTemplates: PropTypes.array,
  clientEmail: PropTypes.array,
  checkedTemplate: PropTypes.array,
  resetDropDowns: PropTypes.bool,
  externalUserVisibilityData: PropTypes.array
};

export default reduxForm({
  form: "psqcreatechecklistmodal",
  enableReinitialize: true,
})(PsqCreateChecklistModal);
