import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_ARQC_CHECKLIST_SUCCESS action and sets the ARQC checklist in the store
 * @param {Object} [state] The ARQC checklist currently in the store, or the initial state of the ARQC checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function arqcLoadChecklistsReducer(state = initialState.arqcChecklists, action) {
    if (action.type == actions.LOAD_ARQC_CHECKLIST_SUCCESS) {
        return action.arqcChecklists;
    } else if (action.type == actions.UNLOAD_TESTS) {
        return initialState.arqcChecklists;
    } else {
        return state;
    }
}