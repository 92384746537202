import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { MotifTextLink, MotifButton, MotifBadge, MotifLabel } from '@ey-xd/motif-react';
import * as authActions from '../../../actions/authActions';
import * as propertyActions from "../../../actions/propertyActions";
export default (props) => {

    const period = props.data;
    const clientId = props.clientId;
    const reit = props.reit;
    const reitId = props.reitId;
    const clientIsAudit = props.clientIsAudit;
    const periodLink = clientIsAudit ? `/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/arqc` : `/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/uploadtb/adjusted`;
    const chartExistsForClient = props.chartExistsForClient;

    let closeButtonText = "Close Testing";
    let openButtonText = "";
    let isClosed = false;
    let isReportingClosed = false;
    switch (period.reportPeriodStatusDescription) {
        case "Closed to Testing":
            closeButtonText = "Close Reporting";
            openButtonText = "Reopen to Testing";
            break;
        case "Closed to Reporting":
            closeButtonText = "Close Period";
            openButtonText = "Reopen to Reporting";
            isReportingClosed = true;
            break;
        case "Complete":
            closeButtonText = "Closed";
            openButtonText = "Reopen Period";
            isReportingClosed = true;
            isClosed = true;
            break;
    }

    let tbModeText = "REIT & Property TB Mode";
    if (period.trialBalanceModeID === 2) {
        tbModeText = "Consolidated TB Mode";
    }
    if (period.trialBalanceModeID === 0) {
        tbModeText = "TB Mode not set.";
    }
    
    const onHandleActivePeriodIdChanged = () => {
        return props.handleActivePeriodIdChanged(period.reportPeriodID);
    };

    const downloadCurrentTestSnapshot = (e) => {
        e.preventDefault();
        let fileName = `${period.reportPeriodDescription.replace(/\s/g, '')}_${reit.reitName}_TestSnapshot.xlsx`;
        return props.handleDownloadCurrentTestSnapshot(fileName, period.reportPeriodID);
    };

    const downloadTbTemplates = (e) => {
        e.preventDefault()
        return props.handleDownloadTbTemplates(clientId, period.reportPeriodID);
    };

    const downloadMappingTemplatePackage = (e) => {
        e.preventDefault()
        return props.handleDownloadMappingTemplatePackage(period.reportPeriodID);
    };

    const handleToggleShowClosePeriodModal = () => {
        return props.toggleShowClosePeriodModal(period.reportPeriodID);
    };


    const handleToggleShowOpenPeriodModal = () => {
        return props.toggleShowOpenPeriodModal(period.reportPeriodID);
    };

    let isEngAdminOrSysAdmin = false;
    if (Array.isArray(props.currentUserAuthorizations) && props.column.colId === "signOff") {
        isEngAdminOrSysAdmin = authActions.isSystemAdministrator(props.currentUserAuthorizations) || authActions.isEngagementAdministrator(props.currentUserAuthorizations, clientId);
    }

    const tbModeClick = () => {
        props.toggleShowModeModal(period.reportPeriodID);
    };

    let hideTbModeLink = false;
    if(props.column.colId === "manage" && props.hideTbModeLinkList?.length > 0){
        hideTbModeLink = props.hideTbModeLinkList.find(repPeriod => repPeriod.reportPeriodId === period.reportPeriodID)?.hideTbModeLink
    }

    let consolidatedTrialBalance;
    if(props.column.colId === "uploadDownload" && props.trialBalancesList?.length > 0) {
        consolidatedTrialBalance = props?.trialBalancesList.find(repPeriod => repPeriod.reportPeriodId === period.reportPeriodID)?.consolidatedTrialBalance;
    }

    let hideTestSnapshotLink;
    if(props.column.colId === "uploadDownload" && props.hideTestSnapshotLinkList?.length > 0) {
        hideTestSnapshotLink = props?.hideTestSnapshotLinkList.find(repPeriod => repPeriod.reportPeriodId === period.reportPeriodID)?.hideTestSnapshotLink;
    }

    let hasIncompleteChecklists;
    if((props.column.colId === "reportPeriodStatusDescription" || props.column.colId === "signOff") && props.hasIncompleteChecklistsList?.length > 0) {
        hasIncompleteChecklists = props?.hasIncompleteChecklistsList.find(repPeriod => repPeriod.reportPeriodId === period.reportPeriodID)?.hasIncompleteChecklists;
    }

    return (
        <div>
            {(props.column.colId === "reportPeriodDescription") && 
                <div>
                    <Link tabIndex="-1" to={periodLink} className='client-text-link'><MotifTextLink href='#' variant="alt">{period.reportPeriodDescription}</MotifTextLink></Link>
                    {period.discrepancyCount > 0 && <MotifBadge variant="alt" className="ml-1" value={period.discrepancyCount} data-testid="badgeDiscrepancyCount" /> || null}
                </div>
            }

            {(props.column.colId === "uploadDownload") && 
                <div className='mt-3 mb-3'>
                    {!clientIsAudit ?
                        <div style={{display: 'flex'}}> <Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/uploadtb/adjusted`}><MotifTextLink href='#' variant="alt">Upload Trial Balance</MotifTextLink></Link></div>
                        : <div style={{display: 'flex'}}> <MotifTextLink disabled variant="alt">Upload Trial Balance</MotifTextLink></div>}

                    {consolidatedTrialBalance && !clientIsAudit
                        ? <React.Fragment>
                        <div style={{display: 'flex'}}><Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/files`}><MotifTextLink href='#' variant="alt">Download Trial Balance</MotifTextLink></Link></div>
                    </React.Fragment>
                    : <React.Fragment> <div style={{display: 'flex'}}><MotifTextLink disabled variant="alt" href='#'>Download Trial Balance</MotifTextLink></div></React.Fragment>}
                    {(hideTestSnapshotLink !== undefined  && !hideTestSnapshotLink && !clientIsAudit && consolidatedTrialBalance)
                        ? <div style={{display: 'flex'}}><Link className='client-text-link'><MotifTextLink href='#' onClick={downloadCurrentTestSnapshot} variant="alt">Download Test Snapshot</MotifTextLink></Link></div>
                        : <div style={{display: 'flex'}}><MotifTextLink disabled variant="alt" href="#">Download Test Snapshot</MotifTextLink></div>}
                    {(!clientIsAudit && chartExistsForClient) ?
                        <div style={{display: 'flex'}}> <Link className='client-text-link'><MotifTextLink href='#' onClick={downloadTbTemplates} variant="alt" >Download TB Templates</MotifTextLink></Link></div>
                        : <div style={{display: 'flex'}}> <MotifTextLink disabled variant="alt" href='#'>Download TB Templates</MotifTextLink></div>}
                    {(!clientIsAudit && chartExistsForClient) ?
                         <div style={{display: 'flex'}}><Link className='client-text-link'><MotifTextLink href='#' onClick={downloadMappingTemplatePackage} variant="alt">Download Mapping Template Package</MotifTextLink></Link></div>
                         : <div style={{display: 'flex'}}><MotifTextLink disabled variant="alt" href='#'>Download Mapping Template Package</MotifTextLink></div>}
     
                    <React.Fragment>
                        <div style={{display: 'flex'}}><Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/report`}><MotifTextLink href='#' variant="alt">Final Report</MotifTextLink></Link></div>
                    </React.Fragment>
                </div>
            }

            {(props.column.colId === "manage") && 
                <div className='mt-3 mb-3'>
                    <div style={{display: 'flex'}}> <Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/properties`}><MotifTextLink variant="alt">Properties</MotifTextLink></Link></div>
                    {!clientIsAudit ?
                        <div style={{display: 'flex'}}> <Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/securitiesSnapshot`}><MotifTextLink variant="alt">Securities</MotifTextLink></Link></div>
                        : <div style={{display: 'flex'}}> <MotifTextLink disabled variant="alt">Securities</MotifTextLink></div>}
                    {Array.isArray(period.services) && period.services.includes("DistributionTesting") && !clientIsAudit ?
                        <div style={{display: 'flex'}}> <Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/distributionTest`} disabled={true}><MotifTextLink variant="alt">Distributions</MotifTextLink></Link></div>
                        : <div style={{display: 'flex'}}> <MotifTextLink disabled variant="alt">Distributions</MotifTextLink></div>
                    }
                    {!clientIsAudit ?
                        <div style={{display: 'flex'}}> <Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/trs`}><MotifTextLink variant="alt">Taxable REIT Subsidiaries</MotifTextLink></Link></div>
                        : <div style={{display: 'flex'}}> <MotifTextLink disabled variant="alt">Taxable REIT Subsidiaries</MotifTextLink></div>}
                    {!clientIsAudit ?
                        <div style={{display: 'flex'}}> <Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/files`}><MotifTextLink variant="alt">File Management</MotifTextLink></Link></div>
                        : <div style={{display: 'flex'}}> <MotifTextLink disabled variant="alt">File Management</MotifTextLink></div>}
                    {!clientIsAudit ?
                        <div style={{display: 'flex'}}> <Link className='client-text-link' tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/mappings`}><MotifTextLink variant="alt">Client Account Mappings</MotifTextLink></Link> </div>
                        : <div style={{display: 'flex'}}> <MotifTextLink disabled variant="alt">Client Account Mappings</MotifTextLink> </div>}
                    {hideTbModeLink !== undefined && !hideTbModeLink && !clientIsAudit && <div>
                        {period.reportPeriodStatusDescription === "In Progress" || period.reportPeriodStatusDescription === "Not Started"
                            ?
                            <React.Fragment><div style={{display: 'flex'}}><Link className='client-text-link'><MotifTextLink className='client-text-link' href="#" onClick={tbModeClick} variant="alt">Trial Balance Mode</MotifTextLink></Link></div> </React.Fragment>
                            : <React.Fragment> <div style={{display: 'flex'}}><MotifTextLink disabled variant="alt" title={period.reportPeriodStatusDescription}>Trial Balance Mode</MotifTextLink> </div> </React.Fragment>
                        }
                    </div> || null}
                </div>
            }

            {(props.column.colId === "services") && 
               <div align="center">
                    <div style={{ float: 'none', display: 'inline' }}>
                        <MotifButton variant="secondary" type="button"
                            onClick={onHandleActivePeriodIdChanged}
                            disabled={isReportingClosed || clientIsAudit}>
                            Services Scope
                        </MotifButton>
                    </div>
                </div>
            }

            {(props.column.colId === "reportPeriodStatusDescription") && 
                <div className="td-center">
                    <span style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <div style={{display: 'flex'}}><MotifBadge variant={period.reportPeriodStatusDescription === "Complete" ? "success" : "alt"} value={period.reportPeriodStatusDescription} /></div>
                        {hasIncompleteChecklists &&
                            <div style={{display: 'flex'}}>
                                <MotifBadge variant="error" value={"Incomplete ARQC or PSQ"} />        
                            </div>
                        }
                        <div style={{display: 'flex'}}><MotifLabel>{tbModeText}</MotifLabel></div>
                    </span>
                 </div>
            }

            {(props.column.colId === "signOff") && 
                <div align="center">
                    <div style={{ float: 'none', display: 'inline' }}>
                        <div className="close-period-button">
                            {!isClosed &&
                                <MotifButton variant="secondary"
                                    type="button"
                                    onClick={handleToggleShowClosePeriodModal}
                                    disabled={hasIncompleteChecklists 
                                        && closeButtonText === "Close Reporting"}>
                                    {closeButtonText}
                                </MotifButton>
                                || null}
                        </div>
                        {(isEngAdminOrSysAdmin && openButtonText !== "" && (<div className="close-period-button">
                            {openButtonText !== "" &&
                                <MotifButton variant="secondary"
                                    type="button"
                                    onClick={handleToggleShowOpenPeriodModal} >
                                    {openButtonText}
                                </MotifButton>
                                || null}
                        </div>)) || null}
                    </div>
            </div>
            }

        </div>
    )
}