import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { addAttribute } from "../../common/Attribute";
import {
    MotifButton,
    MotifDropdown,
    MotifDropdownItem,
    MotifIcon,
} from '@ey-xd/motif-react';
import { IconoirNavArrowDown, IconoirInfoCircle, IconoirHelpCircle } from '@ey-xd/motif-icon';

const ExternalUserDataVisibilityHeader = ({handleHideExternalUserDataVisibility,handleUnHideExternalUserDataVisibility}) => {
    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });
    const [actionOptionsOpen, setActionOptionsOpen] = useState(false);

    return <div>
        {/* <div style={{ minHeight: '15px'}}></div> */}
        <div className="col-md-12 mt-5 d-flex justify-content-end">
        <div style={{ width: 'fit-content' }}>
            <MotifDropdown
                open={actionOptionsOpen}
                placement="bottom-end"
                id={'action-dropdown'}
                aria-labelledby={'data-retention-trigger'}
                handleClickOutside={() => setActionOptionsOpen(false)}
                trigger={
                    <MotifButton
                        type="button"
                        variant="primary"
                        onClick={() => setActionOptionsOpen(!actionOptionsOpen)}
                        aria-controls={'action-dropdown'}
                        id={'action-dropdown-trigger'}
                        aria-expanded={actionOptionsOpen}
                        aria-label="Action dropdown"
                        data-testid="actions"
                    >
                        Actions <MotifIcon iconFunction={IconoirNavArrowDown} size="24" fill="none" />
                    </MotifButton>
                }
            >

                <ul role="menu">

                    <li role="menuitem">
                        <MotifDropdownItem type="button"
                            onClick={() => {handleHideExternalUserDataVisibility();}}
                            data-testid="hide">
                            Hide
                        </MotifDropdownItem>
                    </li>
                    <li role="menuitem">
                        <MotifDropdownItem type="button"
                            onClick={() => {handleUnHideExternalUserDataVisibility();}} 
                            data-testid="show">
                            Show
                        </MotifDropdownItem>
                    </li>
                </ul>

            </MotifDropdown>
        </div>
        </div>
    </div>;
};

ExternalUserDataVisibilityHeader.propTypes = {};

export default reduxForm({ form: "ExternalUserDataVisibilityHeader" })(ExternalUserDataVisibilityHeader);