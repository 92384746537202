import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the CUSTOM_QUESTION_INTENT_LOCK_UPDATE_SUCCESS & CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE actions and sets result data for an intent lock update of a custom question.
 * @param {Object} [state] The initial state of the intent lock update result
 * @param {Object} action The action to be handled
 * @returns {Object} The intent lock update result data
 */

export default function customQuestionIntentLockUpdateResultReducer(
  state = initialState.intentLockUpdateResult,
  action
) {
  switch (action.type) {
    case actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_SUCCESS:
      return action.intentLockUpdateResult;
    case actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE:
    default:
      return state;
  }
}
