import React from "react";
import PropTypes from "prop-types";
import FormattedPercent from "../shared/FormattedPercent";
import { Icon } from 'semantic-ui-react';
import { fontcolor } from "../../../tools/fileMock";
import { white } from "chalk";
import { MotifBadge } from '@ey-xd/motif-react';

const DistributionTestSummaryItem = ({ test, totalItems }) => {
    let title = test.title || "";
    const colSize = Math.floor(12 / totalItems);
    const className = `summary col-md-${colSize}`;
    const re = /(\d+%)(.*)/;
    const matches = title.match(re);

    if (Array.isArray(matches) && matches.length === 3) {
        title = <span>{matches[1]}{matches[2]}</span>;
    }
    return (
        <div className="summaryr">
            <div className="testname">{title}</div>
            <div className={"teststatus testresults"}>
                <FormattedPercent percentage={test.percent} />
            </div>
            <MotifBadge className="roundedCornerDefault" variant={test.isPassing ? "success" : "error"}
                value={test.isPassing ? "PASS" : "FAIL"} data-testid="badgeTestSummary" />
        </div>
    );
};

DistributionTestSummaryItem.propTypes = {
    test: PropTypes.shape({
        title: PropTypes.string,
        isPassing: PropTypes.bool,
        percent: PropTypes.number
    }),
    totalItems: PropTypes.number.isRequired
};

export default DistributionTestSummaryItem;