import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_MANAGE_CHECKLIST_SUCCESS action and sets the manageChecklists in the store
 * @param {Object} [state] The manageChecklists currently in the store, or the initial state of the manageChecklists
 * @param {Object} action The action to be handled
 * @returns {Object} The manageChecklists
 */
export default function manageChecklistReducer(state = initialState.manageChecklists, action) {
    if (action.type == actions.LOAD_MANAGE_CHECKLIST_SUCCESS && Array.isArray(action.manageChecklists)) {
        return action.manageChecklists;
    } else if (action.type == actions.LOAD_MANAGE_CHECKLIST_FAILURE) {
        return initialState.manageChecklists;
    } else {
        return state;
    }
}