import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";

/**
 * Removes the client from state.
 * @param {any} dispatch The dispatch.
 * @returns {void}
 */
export function clearClient(dispatch) {
    //dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    dispatch({ type: actions.UNLOAD_CLIENT });
    //dispatch({ type: actions.UNLOAD_REIT });
    //dispatch({ type: actions.UNLOAD_PERIOD });
    //dispatch({ type: actions.UNLOAD_FILE_MANAGEMENT });
    //dispatch({ type: actions.UNLOAD_TESTS });
    //dispatch({ type: actions.UNLOAD_PROPERTY });
    //dispatch({ type: actions.TASK_END, [pendingTask]: end });
}

/**
 * Not implemented.  Future use.
 * @param {any} dispatch The dispatch.
 * @returns {void}
 */
export function clearReit(dispatch) {
    //dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    //dispatch({ type: actions.UNLOAD_REIT });
    //dispatch({ type: actions.UNLOAD_PERIOD });
    //dispatch({ type: actions.UNLOAD_FILE_MANAGEMENT });
    //dispatch({ type: actions.UNLOAD_TESTS });
    //dispatch({ type: actions.UNLOAD_PROPERTY });
    //dispatch({ type: actions.TASK_END, [pendingTask]: end });
}

/**
 * Not implemented.  Future use.
 * @param {any} dispatch The dispatch.
 * @returns {void}
 */
export function clearPeriod(dispatch) {
    //dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    //dispatch({ type: actions.UNLOAD_PERIOD });
    //dispatch({ type: actions.UNLOAD_FILE_MANAGEMENT });
    //dispatch({ type: actions.UNLOAD_TESTS });
    //dispatch({ type: actions.UNLOAD_PROPERTY });
    //dispatch({ type: actions.TASK_END, [pendingTask]: end });
}

/**
 * Not implemented.  Future use.
 * @param {any} dispatch The dispatch.
 * @returns {void}
 */
export function clearProperty(dispatch) {
    //dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    //dispatch({ type: actions.UNLOAD_PROPERTY });
    //dispatch({ type: actions.TASK_END, [pendingTask]: end });
}