import React from 'react';
import PropTypes from 'prop-types';
import * as formatters from "../../scripts/formFormatters";

const ShortDate = ({ value }) => {
    return (
        <span>{formatters.formatDate(value)}</span>
    );
};

ShortDate.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired
};

export default ShortDate;