import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_ALL_RIETS_SUCCESS action and sets an array of REITs in the store
 * @param {Array} [state] The REITs currently in the store, or the initial state of REITs
 * @param {Object} action The action to be handled
 * @returns {Array} The REITs
 */
export default function featureManagementData(state = initialState.featureFlags, action) {
    switch(action.type) {
        case actions.LOAD_FEATURE_FLAGS_SUCCESS:
            return action.featureFlags;

    default:
        return state;
    }
}