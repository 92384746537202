import React from "react";
import PropTypes from "prop-types";
import { Label, Form, Input } from "semantic-ui-react";
import { MotifFormField, MotifInput, MotifLabel } from "@ey-xd/motif-react";

const editChecklistComments = ({
    section,
    handleComments,
    isExternal
}) => {

    let defaultValue = "";
    if (section.comments !== undefined && section.comments.length > 0) {
        defaultValue = section.comments[0];
    }

    if (!isExternal) {
        return (
            <Form.Group>
                <MotifFormField>
                    <MotifLabel>Comments</MotifLabel>
                    <MotifInput
                        onChange={handleComments}
                        value={defaultValue}
                        maxLength="4000"
                    />
                </MotifFormField>
            </Form.Group>
        );
    }

    return null;
};

editChecklistComments.propTypes = {
    section: PropTypes.object.isRequired,
    handleComments: PropTypes.func,
    isExternal: PropTypes.bool
};

export default editChecklistComments;