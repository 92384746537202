export default (headerName) => {
    return `<div className="ag-cell-label-container" style="width: 100%" role="presentation" data-testid=` + headerName + "Header" + `>
    <span style="float: inline-end" ref="eMenu" className="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" data-testid="menuHeader"></span>
        <div ref="eLabel" className="ag-header-cell-label" role="presentation">
            <span ref="eText" className="ag-header-cell-text"></span>
            <span ref="eFilter" className="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true" data-testid="filter"></span>
            <span ref="eSortOrder" className="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
            <span ref="eSortAsc" className="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
            <span ref="eSortDesc" className="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
            <span ref="eSortNone" className="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
        </div>
    </div>`;
}