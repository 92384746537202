import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS action and sets the checklistDeliveryCalendar in the store
 * @param {Object} [state] The checklistDeliveryCalendar currently in the store, or the initial state of the checklistDeliveryCalendar
 * @param {Object} action The action to be handled
 * @returns {Object} The checklistDeliveryCalendar
 */
export default function checklistDeliveryCalendarReducer(state = initialState.checklistDeliveryCalendar, action) {
    if (action.type == actions.LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS && Array.isArray(action.checklistDeliveryCalendar)) {
        return action.checklistDeliveryCalendar;
    } else if (action.type == actions.LOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE) {
        return initialState.checklistDeliveryCalendar;
    } else if (action.type == actions.RESET_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS){
        return state;
    } else if (action.type == actions.RESET_CHECKLIST_DELIVERY_SCHEDULE_FAILURE){
        return state;
    } else {
        return state;
    }
}