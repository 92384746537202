import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/assetActions';
import * as varianceActions from '../../actions/varianceActions';
import * as propertyActions from '../../actions/propertyActions';
import * as securitiesActions from '../../actions/securitiesSnapshotActions';
import * as periodActions from '../../actions/periodActions';
import TestSummary from '../../components/reits/TestSummary';
import AssetDetail from '../../components/tb/AssetDetails';
import { withRouter } from '../../common/withRouter';

/**
 * AssetTestPage container component
 * @extends {React.Component}
 */
export class AssetTestPage extends React.Component {
  /**
   * Creates a new AssetTestPage
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.state = { showDetails: false, tbMode: 1 };
    this.showDetails = this.showDetails.bind(this);
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.actions.fetchAssetSummary(this.props.periodId);
    this.props.varianceActions.fetchVarianceDetails(this.props.periodId);
    this.props.securitiesActions.fetchSecuritiesSnapshotByPeriod(
      this.props.periodId,
    );
    this.props.propertyActions.fetchPropertiesByPeriod(this.props.periodId);
    periodActions.fastFetchPeriodById(this.props.periodId).then(period => {
      if (period && period.trialBalanceModeID === 2) {
        this.setState({ tbMode: 2 });
      }
    });
  }

  showDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
    render() {
    if (
      this.props.assetSummary.tests &&
      this.props.assetSummary.tests.length === 0
    ) {
      return (
        <div>
          <p>Please upload trial balance for the period to view results.</p>
        </div>
      );
    }

    return (
      <div>
        <TestSummary
          testSummary={this.props.assetSummary}
          testHeader="Asset Test Summary"
          showDetails={this.state.showDetails}
          handleShowDetails={this.showDetails}
          varianceDetails={this.props.varianceDetails}
        />
        {this.state.showDetails ? (
          <AssetDetail
            assetDetails={this.props.assetDetails}
            securities={this.props.securitiesSnapshots}
            properties={this.props.properties}
            tbMode={this.state.tbMode}
            clientId={this.props.router.params.clientId}
            reitId={this.props.router.params.reitId}
            periodId={this.props.router.params.periodId}
          />
        ) : null}
      </div>
    );
  }
}

const clientAccountDataShape = PropTypes.shape({
  balance: PropTypes.number,
  clientAccountDescription: PropTypes.string,
  clientAccountNumber: PropTypes.string,
  internalAccountCode: PropTypes.string,
  internalAccountDescription: PropTypes.string,
  type: PropTypes.number,
});

AssetTestPage.propTypes = {
  //reitId: PropTypes.number.isRequired,
  periodId: PropTypes.number.isRequired,
  assetSummary: PropTypes.shape({
    tests: PropTypes.arrayOf(
      PropTypes.shape({
        testTitle: PropTypes.string,
        isPassing: PropTypes.bool,
        testResultPercent: PropTypes.number,
      }),
    ),
    newAccounts: PropTypes.number,
    deletedAccounts: PropTypes.number,
  }).isRequired,
  assetDetails: PropTypes.array,
  varianceDetails: PropTypes.shape({
    addedAccounts: PropTypes.arrayOf(clientAccountDataShape),
    modifiedAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        after: clientAccountDataShape,
        balanceDelta: PropTypes.number,
        balanceDeltaPercentage: PropTypes.number,
        before: clientAccountDataShape,
      }).isRequired,
    ),
    removedAccounts: PropTypes.arrayOf(clientAccountDataShape).isRequired,
  }),
  actions: PropTypes.object,
  varianceActions: PropTypes.object,
  securitiesSnapshots: PropTypes.array,
  properties: PropTypes.array,
  securitiesActions: PropTypes.object.isRequired,
  propertyActions: PropTypes.object.isRequired,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {
    periodId: Number.parseInt(ownProps.router.params.periodId),
    assetSummary: state.assetSummary,
    assetDetails: state.assetDetails,
    varianceDetails: state.varianceDetails,
    securitiesSnapshots: state.securitiesSnapshots,
    properties: state.properties,
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    varianceActions: bindActionCreators(varianceActions, dispatch),
    propertyActions: bindActionCreators(propertyActions, dispatch),
    securitiesActions: bindActionCreators(securitiesActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AssetTestPage)
);
