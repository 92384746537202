import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';
import * as statuses from '../../constants/trialBalanceStatus';

/**
 * This reducer handles the LOAD_ASSET_DETAIL_SUCCESS action and sets a client in the store
 * @param {Object} [state] The client currently in the store, or the initial state of client
 * @param {Object} action The action to be handled
 * @returns {Object} The client
 */
export default function trialBalanceStatus(state = initialState.trialBalanceStatus, action) {
    switch (action.type) {
        case actions.TB_PROCESSING_COMPLETE:
            return {
                uploadId: action.uploadId,
                fileName: action.fileName,
                status: statuses.COMPLETED
            };
        case actions.TB_PROCESSING_FAILED:
            return {
                uploadId: action.uploadId,
                fileName: action.fileName,
                error: action.error,
                status: statuses.FAILED
            };
        case actions.TB_PROCESSING_COMPLETE_NOTIFIED:
            return initialState.trialBalanceStatus;
        default:
            return state;
    }
}