import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_PSQ_CHECKLIST_SUCCESS action and sets the PSQ checklists in the store
 * @param {Object} [state] The PSQ checklist currently in the store, or the initial state of the PSQ checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function psqLoadChecklistsReducer(state = initialState.psqChecklists, action) {
    if (action.type === actions.LOAD_PSQ_CHECKLIST_SUCCESS && action.psqChecklists !== undefined) {
        return action.psqChecklists; // NOTE: See psqChecklistReducer.  Both reducers handle the same action types but expect different properties on the action (one plural, one not)
    } else if (action.type == actions.UNLOAD_TESTS) {
        return initialState.psqChecklists;
    } else {
        return state;
    }
}