import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

/**
 * This reducer handles the LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_SUCCESS action and sets upcoming data deletion notice data in the store
 * @param {Object} [state] The upcoming data deletion notice data currently in the store, or the initial state of upcoming data deletion notice data 
 * @param {Object} action The action to be handled
 * @returns {Object} The upcoming data deletion notice data.
 */
export default function upcomingDataDeletionNoticeDataReducer(state = initialState.upcomingDataDeletionNoticeData, action) {
    if (action.type == actions.LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_SUCCESS) {
        return action.upcomingDataDeletionNoticeData;
    } else if (action.type == actions.LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_FAILURE) {
        return initialState.upcomingDataDeletionNoticeData;
    } else {
        return state;
    }
}