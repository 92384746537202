import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_PERIOD_MAPPINGS_SUCCESS action and sets a periods mappings in the store
 * @param {Object} [state] The period mappings currently in the store, or the initial state of period mappings
 * @param {Object} action The action to be handled
 * @returns {Object} The mappings
 */
export default function periodMappingsBalances(state = initialState.periodMappingsBalances, action) {
    switch (action.type) {
    case actions.LOAD_PERIOD_MAPPINGS_BALANCES_SUCCESS:
        return action.periodMappingsBalances || [];
    case actions.LOAD_PERIOD_MAPPINGS_BALANCES_FAILURE:
    case actions.UNLOAD_FILE_MANAGEMENT:
        return initialState.periodMappingsBalances;
    default:
        return state;
    }
}