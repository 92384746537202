import PropTypes from "prop-types";
import React, { useState,useEffect } from 'react';
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifRadioButton, MotifRadioButtonGroup } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';

const BulkProcessSignOffModal = ({ showSignOffModal, toggleShowSignOffModal, handleSignOffSave, resetSignOffRadioButtons,currentUserIsEngagementUser }) => {
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setSelectedOption('');
}, [resetSignOffRadioButtons])

  return (
    <MotifModal show={showSignOffModal} onClose={toggleShowSignOffModal} variant="alt">
      <MotifModalHeader>
        Update Sign Off Status
      </MotifModalHeader>
      <MotifModalBody>
        <h6>Please select sign off status for selected REIT(s) and report period</h6>
        <br></br>
        <div>
          <MotifRadioButtonGroup>
            {Constants.updateSignOffStatusAction.map((array) =>
              <MotifRadioButton
                name="signOff"
                id={array.value}
                value={array.value}
                checked={selectedOption === array.value}
                disabled={array.type=="reopen" && currentUserIsEngagementUser}
                onChange={event => setSelectedOption(event.target.value)}
              >
                {array.text}
              </MotifRadioButton>)}
          </MotifRadioButtonGroup>
        </div>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton variant="secondary" className="right" type="button"
          onClick={toggleShowSignOffModal}>
          Cancel</MotifButton>
        <MotifButton variant="primary" className="right" type="button"
          onClick={() => handleSignOffSave(selectedOption)}
          disabled={selectedOption==''}
          >
          Save</MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

BulkProcessSignOffModal.propTypes = {
  showSignOffModal: PropTypes.bool,
  toggleShowSignOffModal: PropTypes.func,
  handleSignOffSave: PropTypes.func,
  resetSignOffRadioButtons: PropTypes.bool
};

export default BulkProcessSignOffModal;