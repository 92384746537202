import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import EditPropertyComponent from "../../components/reits/EditPropertyComponent";
import EditPropertyForm from "./EditPropertyForm";
import EndPropertyModal from "./EndPropertyModal";
import ClearEndDateForm from "./ClearEndDateForm";
import DeletePropertyModal from "../../components/reits/DeletePropertyModal";
import { MotifButton, MotifModal, MotifTable } from "@ey-xd/motif-react";
import { AgGridReact } from "ag-grid-react";
import PropertyCellRenderer from "../shared/customRenderers/PropertyCellRenderer";
import {
  handleFilterChanged,
  handleFirstDataRendered,
  handleGridReady,
  tableHeaderClass,
  handleItemsPerPageChange,
} from "../../common/AgGridEventHandlers";
import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate";
import CustomPagination from "../../common/MotifPagination";
import DateComparator from "../checklistDeliveryCalendar/customComparators/DateComparator";
import * as formatters from "../../scripts/formFormatters";
const PropertyList = ({
  period,
  properties,
  lookupTypes,
  property,
  formPurpose,
  formProperty,
  showForm,
  handleCloseForm,
  handleNewButtonClick,
  handleSubmit,
  handleEditButtonClick,
  handleDeleteButtonClick,
  showSuccess,
  isTestingEnabled,
  canAdd,
  canEdit,
  handleDismissErrorMessage,
  handleDismissSuccessMessage,
  periodPropertyCount,
  hideSuccessMessage,
  showComponent,
  handleCloseComponent,
  handleOpenComponent,
  taxableReitSubsidiaries,
  tbMode,
  showEndForm,
  handleShowEndForm,
  handleCloseEndForm,
  showClearEndDateForm,
  handleShowClearEndDateForm,
  handleOpenDeleteProperty,
  showDeletePropertyModal,
  deletePropertyDetail,
  handleYesDeleteProperty,
  handleNoDeleteProperty,
  handleGridChanges,
}) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  //Default column settings
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 65,
      filter: true,
      resizable: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const getColumnDefs = () => {
    return [
      {
        field: "propertyName",
        headerName: "PROPERTY NAME",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 300,
        headerComponentParams: {
          template: CustomHeaderTemplate("propertyName"),
        },
      },
      {
        field: "propertyTypeDescription",
        headerName: "TYPE",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 200,
        headerComponentParams: {
          template: CustomHeaderTemplate("propertyTypeDescription"),
        },
        valueGetter: (params) =>
          params.data.propertyTypeDescription !== "NotSelected"
            ? params.data.propertyTypeDescription
            : "",
      },
      {
        field: "purchaseDate",
        headerName: "PURCHASE DATE",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 183,
        headerComponentParams: {
          template: CustomHeaderTemplate("purchaseDate"),
        },
        valueGetter: (params) => {
          const dateValue = params.data && params.data.purchaseDate ? params.data.purchaseDate : null;
          return formatters.formatDate(dateValue);
        },
        comparator: DateComparator,
      },
      {
        field: "percentageOfOwnership",
        headerName: "OWNERSHIP",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 183,
        headerComponentParams: {
          template: CustomHeaderTemplate("percentageOfOwnership"),
        },
        valueGetter: (params) => {
          const percentage = params.data && params.data.percentageOfOwnership;
          return percentage
            ? ((percentage * 1000.0) / 100000.0).toLocaleString(undefined, {
                style: "percent",
                minimumFractionDigits: 4,
                maximumFractionDigits: 10,
              })
            : "";
        },
      },
      {
        field: "propertyTrackingID",
        headerName: "TRACKING ID",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 183,
        headerComponentParams: {
          template: CustomHeaderTemplate("propertyTrackingID"),
        },
      },
      {
        field: "",
        headerName: "",
        suppressMenu: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 175,
        cellRenderer: PropertyCellRenderer,
        cellRendererParams: {
          properties,
          lookupTypes,
          handleOpenComponent,
          handleOpenDeleteProperty,
        },
        headerComponentParams: {
          template: CustomHeaderTemplate("TemplateAction"),
        },
      },
    ];
  };

  //Handle deselect all event
  const handleDeselectAll = (val) => {
    // set current page and total pages to 0
    setCurrentPage(0);    
    setTotalPages(0);      
  };

  //Handle Grid Page change event
  const handlePageChange = (val) => {
    setCurrentPage(val);
    gridRef.current.api.paginationGoToPage(val - 1);
  };

  const [columnDefs, setColumnDefs] = useState(getColumnDefs());

  useEffect(() => {
    handleGridChanges(
      rowData,
      gridRef && gridRef.current ? gridRef.current.api : null
    );
    if (rowData.length > 0 && gridRef.current.api != undefined) {
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
      //If total page is zero then set the current page as zero
      if (calcTotalPages == 0) {
        setCurrentPage(calcTotalPages);
      }
    }
  }, [rowData]);

  useEffect(() => {
    setRowData(properties);
    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.applyTransaction({});
      // Get total pages from Grid's default pagination control and apply it to custom pagination control
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
    }
  }, [properties]);

  useEffect(() => {
    if (gridRef.current) {
      setColumnDefs(getColumnDefs());
    }
  }, [columnDefs]);

  const reitModalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "100%", // <-- This sets the height
      overflow: "scroll", // <-- This tells the modal to scroll
    },
  };

  let buttonMessage = "Add a property to this REIT";
  if (!canAdd) {
    buttonMessage = "Period is closed to testing.";
  }
  if (tbMode === 2) {
    buttonMessage = "Consolidated Trial Balance Mode";
  }

  const rowHeight = 76; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

  return (
    <React.Fragment>
      <div className="row">
        <div className="w-100">
          <div className="col">
            <div className="d-flex justify-content-between">
              <div>
                <h2>
                  Properties{" "}
                  {!canEdit
                    ? "- Reporting Closed"
                    : !canAdd
                    ? "- Testing Closed"
                    : ""}{" "}
                </h2>
              </div>
              <div className="align-self-center">
                <MotifButton
                  variant="primary"
                  className="right"
                  type="button"
                  onClick={handleNewButtonClick}
                  disabled={!canAdd || tbMode === 2}
                  title={buttonMessage}
                >
                  New Property
                </MotifButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {rowData.length > 0 && (
          <div>
            <div className="row mb-3">
              <div className="col mt-3">
              <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                  <MotifTable zebra={true} compact={true}
                    rowData={rowData}
                    columnDefs={getColumnDefs()}
                    defaultColDef={defaultColDef}
                    accentedSort={true}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    onGridReady={(event) =>
                      handleGridReady(
                        event,
                        gridRef,
                        setCurrentPage,
                        setItemsPerPage,
                        setTotalPages,
                        "REITSuitePropertyColumnState",
                        handleDeselectAll
                      )
                    }
                    onFirstDataRendered={handleFirstDataRendered(gridRef)}
                    ref={gridRef}
                    pagination={true}
                    suppressPaginationPanel={true}
                    paginationPageSize={100}
                    groupDisplayType={"custom"}
                    groupSelectsChildren={true}
                    groupDefaultExpanded={0}
                    onFilterChanged={handleFilterChanged(
                      gridRef,
                      currentPage,
                      setCurrentPage,
                      setTotalPages
                    )}
                    data-testid="divPropertyListGrid"
                    deltaRowDataMode={true}
                    immutableData={true}
                    getRowNodeId={(data) => data.propertyID}
                  />
                  <div className="mt-3">
                    <span className="right mb-3">
                      <CustomPagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        totalPages={totalPages}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        gridRef={gridRef}
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        setTotalPages={setTotalPages}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        )}
        {properties && properties.length === 0 && (
          <div>No property found for this report period.</div>
        )}
      </div>
      <MotifModal
        show={showComponent}
        variant="alt"
        size="xl"
        style={{ minWidth: "65vw" }}
        onClose={handleCloseComponent}
        aria-label="Add or Edit Property"
      >
        <EditPropertyComponent
          property={property}
          lookupTypes={lookupTypes}
          showForm={showForm}
          handleCloseForm={handleCloseForm}
          handleCloseComponent={handleCloseComponent}
          handleNewButtonClick={handleNewButtonClick}
          handleSubmit={handleSubmit}
          handleEditButtonClick={handleEditButtonClick}
          handleDeleteButtonClick={handleDeleteButtonClick}
          showSuccess={showSuccess && !hideSuccessMessage}
          canEdit={canEdit}
          handleDismissErrorMessage={handleDismissErrorMessage}
          handleDismissSuccessMessage={handleDismissSuccessMessage}
          periodPropertyCount={periodPropertyCount}
          taxableReitSubsidiaries={taxableReitSubsidiaries}
          initialValues={property}
          handleShowEndForm={handleShowEndForm}
          handleShowClearEndDateForm={handleShowClearEndDateForm}
        />
      </MotifModal>
      <DeletePropertyModal
        showDeletePropertyModal={showDeletePropertyModal}
        deletePropertyDetail={deletePropertyDetail}
        property={property}
        handleYes={handleYesDeleteProperty}
        handleNo={handleNoDeleteProperty}
      />

      <MotifModal
        show={showForm}
        size="xl"
        style={{ minWidth: "65vw" }}
        variant="alt"
        onClose={handleCloseForm}
        aria-label={formPurpose === "EDIT" ? "Edit Property" : "Add Property"}
      >
        <EditPropertyForm
          lookupTypes={lookupTypes}
          onSubmit={handleSubmit}
          handleCloseForm={handleCloseForm}
          initialValues={formProperty}
          formPurpose={formPurpose}
          handleDismissErrorMessage={handleDismissErrorMessage}
          isTestingEnabled={isTestingEnabled}
          periodPropertyCount={periodPropertyCount}
          taxableReitSubsidiaries={taxableReitSubsidiaries}
        />
      </MotifModal>
      <MotifModal
        show={showEndForm}
        aria-label="End Property"
        size="md"
        variant="alt"
        onClose={handleCloseEndForm}
      >
        <EndPropertyModal
          onSubmit={handleSubmit}
          initialValues={formProperty}
          formPurpose={formPurpose}
          handleDismissErrorMessage={handleDismissErrorMessage}
          handleCloseEndForm={handleCloseEndForm}
        />
      </MotifModal>
      <MotifModal
        show={showClearEndDateForm}
        aria-label="Clear End Date"
        size="md"
        variant="alt"
        onClose={handleCloseEndForm}
      >
        <ClearEndDateForm
          onSubmit={handleSubmit}
          initialValues={formProperty}
          formPurpose={formPurpose}
          handleDismissErrorMessage={handleDismissErrorMessage}
          handleCloseForm={handleCloseEndForm}
        />
      </MotifModal>
    </React.Fragment>
  );
};

PropertyList.propTypes = {
  period: PropTypes.object,
  properties: PropTypes.array,
  property: PropTypes.object,
  formProperty: PropTypes.object,
  showForm: PropTypes.bool,
  handleCloseForm: PropTypes.func,
  handleNewButtonClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleEditButtonClick: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  showSuccess: PropTypes.bool,
  isTestingEnabled: PropTypes.bool,
  canAdd: PropTypes.bool,
  canEdit: PropTypes.bool,
  handleDismissErrorMessage: PropTypes.func,
  handleDismissSuccessMessage: PropTypes.func,
  periodPropertyCount: PropTypes.number,
  hideSuccessMessage: PropTypes.bool,
  showComponent: PropTypes.bool,
  handleCloseComponent: PropTypes.func,
  handleOpenComponent: PropTypes.func,
  taxableReitSubsidiaries: PropTypes.array,
  tbMode: PropTypes.number,
  showEndForm: PropTypes.bool,
  handleShowEndForm: PropTypes.func,
  handleCloseEndForm: PropTypes.func,
  formPurpose: PropTypes.string,
  showClearEndDateForm: PropTypes.bool,
  lookupTypes: PropTypes.object,
  handleShowClearEndDateForm: PropTypes.func.isRequired,
  handleOpenDeleteProperty: PropTypes.func,
  showDeletePropertyModal: PropTypes.bool,
  deletePropertyDetail: PropTypes.object,
  handleYesDeleteProperty: PropTypes.func,
  handleNoDeleteProperty: PropTypes.func,
  handleGridChanges: PropTypes.func,
};

export default PropertyList;
