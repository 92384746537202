import * as actions from '../actions/actionTypes';
import initialState from './initialState';

const pendingTasksReducer = (state = initialState.pendingTasks, action) => {
    switch (action.type) {
        case actions.TASK_BEGIN:
            // Increment the pending tasks count by 1
            return state + 1;
        case actions.TASK_END:
            // Decrement the pending tasks count by 1, but ensure it doesn't go below 0
            return Math.max(0, state - 1);
        case actions.UPDATE_PENDING_TASKS:
            return Math.max(state + action.payload, 0);
        default:
            // Return the current state if the action type is not recognized
            return state;
    }
};

export default pendingTasksReducer;