import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged,handleFirstDataRendered,handleGridReady,handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomPagination from '../../common/MotifPagination';
import TrialBalanceCellRenderer from "./customRenderer/TrialBalanceCellRenderer";
import { toPercentage } from "../../common/NumberFormatting";
import CustomHeaderTemplate from "../checklistDeliveryCalendar/customHeaders/CustomHeaderTemplate.js";
import { MotifTable } from "@ey-xd/motif-react";

const TrialBalanceTable = ({ rows, trialBalanceSummary, tableWidth, showReit,  showTrialBalanceAdjustmentsModal,
     canEdit
}) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [trialBalance, setTrialBalance] = useState([]);
    // const [showReit, setShowReit] = useState(false);
    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
                minWidth: 65,
                filter: true,
                resizable: true,
                sortable: true,
                wrapText: true,
                autoHeight: true,
        };
    }, []);

    const customFilterValueGetter = (amount) => {
            const roundedAmount = Math.sign(amount) * Math.round(Math.abs(amount));
            let value;
            if (roundedAmount < 0) {
                value = roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                value = "($" + value.replace('-', '') + ")";
            }
            else {
                value = "$" + roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            return value;
        
     }

    const getColumnDefs = () => {
        return [
            {
                headerName: `Overview of Uploaded Trial Balances for ${trialBalance.reitName} for ${trialBalance.reportPeriodDescription}`,
                marryChildren: true,
                children: [
                  { field:'chartName', headerName: 'Chart',  
                    sortable: true, showRowGroup: false, 
                    headerComponentParams: {
                        template: CustomHeaderTemplate("chartName"),
                      },
                    hide: false, rowGroup: false, minWidth: 150,  pinned:'left',},
                  { field:'clientAccountNumber', headerName: 'Client Account Number', 
                    sortable: true, showRowGroup: false, 
                    headerComponentParams: {
                        template: CustomHeaderTemplate("clientAccountNumber"),
                      },
                    hide: false, rowGroup: false, minWidth: 250,  pinned:'left',},
                  { field: 'clientAccountName', headerName: 'Client Account Name', 
                    sortable: true, showRowGroup: false, 
                    headerComponentParams: {
                        template: CustomHeaderTemplate("clientAccountName"),
                      },
                    hide: false, rowGroup: false, minWidth: 250,  pinned:'left',},    
                  { field: 'reitTestingAttributeDescription', headerName: 'REIT Testing Attribute',  
                    sortable: true, showRowGroup: false, 
                    headerComponentParams: {
                        template: CustomHeaderTemplate("reitTestingAttributeDescription"),
                      },
                    hide: false, rowGroup: false, minWidth: 150,  pinned:'left',
                  },  
                ],
              },
              {
                marryChildren: true,
                children: [
                { 
                    field:'periodTotalCell.amount', headerName: 'Period Total', minWidth: 150, 
                    sortable: true, showRowGroup: false, 
                    hide: false, rowGroup: false, 
                    valueGetter: (params) => {
                        return customFilterValueGetter(params.data.periodTotalCell.amount)
                    },
                    headerComponentParams: {
                        template: CustomHeaderTemplate("periodTotalCell.amount"),
                      },
                    cellClassRules: {
                        "tb-reit-property": params => params.data
                    },
                },
                { 
                    field:'reitCell.amount', headerName: 'REIT', minWidth: 100, 
                    sortable: true, showRowGroup: false, 
                    hide: !showReit, 
                    rowGroup: false, 
                    headerClass: 'tb-reit-prop-header',
                    cellRenderer: TrialBalanceCellRenderer,
                    cellRendererParams:{canEdit: canEdit, showTrialBalanceAdjustmentsModal: showTrialBalanceAdjustmentsModal},
                    cellClassRules: {
                        "tb-ag-cell-client-mapping-na": params => params.data.reitCell && !(params.data.reitCell.isAdjustable && canEdit),
                        "tb-reit-property": params => params.data.reitCell && params.data.reitCell.isAdjustable
                    },
                    headerComponentParams: {
                        template: CustomHeaderTemplate("reitCell.amount"),
                      },
                    valueGetter: (params) => {
                       return customFilterValueGetter(params.data.reitCell?.amount);
                    }
                   
                },
                ...trialBalanceSummary.propertyHeaders.map((property, index) => (
                    {
                        field:`propertyCells[${index}].amount`,
                        headerName: '',
                        headerValueGetter: (params) => {
                            return property.propertyName + " "+  toPercentage(property.percentageOfOwnership)
                        },
                        headerClass: 'tb-reit-prop-header ',
                        minWidth: 150, 
                        sortable: false, // temporary fix for sorting issue of property columns
                        showRowGroup: false, 
                        hide: false, rowGroup: false, 
                        cellRenderer: TrialBalanceCellRenderer,
                        cellRendererParams: { showTrialBalanceAdjustmentsModal: showTrialBalanceAdjustmentsModal, index: index,
                        canEdit: canEdit, property: property},
                        cellClassRules: { 
                            "tb-ag-cell-client-mapping-na": params => !(params.data.propertyCells[index === undefined ? 0 : index]?.isAdjustable && canEdit),
                            "tb-reit-property": params => params.data.propertyCells[index === undefined ? 0 : index]?.isAdjustable,
                        },
                        valueGetter: (params) => {
                            return customFilterValueGetter(params.data.propertyCells[index === undefined ? 0 : index]?.amount);
                        }
                    }
                )),
                ],
              },

        ];
    };
    //Handle deselect all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);      
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val-1);
    };

    const handleGridChanges = (gridRefApi) => {
        if (gridRefApi) {
            gridRefApi.refreshCells();
        }
    }

    useEffect(() => {
        handleGridChanges(gridRef && gridRef.current ? gridRef.current.api : null);
        
        if(rowData.length > 0 && gridRef.current.api !== undefined){
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);

    useEffect(() => {
        setRowData(rows);
        setTrialBalance(trialBalanceSummary);
        if (gridRef && gridRef.current && gridRef.current.api) {
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            getColumnDefs();
        }
    }, [rows]);

    const rowHeight = 76; 
    const headerHeight = 90; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return(
        <div>
                {(rowData.length > 0 ) &&
                    <div>
                        <div className="row">
                            <div className="col">
                                <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                                    <MotifTable zebra={true} compact={true}
                                        rowData={rowData}
                                        columnDefs={getColumnDefs()}
                                        defaultColDef={defaultColDef}
                                        accentedSort={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        onGridReady={event => handleGridReady(event, gridRef,setCurrentPage,setItemsPerPage,setTotalPages,'REITSuiteTrialBalanceListColumnState', handleDeselectAll)}
                                        onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                        ref={gridRef}
                                        pagination={true}
                                        suppressPaginationPanel={true}
                                        paginationPageSize={100}
                                        groupDisplayType={'custom'}
                                        groupSelectsChildren={true}
                                        groupDefaultExpanded={0}
                                        onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                        data-testid="divTrialBalanceListGrid"
                                        immutableData={true}
                                        getRowNodeId={(data) => data.chartName + '-' + data.clientAccountNumber+'-' + data.reitTestingAttributeDescription}
                                    />
                                    <div className="mt-3">                 
                                        <span className="right mb-3">
                                            <CustomPagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                totalPages={totalPages}
                                                handleItemsPerPageChange={handleItemsPerPageChange}
                                                gridRef={gridRef}
                                                setCurrentPage={setCurrentPage}
                                                setItemsPerPage={setItemsPerPage}
                                                setTotalPages={setTotalPages}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* {(clients && clients.length === 0 && clientSearchText === "" && clientsAreLoaded && <div><h5>It looks as though you aren't assigned to any clients right now - please contact your manager.</h5></div>) || ((clients && clients.length === 0 && clientSearchText !== "" && clientsAreLoaded) && <div><h5>No clients match your search text '{clientSearchText}'</h5></div>) || null} */}

            </div>
    )

};

TrialBalanceTable.propTypes = {
    trialBalanceSummary: PropTypes.object,
    showTrialBalanceAdjustmentsModal : PropTypes.func,
};

export default TrialBalanceTable;
