import React from 'react';
import { MotifTextLink, MotifIcon, MotifButton } from '@ey-xd/motif-react';
import { IconoirOpenNewWindow } from '@ey-xd/motif-icon';
import { Link } from 'react-router-dom';

export default (props) => {
  const isStandardTemplate = props.data ? props.data.isStandardTemplate : false;
  const templateId =
    props.data && props.data.checklistTemplateID
      ? props.data.checklistTemplateID
      : null;
  const isEditMode = props.data ? props.data.isEditMode : false;

  const reitId = 1;
  const reportPeriodID = -1;
  const targetUrl = `/client/${props.clientId}/customPsqTemplate/template/${templateId}/preview`;
  //TODO - This will be implemented after DB migration
  return (
    <div>
      {
        <div>
          <Link to={`#`} rel="noopener noreferrer" onClick={() => props.handlePreview(templateId, targetUrl)}>	
            {' '}
             <MotifIcon className="mt-2 linkIcon" iconFunction={IconoirOpenNewWindow} size='24'/>
          </Link>
        </div>
      }
    </div>
  );
};
