export const allowedDomain = (email, allowedDomains, allValues) => {
  if (email) {
    const atIndex = email.indexOf('@');
    if (atIndex < 1) {
      return undefined;
    }

    let domains = allowedDomains.split(',');
    let emailDomain = email.substr(atIndex + 1).toLowerCase();

    // First, check if domain is in the allowed list
    // But only if allValues not passed
    if (domains.some(x => x.trim() === emailDomain.trim()) && !allValues) {
      return undefined;
    }

    // ^(?!.{256})((?:[a-z0-9]?(?:[a-z0-9-]{0,61}[a-z0-9])\.)+(?:(ey[.]com)))|(^ey[.]com)$
    const eySubDomainsRegExp = new RegExp(
      `
      ${/* Match DNS named host domain having one or more subdomains. */ ''}
      ${/* See: http://stackoverflow.com/a/7933253/433790 */ ''}
      ^(
      (?!.{256})            ${/* Whole domain must be 255 or less. */ ''}
      ((?:                  ${/* One or more sub-domains. */ ''}
        [a-z0-9]            ${/* Subdomain begins with alpha-num. */ ''}
        ?(?:                 ${/* Optionally more than one char. */ ''}
          [a-z0-9-]{0,61}   ${/* Middle part may have dashes. */ ''}
          [a-z0-9]          ${/* Starts and ends with alpha-num. */ ''}
         )\\.                ${/* Subdomain length from 1 to 63 and required dot separates subdomains. */ ''}
      )+                    ${/* End one or more sub-domains. */ ''}
      (?:
        (ey[.]com)          ${/* ey.com domain only */ ''}
      ))                    ${/* End top level domain. */ ''}
      |(^ey[.]com)          ${/* OR allow ey.com without subdomain */ ''}
      )$                     ${/* Anchor to end of string. */ ''}
      `.replace(/\s|\n/g, ''), // Remove white-space and line breaks in Regular Expression
      'i', // ignore case
    );

    if (!allValues || (allValues && allValues.role !== '4')) {
      // No matter what is in the allowed list, we will allow the following:
      // Allow "ey.com" or any subdomain of ey.com (*.ey.com)
      // Subdomain portion must begin and end with Letter or Number (not dashes)
      if (emailDomain && !eySubDomainsRegExp.test(emailDomain)) {
        return `Email ending in '${emailDomain}' is not allowed.`;
      }
    } else if (allValues && allValues.role == '4') {
      // If we are authorizing users, and they are attempting to add an External Checklist User (role==4)
      // then we want to ensure they are NOT ey.com domain or subdomains
      if (emailDomain && eySubDomainsRegExp.test(emailDomain)) {
        return `Email ending in '${emailDomain}' is not allowed.`;
      }
    }

    return undefined;
  }
  return undefined;
};
