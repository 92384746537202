import React from 'react';
import PropTypes from 'prop-types';
import ReitsPage from '../../containers/reits/ReitsPage';

const ClientDetails = ({ client }) => {
  return (
    <ReitsPage clientId={client.id || 0} client={client} />
  );
};

ClientDetails.propTypes = {
  client: PropTypes.object.isRequired
};

export default ClientDetails;
