import React from 'react';
import { useNavigate, useLocation, useParams, useMatches, Outlet } from 'react-router-dom';
import ConditionalMsalProvider from './ConditionalMsalProvider';

// This component will also optionally inject MsalProvider based on the route
const CustomRouterProvider = () => {
  const router = {
    navigate: useNavigate(),
    location: useLocation(),
    params: useParams(),
    matches: useMatches()
  };

  window.routerContext = router;
  
  return (
    <ConditionalMsalProvider>
      <Outlet />
    </ConditionalMsalProvider>
  );
};

export default CustomRouterProvider;
