import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button, Message, Icon } from 'semantic-ui-react';
import { MotifFormField, MotifSearch, MotifLabel, MotifToggleSwitch, MotifToast, MotifButton, MotifTable } from '@ey-xd/motif-react';
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged,handleFirstDataRendered,handleGridReady,tableHeaderClass,handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import ReitsCellRenderer from "./customRenderer/ReitsCellRenderer";
import CustomPagination from '../../common/MotifPagination';
import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate";

const ReitList = ({
  clientId,
  client,
  reits,
  getCurrentPeriodFunction,
  handleSearchChange,
  handleClearSearchText,
  reitSearchText,
  handleShowInactive,
  showInactive,
  canInactivateReits,
  handleToggleActiveFlag,
  handleDownloadIdList,
  handleDownloadTbTemplates,
  showSuccessMessage,
  handleDismissSuccessMessage,
  successMessageText,
  chartExistsForClient,
  handleGridChanges,
  theme 
}) => {
  let allOrActive = 'Active';
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  if (showInactive) {
    allOrActive = 'All';
  }
//Default column settings
const defaultColDef = useMemo(() => {
  return {
      flex: 1,
      minWidth: 65,
      filter: true,
      resizable: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
  };
}, []);

let getColumnDefs =
   [
      {
          field: 'reitName', headerName: 'REIT NAME', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 300,
          cellRenderer: ReitsCellRenderer,headerComponentParams: {
            template: CustomHeaderTemplate("reitName"),
          },
          filterValueGetter: params => { return params.data && params.data.reitName; },

      },  
      {
        field: 'reportPeriodDescription', headerName: 'CURRENT PERIOD', sortable: true, 
        showRowGroup: false, hide: false, rowGroup: false, 
        minWidth: 250,
        cellRenderer: ReitsCellRenderer,
        valueGetter: params => {
          if(params.data.periods && params.data.periods.length > 0){
          return params.data && params.data.periods[0].reportPeriodDescription;
          }
          else{
            return "Not Found";
          }
        },
        headerComponentParams: {
          template: CustomHeaderTemplate("reportPeriodDescription"),
        },
        filterValueGetter: params => { 
          if(params.data.periods && params.data.periods.length > 0){
            return params.data && params.data.periods[0].reportPeriodDescription;
            }
        },
      },                 
     
      {
          field: 'reportPeriodStatusDescription', headerName: 'STATUS', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
          minWidth: 200,
          cellRenderer: ReitsCellRenderer,
          valueGetter: params => {
            if(params.data.periods && params.data.periods.length > 0){
              return params.data && params.data.periods[0].reportPeriodStatusDescription;
              }
          },
          headerComponentParams: {
            template: CustomHeaderTemplate("reportPeriodStatusDescription"),
          },
          filterValueGetter: params => { 
            if(params.data.periods && params.data.periods.length > 0){
              return params.data && params.data.periods[0].reportPeriodStatusDescription;
              }
              else{
                return "Not Found";
              }
          },

      },
      {
          field: 'reitTrackingID', headerName: 'TRACKING ID', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
          minWidth: 250,headerComponentParams: {
            template: CustomHeaderTemplate("reitTrackingID"),
          },
      },
  ];

  if (showInactive) {
    getColumnDefs.push(   {
      field: 'isActive', headerName: 'Active', sortable: true, showRowGroup: false, rowGroup: false,
      minWidth: 150, 
      cellRenderer: ReitsCellRenderer,
      cellRendererParams: { handleToggleActiveFlag },
      hide: !showInactive,
      headerComponentParams: {
        template: CustomHeaderTemplate("isActive"),
      },
  },);
}

//Handle deselect all event
const handleDeselectAll = (val) => {
  // set current page and total pages to 0
  setCurrentPage(0);    
  setTotalPages(0);      
};

//Handle Grid Page change event
const handlePageChange = (val) => {
setCurrentPage(val);
gridRef.current.api.paginationGoToPage(val-1);
};

const [columnDefs, setColumnDefs] = useState(getColumnDefs);
useEffect(() => {
  handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
  if(rowData.length > 0 && gridRef.current.api !== undefined){
    let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
    setTotalPages(calcTotalPages);
    //If total page is zero then set the current page as zero
    if (calcTotalPages == 0) {
        setCurrentPage(calcTotalPages);
    }
    gridRef.current.columnApi.setColumnVisible('isActive', showInactive);
    gridRef.current.api.refreshCells();
  }
}, [rowData]);

useEffect(() => {     
  setRowData(reits);
  if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.applyTransaction({          
      });
      // Get total pages from Grid's default pagination control and apply it to custom pagination control
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
  }
}, [reits]);

useEffect(() => {
      setColumnDefs(getColumnDefs);
      localStorage.removeItem('REITSuiteReitListColumnState');

      // set the current page to 1 and reset the grid to first page
      if (gridRef.current?.api) {
        setCurrentPage(1);
        gridRef.current.api.paginationGoToPage(0);
    }
}, [ showInactive]);
  const clientIsAudit = client && client.isAudit;

  const GetCurrentQuarter = () => {
    let currentQuarter = 'Q';
    let today = new Date();

    switch (today.getMonth()) {
      case 0:
      case 1:
      case 2:
        currentQuarter += '1';
        break;

      case 3:
      case 4:
      case 5:
        currentQuarter += '2';
        break;

      case 6:
      case 7:
      case 8:
        currentQuarter += '3';
        break;

      case 9:
      case 10:
      case 11:
        currentQuarter += '4';
        break;

      default:
        currentQuarter += '1';
        break;
    }

    currentQuarter += ' ' + (today.getYear() + 1900);

    return currentQuarter;
  };

  const downloadIdList = () => handleDownloadIdList(client.id, showInactive, `ID List for ${client.name}.xlsx`);

  const downloadTbTemplates = () => handleDownloadTbTemplates(client.id, showInactive);

  const rowHeight = 79; 
  const headerHeight = 55; 
  const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default if compact={false}

  return (
    <React.Fragment>
      {showSuccessMessage &&
        <MotifToast  className="mt-2 mb-2" variant="success" onClose={handleDismissSuccessMessage}>{successMessageText}</MotifToast>
      }
      <div className="row align-items-center">
        <div className="col-md-4">
          <h2>REITs</h2>
        </div>
        <div className="col-md-5 text-right">
          {canInactivateReits &&
            <React.Fragment>
              <MotifLabel
                htmlFor="switchInactive"
                className="text-right"
                style={{ display: 'inline-block', marginRight: '1rem', width: 'auto' }}>
                Display Inactive REITs:
            </MotifLabel>
            <MotifFormField style={{ display: 'inline-block', width: 'auto' }}>
              <MotifToggleSwitch
                  aria-label="Display Inactive REITs toogle"
                name="switchInactive"
                id="switchInactive"
                onChange={handleShowInactive}
                checked={!!showInactive} />
            </MotifFormField>
            </React.Fragment>
          }
        </div>
        <div className="col-md-3">
          <MotifFormField>
            <MotifSearch
              type="text"
              items={[]}
              name="reitFilter"
              onChange={handleSearchChange}
              onSelect={handleClearSearchText}
              value={reitSearchText}
              title="Type in a REIT name"
              searchButtonProps={{tabIndex:"-1"}}
              aria-label="Search for REITs"
              placeholder="Search for REITs"
            />
          </MotifFormField>
        </div>
      </div>
      {!clientIsAudit &&
              <div style={{ width: '100%', textAlign: 'left', marginTop: '15px' }}>
              <span style={{display: 'inline-block', float:'left'}}>
                  <MotifButton variant="secondary" type="button"
                      onClick={downloadIdList}
                      disabled={(allOrActive === 'Active' && reits.length === 0)}>
                          Download {allOrActive} Tracking IDs
                   </MotifButton>
              </span>
              <span style={{display: 'inline-block'}}>
                  <MotifButton variant="secondary" className="ml-2" type="button"
                      onClick={downloadTbTemplates}
                      disabled={!chartExistsForClient}>
                          Download {allOrActive} TB Templates
                  </MotifButton>
              </span>
        </div>}

        <div>
                {(rowData.length > 0 ) &&
                    <div className="mt-2">
                        <div className="row">
                            <div className="col">
                            <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                                    <MotifTable zebra={true} compact={true}
                                        rowData={rowData}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        accentedSort={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        onGridReady={event => handleGridReady(event, gridRef,setCurrentPage,setItemsPerPage,setTotalPages,'REITSuiteReitListColumnState', handleDeselectAll)}
                                        onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                        ref={gridRef}
                                        pagination={true}
                                        suppressPaginationPanel={true}
                                        paginationPageSize={100}
                                        groupDisplayType={'custom'}
                                        groupSelectsChildren={true}
                                        groupDefaultExpanded={0}
                                        onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                        data-testid="divClientListGrid"
                                        deltaRowDataMode={true} 
                                        immutableData={true}
                                        getRowNodeId={(data) => data.reitid}
                                    />
                                    <div className="mt-3">                 
                                      <span className="right mb-3">
                                          <CustomPagination
                                              currentPage={currentPage}
                                              handlePageChange={handlePageChange}
                                              totalPages={totalPages}
                                              handleItemsPerPageChange={handleItemsPerPageChange}
                                              gridRef={gridRef}
                                              setCurrentPage={setCurrentPage}
                                              setItemsPerPage={setItemsPerPage}
                                              setTotalPages={setTotalPages}/>
                                      </span>
                                  </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                }
                {(reits && reits.length === 0 && reitSearchText === ""
                && <div className="mt-3">No REIT found for this client.</div>) 
                || ((reits && reits.length === 0 && reitSearchText !== "") 
                && <div className="mt-3"><h5>No REITs match your search text '{reitSearchText}'</h5></div>) || null}
            </div>
    </React.Fragment>
  );
};

ReitList.propTypes = {
  clientId: PropTypes.number.isRequired,
  client: PropTypes.object.isRequired,
  reits: PropTypes.array.isRequired,
  getCurrentPeriodFunction: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleClearSearchText: PropTypes.func.isRequired,
  reitSearchText: PropTypes.string,
  handleShowInactive: PropTypes.func.isRequired,
  showInactive: PropTypes.bool,
  canInactivateReits: PropTypes.bool,
  handleToggleActiveFlag: PropTypes.func.isRequired,
  handleDownloadIdList: PropTypes.func.isRequired,
  handleDownloadTbTemplates: PropTypes.func.isRequired,
  handleDismissSuccessMessage: PropTypes.func,
  showSuccessMessage: PropTypes.bool,
  successMessageText: PropTypes.string,
};

export default ReitList;
