import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_QUEUE_COUNT_SUCCESS and LOAD_QUEUE_COUNT_FAILURE actions and sets the processing queue count in the store
 * @param {Object} [state] The processing queue count currently in the store, or the initial state of the processing queue count
 * @param {Object} action The action to be handled
 * @returns {Object} The processing queue count
 */
export default function lastClickedTabChecklistQuestionId(state = initialState.lastClickedTabChecklistQuestionId, action) {
    switch (action.type) {
        case actions.LAST_CLICKED_TAB_CHECKLIST_QUESTION_ID:
            return action.questionId;
        default:
            return state;
    }
}