import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import * as reitActions from './reitActions';
import { fetchFromReitApi as fetch } from "./fetchFromApi";
import * as actionHelpers from '../scripts/actionHelpers';
import * as clearActions from './clearDependentsActions';

const createTypes = [
    "Previous",
    "Next",
    "Default"
];

const periodStatuses = {
    NotStarted: "Not Started",
    Planned: "Planned",
    InProgress: "In Progress",
    ClosedToTesting: "Closed to Testing",
    ClosedToReporting: "Closed to Reporting",
    Complete: "Complete"
};

export { createTypes };

export { periodStatuses };

/**
 * Gets a period from the REIT
 * @param {Number} periodId The period ID
 * @param {Object} reit The REIT that contains the period
 * @returns {Function} A function that dispatches an action
 */
export function getPeriodFromReit(periodId, reit) {
    return function (dispatch) {
        let period = null;
        if (reit && Array.isArray(reit.periods) && periodId) {
            period = reit.periods.find(item => item.reportPeriodID === parseInt(periodId));
        }

        clearActions.clearProperty(dispatch);
        dispatch({ type: actions.LOAD_PERIOD_SUCCESS, period });
    };
}

/**
 * Creates a new period.
 * @param {any} reitId The REIT id
 * @param {number} quarter A number between 1 and 4 representing the quarter portion of the period to create
 * @param {number} year The year portion of the period to create
 * @param {bool} shouldRefresh True to reload the REIT, otherwise false.
 * @returns {Promise} A Promise
 */
export function createPeriod(reitId, quarter, year, shouldRefresh) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const payload = {
            "reitId": reitId,
            "quarter": quarter,
            "year": year
        };

        const isShouldRefresh = shouldRefresh;
        return fetch(`/periods`,
            {
                headers: {
                    'Accept':
                        "application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    'Content-Type': "application/json"
                },
                method: "POST",
                body: JSON.stringify(payload)
            }).then(response => {
                return response.json();
            }).then(checklist => {
                if (actionHelpers.isErrorResponse(checklist)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPDATE_CHECKLIST_FAILURE, checklist);
                }

                if (isShouldRefresh) {
                    reitActions.fetchReit(reitId)(dispatch);
                }

                dispatch({ type: actions.UPDATE_CHECKLIST_SUCCESS, checklist, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPDATE_CHECKLIST_FAILURE, null, error);
            });
    };
}

/**
 * Gets the current period
 * @param {any} reit The REIT to retrieve the period for.
 * @returns {any} The period.
 */
export function getCurrentPeriod(reit) {
    if (!reit || !Array.isArray(reit.periods)) {
        return null;
    }

    const period = reit.periods.sort((a, b) => new Date(b.endDate) - new Date(a.endDate)).find(x => x.reportPeriodTypeDescription.toLowerCase() === 'quarterly');

    return period;
}

/**
 * Saves a period to the REIT API database.
 * @param {any} periodId The period ID
 * @param {any} status The status of the period.
 * @param {bool} isShouldRefresh True to reload the REIT, otherwise false.
 * @param {any} trialBalanceMode The trial balance mode.
 * @returns {Promise} A Promise
 * http://localhost:10356/api/periods/4
 */
export function savePeriod(periodId, status, isShouldRefresh, trialBalanceMode) {
    if (!trialBalanceMode) {
        trialBalanceMode = 0; //Unknown Mode
    }

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const payload = {
            "status": status,
            "trialBalanceMode": trialBalanceMode
        };

        return fetch(`/periods/${periodId}`,
            {
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json"
                },
                method: "PUT",
                body: JSON.stringify(payload)
            }).then(response => {
                return response.json();
            }).then(period => {
                if (actionHelpers.isErrorResponse(period)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPDATE_PERIOD_STATUS_FAILURE, period);
                }

                dispatch({ type: actions.UPDATE_PERIOD_STATUS_SUCCESS, period, [pendingTask]: end });
                if (isShouldRefresh) {
                    reitActions.fetchReit(period.reitid)(dispatch);
                }
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPDATE_PERIOD_STATUS_FAILURE, null, error);
            });
    };
}

/**
 * Fetch period by ID. If successful this will dispatch the LOAD_PERIOD_SUCCESS
 * action, otherwise it will dispatch the LOAD_PERIOD_FAILURE action.
 * @param {number} periodId The ID of the period.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchPeriodById(periodId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/periods/${periodId}`).then(response => {
            return response.json();
        }).then(period => {
            if (actionHelpers.isErrorResponse(period)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PERIOD_FAILURE, period);
            }

            dispatch({ type: actions.LOAD_PERIOD_SUCCESS, period, [pendingTask]: end });
            return period;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PERIOD_FAILURE, null, error);
        });
    };
}

/**
 * Fetch period by ID.
 * @param {number} periodId The ID of the period.
 * @returns {funtion} A function that returns a Promise.
 */
export function fastFetchPeriodById(periodId) {
    return fetch(`/periods/${periodId}`).then(response => {
        return response.json();
    }).catch(error => {
        throw error;
    });
}

/**
 * Fetch period by ID.
 * @param {number} periodId The ID of the period.
 * @param {string} checklistId The id of the checklist
 * @param {string} checklistType The type of the checklist
 * @returns {function} A function that returns a Promise.
 */
export function fastFetchExternalPeriodById(periodId, checklistId, checklistType) {
    const authHeader = actionHelpers.buildAuthHeader(checklistId, "ARQC");
    return fetch(`/periods/${periodId}`, {
        headers: authHeader && new Headers({ 'Authorization': authHeader })
    }).then(response => {
        return response.json();
    }).catch(error => {
        throw error;
    });
}

/**
 * Fetch period by ID.
 * @param {number} periodId The ID of the period.
 * @param {string} checklistId The id of the checklist
 * @param {string} checklistType The type of the checklist
 * @returns {function} A function that returns a Promise.
 */
export function fetchExternalPeriodById(periodId, checklistId, checklistType) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const authHeader = actionHelpers.buildAuthHeader(checklistId, checklistType);
        return fetch(`/periods/${periodId}`, {
            headers: authHeader && new Headers({ 'Authorization': authHeader })
        }).then(response => {
            return response.json();
        }).then(period => {
            if (actionHelpers.isErrorResponse(period)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PERIOD_FAILURE, period);
            }

            return dispatch({ type: actions.LOAD_PERIOD_SUCCESS, period, [pendingTask]: end });
        }).catch(error => {
            throw error;
        });
    }
}

/**
 * Determines if testing is enabled for a period.
 * @param {any} period The period
 * @returns {bool} False if the period is closed to testing, closed to reporting, or complete, otherwise true.
 */
export function isTestingEnabled(period) {
    if (period.reportPeriodStatusDescription == periodStatuses.ClosedToTesting
        || period.reportPeriodStatusDescription == periodStatuses.ClosedToReporting
        || period.reportPeriodStatusDescription == periodStatuses.Complete) {

        return false;
    }
    return true;
}

/**
 * Determines if reporting is enabled for a period
 * @param {any} period The period
 * @returns {bool} False if the period is closed to reporting or complete, otherwise true.
 */
export function isReportingEnabled(period) {
    if (period.reportPeriodStatusDescription == periodStatuses.ClosedToReporting
        || period.reportPeriodStatusDescription == periodStatuses.Complete) {
        return false;
    }
    return true;
}

/**
 * Determines if file upload is enabled for a period
 * @param {any} period The period
 * @returns {bool} False if the period is complete, otherwise true.
 */
export function isFileUploadEnabled(period) {
    if (period.reportPeriodStatusDescription == periodStatuses.Complete) {
        return false;
    }
    return true;
}


/**
 * Fetch Report periods by Client Id. If successful this will dispatch the LOAD_PERIODS_BY_CLIENT_ID_SUCCESS
 * action, otherwise it will dispatch the LOAD_PERIODS_BY_CLIENT_ID_FAILURE action.
 * @param {number} clientId The ID of the report period.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchReportPeriodsByClientId(clientId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/periods/client/${clientId}`).then(response => {
            return response.json();
        }).then(periods => {
            if (actionHelpers.isErrorResponse(periods)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PERIODS_BY_CLIENT_ID_FAILURE, periods);
            }

            dispatch({ type: actions.LOAD_PERIODS_BY_CLIENT_ID_SUCCESS, periods, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PERIODS_BY_CLIENT_ID_FAILURE, null, error);
        });
    };
}
