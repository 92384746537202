import React from "react";
import PropTypes from "prop-types";

const NewOrRemovedAccountDetailsRow = ({ row }) => {
    return (
        <tr>
            <td colSpan="2">{row.clientAccountDescription}</td>
     </tr>
    );
};

NewOrRemovedAccountDetailsRow.propTypes = {
    row: PropTypes.shape({
        balance: PropTypes.number.isRequired,
        clientAccountDescription: PropTypes.string.isRequired,
        clientAccountCode: PropTypes.string.isRequired,
        reitTestingAttributeCode: PropTypes.string.isRequired,
        reitTestingAttributeDescription: PropTypes.string.isRequired,
        type: PropTypes.number.isRequired
    })
};

export default NewOrRemovedAccountDetailsRow;