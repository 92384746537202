import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'semantic-ui-react';
import ErrorSummary from '../shared/ErrorSummary';
import TrialBalanceSummaryTable from "./TrialBalanceSummaryTable";

const TrialBalanceSummary = ({ trialBalanceSummary, handleHideZeroBalanceRows, hideZeroBalanceRows, handleDownloadTrialBalanceSummary, showTrialBalanceAdjustmentsModal, tbMode, canEdit }) => {
    return (<TrialBalanceSummaryTable trialBalanceSummary={trialBalanceSummary} handleHideZeroBalanceRows={handleHideZeroBalanceRows} hideZeroBalanceRows={hideZeroBalanceRows} 
        handleDownloadTrialBalanceSummary={handleDownloadTrialBalanceSummary} showTrialBalanceAdjustmentsModal={showTrialBalanceAdjustmentsModal} tbMode={tbMode} canEdit={canEdit} />);
};

TrialBalanceSummary.propTypes = {
    hideZeroBalanceRows: PropTypes.bool.isRequired,
    trialBalanceSummary: PropTypes.object.isRequired,
    handleHideZeroBalanceRows: PropTypes.func.isRequired,
    handleDownloadTrialBalanceSummary: PropTypes.func.isRequired,
    showTrialBalanceAdjustmentsModal: PropTypes.func.isRequired,
    canEdit: PropTypes.number.isRequired,
    tbMode: PropTypes.number.isRequired
};

export default TrialBalanceSummary;


