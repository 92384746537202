import { addTagNodes } from '../../scripts/prosemirror-mentions';
import { ProseMirror } from '@progress/kendo-react-editor';
const { Schema } = ProseMirror;

const DefaultFontFamilies =
    'font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif';

const DefaultParagraphFontSize = 'font-size : 10pt';
const DefaultHeadingFontSize = 'font-size : 13pt';
const ParagraphMargin = 'margin : 0';
const DefaultHeadingColor = 'color: #2f5496';

export const buildSchema = function (originalSchema) {
    const mySchema = new Schema({
        nodes: addTagNodes(originalSchema.spec.nodes),
        marks: originalSchema.spec.marks
    });

    const paragraph = { ...mySchema.spec.nodes.get('paragraph') };

    paragraph.attrs['style'] =
    {
        default:
            `${DefaultFontFamilies}; ` +
            `${DefaultParagraphFontSize}; ` +
            `${ParagraphMargin};`
    };

    mySchema.spec.nodes.update('paragraph', paragraph);

    const heading = { ...mySchema.spec.nodes.get('heading') };

    heading.attrs['style'] = {
        default:
            `${DefaultFontFamilies}; ` +
            `${DefaultHeadingFontSize}; ` +
            `${DefaultHeadingColor};`
    }

    mySchema.spec.nodes.update('heading', heading);

    return mySchema;
}