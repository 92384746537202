import PropTypes from "prop-types";
import React from "react";
import { Popup } from "semantic-ui-react";
import { Field, reduxForm, getFormValues } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import { MotifToggleSwitch, MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter,  MotifLabel,MotifErrorMessage } from '@ey-xd/motif-react';
import { addAttribute } from "../../common/Attribute";

/**
 * CustomFdrTemplateContainer container component
 * @extends {React.Component}
 */
export class CreateChartOfAccountsModal extends React.Component {
    /**
      * Creates a new CustomFdrTemplateContainer
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);
        this.handleIsDefault = this.handleIsDefault.bind(this);
        this.handleCopyExistingChart = this.handleCopyExistingChart.bind(this);
    }

    componentDidMount() {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");

        if (this.props.mode === 'create') {
            this.props.dispatch(this.props.initialize(
                {
                    chartName: '',
                    copyExistingChart: false,
                    copyChartID: this.props.chartOfAccounts.charts.length === 0 ? -1 : this.props.chartOfAccounts.charts.find(c => c.isDefault).chartID,
                    isDefault: this.props.chartOfAccounts.charts.length === 0
                },
                { options: { updateUnregisteredFields: true } }
            ));
        } else {
            // const template = this.props.customFdrTemplateFormValues
            //     && this.props.customFdrTemplateFormValues.templateId
            //     && this.props.customFdrTemplateFormValues.templateId !== -1 ?
            //     this.props.customFdrTemplates.templates.find(t => t.fdrTemplateID == this.props.customFdrTemplateFormValues.templateId) : null;
            // //If is saveas set the name with appending -Copy to the template name

            // this.props.dispatch(this.props.initialize(
            //     {
            //         templateId: template.fdrTemplateId,
            //         fdrTemplateName: this.props.mode != 'saveas' ? template.fdrTemplateName : template.fdrTemplateName + '-Copy'
            //     },
            //     { options: { updateUnregisteredFields: true } }
            // ));
        }
    }

    componentDidUpdate(prevProps) {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    }

    handleCopyExistingChart() {
        this.props.dispatch(this.props.change('copyExistingChart', !this.props.createChartModalFormValues.copyExistingChart));
    }

    handleIsDefault() {
        this.props.dispatch(this.props.change('isDefault', !this.props.createChartModalFormValues.isDefault));
    }

    /**
    * Render a React element
    * @returns {Object} A reference to the component
    */
    render() {
        const createEdit = this.props.mode === 'create' ? 'Create New' : 'Edit';
        const hasNoCharts = this.props.chartOfAccounts.charts && this.props.chartOfAccounts.charts.length === 0;

        const chartOfAccountsOptions =
            this.props.chartOfAccounts.charts
                .map(t => {
                    return { key: t.chartID, value: t.chartID, text: `${t.isDefault ? '(Default) ' : ''}${t.chartName}` }
                });
        return (
            <MotifModal
                show={true}
                onClose={this.props.toggleShowCreateTemplateModal}
                variant="alt"
            >
                <MotifModalHeader>
                    {`${createEdit} Chart of Accounts`}
                </MotifModalHeader>
                <MotifModalBody>
                    <form onSubmit={this.props.handleSubmit(this.props.onSubmit)} id="createCamForm">
                        <Field
                            name="chartName"
                            type="text"
                            component={formHelpers.renderField}
                            label="Chart Name"
                            maxLength={255}
                            isRequiredField={true}
                            onChange={this.props.clearDuplicateErrorMessage}
                            validate={[formValidators.required, formValidators.maxLength255]} />
                        {this.props.chartOfAccounts.duplicate
                            &&
                            <MotifErrorMessage>This chart name already exists.</MotifErrorMessage>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                {!hasNoCharts &&
                                    <div className="row">
                                        <div className="col-md-12" style={{ marginTop: '15px' }}>
                                            <span style={{ paddingRight: '15px' }}>Copy from existing chart:</span>
                                            <div
                                                className="switch-box"
                                                style={{
                                                    display: 'inline-block',
                                                    position: 'relative',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <MotifToggleSwitch
                                                    name="copyExistingChart"
                                                    id="copyExistingChart"
                                                    aria-label="Copy from existing chart toggle"
                                                    onChange={this.handleCopyExistingChart}
                                                    checked={this.props.createChartModalFormValues && this.props.createChartModalFormValues.copyExistingChart}
                                                    offLabel={<MotifLabel>Off</MotifLabel>}
                                                    onLabel={<MotifLabel>On</MotifLabel>} />
                                            </div>
                                        </div>
                                    </div>}
                                {this.props.createChartModalFormValues && this.props.createChartModalFormValues.copyExistingChart &&
                                    <div className="row ml-2">
                                        <div className="col-md-12">
                                            <Field
                                                name="copyChartID"
                                                selection
                                                options={chartOfAccountsOptions}
                                                component={formHelpers.SingleSelectComponent}
                                                style={{ minWidth: '275px',  marginTop:"-1rem"}} />
                                        </div>
                                    </div>}
                                <div className="row">
                                    <div className={`col-md-12 ${(this.props.createChartModalFormValues && this.props.createChartModalFormValues.copyExistingChart) ? "margin-top-6" : ""}`}>
                                        <span className={hasNoCharts ? 'text-muted' : ''}>Set as default for the Client:</span>

                                        <Popup
                                            trigger={
                                                <div
                                                    className="switch-box ml-3"
                                                    style={{
                                                        display: 'inline-block',
                                                        position: 'relative',
                                                        marginTop: '15px',
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <MotifToggleSwitch
                                                        name="isDefault"
                                                        id="isDefault"
                                                        aria-label="Set as default for the Client toggle"
                                                        onChange={this.handleIsDefault}
                                                        checked={this.props.createChartModalFormValues && this.props.createChartModalFormValues.isDefault}
                                                        disabled={hasNoCharts}
                                                        offLabel={<MotifLabel>Off</MotifLabel>}
                                                        onLabel={<MotifLabel>On</MotifLabel>} />
                                                </div>
                                            }
                                            content="The first chart created for the Client will automatically be set as the default and cannot be changed."
                                            on='hover'
                                            disabled={!hasNoCharts}
                                            inverted
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton
                        type="button"
                        onClick={this.props.toggleShowCreateTemplateModal}
                        variant="secondary"
                        form="createCamForm"
                    >
                        Cancel
                    </MotifButton>
                    <MotifButton
                        type="submit"
                        variant="primary"
                        disabled={this.props.invalid || this.props.submitting}
                        form="createCamForm"
                    >
                        Save
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        );
    }
}

CreateChartOfAccountsModal.propTypes = {
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    dispatch: PropTypes.func,
    initialize: PropTypes.func,
    toggleShowCreateTemplateModal: PropTypes.func,
    clearDuplicateErrorMessage: PropTypes.func,
    mode: PropTypes.string,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    chartOfAccountsFormValues: PropTypes.object,
    createChartModalFormValues: PropTypes.object,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        createChartModalFormValues: getFormValues('createChartModalForm')(state)
    };
}

export default connect(mapStateToProps)(reduxForm({ form: "createChartModalForm" })(CreateChartOfAccountsModal));