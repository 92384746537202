import { useEffect, useState, useLayoutEffect, useRef } from "react";

// Hook to temporarily toggle a boolean upon a variable change.
export const useEffectToggle = function (
    watchedDependencies,
    defaultValue = true,
    toggleDelayMs = 0
) {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(!defaultValue);
        setTimeout(() => setValue(defaultValue), toggleDelayMs);
    }, watchedDependencies);

    return value;
};

export const useInput = function (initialValue = undefined) {
    const [value, setValue] = useState(initialValue);
    const onChange = (e) => setValue(e.target.value);
    return { value, onChange, setValue };
};

export const useWindowSize = function () {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
};

export const usePrevious = function (value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
};