import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_SERVICES_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function customFdrTemplateReducer(state = initialState.customFdrTemplate, action) {
    switch (action.type) {
        case actions.LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS:
            return Object.assign({}, state, {
                templates: action.customFdrTemplates.fdrTemplates
            });
        case actions.CREATE_CUSTOM_FDR_TEMPLATE_SUCCESS:
            return Object.assign({}, state, {
                //  template: action.template
            });
        case actions.CLEAR_CUSTOM_FDR_TEMPLATE_DUPLICATE:
            return Object.assign({}, state, {
                duplicate: false
            });
        case actions.SET_CUSTOM_FDR_TEMPLATE_DUPLICATE:
            return Object.assign({}, state, {
                duplicate: true
            });
        case actions.LOAD_DEFAULT_FDR_TEMPLATES_SUCCESS:
            return Object.assign({}, state, {
                defaultFdrTemplates: action.defaultFdrTemplates
            });
        case actions.DELETE_TEMPLATE_FAILURE:
            return Object.assign({}, state, {                
                duplicate : false
            });
        case actions.DELETE_TEMPLATE_SUCCESS:{
            let filteredTemplates = state.templates.filter(template => template.fdrTemplateID != action.fdrTemplateId);
            return Object.assign({}, state, {                
                templates : filteredTemplates
            });
        }    
        case actions.UPDATE_CUSTOM_FDR_TEMPLATES_SUCCESS:
            return Object.assign({}, state, {                
                templates: action.updatedFdrTemplateList
            });
        default:
            return state;
    }
}