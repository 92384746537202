import React , { useState }from 'react';
import { Field } from 'redux-form';
import { PropTypes } from "prop-types";
import * as formHelpers from '../../scripts/formHelpers';
import {
    MotifButton,
    MotifDropdown,
    MotifDropdownItem,
    MotifIcon,
    MotifIconButton,
    MotifTooltip,
} from '@ey-xd/motif-react';
import { IconoirNavArrowDown, IconoirInfoCircle, IconoirHelpCircle } from '@ey-xd/motif-icon';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants/other';
import { constants } from 'buffer';
const DataRetentionManagementHeader = ({
    clientId,
    handleDataRetentionActions,
    gridCurrentState,
    isSystemAdmin,
    isEngagementAdminAndDataSteward,
    isEngagementUser,
    handleExportDataDeletionSummary
}) => {
    let selectedApprovedDataDeletionRecords=[];
    let unapproveDataDeletion=[];
    let approveException=[];
    let removeException=[];
   
    const checkDataPurgeStatus = (selectedRecords) => {
       // current status id approved data deletion 
        selectedApprovedDataDeletionRecords = selectedRecords.filter(x => x.stage2DataPurgeStatusID === Constants.dataRetentionManagementApproveActionId);
        if (selectedApprovedDataDeletionRecords.length > 0) {
            actionButtonOptionStatus.approveDataDeletion = false;   
            actionButtonOptionStatus.removeException = false; 
            actionButtonOptionStatus.approveException = false; 
        }    

        // current status id unapprove data deletion
        unapproveDataDeletion = selectedRecords.filter(x => x.stage2DataPurgeStatusID === Constants.dataRetentionManagementUnApproveActionId
                                                         || x.stage2DataPurgeStatusID === Constants.dataRetentionManagementApprovedActionId);
        if (unapproveDataDeletion.length > 0) {
            actionButtonOptionStatus.unapproveDataDeletion = false;   
            actionButtonOptionStatus.removeException = false;      
        }  

        // current status is approved exception 
        approveException = selectedRecords.filter(x => x.stage2DataPurgeStatusID === Constants.dataRetentionManagementApproveExceptionActionId);
        if (approveException.length > 0) {
            actionButtonOptionStatus.approveDataDeletion = false; 
            actionButtonOptionStatus.unapproveDataDeletion = false; 
            actionButtonOptionStatus.approveException = false;  
        }  

        // current status is remove exception 
        removeException = selectedRecords.filter(x => x.stage2DataPurgeStatusID === Constants.dataRetentionManagementRemoveExceptionActionId);
        if (removeException.length > 0) {
            actionButtonOptionStatus.removeException = false; 
            actionButtonOptionStatus.unapproveDataDeletion = false; 
        }
    };   

    let actionButtonOptionStatus = {
        approveDataDeletion: false,
        unapproveDataDeletion: false,
        approveException: false,
        removeException: false,
        exportDataDeletionSummary: true
    };  
        
    let disabledInfoMessageOfNoRowSelection=  Constants.dataRetentionNoRowSelectionMessage;
    let disabledInfoMessageOfNoPermission= Constants.dataRetentionNoPermissionMessage;
    let dataRetentionMessageForApprove= Constants.dataRetentionMessageForApprove;
    let dataRetentionMessageForUnApprove= Constants.dataRetentionMessageForUnApprove;
    let dataRetentionMessageForException= Constants.dataRetentionMessageForException;
    let dataRetentionMessageForRemoveException= Constants.dataRetentionMessageForRemoveException;
    let dataRetentionManagementApproveActionId = Constants.dataRetentionManagementApproveActionId;
    let dataRetentionManagementUnApproveActionId = Constants.dataRetentionManagementUnApproveActionId;
    let dataRetentionManagementApproveExceptionActionId = Constants.dataRetentionManagementApproveExceptionActionId;
    let dataRetentionManagementRemoveExceptionActionId = Constants.dataRetentionManagementRemoveExceptionActionId;

    const [actionOptionsOpen, setActionOptionsOpen] = useState(false);
  
   
    let isChecklistSelected = gridCurrentState && gridCurrentState.some(record => record.reitSelection === true);
    if (isChecklistSelected) { 
        //Get the selected checkist records
        let selectedRecords = gridCurrentState.filter(x => x.reitSelection === true);   
        if(isSystemAdmin){
        actionButtonOptionStatus.approveDataDeletion = true; 
        actionButtonOptionStatus.unapproveDataDeletion = true; 
        actionButtonOptionStatus.approveException = true; 
        actionButtonOptionStatus.removeException = true;     
        actionButtonOptionStatus.exportDataDeletionSummary = true; 
        checkDataPurgeStatus(selectedRecords);    
        }

   else  if(isEngagementAdminAndDataSteward ){
        actionButtonOptionStatus.approveDataDeletion = true; 
        actionButtonOptionStatus.unapproveDataDeletion = true;
        actionButtonOptionStatus.approveException = false; 
        actionButtonOptionStatus.removeException = true;     
        actionButtonOptionStatus.exportDataDeletionSummary = true; 
        checkDataPurgeStatus(selectedRecords);      
        }  

   else  if(isEngagementUser || !isEngagementAdminAndDataSteward){
        actionButtonOptionStatus.approveDataDeletion = false; 
        actionButtonOptionStatus.unapproveDataDeletion = false; 
        actionButtonOptionStatus.approveException = false; 
        actionButtonOptionStatus.removeException = false;     
        actionButtonOptionStatus.exportDataDeletionSummary = true;  
        checkDataPurgeStatus(selectedRecords);      
      }
    }
   
return (
    <>
       
          {/* Header area */}
        <h2 >Data Retention Management         
        <Link to={'/helpGuide/'} target="_blank" rel="noopener noreferrer" >        
        <MotifIcon className="ml-2 mt-2 mr-5 dataretentionIcon"  iconFunction={IconoirHelpCircle} size="24" fill="none" />        
        </Link>     
        </h2>
                <div className="row">
                    <div className="col-md-2"> </div>
                    <div className="col-md-10 d-flex justify-content-end">
                        {/* {gridCurrentState != null && Array.isArray(gridCurrentState) && gridCurrentState.length > 0 &&  */}
                        <div style={{ width: 'fit-content' }}>
                        <MotifDropdown
                            open={actionOptionsOpen}
                            placement="bottom-end"
                            id={'action-dropdown'}
                            aria-labelledby={'data-retention-trigger'}
                            handleClickOutside={() => setActionOptionsOpen(false)}
                            trigger={
                                <MotifButton
                                    type="button"
                                    variant="primary"
                                    onClick={() => setActionOptionsOpen(!actionOptionsOpen)}
                                    aria-controls={'action-dropdown'}
                                    id={'action-dropdown-trigger'}
                                    aria-expanded={actionOptionsOpen}
                                    aria-label="Action dropdown"
                                    data-testid="actions"
                                >
                                    Actions <MotifIcon iconFunction={IconoirNavArrowDown} size="24" fill="none" />
                                </MotifButton>
                            }
                        >
                        
                            <ul role="menu">
                                { (actionButtonOptionStatus.approveDataDeletion) &&
                                    <li role="menuitem">
                                        <MotifDropdownItem type="button"
                                        onClick={() => { handleDataRetentionActions(dataRetentionManagementApproveActionId);}}
                                        disabled={!actionButtonOptionStatus.approveDataDeletion}
                                        data-testid="updateServicesScope">
                                            Approve Data Deletion
                                        </MotifDropdownItem>
                                    </li>
                                    ||
                                    <li role="menuitem">
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem type="button"
                                                onClick={() => { handleDataRetentionActions(dataRetentionManagementApproveActionId);}}
                                                    disabled={!actionButtonOptionStatus.approveDataDeletion} data-testid="approveDataDeletion"
                                                >
                                                    Approve Data Deletion
                                                    <MotifIconButton aria-label="Approve Data Deletion" type="button">
                                                        <MotifIcon iconFunction={IconoirInfoCircle} size="24" fill="none" />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >                                 
                                    {isSystemAdmin || isEngagementAdminAndDataSteward ?
                                        !actionButtonOptionStatus.approveDataDeletion && isChecklistSelected
                                        ? dataRetentionMessageForApprove
                                        : disabledInfoMessageOfNoRowSelection
                                        : disabledInfoMessageOfNoPermission}
                                        </MotifTooltip>
                                    </li>
                                }
                                
                                { (actionButtonOptionStatus.unapproveDataDeletion) &&
                                    <li role="menuitem">
                                        <MotifDropdownItem type="button"
                                            disabled={!actionButtonOptionStatus.unapproveDataDeletion}
                                            onClick={() => { handleDataRetentionActions(dataRetentionManagementUnApproveActionId);}}                                    
                                            data-testid="unapproveDataDeletion">
                                            Unapprove Data Deletion
                                        </MotifDropdownItem>
                                    </li>
                                    ||
                                    <li role="menuitem">
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem type="button"
                                                    disabled={!actionButtonOptionStatus.unapproveDataDeletion} 
                                                    onClick={() => { handleDataRetentionActions(dataRetentionManagementUnApproveActionId);}}                                  
                                                    data-testid="unapproveDataDeletion"
                                                >
                                                    Unapprove Data Deletion
                                                    <MotifIconButton  aria-label="Unapprove Data Deletion" type="button">
                                                        <MotifIcon iconFunction={IconoirInfoCircle} size="24" fill="none" />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >
                                            {isSystemAdmin || isEngagementAdminAndDataSteward ?
                                            !actionButtonOptionStatus.unapproveDataDeletion && isChecklistSelected
                                            ? dataRetentionMessageForUnApprove
                                            : disabledInfoMessageOfNoRowSelection
                                            : disabledInfoMessageOfNoPermission}  
                                                                        
                                        </MotifTooltip>
                                    </li>
                                }

                                {(actionButtonOptionStatus.approveException) &&
                                    <li role="menuitem">
                                        <MotifDropdownItem type="button"
                                            disabled={!actionButtonOptionStatus.approveException}
                                            onClick={() => { handleDataRetentionActions(dataRetentionManagementApproveExceptionActionId);}}                                 
                                            data-testid="approveException">
                                            Approve Exception to Deletion
                                        </MotifDropdownItem>
                                    </li>
                                    ||
                                    <li role="menuitem">
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem type="button"
                                                onClick={() => { handleDataRetentionActions(dataRetentionManagementApproveExceptionActionId);}}
                                                    disabled={!actionButtonOptionStatus.approveException} data-testid="approveException"
                                                >
                                                    Approve Exception to Deletion
                                                    <MotifIconButton aria-label="Approve Exception to Deletion" type="button">
                                                        <MotifIcon iconFunction={IconoirInfoCircle} size="24" fill="none" />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >                                   

                                        {isSystemAdmin  ?
                                            !actionButtonOptionStatus.approveException && isChecklistSelected
                                            ? dataRetentionMessageForException
                                            : disabledInfoMessageOfNoRowSelection
                                            : disabledInfoMessageOfNoPermission}  
                                        
                                        </MotifTooltip>
                                    </li>
                                }

                                {(actionButtonOptionStatus.removeException) &&
                                    <li role="menuitem">
                                        <MotifDropdownItem type="button"
                                            disabled={!actionButtonOptionStatus.removeException}  
                                            onClick={() => { handleDataRetentionActions(dataRetentionManagementRemoveExceptionActionId);}}
                                            data-testid="removeException">
                                            Remove Exception to Deletion
                                        </MotifDropdownItem>
                                    </li>
                                    ||
                                    <li role="menuitem">
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem type="button"
                                                    disabled={!actionButtonOptionStatus.removeException} 
                                                    onClick={() => { handleDataRetentionActions(dataRetentionManagementRemoveExceptionActionId);}}
                                                    data-testid="removeException"
                                                >
                                                    Remove Exception to Deletion
                                                    <MotifIconButton  aria-label="Remove Exception to Deletion" type="button">
                                                        <MotifIcon iconFunction={IconoirInfoCircle} size="24" fill="none" />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >  
                                        {isSystemAdmin || isEngagementAdminAndDataSteward ?
                                            !actionButtonOptionStatus.removeException && isChecklistSelected
                                            ? dataRetentionMessageForRemoveException
                                            : disabledInfoMessageOfNoRowSelection
                                            : disabledInfoMessageOfNoPermission} 
                                        </MotifTooltip>
                                    </li>
                                }

                                {(actionButtonOptionStatus.exportDataDeletionSummary) &&
                                    <li role="menuitem">
                                        <MotifDropdownItem type="button"
                                            disabled={!actionButtonOptionStatus.exportDataDeletionSummary}
                                            onClick={handleExportDataDeletionSummary}
                                            data-testid="exportDataDeletionSummary">
                                            Export Data Deletion Summary
                                        </MotifDropdownItem>
                                    </li>
                                
                                }
                            </ul>
                        
                        </MotifDropdown>
                        </div>

                        {/* } */}

                    </div>
            </div>

        </>        
    );               
};

DataRetentionManagementHeader.propTypes = {
    clientId: PropTypes.number.isRequired,
    gridCurrentState: PropTypes.arrayOf(PropTypes.shape({
        checklistSelection: PropTypes.bool
    })),
    handleDataRetentionActions: PropTypes.func,
    isSystemAdmin: PropTypes.bool,
    isEngagementAdminAndDataSteward: PropTypes.bool,
    isEngagementUser: PropTypes.bool,
    handleExportDataDeletionSummary:PropTypes.func
};

export default DataRetentionManagementHeader;
