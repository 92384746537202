import React from "react";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

export default (props) => {
  const item = props.node.data ? props.node.data : {};
  const isChecklist = props.isChecklist ? props.isChecklist : false;
  const canEdit = props.canEdit ? props.canEdit : false;

  if (item !== undefined) {
    return (
      (!isChecklist && (
        <div className="tright">
          {(canEdit && (
            <div className="justify-content-end align-items-center mt-2">
              <MotifIconButton
                aria-label="Edit this Distribution"
                type="button"
                onClick={() => props.handleEditButtonClick(item)}
              >
                <MotifIcon
                  iconFunction={IconoirEditPencil} size='24' fill='none'
                  title="Edit this Distribution"
                />
              </MotifIconButton>
              <MotifIconButton
                aria-label="Delete this Distribution"
                type="button"
                onClick={() => props.handleDeleteButtonClick(item)}
              >
                <MotifIcon
                  iconFunction={IconoirTrash} size='24' fill='none'
                  title="Delete this Distribution"
                />
              </MotifIconButton>
            </div>
          )) || (
            <div className="justify-content-end align-items-center mt-2">
              <MotifIconButton
                aria-label="Can't edit Distribution - Testing Closed"
                type="button"
                disabled
              >
                <MotifIcon
                  iconFunction={IconoirEditPencil} size='24' fill='none'
                  aria-label="Can't edit Distribution - Testing Closed"
                />
              </MotifIconButton>
              <MotifIconButton
                aria-label="Can't delete Distribution - Testing Closed"
                type="button"
                disabled
              >
                <MotifIcon
                  iconFunction={IconoirTrash} size='24' fill='none'
                  aria-label="Can't delete Distribution - Testing Closed"
                />
              </MotifIconButton>
            </div>
          )}
        </div>
      )) ||
      null
    );
  }
};
