import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminAuthList from "../../components/auth/AdminAuthList";
import * as authActions from "../../actions/authActions";
import * as commonActions from "../../actions/commonActions";

import * as authorizationDomainValidator from "../../scripts/authorizationDomainValidator";
import { store } from "../../store/configureStore";
import { reset } from "redux-form";
import { MotifButton, MotifIcon, MotifTabNavigation, MotifTabControl } from '@ey-xd/motif-react';
import DeleteModal from "../../components/shared/DeleteModal";
import { withRouter } from "../../common/withRouter";
import { Link } from 'react-router-dom';
/**
 * AdminAuthPage container component
 * @extends {React.Component}
 */
export class AdminAuthPage extends React.Component {
    /**
        * Creates a new AdminAuthPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);

        this.state = {
            adminUserSearchText: "",
            filteredAdminAuths: [],
            showAddSysAdminModal: false,
            auth: {
                "userId": null, "resource": authActions.resource.System, "role": authActions.role.Administrator, "resourceId": null, "id": null
            },
            confirmAction: null,
            currentUserId: this.props.currentUserId,
            addAuthMessageText: null,
            allowedDomains: "",
            tabBar1: 0,
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.reloadAdminAuths = this.reloadAdminAuths.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleNewButtonClick = this.handleNewButtonClick.bind(this);
        this.handleAddSysAdminModalAction = this.handleAddSysAdminModalAction.bind(this);
        this.toggleShowAddSysAdminModal = this.toggleShowAddSysAdminModal.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.validateDomain = this.validateDomain.bind(this);
        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchAuthorizations(null, 1, null, 1);
        this.setFilter();
        authActions.fetchAllowedAuthorizationDomains().then((allowedDomains) => { this.setState({ allowedDomains: allowedDomains }); });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authorizations !== this.props.authorizations && this.props.authorizations) {
            const list = Object.assign([], this.props.authorizations);
            this.setState({ filteredAdminAuths: list });
        }
    }

    reloadAdminAuths(reloadAdminAuths) {
        if (reloadAdminAuths) {
            this.props.actions.fetchAuthorizations(null, 1, null, 1);
        }
    }

    handleSearchChange(e) {
        this.setState({ adminUserSearchText: e.target.value }, this.setFilter);
    }

    handleClearSearchText(e) {
        this.setState({ adminUserSearchText: "" }, this.setFilter);
    }

    setFilter() {
        if (this.state.adminUserSearchText === "") {
            this.setState({ filteredAdminAuths: Object.assign([], this.props.authorizations) });
        } else {
            this.setState({ filteredAdminAuths: this.props.authorizations.filter((auth) => auth.userId.toLowerCase().includes(this.state.adminUserSearchText.toLowerCase())) });
        }
    }

    handleNewButtonClick(value) {
        this.setState({ showAddSysAdminModal: !this.state.showAddSysAdminModal });
    }

    handleEditButtonClick() {
        //Not used in SysAdmin
    }

    handleDeleteButtonClick(auth) {
        let id = auth.id;

        this.setState({
            confirmAction: () => this.props.authActions.removeAuthorization(id).then(() => {
                this.reloadAdminAuths(true);
                this.setState({
                    auth: {
                        "userId": null, "resource": authActions.resource.System, "role": authActions.role.Administrator, "resourceId": null, "id": null
                    }
                });
            })
        });
    }

    toggleShowAddSysAdminModal() {
        this.setState({ addAuthMessageText: null, showAddSysAdminModal: !this.state.showAddSysAdminModal }, () => {
            if (!this.state.showAddSysAdminModal) {
                store.dispatch(reset('auth'));
            }
        });
    }

    handleAddSysAdminModalAction(values) {
        if (!values || !values.userId || values.userId.trim() === "") {
            this.setState({ addAuthMessageText: "Required" });
            return;
        }

        for (let auth of this.props.authorizations) {
            if (values.userId.toLowerCase() === auth.userId.toLowerCase()) {
                this.setState({ addAuthMessageText: `'${values.userId}' is already an authorized SysAdmin.` });
                return;
            }
        }

        this.setState({ auth: values }, () => {
            this.props.commonActions.beginTask();
            this.props.authActions.createAuthorization(this.state.auth).then(() => {

                this.reloadAdminAuths(true);
                this.setState({
                    auth: {
                        "userId": null, "resource": authActions.resource.System, "role": authActions.role.Administrator, "resourceId": null, "id": null
                    }
                });
                this.toggleShowAddSysAdminModal();
                this.props.commonActions.endTask();
            }).catch((error) => {
                this.toggleShowAddSysAdminModal();
            });
        });
 }

    onConfirmDelete() {
            if(this.state.confirmAction) {
            this.state.confirmAction();
            this.setState({ confirmAction: null });
        }
    }

    handleCancelDelete() {
        this.setState({ confirmAction: null });
    }

    validateDomain(value) {
        return authorizationDomainValidator.allowedDomain(value, this.state.allowedDomains);
    }

    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    //Set current tab index
    handleClick(index) {
        this.setTabBar1(index);
    }
    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const authModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '160px'
            }
        };

        let isSysAdmin = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations);
        }

        // Non sys admins are unauthorized to access system admin page
        if (!isSysAdmin) {
            this.props.router.navigate(`/unauthorized`);
            return (<span />);
        }

        return isSysAdmin ? (
            <React.Fragment>
                <h2>System Administrators</h2>
                <MotifTabNavigation variant="default">
                    <Link to={`/adminauth`}
                        tabIndex="-1">
                        <MotifTabControl className="motif-active"
                            autoFocus
                            role="tab"
                            onClick={() => this.handleClick(1)}>
                            Manage System Administrators
                        </MotifTabControl>
                    </Link>
                    <Link to={`/adminauth/dataDeletionDiagnostic`} tabIndex="-1">
                        <MotifTabControl role="tab" onClick={() => this.handleClick(2)}>
                            Data Deletion Diagnostic
                        </MotifTabControl>
                    </Link>
                    
                    <Link to={`/adminauth/externalUserDataVisibility`} tabIndex="-1">
                        <MotifTabControl role="tab" onClick={() => this.handleClick(3)}>
                        External User Data Visibility Manager
                        </MotifTabControl>
                    </Link>
                    
                </MotifTabNavigation>
                <div >
                    <AdminAuthList auths={this.state.filteredAdminAuths} handleSearchChange={this.handleSearchChange} handleClearSearchText={this.handleClearSearchText} adminUserSearchText={this.state.adminUserSearchText} handleEditButtonClick={this.handleEditButtonClick} handleDeleteButtonClick={this.handleDeleteButtonClick} handleNewButtonClick={this.handleNewButtonClick} showAddSysAdminModal={this.state.showAddSysAdminModal} handleAddSysAdminModalAction={this.handleAddSysAdminModalAction} auth={this.state.auth} toggleShowAddSysAdminModal={this.toggleShowAddSysAdminModal} currentUserId={this.state.currentUserId} addAuthMessageText={this.state.addAuthMessageText} validateDomain={this.validateDomain} />
                    <DeleteModal showDeleteModal={this.state.confirmAction !== null} confirmationMessage="Are you sure?" size="sm" handleDelete={this.onConfirmDelete} toggleshowDeleteModal={this.handleCancelDelete} closeTitle="close system admin delete modal" headerTitle="Delete System Administrator" />
                </div>
            </React.Fragment>
        ) : <span />;
    }
}

AdminAuthPage.propTypes = {
    authorizations: PropTypes.array,
    currentUserAuthorizations: PropTypes.array,
    actions: PropTypes.object.isRequired,
    commonActions: PropTypes.object.isRequired,
    currentUserId: PropTypes.string.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    const authorizations = state.authorizations;
    const currentUserAuthorizations = state.currentUserAuthorizations;

    return {
        authorizations: authorizations,
        currentUserAuthorizations: currentUserAuthorizations,
        currentUserId: state.authentication.currentUser
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminAuthPage));