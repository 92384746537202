export const TabProperty = [
    {
        "title": "Discrepancies",
        "id": "discrepancies",
        "badgeClassName": "template-question-comment-error-badge"
    },
    {
        "title": "Attachments",
        "id": "attachments",
        "badgeClassName": ""
    },
    {
        "title": "Comments",
        "id": "comments",
        "badgeClassName": "template-question-comment-alt-badge"
    }
];

export const TabsDefaultCount = [
    {
        "counter": "Discrepancies",
         "totalCount":0
    },
    {
        "counter": "Attachments",
        "totalCount":0
    },
    {
        "counter": "Comments",
        "totalCount":0
    }
];

export const ChecklistCountRequest = {
    GetCountOfAttachments: true,
    GetCountOfDiscrepencies: true,
    GetCountOfComments: true,
  };
