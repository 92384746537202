import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the CHECKLIST_SCHEDULED_DELIVERY_SUCCESS action and sets data of checklist delivery schedules to the store
 * @param {Object} [state] The custom psq template data currently in the store, or the initial state of checklist delivery schedules data
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist delivery schedules data
 */

export default function checklistDeliverySchedulesReducer(
  state = initialState.checklistDeliverySchedules,
  action
) {
  switch (action.type) {
    case actions.CHECKLIST_SCHEDULED_DELIVERY_SUCCESS:
          return action.checklistDeliverySchedules;
    case actions.CHECKLIST_SCHEDULED_DELIVERY_FAILURE:
          return initialState.checklistDeliverySchedules;
    default:
          return state;
  }
}
