import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the DETAIL_GROUP_MAPPINGS_SUCCESS action and sets a the COA/Detail Group Mappings in the store
 * @param {Object} [state] The COA/Detail Group Mappings currently in the store, or the initial state of period mappings
 * @param {Object} action The action to be handled
 * @returns {Object} The COA/Detail Group Mappings
 */
export default function detailGroupMappings(state = initialState.detailGroupMappings, action) {
    switch (action.type) {
    case actions.LOAD_DETAIL_GROUPS_MAPPINGS_SUCCESS:
        return action.detailGroupMappings;
    case actions.LOAD_DETAIL_GROUPS_MAPPINGS_FAILURE:
        return initialState.detailGroupMappings;
    default:
        return state;
    }
}