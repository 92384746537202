import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as notificationActions from '../../actions/notificationActions';
import Notification from '../../components/notifications/Notification';
import { MotifButton } from '@ey-xd/motif-react';

/**
 * Notifications container component
 * @extends {React.Component}
 */
export class Notifications extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.onDismissNotification = this.onDismissNotification.bind(this);
        this.onDismissAllNotifications = this.onDismissAllNotifications.bind(this);
    }

    onDismissNotification(id) {
        this.props.notificationActions.clearNotification(id, this.props.currentUserId);
    }

    onDismissAllNotifications(e) {
        e.preventDefault();
        this.props.notificationActions.clearAllNotifications(this.props.currentUserId);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        if (!Array.isArray(this.props.notifications) || this.props.notifications.length === 0) {
            return (
                <div style={{ width: '100%' }} className="notifications"><p style={{ fontStyle: 'italic', color: '#ffffff' }}>There are no notifications to show</p></div>
            );
        }

        return (
            <div style={{ width: '100%' }} className="notifications">
                <div style={{ width: '100%', textAlign: 'right' }}>
                    <MotifButton style={{ color: 'white' }} variant="text" onClick={this.onDismissAllNotifications} title="Clear all notifications" aria-label="Clear all notifications">
                        Clear all
                    </MotifButton>
                </div>
                {this.props.notifications.map(item => <Notification key={item.notificationID} item={item} handleDismiss={this.onDismissNotification} />)}
            </div>
        );
    }
}

Notifications.propTypes = {
    notifications: PropTypes.array,
    currentUserId: PropTypes.string.isRequired,
    notificationActions: PropTypes.object.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        notifications: state.notifications,
        currentUserId:  state.authentication.currentUser
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        notificationActions: bindActionCreators(notificationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);