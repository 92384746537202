import React from "react";
import PropTypes from "prop-types";
import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
  } from "@ey-xd/motif-react";

const RequestToReopenModal = ({
    showRequestToReopenModal,
    handleReopenChecklistRequest,
    toggleShowRequestToReopenModal,
    selectedChecklists
}) => {

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Resend checklists  modal',
        ariaLabel: 'Close Resend checklists modal',
        dataTestid: 'close'
    };

    return (
        <MotifModal variant="alt" show={showRequestToReopenModal} onClose={toggleShowRequestToReopenModal} data-testid="RequestToReopenModal">
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Request to Reopen</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <span>Are you sure you want to request the selected checklist(s) to reopen?</span>
                <span>You have selected {selectedChecklists.length} checklist(s).</span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" className="right" data-testid="Cancel" type="button" onClick={toggleShowRequestToReopenModal}>
                    Cancel
                </MotifButton>
                <MotifButton variant="primary" className="right" data-testid="Submit" type="button" onClick={handleReopenChecklistRequest}>
                    Submit
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

RequestToReopenModal.propTypes = {
    showRequestToReopenModal: PropTypes.bool,
    handleReopenChecklistRequest: PropTypes.func,
    toggleShowRequestToReopenModal: PropTypes.func,
    selectedChecklists: PropTypes.array
};

export default RequestToReopenModal;
