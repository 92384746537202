import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_TB_ADJUSTMENTS_SUCCESS action and sets the TB adjustments in the store
 * @param {Object} [state] The adjustments currently in the store, or the initial state of the TB adjustments
 * @param {Object} action The action to be handled
 * @returns {Object} The summary
 */
export default function trialBalanceAdjustmentsReducer(state = initialState.trialBalanceAdjustments, action) {
    if (action.type === actions.LOAD_TB_ADJUSTMENTS_SUCCESS) {
        return action.trialBalanceAdjustments;
    } else if (action.type == actions.UNLOAD_PERIOD || action.type === actions.LOAD_TB_ADJUSTMENTS_FAILURE) {
        return initialState.trialBalanceAdjustments;
    } else {
        return state;
    }
}