import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS & LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE action and sets bulk process management grid data to the store
 * @param {Object} [state] The bulk process management grid data
 * @param {Object} action The action to be handled
 * @returns {Object} The bulk process management grid data
 */

export default function bulkProcessManagementDataReducer(
  state = initialState.bulkProcessManagementData,
  action
) {
  switch (action.type) {
    case actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS:
      return action.bulkProcessManagementData;
    case actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_REFRESH:
      return action.message;
    case actions.LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE:
      return state;
    case actions.RECEIVE_BULK_PROCESS_STATUS_UPDATE_MESSAGE:
      return state.map(row => {
        if (row.reitid === action.message.REITID && row.reportPeriodID == action.message.ReportPeriodID) {
          return {
            ...row,
            signOffStatus: action.message.SignOffStatus,
            arqc: action.message.ARQC,
            assetTesting: action.message.AssetTesting,
            distributionTesting: action.message.DistributionTesting,
            incomeTesting: action.message.IncomeTesting,
            isActive: action.message.IsActive,
            isAudit: action.message.IsAudit,
            isReitAvailableForSelection: action.message.IsReitAvailableForSelection,
            leaseReview: action.message.LeaseReview,
            psq: action.message.PSQ,
            reitSelection: false
          };
        }

        return row;
      });
    case actions.RECEIVE_BULK_PROCESS_STATUS_CONCURRENT_UPDATE_MESSAGE:
      return state.map(row => {
        if (row.reitid === action.message.REITID && row.reitName == action.message.REITName) {
          return {
            ...row,
            isReitAvailableForSelection: action.message.IsReitAvailableForSelection,
            reitSelection: false
          };
        }

        return row;
      });      
    default:
      return state;
  }
}
