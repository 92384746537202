import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_PROPERTIES_SUCCESS action and sets the properties in the store
 * @param {Object} [state] The properties currently in the store, or the initial state of the properties
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function propertyReducer(state = initialState.property, action) {
    if (action.type === actions.LOAD_PROPERTY_SUCCESS || action.type === actions.UPDATE_PROPERTY_SUCCESS) {
        return action.property;
    } else {
        if (action.type === actions.LOAD_PROPERTY_FAILURE || action.type === actions.UNLOAD_PROPERTY) {
            return initialState.property;
        } else {
            return state;
        }
    }
}