import React from 'react';
import {Link} from 'react-router';
import {  MotifTextLink} from '@ey-xd/motif-react';

export default (props) => {
    // This is the data for the reit cell
    const reit = props.data.reitCell;

    // This is the data for the property cell
    const property = props.node.data.propertyCells;
    let index;

    let propertyIsAdjusted;
    let propertyIsAdjustable;
    let propertyAmount;
    if(props.column.colId.startsWith("property") && property){
        index = props.index === undefined ? 0 : props.index;
        propertyIsAdjusted = property[index]?.isAdjusted;
        propertyIsAdjustable = property[index]?.isAdjustable;
        
        propertyAmount = property[index]?.amount;
        
    } else if(props.column.colId == "reitCell.amount" && reit){
        propertyAmount = reit.amount;
    }

    const roundedAmount = Math.sign(propertyAmount) * Math.round(Math.abs(propertyAmount));
        let value;
        if (roundedAmount < 0) {
            value = roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            value = "($" + value.replace('-', '') + ")";
        }
        else {
            value = "$" + roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        propertyAmount = value;

    const showTrialBalanceAdjustments = (e) => {
        props.showTrialBalanceAdjustmentsModal(e);
    };


    return (
        <div> 
            {(props.column.colId.startsWith("property")) &&
                <div>
                    {
                        propertyIsAdjustable && props.canEdit ? 
                        ( 
                            <MotifTextLink data-id={property[index].trialBalanceItemID}
                                data-propname={props.property?.propertyName}
                                onClick={showTrialBalanceAdjustments}>{propertyIsAdjusted ? `*${propertyAmount}` : propertyAmount}</MotifTextLink>
                        ) 
                        :
                        ( <div> {propertyIsAdjusted ? `*${propertyAmount}` : propertyAmount}</div> )
                        
                    }
                </div>
            }
            {(props.column.colId === "reitCell.amount" && reit) &&
                <div>
                    {
                        reit.isAdjustable && props.canEdit ? 
                        ( 
                            <MotifTextLink data-id={reit.trialBalanceItemID}
                            data-propname="REIT"
                                onClick={showTrialBalanceAdjustments}>{reit.isAdjusted ? `*${propertyAmount}` : propertyAmount}</MotifTextLink>
                        ) 
                        :
                        ( <div> {reit.isAdjusted ? `*${propertyAmount}` : propertyAmount}</div> )
                        
                    }
                </div>
            }
        </div>
    )

}
