import React from "react";
import PropTypes from "prop-types";
import FormattedCurrency from "../shared/FormattedCurrency";

const VarianceBalanceDetailsRow = ({ row }) => {
    return (
        <tr>
            <td>{row.before.clientAccountDescription}</td><td className="text-nowrap"><FormattedCurrency value={row.balanceDelta} currency="USD" /></td>
        </tr>
    );
};

const clientAccountDataShape = PropTypes.shape({
    balance: PropTypes.number,
    clientAccountDescription: PropTypes.string,
    clientAccountCode: PropTypes.string,
    reitTestingAttributeCode: PropTypes.string,
    reitTestingAttributeDescription: PropTypes.string,
    type: PropTypes.number
});

VarianceBalanceDetailsRow.propTypes = {
    row: PropTypes.shape({
        after: clientAccountDataShape,
        balanceDelta: PropTypes.number,
        balanceDeltaPercentage: PropTypes.number,
        before: clientAccountDataShape
    })
};

export default VarianceBalanceDetailsRow;