import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as detailTestTableHelpers from '../../scripts/detailTestTableHelpers';
import {withWindowDimensions} from '../../common/withWindowDimensions';
/**
 * IncomeDetailTestTable container component
 * @extends {React.Component}
 */
export class IncomeDetailTestTable extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth - (window.innerWidth < 680 ? 0 : 47),
          };
    }

    handleResize = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth - (window.innerWidth < 680 ? 0 : 47),
        });
      };
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
      }

    render() {
        const { width, height } = this.props;
        return (
            <div style={{ marginBottom: "20px", overflowX: 'auto' }}>
                <table className="table-responsive rtable detail-test-table" style={{ width: `${width}px`, tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th>
                                {this.props.detail.test}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {detailTestTableHelpers.getInnerTables(this.props.detail, this.props.properties, this.props.tbMode, width, height, "INCOME")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

IncomeDetailTestTable.propTypes = {
    detail: PropTypes.object,
    properties: PropTypes.array,
    tbMode: PropTypes.number,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
    };
}



export default withWindowDimensions(connect(mapStateToProps)(IncomeDetailTestTable));
