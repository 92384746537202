import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_SERVICES_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function chartOfAccountsManagerReducer(state = initialState.chartOfAccountsManager, action) {
    switch (action.type) {
        case actions.LOAD_CHARTS_SUCCESS:
            return Object.assign({}, state, {
                charts: action.charts
            });
        case actions.SET_CHART_DUPLICATE:
            return Object.assign({}, state, {
                duplicate: true
            });
        case actions.SET_CHARTOFACCOUNT_DUPLICATE:
            return Object.assign({}, state, {
                duplicateClientAccount: true
            });
        case actions.CLEAR_CHART_DUPLICATE:
            return Object.assign({}, state, {
                duplicate: false
            });
        case actions.CLEAR_CHARTOFACCOUNT_DUPLICATE:
            return Object.assign({}, state, {
                duplicateClientAccount: false
            });
        case actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE:
            return state
        default:
            return state;
    }
}