import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
  } from "@ey-xd/motif-react";

const ResendChecklistModal = ({
    showResendChecklistModal,
    handleResendChecklist,
    toggleShowResendChecklistModal,
    selectedRecipients
}) => {

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Resend checklists  modal',
        ariaLabel: 'Close Resend checklists modal',
        dataTestid: 'close'
    };

    let selectedChecklists = (selectedRecipients && new Set(selectedRecipients.map(sr => sr.recipients)).size) || 0;

    return (
        <MotifModal variant="alt" show={showResendChecklistModal} onClose={toggleShowResendChecklistModal} data-testid="ResendChecklistModal">
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Resend Checklist(s)</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <span className="checklistActionMsg">Are you sure you want to send the checklist(s) to the selected recipients?</span>
                <span className="checklistActionMsg">You selected {selectedRecipients.length} recipients(s) within {selectedChecklists} checklist(s).</span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" className="right" data-testid="Cancel" type="button" onClick={toggleShowResendChecklistModal}>
                    Cancel
                </MotifButton>
                <MotifButton variant="primary" className="right" data-testid="Submit" type="button" onClick={handleResendChecklist}>
                    Submit
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

ResendChecklistModal.propTypes = {
    showResendChecklistModal: PropTypes.bool,
    handleResendChecklist: PropTypes.func,
    toggleShowResendChecklistModal: PropTypes.func,
    selectedRecipients: PropTypes.array
};

export default ResendChecklistModal;
