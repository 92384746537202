import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_SUCCESS action and sets the checklistCalendarYears in the store
 * @param {Object} [state] The checklistCalendarYears currently in the store, or the initial state of the checklistCalendarYears
 * @param {Object} action The action to be handled
 * @returns {Object} The checklistCalendarYears
 */
export default function checklistCalendarYearsByTypeReducer(state = initialState.externalChecklists.calendarYears, action) {    
   
    if (action.type == actions.LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_SUCCESS && Array.isArray(action.checklistCalendarYears)) {        
        return action.checklistCalendarYears;     
    } else if (action.type == actions.LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_FAILURE) {       
        return initialState.externalChecklists.checklistCalendarYears;
    } else {        
        return state;
    }
}