import React from 'react';
import { MotifTextLink, MotifCheckbox, MotifIcon } from '@ey-xd/motif-react';
import { Link } from 'react-router-dom';
import { IconoirOpenNewWindow } from '@ey-xd/motif-icon';

export default (props) => {
    const reitId = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data["reitid"] : null;
    const reportPeriodID = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data["reportPeriodID"] : null;
    const checklistType = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data["checklistType"] : null;
    const checklistID = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data["checklistID"] : null;

    return (<div>
        {checklistType && checklistType.toLowerCase() == 'psq' && reitId && reportPeriodID &&
            <div>
            <Link tabIndex="-1" to={`/client/${props.clientId}/reit/${reitId}/period/${reportPeriodID}/psq/psqRecord/${checklistID}`} target="_blank" rel="noopener noreferrer" >
                 <MotifIcon className="linkIcon" iconFunction={IconoirOpenNewWindow} size='24' fill='none' />
                </Link>
            </div>
        }
        {checklistType && checklistType.toLowerCase() == 'arqc' && reitId && reportPeriodID &&
            <div>
            <Link tabIndex="-1" to={`/client/${props.clientId}/reit/${reitId}/period/${reportPeriodID}/arqc/arqcRecord/${checklistID}`} target="_blank" rel="noopener noreferrer" >
                <MotifIcon className="linkIcon" iconFunction={IconoirOpenNewWindow} size='24' fill='none' />
                </Link>
            </div>
        }
    </div>);
};