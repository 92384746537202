import urlJoin from 'url-join';
import 'whatwg-fetch';
import { PublicClientApplication } from "@azure/msal-browser";
import { store } from '../store/configureStore';
import { graphConfig } from './../auth/authConfig';

/**
 * Middleware to handle if the response was Ok (2xx HTTP Code), or if we need to throw an Error on a non 2xx.
 * NOTE: This doesn't need to be used because of all the other places that manully look for an error response without a catch statement
 * @param {any} response The response object returned from fetch.
 * @returns {any} The response object that was passed in.
 */
const checkResponseOk = (response) => {
    if (response.ok) {
        return response;
    } else {
        throw Error(response.statusText);
    }
};

/**
 * Builds the headers sent with requests
 * @param {any} init The init object which may contain pre-existing headers.
 * @returns {any} An object containing headers.
 */
export function buildHeaders(init = null) {
    const token = store.getState().authentication.idToken;

    if (process.env.NODE_ENV !== 'test' && token) {
        const defaultHeaders = {
            'Authorization': `Bearer ${token}`
        };

        if (init !== undefined && init !== null) {
            let headers = init.headers;
            if (headers === undefined || headers === null) {
                init.headers = defaultHeaders;
            } else {
                headers.Authorization = `Bearer ${token}`;
                init.headers = headers;
            }

            return init;
        }

        return {
            headers: defaultHeaders,
            method: "GET"
        };
    } else {
        if (init !== undefined && init !== null) {
            let headers = init.headers;
            if (headers === undefined || headers === null) {
                init.headers = new Headers();
            }

            return init;
        }
    }

    return init;
}

/**
 * Builds the headers to be sent with Microsoft Graph API requests
 * @param {any} init The init object which may contain pre-existing headers.
 * @returns {any} An object containing headers.
 */
export function buildHeadersForMicrosoftGraphApi(init = null) {
    const token = store.getState().authentication.accessToken;

    if (process.env.NODE_ENV !== 'test' && token) {
        const defaultHeaders = {
            'Authorization': `Bearer ${token}`
        };

        return {
            headers: defaultHeaders,
            method: "GET"
        };
    } else {
        if (init !== undefined && init !== null) {
            let headers = init.headers;
            if (headers === undefined || headers === null) {
                init.headers = new Headers();
            }

            return init;
        }
    }

    return init;
}

/**
 * Fetch from the ClientAPI, which uses the root URL specified by the ROOT_CLIENT_API_URL environment variable.
 * In the dev environment, this variable is set in webpack.config.dev.js
 * @param {string} resource Path to the resource you wish to fetch
 * @param {Object} init An options object containing any custom settings that you want to apply to the request. See {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch} for more details.
 * @returns {Promise} A promise that resolves to the Response object representing the response to your request.
 */
export function fetchFromClientApi(resource, init = null) {
    init = buildHeaders(init);
    const rootApiUrl = process.env.ROOT_CLIENT_API_URL;
    const fullUrl = urlJoin(rootApiUrl, resource);

    return init ? fetch(fullUrl, init) : fetch(fullUrl);
}

/**
 * Fetch from the ReitAPI, which uses the root URL specified by the ROOT_REIT_API_URL environment variable.
 * In the dev environment, this variable is set in webpack.config.dev.js
 * @param {string} resource Path to the resource you wish to fetch
 * @param {Object} init An options object containing any custom settings that you want to apply to the request. See {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch} for more details.
 * @returns {Promise} A promise that resolves to the Response object representing the response to your request.
 */
export function fetchFromReitApi(resource, init = null) {
    init = buildHeaders(init);
    const rootApiUrl = process.env.ROOT_REIT_API_URL;
    const fullUrl = urlJoin(rootApiUrl, resource);


    return init ? fetch(fullUrl, init) : fetch(fullUrl);
}

/**
 * Fetch from the TrialBalanceAPI, which uses the root URL specified by the ROOT_TB_API_URL environment variable.
 * In the dev environment, this variable is set in webpack.config.dev.js
 * @param {string} resource Path to the resource you wish to fetch
 * @param {Object} init An options object containing any custom settings that you want to apply to the request. See {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch} for more details.
 * @returns {Promise} A promise that resolves to the Response object representing the response to your request.
 */
export function fetchFromTrialBalanceApi(resource, init = null) {
    init = buildHeaders(init);
    const rootApiUrl = process.env.ROOT_TB_API_URL;
    const fullUrl = urlJoin(rootApiUrl, resource);

    return init ? fetch(fullUrl, init) : fetch(fullUrl);
}

/**
 * Fetch from the ReportAPI, which uses the root URL specified by the ROOT_REPORT_API_URL environment variable.
 * In the dev environment, this variable is set in webpack.config.dev.js
 * @param {string} resource Path to the resource you wish to fetch
 * @param {Object} init An options object containing any custom settings that you want to apply to the request. See {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch} for more details.
 * @returns {Promise} A promise that resolves to the Response object representing the response to your request.
 */
export function fetchFromReportApi(resource, init = null) {
    init = buildHeaders(init);
    const rootApiUrl = process.env.ROOT_REPORT_API_URL;
    const fullUrl = urlJoin(rootApiUrl, resource);

    return init ? fetch(fullUrl, init) : fetch(fullUrl);
}

/**
 * Fetch from the AuthAPI, which uses the root URL specified by the ROOT_REPORT_API_URL environment variable.
 * In the dev environment, this variable is set in webpack.config.dev.js
 * @param {string} resource Path to the resource you wish to fetch
 * @param {Object} init An options object containing any custom settings that you want to apply to the request. See {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch} for more details.
 * @returns {Promise} A promise that resolves to the Response object representing the response to your request.
 */
export function fetchFromAuthApi(resource, init = null) {
    init = buildHeaders(init);
    const rootApiUrl = process.env.ROOT_AUTH_API_URL;
    const fullUrl = urlJoin(rootApiUrl, resource);

    return init ? fetch(fullUrl, init) : fetch(fullUrl);
}

/**
 * Fetch from the microsoft Graph API, which uses the root URL specified by the graphConfig.graphApiV1Root constant in authConfig.js.
 * The root url is same for all the environments
 * @param {string} resource Path to the resource you wish to fetch
 * @param {Object} init An options object containing any custom settings that you want to apply to the request. See {@link https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch} for more details.
 * @returns {Promise} A promise that resolves to the Response object representing the response to your request.
 */
export function fetchFromV1MicrosoftGraphApi(resource, init = null) {
    init = buildHeadersForMicrosoftGraphApi(init);
    const rootApiUrl = graphConfig.graphApiV1Root;
    const fullUrl = urlJoin(rootApiUrl, resource);

    return init ? fetch(fullUrl, init) : fetch(fullUrl);
}