import React from 'react';
import { MotifCheckbox } from "@ey-xd/motif-react";

export default (props) => {
  const value = props.data ? props.data[props.colDef.field] : null;
  const reitId = props.data ? props.data.reitid : null;
  const isReitAvailableForSelection = props.data ? props.data.isReitAvailableForSelection : null;

  // Display checkbox if the reit is available for selection
  if (props.data && isReitAvailableForSelection) {
    const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
    let checkboxClass = value ? defaultCheckboxClass + " ag-checked" : defaultCheckboxClass;

    return (
      <span>
        <MotifCheckbox
          type="checkbox"
          name="cell-checkbox"
          id={"ag-cell-checkbox_"+reitId}
          checked={value}
          tabindex="-1"
          data-testid="selectCheckboxHeader"
          aria-label="Press Space to toggle row selection"
          onClick={() => props.onCellCheckboxChange(reitId, props.node)}>
        </MotifCheckbox>
      </span>
    );
  }

    // Display loading spinner if the reit is not available for selection
  if (props.data && !isReitAvailableForSelection) {
    return (
      <span>
        <img src="/images/loading-spinner-black.gif" height="20px" />
      </span>
    );
  }

  return null;
};