import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch all the custom questionnaire templates for a client. If successful this will dispatch the LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomQuestionnaireTemplates(
  clientId,
  excludeEmpty = false
) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_SUCCESS,
        customQuestionnaireTemplates: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/checklists/customQuestionnaireTemplates/${clientId}?excludeEmpty=${excludeEmpty}`
    )
      .then((response) => {
        return response.json();
      })
      .then((customQuestionnaireTemplates) => {
        if (actionHelpers.isErrorResponse(customQuestionnaireTemplates)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_FAILURE,
            customQuestionnaireTemplates
          );
        }
        dispatch({
          type: actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_SUCCESS,
          customQuestionnaireTemplates,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Fetch the details for the custom questionnaire template. If successful this will dispatch the LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomQuestionnaireTemplateQuestions(
  questionnaireTemplateID
) {
  return function (dispatch) {
    if (!questionnaireTemplateID) {
      return dispatch({
        type: actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_SUCCESS,
        templateQuestions: null,
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/checklists/questionnaireTemplateQuestions/${questionnaireTemplateID}`
    )
      .then((response) => {
        return response.json();
      })
      .then((templateQuestions) => {
        if (actionHelpers.isErrorResponse(templateQuestions)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_FAILURE,
            templateQuestions
          );
        }
        dispatch({
          type: actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_SUCCESS,
          templateQuestions,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Create or Edit a Custom PSQ Template. Pass -1 for questionnaireTemplateId for Create.
 * If failure, it will dispatch the CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE action.
 * @param {number} clientID The id of the client.
 * @param {number} questionnaireTemplateID The id of the questionnaire template.
 * @param {string} templateName The name of the template.
 * @param {string} questionnaireSectionName The custom section header name.
 * @param {bool} hideStandardQuestions Whether the standard questions should be hidden or not.
 * @returns {function} A function that returns a Promise.
 */
export function createOrEditQuestionnaireTemplate(
  clientID,
  questionnaireTemplateID,
  templateName,
  questionnaireSectionName,
  hideStandardQuestions
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`/checklists/questionnaireTemplate`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        clientID,
        questionnaireTemplateID,
        templateName,
        questionnaireSectionName,
        hideStandardQuestions,
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Fetch the details for the custom questionnaire template. If successful this will dispatch the LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function saveCustomQuestionnaireTemplate(requestParams) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`/checklists/questionnaireTemplate`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(requestParams),
    })
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.SAVE_CUSTOM_QUESTIONNAIRE_TEMPLATE_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.SAVE_CUSTOM_QUESTIONNAIRE_TEMPLATE_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Fetch all Custom Question records belonging to the client. If successful this will dispatch the LOAD_CUSTOM_QUESTIONS_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_QUESTIONS_FAILURE action.
 * @param {number} clientId The id of the client that owns the Custom Questions.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomQuestionList(clientId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/customQuestions/${clientId}`)
      .then((response) => {
        return response.json();
      })
      .then((customQuestionList) => {
        if (actionHelpers.isErrorResponse(customQuestionList)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_QUESTIONS_FAILURE,
            customQuestionList
          );
        }

        dispatch({
          type: actions.LOAD_CUSTOM_QUESTIONS_SUCCESS,
          customQuestionList,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CUSTOM_QUESTIONS_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Creates a custom question record to the REIT API database.
 * @param {any} createRequest The create request
 * @returns {Promise} A Promise
 */
export function createCustomQuestion(createRequest) {
  return (dispatch) => {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const { questionAttachments, ...createRequestObj } = createRequest;
return fetch(`/customChecklistTemplate/customQuestions`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createRequestObj),
    }).then((response) => {
      return response.json();
    })
      .then(async (response) => {
        let attachmentsStatus;
        if (actionHelpers.isErrorResponse(response)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.CREATE_CUSTOM_QUESTION_FAILURE,
            response
          );
        }

        if (response != null && createRequest.questionAttachments.length > 0) {
          attachmentsStatus = await addUpdateAttachments(createRequest.questionAttachments, createRequest.clientID, response.questionVersionID);
        }
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return attachmentsStatus;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CREATE_CUSTOM_QUESTION_FAILURE,
          null,
          error
        );
      });
  }
}

export function addUpdateCustomQuestionAttachment(
  clientId,
  questionVersionID,
  questionAttachmentData
) {
  let formData = new FormData();
  formData.append('questionAttachment.questionAttachmentID', getFormValues(questionAttachmentData.questionAttachmentID));
  formData.append('questionAttachment.questionVersionID', getFormValues(questionVersionID));
  formData.append('questionAttachment.attachmentGuid', getFormValues(questionAttachmentData.attachmentGuid));
  formData.append('questionAttachment.attachmentName', questionAttachmentData.attachmentName);
  formData.append('questionAttachment.file', questionAttachmentData.file);
  formData.append('questionAttachment.isNew', questionAttachmentData.isNew);
  formData.append('questionAttachment.isDeleted', questionAttachmentData.isDeleted);
  return fetch(`/customChecklistTemplate/AddUpdateCustomQuestionAttachment?clientId=${clientId}`, {
    method: 'POST',
    body: formData
  })
    .then(response => {
      return response.text();
    })
    .then(response => {
      const jsonResponse = response ? JSON.parse(response) : {};
      if (actionHelpers.isErrorResponse(jsonResponse)) {
        throw jsonResponse;
      }
      return jsonResponse;
    })
    .catch(error => {
      throw error;
    });
}

export async function addUpdateAttachments(attachments, clientId, questionVersionID) {
  let attachmentsStatus = [];
  for (let i = 0; i <= attachments.length - 1; i++) {
    await addUpdateCustomQuestionAttachment(clientId, questionVersionID, attachments[i])
      .then(() => {
        if (attachments[i].isNew) {
          attachmentsStatus.push({ attachmentName: attachments[i].attachmentName, status: "File uploaded", variant: "success" });
        }
      }).catch(() => {
        if (attachments[i].isNew) {
          attachmentsStatus.push({ attachmentName: attachments[i].attachmentName, status: "Failed", variant: "error" });
        }
      });
  }
  return attachmentsStatus;
}

/**
 * Updates a custom question record to the REIT API database.
 * @param {any} createRequest The create request
 * @returns {Promise} A Promise
 */
export function updateCustomQuestion(updateRequest) {
  return (dispatch) => {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const { questionAttachments, ...updateRequestObj } = updateRequest;
    return fetch(`/customChecklistTemplate/customQuestions`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateRequestObj),
    }).then((response) => {
      return response.text();
    })
      .then(async response => {
        let attachmentsStatus;
        const jsonResponse = response ? JSON.parse(response) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPDATE_CUSTOM_QUESTION_FAILURE,
            jsonResponse,
          );
        }
        if (jsonResponse != null && updateRequest.questionAttachments.length > 0) {
          attachmentsStatus = await addUpdateAttachments(updateRequest.questionAttachments, updateRequest.clientID, jsonResponse.questionVersionID);
        }
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return attachmentsStatus;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPDATE_CUSTOM_QUESTION_FAILURE,
          null,
          error
        );
      });
  }
}

/**
 * Get lock status for a question.
 * @param {number} questionId The question id
 * @param {number} checklistTemplateId The checklist template id
 * @param {number} clientId The client id
 * @returns {Promise} A Promise
 */

export const getLockStatusForQuestion = (questionId, checklistTemplateId, clientId) => {
  return (dispatch) => {
    if (!questionId || !checklistTemplateId || !clientId) {
      return dispatch({
        type: actions.CUSTOM_QUESTION_FETCH_LOCK_STATUS_FAILURE,
        customQuestionLockStatus: null,
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const headers = {
      Accept: 'application/json',
    };

    return fetch(`/customChecklistTemplate/getLockStatus?questionId=${questionId}&checklistTemplateId=${checklistTemplateId}&clientId=${clientId}`, {
      method: 'GET',
      headers
    }).then((response) => {
      return response.json();
    }).then((customQuestionLockStatus) => {
      if (actionHelpers.isErrorResponse(customQuestionLockStatus)) {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CUSTOM_QUESTION_FETCH_LOCK_STATUS_FAILURE,
          null
        );
      }

      dispatch({
        type: actions.CUSTOM_QUESTION_FETCH_LOCK_STATUS_SUCCESS,
        customQuestionLockStatus,
        [pendingTask]: end,
      });

      return customQuestionLockStatus;
    }).catch((error) => {
      return actionHelpers.dispatchErrorAndEndTask(
        dispatch,
        actions.CUSTOM_QUESTION_FETCH_LOCK_STATUS_FAILURE,
        null,
        error
      );
    });
  }
};

/**
 * Acquires an intent lock for the custom question.
 * @param {any} intentLockUpdateRequest Intent lock update request
 * @returns {Promise} A Promise
 */

export const acquireIntentLockForCustomQuestion = (intentLockUpdateRequest) => {
  return (dispatch) => {
    if (!intentLockUpdateRequest) {
      return dispatch({
        type: actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE,
        intentLockUpdateResult: null,
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return fetch(`/customChecklistTemplate/acquireIntentLock`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(intentLockUpdateRequest)
    }).then((response) => {
      return response.json();
    }).then((intentLockUpdateResult) => {
      if (actionHelpers.isErrorResponse(intentLockUpdateResult)) {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE,
          null
        );
      }

      dispatch({
        type: actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_SUCCESS,
        intentLockUpdateResult,
        [pendingTask]: end,
      });

      return intentLockUpdateResult;
    }).catch((error) => {
      return actionHelpers.dispatchErrorAndEndTask(
        dispatch,
        actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE,
        null,
        error
      );
    });
  }
};

/**
 * Releases an intent lock for the custom question.
 * @param {any} intentLockUpdateRequest Intent lock update request
 * @returns {Promise} A Promise
 */

export const releaseIntentLockForCustomQuestion = (intentLockUpdateRequest) => {
  return (dispatch) => {
    if (!intentLockUpdateRequest) {
      return dispatch({
        type: actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE,
        intentLockUpdateResult: null,
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return fetch(`/customChecklistTemplate/releaseIntentLock`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(intentLockUpdateRequest)
    }).then((response) => {
      return response.json();
    }).then((intentLockUpdateResult) => {
      if (actionHelpers.isErrorResponse(intentLockUpdateResult)) {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE,
          null
        );
      }

      dispatch({
        type: actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_SUCCESS,
        intentLockUpdateResult,
        [pendingTask]: end,
      });
    }).catch((error) => {
      return actionHelpers.dispatchErrorAndEndTask(
        dispatch,
        actions.CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE,
        null,
        error
      );
    });
  }
};

/**
 * Releases an intent lock for the custom question.
 * @param {any} intentLockUpdateRequest Intent lock update request
 * @returns {Promise} A Promise
 */
export function deleteCustomTemplateQuestion(questionID, parentQuestionID, clientId, customChecklistSectionID) {
  return (dispatch) => {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/customChecklistTemplate/deleteQuestion?clientID=${clientId}&questionID=${questionID}&parentQuestionID=${parentQuestionID}&checklistTemplateSectionID=${customChecklistSectionID}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'DELETE',
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_TEMPLATE_QUESTION_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_TEMPLATE_QUESTION_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Update the child question sort order for a given parent question when rearranged. If successful this will dispatch the CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS
 * action, otherwise it will dispatch the CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE action.
 * @param {object} request The request model.
 * @returns {function} A function that returns a Promise.
 */
export function updateChildQuestionSortOrder(request) {
  return async function (dispatch) {
    if (!request) {
      return dispatch({
        type: actions.UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE,
        templateId: 0,
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    await fetch(
      `/customChecklistTemplate/uncommitted/updateChildQuestionSortOrder`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(request),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((updateChildChildQuestionSortOrderResponse) => {
        if (actionHelpers.isErrorResponse(updateChildChildQuestionSortOrderResponse)) {
          const templateId = 0;

          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE,
            templateId
          );
        }

        const templateId = request.templateId;

        dispatch({
          type: actions.UPSERT_CUSTOM_PSQ_TEMPLATE_SUCCESS,
          templateId,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        const templateId = 0;

        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE,
          templateId,
          error
        );
      });
  };
}

/**
 * Creates a custom question record to the REIT API database.
 * @param {any} questionnaireTemplateID The questionnaireTemplateID to delete
 * @returns {Promise} A Promise
 */
export function deleteQuestionnaireTemplate(questionnaireTemplateID) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/checklists/questionnaireTemplates/${questionnaireTemplateID}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_QUESTIONNAIRE_TEMPLATE_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_QUESTIONNAIRE_TEMPLATE_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Delete a custom question
 * @param { number } customQuestionID The ID of the checklist.
 * @param { object } authHeader The Authorization header.  If not supplied it will be generated.
 * @returns { any } A function that returns a Promise.
 */
export function deleteCustomQuestion(customQuestionID, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    return fetch(`/checklists/customQuestions/${customQuestionID}`, {
      method: 'DELETE',
      headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (actionHelpers.isErrorResponse(response)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_CUSTOM_QUESTION_FAILURE
          );
        }

        dispatch({
          type: actions.DELETE_CUSTOM_QUESTION_SUCCESS,
          [pendingTask]: end,
        });

        return response;
      })
      .catch((error) => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_CUSTOM_QUESTION_FAILURE,
          null,
          error
        );
      });
  };

}

function getFormValues(value) {
  if (value == null) {
    return '';
  }
  else {
    return value;
  }
}
