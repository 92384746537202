import * as actions from '../actions/actionTypes';

/**
 * This reducer handles the UPLOAD_TB_SUCCESS
 * @param {Object} [state] True if uploading the tb was successful otherwise false
 * @param {Object} action The action to be handled
 * @returns {Object} True if uploading the tb was successful otherwise false
 */
export default function tbUploadSuccess(state = false, action) {
    switch (action.type) {
        case actions.UPLOAD_TB_SUCCESS:
        case actions.TB_PROCESSING_COMPLETE:
            return true;
        case actions.UPLOAD_TB_CLEAR:
        case actions.UPLOAD_TB_FAILURE:
        case actions.UPLOAD_FILE_SUCCESS:
        case actions.UPLOAD_FINAL_REPORT_SUCCESS:
        case actions.TB_PROCESSING_FAILED:
            return false;
    default:
        return state;
    }
}