import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";
import * as actionHelpers from '../scripts/actionHelpers';
import { fetchFromAuthApi as fetch } from "./fetchFromApi";
const unmanagedResources = ["Unknown", "ArqcChecklist", "PsqChecklist"];
const unmanagedRoles = ["Unknown", "ExternalTemporaryUser"];

const resource = {
    /// <summary>
    /// An undetermined resource
    /// </summary>
    "Unknown": 0,

    /// <summary>
    /// A system resource
    /// </summary>
    "System": 1,

    /// <summary>
    /// A client resource
    /// </summary>
    "Client": 2,

    /// <summary>
    /// A PSQ checklist resource
    /// </summary>
    "PsqChecklist": 3,

    /// <summary>
    /// An ARQC checklist resource
    /// </summary>
    "ArqcChecklist": 4
};

const role = {
    /// <summary>
    /// An undetermined role
    /// </summary>
    "Unknown": 0,

    /// <summary>
    /// The administrator role
    /// </summary>
    "Administrator": 1,

    /// <summary>
    /// The user role
    /// </summary>
    "User": 2,

    /// <summary>
    /// The external user role with access which expires
    /// </summary>
    "ExternalTemporaryUser": 3,

    /// <summary>
    /// The external user role with access which does NOT expire
    /// </summary>
    "ExternalPermanentUser": 4
};

export { unmanagedResources };
export { unmanagedRoles };
export { resource };
export { role };

/**
 * Gets the authorize resource.
 * @param {any} resource The resource
 * @param {any} resourceId The resource ID
 * @param {any} role The role
 * @returns {any} A function that returns a Promise.
 */
export function fetchAuthorizeResource(resource, resourceId, role) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = '/authorize?';
        url += `&resource=${resource}`;
        url += `&resourceId=${resourceId}`;
        url += `&role=${role}`;

        return fetch(url).then(response => {
            return response.json();
        }).then(authorization => {
            if (actionHelpers.isErrorResponse(authorization)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_AUTHORIZATION_RESOURCE_FAILURE, authorization);
            }

            dispatch({ type: actions.LOAD_AUTHORIZATION_RESOURCE_SUCCESS, authorization, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_AUTHORIZATION_RESOURCE_FAILURE, null, error);
        });
    };
}

/**
 * Gets managed resources
 * @returns {any} A function that returns a Promise
 */
export function fetchManagedResources() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = '/authorization/resources/managed';

        return fetch(url).then(response => {
            return response.json();
        }).then(managedResources => {
            if (actionHelpers.isErrorResponse(managedResources)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_MANAGED_RESOURCES_FAILURE, managedResources);
            }

            dispatch({ type: actions.LOAD_MANAGED_RESOURCES_SUCCESS, managedResources, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_MANAGED_RESOURCES_FAILURE, null, error);
        });
    };
}

/**
 * Get unmanaged resources
 * @returns {any} A function that returns a Promise
 */
export function fetchUnmanagedResources() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = '/authorization/resources/unmanaged';

        return fetch(url).then(response => {
            return response.json();
        }).then(unmanagedResources => {
            if (actionHelpers.isErrorResponse(unmanagedResources)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_UNMANAGED_RESOURCES_FAILURE, unmanagedResources);
            }

            dispatch({ type: actions.LOAD_UNMANAGED_RESOURCES_SUCCESS, unmanagedResources, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_UNMANAGED_RESOURCES_FAILURE, null, error);
        });
    };
}

/**
 * Retrieve managed roles.
 * @returns {any} A function that returns a Promise.
 */
export function fetchManagedRoles() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = '/authorization/roles/managed';

        return fetch(url).then(response => {
            return response.json();
        }).then(managedRoles => {
            if (actionHelpers.isErrorResponse(managedRoles)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_MANAGED_ROLES_FAILURE, managedRoles);
            }

            dispatch({ type: actions.LOAD_MANAGED_ROLES_SUCCESS, managedRoles, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_MANAGED_ROLES_FAILURE, null, error);
        });
    };
}

/**
 * Retrieve unmanaged roles
 * @returns {any} A function that returns a Promise.
 */
export function fetchUnmanagedRoles() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = '/authorization/roles/unmanaged';

        return fetch(url).then(response => {
            return response.json();
        }).then(unmanagedRoles => {
            if (actionHelpers.isErrorResponse(unmanagedRoles)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_UNMANAGED_ROLES_FAILURE, unmanagedRoles);
            }

            dispatch({ type: actions.LOAD_UNMANAGED_ROLES_SUCCESS, unmanagedRoles, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_UNMANAGED_ROLES_FAILURE, null, error);
        });
    };
}

/**
 * Retrieve authorizations
 * @param {any} userId The user ID.
 * @param {any} resource The resource.
 * @param {any} resourceId The resource ID.
 * @param {any} role The role.
 * @returns {any} A function that returns a Promise.
 */
export function fetchAuthorizations(userId, resource, resourceId, role) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = '/authorization?';
        url += `resource=${resource}`;
        if (userId) { url += `&userId=${userId}`; }
        if (resourceId) { url += `&resourceId=${resourceId}`; }
        if (role) { url += `&role=${role}`; }

        return fetch(url).then(response => {
            return response.json();
        }).then(authorizations => {
            if (actionHelpers.isErrorResponse(authorizations)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_AUTHORIZATIONS_FAILURE, authorizations);
            }

            dispatch({ type: actions.LOAD_AUTHORIZATIONS_SUCCESS, authorizations, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_AUTHORIZATIONS_FAILURE, null, error);
        });
    };
}

/**
 * Retrieve the authorizations for the user.
 * @param {any} userId The user ID.
 * @returns {any} A function that returns a Promise.
 */
export function fetchAuthorizationsForCurrentUser(userId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = `/authorization?userId=${userId}`;
        return fetch(url).then(response => {
            return response.json();
        }).then(authorizations => {
            if (actionHelpers.isErrorResponse(authorizations)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CURRENT_USER_AUTHORIZATIONS_FAILURE, authorizations);
            }

            dispatch({ type: actions.LOAD_CURRENT_USER_AUTHORIZATIONS_SUCCESS, authorizations, [pendingTask]: end });
            return authorizations;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CURRENT_USER_AUTHORIZATIONS_FAILURE, null, error);
        });
    };

}

/**
 * Get the authorization by ID.
 * @param {any} id The ID of the authorization to fetch.
 * @returns {any} A function that returns a Promise.
 */
export function fetchAuthorization(id) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = `/authorization/${id}`;

        return fetch(url).then(response => {
            return response.json();
        }).then(authorization => {
            if (actionHelpers.isErrorResponse(authorization)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_AUTHORIZATION_FAILURE, authorization);
            }

            dispatch({ type: actions.LOAD_AUTHORIZATION_SUCCESS, authorization, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_AUTHORIZATION_FAILURE, null, error);
        });
    };
}


/**
 * Get the authorization by ID.
 * @param {any} id The ID of the authorization to fetch.
 * @returns {any} A function that returns a Promise.
 */
export function getMyEyUserByEmail(email) {
    let url = `/myEy/getUserByEmail?email=${email}`;

    return fetch(url).then(response => {
        return response.json();
    });
}

/**
 * Create a new authorization
 * @param {any} authorization The authorization to create.
 * @returns {any} A Promise.
 */
export function createAuthorization(authorization) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/authorization',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(authorization)
        }).then(response => {
            return response.json();
        }).then(authorization => {
            if (actionHelpers.isErrorResponse(authorization)) {
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                throw (authorization);
            }

            dispatch({ type: actions.LOAD_AUTHORIZATION_SUCCESS, authorization, [pendingTask]: end });
            return authorization;
        }
    ).catch((error) => {
        throw (error);
    });
    };
}

/**
 * Update an authorization
 * @param {any} authUpdate The authorization to update.
 * @returns {any} A Promise.
 */
export function updateAuthorization(authUpdate) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/authorization/${authUpdate.id}`,
        {
            headers: {
                'Accept':
                    "application/json",
                'Content-Type': "application/json"
            },
            method: "PUT",
            body: JSON.stringify(authUpdate)
        }).then(response => {
            return response.json();
        }).then(authUpdate => {
            if (actionHelpers.isErrorResponse(authUpdate)) {
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                throw (authUpdate);
            }

            dispatch({ type: actions.LOAD_AUTHORIZATION_SUCCESS, authUpdate, [pendingTask]: end });
            return authUpdate;
        }
    ).catch((error) => {
        throw (error);
    });
  };
}

/**
 * Remove authorization of resource by id
 * @param {GUID} id The ID of the authorization to remove.
 * @returns {any} A Promise
 */
export function removeAuthorization(id) {
    return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(
        `/authorization/${id}`,
       { headers: {
                  'Accept':
                      "application/json",
                  'Content-Type': "application/json"
              },
              method: "DELETE",
            } )
      .then(response => {
        return response.text();
    })
        .then((response) => {
          const jsonResponse = response ? JSON.parse(response) : {};      
          if (actionHelpers.isErrorResponse(jsonResponse)) {
            return actionHelpers.dispatchErrorAndEndTask(
              dispatch,
              actions.DELETE_AUTH_FAILURE,
              jsonResponse
            );
          }
  
          dispatch({ type: actions.TASK_END, [pendingTask]: end });
          return jsonResponse;
        })
        .catch((error) => {
          actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_AUTH_FAILURE,
            null,
            error
          );
          
        });
    };
  }
  

/**
 * Fetch allowed domains for authorizations.
 * @returns {any} A Promise with an array of services, or an error response if unsuccessful.
 */
export function fetchAllowedAuthorizationDomains() {
    return fetch('/authorization/allowedDomains').then(response => {
        return response.json();
    });
}

/**
 * Filter active authorizations from an array of authorizations
 * @param {any} allAuthorizations An array of authorizations to filter.
 * @returns {Array} An array of active authorizations.
 */
export function getActiveAuthorizations(allAuthorizations) {
    if (!Array.isArray(allAuthorizations)) {
        return allAuthorizations;
    }

    return allAuthorizations.filter(item => {
        if (!item.expiration) {
            return true;
        }

        const exDate = new Date(item.expiration);
        const today = new Date();

        if (exDate.getTime() > today.getTime()) {
            return true;
        }

        return false;
    });
}

/**
 * Determine if any of the authorizations in an array belong to a System Administrator
 * @param {any} authorizations The array of authorizations.
 * @returns {bool} True if any of the authorizations belong to a System Administrator, otherwise false.
 */
export function isSystemAdministrator(authorizations) {
    return hasMatchingAuthorization(authorizations, 1, 1);
}

/**
 * Determine if any of the authorizations in an array belong to a Engagement Administrator
 * @param {any} authorizations The array of authorizations.
 * @param {any} clientId The client ID.
 * @returns {bool} True if any of the authorizations belong to an Engagement Administrator, otherwise false.
 */
export function isEngagementAdministrator(authorizations, clientId) {
    return hasMatchingAuthorization(authorizations, 2, 1, clientId);
}
/**
 * Determine if any of the authorizations in an array belong to a Engagement Administrator and a Data Steward
 * @param {any} authorizations The array of authorizations.
 * @param {any} clientId The client ID.
 * @returns {bool} True if any of the authorizations belong to an Engagement Administrator and a Data Steward, otherwise false.
 */
export function isEngagementAdministratorAndDataSteward(authorizations, clientId) {
    return hasMatchingAuthorizationAndDataSteward(authorizations, 2, 1, clientId);
}
/**
 * Determine if any of the authorizations in an array belong to a User
 * @param {any} authorizations The array of authorizations.
 * @param {any} clientId The client ID.
 * @returns {bool} True if any of the authorizations belong to a User, otherwise false.
 */
export function isUser(authorizations, clientId) {
    return hasMatchingAuthorization(authorizations, resource.Client, role.User, clientId);
}

/**
 * Determines if any of the authorizations in an array match the resource, role, and resource ID.
 * @param {any} authorizations The array of authorizations.
 * @param {any} resource The resource
 * @param {any} role The role
 * @param {any} resourceId The resource ID
 * @returns {bool} True if any of the authorizations match, otherwise false.
 */
export function hasMatchingAuthorization(authorizations, resource, role, resourceId) {
    if (!Array.isArray(authorizations)) {
        return false;
    }

    const activeAuthorizations = getActiveAuthorizations(authorizations);
    for (let i = 0; i < activeAuthorizations.length; i++) {
        let item = activeAuthorizations[i];
        if (item.resource === resource && item.role === role) {
            if (resourceId) {
                if (item.resourceId === resourceId.toString()) {
                    return true;
                }

                continue;
            }

            // only if both resourceId fields are null and resource and role are equal
            // then this qualifies as a matching authorization
            if (!item.resourceId) {
                return true;
            }
        }
    }

    return false;
}

/// Set client id to show refresh message on Manage Checklist section for that particular client
export function refreshManageChecklistClient(clientId) {
    return function (dispatch) {
        dispatch({ type: actions.REFRESH_MANAGE_CHECKLIST_CLIENT, clientId});
    };
}

/**
 * Determines if any of the authorizations in an array match the resource, role, and resource ID.
 * @param {any} authorizations The array of authorizations.
 * @param {any} resource The resource
 * @param {any} role The role
 * @param {any} resourceId The resource ID
 * @returns {bool} True if any of the authorizations and a DataSteward match, otherwise false.
 */
export function hasMatchingAuthorizationAndDataSteward(authorizations, resource, role, resourceId) {
    if (!Array.isArray(authorizations)) {
        return false;
    }

    const activeAuthorizations = getActiveAuthorizations(authorizations);
    for (let i = 0; i < activeAuthorizations.length; i++) {
        let item = activeAuthorizations[i];
        if (item.resource === resource && item.role === role) {
            if (resourceId) {
                if (item.resourceId === resourceId.toString()) {
                  if(item.isDataSteward == true)
                  {
                    return true;
                  }
                }

                continue;
            }

            // only if both resourceId fields are null and resource and role are equal
            // then this qualifies as a matching authorization
            if (!item.resourceId) {
                return true;
            }
        }
    }

    return false;
}
