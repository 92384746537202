import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_CLIENT_ACCOUNT_DATA_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function varianceDetails(state = initialState.varianceDetails, action) {
    switch (action.type) {
        case actions.LOAD_CLIENT_ACCOUNT_DATA_SUCCESS:
            return action.varianceDetails;
        case actions.UNLOAD_TESTS:
            return initialState.varianceDetails;
        default:
            return state;
    }
}