import React from "react";
import { Form } from "semantic-ui-react";
import { reduxForm } from "redux-form";
import { MotifButton } from "@ey-xd/motif-react";

const BulkReportManagementHeader = ({    
    currentUserIsSysAdmin,
    currentUserIsEngAdmin,
    currentUserIsEngagementUser,
    toggleShowCreateAndRunReportPackageModal,
}) => {    
    return (
        <Form>
            <div className="row mt-2 mb-4">
                {(currentUserIsSysAdmin || currentUserIsEngAdmin || currentUserIsEngagementUser) && <div className="col">                                      
                    <MotifButton
                        variant="primary"
                        className="right ml-2"
                        type="button"
                        title="Create and Run Package"
                        data-testid="btnCreateRunPackage"
                        onClick={() => { toggleShowCreateAndRunReportPackageModal(); }}
                    >
                        Create and Run Package
                    </MotifButton>                                    
                </div>}
            </div>
        </Form>
    );
};

BulkReportManagementHeader.propTypes = {
};

export default reduxForm({ form: "bulkReportManagementForm" })(BulkReportManagementHeader);