import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the LOAD_BULK_ROLL_FORWARD_DATA_SUCCESS, LOAD_BULK_ROLL_FORWARD_DATA_FAILURE & RECEIVE_BULK_ROLL_FORWARD_STATUS_UPDATE_MESSAGE action and sets bulk roll forward grid data to the store
 * @param {Object} [state] The bulk roll forward grid data
 * @param {Object} action The action to be handled
 * @returns {Object} The bulk roll forward grid data
 */

export default function bulkRollForwardDataReducer(
  state = initialState.bulkRollForwardData,
  action
) {
  switch (action.type) {
    case actions.LOAD_BULK_ROLL_FORWARD_DATA_SUCCESS:
      return action.bulkRollForwardData;
    case actions.LOAD_BULK_ROLL_FORWARD_DATA_REFRESH:
      return action.message;
    case actions.RECEIVE_BULK_ROLL_FORWARD_STATUS_UPDATE_MESSAGE:
      return state.map(row => {
        if (row.reitid === action.message.REITID && row.reitName == action.message.REITName) {
          return {
            ...row,
            isReitActive: action.message.IsReitActive,
            isReitAvailableForSelection: action.message.IsReitAvailableForSelection,
            currentPeriod: action.message.CurrentPeriod,
            nextAvailablePeriod: action.message.NextAvailablePeriod,
            bulkRollForwardStatusDescription: action.message.BulkRollForwardStatusDescription,
            reitSelection: false
          };
        }

        return row;
      });
    case actions.RECEIVE_BULK_ROLL_FORWARD_STATUS_CONCURRENT_UPDATE_MESSAGE:
      return state.map(row => {
        if (row.reitid === action.message.REITID && row.reitName == action.message.REITName) {
          return {
            ...row,
            isReitAvailableForSelection: action.message.IsReitAvailableForSelection,
            reitSelection: false
          };
        }

        return row;
      });       
    case actions.LOAD_BULK_ROLL_FORWARD_DATA_FAILURE:
    default:
      return state;
  }
}
