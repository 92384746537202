import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'semantic-ui-react';
import ErrorSummary from '../shared/ErrorSummary';
import $ from 'jquery';
import { FileField } from "../shared/formFields";
import { MotifButton } from '@ey-xd/motif-react';

const OtherFileUpload = ({ onSubmit, onFileChange, file, canUpload, title }) => {
    const clearFileInput = () => {
        $('input[name=otherFileToUpload]').val("");
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    {(title && <h5>{title}</h5>) || <h5>General &amp; Other Files{canUpload ? "" : " - Period Closed"}</h5>}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '20%' }} />
                                <td style={{ width: '15%', minWidth: '150px' }} />
                                <td style={{ width: '1%' }} />
                                <td style={{ width: '34%'}} className="text-nowrap" >
                                    <form className="" onSubmit={onSubmit}>
                                        <div>
                                          <span>  
                                            <FileField
                                                name="otherFileToUpload"
                                                onChange={onFileChange}
                                                className="form-control tbinput"
                                                        disabled={!canUpload} />
                                            </span>
                                            <span >
                                                <MotifButton type="submit" variant="primary" className="ml-4" disabled={!file} onMouseUp={clearFileInput}>Upload</MotifButton>
                                            </span>
                                        </div>
                                            </form>
                                </td>
                                <td style={{ width: '15%' }} />
                                <td style={{ width: '15%' }} />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

OtherFileUpload.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    file: PropTypes.object,
    canUpload: PropTypes.bool,
    title: PropTypes.string
};

export default OtherFileUpload;
