import PropTypes from "prop-types";
import React from "react";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifLabel,
} from "@ey-xd/motif-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";

const SyncREITTestingAttributesModal = ({
  showSyncREITTestingAttributesModal,
  handleSubmit,
  toggleShowSyncREITTestingAttributesModal,
  syncREITTestingAttributesFormValues,
  initialValues,
  selectedTbName,
  tbMode,
}) => {
  let applyToREITorProperties = "Apply only to " + selectedTbName;
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
  return (
    <MotifModal
      variant="alt"
      size="sm"
      aria-label="Restore Default REIT Testing Attributes"
      show={showSyncREITTestingAttributesModal}
      onClose={toggleShowSyncREITTestingAttributesModal}
      data-testid="SyncReitTestingAttributes"
    >
      <MotifModalHeader
        closeButtonProps={toggleShowSyncREITTestingAttributesModal}
      >
        <HeaderWrapper>
          <span className="ml-2">Restore Default REIT Testing Attributes</span>
        </HeaderWrapper>
      </MotifModalHeader>

      <form className="synchREITTestingAttribute">
        <MotifModalBody>
          {tbMode && tbMode !== 2 && <br />}
          <span>
            {tbMode && tbMode !== 2 && (
              <Field
                name="synchType"
                component={formHelpers.renderField}
                type="radio"
                value="1"
                label={applyToREITorProperties}
                labelLocationOnRight={true}
              />
            )}
          </span>
          <br />
          <span>
            <Field
              name="synchType"
              component={formHelpers.renderField}
              type="radio"
              value="2"
              label="Apply to REIT and all Properties"
              labelLocationOnRight={true}
            />
          </span>
          <br />

          <br />
          <br />
          <div className="mb-3">
            By clicking confirm, you agree to push these changes.
          </div>
        </MotifModalBody>

        <MotifModalFooter>
          <MotifButton
              variant="secondary"
              className="right"
              type="button"
              onClick={toggleShowSyncREITTestingAttributesModal}
            >
              Cancel
          </MotifButton>
          <MotifButton
            variant="primary"
            className="right"
            type="button"
            onClick={() => handleSubmit()}
            disabled={!syncREITTestingAttributesFormValues}
          >
            Confirm
          </MotifButton>
        </MotifModalFooter>
      </form>
    </MotifModal>
  );
};

SyncREITTestingAttributesModal.propTypes = {
  showSyncREITTestingAttributesModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggleShowSyncREITTestingAttributesModal: PropTypes.func,
  initialValues: PropTypes.object,
  tbMode: PropTypes.number,
  syncREITTestingAttributesFormValues: PropTypes.object,
};

export default reduxForm({
  form: "synchREITTestingAttribute",
  enableReinitialize: true,
})(SyncREITTestingAttributesModal);
