const shallowCompare = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key =>
        Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key]
    );

export const indexOfWhile = (arr, value) => {
    let x = 0;
    while (x < arr.length) {
        if (arr[x] === value) return x;
        x++;
    }
    return -1;
};

export const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    // This only returns true if the sort order is also the same

    for (var i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

export const arraysEqualComplex = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    // This only returns true if the sort order is also the same

    for (var i = 0; i < a.length; i++) {
        if (!shallowCompare(a[i], b[i])) {
            return false;
        }
    }

    return true;
};