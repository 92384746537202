import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";
import { fetchFromClientApi as fetch } from "./fetchFromApi";
import * as actionHelpers from '../scripts/actionHelpers';

export const loadFeatureFlags = () => {
    return (dispatch) => {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
      return fetch(`/features`)
        .then((response) => {
          return response.json();
        })
        .then((featureFlags) => {
          if (actionHelpers.isErrorResponse(featureFlags)) {
            return actionHelpers.dispatchErrorAndEndTask(
              dispatch,
              actions.LOAD_FEATURE_FLAGS_FAILURE,
              null
            );
          }
          dispatch({
            type: actions.LOAD_FEATURE_FLAGS_SUCCESS,
            featureFlags,
            [pendingTask]: end,
          });
  
          return featureFlags;
        })
        .catch((error) => {
          actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_FEATURE_FLAGS_FAILURE,
            null,
            error
          );
        });
    };
  }




