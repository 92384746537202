import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/servicesActions";
import * as reitActions from "../../actions/reitActions";
import ScopedServicesModal from "../../components/reits/ScopedServicesModal";

/**
 * The container that renders ScopedServicesModal
 */
export class ScopedServicesContainer extends React.Component {
    /**
     * Creates a new ScopedServicesContainer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = { selectedServices: [] };

        this.handleOnSaveAction = this.handleOnSaveAction.bind(this);
        this.handleOnCancelAction = this.handleOnCancelAction.bind(this);
        this.handleServiceSelected = this.handleServiceSelected.bind(this);
        this.fetchServices = this.fetchServices.bind(this);
    }

    /**
     * Invoked immediately before mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        /// if (this.props.showServicesModal) {
        this.fetchServices(this.props.periodId);
        if (this.props.reitActions) {
            this.props.reitActions.fetchReitLookupTypes();
        }
        // }        
    }

    componentDidUpdate(prevProps) {      
        if (this.props.services !== prevProps.services) {
            this.setState({ selectedServices: this.props.services });
        } 
        if (prevProps.periodId !== this.props.periodId) {
            this.fetchServices(this.props.periodId);
        }
    }

    handleOnSaveAction() {
        if (this.state.selectedServices.length > 0) {
            this.props.actions.saveServices(this.props.reitId, this.props.periodId, { services: this.state.selectedServices })
                .then(() => this.fetchServices(this.props.periodId))
                .then(() => this.props.toggleShowServicesModal());
        } else {
            this.props.actions.saveServices(this.props.reitId, this.props.periodId, { services: ["None"] })
                .then(() => this.fetchServices(this.props.periodId))
                .then(() => this.props.toggleShowServicesModal());
        }
    }

    handleOnCancelAction() {
        this.fetchServices(this.props.periodId);
        this.props.toggleShowServicesModal();
    }

    fetchServices(periodId) {
        return this.props.actions.fetchServicesByPeriod(periodId);
    }

    handleServiceSelected(service, isSelected) {
        if (isSelected) {
            const selectedServicesSet = new Set([...this.state.selectedServices, service]);
            this.setState({ selectedServices: [...selectedServicesSet] });
        } else {
            const selectedServices = this.state.selectedServices.filter(s => s != service);
            this.setState({ selectedServices });
        }
    }


    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <ScopedServicesModal
                services={this.state.selectedServices}
                showServicesModal={this.props.showServicesModal}
                handleSave={this.handleOnSaveAction}
                handleCancel={this.handleOnCancelAction}
                periodId={this.props.periodId}                
                lookupTypes={this.props.lookupTypes}
                handleServiceSelected={this.handleServiceSelected} />
        );
    }
}

ScopedServicesContainer.propTypes = {
    periodId: PropTypes.number.isRequired,
    reitId: PropTypes.number,
    services: PropTypes.array,
    showServicesModal: PropTypes.bool,
    toggleShowServicesModal: PropTypes.func,
    reitActions: PropTypes.object,
    actions: PropTypes.object.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: ownProps.periodId,
        services: state.services,
        showServicesModal: ownProps.showServicesModal,
        toggleShowServicesModal: ownProps.toggleShowServicesModal,
        lookupTypes: state.lookupTypes
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            saveServices: (reitId, periodId, services) => actions.saveServices(reitId, periodId, services)(dispatch),
            fetchServicesByPeriod: (periodId) => actions.fetchServicesByPeriod(periodId)(dispatch),
            fetchReit: (reitId) => reitActions.fetchReit(reitId)(dispatch)
        },
        reitActions: bindActionCreators(reitActions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ScopedServicesContainer);