import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";

import { MotifButton,MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter, MotifLabel } from "@ey-xd/motif-react";

const DuplicateAccountsEditModal = ({
    showDuplicateAccountsEditModal,
    closeDuplicateAccountsEditModal,
    clientAccountCodeList,
    theme
}) => {
    //const listModalColor = theme === 'motifDark' ? '#a4a3b1' : '#656579';
    return (
        <MotifModal aria-label="Duplicate Notification" show={showDuplicateAccountsEditModal} onClose={closeDuplicateAccountsEditModal}>
            <MotifModalHeader>
                Cannot Save
            </MotifModalHeader>
            <MotifModalBody>
                <form>
                    <MotifLabel>Your changes cannot be saved. The following Client Account Number(s) already exist in this chart:</MotifLabel>
                    <ul>
                        {clientAccountCodeList.map(code => <li key={code}>{code}</li>)}
                    </ul>
                </form>   
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="primary" className="right" type="button"
                    onClick={closeDuplicateAccountsEditModal}>
                    Close
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

DuplicateAccountsEditModal.propTypes = {
    showDuplicateAccountsEditModal: PropTypes.bool,
    closeDuplicateAccountsEditModal: PropTypes.func,
    clientAccountCodeList: PropTypes.array,
    theme: PropTypes.string,
};

export default DuplicateAccountsEditModal;