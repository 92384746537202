import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_QUEUE_COUNT_SUCCESS and LOAD_QUEUE_COUNT_FAILURE actions and sets the processing queue count in the store
 * @param {Object} [state] The processing queue count currently in the store, or the initial state of the processing queue count
 * @param {Object} action The action to be handled
 * @returns {Object} The processing queue count
 */
export default function processingQueueCount(state = initialState.processingQueueCount, action) {
    switch (action.type) {
        case actions.LOAD_QUEUE_COUNT_SUCCESS:
            return action.count;
        case actions.LOAD_QUEUE_COUNT_FAILURE:
            return 0;
        default:
            return state;
    }
}