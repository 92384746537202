export const options = [
    {
        "label": "Assisted Living", "value": "Assisted Living"
    },
    {
        "label": "Bulk Item Removal", "value": "Bulk Item Removal"
    },
    {
        "label": "Child Care", "value": "Child Care"
    },
    {
        "label": "Concierge", "value": "Concierge"
    },
    {
        "label": "Constructing Tenant Improvements During Lease Term", "value": "Constructing Tenant Improvements During Lease Term"
    },
    {
        "label": "Fitness Aerobics Instruction", "value": "Fitness Aerobics Instruction"
    },
    {
        "label": "Food Service", "value": "Food Service"
    },
    {
        "label": "Interior Design & Decorating", "value": "Interior Design & Decorating"
    },
    {
        "label": "IT Services", "value": "IT Services"
    },
    {
        "label": "Maid Service, Specialty Cleaning", "value": "Maid Service, Specialty Cleaning"
    },
    {
        "label": "Medical Services", "value": "Medical Services"
    },
    {
        "label": "Office/ Secretarial Services", "value": "Office/ Secretarial Services"
    },
    {
        "label": "Painting of Leased Space During Lease Term", "value": "Painting of Leased Space During Lease Term"
    },
    {
        "label": "Pet Sitting", "value": "Pet Sitting"
    },
    {
        "label": "Pick Up and Delivery Of Goods", "value": "Pick Up and Delivery Of Goods"
    },
    {
        "label": "Real Estate Brokerage", "value": "Real Estate Brokerage"
    },
    {
        "label": "Repairing Tenants Personal Property", "value": "Repairing Tenants Personal Property"
    },
    {
        "label": "Repair/ Replace Lighting Fixtures", "value": "Repair/ Replace Lighting Fixtures"
    },
    {
        "label": "Specialty Security", "value": "Specialty Security"
    },
    {
        "label": "Shipping, Transportation Of Goods", "value": "Shipping, Transportation Of Goods"
    },
    {
        "label": "Shuttle Bus Transportation", "value": "Shuttle Bus Transportation"
    },
    {
        "label": "Tenant Plant Care", "value": "Tenant Plant Care"
    },
    {
        "label": "Public Parking", "value": "Public Parking"
    },
    {
        "label": "Valet Parking", "value": "Valet Parking"
    },
    {
        "label": "Reserved Parking", "value": "Reserved Parking"
    },
    {
        "label": "Other", "value": "Other"
    }];