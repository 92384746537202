import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_CLIENT_SUCCESS action and sets a client in the store
 * @param {Object} [state] The client currently in the store, or the initial state of client
 * @param {Object} action The action to be handled
 * @returns {Object} The client
 */
export default function clients(state = initialState.client, action) {
    switch(action.type) {
        case actions.LOAD_CLIENT_SUCCESS:            
            return action.client;
        case actions.LOAD_CLIENTS_SUCCESS:            
            return initialState.client;
        case actions.UNLOAD_CLIENT:            
            return null;
        default:
            return state;
    }
}