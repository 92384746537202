export const handleFilterChanged = (gridRef, currentPage, setCurrentPage, setTotalPages) => {  
  let filteredRowData = [];
  if (gridRef && gridRef.current && gridRef.current.api) {
    setTimeout(() => {
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
      if (currentPage > calcTotalPages) {
        if (calcTotalPages == 0) {
          setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
        }
        else {
          setCurrentPage(calcTotalPages);
        }
      } else{
        setCurrentPage(currentPage);
      }
    }, 0);
  }
}

  export const handleFirstDataRendered = (gridRef) => {
    setTimeout(() => {
    return (event) => {
      gridRef.current.columnApi.autoSizeAllColumns();
    };
  }, 0);
  };

  // gridHandlers.js

export const saveUserPreferenceDetails = (gridRef, localStorageKey) => {
  var columnState = gridRef.current.columnApi.getColumnState();
  if (columnState) {
      localStorage.setItem(localStorageKey, JSON.stringify(columnState));
  }
};

export const handleGridColumnsChanged = (event, gridRef, localStorageKey) => {
  var currentColumnState = gridRef.current.columnApi.getColumnState();
  var localStorageColumnState = localStorage.getItem(localStorageKey);

  // If the column state details are not exists in local storage then save the changed Column state data
  if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
      saveUserPreferenceDetails(gridRef, localStorageKey);
  }
  else if (localStorageColumnState) {
      // If the column state details are exists in local storage then save the changed Column state data only if difference is found
      var columnsStateArray = JSON.parse(localStorageColumnState);
      if (columnsStateArray && columnsStateArray.length > 0
          && currentColumnState && currentColumnState.length > 0
          && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
          //If column state mismatch found then only save column state data to avoid saving same state
          saveUserPreferenceDetails(gridRef, localStorageKey);
      }
  }

  //Trigger Grid Column state change (of parent) to sync column state data
  // commented this code to resolve the build error and the functionality is working without this code block
   // handleGridColumnStateChange(currentColumnState);

  gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
};

export const gridGlobalListener = (localStorageKey, gridRef, setCurrentPage, setTotalPages, handleDeselectAll) => {
  return function (type, event) {
      if (type.indexOf("columnVisible") >= 0) {
          handleGridColumnsChanged(event,gridRef,localStorageKey);
      }

      // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
      if (type.indexOf("filterChanged") >= 0 || type.indexOf("rowDataUpdated") >= 0) {
        let currentGridPage = 1;        
        if (gridRef && gridRef.current && gridRef.current.api) {
          currentGridPage = gridRef.current.api.paginationGetCurrentPage() + 1;          
        }
        //
       // This is to handle when user deselects select all
       let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
        if (type.indexOf("filterChanged") >= 0) {
          if(calcTotalPages > 0){
          setTimeout(function () { handleFilterChanged(gridRef, currentGridPage, setCurrentPage, setTotalPages); }, 0);  
          }
          else{
            console.log('#skip'); 
            handleDeselectAll();
          }          
        }

        if (type.indexOf("rowDataUpdated") >= 0) {
          setTimeout(function () { handleFilterChanged(gridRef, currentGridPage, setCurrentPage, setTotalPages); }, 0);            
        }
      }   
  }
};

// Keep a reference to the listener function
let globalListenerRef;
export const handleGridReady = (event, gridRef, setCurrentPage, setItemsPerPage, setTotalPages, localStorageKey, handleDeselectAll) => {

 // If there's an existing listener, remove it
 if (globalListenerRef) {
  event.api.removeGlobalListener(globalListenerRef);
}

// Create a new listener and keep a reference to it
globalListenerRef = gridGlobalListener(localStorageKey, gridRef, setCurrentPage, setTotalPages,handleDeselectAll);
//Add event for column state change
event.api.addGlobalListener(globalListenerRef);

//Apply column state
var columnState = localStorage.getItem(localStorageKey);
if (columnState) {
    gridRef.current.columnApi.applyColumnState({
        state: JSON.parse(columnState),
        applyOrder: true,
    });
}


// Set the page size and total pages on the first load        
handleItemsPerPageChange(100, gridRef,setCurrentPage,setItemsPerPage,setTotalPages); // Setting it 100 to make sure the default number of items on load is always set to 100
};

export const handleItemsPerPageChange = (val, gridRef, setCurrentPage, setItemsPerPage, setTotalPages) => {
  gridRef.current.api.paginationGoToFirstPage();
  gridRef.current.api.paginationSetPageSize(Number(val));
  setCurrentPage(1);
  setItemsPerPage(val);
  // Get total pages from Grid's default pagination control and apply it to custom pagination control
  if (gridRef && gridRef.current && gridRef.current.api) {
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
      //If total page is zero then set the current page as zero
      if (calcTotalPages == 0) {
          setCurrentPage(calcTotalPages);
      }
  }
};

export const tableHeaderClass = (theme) => {
  return theme === "motifDark" ? "custom-grid-dark" : "custom-grid-light";
}
