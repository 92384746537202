import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_QUESTION_DISCREPANCIES_SUCCESS action and sets client discrepancies data in the store
 * @param {Object} [state] The question discrepancies data currently in the store, or the initial state of question discrepancies data
 * @param {Object} action The action to be handled
 * @returns {Object} The question discrepancies data
 */
export default function questionDiscrepanciesReducer(state = initialState.discrepanciesDetail, action) {
    if (action.type == actions.LOAD_QUESTION_DISCREPANCIES_SUCCESS && Array.isArray(action.discrepanciesDetail)) {
        return action.discrepanciesDetail;
    } else if (action.type == actions.LOAD_QUESTION_DISCREPANCIES_FAILURE) {
        return initialState.discrepanciesDetail;
    } else {
        return state;
    }
}