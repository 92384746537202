import React from "react";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, getFormValues, reset } from "redux-form";

import BulkRollForwardHeader from "../../components/bulkRollForward/bulkRollForwardHeader";
import * as bulkRollForwardActions from "../../actions/bulkRollForwardActions";
import * as reitActions from "../../actions/reitActions";
import BulkRollForwardTable from "../../components/bulkRollForward/BulkRollForwardTable";

import { store }  from "../../store/configureStore";
import * as actions from '../../actions/actionTypes';
import { withRouter } from "../../common/withRouter";

/**
 * BulkRollForwardContainer component
 * @extends {React.Component}
 */
export class BulkRollForwardContainer extends React.Component {
    /**
    * Creates a new BulkRollForwardContainer
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.setGridColumnState = this.setGridColumnState.bind(this);
        this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
        this.initiateRollForwardReitsProcess = this.initiateRollForwardReitsProcess.bind(this);

        this.state = {
            gridCurrentState: [],
            gridColumnState: [],
            bulkRollForwardData: [],
            enableSubmit: false,
            bulkProcessActionId: 8 /*Default for Roll Forward*/
        };
    }

    /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
    componentDidMount() {
        // Fetch Reits for Bulk Roll Forward
        this.getRollForwardGridData();
    }

    /**
     * Invoked when a new props is recived
     * @returns {undefined}
     */


    componentDidUpdate(prevProps) {
        // Reset grid data based on roll forward type selection
        if (JSON.stringify(this.props.bulkRollForwardFormValues) !== JSON.stringify(prevProps.bulkRollForwardFormValues)) {
            if (this.props.bulkRollForwardFormValues && this.props.bulkRollForwardFormValues.rollForwardToSelectionType) {
                if (this.props.bulkRollForwardFormValues.rollForwardToSelectionType === 'nextAvailablePeriod') {
                    const updatedBulkRollForwardData = this.state.bulkRollForwardData.map((data) => {
                        if (data.isReitActive && data.isReitAvailableForSelection) {
                            return {
                                ...data,
                                rollForwardToPeriod: data.nextAvailablePeriod,
                                reitSelection: this.state.gridCurrentState.find(x => x.reitid === data.reitid)?.reitSelection
                            };
                        } else {
                            return data;
                        }
                    });

                    this.setState({ bulkRollForwardData: updatedBulkRollForwardData });
                } else if (this.props.bulkRollForwardFormValues.rollForwardToSelectionType === 'customPeriod') {
                    if (this.props.bulkRollForwardFormValues?.quarterSelection && this.props.bulkRollForwardFormValues?.yearSelection) {
                        const updatedBulkRollForwardData = this.state.bulkRollForwardData.map((data) => {
                            if (data.isReitActive && data.isReitAvailableForSelection) {
                                return {
                                    ...data,
                                    rollForwardToPeriod: `${this.props.bulkRollForwardFormValues?.quarterSelection} ${this.props.bulkRollForwardFormValues?.yearSelection}`,
                                    reitSelection: this.state.gridCurrentState.find(x => x.reitid === data.reitid)?.reitSelection
                                };
                            } else {
                                return data;
                            }
                        });

                        this.setState({ bulkRollForwardData: updatedBulkRollForwardData });
                    } else {
                        const updatedBulkRollForwardData = this.state.bulkRollForwardData.map((data) => {
                            return {
                                ...data,
                                rollForwardToPeriod: null,
                                reitSelection: this.state.gridCurrentState.find(x => x.reitid === data.reitid)?.reitSelection
                            };
                        });

                        this.setState({ bulkRollForwardData: updatedBulkRollForwardData });
                    }
                }
            }
        }

        // Reset grid data when a data refresh comes in
        if (prevProps.bulkRollForwardData
            && this.props.bulkRollForwardData != prevProps.bulkRollForwardData) {
            this.setState({ bulkRollForwardData: this.props.bulkRollForwardData, enableSubmit: false }, this.resetForm);
        }
}

/**
 * Invoked immediately before unmounting occurs
 * @returns {undefined}
 */
componentWillUnmount() {
    // On unmount, clear the state to conserve memory
    this.setState({ bulkRollForwardData: [] });

    // Clearing the redux state for bulk roll forward data
    this.props.bulkRollForwardActions.fetchBulkRollForwardData();
}

getRollForwardGridData() {
    this.props.bulkRollForwardActions.fetchBulkRollForwardData(this.props.clientId);
}

/**
* Set AG-Grid column state   
* @returns {undefined}
*/
setGridColumnState() {
    var columnState = localStorage.getItem('REITSuiteBulkRollForwardColumnState');
    if (columnState) {
        this.setState({ gridColumnState: JSON.parse(columnState) });
    }
    else {
        this.setState({ gridColumnState: [{ colId: '-1' }] });
    }
}

/**
* Handle Grid column state chagne
* @returns {undefined}
*/
handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
}

handleGridChanges(gridRowData) {
    const rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
        return {
            reitid: x.reitid,
            isReitActive: x.isReitActive,
            isReitAvailableForSelection: x.isReitAvailableForSelection,
            reitSelection: x.isReitAvailableForSelection ? x.reitSelection : false,
            reitName: x.reitName,
            currentPeriod: x.currentPeriod,
            rollForwardToPeriod: x.reitSelection ? this.getRollForwardToPeriod(x.reitid) : null,
            rollForwardStatus: x.rollForwardStatus,
        }
    });

    this.setState({ gridCurrentState: rowData }, this.checkForSubmitButtonEnablement);
}

getRollForwardToPeriod(reitId) {
    const [first] = this.state.bulkRollForwardData.filter(r => r.reitid === reitId);
    return first.rollForwardToPeriod;
    
}

checkForSubmitButtonEnablement() {
    

    if ( Array.isArray(this.state.gridCurrentState)) {
        const isAnyReitSelected = this.state.gridCurrentState.some(r => r.reitSelection);
    //const areReitsSlected = this.state.bulkRollForwardData.some(r => r.reitSelection);

    if (isAnyReitSelected && this.props.bulkRollForwardFormValues?.rollForwardToSelectionType === 'nextAvailablePeriod') {
        this.setState({ enableSubmit: true });
    } else if (isAnyReitSelected && this.props.bulkRollForwardFormValues?.rollForwardToSelectionType === 'customPeriod') {
        if (this.props.bulkRollForwardFormValues?.quarterSelection && this.props.bulkRollForwardFormValues?.yearSelection) {
            this.setState({ enableSubmit: true });
        } else {
            this.setState({ enableSubmit: false });
        }
    } else if (!isAnyReitSelected) {
        this.setState({ enableSubmit: false });
    }
}
}

initiateRollForwardReitsProcess() {
    const request = {
        clientId: this.props.clientId,
        calendarYear: this.state.selectedCalanderYear,
        quarterDescription: this.state.selectedReportPeriod,
        reitIds: this.getSelectedReits(),
        bulkProcessActionId: this.state.bulkProcessActionId,
        diagnosticMessage: this.state.diagnosticMessage,
        bulkProcessRequestJSON: this.getBulkProcessRequestJSON(),
    };

    const currentGridState = [...this.state.gridCurrentState];
    const currentBulkRollForwardDataState = [...this.state.bulkRollForwardData];

    this.updateGridAndDataStatePostSubmission(request.reitIds);

    this.resetForm();

    this.props.bulkRollForwardActions.initiateBulkRollForwardProcess(request, currentBulkRollForwardDataState)
        .then((isErrored) => {
            if (isErrored) {
                this.setState({ currentGridState: currentGridState, bulkRollForwardData: currentBulkRollForwardDataState });
            }
        })
        .catch((isErrored) => {
            if (isErrored) {
                this.setState({ currentGridState: currentGridState, bulkRollForwardData: currentBulkRollForwardDataState });
            }
        });
}

updateGridAndDataStatePostSubmission(reitsToBeUpdated) {
    const rowData = this.state.gridCurrentState.map(row => {
        if (reitsToBeUpdated.find(r => r === row.reitID)) {
            return {
                ...row,
                isReitAvailableForSelection: false,
                reitSelection: false,
                rollForwardToPeriod: null
            };
        } else {
            return {
                ...row,
                rollForwardToPeriod: null
            };
        }
    });

    const updatedBulkRollForwardtData = this.state.bulkRollForwardData.map(data => {
        if (reitsToBeUpdated.find(r => r === data.reitid)) {
            return {
                ...data,
                isReitAvailableForSelection: false,
                reitSelection: false,
                rollForwardToPeriod: null
            };
        } else {
            return {
                ...data,
                rollForwardToPeriod: null
            };
        }
    });

    this.props.bulkRollForwardActions.dispatchBulkRollForwardData(updatedBulkRollForwardtData, actions.LOAD_BULK_ROLL_FORWARD_DATA_REFRESH);
    this.setState({ gridCurrentState: rowData });
}

getSelectedReits() {
    return this.state.gridCurrentState.filter(r => r.reitSelection).map(r => r.reitid);
}

getBulkProcessRequestJSON() {
    return {
        services: null,
        requestedSignOffStatus: null,
        bulkRollForwardSelections: this.getBulkRollForwardSelections(),
    };
}

getBulkRollForwardSelections() {
    const dataSelections = this.state.gridCurrentState.filter(r => r.reitSelection).map((r) => {
        return {
            reitId: r.reitid,
            currentPeriod: r.currentPeriod,
            rollForwardToPeriod: r.rollForwardToPeriod
        };
    });

    return dataSelections;
}

resetForm() {
    store.dispatch(reset('bulkRollForwardForm'));
}

/**
* Render a React element
* @returns {Object} A reference to the component
*/
render() {
    return (
        <>
            <BulkRollForwardHeader
                enableSubmit={this.state.enableSubmit}
                onSubmit={this.initiateRollForwardReitsProcess}
            />
            <BulkRollForwardTable
                bulkRollForwardData={this.state.bulkRollForwardData}
                handleGridChanges={this.handleGridChanges}
                gridColumnState={this.state.gridColumnState}
                gridCurrentState={this.state.gridCurrentState}
                handleGridColumnStateChange={this.handleGridColumnStateChange}
            />
        </>
    );
}
}

BulkRollForwardContainer.propTypes = {
    clientId: PropTypes.number,
    bulkRollForwardData: PropTypes.array,
    bulkRollForwardActions: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: Number.parseInt(ownProps.router.params.clientId),
        bulkRollForwardData: state.bulkRollForwardData,
        bulkRollForwardFormValues: getFormValues('bulkRollForwardForm')(state),
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        bulkRollForwardActions: bindActionCreators(bulkRollForwardActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch)
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        reduxForm({ form: "bulkRollForwardForm", enableReinitialize: true })(BulkRollForwardContainer)
    )
);
