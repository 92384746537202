import { NavigationClient } from "@azure/msal-browser";

export class CustomNavigationClient extends NavigationClient {
    constructor() {
        super();
    }

    navigateInternal(url, options) {
        const relativePath = url.replace(window.location.origin, '');
        let attempts = 0;
        const maxAttempts = 50; // 5 seconds / 100ms = 50 attempts
    
        const intervalId = setInterval(() => {
            if (window.routerContext) {
                clearInterval(intervalId);

                if (options.noHistory) {
                    window.routerContext.navigate(relativePath, { replace: true });
                } else {
                    window.routerContext.navigate(relativePath);
                }
            } else if (attempts >= maxAttempts) {
                console.error('routerContext is not defined after 5 seconds');
                clearInterval(intervalId);
            }
            attempts++;
        }, 100);
    
        return false;
    }
}