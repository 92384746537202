import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commonActions from '../../actions/commonActions';
import * as checklistActions from '../../actions/checklistActions';
import * as propertyActions from '../../actions/propertyActions';
import * as periodActions from '../../actions/periodActions';
import * as authActions from '../../actions/authActions';
import * as reitActions from '../../actions/reitActions';
import * as reportActions from '../../actions/reportActions';
import * as lookupTypeActions from "../../actions/lookupTypeActions";
import * as fileActions from '../../actions/fileActions';
import * as formValidators from '../../scripts/formValidators';
import ChecklistNavigation from '../../components/reits/ChecklistNavigation';
import EditChecklistSection from '../../components/reits/EditChecklistSection';
import PsqChecklistSummary from '../../components/reits/PsqChecklistSummary';
import ChecklistCounters from '../../components/reits/ChecklistCounters';
import { Segment, Button, Modal, Header, Icon, Grid } from 'semantic-ui-react';
import ChecklistCompleteModal from '../../components/reits/ChecklistCompleteModal';
import PsqCreateChecklistModal from '../../components/reits/PsqCreateChecklistModal';
import ChecklistPageBase from './ChecklistPageBase';
import PsqProviderSummary from '../../components/reits/PsqProviderSummary';

import * as customQuestionActions from "../../actions/customQuestionActions";
import { Button as BootstrapButton, Dropdown as BootstrapDropdown, ButtonGroup, DropdownButton } from 'react-bootstrap';
import { MotifToggleSwitch, MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem } from '@ey-xd/motif-react';
import { IconoirNavArrowDown } from '@ey-xd/motif-icon';
import ShowCommentsTable from "../../components/customChecklistTemplate/ShowCommentsTable";
import ShowAttachmentTable from '../../components/customChecklistTemplate/ShowAttachmentTable';
import ShowDiscrepanciesTable from '../../components/customChecklistTemplate/ShowDiscrepanciesTable';
import DeleteChecklistDetailGridRowModal from '../../components/reits/DeleteChecklistDetailGridRowModal';
import * as  checklistCountRequest from '../../constants/checklistNavigationTabs';
import DeleteChecklistServiceProviderModal from '../../components/reits/DeleteChecklistServiceProviderModal';
import { withRouter } from '../../common/withRouter';

/**
 * PsqChecklistRecord container component
 * @extends {ChecklistPageBase}
 */
export class PsqChecklistRecord extends ChecklistPageBase {
  /**
   * Creates a new PsqChecklistRecord
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);
    this.setActiveItem = this.setActiveItem.bind(this);
    this.state.psqProviderSummary = [];
    this.state.showAllQuestions = true;
    this.state.showUnansweredQuestions = false;
    this.state.canEdit = true;
    this.state.isSubNavigation = false;
    this.state.newQuestionIds = [];
    this.state.checklistDropdownOpen = false;
    // This represents whether to set Checklist record as current record(based on Checklist Id provided in Route Parameter)
    this.state.setChecklistRecordAsCurrentRecord = true;
    this.state.selectedAttachmentId = null;
    this.state.selectedCommentId = null;
    this.state.selectedServiceProvider = null;
    this.state.showDeleteChecklistGridRowModal = false;
    this.state.showDeleteChecklistServiceProviderModal = false;
    this.state.showDetails = true; // Will always be true as the checklist is displayed by default
    this.isEngagementAdminOrSysAdmin = this.isEngagementAdminOrSysAdmin.bind(
      this,
    );
    this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
    this.handleAddPsqSection2Row = this.handleAddPsqSection2Row.bind(this);
    this.handleRemovePsqSection2Row = this.handleRemovePsqSection2Row.bind(
      this,
    );
    this.downloadChecklist = this.downloadChecklist.bind(this);
    this.state.gridColumnState = [];
    this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.state.questionComments = [];
    this.state.attachmentDetails = [];
    this.state.discrepanciesDetail = [];
    this.state.deleteFor = null;
    this.handleGridRowDeleteById = this.handleGridRowDeleteById.bind(this);
    this.handleDeleteAttachment =
      this.handleDeleteAttachment.bind(this);
    this.toggleShowChecklistGridRowDelete = this.toggleShowChecklistGridRowDelete.bind(this);
    this.toggleShowDeleteChecklistServiceProvider = this.toggleShowDeleteChecklistServiceProvider.bind(this);
    this.handleServiceProviderDelete = this.handleServiceProviderDelete.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.handleDownloadAttachmentClick = this.handleDownloadAttachmentClick.bind(this);
  }

  /**
  * Download the checklist
  */

  downloadChecklist(downloadChecklistType) {
    const filename = `${this.props.period.reportPeriodDescription.replace(' ', '')}_${this.props.reit.reitName}_PSQ.docx`
    this.props.commonActions.clearErrorResponse();
    this.props.reportActions.downloadChecklist('reitName=' + this.props.reit.reitName + ',periodDesc=' + this.props.period.reportPeriodDescription, this.props.periodId, 'PSQ', this.props.psqActiveChecklist.checklistID, downloadChecklistType, filename);
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.propertyActions.fetchPropertiesByPeriod(this.props.periodId);
    this.props.lookupTypeActions.fetchPropertyTypes();
    this.props.checklistActions.fetchPsqChecklistById(this.props.psqChecklistId, true)
      .then(() => this.getChecklistCount()); // For performance optimization
    this.props.checklistActions.fetchLastServiceProviderQuestionId();
    this.props.checklistActions.fetchChecklistDiscrepancies(this.props.clientId, this.props.psqChecklistId);
    checklistActions
      .getReportPeriodSummary(this.props.periodId)
      .then(request => {
        return request.json();
      })
      .then(json => {
        if (json) {
          this.setState({ reportPeriodSummary: json });
        }
      });

    // Set the period if it doesn't exist
    if (!this.props.period) {
      this.props.periodActions.fetchPeriodById(this.props.periodId);
    } else {
      this.setCanEdit(this.props.period);
    }
  }

  componentWillUnmount() {
    this.props.checklistActions.clearDeletedChecklist();
    this.setState({ questionComments: [], attachmentDetails: [], discrepanciesDetail: [] });
  }

componentDidUpdate(prevProps) {
    if (this.props.psqActiveChecklist !== prevProps.psqActiveChecklist) {
 
      let checklist = this.props.psqActiveChecklist;
 
      this.setState({ checklist: checklist });
 
      const data = this.props.psqChecklists.find(x => x.id === checklist.id);
      if (data) {
        this.setState({ psqProviderSummary: data.providerSummaries });
      } else {
        this.setState({ psqProviderSummary: [] });
      }
 
      // set disabled sections
      if (
        this.props.psqActiveChecklist.content !== undefined &&
        this.props.psqActiveChecklist.content.length > 0
      ) {
        this.disableSections(checklist);
      }
 
      if (
        this.state.activeItem == null &&
        this.props.psqActiveChecklist.content !== undefined &&
        this.props.psqActiveChecklist.content.length > 0
      ) {
        for (let i = 0; i < this.props.psqActiveChecklist.content.length; i++) {
          if (
            this.state.disabledSections.find(
              x => x === this.props.psqActiveChecklist.content[i].id,
            ) === undefined
          ) {
            this.setState({
              activeItem: this.props.psqActiveChecklist.content[0],
            });
            break;
          }
        }
      }
    }
 
    // Populate previous answers on active checklist
    if (
      this.props.previousPsqChecklist !== prevProps.previousPsqChecklist &&
      this.props.psqActiveChecklist
    ) {
      this.props.checklistActions.setActiveChecklist(
        this.props.psqActiveChecklist,
      this.props.previousPsqChecklist);
    }
 
    if (
      this.props.psqActiveChecklist &&
      (!prevProps.psqActiveChecklist ||
        !('mappedPreviousAnswers' in this.props.psqActiveChecklist))
    ) {
      if (
        this.props.psqActiveChecklist !== prevProps.psqActiveChecklist &&
        prevProps.previousPsqChecklist
      ) {
        this.props.checklistActions.setActiveChecklist(
          this.props.psqActiveChecklist,
          this.props.previousPsqChecklist,
        );
      }
    }
 
    if (this.props.customQuestionnaireTemplates !== prevProps.customQuestionnaireTemplates && this.props.customQuestionnaireTemplates && prevProps.customQuestionnaireTemplates) {
      const list = Object.assign([], this.props.customQuestionnaireTemplates);
      this.setState({ customQuestionnaireTemplates: list });
    }
 
    // Refresh attachment details table post addition/deletion of attachments
    if (this.props.attachmentDetails !== prevProps.attachmentDetails) {
      if (this.props.psqActiveChecklist && this.props.psqActiveChecklist !== null && this.props.psqActiveChecklist.content.length > 0) {
        const updatedAttachmentDetails = this.props.attachmentDetails.map((attachment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.psqActiveChecklist }, attachment.questionID);
 
          return {
            ...attachment,
            questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
          };
        });
 
        this.setState({ attachmentDetails: updatedAttachmentDetails });
      }
    }
 
    // Refresh comments details table post addition/deletion of attachments
    if (this.props.questionComments !== prevProps.questionComments) {
      if (this.props.psqActiveChecklist && this.props.psqActiveChecklist !== null && this.props.psqActiveChecklist.content.length > 0) {
        const updatedCommentsDetails = this.props.questionComments.map((comment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.psqActiveChecklist }, comment.checklistSectionQuestionID);
 
          return {
            ...comment,
            questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
          };
        });
 
        this.setState({ questionComments: updatedCommentsDetails });
      }
    }

     // Set Can Edit based on period status
     if (prevProps.period !== this.props.period) {
      this.setCanEdit(this.props.period);
      }
  }

  setGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    var columnState = localStorage.getItem(localState);
    if (columnState) {
      this.setState({ gridColumnState: JSON.parse(columnState) });
    }
    else {
      this.setState({ gridColumnState: [{ colId: '-1' }] });
    }
  }

  /**
  * Reset AG-Grid column state
  * @returns {undefined}
  */
  resetGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    localStorage.removeItem(localState);
    this.setState({ gridColumnState: [] });
  }

  /**
  * Handle Grid column state chagne
  * @returns {undefined}
  */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
  }

  handleGridChanges(gridRowData) {
    let rowData = [];

    switch (this.state.activeItem) {
      case 'Comments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            commentText: x.commentText,
            commentedBy: x.commentedBy,
            dateCommented: x.dateCommented,
          }
        });
        break;
      }
      case 'Attachments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            attachedFileName: x.attachedFileName,
            uploadedBy: x.uploadedBy,
            dateUploaded: x.dateUploaded,
          }
        });
        break;
      }
      case 'Discrepancies': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            sectionName: x.sectionName,
            questionText: x.questionText,
            discrepancyMessage: x.discrepancyMessage,
          }
        });
        break;
      }
    }
    this.setState({ gridCurrentState: rowData });
  }


  handleDismissErrorMessage() {
    this.props.commonActions.clearFormErrors('psqcreatechecklistmodal');
  }

  setCanEdit(period) {
    const isEnabled = periodActions.isReportingEnabled(period);
    this.setState({ canEdit: isEnabled });
  }

  setActiveItem(e, { name }) {
    const item = this.props.psqActiveChecklist.content.find(
      x => x.title === name,
    );
    if (this.state.disabledSections.find(x => x === item.id) === undefined) {
      this.setState({ activeItem: item, isSubNavigation: false });
    }

    if (item === undefined && name) {
      this.setState({ activeItem: name, isSubNavigation: true });
    }
  }

  handleAddPsqSection2Row() {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    const psqSection2 = checklist.content.find(x => x.type === 'PsqSection2');
    let items = psqSection2.items;
    if (items != null) {
      // Get the questions of type "PsqSection2AdditionalServices"
      const additionalServicesItems = items.filter(item => item.itemType === "PsqSection2AdditionalServices");

      // Get the last Service Provider exists on checklist.
      let lastItem = additionalServicesItems[additionalServicesItems.length - 1];
      const questionSortOrder = lastItem.questionSortOrder + 1;

      // Insert Service Provider question in ChecklistSectionQuestion table
      this.props.checklistActions.insertPsqServiceProviderQuestion({
        checklistId: this.props.psqChecklistId,
        checklistSectionId: psqSection2.id,
        lastServiceProviderQuestionId: lastItem.id,
        questionSortOrder: questionSortOrder
      }, null)
        .then((addServiceProviderQuestionResponse) => {
          // Get Service Provider Question JSON with actual IDs populated from addServiceProviderQuestionResponse
          let newItem = this.getServiceQuestionJSON(addServiceProviderQuestionResponse, psqSection2.sectionSortOrder, questionSortOrder);

          // Insert this Service Provider question into checklist
          checklist.content
            .find(x => x.type === 'PsqSection2')
            .items.push(newItem);

          this.setState({ checklist: checklist });

          // Append all these new question ids into state
          this.setState({ newQuestionIds: [...this.state.newQuestionIds, ...addServiceProviderQuestionResponse.map(q => q.questionId)] });

          this.disableSections(checklist);
        });
    }
  }

  handleRemovePsqSection2Row() {
    const item = this.state.selectedServiceProvider;
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    let section = checklist.content.find(x => x.type === 'PsqSection2');
    for (let i = 1; i < section.items.length; i++) {
      if (section.items[i].id === item.id) {
        let questionIdsToRemove = [item.id];
        questionIdsToRemove = [...questionIdsToRemove, ...item.children.map(x => x.id)];

        // Remove Service Provider question and it's childs from ChecklistSectionQuestion table
        this.props.checklistActions.removePsqServiceProviderQuestion({
          checklistId: this.props.psqChecklistId,
          checklistSectionId: section.id,
          serviceProviderQuestionIdsToRemove: [item.checklistSectionQuestionID, ...item.children.map(x => x.checklistSectionQuestionID)]
        }, null)
          .then((removeServiceProviderQuestionResponse) => {
            // Remove these question ids from state
            this.setState({ newQuestionIds: this.state.newQuestionIds.filter(x => !questionIdsToRemove.includes(x)) });

            // Remove the Service Provider question from checklist
            checklist.content
              .find(x => x.type === 'PsqSection2')
              .items.splice(i, 1);

            section = this.reArrangeSortOrder(section);
            this.setState({ checklist: checklist });
            this.disableSections(checklist);
          });

        break;
      }
    }
    this.toggleShowDeleteChecklistServiceProvider();
  }

  reArrangeSortOrder(section) {
    for (let j = 0; j < section.items.length; j++) {
      const newSortOrder = j + 1;
      section.items[j].questionSortOrder = newSortOrder; // assign new question sort order
      //Prepare new question sort order to display with question text
      const sortOrder = section.sectionSortOrder + "." + newSortOrder;

      //Split question title to seprate question number and text
      const [oldQuestionSortOrder, questionText] =
        section.items[j].title.split(/\s+(.*)/);

      if (
        section.items[j].itemType == "PsqSection2AdditionalServices" &&
        sortOrder != oldQuestionSortOrder
      ) {
        //Concatenate new sort order with question text
        section.items[j].title = sortOrder + " " + questionText;
        let StartIndex = 1;
        //Rearrange child question number
        section.items[j].children.forEach((element) => {
          const [childOrder, childQuestionText] =
            element.title.split(/\s+(.*)/);
          element.title =
            sortOrder + "." + StartIndex + " " + childQuestionText;
          StartIndex = StartIndex + 1;
        });
      }
    }
    return section;
  }

  getChecklistCount() {
    this.getAttachmentDetails();
    this.getCommentsDetails();

    this.props.checklistActions
      .fetchChecklistNavCounts(
        this.props.clientId,
        this.props.psqChecklistId,
        checklistCountRequest.ChecklistCountRequest
      )
      .then((data) => {
        this.setState({ counters: data });
      });
  }

  getAttachmentDetails() {
    this.props.checklistActions.fetchAttachmentDetail(this.props.clientId,
      this.props.psqChecklistId, null)
      .then(() => {
        if (this.props.psqActiveChecklist && this.props.psqActiveChecklist !== null && this.props.psqActiveChecklist.content.length > 0) {
          const updatedAttachmentDetails = this.props.attachmentDetails.map((attachment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.psqActiveChecklist }, attachment.questionID);

            return {
              ...attachment,
              questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
            };
          });

          this.setState({ attachmentDetails: updatedAttachmentDetails });
        }
      });
  }

  getCommentsDetails() {
    this.props.checklistActions.fetchQuestionComments(this.props.clientId, this.props.psqChecklistId)
      .then(() => {
        if (this.props.psqActiveChecklist && this.props.psqActiveChecklist !== null && this.props.psqActiveChecklist.content.length > 0) {
          const updatedCommentsDetails = this.props.questionComments.map((comment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.psqActiveChecklist }, comment.checklistSectionQuestionID);

            return {
              ...comment,
              questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
            };
          });

          this.setState({ questionComments: updatedCommentsDetails });
        }
      });
  }

  handleGridRowDeleteById(id) {
    let activeTab = this.state.activeItem;
    if (this.state.activeItem === 'Attachments') {
      this.setState({ selectedAttachmentId: id });

    }
    else if (this.state.activeItem === 'Comments') {
      this.setState({ selectedCommentId: id });
    }
    else {
      activeTab = 'Attachments';
      this.setState({ selectedAttachmentId: id });
    }
    this.setState({ deleteFor: activeTab });
    this.toggleShowChecklistGridRowDelete();
  }

  handleServiceProviderDelete(item) {
    this.setState({ selectedServiceProvider: item });
    this.toggleShowDeleteChecklistServiceProvider();
  }

  toggleShowDeleteChecklistServiceProvider() {
    this.setState({
      showDeleteChecklistServiceProviderModal: !this.state.showDeleteChecklistServiceProviderModal
    });
  }

  toggleShowChecklistGridRowDelete() {
    const showToggle = !this.state.showDeleteChecklistGridRowModal;
    this.setState({
      showDeleteChecklistGridRowModal: showToggle,
    });
  }

  deleteAttachment() {
    const clientId = this.props.clientId;
    this.props.checklistActions
      .deleteAttachment(
        this.state.selectedAttachmentId,
        clientId
      )
      .then((data) => {
        this.getAttachmentDetails();

        this.props.checklistActions
          .fetchChecklistNavCounts(
            this.props.clientId,
            this.props.psqChecklistId,
            checklistCountRequest.ChecklistCountRequest
          );
        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }

  handleDownloadAttachmentClick(attachmentGuid, attachmentName) {
    return fileActions.downloadChecklistQuestionnaireAttachment(this.props.psqChecklistId, this.props.clientId, attachmentGuid, encodeURIComponent(attachmentName), null);

  }


  deleteComment() {
    const clientId = this.props.clientId;
    this.props.checklistActions
      .deleteComment(
        this.state.selectedCommentId,
        clientId
      )
      .then((data) => {
        this.getCommentsDetails();
        this.props.checklistActions
          .fetchChecklistNavCounts(
            this.props.clientId,
            this.props.psqChecklistId,
            checklistCountRequest.ChecklistCountRequest
          );

        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }

  getIsGridNoDataFound(name) {
    if ((name === "Comments"
      && this.props.questionComments
      && this.props.questionComments.length === 0)
      || (name === "Attachments"
        && this.props.attachmentDetails
        && this.props.attachmentDetails.length === 0)
      || (name === "Discrepancies"
        && this.props.discrepanciesDetail
        && this.props.discrepanciesDetail.length === 0)) {
      return true;
    }
    else { return false; }
  }


  isChecklistContentEnabled() {
    return this.props.psqActiveChecklist !== null &&
      this.props.psqActiveChecklist.checklistStateID !== undefined &&
      this.props.psqActiveChecklist.checklistStateID.toLowerCase() !== 'completed' &&
      this.state.canEdit;
  }

  getDetails() {
    const isEnabled = this.isChecklistContentEnabled();

    if (this.state.showDetails && this.state.checklist !== null) {
      return (
        <div>
          <div className="row">
            <div className="col-md-3 mb-2">
              <ChecklistNavigation
                disabledSections={this.state.disabledSections}
                checklist={this.state.checklist}
                activeItem={this.state.activeItem}
                handleSelectionChanged={this.setActiveItem}
                checklistFiles={this.props.attachmentDetails}
                isExternal={false}
                navigationCount={this.props.checklistCounter}
              />
            </div>
            <div className="col-md-9 mb-2">
              {!this.state.isSubNavigation && (
                <>
                  {this.state.checklist.content.map(section => (
                    <EditChecklistSection
                      isEnabled={isEnabled}
                      key={section.id}
                      section={section}
                      isVisible={section.title === this.state.activeItem.title}
                      handleItemAnswered={this.handleItemAnswered}
                      showAllQuestions={this.state.showAllQuestions}
                      showUnansweredQuestions={this.state.showUnansweredQuestions}
                      isExternal={false}
                      isArqc={false}
                      previousChecklistFound={
                        this.props.previousPsqChecklist &&
                        this.props.previousPsqChecklist.content
                      }
                      prePopulateAnswerOptionID={this.state.checklist.prePopulateAnswerOptionID}
                      lastServiceProviderQuestionId={this.props.lastServiceProviderQuestionId}
                      handleComments={this.handleComments}
                      handleAddPsqSection2Row={this.handleAddPsqSection2Row}
                      handleRemovePsqSection2Row={this.handleServiceProviderDelete}
                      canEdit={this.state.canEdit}
                      user={this.props.user}
                      checklistFiles={this.props.attachmentDetails}
                      questionComments={this.props.questionComments}
                      handleSaveComment={this.handleSaveComment}
                      handleDeleteComment={this.handleDeleteComment}
                      handleEditComment={this.handleEditComment}
                      handleDeleteAttachment={this.handleGridRowDeleteById}
                      handleDownloadChecklistFile={this.handleDownloadChecklistFile}
                      handleDownloadAttachmentClick={this.handleDownloadAttachmentClick}
                    />
                  ))}


                </>
              )}
              {this.state.isSubNavigation && (
                <>
                  <h5 className="sectionTitle">{this.state.activeItem}</h5>

                  {this.getIsGridNoDataFound(this.state.activeItem) &&
                    <div>No {this.state.activeItem.toLowerCase()} found for this checklist.</div>
                  }

                  {this.state.activeItem === 'Comments' &&
                    <ShowCommentsTable
                      clientId={this.props.client.id}
                      questionComments={this.state.questionComments}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleRowDelete={this.handleGridRowDeleteById}
                      canEdit={this.state.canEdit}
                    />
                  }
                  {this.state.activeItem === 'Attachments' &&
                    <ShowAttachmentTable
                      attachmentDetail={this.state.attachmentDetails}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleDeleteAttachment={this.handleGridRowDeleteById}
                      handleDownloadChecklistFile={this.handleDownloadChecklistFile}
                      canEdit={this.state.canEdit}
                    />
                  }
                  {this.state.activeItem === 'Discrepancies' &&
                    <ShowDiscrepanciesTable
                      discrepanciesDetail={this.props.discrepanciesDetail}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                    />
                  }
                </>
              )}
            </div>
          </div>
          <DeleteChecklistDetailGridRowModal
            showDeleteChecklistGridRowModal={
              this.state.showDeleteChecklistGridRowModal
            }
            toggleshowDeleteChecklistGridRowModal={
              this.toggleShowChecklistGridRowDelete
            }
            deleteRow={this.state.deleteFor === "Attachments" ? this.deleteAttachment : this.deleteComment}
            modalName={this.state.deleteFor}
          />
          <DeleteChecklistServiceProviderModal
            showDeleteChecklistServiceProviderModal={
              this.state.showDeleteChecklistServiceProviderModal
            }
            toggleShowDeleteChecklistServiceProviderModal={
              this.toggleShowDeleteChecklistServiceProvider
            }
            deleteServiceProvider={this.handleRemovePsqSection2Row}
          />
          <ChecklistCompleteModal
            handleModalAction={this.handleConfirmationAction}
            showModal={this.state.showConfirmationModal}
          />
        </div >
      );
    }

    return null;
  }

  isEngagementAdminOrSysAdmin() {
    let isEngAdminOrSysAdmin = false;
    let currentUserAuthorizations = this.props.currentUserAuthorizations;
    if (Array.isArray(currentUserAuthorizations)) {
      isEngAdminOrSysAdmin =
        authActions.isSystemAdministrator(currentUserAuthorizations) ||
        authActions.isEngagementAdministrator(currentUserAuthorizations, this.props.client.id);
    }

    return isEngAdminOrSysAdmin;
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {

    let isEngAdminOrSysAdmin = false;
    isEngAdminOrSysAdmin = this.isEngagementAdminOrSysAdmin();

    if (this.props.psqActiveChecklist === null) {
      return (
        <div className="checklist">
          <h2 data-testid="h2PageHeading">{this.getPsqQuestionnaireTitle()}</h2>
          {!this.state.canEdit && <div>Reporting Closed</div>}

          <div style={{ marginTop: '15px' }}>
            <span>
              <MotifDropdown
                open={this.state.checklistDropdownOpen}
                placement="bottom-end"
                id={'checklist-dropdown'}
                aria-labelledby={'checklist-management-trigger'}
                data-testid="btnDownloadChecklist"
                handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                trigger={
                  <MotifButton
                    disabled={!(this.props.psqActiveChecklist)}
                    type="button"
                    variant="primary-alt"
                    onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                    aria-controls={'checklist-dropdown'}
                    id={'checklist-dropdown-trigger'}
                    aria-expanded={this.state.checklistDropdownOpen}
                    aria-label="Download checklist dropdown"
                  >
                    Download Checklist <MotifIcon iconFunction={IconoirNavArrowDown} size='24' fill='none' />
                  </MotifButton>
                }
              >
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                  With Answers Only
                </MotifDropdownItem>
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                  With Answers And Comments
                </MotifDropdownItem>
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                  Without Answers or Comments
                </MotifDropdownItem>
              </MotifDropdown>
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="checklist">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <h2 data-testid="h2PageHeading">{this.getPsqQuestionnaireTitle()}</h2>
            {!this.state.canEdit && <div>Reporting Closed</div>}
            {this.props.psqActiveChecklist && (this.props.psqActiveChecklist.checklistTemplateID < 0 ? "" :
              <div>
                <div><span class="questionnaireSummary">Property Name:</span> {this.props.psqActiveChecklist.propertyName}</div>
                <div><span class="questionnaireSummary">Property Address:</span> {this.props.psqActiveChecklist.propertyFullAddress}</div>
              </div>)}
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-md-4 checklistCounters">
            <ChecklistCounters
              previousChecklistFound={
                this.props.previousPsqChecklist &&
                this.props.previousPsqChecklist.content
              }
              isExternal={false}
              checklist={this.state.checklist}
              disabledSections={this.state.disabledSections}
            />
          </div>
          <div className="col-md-8">
            <div className="d-flex ml-auto justify-content-end">
              <span className="ml-3">
                <MotifDropdown
                  open={this.state.checklistDropdownOpen}
                  placement="bottom-end"
                  id={'checklist-dropdown'}
                  aria-labelledby={'checklist-management-trigger'}
                  data-testid="btnDownloadChecklist"
                  handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                  trigger={
                    <MotifButton
                      disabled={!(this.props.psqActiveChecklist)}
                      type="button"
                      variant="primary-alt"
                      onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                      aria-controls={'checklist-dropdown'}
                      id={'checklist-dropdown-trigger'}
                      aria-expanded={this.state.checklistDropdownOpen}
                      aria-label="Download checklist dropdown"
                    >
                      Download Checklist <MotifIcon iconFunction={IconoirNavArrowDown} size='24' fill='none' />
                    </MotifButton>
                  }
                >
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                    With Answers Only
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                    With Answers And Comments
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                    Without Answers or Comments
                  </MotifDropdownItem>
                </MotifDropdown>
              </span>

              <MotifButton variant="secondary" className="ml-2" type="button" data-testid="btnReviewComplete"
                onClick={this.showConfirmationModal}
                disabled={!this.isChecklistContentEnabled()}>
                Review Complete
              </MotifButton>
              <MotifButton variant="primary" className="ml-2" type="button" data-testid="btnSave"
                onClick={this.handleSaveChecklist}
                disabled={!this.isChecklistContentEnabled()}>
                Save
              </MotifButton>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex mr-auto">
            <div className="d-flex flex-row" style={{ alignItems: 'center' }} data-testid="toggleDisplayUnansweredQuestionsOnly">
              <div className="mr-3">
                <span className="right font-weight-bold">Display Unanswered Questions Only:</span>
              </div>
              <div>
                <div className="switch-box">
                  <MotifToggleSwitch
                    aria-label="Display Unanswered Questions Only toggle"
                    name="showUnanswered"
                    id="showUnanswered"
                    onChange={this.handleShowUnansweredQuestions}
                    checked={this.state.showUnansweredQuestions}
                  />
                </div>
              </div>
            </div>

            {(this.state.showDetails &&
              this.props.psqActiveChecklist &&
              this.props.previousPsqChecklist &&
              Array.isArray(this.props.previousPsqChecklist.content) &&
              this.props.previousPsqChecklist.content.length > 0 && (
                <div className="d-flex flex-row ml-4" style={{ alignItems: 'center' }} data-testid="toggleDisplayChangedAnswers">
                  <div className="mr-3">
                    <span className="right font-weight-bold">Display Changed Answers:</span>
                  </div>
                  <div>
                    <div className="switch-box">
                      <MotifToggleSwitch
                        aria-label="Display Changed Answers toggle"
                        name="showAll"
                        id="showAll"
                        onChange={this.handleShowAllQuestions}
                        checked={!this.state.showAllQuestions}
                      />
                    </div>
                  </div>
                </div>
              )) || <div />}
          </div>
        </div>

        {this.getDetails()}
      </div>
    );
  }
}

PsqChecklistRecord.propTypes = {
  client: PropTypes.object.isRequired,
  reit: PropTypes.object.isRequired,
  periodId: PropTypes.number.isRequired,
  psqChecklists: PropTypes.array,
  psqActiveChecklist: PropTypes.object,
  previousPsqChecklist: PropTypes.object,
  checklistActions: PropTypes.object,
  reitActions: PropTypes.object,
  propertyActions: PropTypes.object,
  fileActions: PropTypes.object,
  properties: PropTypes.array,
  periodActions: PropTypes.object.isRequired,
  currentUserAuthorizations: PropTypes.array,
  deletedChecklist: PropTypes.object,
  canDelete: PropTypes.bool,
  canRevertState: PropTypes.bool,
  pendingTasks: PropTypes.number,
  reportActions: PropTypes.object,
  lookupTypes: PropTypes.object,
  user: PropTypes.string,
  questionComments: PropTypes.array,
  attachmentDetail: PropTypes.array,
  discrepanciesDetail: PropTypes.array,
};

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
             */
function mapStateToProps(state, ownProps) {
  let clientId = Number.parseInt(ownProps.router.params.clientId);
  if (isNaN(clientId)) {
    clientId = 0;
  }

  return {
    user: state.authentication.currentUser,
    clientId: Number.parseInt(ownProps.router.params.clientId),
    client: state.client,
    periodId: Number.parseInt(ownProps.router.params.periodId),
    psqChecklistId: Number.parseInt(ownProps.router.params.psqChecklistId),
    psqChecklists: state.psqChecklists,
    psqActiveChecklist: state.psqActiveChecklist,
    previousPsqChecklist: state.previousPsqChecklist,
    checklistFiles: state.checklistFiles,
    reit: state.reit,
    properties: state.properties,
    period: state.period,
    currentUserAuthorizations: state.currentUserAuthorizations,
    deletedChecklist: state.deletedChecklist,
    canDelete:
      Array.isArray(state.currentUserAuthorizations) &&
      (authActions.isSystemAdministrator(state.currentUserAuthorizations) ||
        (state.client &&
          authActions.isEngagementAdministrator(
            state.currentUserAuthorizations,
            state.client.id,
          ))) &&
      (state.period.reportPeriodStatusDescription !== 'Closed to Reporting' &&
        state.period.reportPeriodStatusDescription !== 'Complete'),
    canRevertState:
      state.period.reportPeriodStatusDescription !== 'Closed to Reporting' &&
      state.period.reportPeriodStatusDescription !== 'Complete',
    pendingTasks: state.pendingTasks,
    customQuestionnaireTemplates: state.customQuestionnaire.templates,
    lookupTypes: state.lookupTypes,
    questionComments: state.questionComments,
    attachmentDetails: state.attachmentDetail,
    discrepanciesDetail: state.discrepanciesDetail,
    checklistCounter: state.checklistsCounter,
    lastServiceProviderQuestionId: state.lastServiceProviderQuestionId
  };
}

/**
 * Binds actions to the dispatcher
* @param {Object} dispatch The action dispatcher
* @returns {Object} An object containing properties that the component can access
               */
function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    checklistActions: bindActionCreators(checklistActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
    propertyActions: bindActionCreators(propertyActions, dispatch),
    reitActions: bindActionCreators(reitActions, dispatch),
    periodActions: bindActionCreators(periodActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    customQuestionActions: bindActionCreators(customQuestionActions, dispatch),
    lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PsqChecklistRecord)
);
