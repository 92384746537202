import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_SUCCESS action and sets the last trial balance for the period in the store
 * @param {Array} [state] The last CONSOLIDATED TB currently in the store, or the initial state of the last CONSOLIDATED TB
 * @param {Object} action The action to be handled
 * @returns {Array} The CONSOLIDATED TB
 */
export default function lastConsolidatedTrialBalance(state = initialState.lastConsolidatedTrialBalance, action) {
    switch (action.type) {
    case actions.LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_SUCCESS:
        if (action.lastConsolidatedTrialBalance === undefined) {
            return null;
        }
        
        return action.lastConsolidatedTrialBalance;
    case actions.LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_FAILURE:
    case actions.UPLOAD_TB_SUCCESS:
    case actions.TB_PROCESSING_COMPLETE:
    case actions.UNLOAD_FILE_MANAGEMENT:
        return initialState.lastConsolidatedTrialBalance;
    default:
        return state;
    }
}