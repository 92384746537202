import PropTypes from "prop-types";
import React from "react";
import { reduxForm } from "redux-form";
import { MotifTextLink,MotifFormField, MotifCheckbox, MotifLabel, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifButton } from "@ey-xd/motif-react";
import { Link } from 'react-router-dom';
const UpcomingDataDeletionNoticeModal = ({
    showUpcomingDataDeletionNoticeMsg,
    toggleUpcomingDataDeletionNoticeMsg,
    upcomingDataDeletionckChecked,
    handleUpcomingDataDeletionNotice,
    handleUserPreferenceSelectedForDataDeletionNotice,
    upcomingDataDeletionNoticeData
}) => {

    const scheduledDataPurgeDate = upcomingDataDeletionNoticeData.scheduledDataPurgeDate 
        ? (new Date(upcomingDataDeletionNoticeData.scheduledDataPurgeDate))
            .toLocaleString('en-US', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }) 
        : '';
    return (
        <form>
            <MotifModal size="xl" className="data-purge-notification-modal" variant="info" show={showUpcomingDataDeletionNoticeMsg} onClose={toggleUpcomingDataDeletionNoticeMsg} data-testid="UpcomingDataDeletionNotice">
                <MotifModalHeader closeModalButton={<span></span>}>
                    REITSuite Data Management Alert
                </MotifModalHeader>
                <MotifModalBody>
                <p>Alert: In compliance with REITSuite’s data retention policy, select records are scheduled for deletion on {scheduledDataPurgeDate}. Please access the 'Data Retention Management' screen to review what records are scheduled for deletion. You can also reference the REITSuite data retention policy <Link to="/helpguide" target="_blank" rel="noopener noreferrer"><MotifTextLink  href="/helpguide" target="_blank" variant='alt'>here</MotifTextLink></Link>.</p>
                 <p> If you are a Data Steward and have yet to approve the deletion, please do so as soon as possible. Please note Tax Quality and engagement partners will be notified where the appropriate records are not approved for deletion on time.</p>
                    <br></br>
                    <MotifFormField>
                        <MotifCheckbox
                            type="checkbox"
                            data-testid="chkbxUpcomingDataDeletionAck"
                            name="upcomingDataDeletionAck"
                            id="upcomingDataDeletionAck"
                            checked={upcomingDataDeletionckChecked}
                            onChange={handleUserPreferenceSelectedForDataDeletionNotice}
                            className="mt-3"
                        >
                            <MotifLabel>I understand and do not show this message again</MotifLabel>
                        </MotifCheckbox>
                    </MotifFormField>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton variant="primary" className="right" type="button" data-testid="Confirm" 
                        onClick={() => handleUpcomingDataDeletionNotice()}>
                        Confirm
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </form >
    );
};

UpcomingDataDeletionNoticeModal.propTypes = {
    showUpcomingDataDeletionNoticeMsg: PropTypes.bool,
    upcomingDataDeletionckChecked: PropTypes.bool,
    toggleUpcomingDataDeletionNoticeMsg: PropTypes.func,
    handleUpcomingDataDeletionNotice: PropTypes.func,
    upcomingDataDeletionNoticeData: PropTypes.object
};

export default reduxForm({ form: "upcomingDataDeletionNotice", enableReinitialize: true })(
    UpcomingDataDeletionNoticeModal
);
