import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import * as formValidators from "../../scripts/formValidators";
import * as formHelpers from "../../scripts/formHelpers";
import $ from "jquery";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifErrorMessage,
  MotifFormField,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifTooltip,
  MotifIcon
} from "@ey-xd/motif-react";
import * as Constants from "../../constants/other";
import { IconoirInfoCircle } from '@ey-xd/motif-icon';
const ArqcCreateChecklistModal = ({
  handleSubmit,
  handleClientFieldChanged,
  handlePopulatePreviousAnswersChanged,
  isSendEmailEnabled,
  isPreviousAnswersPopulated,
  showModal,
  setCreateAction,
  handleCancelAction,
  error,
  handleDismissErrorMessage,
  clientEmailsRef,
  checklist,
  clientEmail,
  handleRollForwardComment,
  createChecklistCustomErrorMessage,
  isInititiallySetEmailsFrwd,
  setInititiallySetEmailsFrwd,
  externalUserVisibilityData,
}) => {
  let isReportPeriodVisible=true;

  if(externalUserVisibilityData && externalUserVisibilityData.length>0){
    isReportPeriodVisible = externalUserVisibilityData[0].isReportPeriodVisibleToExternalUsers;
  }
  const [emailInput, setEmailInput] = useState('');
  let prePopulateOptions = Constants.prePopulateOptions;
  const rollForwardQuestionComment = Constants.rollForwardQuestionComment;
  const preventSubmitOnEnter = (e) => {
    if (e.key === "Enter") {
      //Avoid preventing key press for buttons
      if (
        e.target &&
        e.target.type !== "button" &&
        e.target.type !== "submit"
      ) {
        e.preventDefault();
      }
    }
  };

  const [selectedRollForwadAns, setSelectedRollForwadAns] = useState(
    rollForwardQuestionComment[0]
  );

  const handleCreateButtonClick = (e) => {
    setCreateAction("CREATE");
  };
  const useHandleFieldChangedRollForwardQuestions = (v) => {
    handleRollForwardComment(v, rollForwardQuestionComment);

    setSelectedRollForwadAns(v);
  };

  const [selectedPopulateAns, setSelectedPopulateAns] = useState(
    prePopulateOptions[0]
  );
  const useHandleFieldChangedPreviousAnswer = (v) => {
    handlePopulatePreviousAnswersChanged(v, prePopulateOptions);

    setSelectedPopulateAns(v);
  };

  const handleCreateAndSendButtonClick = () => {
    setCreateAction("CREATEANDSEND");
  };

  const validateEmails = () => {
    let sendToFieldValue = $("#sendToField :input").val() || emailInput;

    if (sendToFieldValue !== "") {
      throw new SubmissionError({
        _error: "'" + sendToFieldValue + "' is not a valid email address.",
      });
    }
    return handleSubmit();
  };

  const handleOnInputChange = (email) => {
    if(formValidators.email(email) !== undefined){
      setEmailInput(email);
    } 
    else{
      setEmailInput("");
    }
  }

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: "Close add new ARQC modal",
    ariaLabel: "Close add new ARQC modal",
    dataTestid: "close",
  };

  useEffect(() => {     
    if(isInititiallySetEmailsFrwd && clientEmailsRef !== undefined)   {
      clientEmailsRef(null);
    }
    else if (setInititiallySetEmailsFrwd !== undefined){
      setInititiallySetEmailsFrwd();
    }
  }, [clientEmail]);

  return (
    <MotifModal
      variant="alt"
      show={showModal}
      onClose={handleCancelAction}
      data-testid="addARQCModal"
    >
      <MotifModalHeader
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <span className="ml-2">Create a new ARQC</span>
        </HeaderWrapper>
      </MotifModalHeader>

      <MotifModalBody>
        <form
          onSubmit={handleSubmit(validateEmails)}
          onKeyPress={preventSubmitOnEnter}
          id="createARQC"
        >
          {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
          <div className="field">
            <MotifFormField>
              <MotifLabel data-testid="lblPreAnsField">
                Pre-Populate Answers? / Show Reference Answers?
              </MotifLabel>
              <MotifSelect
                data-testid="divPreAnsField"
                name="populatePreviousAnswersField"
                value={Constants.defaultPrepopulateAnswer}
                onChange={useHandleFieldChangedPreviousAnswer}
              >
                {prePopulateOptions.map((co) => (
                  <MotifOption
                    className="motif-option-text-space"
                    key={co.value}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>

          <div className="field margin-top-6">
            <MotifFormField>
              <MotifLabel data-testid="lblRollForwardQuestion">
                Roll Forward Question Comments? / Attachments?
              </MotifLabel>
              <MotifSelect
                data-testid="divRollForwardComments"
                name="rollForwardQuestionCommentsField"
                value={Constants.defaultRollFowardValue}
                onChange={useHandleFieldChangedRollForwardQuestions}
              >
                {rollForwardQuestionComment.map((co) => (
                  <MotifOption
                    className="motif-option-text-space"
                    key={co.value}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>

          <div className="field margin-top-6">
            <MotifLabel data-testid="lblRollForwardQuestion">
              Send to (Multiple email addresses are supported if separated by a semi-colon)
            </MotifLabel>
            <Field
              component={formHelpers.renderReactMultiEmail}
              id="sendToField"
              name="sendToField"
              type="text"
              domRef={clientEmailsRef}
              handleChange={handleClientFieldChanged}
              emails={clientEmail}
              removeEYEmail={true}
              validate={[
                formValidators.multipleEmails,
                formValidators.maxLength255,
              ]}
              handleOnInputChange={handleOnInputChange}
            />
          </div>
          {createChecklistCustomErrorMessage &&
            createChecklistCustomErrorMessage.length > 0 && (
              <MotifErrorMessage>
                {createChecklistCustomErrorMessage}
              </MotifErrorMessage>
            )}
        </form>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          variant="secondary"
          className="right"
          type="submit"
          onClick={handleCancelAction}
        >
          Cancel
        </MotifButton>
        <MotifButton
          variant="primary-alt"
          className="right"
          type="submit"
          form="createARQC"
          onClick={handleCreateAndSendButtonClick}
          disabled={!isSendEmailEnabled || !isReportPeriodVisible}
        >
        {!isReportPeriodVisible &&   
        <MotifTooltip
            trigger={
                <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' className="mt-1" />
            }>
                  <div>Due to EY's independence policy, the create and send checklist is disabled<br/>
                        as the client no longer has access to this report period. <br />
                        Please email 'reitsuite@ey.com' for additional support. 
                  </div>
        </MotifTooltip> }
        Create &amp; Send
        </MotifButton>
        <MotifButton
          variant="primary"
          className="right"
          type="submit"
          form="createARQC"
          onClick={handleCreateButtonClick}
        >
          Create Only
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

ArqcCreateChecklistModal.propTypes = {
  showModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClientFieldChanged: PropTypes.func,
  handlePopulatePreviousAnswersChanged: PropTypes.func,
  handleEyFieldChanged: PropTypes.func,
  isSendEmailEnabled: PropTypes.bool,
  isPreviousAnswersPopulated: PropTypes.bool,
  setCreateAction: PropTypes.func,
  handleCancelAction: PropTypes.func,
  error: PropTypes.string,
  handleDismissErrorMessage: PropTypes.func,
  clientEmailsRef: PropTypes.func,
  checklist: PropTypes.object,
  clientEmail: PropTypes.array,
  handleRollForwardComment: PropTypes.func,
  externalUserVisibilityData: PropTypes.array
};

export default reduxForm({
  form: "arqccreatechecklistmodal",
  enableReinitialize: true,
})(ArqcCreateChecklistModal);
