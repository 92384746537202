export const formatEin = (value) => {
    if (!value) {
        return '';
    }

    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`;
};

export const formatPhone = (value) => {
    if (!value) {
        return '';
    }

    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length <= 7) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const formatCurrency = (value) => {
    let result = formatNumberAllowNegativesAndDecimal(value);

    if (!result) {
        return '';
    }

    return '$' + result;
};

export const formatPercent = (value) => {
    let result = formatNumberAllowNegativesAndDecimal(value);

    if (!result) {
        return '';
    }

    return result + '%';
};

export const formatNumberAllowNegativesAndDecimal = (value) => {
    if (!value) {
        return '';
    }

    value = value.toString();

    const onlyNums = value.replace(/[^\-\d\.]/g, '');

    if (onlyNums === "-") {
        return "-";
    }

    let n = Number.parseFloat(onlyNums);

    let separator = ",",
        sign = n < 0 ? "-" : "",
        absoluteValue = String(parseInt(n = Math.abs(Number(n) || 0))),
        j = (j = absoluteValue.length) > 3 ? j % 3 : 0;

    let matches = onlyNums.match(/(\.\d*$)/);
    let numEnd = '';

    if (Array.isArray(matches) && matches.length >= 1) {
        numEnd = matches[0];
    }

    let numBegin = sign + (j ? absoluteValue.substr(0, j) + separator : "") + absoluteValue.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + separator);

    return `${numBegin}${numEnd}`;
};

export const formatNumberAllowNegatives = (value) => {
    if (!value) {
        return '';
    }

    value = value.toString();

    const onlyNums = value.replace(/[^\-\d]/g, '');

    if (onlyNums === "-") {
        return "-";
    }

    let n = Number.parseInt(onlyNums);

    let separator = ",",
        sign = n < 0 ? "-" : "",
        absoluteValue = String(parseInt(n = Math.abs(Number(n) || 0))),
        j = (j = absoluteValue.length) > 3 ? j % 3 : 0;

    return sign + (j ? absoluteValue.substr(0, j) + separator : "") + absoluteValue.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + separator);
};

export const formatNumberAllowDecimal = (value) => {
    if (!value) {
        return '';
    }

    value = value.toString();

    const onlyNums = value.replace(/[^\d\.]/g, '');

    let n = Number.parseFloat(onlyNums);

    let separator = ",",
        absoluteValue = String(parseInt(n = Math.abs(Number(n) || 0))),
        j = (j = absoluteValue.length) > 3 ? j % 3 : 0;

    let matches = onlyNums.match(/(\.\d*$)/);
    let numEnd = '';

    if (Array.isArray(matches) && matches.length >= 1) {
        numEnd = matches[0];
    }

    let numBegin = (j ? absoluteValue.substr(0, j) + separator : "") + absoluteValue.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + separator);
    return `${numBegin}${numEnd}`;
};

export const formatNumber = (value) => {
    if (!value) {
        return '';
    }

    value = value.toString();

    const onlyNums = value.replace(/[^\d]/g, '');

    let n = Number.parseInt(onlyNums);

    let separator = ",",
        absoluteValue = String(parseInt(n = Math.abs(Number(n) || 0))),
        j = (j = absoluteValue.length) > 3 ? j % 3 : 0;

    return (j ? absoluteValue.substr(0, j) + separator : "") + absoluteValue.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + separator);
};

export const formatDate = (value) => {
    if (!value) {
        return '';
    }

    value = value.toString();
    let lastSlash = value.lastIndexOf('/');

    if (value.length > 10 && lastSlash === -1) {
        try {
            let d = new Date(value);
            if (isNaN(d.getTime())) {
                return value;
            }
            return d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
        }
        catch (e) {
            return value;
        }

    }

    let onlyDigitsAndSlashes = value.replace(/[^\d\/]/g, '');
    lastSlash = onlyDigitsAndSlashes.lastIndexOf('/');

    if (lastSlash >= 3 && onlyDigitsAndSlashes.length > (lastSlash + 4)) {
        onlyDigitsAndSlashes = onlyDigitsAndSlashes.substring(0, lastSlash + 5);
    }

    return onlyDigitsAndSlashes;
};

export const formatNumberAllowNegativesAndLessThanZero = (value) => {
    if (!value) {
        return '';
    }

    value = value.toString();

    const onlyNums = value.replace(/[^\-\d\.]/g, '');

    if (onlyNums === "-" || value.startsWith("--")) {
        return "-";
    }

    let n = Number.parseFloat(onlyNums);

    let separator = ",",
        sign = (onlyNums.toString().startsWith("-0") || onlyNums.toString().startsWith("-")) && !isNaN(n)  ? "-" : "",
        absoluteValue = String(parseInt(n = Math.abs(Number(n) || 0))),
        j = (j = absoluteValue.length) > 3 ? j % 3 : 0;

    let matches = onlyNums.match(/(\.\d*$)/);
    let numEnd = '';

    if (Array.isArray(matches) && matches.length >= 1) {
        numEnd = matches[0];
    }

    let numBegin = sign + (j ? absoluteValue.substr(0, j) + separator : "") + absoluteValue.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + separator);

    return `${numBegin}${numEnd}`;
};