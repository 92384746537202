import React from 'react';
import PropTypes from 'prop-types';
import PropertyItsiTable from "./PropertyItsiTable";

const PropertyItsiTestTable = ({ itsi }) => {
    return (
        <table className="table detail-test-table mb-0">
            <thead>
                <tr>
                    <th>
                        {(itsi && itsi.test) || null}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <PropertyItsiTable
                            itsi={itsi}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

PropertyItsiTestTable.propTypes = {
    itsi: PropTypes.object
};

export default PropertyItsiTestTable;