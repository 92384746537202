import PropTypes from "prop-types";
import React from "react"
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter  } from '@ey-xd/motif-react';

const OpenPeriodModal = ({ showOpenPeriodModal, handleOpenPeriodClick, handleCancel, period, pendingTasks }) => {
    let openQuestion = `Open ${period.reportPeriodDescription} for testing?`;
    let openStatement = "Reporting and file services will remain accessible.";

    switch (period.reportPeriodStatusDescription) {
    case "Closed to Testing":
        openQuestion = `Reopen ${period.reportPeriodDescription} for testing?`;
        openStatement = `Reopening ${period.reportPeriodDescription} for testing will make all functions available again and set the period status to "In progress."`;
        break;
    case "Closed to Reporting":
        openQuestion = `Reopen ${period.reportPeriodDescription} for reporting?`;
        openStatement = `Reopening ${period.reportPeriodDescription} for reporting will make reporting functions available again and set the period status to "Closed to Testing."  File Manager Services will also remain available.`;
        break;
    case "Complete":
        openQuestion = `Reopen ${period.reportPeriodDescription}?`;
        openStatement = `Reopening ${period.reportPeriodDescription} will make File Manager Services available again and set the period status to "Closed to Reporting."`;
        break;
    }

    const onYesClick = () => {
        handleOpenPeriodClick(period.reportPeriodID, period.reportPeriodStatusDescription);
    };

    return (
        <div>
            {pendingTasks === 0 &&
             <MotifModal show={showOpenPeriodModal} onClose={handleCancel} variant="alt">
             <MotifModalHeader>Reopen Period</MotifModalHeader>
             <MotifModalBody>
             <span className="motif-h5" >{openQuestion}</span>
                    <blockquote className="mb-0 mt-3">
                    <span>
                        {openStatement}
                    </span>
                    <br/>
                    </blockquote>
             </MotifModalBody>
             <MotifModalFooter>
                    <MotifButton variant="secondary" type="button"
                        onClick={handleCancel}>
                        Cancel
                    </MotifButton>
                    <MotifButton variant="primary" type="button"
                        id="openButton" onClick={onYesClick}
                        data-key={period.reportPeriodID}>
                        Yes
                    </MotifButton>
             </MotifModalFooter>
         </MotifModal> 
            || null }
        </div>
    );
};

OpenPeriodModal.propTypes = {
    handleOpenPeriodClick: PropTypes.func,
    showOpenPeriodModal: PropTypes.bool,
    handleCancel: PropTypes.func,
    period: PropTypes.object,
    pendingTasks: PropTypes.number
};

export default OpenPeriodModal;