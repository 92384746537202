import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/periodActions";
import OpenPeriodModal from "../../components/reits/OpenPeriodModal";

/**
 * The container that renders OpenPeriodModal
 */
export class OpenPeriodContainer extends React.Component {
    /**
     * Creates a new OpenPeriodContainer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        
        this.handleOnCancelAction = this.handleOnCancelAction.bind(this);
        this.handleOpenPeriodClick = this.handleOpenPeriodClick.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        if (this.props.periodId > 0) {
            this.props.actions.fetchPeriodById(this.props.periodId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.periodId !== this.props.periodId || prevProps.status !== this.props.status) {
            this.props.actions.fetchPeriodById(this.props.periodId);
        }
    }

    handleOnCancelAction() {
        this.setState({ isAcknowledged: false}, this.props.toggleShowOpenPeriodModal());
    }

    handleOpenPeriodClick(periodId, currentStatus) {
        this.props.handleOpenPeriod(periodId, currentStatus);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <OpenPeriodModal showOpenPeriodModal={this.props.showOpenPeriodModal} handleOpenPeriodClick={this.handleOpenPeriodClick} handleCancel={this.handleOnCancelAction} period={this.props.period}  periodId={this.props.periodId} pendingTasks={this.props.pendingTasks} />
        );
    }
}

OpenPeriodContainer.propTypes = {
    periodId: PropTypes.number.isRequired,
    handleOpenPeriod: PropTypes.func,
    period: PropTypes.object,
    showOpenPeriodModal: PropTypes.bool,
    toggleShowOpenPeriodModal: PropTypes.func,
    pendingTasks: PropTypes.number,
    status: PropTypes.string,
    actions: PropTypes.object.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: ownProps.periodId,
        handleOpenPeriod: ownProps.handleOpenPeriod,
        period: state.period,
        showOpenPeriodModal: ownProps.showOpenPeriodModal,
        toggleShowOpenPeriodModal: ownProps.toggleShowOpenPeriodModal,
        pendingTasks: state.pendingTasks
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(OpenPeriodContainer);