import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as actions from "../../actions/servicesActions";
import TabbedNavigation from '../../components/shared/TabbedNavigation';


/**
 * Container component used for uploading trial balance files
 */
export class TabbedNavigationContainer extends React.Component {
    /**
     * Creates a new TrialBalanceUploadPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (<TabbedNavigation
            clientId={this.props.clientId}
            clientIsAudit={this.props.clientIsAudit}
            reitId={this.props.reitId}
            periodId={this.props.periodId}
            currentLocation={this.props.currentLocation}
            navigationServices={this.props.navigationServices}
            context={this.context}
        />);
    }

}

TabbedNavigationContainer.propTypes = {
    clientId: PropTypes.number.isRequired,
    reitId: PropTypes.number.isRequired,
    periodId: PropTypes.number.isRequired,
    currentLocation: PropTypes.string,
    navigationServices: PropTypes.array,
    clientIsAuditOnly: PropTypes.bool,
    actions: PropTypes.object.isRequired
};

TabbedNavigationContainer.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let result = {
        periodId: Number.parseInt(ownProps.periodId),
        clientId: Number.parseInt(ownProps.clientId),
        reitId: Number.parseInt(ownProps.reitId),
        currentLocation: ownProps.currentLocation,
        navigationServices: state.navigationServices,
        clientIsAudit: state.client && state.client.isAudit
    };
    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabbedNavigationContainer);