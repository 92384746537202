import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_TRSES_SUCCESS action and sets an array of REITs in the store
 * @param {Array} [state] The REITs currently in the store, or the initial state of REITs
 * @param {Object} action The action to be handled
 * @returns {Array} The REITs
 */
export default function taxableReitSubsidiaries(state = initialState.taxableReitSubsidiaries, action) {
    switch (action.type) {
        case actions.LOAD_TRSES_SUCCESS:
            return action.trs;
        case actions.UNLOAD_TESTS:
            return initialState.taxableReitSubsidiaries;
        default:
            return state;
    }
}