import React, { Component } from 'react';
import DataStewardRoleManagementHeader from '../../components/dataStewardRoleManagement/dataStewardRoleManagementHeader';
import { reduxForm } from "redux-form";
import { withRouter } from "../../common/withRouter";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import DataStewardList from '../../components/dataStewardRoleManagement/dataStewardList';
import { bindActionCreators } from "redux";
import { SubmissionError, reset, getFormValues } from "redux-form";
import DeleteModal from "../../components/shared/DeleteModal";
import * as authorizationDomainValidator from "../../scripts/authorizationDomainValidator";
import * as commonActions from "../../actions/commonActions";
import * as authActions from "../../actions/authActions";
import * as dataPurgeActions from "../../actions/dataPurgeActions";
import * as clientActions from "../../actions/clientActions";
import { store } from "../../store/configureStore";
import {
  MotifToast
} from '@ey-xd/motif-react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import DataStewardAssignmentRequiredModal from '../../components/dataStewardRoleManagement/dataStewardAssignmentRequiredModal';
import * as Constants from '../../constants/other';

export class DataStewardRoleManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientdata: [],
      gridCurrentState: [],
      showAddClientAuthModal: false,
      showEditClientAuthModal: false,
      auth: { "userId": null, "resource": authActions.resource.Client, "role": "2", "resourceId": this.props.clientId, "id": null, receiveDailyNewsletter: false, receiveWeeklyNewsletter: false, receiveRealTimeNotification: false, receiveRealTimeNotificationForNewPsqArqcSent: false, isDataSteward: false },
      confirmAction: null,
      addAuthMessageText: null,
      currentUserId: this.props.currentUserId,
      currentUserIsSysAdmin: this.props.currentUserIsSysAdmin,
      allowedDomains: "",
      myEyUser: {},
      receiveDailyNewsletter: false,
      receiveWeeklyNewsletter: false,
      receiveRealTimeNotification: false,
      receiveRealTimeNotificationForNewPsqArqcSent: false,
      isAuthorizedUserSearchBoxVisible: false,
      isDataSteward: false,
      showDSError: false,
      showDSErrorMessage: false,
      isAudit: false,
      dataPurgeConfigurationData: [],
      showMinimumDataStewardWarningModal: false,
      deletedUserID: null,
      dataStewardValue: false,
    };

    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.toggleShowEditClientAuthModal = this.toggleShowEditClientAuthModal.bind(this);
    this.handleEditClientAuthModalAction = this.handleEditClientAuthModalAction.bind(this);
    this.handleEditClientAuthModalCancelAction = this.handleEditClientAuthModalCancelAction.bind(this);
    this.handleDailyNewsletter = this.handleDailyNewsletter.bind(this);
    this.handleWeeklyNewsletter = this.handleWeeklyNewsletter.bind(this);
    this.handleRealTimeNotification = this.handleRealTimeNotification.bind(this);
    this.handleInternalReviewNotification = this.handleInternalReviewNotification.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.toggleShowAddClientAuthModal = this.toggleShowAddClientAuthModal.bind(this);
    this.handleNewButtonClick = this.handleNewButtonClick.bind(this);
    this.handleAddClientAuthModalCancelAction = this.handleAddClientAuthModalCancelAction.bind(this);
    this.handleAddClientAuthModalValidateAction = this.handleAddClientAuthModalValidateAction.bind(this);
    this.validateDomainWithRole = this.validateDomainWithRole.bind(this);
    this.handleOnRoleTypeChange = this.handleOnRoleTypeChange.bind(this);
    this.handleAddClientAuthModalAction = this.handleAddClientAuthModalAction.bind(this);
    this.handleDataSteward = this.handleDataSteward.bind(this);
    this.handleDataStewardCheckboxDisplay = this.handleDataStewardCheckboxDisplay.bind(this);
    this.handleDataStewardsForClientCount = this.handleDataStewardsForClientCount.bind(this);
    this.toggleShowMinimumDataStewardWarningModal = this.toggleShowMinimumDataStewardWarningModal.bind(this);
    this.handleGoToClientOverview = this.handleGoToClientOverview.bind(this);
    this.toastSystem = React.createRef();
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.props.clientActions.loadClient(this.props.clientId);
      this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);
      this.props.dataPurgeActions.fetchDataPurgeConfigurationData();
      this.setState({ clientdata: this.props.authorizations });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.authorizations !== prevProps.authorizations && this.props.authorizations && prevProps.authorizations) {
      if (this.props.clientId) {
        const list = Object.assign([], this.props.authorizations);
        this.setState({ clientdata: list },
        );
      }
    }
    if (prevState.numberOfDataStewards !== this.state.numberOfDataStewards) {
      if (this.state.numberOfDataStewards >= this.dataPurgeMaximumDSValue) {
        this.setState({ dataStewardCheckboxDisplay: true, showDSError: true });
      }
    }

    if (this.props.dataPurgeConfigurationData != prevProps.dataPurgeConfigurationData) {
      this.getDataPurgeMaximumDSValue();
    }
    if (this.props.clientId !== prevProps.clientId) {
      this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);
      this.props.dataPurgeActions.fetchDataPurgeConfigurationData();
      this.setFilter();
    }
    if (this.props.client !== prevProps.client) {
      if (this.props.client !== null) {
        this.setState({ isAudit: this.props.client.isAudit });
        this.reloadAdminAuths(true);
        this.props.dataPurgeActions.fetchDataPurgeConfigurationData();
      }
    }
  }
  /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
  render() {
    const { clientId } = this.props;
    return (
      <React.Fragment>
        <DataStewardRoleManagementHeader
          clientId={clientId}
        />
        <DataStewardList
          clientId={clientId}
          gridCurrentState={this.state.gridCurrentState}
          auths={this.state.clientdata}
          auth={this.state.auth}
          handleGridChanges={this.handleGridChanges}
          handleEditButtonClick={this.handleEditButtonClick}
          showEditClientAuthModal={this.state.showEditClientAuthModal}
          handleEditClientAuthModalAction={this.handleEditClientAuthModalAction}
          toggleShowEditClientAuthModal={this.handleEditClientAuthModalCancelAction}
          isAuthorizedUserSearchBoxVisible={this.state.isAuthorizedUserSearchBoxVisible}
          clientAuthFormValues={this.props.clientAuthFormValues}
          handleDailyNewsletter={this.handleDailyNewsletter}
          receiveDailyNewsletter={this.state && this.state.receiveDailyNewsletter || false}
          handleWeeklyNewsletter={this.handleWeeklyNewsletter}
          receiveWeeklyNewsletter={this.state && this.state.receiveWeeklyNewsletter || false}
          receiveRealTimeNotification={this.state && this.state.receiveRealTimeNotification || false}
          receiveRealTimeNotificationForNewPsqArqcSent={this.state && this.state.receiveRealTimeNotificationForNewPsqArqcSent || false}
          isDataSteward={this.state && this.state.isDataSteward || false}
          handleRealTimeNotification={this.handleRealTimeNotification}
          handleInternalReviewNotification={this.handleInternalReviewNotification}
          handleDeleteButtonClick={this.handleDeleteButtonClick}
          toggleShowAddClientAuthModal={this.handleAddClientAuthModalCancelAction}
          handleNewButtonClick={this.handleNewButtonClick}
          showAddClientAuthModal={this.state.showAddClientAuthModal}
          handleAddClientAuthModalValidateAction={this.handleAddClientAuthModalValidateAction}
          validateDomainWithRole={this.validateDomainWithRole}
          validatingUser={this.state.validatingUser}
          myEyError={this.state.myEyError}
          handleOnRoleTypeChange={this.handleOnRoleTypeChange}
          handleAddClientAuthModalAction={this.handleAddClientAuthModalAction}
          addAuthMessageText={this.state.addAuthMessageText}
          handleDataSteward={this.handleDataSteward}
          isEngagementAdministrator={authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId)}
          dataStewardCheckboxDisplay={this.state.dataStewardCheckboxDisplay}
          dataStewardValue={this.state && this.state.dataStewardValue || false}
          showDSError={this.state.showDSError}
          handleGoToClientOverview={this.handleGoToClientOverview}
          buttonVarient="primary"
        />

        <DeleteModal
          showDeleteModal={this.state.confirmAction !== null}
          confirmationMessage="Are you sure?" size="sm"
          handleDelete={this.onConfirmDelete}
          toggleshowDeleteModal={this.handleCancelDelete}
          closeTitle="close authorized user delete modal"
          headerTitle="Delete Authorized User"
        />

        <DataStewardAssignmentRequiredModal
          showEngagementAdminForDataStewardAssignmentModal={this.state.showMinimumDataStewardWarningModal}
          toggleShowEngagementAdminForDataStewardAssignmentModal={this.toggleShowMinimumDataStewardWarningModal}
          dataStewardAssignmentWarningHeader={Constants.dataStewardDeletionWarningHeader}
          dataStewardAssignmentWarningBody={Constants.dataStewardDeletionWarningBody}
        />
        <ToastContainer
          ref={this.toastSystem}
          position="bottom-right"
          closeOnClick={false}
          pauseOnHover={false}
          draggable={false}
          closeButton={false}
          hideProgressBar={true}
          transition={Slide}

        />
      </React.Fragment>
    );
  }
  handleGoToClientOverview(clientId) {

    clientActions.fetchClient(clientId).then((client) => {

      if (client) {
        if (client.isLockedForNotHavingDataSteward !== false) {
          this.setState({
            showMinimumDataStewardWarningModal: true,
          });

          return true;
        }
        else {
          this.props.router.navigate(`/client/${clientId}`);
        }
      }
    });

  }

  handleAuthStatusNotificationClose(authStatusNotification) {
    if (authStatusNotification && toast.isActive(authStatusNotification)) {
      toast.dismiss(authStatusNotification);
    }
  }

  toggleShowAddClientAuthModal() {
    if (!this.state.showAddClientAuthModal) {
      this.setState({
        auth: {
          "userId": null,
          "resource": authActions.resource.Client,
          "role": "2",
          "resourceId": this.props.clientId,
          "id": null,
          "myEyError": null,
          receiveDailyNewsletter: false,
          receiveWeeklyNewsletter: false,
          receiveRealTimeNotification: false,
          receiveRealTimeNotificationForNewPsqArqcSent: false,
          isDataSteward: false
        },
        addAuthMessageText: null,
        myEyUser: {},
        receiveDailyNewsletter: false,
        receiveWeeklyNewsletter: false,
        receiveRealTimeNotification: true,
        receiveRealTimeNotificationForNewPsqArqcSent: false,
        isDataSteward: false,
        myEyError: null
      },
        this.setState({ showAddClientAuthModal: !this.state.showAddClientAuthModal }));
    }
    else {
      this.setState({ showAddClientAuthModal: !this.state.showAddClientAuthModal }, () => store.dispatch(reset('clientAuth')));
    }
  }

  handleAddClientAuthModalAction(values) {
    if (!values || !values.userId || values.userId.trim() === "") {
      this.setState({ addAuthMessageText: "Required" });
      return;
    }

    for (let auth of this.props.authorizations) {
      if (values.userId.toLowerCase() === auth.userId.toLowerCase()) {
        this.setState({ addAuthMessageText: `'${values.userId}' is already an authorized user.` });
        return;
      }
    }

    let authValues = Object.assign({}, values);
    authValues.receiveDailyNewsletter = this.state.receiveDailyNewsletter;
    authValues.receiveWeeklyNewsletter = this.state.receiveWeeklyNewsletter;
    authValues.receiveRealTimeNotification = this.state.receiveRealTimeNotification;
    authValues.receiveRealTimeNotificationForNewPsqArqcSent = this.state.receiveRealTimeNotificationForNewPsqArqcSent;
    authValues.isDataSteward = this.state.isDataSteward;
    // Populate companyName using companyId if needed
    // If there were multiple companies get the name by id selected, otherwise, use company_name
    if (values.role === '4') {
      // If missing first/last name, then exit function
      if (!values || !values.first_name || !values.first_name.trim() === "" || !values.last_name || !values.last_name.trim() === "") {
        return;
      }

      authValues = Object.assign(
        {},
        authValues,
        {
          companyName: values.companies && values.companies.length > 0 ?
            values.companies.find(x => x.id == values.companyId).name : values.company_name,
          firstName: values.first_name,
          lastName: values.last_name,
          guid: this.state.myEyUser ? this.state.myEyUser.guid : null

        }
      );

      if (authValues.companies) {
        delete authValues.companies;
      }
    }
    
    this.props.commonActions.beginTask();
    this.props.authActions.createAuthorization(authValues).then(() => {

      this.reloadAdminAuths(true);
      this.setState({
        auth: {
          "userId": null,
          "resource": authActions.resource.Client,
          "role": "2",
          "resourceId": this.props.clientId,
          "id": null,
          receiveDailyNewsletter: false,
          receiveWeeklyNewsletter: false,
          receiveRealTimeNotification: false,
          receiveRealTimeNotificationForNewPsqArqcSent: false,
          isDataSteward: false
        },
      });
      this.toggleShowAddClientAuthModal();
      this.props.commonActions.endTask();

      // If the user is a data steward, then navigate to the client settings page
      if(authValues.isDataSteward) {
        this.props.router.navigate(`/client/${this.props.clientId}/edit`);
      }
    }).catch((error) => {
      this.toggleShowAddClientAuthModal();
      this.reloadAdminAuths(true);
      var authStatusNotification = toast(
        (
          <MotifToast position="right"
            variant="error"
            onClose={() => this.handleAuthStatusNotificationClose(authStatusNotification)}
          >
            <div>
              <div className="row"><div className="ml-3 mr-2">
                {error.message}
              </div></div>
            </div>
          </MotifToast>
        )
        , {
          autoClose: 5000,
        });
    })
    this.props.commonActions.endTask();
  }

  getDataPurgeMaximumDSValue() {
      const dataPurgeConfig = this.props.dataPurgeConfigurationData && this.props.dataPurgeConfigurationData.find(item => item.dataPurgeConfigurationID === 4);
      return this.dataPurgeMaximumDSValue = dataPurgeConfig ? dataPurgeConfig.dataPurgeConfigurationValue : null;
  }

  handleDataStewardCheckboxDisplay(numberOfDataStewards) {
    if (numberOfDataStewards >= this.dataPurgeMaximumDSValue) {
      this.setState({ dataStewardCheckboxDisplay: true });
    }
    else {
      this.setState({ dataStewardCheckboxDisplay: false });
    }
  }

  handleDataStewardsForClientCount() {
    const numberOfDataStewards = this.props.authorizations && this.props.authorizations.filter(x => x.resourceId === this.props.clientId.toString() && x.resource === 2 && x.isDataSteward === true).length;
    this.setState({
      numberOfDataStewards: numberOfDataStewards
    });
    this.handleDataStewardCheckboxDisplay(numberOfDataStewards);

  }


  validateDomainWithRole(value, allValues) {
    return authorizationDomainValidator.allowedDomain(value, this.state.allowedDomains, allValues);
  }

  toggleShowEditClientAuthModal() {
    this.setState({ showEditClientAuthModal: !this.state.showEditClientAuthModal });
  }

  handleAddClientAuthModalCancelAction() {
    this.props.commonActions.clearFormErrors("clientAuth");
    this.setState({
      auth: {
        "userId": null,
        "resource": authActions.resource.Client,
        "role": "2",
        "resourceId": this.props.clientId,
        "id": null,
        receiveDailyNewsletter: false,
        receiveWeeklyNewsletter: false
      },
      addAuthMessageText: null,
      myEyUser: {}
    }, () => this.toggleShowAddClientAuthModal());

  }

  handleOnRoleTypeChange(e) {
    if (e && e.target && e.target.value == "4") {
      this.setState({ receiveRealTimeNotification: false });
      this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: true });
    }
    else {
      this.setState({ receiveRealTimeNotification: true });
      this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: false });
    }
  }

  handleAddClientAuthModalValidateAction(valid) {
    if (valid) {
      // Check if user is already authorized for this Client
      for (let auth of this.props.authorizations) {
        if (this.props.clientAuthFormValues.userId.toLowerCase() === auth.userId.toLowerCase()) {
          this.setState({ addAuthMessageText: `'${this.props.clientAuthFormValues.userId}' is already an authorized user.` });
          return;
        }
      }

      this.setState({ myEyUser: {}, validatingUser: true, myEyError: null, addAuthMessageText: null }, () => {
        authActions.getMyEyUserByEmail(this.props.clientAuthFormValues.userId).then((myEyUser) => {
          // If the domain_status is INACTIVE, we will not be able to add this email domain to MyEY
          if (myEyUser.domain_status === 'INACTIVE') {
            this.setState({
              validatingUser: false,
              myEyError: 'Unable to verify the email domain of the user in My EY. Please contact system admin for assistance.'
            });
          } else {
            this.setState({
              validatingUser: false,
              myEyUser: myEyUser,
              auth: Object.assign({}, this.state.auth, {
                userId: myEyUser.email,
                role: '4',
                first_name: myEyUser.first_name,
                last_name: myEyUser.last_name,
                company_name: myEyUser.company_name,
                companies: myEyUser.companies,
                companyId: myEyUser.companies && myEyUser.companies[0] ? myEyUser.companies[0].id : null,
                applications: myEyUser.applications,
                status: myEyUser.status
              })
            });
          }
        }).catch((err) => {
          this.setState({
            validatingUser: false,
            myEyError: 'Error validating My EY User.'
          })
        });
      });
    }
  }

  handleEditClientAuthModalAction(values) {
    const dataStewardUserID = this.props.authorizations && this.props.authorizations.find(x => x.isDataSteward)?.userId || null;
    if (this.props.authorizations && this.props.authorizations.filter(x => x.isDataSteward).length === 1) {
      if ((!this.state.isDataSteward || values.role !== "1") && dataStewardUserID === this.state.auth.userId) {
        this.toggleShowEditClientAuthModal();
        this.toggleShowMinimumDataStewardWarningModal();
        this.setState({
          auth: {
            "userId": null,
            "resource": authActions.resource.Client,
            "role": "2",
            "resourceId": this.props.clientId,
            "id": null,
            receiveDailyNewsletter: false,
            receiveWeeklyNewsletter: false,
            receiveRealTimeNotificationForNewPsqArqcSent: false,
            isDataSteward: true
          }
        });
        return;
      }
    }
    const updatedValues = {
      ...values,
      receiveDailyNewsletter: this.state.receiveDailyNewsletter,
      receiveWeeklyNewsletter: this.state.receiveWeeklyNewsletter,
      receiveRealTimeNotification: this.state.receiveRealTimeNotification,
      receiveRealTimeNotificationForNewPsqArqcSent: this.state.receiveRealTimeNotificationForNewPsqArqcSent,
      isDataSteward: values.role === "1" ? this.state.isDataSteward : false,
    };
    
    this.setState({ auth: updatedValues }, () => {
      this.props.authActions.updateAuthorization(this.state.auth).then(() => {
        this.reloadAdminAuths(true);
        this.setState({
          auth: {
            "userId": null,
            "resource": authActions.resource.Client,
            "role": "2",
            "resourceId": this.props.clientId,
            "id": null,
            receiveDailyNewsletter: false,
            receiveWeeklyNewsletter: false,
            receiveRealTimeNotificationForNewPsqArqcSent: false,
            isDataSteward: false
          }
        });
        this.toggleShowEditClientAuthModal();
        // If the user is a data steward, then navigate to the client settings page
        if(updatedValues.isDataSteward) {          
          this.props.router.navigate(`/client/${this.props.clientId}/edit`);
        }

      }).catch((error) => {
        this.toggleShowEditClientAuthModal();
        this.reloadAdminAuths(true);
        var authStatusNotification = toast(
          (
            <MotifToast position="right"
              variant="error"
              onClose={() => this.handleAuthStatusNotificationClose(authStatusNotification)}
            >
              <div>
                <div className="row"><div className="ml-3 mr-2">
                  {error.message}
                </div></div>
              </div>
            </MotifToast>
          )
          , {
            autoClose: 5000,
          });
      });
    });
  }

  handleEditClientAuthModalCancelAction() {
    this.props.commonActions.clearFormErrors("clientAuth");
    this.setState({
        auth: {
            "userId": null,
            "resource": authActions.resource.Client,
            "role": "2",
            "resourceId": this.props.clientId,
            "id": null,
            receiveDailyNewsletter: false,
            receiveWeeklyNewsletter: false,
            receiveRealTimeNotification: false,
            receiveRealTimeNotificationForNewPsqArqcSent: false,
            isDataSteward: false
        }
    }, () => this.toggleShowEditClientAuthModal());
  }

  handleNewButtonClick(e) {
    e.preventDefault();
    this.toggleShowAddClientAuthModal();
    this.handleDataStewardsForClientCount();
  }

  reloadAdminAuths(reloadAdminAuths) {
    if (reloadAdminAuths) {
      this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);
    }
  }

  handleEditButtonClick(formAuth) {
    this.handleDataStewardsForClientCount();
    for (let a of this.props.authorizations) {
      if (a.id === formAuth.id) {
        let authToUpdate = Object.assign({}, a);
        authToUpdate.role = a.role.toString();
        this.setState({ dataStewardValue: authToUpdate.isDataSteward });
        this.setState({
          auth: authToUpdate,
          receiveDailyNewsletter: authToUpdate.receiveDailyNewsletter,
          receiveWeeklyNewsletter: authToUpdate.receiveWeeklyNewsletter,
          receiveRealTimeNotification: authToUpdate.receiveRealTimeNotification,
          receiveRealTimeNotificationForNewPsqArqcSent: authToUpdate.receiveRealTimeNotificationForNewPsqArqcSent,
          isDataSteward: authToUpdate.isDataSteward
        },
          () => {
            this.toggleShowEditClientAuthModal();
          });
        break;
      }
    }
  }

  handleDeleteButtonClick(auth) {
    if (auth.isDataSteward && this.props.authorizations && this.props.authorizations.filter(x => x.isDataSteward).length === 1) {
      this.toggleShowMinimumDataStewardWarningModal();
      return;
    }

    let id = auth.id;
    this.deletedUserID = auth.userId;

    this.setState({
      confirmAction: () => this.props.authActions.removeAuthorization(id).then(() => {
        this.reloadAdminAuths(true);
        this.setState({ auth: { "userId": null, "resource": authActions.resource.Client, "role": "2", "resourceId": this.props.clientId, "id": null, receiveDailyNewsletter: false, receiveWeeklyNewsletter: false } });
      })
    });
  }

  onConfirmDelete() {
    if (!this.state.currentUserIsSysAdmin && this.deletedUserID?.toLowerCase() == this.state.currentUserId?.toLowerCase()) {
      this.props.router.navigate('/');
      this.setState({ reloadClients: true });
    }
    if (this.state.confirmAction) {
      this.state.confirmAction();
      this.setState({ confirmAction: null });
    }
  }

  toggleShowMinimumDataStewardWarningModal = () => {
    this.setState({
      showMinimumDataStewardWarningModal: !this.state.showMinimumDataStewardWarningModal,
    });

  };
  handleCancelDelete() {
    this.setState({ confirmAction: null });
  }

  handleDailyNewsletter(e) {
    if (e && e.target) {
      this.setState({ receiveDailyNewsletter: e.target.checked });
    }
  }

  handleWeeklyNewsletter(e) {
    if (e && e.target) {
      this.setState({ receiveWeeklyNewsletter: e.target.checked });
    }
  }

  handleRealTimeNotification(e) {
    if (e && e.target) {
      this.setState({ receiveRealTimeNotification: e.target.checked });
    }
  }
  handleDataSteward(e) {
    if (e && e.target) {
      this.setState({ isDataSteward: e.target.checked });
    }
  }
  handleInternalReviewNotification(e) {
    if (e && e.target) {
      this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: e.target.checked });
    }
  }
  /**
   * Handle Grid changes
  */
  handleGridChanges(gridRowData, gridRefApi) {
    let rowData = [];
    rowData =
      gridRowData &&
      gridRowData.length &&
      gridRowData.map((x) => {
        return {
          userId: x.userId,
          role: x.role,
          receiveDailyNewsletter: x.receiveDailyNewsletter,
          receiveWeeklyNewsletter: x.receiveWeeklyNewsletter,
          receiveRealTimeNotification: x.receiveRealTimeNotification,
          receiveRealTimeNotificationForNewPsqArqcSent: x.receiveRealTimeNotificationForNewPsqArqcSent,
          isDataSteward: x.isDataSteward
        };
      });

    this.setState({ gridCurrentState: rowData });
    if (gridRefApi) {
      gridRefApi.refreshCells();
    }
  }

}

DataStewardRoleManagementContainer.propTypes = {
  clientId: PropTypes.number,
  client: PropTypes.object,
  currentUserAuthorizations: PropTypes.array,
  actions: PropTypes.object,
  authorizationActions: PropTypes.object,
  authorizations: PropTypes.array,
  auth: PropTypes.object,
  clientAuthFormValues: PropTypes.object,
  commonActions: PropTypes.object.isRequired,
  dataPurgeConfigurationData: PropTypes.array,
  deletedUserID: PropTypes.string,
  currentUserId: PropTypes.string.isRequired,
  currentUserIsSysAdmin: PropTypes.bool.isRequired,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  let cid = Number.parseInt(ownProps.router.params.clientId);
  if (isNaN(cid)) {
    cid = 0;
  }
  return {
    clientId: cid,
    client: state.client,
    currentUserAuthorizations: state.currentUserAuthorizations,
    authorizations: state.authorizations,
    auth: state.auth,
    dataPurgeConfigurationData: state.dataPurgeConfigurationData,
    clientAuthFormValues: getFormValues('clientAuth')(state),
    currentUserId: state.authentication.currentUser,
    currentUserIsSysAdmin: Array.isArray(state.currentUserAuthorizations) && (authActions.isSystemAdministrator(state.currentUserAuthorizations)),
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    clientActions: bindActionCreators(clientActions, dispatch),
    authorizationActions: bindActionCreators(authActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    dataPurgeActions: bindActionCreators(dataPurgeActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({ form: "dataStewardRoleManagementForm", enableReinitialize: true })(DataStewardRoleManagementContainer)
  )
);
