import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";
import { fetchFromReitApi as fetch } from "./fetchFromApi";
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch all property records belonging to the REIT period. If successful this will dispatch the LOAD_PROPERTIES_SUCCESS
 * action, otherwise it will dispatch the LOAD_PROPERTIES_FAILURE action.
 * @param {number} periodId The id of the period that owns the properties.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchPropertiesByPeriod(periodId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/properties?periodId=${periodId}`).then(response => {
            return response.json();
        }).then(properties => {
            if (actionHelpers.isErrorResponse(properties)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTIES_FAILURE, properties);
            }

            dispatch({ type: actions.LOAD_PROPERTIES_SUCCESS, properties, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTIES_FAILURE, null, error);
        });
    };
}


/**
 * Return true or false if the property can be deleted. If successful this will dispatch the DELETE_PROPERTY_DETAIL_SUCCESS
 * action, otherwise it will dispatch the DELETE_PROPERTY_DETAIL_FAILURE action.
 * @param {number} propertyId The id of the property.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchCanDeleteProperty(propertyId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/properties/deletePropertyDetail?propertyId=${propertyId}`).then(response => {
            return response.json();
        }).then(deletePropertyDetail => {
            if (actionHelpers.isErrorResponse(deletePropertyDetail)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DELETE_PROPERTY_DETAIL_FAILURE, deletePropertyDetail);
            }

            dispatch({ type: actions.DELETE_PROPERTY_DETAIL_SUCCESS, deletePropertyDetail, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DELETE_PROPERTY_DETAIL_FAILURE, null, error);
        });
    };
}


/**
 * Fetch all property records belonging to the REIT period. If successful this will dispatch the LOAD_PROPERTIES_SUCCESS
 * action, otherwise it will dispatch the LOAD_PROPERTIES_FAILURE action.
 * @param {number} propertyId The id of the period that owns the properties.
 * @returns {funtion} A function that returns a Promise.
 */
export function deleteProperty(propertyId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/properties/${propertyId}`,
            {
                method: 'DELETE'
            }
        ).then(response => {
            return response;
        }).then(response => {
            if (actionHelpers.isErrorResponse(response)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DELETE_PROPERTY_FAILURE, response);
            }

            dispatch({ type: actions.DELETE_PROPERTY_SUCCESS, response, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DELETE_PROPERTY_FAILURE, null, error);
        });
    };
}



/**
 * Fetch all property records belonging to the REIT period. If successful this will dispatch the LOAD_PROPERTIES_SUCCESS
 * action, otherwise it will dispatch the LOAD_PROPERTIES_FAILURE action.
 * @param {number} periodId The id of the period that owns the properties.
 * @param {string} checklistId The id of the checklist
 * @param {string} checklistType The typye of the checklist
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalPropertiesByPeriod(periodId, checklistId, checklistType) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const authHeader = actionHelpers.buildAuthHeader(checklistId, "ARQC");
        return fetch(`/properties?periodId=${periodId}`, {
            headers: authHeader && new Headers({ 'Authorization': authHeader })
        }).then(response => {
            return response.json();
        }).then(properties => {
            if (actionHelpers.isErrorResponse(properties)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTIES_FAILURE, properties);
            }

            dispatch({ type: actions.LOAD_PROPERTIES_SUCCESS, properties, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTIES_FAILURE, null, error);
        });
    };
}

/**
 * Fetch all property records belonging to the period.
 * @param {number} periodId The id of the period that owns the properties.
 * @returns {funtion} A function that returns a Promise.
 */
export function fastFetchPropertiesByPeriod(periodId) {
    return fetch(`/properties?periodId=${periodId}`).then(response => {
        return response.json();
    }).catch(error => {
        throw error;
    });
}

/**
 * Fetch first property record belonging to the REIT period. If successful this will dispatch the LOAD_PROPERTY_SUCCESS
 * action, otherwise it will dispatch the LOAD_PROPERTY_FAILURE action.
 * @param {number} periodId The id of the period that owns the property.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchFirstPropertyByPeriod(periodId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/properties/first?PeriodId=${periodId}`).then(response => {
            return response.json();
        }).then(property => {
            if (actionHelpers.isErrorResponse(property)) {
                if (property.errorCode === 'RESOURCE_NOT_FOUND') {
                    property = null;  // Allow 404 and dispatch success with null property
                } else {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTY_FAILURE, property);
                }
            }

            dispatch({ type: actions.LOAD_PROPERTY_SUCCESS, property, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTY_FAILURE, null, error);
        });
    };
}

/**
 * Fetch a property record by its ID. If successful this will dispatch the LOAD_PROPERTY_SUCCESS
 * action, otherwise it will dispatch the LOAD_PROPERTY_FAILURE action.
 * @param {number} id The id of the period that owns the property.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchProperty(id) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/properties/${id}`).then(response => {
            return response.json();
        }).then(property => {
            if (actionHelpers.isErrorResponse(property)) {
                if (property.errorCode === 'RESOURCE_NOT_FOUND') {
                    property = null;  // Allow 404 and dispatch success with null property
                } else {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTY_FAILURE, property);
                }
            }

            dispatch({ type: actions.LOAD_PROPERTY_SUCCESS, property, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_PROPERTY_FAILURE, null, error);
        });
    };
}

/**
 * Create a new property.
 * @param {any} property The property
 * @returns {function} A Promise.
 */
export function createProperty(property) {
    return fetch('/properties',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(property)
        }).then(response => {
            return response.json();
        });
}

/**
 * Updates a property to the REIT API database.
 * @param {any} property The destribution
 * @returns {Promise} A Promise
 * http://localhost:5000/api/properties
 */
export function updateProperty(property) {
    return fetch("/properties",
        {
            headers: {
                'Accept':
                    "application/json",
                'Content-Type': "application/json"
            },
            method: "PUT",
            body: JSON.stringify(property)
        }).then(response => {
            return response.json().catch(error => {
                return null;
            });
        });
}

/**
 * Updates a property's end date and deletes the property in future open periods.
 * @param {any} property The property Id
 * @returns {Promise} A Promise
 * http://localhost:5000/api/properties
 */
export function endProperty(property) {
    return fetch(`/properties/${property.id}/end`,
        {
            headers: {
                'Accept':
                    "application/json",
                'Content-Type': "application/json"
            },
            method: "PUT",
            body: JSON.stringify(property)
        }).then(response => {
            return response.json().catch(error => {
                return null;
            });
        });
}

/**
 * Clears the properties end date
 * @param {any} propertyId The property ID
 * @returns {Promise} A Promise
 */
export function clearEndDate(propertyId) {
    return fetch(`/properties/${propertyId}/end`,
        {
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return null;
            }

            return response.json();
        });
}

/**
 * Fetch all the property enums
 * @returns {Promise} A Promise
 */
export function fetchPropertyTypes() {
    /*
        This is an example by now im just assuming that the returned object is like the next example
        {
            "PropertyTypes": [{"PropertyTypeID": 1, "PropertyTypeDescription": "Office"}...],
            "PSQPropertyTypes": [{"PSQPropertyTypeID": 1, "PSQPropertyTypeDescription": "Office"}...],
        }
    */
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/properties/lookupData`,
            {
                method: "GET"
            }).then(response => {
                return response.json();
            })
            .then(fetchedTypes => {
                dispatch({
                    type: actions.LOAD_ALL_PROPERTY_TYPES_SUCCESS,
                    fetchedTypes,
                    [pendingTask]: end,
                });
            });
    }
}