import React from "react";
import PropTypes from "prop-types";
import FormattedPercent from "../shared/FormattedPercent";

const VariancePercentageDetailsRow = ({ row }) => {
    return (
        <tr>
            <td>{row.before.clientAccountDescription}</td><td className="text-nowrap"><FormattedPercent percentage={row.balanceDeltaPercentage} /></td>
     </tr>
    );
};

const clientAccountDataShape = PropTypes.shape({
    balance: PropTypes.number,
    clientAccountDescription: PropTypes.string,
    clientAccountNumber: PropTypes.string,
    internalAccountCode: PropTypes.string,
    internalAccountDescription: PropTypes.string,
    type: PropTypes.number
});

VariancePercentageDetailsRow.propTypes = {
    row: PropTypes.shape({
        after: clientAccountDataShape,
        balanceDelta: PropTypes.number,
        balanceDeltaPercentage: PropTypes.number,
        before: clientAccountDataShape
    })
};

export default VariancePercentageDetailsRow;