import React from "react";
import PropTypes from "prop-types";
import ReitsExternalRow from "./ReitsExternalRow";

const ReitsExternal = ({ externalReits, reitsAreLoaded, clientId, clientName }) => {
    return (
        <React.Fragment>
            <h2>REITs</h2>
            <div className="row">
                <div className="col">
                    <section className="table-responsive rtable">
                        <table>
                            <thead>
                                <tr>
                                    <th>REITs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {externalReits.map(reit => <ReitsExternalRow key={reit.reitId} reit={reit} clientId={clientId} />)}
                            </tbody>
                        </table>
                        {(externalReits && externalReits.length === 0 && reitsAreLoaded && <div><h5>It looks as though there aren't any REITs for this Client - please reach out to your EY contact.</h5></div>)
                            || null}
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};

ReitsExternal.propTypes = {
    externalReits: PropTypes.array.isRequired,
    reitsAreLoaded: PropTypes.bool,
    clientId: PropTypes.string,

};

export default ReitsExternal;