import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_EXTERNAL_PSQ_SUCCESS action and sets the PSQ checklist in the store
 * @param {Object} [state] The PSQ checklist currently in the store, or the initial state of the PSQ checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The PSQ
 */
export default function externalPsqReducer(state = initialState.psqChecklists, action) {
    if (action.type === actions.LOAD_EXTERNAL_PSQ_SUCCESS) {
        return action.psqChecklists;
    } else if (action.type == actions.UNLOAD_TESTS) {
        return initialState.psqChecklists;
    }else {
        return state;
    }
}