/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifIcon,
} from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';

const BulkProcessUpdateServiceAlertModal = ({
  showUpdateServiceScopeInfo,
  toggleShowUpdateServiceScopeInfo,
}) => {
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Update Service Scope',
    ariaLabel: 'Close Update Service Scope Modal',
    dataTestid: 'close',
  };

  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showUpdateServiceScopeInfo}
        onClose={toggleShowUpdateServiceScopeInfo}
        data-testid="UnAuthorizedServiceScope"
      >
        <MotifModalHeader
          data-testid="h3UpdateServiceScope"
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
          <MotifIcon style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24' fill='none'  />
          <span className="ml-2">Update Services Scope</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <span
            className="checklistActionMsg"
            data-testid="spanUpdateServiceScopeInfo"
          >
          You have selected at least one REIT that is Closed for the selected report period. Please contact Engagement or System Administrator to reopen period and comeback here to update the services scope.
          </span>
    
        </MotifModalBody>
        <MotifModalFooter>
            <MotifButton
              variant="secondary"
              data-testid="btnClose"
              type="button"
              onClick={toggleShowUpdateServiceScopeInfo}
            >
              Close
            </MotifButton>
       
          </MotifModalFooter>
      </MotifModal>
    </React.Fragment>
  );
};

BulkProcessUpdateServiceAlertModal.propTypes = {
  showUpdateServiceScopeInfo: PropTypes.bool,
  toggleShowUpdateServiceScopeInfo: PropTypes.func
};

export default BulkProcessUpdateServiceAlertModal;
