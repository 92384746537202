import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_SUCCESS action and sets content of custom psq templates to the store
 * @param {Object} [state] The custom psq template content currently in the store, or the initial state of custom psq template content
 * @param {Object} action The action to be handled
 * @returns {Object} The custom psq template data
 */

export default function customPsqTemplateContentReducer(
  state = initialState.activeCustomPsqTemplateContent,
  action
) {
  switch (action.type) {
    case actions.LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_SUCCESS:
      return action.customPsqTemplateContent;
    default:
      return state;
  }
}
