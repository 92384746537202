import React from 'react';
import { MotifTooltip } from '@ey-xd/motif-react';

export default (props) => {
  let fieldValue = "";

  if (props && props.data) {
    switch (props.fieldName) {
      case 'sectionName': {
        fieldValue = props.data['sectionSortOrder'] + ' - ' + props.data['sectionName'];
        break;
      }
      case 'questionTitle': {
          fieldValue = props.node.data.itemType && props.node.data.itemType.toLowerCase() === 'fileupload'
              ? props.node.data['questionText']
              : props.node.data['displayQuestionOrder'] + ' - ' + props.node.data['questionText'];
          break;
      }
      case 'discrepanyMessage': {
        fieldValue = props.data['discrepancyMessage'];
        break;
      }
    }
  }

  if (fieldValue && fieldValue.length > 55) {
    return (
      <div>
        <span>{fieldValue.substring(0, 54)}</span>
        <span className="ml-1" style={{ cursor: 'pointer' }}>
          <MotifTooltip
            id="show-discripancy-tooltip"
            allowClick
            allowHover
            placement="bottom"
            trigger={<span style={{ whiteSpace: 'nowrap' }}>...</span>}
            variant="alt"
            style={{ maxWidth: '20px' }}
            hideCloseButton={true}
          >
            {fieldValue}
          </MotifTooltip>
        </span>
      </div>
    );
  } else {
    return (
      <div>
        <span>{fieldValue}</span>
      </div>
    );
  }
}