import * as React from 'react';
import { EditorUtils } from '@progress/kendo-react-editor';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const spacingOptions = ['1.0', '1.08','1.15', '1.5', '2.0', '2.5', '3.0'];

const percentagePattern = /^\d+(\.\d+)?%$/;
const numberPattern = /^\d+(\.\d+)?$/;
const DefaultToolText = 'Line Spacing';

const formatLineHeight = function(number) {
    const numberString = number.toFixed(2);

    if(numberString.slice(-1) === '0') {
        return numberString.substring(0, numberString.length - 1);
    }
    else {
        return numberString;
    }
}

const readLineHeight = function (height) {
    height = `${height}`.trim();

    if (percentagePattern.test(height)) {
        return formatLineHeight(parseFloat(height) / 100);
    }

    if (numberPattern.test(height)) {
        return formatLineHeight(parseFloat(height));
    }

    return DefaultToolText;
}

const CustomLineSpacingTool = (props) => {
    const { view } = props;

    const styleQuery = {
        name: 'line-height',
        value: /^([a-z]+)|(\d+(\.\d+)?%?)$/
    };

    const appliedSpacings =
        view ?
            EditorUtils.getInlineStyles(view.state, styleQuery).map(readLineHeight) :
            [];

    const singleSpacingApplied = new Set(appliedSpacings).size === 1;

    const currentSpacing =
        singleSpacingApplied ?
            readLineHeight(appliedSpacings[0]) :
            DefaultToolText;

    const onChange = function (event) {
        const value = parseFloat(event.value);

        if (!isNaN(value)) {
            const styling = {
                style: 'line-height',
                value
            };

            EditorUtils.applyInlineStyle(view, styling);
            setTimeout(() => view.focus());
        }
    }

    return (
        <DropDownList
            data={spacingOptions}
            onChange={onChange}
            value={currentSpacing}></DropDownList>
    )
};

export { CustomLineSpacingTool };