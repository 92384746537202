import React from 'react';

export default (props) => {
  let dateValue = props.data && props.value;
  const dateOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return dateValue ? (new Date(dateValue + 'Z')).toLocaleString('en-US', dateOptions) : '';
};
