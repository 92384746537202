import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the LOAD_USER_PROFILE_PICTURE_SUCCESS action and sets blob url of the profile picture of the logged in user to the store
 * @param {Object} [state] The blob url of the profile picture of the logged in user currently in the store, or the initial state of blob url of the profile picture of the logged in user
 * @param {Object} action The action to be handled
 * @returns {Object} The blob url of the profile picture of the logged in user
 */

export default function userProfilePictureReducer(
  state = initialState.userProfilePictureUrl,
  action
) {
  switch (action.type) {
    case actions.LOAD_USER_PROFILE_PICTURE_SUCCESS:
      return action.blobUrl;
    case actions.LOAD_USER_PROFILE_PICTURE_FAILURE:
      return state;
    default:
      return state;
  }
}
