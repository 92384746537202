import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOGIN_SUCCESS, LOGIN_FAILURE, and LOGOUT actions.
 * @param {boolean} [state] The existing state of the isAuthenticated property in store or the initial state of that property
 * @param {Object} action The action to handle
 * @returns {boolean} True if the user is authenticated, false if not.
 */
export default function isAuthenticated(state = initialState.isAuthenticated, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return true;
        case actions.LOGIN_FAILURE:
        case actions.LOGOUT:
            return false;
        default:
            return state;
    }
}