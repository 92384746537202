import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifErrorMessage } from '@ey-xd/motif-react';


/**
 * CustomFdrTemplateContainer container component
 * @extends {React.Component}
 */
export class EditChartNameModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        const chartID = props.chartOfAccountsFormValues.chartID;

        const chartName =
            props
                .chartOfAccounts
                .charts
                .find(c => c.chartID === chartID)
                .chartName;

        this.props.dispatch(this.props.initialize({ chartName, chartID }));
    }

    componentDidMount() { }

    /**
    * Render a React element
    * @returns {Object} A reference to the component
    */
    render() {
        return (
            <MotifModal
                show={true}
                onClose={this.props.toggleEditNameModal}
                variant="alt"
            >
                <MotifModalHeader>
                    Edit Chart of Accounts
                </MotifModalHeader>
                <MotifModalBody>
                    <form onSubmit={this.props.handleSubmit(this.props.onSubmit)} id="editCamForm">
                        <Field
                            name="chartName"
                            type="text"
                            component={formHelpers.renderField}
                            label="Chart Name"
                            maxLength={255}
                            onChange={this.props.clearDuplicateErrorMessage}
                            validate={[formValidators.required, formValidators.maxLength255]} />
                        {this.props.chartOfAccounts.duplicate
                            &&
                            <MotifErrorMessage>This chart name already exists.</MotifErrorMessage>
                        }
                    </form>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton
                            variant="secondary"
                            type="button"
                            onClick={this.props.toggleEditNameModal}
                            form="editCamForm"
                        >
                            Cancel
                    </MotifButton>
                    <MotifButton
                        variant="primary"
                        type="submit"
                        disabled={this.props.invalid || this.props.submitting}
                        form="editCamForm"
                    >
                        Save
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        );
    }
}

EditChartNameModal.propTypes = {
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    dispatch: PropTypes.func,
    initialize: PropTypes.func,
    toggleEditNameModal: PropTypes.func,
    clearDuplicateErrorMessage: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    editChartNameFormValues: PropTypes.object,
    chartOfAccountsFormValues: PropTypes.object,
    chartOfAccounts: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    const editChartNameFormValues = getFormValues('editChartNameForm')(state);

    return {
        editChartNameFormValues
    };
}

export default connect(mapStateToProps)(reduxForm({ form: "editChartNameForm" })(EditChartNameModal));