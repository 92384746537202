import React from 'react';
import { Link } from 'react-router-dom';
import { MotifTextLink } from '@ey-xd/motif-react';

export default (props) => {
    const client = props.node.data;
    const rowIndex = props.node.rowIndex + 2;
    return (
        <div aria-label="Press ENTER to open this client." aria-rowindex={rowIndex}>
            <Link className='client-text-link' tabIndex='-1' to={'/ext/reits/' + client.id}>
                <MotifTextLink variant="alt">{client.name}</MotifTextLink>
            </Link>
        </div>
    );
}