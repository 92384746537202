import React from 'react';

const HeaderWrapper = ({ children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center'
    }}
  >
    {children}
  </div>
);

export default HeaderWrapper;