import React from "react";
import PropTypes from "prop-types";
import { Icon, Progress, Button, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as checklistActions from "../../actions/checklistActions";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter, 
  MotifFileUploader,
  MotifIcon, 
  MotifMessage,
  MotifFormField
} from "@ey-xd/motif-react";
import { IconoirXmarkCircle, IconoirCheckCircle } from '@ey-xd/motif-icon';
import * as Constants from "../../constants/other";

/**
 * Container component used for uploading Checklist Delivery Schedules
 */
export class ChecklistDeliveryScheduleUploadModal extends React.Component {
  /**
   * Creates a new ChecklistDeliveryScheduleUploadModal
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.renderUploadStatus = this.renderUploadStatus.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      status: null,
      rejectedFiles: [],
      acceptedFiles: [],
      validationErrors: [],
      closedReportPeriodExists: false,
      closedReportPeriodExistsMessage: "",
    };

    this.acceptedFileExtensions = ".xlsx";
  }

  /**
   * Handle Close popup
   */
  handleClose() {
    this.setState({
      status: null,
      rejectedFiles: [],
      acceptedFiles: [],
      validationErrors: [],
    });
    this.props.refreshScheduleChecklists(
      this.props.clientId,
      this.props.scheduleChecklistValues.calendarYear
    );
    this.props.toggleShowUploadChecklistDeliveryScheduleModal();
  }

  /**
   * Hanle Drop event
   */
  onDrop(acceptedFiles, rejectedFiles) {
    if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
      this.setState({
        status: "Rejected",
        rejectedFiles,
        acceptedFiles: [],
        validationErrors: [],
      });
    } else if (Array.isArray(acceptedFiles)) {
      this.setState({
        status: "Uploading",
        rejectedFiles: [],
        acceptedFiles: acceptedFiles,
        validationErrors: [],
      });

      this.props.checklistActions
        .uploadChecklistDeliverySchedules(
          this.props.clientId,
          this.props.scheduleChecklistValues.calendarYear,
          acceptedFiles[0]
        )
        .then((response) => {
          if (
            response &&
            response.errorResponse &&
            response.errorResponse.errorCode
          ) {
            //SERVER ERROR, BAD REQUEST
            this.setState({
              status: null,
              rejectedFiles: [],
              acceptedFiles: [],
              validationErrors: [],
            });
            this.props.toggleShowUploadChecklistDeliveryScheduleModal();
          } else if (
            response &&
            response.uploadChecklistDeliveryScheduleValidationError &&
            Array.isArray(
              response.uploadChecklistDeliveryScheduleValidationError
            ) &&
            response.uploadChecklistDeliveryScheduleValidationError.length > 0
          ) {
            this.setState({
              status: "Error",
              acceptedFiles: acceptedFiles,
              validationErrors:
                response.uploadChecklistDeliveryScheduleValidationError,
            });
          } else {
            this.setState({
              status: "Success",
              acceptedFiles: acceptedFiles,
              validationErrors: [],
            });
          }

          //  Set isClosedReportPeriodExists flag and closed period message
          if (
            response &&
            response.isClosedReportPeriodExists &&
            response.closedReportPeriodExistsMessage
          ) {
            this.setState({
              closedReportPeriodExists: true,
              closedReportPeriodExistsMessage:
                response.closedReportPeriodExistsMessage,
            });
          } else {
            this.setState({
              closedReportPeriodExists: false,
              closedReportPeriodExistsMessage: "",
            });
          }
        });
    }
  }

  /**
   * Render Upload Status
   */
  renderUploadStatus() {
    let msg;
    let rejectedDetailMsg;

    if (this.state.status == "Uploading") {
      msg = "Uploading file(s)";
    } else if (this.state.status == "Success") {
      msg = "File has successfully been processed.";
      if (this.state.acceptedFiles && this.state.acceptedFiles.length > 0) {
        var acceptedfileName = this.state.acceptedFiles[0].name;
        msg = acceptedfileName + " has successfully been processed.";
      }
    } else if (this.state.status == "Rejected") {
      if (this.state.rejectedFiles && this.state.rejectedFiles.length > 1) {
        //Upload more than one file

        msg = "Upload failed";
        rejectedDetailMsg = "Cannot upload more than one file.";
      } else {
        //Upload single non xlsx type file or Upload two xlsx type files

        msg = "Upload failed";
        rejectedDetailMsg = "Cannot upload more than one file.";

        if (this.state.rejectedFiles && this.state.rejectedFiles.length == 1) {
          let file = this.state.rejectedFiles[0].file;
          let fileName = file.name;
          if (fileName) {
            let fileExtension = fileName.substr(fileName.lastIndexOf(".") + 1);
            if (fileExtension.toUpperCase() != "XLSX") {
              rejectedDetailMsg =
                "The format of the file you submitted is not supported. The only supported format for the Schedule Checklist Delivery template is XLSX.";
            } else if (file.size > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES) {
              rejectedDetailMsg =
                "The file size has exceeded max limit of " +
                Constants.MAX_UPLOAD_FILE_SIZE_IN_MB +
                "MB.";
            } else {
              rejectedDetailMsg = "Cannot upload more than one file.";
            }
          }
        }
      }
    } else if (this.state.status == "Error") {
      msg = "Upload failed";
    }

    return (
      msg && (
        <React.Fragment>
          {this.state.status == "Success" && (
            <div style={{ textAlign: "left", marginBottom: "20px" }}>
                <div className="row">
                    <div className="col-md-1">
                        <div className="mt-1">
                            <MotifIcon iconFunction={IconoirCheckCircle} title="success" size='24' fill='none' stroke="#34c768" />
                        </div>
                    </div> 
                    <div className="col-md-11"> 
                        <div style={{ marginLeft:'-18px' }}> 
                            <MotifMessage className="motifLabelh3 upload-success-header" >{msg}</MotifMessage>
                        </div>
                    </div>
                </div>
            </div>
          )}

          {this.state.status == "Error" && (
            <div style={{ textAlign: "left" }}>
                  <div className="row">
                    <div className="col-md-1">
                        <div className="mt-1">
                            <MotifIcon iconFunction={IconoirXmarkCircle} title="error" size='24' fill='none' stroke="#ff4136" />
                        </div>
                    </div> 
                    <div className="col-md-11"> 
                        <div style={{ marginLeft:'-18px' }}> 
                                <MotifMessage className="motifLabelh3 upload-error-header" >{msg}</MotifMessage>
                        </div>
                    </div>
                </div>
              {this.state.validationErrors &&
                this.state.validationErrors.length > 0 && (
                  <div className="validation-error-content">
                    {this.state.validationErrors.map((e, index) => (
                      <div
                        key={index}
                        style={{ marginLeft: 37, wordWrap: 'break-word' }}
                      >
                        {e.error}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
          {this.state.closedReportPeriodExists && (
            <div style={{ textAlign: "left", marginLeft: "11px" }}>
              <div>
                <span>
                  <MotifMessage className="motifLabelh3" >Note</MotifMessage>
                </span>
              </div>
              <div className="validation-note-content">
                {this.state.closedReportPeriodExistsMessage}
              </div>
            </div>
          )}

          {this.state.status == "Rejected" && (
            <div style={{ textAlign: "left" }}>              
              <div className="row">
                <div className="col-md-1">
                    <div className="mt-1">
                        <MotifIcon iconFunction={IconoirXmarkCircle} title="error" size='24' fill='none' stroke="#ff4136" />
                    </div>
                </div> 
                <div className="col-md-11"> 
                    <div style={{ marginLeft:'-18px' }}> 
                            <MotifMessage className="motifLabelh3 upload-error-header" >{msg}</MotifMessage>
                    </div>
                </div>
                </div>
              <div style={{ marginLeft: 37, fontSize: '14px' }}> {rejectedDetailMsg} </div>
            </div>
          )}

          {this.state.status == "Uploading" && (
            <div>
              <MotifMessage className="motifLabelh3" >{msg}</MotifMessage>
            </div>
          )}
        </React.Fragment>
      )
    );
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    const HeaderWrapper = ({ children }) => (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    );

    const closeButtonProps = {
      title: "Close upload checklist delivery schedules modal",
      ariaLabel: "Close upload checklist delivery schedules modal",
      dataTestid: "close",
    };

    return (
      <MotifModal variant="alt" show={this.props.showUploadChecklistDeliveryScheduleModal} 
            onClose={this.handleClose} data-testid="uploadScheduleDelivery">
                <MotifModalHeader closeButtonProps={closeButtonProps} >
                    <HeaderWrapper>
                        <span className="ml-2">Upload Checklist Delivery Schedules</span>
                    </HeaderWrapper>
                </MotifModalHeader>
            <MotifModalBody>                 
                <MotifFormField className="mt-6"> 
                <MotifFileUploader
                    onDrop={this.onDrop}                
                    label="Drop files here or click to upload"
                    accept=".xlsx"
                    maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}
                    data-test-id="attachmentFileUpload"
                    tabIndex="-1"/>
                    <div className="react-modal-content-scrollable mt-3 mb-3">
                        {this.renderUploadStatus()}
                    </div>
                </MotifFormField>
                    <br />            
            </MotifModalBody>
                    <MotifModalFooter>
                        <MotifButton onClick={this.handleClose} variant="primary" className="right" >Close</MotifButton>
                    </MotifModalFooter>
            </MotifModal>
    );
  }
}

ChecklistDeliveryScheduleUploadModal.propTypes = {
  toggleShowUploadChecklistDeliveryScheduleModal: PropTypes.func.isRequired,
  showUploadChecklistDeliveryScheduleModal: PropTypes.bool.isRequired,
  authHeader: PropTypes.object,
  scheduleChecklistValues: PropTypes.object,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {};
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    checklistActions: bindActionCreators(checklistActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistDeliveryScheduleUploadModal);