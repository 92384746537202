import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";
import { fetchFromClientApi as fetch } from "./fetchFromApi";
import * as actionHelpers from '../scripts/actionHelpers';
import * as Constants from '../constants/other';

let notificationInterval = null;

/**
 * Adds or updates notifications and the retrieves the notifications for a user.
 * @param {any} notifications The notifications.
 * @param {any} userName The user to retrieve notifications for.
 * @returns {any} A function that returns a Promise.
 */
export function addOrUpdateNotifications(notifications, userName) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch("/notifications",
            {
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json"
                },
                method: "POST",
                body: JSON.stringify(notifications)
            }).then(response => {
                if (response.ok) {
                    return null;
                } else {
                    return response.json();
                }
            }).then(data => {
                if (data) {
                    actionHelpers.dispatchErrorAndEndTask(dispatch, actions.ADD_NOTIFICATIONS_FAILURE, data);
                    return false;
                } else {
                    dispatch({ type: actions.ADD_NOTIFICATIONS_SUCCESS, [pendingTask]: end });
                    if (userName) {
                        getNotifications(dispatch, userName);
                    }
                    return true;
                }
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.ADD_NOTIFICATIONS_FAILURE, null, error);
                return false;
            });
    };
}

/**
 * Get notifications for a user.
 * @param {any} dispatch The dispatch.
 * @returns {any} A Promise.
 */
export function getNotifications(dispatch) {    
    return fetch(`/notifications`)
        .then(response => {
            if (response.ok) {
                return response.json();
            } 

            return null;
        }).then(data => {
            if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].notificationContent) {
                        data[i].notificationContent = JSON.parse(data[i].notificationContent);
                        if (data[i].notificationContent && data[i].notificationContent.message) {
                            // Replace \\u0026 with ampersand
                            data[i].notificationContent.message = data[i].notificationContent.message.replaceAll('\\u0026', '&');
                        }
                    }
                }

                dispatch({ type: actions.GET_NOTIFICATIONS_SUCCESS, notifications: data });
            }            
        });
}

/**
 * Remove all notifications for a user.
 * @param {any} userName The username of the user to clear notifications for.
 * @returns {any} A function that returns a Promise.
 */
export function clearAllNotifications(userName) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/notifications?userName=${userName}`, {
            method: "DELETE"
        }).then(response => {
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            getNotifications(dispatch, userName);
        });
    };
}

/**
 * Remove a single notification for a user.
 * @param {any} id The ID of the notification to clear.
 * @param {any} userName The username of the notification to clear.
 * @returns {any} A function that returns a Promise.
 */
export function clearNotification(id, userName) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/notifications/${id}`, {
            method: "DELETE"
        }).then(response => {
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            getNotifications(dispatch, userName);
        });
    };
}

/**
 * Repeatedly fetch notifications at a configured interval.  The interval is configured in the NOTIFICATIONS_POLL_INTERVAL environment variable and defaults to 5000 (5 seconds).
 * @param {any} dispatch The dispatch.
 * @param {any} userName The username of the user to get notifications for.
 * @returns {void} None
 */
export function pollNotifications(dispatch, userName) {
    getNotifications(dispatch, userName);
    notificationInterval = setInterval(function() { getNotifications(dispatch, userName); }, Constants.NOTIFICATIONS_POLL_INTERVAL);
}

/**
 *  Toggle the display of the notifications side bar.
 *  @returns {any} A functon that has no return value.
 */
export function toggleNotificationsDisplay() {
    return function (dispatch) {
        dispatch({ type: actions.TOGGLE_NOTIFICATIONS_DISPLAY });
    };
}

/**
 *  Stop polling for notifications
 * @returns {void} None
 */
export function stopNotificationPolling() {
    if (notificationInterval !== undefined && notificationInterval !== null) {
        clearInterval(notificationInterval);
        notificationInterval = null;
    }
}