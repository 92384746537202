import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import {
    MotifButton, MotifModal,
    MotifModalBody, MotifModalFooter, MotifModalHeader} from "@ey-xd/motif-react";
import { addAttribute } from "../../common/Attribute";

const ReopenForEYReviewChecklistModal = ({ showReopenForEYReviewChecklistModal,
    handleSubmit, handleReopenForEYReviewChecklist, toggleShowReopenForEYReviewChecklistModal, gridCurrentState }) => {

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Reopen for EY Review modal',
        ariaLabel: 'Close Reopen for EY Review modal',
        dataTestid: 'close'
    };

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });
    
    return (
        <MotifModal variant="alt" show={showReopenForEYReviewChecklistModal} onClose={() => toggleShowReopenForEYReviewChecklistModal()} size="xl" data-testid="ReopenForEYReviewChecklists">
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Reopen for EY Review</span>
                </HeaderWrapper>
            </MotifModalHeader>

            <MotifModalBody>
                <form onSubmit={handleSubmit} className="auth">
                    <span className="checklistActionMsg">
                        You selected {gridCurrentState && new Set(gridCurrentState.filter(record => record.checklistSelection)
                            .map(record => record.checklistID + '_' + record.checklistType)).size} checklist(s).
                    </span>
                    <br />               
                </form>
            </MotifModalBody>
            
            <MotifModalFooter>
                <MotifButton
                    variant="secondary"
                    type="button"
                    onClick={() => toggleShowReopenForEYReviewChecklistModal()} className="right" data-testid="Cancel">
                    Cancel
                </MotifButton>
                <MotifButton type="button" onClick={() => handleReopenForEYReviewChecklist()} className="right" data-testid="Submit" >
                    Submit
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

ReopenForEYReviewChecklistModal.propTypes = {
    showReopenForEYReviewChecklistModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleShowReopenForEYReviewChecklistModal: PropTypes.func
};

export default reduxForm({ form: "ReopenForEYReviewChecklist", enableReinitialize: true })(ReopenForEYReviewChecklistModal);