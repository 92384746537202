export const download = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;

    var ext = fileName.substr(fileName.lastIndexOf('.') + 1);
    var name = fileName.substr(0, fileName.lastIndexOf('.')); 
    
    name = (name.replace(/[/\\":*?<>|]/g,'')).replace(/_{2,}/g,'_');
    ext = (ext.replace(/[/\\":*?<>|]/g,'')).replace(/_{2,}/g,'_');

    name = name.replace(/_{2,}/g,'_');
    ext = ext.replace(/_{2,}/g,'_');
    
    name = name.indexOf('_') == 0 ? name.substring(1) : name;
    ext = ext.indexOf('_') == 0 ? ext.substring(1) : ext;
    
    ext = ext.replace(/_\s*$/,"");

    var fN = name+"."+ext;

    fN = truncateFileNameToAGivenCharacterLengthWithExtension(fN, 200);
    
    a.download = fN;
    a.rel = 'noopener';
    document.body.appendChild(a);
    setTimeout(() => a.click(), 0);
    a.parentNode.removeChild(a);
    setTimeout(() => window.URL.revokeObjectURL(url), 40000);    
}

export function decodeURIComponentSafe(uri, mod) {
    var out = new String(),
        arr,
        i = 0,
        l,
        x;
    typeof mod === "undefined" ? mod = 0 : 0;
    arr = uri.split(/(%(?:d0|d1)%.{2})/);
    for (l = arr.length; i < l; i++) {
        try {
            x = decodeURIComponent(arr[i]);
        } catch (e) {
            x = mod ? arr[i].replace(/%(?!\d+)/g, '%25') : arr[i];
        }
        out += x;
    }
    return out;
}

/* Truncate fileName string to length mentioned in parameter maxLength
e.g. If max length parameter value is 200 then fileName(with extension) string is truncated to 200 characters. */
export const truncateFileNameToAGivenCharacterLengthWithExtension = (originalFileName, maxlength) => {
    const [fileName, fileExtension] = originalFileName.split(/\.(?=[^\.]+$)/);

    if (fileName.length <= (maxlength - (fileExtension.length + 1))) {
        return originalFileName;
    } else {
        return fileName.substring(0, (maxlength - (fileExtension.length + 1))) + '.' + fileExtension;
    }
}


