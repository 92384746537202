import React from "react";
import PropTypes from "prop-types";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter, 
  MotifFileUploader
} from "@ey-xd/motif-react";
import * as Constants from "../../constants/other";
/**
 * Container component used for uploading trial balance files
 */
export class BulkUploadModal extends React.Component {
  /**
   * Creates a new TrialBalanceUploadPage
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.renderUploadProgress = this.renderUploadProgress.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.getQueueCount = this.getQueueCount.bind(this);

    this.state = {
      fileStatuses: [],
      fileTypes: {
        "application/vnd.ms-excel": [".xlsx"],
      },
    };
  }

  componentDidUpdate(prevProps) {
    // If modal is shown
    if (this.props.showBulkUpload && !prevProps.showBulkUpload) {
      this.getQueueCount();
      this.timer = setInterval(this.getQueueCount, 3500);
    } else if (!this.props.showBulkUpload && prevProps.showBulkUpload) {
      // else if modal is hidden
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  }

  getQueueCount() {
    this.props.fetchQueueCount();
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (!this.props.client) {
      return;
    }

    const addedStatuses = [];
    this.props.clearUploadTrialBalanceProgress();

    if (Array.isArray(acceptedFiles)) {
      acceptedFiles = acceptedFiles.filter(f => f.error === undefined);
      const acceptedFileStatuses = acceptedFiles.map((file) => ({
        file,
        status: "PENDING",
      }));

      const notifyStatus = (file, status) => {
        acceptedFileStatuses
          .filter((s) => s.file === file)
          .forEach((s) => (s.status = status));

        this.setState({ fileStatuses: [...this.state.fileStatuses] });
      };

      addedStatuses.push(...acceptedFileStatuses);
      this.props.addFilesToQueue(
        acceptedFiles,
        this.props.currentUserId,
        this.props.client.id,
        notifyStatus
      );
    }

    if (Array.isArray(rejectedFiles)) {
      const rejectedFileStatuses = rejectedFiles.map((file) => ({
        file: file.file,
        status: "REJECTED",
      }));

      addedStatuses.push(...rejectedFileStatuses);
      // this.props.notifyRejectedFiles(rejectedFiles, this.props.currentUserId);
    }

    this.setState({
      fileStatuses: [...this.state.fileStatuses, ...addedStatuses],
    });
  }

  renderUploadProgress() {
    const getClassNameAndMessage = (status, fileSize) => {
      if (status === "PENDING") {
        return { className: "row", message: "Pending Upload" };
      }

      if (status === "ERROR") {
        return {
          className: "row bg-danger text-white",
          message: "Upload Error",
        };
      }

      if (status === "IN_PROGRESS") {
        return {
          className: "row bg-secondary text-white",
          message: "Upload In Progress",
        };
      }

      if (status === "UPLOADED") {
        return {
          className: "row bg-success text-white",
          message: "File Uploaded. Check progress in notifications sidebar.",
        };
      }

      if (status === "REJECTED") {
        return {
          className: "row bg-danger text-white",
          message:
            fileSize > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES
              ? "The file size has exceeded max limit of " +
                Constants.MAX_UPLOAD_FILE_SIZE_IN_MB +
                "MB."
              : "Invalid file extension.",
        };
      }

      return { className: "row bg-warning", message: "Status Unknown" };
    };

    return this.state.fileStatuses.map((item, index) => (
      <div
        key={index}
        className={
          getClassNameAndMessage(item.status, item.file.size).className
        }
      >
        <div className="col-md-6" style={{ display: 'inline-block'}} >{item.file.name}</div>
        <div className="col-md-6">
          {getClassNameAndMessage(item.status, item.file.size).message}
        </div>
      </div>
    ));
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    const clear = () => {
      this.props.hideBulkUploadModal();

      // clear everything except PENDING and IN_PROGRESS
      this.setState({
        fileStatuses: this.state.fileStatuses.filter(
          (x) => x.status === "PENDING" || x.status === "IN_PROGRESS"
        ),
      });
    };
    const HeaderWrapper = ({ children }) => (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    );
    return (
      <MotifModal
        variant="alt"
        size="lg"
        show={this.props.showBulkUpload}
        onClose={clear}
        data-testid="uploadBulk"
      >
        <MotifModalHeader
          closeButtonProps={this.props.hideBulkUploadModal}
        >
          <HeaderWrapper>
            <span className="ml-2">Client Trial Balance Upload</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>          
                <MotifFileUploader
                    onDrop={this.onDrop}                
                    onDropRejected={(e) => this.onDrop(null, e)}                
                    label="Drop files here or click to upload"
                    accept={this.state.fileTypes}
                    maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}
                    data-test-id="attachmentFileUpload"
                    tabIndex="-1"/>                      
                    <div className="react-modal-content-scrollable mt-3 mb-3">
                      {this.renderUploadProgress()}
                    </div>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton
            variant="primary"
            className="right"
            type="button"
            onClick={clear}
          >
            Close
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    );
  }
}

BulkUploadModal.propTypes = {
  uploadProgress: PropTypes.array,
  fetchQueueCount: PropTypes.func.isRequired,
  clearUploadTrialBalanceProgress: PropTypes.func.isRequired,
  addFilesToQueue: PropTypes.func.isRequired,
  notifyRejectedFiles: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  processingQueueCount: PropTypes.number,
  showBulkUpload: PropTypes.bool.isRequired,
  hideBulkUploadModal: PropTypes.func.isRequired,
  client: PropTypes.object,
};

export default BulkUploadModal;
