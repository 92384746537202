import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import * as actionHelpers from '../scripts/actionHelpers';
import { fetchFromAuthApi as fetch } from "./fetchFromApi";
/**
 * Fetch calendar years from Data purge. If successful this will dispatch the the TASK_END and return calendarYears
 * otherwise it will dispatch the LOAD_CALENDAR_YEARS_FAILURE action.
 * @returns {function} A function that returns a Promise.
 */
export function fetchDataDeletionCalendarYears() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        let url = `/dataPurge/getDataDeletionYears`;

        return fetch(url).then(response => {
            return response.json();
        }).then(calendarYears => {
            if (actionHelpers.isErrorResponse(calendarYears)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DATA_DELETION_LOAD_CALENDAR_YEARS_FAILURE, calendarYears);
            }

            dispatch({ type: actions.TASK_END, [pendingTask]: end });
            return calendarYears;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.DATA_DELETION_LOAD_CALENDAR_YEARS_FAILURE, null, error);
        });
    };
}


export function fetchDataDeletionDiagnosticData(requestData) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/dataPurge/getDataPurgeDiagnosticData',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(requestData)
        }).then(response => {
            return response.json();
        }).then(dataDeletionDiagnosticData => {
            if (actionHelpers.isErrorResponse(dataDeletionDiagnosticData)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DATA_PURGE_DATA_FAILURE, dataDeletionDiagnosticData);
            }
  
            dispatch({ type: actions.LOAD_DATA_PURGE_DATA_SUCCESS, dataDeletionDiagnosticData, [pendingTask]: end });
            return dataDeletionDiagnosticData;
        }
    ).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DATA_PURGE_DATA_FAILURE, null, error);
        });
    }
  }
  
  export function runDataDeletion(calendarYear, clientId, dataPurgeReportPeriodIDs) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
  
        return fetch(`/dataPurge/runDataDeletion/${calendarYear}/${clientId}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(dataPurgeReportPeriodIDs)
        })
            .then(response => {
                return response.text();
            })
            .then(response => {
                const jsonResponse = response ? JSON.parse(response) : {};
                if (actionHelpers.isErrorResponse(jsonResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.ACTION_RUN_DATA_DELETION_FAILURE,
                        jsonResponse,
                    );
                }
  
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return jsonResponse;
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.ACTION_RUN_DATA_DELETION_FAILURE,
                    null,
                    error,
                );
            });
    };
  }