import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Input } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { DateField } from "../shared/formFields";
import { MotifButton, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';

const EndPropertyForm = ({ handleSubmit, handleCloseEndForm, initialValues, error, submitting, handleDismissErrorMessage }) => {

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            overflowWrap: 'anywhere'
          }}
        >
          {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close End Property Modal',
        ariaLabel: 'Close End Property Modal',
        dataTestid: 'close',
    };

    return (
        <React.Fragment>
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span>{initialValues.propertyName}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <form onSubmit={handleSubmit} id="endPropertyForm">
                    {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
                    <input name="id" type="hidden" label="id" />  
                    {initialValues.propertyTrackingID}
                    <div className="row">
                        <div className="field col-md-5">
                            <br />
                            <DateField
                                    name="endDate"
                                    label="End Date"
                                    placeholder="MM/DD/YYYY"
                                    validate={[formValidators.required, formValidators.isValidDate ]}
                                />
                        </div>
                    </div>
                    <div>
                        <p>Note: This property will be removed from all open periods with a start date after the date entered. For each impacted period, the property's trial balances will be deleted and test results will be recalculated.</p>
                    </div>
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" type="button" form="endPropertyForm"
                    onClick={handleCloseEndForm}>
                    Cancel
                </MotifButton>
                <MotifButton variant="primary" type="submit" form="endPropertyForm"
                    disabled={submitting} >
                    Save
                </MotifButton>
            </MotifModalFooter>
        </React.Fragment>
    );
};

EndPropertyForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    property: PropTypes.object,
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    handleCloseEndForm: PropTypes.func.isRequired,
    handleDismissErrorMessage: PropTypes.func.isRequired
};

export default reduxForm({ form: "property" })(EndPropertyForm);