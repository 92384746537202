import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

const FormattedCurrency = ({ value, currency = "USD", minimumFractionDigits = 2, maximumFractionDigits = 2 }) => {
    if (value < 0) {
        return (<span>(<FormattedNumber value={-value} style="currency" currency={currency} minimumFractionDigits={minimumFractionDigits} maximumFractionDigits={maximumFractionDigits} />)</span>);
    }
    
    return (<FormattedNumber value={value} style="currency" currency={currency} minimumFractionDigits={minimumFractionDigits} maximumFractionDigits={maximumFractionDigits} />);
};

FormattedCurrency.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currency: PropTypes.string,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number
};

export default FormattedCurrency;