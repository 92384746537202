import React from 'react';
import { MotifIcon, MotifIconButton, MotifLabel } from '@ey-xd/motif-react';
import FormattedCurrency from '../../shared/FormattedCurrency';

export default (props) => {

    return (
        <div>
            {(props.column.colId == "trsValue" ) &&
                <div>  
                    <FormattedCurrency value={props.data.trsValue}/> 
               </div>
            }

            {(props.column.colId == "trsDebt") &&  
                <div>  
                    <FormattedCurrency value={props.data.trsDebt}/> 
                </div>
            }

            {(props.column.colId == "trsEquity") &&  
                <div>  
                    <FormattedCurrency value={props.data.trsEquity}/> 
                </div>
            }
        </div>
    )
};