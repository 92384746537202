import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_REPORT_ITEMS_SUCCESS action and sets an array of REITs in the store
 * @param {Array} [state] The REITs currently in the store, or the initial state of REITs
 * @param {Object} action The action to be handled
 * @returns {Array} The REITs
 */
export default function availableReportItems(
  state = initialState.availableReportItems,
  action,
) {
  switch (action.type) {
    case actions.LOAD_REPORT_ITEMS_SUCCESS:
    case actions.LOAD_REPORT_ITEMS_FOR_CLIENT_SUCCESS: {
      if (!action || !Array.isArray(action.items)) {
        return state;
      }

      return action.items;
    }
    case actions.UNLOAD_FILE_MANAGEMENT:
      return initialState.availableReportItems;
    default:
      return state;
  }
}
