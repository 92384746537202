import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { addAttribute } from "../../common/Attribute";

import {
    MotifFormField,
    MotifCheckbox,
    MotifLabel,
    MotifSelect,
    MotifOption    
} from "@ey-xd/motif-react";

const TABLE_STYLE = { width: '400px' };

const INNER_TABLE_STYLE = {
    width: 'auto',
    minWidth: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
};

const INCLUDE_COMMENTS_TEXT = 'Include Comments and Attachments';

const PsqTable = ({ includePsqComments, setIncludePsqComments }) => {
    return (<table style={INNER_TABLE_STYLE}>
        <tbody>            
            <tr>
                <td>
                    <MotifFormField onClick={() => setIncludePsqComments(!includePsqComments)}>
                        <MotifCheckbox
                            checked={includePsqComments}
                            onChange={() => setIncludePsqComments(!includePsqComments)}>
                            {INCLUDE_COMMENTS_TEXT}
                        </MotifCheckbox>
                    </MotifFormField>
                </td>
            </tr>
        </tbody>
    </table>);
}

const ArqcTable = ({ includeArqcComments, setIncludeArqcComments }) => {
    return (
        <table style={INNER_TABLE_STYLE}>
            <tbody>
                <tr>
                    <td>
                        <MotifFormField onClick={() => setIncludeArqcComments(!includeArqcComments)}>
                            <MotifCheckbox
                                checked={includeArqcComments}
                                onChange={() => setIncludeArqcComments(!includeArqcComments)}>
                                {INCLUDE_COMMENTS_TEXT}
                            </MotifCheckbox>
                        </MotifFormField>
                    </td>
                </tr>
            </tbody>
        </table>);
}

const TemplateSelector = ({
    customFdrTemplates,
    selectedTemplate,
    handleTemplateSelect,
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const customTemplateOptions = customFdrTemplates.templates.map((t) => ({
        value: t.fdrTemplateID,
        label: t.fdrTemplateName,
    }));

    const templateOptions = [
        { value: -3, label: "Assigned Defaults" },
        { value: -2, label: "Standard Template" },
        ...customTemplateOptions,
    ];

    const currentTemplate = selectedTemplate
        ? templateOptions.find(
              (to) => to.value === selectedTemplate.templateId
          ) || templateOptions[0]
        : templateOptions[0];

    const onTemplateSelected = (v) => {
        const templateId = parseInt(v);

        const templateOption = templateOptions.find(
            (to) => to.value === templateId
        );

        handleTemplateSelect(templateOption);
    };

    const [renderDropdown, setRenderDropdown] = useState(false);

    useEffect(() => {
        setRenderDropdown(false);
        setTimeout(() => setRenderDropdown(true));
    }, [currentTemplate.value]);

    return (
        <div className="row d-flex justify-content-start">
            <div
                className="ml-3 mb-2"
                style={{ textAlign: "initial", width: "400px"}}>
                <MotifFormField>
                    <MotifLabel data-testid="lblSelectFDRTemplate">Select an FDR Template</MotifLabel>
                    {renderDropdown && (
                        <MotifSelect
                            aria-label="Select an FDR template dropdown"
                            value={String(currentTemplate.value)}
                            data-testid="divSelectFDRTemplate"
                            onChange={onTemplateSelected}>
                            {templateOptions.map((o, i) => (
                                <MotifOption key={i} value={String(o.value)} data-testvalue={String(o.label)} aria-label={String(o.label)}>
                                    {String(o.label).replace(/\s+/g, "\u2800")}
                                </MotifOption>
                            ))}
                        </MotifSelect>
                    )}
                </MotifFormField>
            </div>
        </div>
    );
};

const ReportItemRow = ({
    title,
    onClick,
    item,
    selected,
    disabled,
    setItemSelection,
    parentProps
}) => {
    return (
        <tr key={item.type}>
            <td>
                <MotifFormField
                    title={title}
                    onClick={onClick}>
                    <MotifCheckbox
                        name={item.type}
                        checked={selected}
                        disabled={disabled}
                        onChange={() => setItemSelection(item.type, !selected)}>
                        {item.displayName}
                    </MotifCheckbox>
                </MotifFormField>
                {
                    item.type === 'PsqResults' &&
                    selected &&
                    <PsqTable {...parentProps} />
                }
                {
                    item.type === 'ArqcResults' &&
                    selected &&
                    <ArqcTable {...parentProps} />
                }
            </td>
        </tr>);
}

const FDRReportTypeReportItemList = (props) => {

    const {
        availableReportItems,
        selectedItems,
        setItemSelection,
        selectedTemplate
    } = props;
    
    const itemIsSelected = type => selectedItems.includes(type);

    const itemFilter =
        selectedTemplate.templateId < 0 ? () => true : item => ['PsqResults', 'ArqcResults'].includes(item.type);

    const filteredItems = availableReportItems.filter(itemFilter);

    return (
        <Fragment>
            <div style={{ textAlign: 'left', width: '100%' }}>
                <TemplateSelector {...props} />
                <table style={TABLE_STYLE} className="create-and-run-package-fdr-table margin-top-5">
                    <tbody>
                        {filteredItems.map(item => {
                            const selected = itemIsSelected(item.type) || item.isRequired;                            

                            const title = item.isRequired ?
                                    `${item.displayName} is required for all reports.` :
                                    item.displayName;

                            const disabled = item.isRequired;

                            const onClick = disabled ?
                                () => { } :
                                () => setItemSelection(item.type, !selected);

                            return (
                                <ReportItemRow
                                    key={item.type}
                                    title={title}
                                    onClick={onClick}
                                    item={item}
                                    selected={selected}
                                    disabled={disabled}
                                    setItemSelection={setItemSelection}
                                    parentProps={props} />);
                        })}
                    </tbody>
                </table>
            </div>
        </Fragment>);
};

FDRReportTypeReportItemList.propTypes = {
    availableReportItems: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string,
        type: PropTypes.string
    })),
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    setItemSelection: PropTypes.func,
    includePsqComments: PropTypes.bool,
    setIncludePsqComments: PropTypes.func,
    includeArqcComments: PropTypes.bool,
    setIncludeArqcComments: PropTypes.func,
    customFdrTemplates: PropTypes.object,
    selectedTemplate: PropTypes.object,
    handleTemplateSelect: PropTypes.func
}

export default FDRReportTypeReportItemList;