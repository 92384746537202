import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_PERIODS_BY_CLIENT_ID_SUCCESS action and sets a PERIODS in the store
 * @param {Object} [state] The PERIODS currently in the store, or the initial state of PERIODS
 * @param {Object} action The action to be handled
 * @returns {Array} The PERIODS
 */
export default function periods(state = initialState.periods, action) {
    switch (action.type) {
        case actions.LOAD_PERIODS_BY_CLIENT_ID_SUCCESS:
            return action.periods || [];
        case actions.LOAD_PERIODS_BY_CLIENT_ID_FAILURE:
            return initialState.periods;
        default:
            return state;
    }
}