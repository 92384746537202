import { pendingTask, begin, end } from '../constants/pendingTask';
import * as Constants from '../constants/other';
import * as actions from './actionTypes';
import { fetchFromAuthApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download } from '../scripts/fileHelpers';
/**
 * Fetch all the Data Purge Configuration Data for a given client, calendar year & quarter description. If successful this will dispatch the LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS
 * action, otherwise it will dispatch the LOAD_DATA_PURGE_DATA_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export const fetchDataPurgeConfigurationData = () => {
  return (dispatch) => {
   
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/dataPurge/getDataPurgeConfigurationData`)
      .then((response) => {
        return response.json();
      })
      .then((dataPurgeConfigurationData) => {
        if (actionHelpers.isErrorResponse(dataPurgeConfigurationData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_DATA_PURGE_DATA_FAILURE,
            null
          );
        }
        dispatch({
          type: actions.LOAD_DATA_PURGE_DATA_SUCCESS,
          dataPurgeConfigurationData,
          [pendingTask]: end,
        });
        return dataPurgeConfigurationData;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_DATA_PURGE_DATA_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Fetch all the Data Purge User Acknowledgement History Data for a given user id, calendar year & dataPurgeNotificationTypeId. If successful this will dispatch the LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS
 * action, otherwise it will dispatch the LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE action.
 * @param {number} userId The id of the user * 
 * @param {number} calendarYear The calendar year.
 * @param {number} dataPurgeNotificationTypeId The id of the data purge notification type.
 * @returns {function} A function that returns a Promise.
 */
export const fetchDataPurgeUserAcknowledgementHistory = (userId, calendarYear, dataPurgeNotificationType) => {
  return (dispatch) => {
    if (!userId || !calendarYear || !dataPurgeNotificationType) {
      return dispatch({
        type: actions.LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS,
        dataPurgeUserAcknowledgementHistoryData: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/dataPurge/getDataPurgeUserAcknowledgementHistory?userId=${userId}&calendarYear=${calendarYear}&dataPurgeNotificationType=${dataPurgeNotificationType}`)
      .then((response) => {
        return response.json();
      })
      .then((dataPurgeUserAcknowledgementHistoryData) => {
        if (actionHelpers.isErrorResponse(dataPurgeUserAcknowledgementHistoryData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE,
            null
          );
        }
        dispatch({
          type: actions.LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS,
          dataPurgeUserAcknowledgementHistoryData,
          [pendingTask]: end,
        });

        return dataPurgeUserAcknowledgementHistoryData;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE,
          null,
          error
        );
      });
  };
}

/**
* Creates a Data Purge User Acknowledgement History.
* If successful this will return response,
* otherwise it will dispatch the CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE action.
* @param {any} request The Create Data Purge User Acknowledgement request.
* @returns {Promise} A Promise
*/
export function createDataPurgeUserAcknowledgementHistory(request) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin }); 
      
      return fetch(`/dataPurge/createUserAcknowledgementHistory`,
          {
              headers: {
                  'Accept':
                      "application/json",
                  'Content-Type': "application/json"
              },
              method: "POST",
              body: JSON.stringify(request)
          }).then(response => {
              return response.json();
          }).then(result => {
              if (actionHelpers.isErrorResponse(result)) {
                  return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE, result);
              }                

              dispatch({ type: actions.CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS, result, [pendingTask]: end });
          }).catch(error => {
              actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE, null, error);
          });
  };
}

export function getdataPurgeApprovedStatus(clientId) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
      return fetch(`/dataPurge/getDataDeletionApprovalStatus?clientID=${clientId}`)
          .then(response => {
              return response.json();
          }).then(response => {
              if (actionHelpers.isErrorResponse(response)) {
                  return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DATA_PURGE_APPROVED_STATUS_FAILURE, response);
              }

              dispatch({ type: actions.TASK_END, [pendingTask]: end });
              return response;
          }).catch(error => {
              actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_DATA_PURGE_APPROVED_STATUS_FAILURE, null, error);
          });
  };
}

/**
 * Fetch upcoming data deletion notice details for a given calendar year. If successful this will dispatch the LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_SUCCESS
 * action, otherwise it will dispatch the LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_FAILURE action.
 * @param {number} calendarYear The calendar year.
 * @returns {function} A function that returns a Promise.
 */
export const fetchUpcomingDataDeletionNoticeDetails = (calendarYear) => {
  return (dispatch) => {
    if (!calendarYear) {
      return dispatch({
        type: actions.LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_SUCCESS,
        upcomingDataDeletionNoticeData: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/dataPurge/getUpcomingDataDeletionNotice?calendarYear=${calendarYear}`)
      .then((response) => {
        return response.json();
      })
      .then((upcomingDataDeletionNoticeData) => {
        if (actionHelpers.isErrorResponse(upcomingDataDeletionNoticeData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_FAILURE,
            null
          );
        }
        dispatch({
          type: actions.LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_SUCCESS,
          upcomingDataDeletionNoticeData,
          [pendingTask]: end,
        });

        return upcomingDataDeletionNoticeData;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_FAILURE,
          null,
          error
        );
      });
  };
}