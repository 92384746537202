import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_CLIENTS_SUCCESS action and sets an array of clients in the store
 * @param {Array} [state] The clients currently in the store, or the initial state of clients
 * @param {Object} action The action to be handled
 * @returns {Array} The clients
 */
export default function clients(state = initialState.clients, action) {
    switch(action.type) {
        case actions.LOAD_CLIENTS_SUCCESS:
            return action.clients;
        default:
            return state;
    }
}