import React from "react";
import PropTypes from "prop-types";

const ErrorSummaryItem = ({ validationError }) => {
    return (<li>{validationError.message}</li>);
};

ErrorSummaryItem.propTypes = {
    validationError: PropTypes.shape({
        message: PropTypes.string.isRequired
    })
};

export default ErrorSummaryItem;