import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the DELETE_PROPERTY_DETAIL_SUCCESS action and sets the properties in the store
 * @param {Object} [state] The properties currently in the store, or the initial state of the properties
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function deletePropertyDetailReducer(state = initialState.deletePropertyDetail, action) {
    if (action.type === actions.DELETE_PROPERTY_DETAIL_SUCCESS) {
        return action.deletePropertyDetail;
    } else {
        if (action.type === actions.DELETE_PROPERTY_DETAIL_FAILURE) {
            return initialState.deletePropertyDetail;
        } else {
            return state;
        }
    }
}