import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";

import { Field, reduxForm, getFormValues } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import { MotifButton,MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifErrorMessage
} from "@ey-xd/motif-react";
import { ModalBody, ModalFooter } from "react-bootstrap";

/**
 * CustomFdrTemplateContainer container component
 * @extends {React.Component}
 */
export class CreateFDRTemplateModal extends React.Component {
    /**
      * Creates a new CustomFdrTemplateContainer
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        if (this.props.mode === 'create') {
            this.props.dispatch(this.props.initialize(
                {
                    templateId: -1,
                    fdrTemplateName: ''
                },
                { options: { updateUnregisteredFields: true } }
            ));
        } else {
            const template = this.props.customFdrTemplateFormValues
                && this.props.customFdrTemplateFormValues.templateId
                && this.props.customFdrTemplateFormValues.templateId !== -1 ?
                this.props.customFdrTemplates.templates.find(t => t.fdrTemplateID == this.props.customFdrTemplateFormValues.templateId) : null;
            //If is saveas set the name with appending -Copy to the template name
            
            this.props.dispatch(this.props.initialize(
                {
                    templateId: template.fdrTemplateId,
                    fdrTemplateName: this.props.mode != 'saveas' ? template.fdrTemplateName : template.fdrTemplateName + '-Copy'
                },
                { options: { updateUnregisteredFields: true } }
            ));
        }
    }
    

    /**
* Render a React element
* @returns {Object} A reference to the component
*/
    render() {
        //const createEdit = this.props.mode === 'create' ? 'Create' : 'Edit';
        //First letter to UpperCase
        const createEdit = this.props.mode != 'saveas'? this.props.mode.charAt(0).toUpperCase() + this.props.mode.slice(1) : 'Save As';
        return (
            <MotifModal aria-label={`${createEdit} Custom FDR Template`} show={true} onClose={this.props.toggleShowCreateTemplateModal}>
                <MotifModalHeader>{`${createEdit} Custom FDR Template`}</MotifModalHeader>
                <MotifModalBody>
                    <form>
                        <Field
                        name="fdrTemplateName"
                        type="text"
                        component={formHelpers.renderField}
                        label="Template Name"
                        maxLength={255}
                        isRequiredField={true}
                        onChange={this.props.clearDuplicateErrorMessage}
                        validate={[formValidators.required, formValidators.maxLength255]} />                   
                        {this.props.customFdrTemplates.duplicate                      
                            && 
                            <MotifErrorMessage>This template name already exists.</MotifErrorMessage>
                        }
                    </form>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton variant="secondary" className="right" type="button"
                        onClick={this.props.toggleShowCreateTemplateModal}>
                        Cancel
                    </MotifButton>
                    <MotifButton variant="primary" className="right" type="submit"
                        disabled={this.props.invalid || this.props.submitting}
                        onClick={this.props.handleSubmit(this.props.mode != 'saveas'? this.props.onSubmit : this.props.onSaveAs)}>
                        Save
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        );
    }
}

CreateFDRTemplateModal.propTypes = {
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    dispatch: PropTypes.func,
    initialize: PropTypes.func,
    toggleShowCreateTemplateModal: PropTypes.func,
    clearDuplicateErrorMessage: PropTypes.func,
    mode: PropTypes.string,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool
};

//export default reduxForm({ form: "customChecklistTemplateModalForm" })(CreateFDRTemplateModal);
export default reduxForm({ form: "customFdrTemplateModalForm" })(CreateFDRTemplateModal);