import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromTrialBalanceApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';

/**
 * Fetch Charts based on Client Id.
 * action, otherwise it will dispatch the LOAD_CHARTS_FAILURE action.
 * @param {number} clientId The Client id.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChartsByClientId(
    clientId
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/chartofaccounts/charts?clientID=${clientId}`)
            .then(response => {
                return response.json();
            }).then(charts => {
                dispatch({ type: actions.TASK_END, [pendingTask]: end });

                if (actionHelpers.isErrorResponse(charts)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHARTS_FAILURE, charts);
                }
                return charts;
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHARTS_FAILURE, null, error);
            });
    };
}

/**
 * Download Client Account Mappings zip package for the REIT and all Properties based on Report Period Id.
 * @param {any} periodId The period ID to determine the template reporting period and properities of that period.
 * @returns {function} A Promise.
 */
export function downloadMappingTemplatePackage(periodId) {
    return fetch(`/chartAssignment/downloadCAMPackage?reportPeriodId=${periodId}`)
        .then(function (response) {
            return response.blob().then(function (blob) {
                const contentDisposition =
                    response.headers.get("Content-Disposition");

                const filename =
                    contentDisposition.split(';')[1].split('=')[1].trim();
                download(blob, decodeURIComponentSafe(filename, 1));
            });
        });
}
