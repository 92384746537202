import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { MotifFormField, MotifSearch, MotifLabel, MotifToggleSwitch, MotifTable } from '@ey-xd/motif-react';
import { AgGridReact } from 'ag-grid-react';
import ClientsCellRenderer from "./customRenderer/ClientsCellRenderer";
import { handleFilterChanged, handleFirstDataRendered, handleGridReady, tableHeaderClass, handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomPagination from '../../common/MotifPagination';
import CustomHeaderTemplate from "../checklistDeliveryCalendar/customHeaders/CustomHeaderTemplate";

const ClientList = ({ clients,currentUserAuthorizations,isEngagementAdministrator,isEngagementUser, handleSearchChange, handleClearSearchText, clientSearchText, handleShowInactive, showInactive,
    isSysAdmin, handleToggleActiveFlag, clientsAreLoaded, handleGridChanges }) => {

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    let getColumnDefs =
       [
            {
                field: 'name', headerName: 'Clients', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 273,
                cellRenderer: ClientsCellRenderer,
                cellRendererParams: { currentUserAuthorizations: currentUserAuthorizations},
                headerComponentParams: {template: CustomHeaderTemplate("name"),},   
                comparator: (a, b) => {
                    var valA = a.toString().toLowerCase();
                    var valB = b.toString().toLowerCase();
                    if (valA === valB) return 0;
                    return valA > valB ? 1 : -1;
                },
                flex: 5,
            },
            {
                field: 'reitCount', headerName: 'REITS', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 110,
                headerComponentParams: {template: CustomHeaderTemplate("reitCount"),},
                flex: 1,
                valueGetter: (params) => params.data.reitCount
            },
            {
                field: 'q1Status', headerName: 'Q1', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 153,headerComponentParams: {template: CustomHeaderTemplate("q1Status"),},
                flex: 1,
            },
            {
                field: 'q2Status', headerName: 'Q2', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 153,headerComponentParams: {template: CustomHeaderTemplate("q2Status"),},
                flex: 1,
            },
            {
                field: 'q3Status', headerName: 'Q3', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 153,headerComponentParams: {template: CustomHeaderTemplate("q3Status"),},
                flex: 1,
            },
            {
                field: 'q4Status', headerName: 'Q4', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 153,headerComponentParams: {template: CustomHeaderTemplate("q4Status"),},
                flex: 1,
            },
           
        ];

    
        if (showInactive) {
            getColumnDefs.push({
                
                    field: 'isActive', headerName: 'Active', sortable: true, showRowGroup: false, rowGroup: false,
                    minWidth: 183, 
                    cellRenderer: ClientsCellRenderer,
                    cellRendererParams: { handleToggleActiveFlag },
                    hide: !showInactive,
                    headerComponentParams: {template: CustomHeaderTemplate("isActive"),},
                    suppressColumnsToolPanel: false,
                
            },);
        }

    //Handle deselet all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);    
    };    

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs);
    useEffect(() => {        
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
        if (rowData.length > 0 && gridRef?.current?.api !== undefined) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);

    useEffect(() => {
        setRowData(clients);
        if (gridRef && gridRef.current && gridRef.current.api) {
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [clients]);

    useEffect(() => {
        if (gridRef.current) {
            setColumnDefs(getColumnDefs);

            // set the current page to 1 and reset the grid to first page
            setCurrentPage(1);
            gridRef.current?.api.paginationGoToPage(0);
        }
    }, [showInactive]);

    const rowHeight = 76; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default if compact={false}

    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-4">
                    <h2>Clients</h2>
                </div>
                <div className="col-md-5 text-right">
                    {isSysAdmin &&
                        <React.Fragment>
                            <MotifLabel
                                htmlFor="switchInactive"
                                aria-label={`Display Inactive Clients toggle button ${showInactive ? 'on' : 'off'}`}
                                style={{ display: 'inline-block', width: 'auto', marginRight: '1rem' }}>
                                Display Inactive Clients:
                            </MotifLabel>
                            <MotifFormField style={{ display: 'inline-block', width: 'auto' }} aria-hidden="true">
                                <MotifToggleSwitch
                                    name="switchInactive"
                                    id="switchInactive"
                                    onChange={handleShowInactive}
                                    checked={!!showInactive} />
                            </MotifFormField>
                        </React.Fragment>
                    }
                </div>
                <div className="col-md-3">
                    <MotifFormField>
                        <MotifSearch
                            type="text"
                            items={[]}
                            onChange={handleSearchChange}      
                            onSelect={handleClearSearchText}
                            value={clientSearchText}
                            name="clientFilter"
                            title="Type in a client name"
                            aria-label="Search for Clients"
                            placeholder="Search for Clients"
                            searchButtonProps={{ tabIndex: "-1" }} />
                    </MotifFormField>
                </div>
            </div>
            <div className="mt-3">
                {(rowData.length > 0) &&
                    <div>
                        <div className="row">
                            <div className="col">
                                <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                                    <MotifTable zebra={true} compact={true}
                                        rowData={rowData}
                                        columnDefs={getColumnDefs}
                                        defaultColDef={defaultColDef}
                                        accentedSort={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        onGridReady={event => handleGridReady(event, gridRef, setCurrentPage, setItemsPerPage, setTotalPages, 'REITSuiteClientListColumnState',handleDeselectAll)}
                                        onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                        ref={gridRef}
                                        pagination={true}
                                        suppressPaginationPanel={true}
                                        paginationPageSize={100}
                                        groupDisplayType={'custom'}
                                        groupSelectsChildren={true}
                                        groupDefaultExpanded={0}
                                        onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                        data-testid="divClientListGrid"
                                        deltaRowDataMode={true}
                                        immutableData={true}
                                        getRowNodeId={(data) => data.id}
                                    />
                                    <div className="mt-3">
                                        <span className="right mb-3">
                                            <CustomPagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                totalPages={totalPages}
                                                handleItemsPerPageChange={handleItemsPerPageChange}
                                                gridRef={gridRef}
                                                setCurrentPage={setCurrentPage}
                                                setItemsPerPage={setItemsPerPage}
                                                setTotalPages={setTotalPages} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(clients && clients.length === 0 && clientSearchText === "" && clientsAreLoaded && <div><h5>It looks as though you aren&apos;t assigned to any clients right now - please contact your manager.</h5></div>) || ((clients && clients.length === 0 && clientSearchText !== "" && clientsAreLoaded) && <div><h5>No clients match your search text &apos;{clientSearchText}&apos;</h5></div>) || null}

            </div>
        </React.Fragment>
    );
};

ClientList.propTypes = {
    clients: PropTypes.array.isRequired,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientSearchText: PropTypes.string,
    handleShowInactive: PropTypes.func,
    showInactive: PropTypes.bool,
    isSysAdmin: PropTypes.bool,
    handleToggleActiveFlag: PropTypes.func,
    clientsAreLoaded: PropTypes.bool,
    currentUserAuthorizations: PropTypes.array,
    isEngagementAdministrator: PropTypes.bool,
    isEngagementUser: PropTypes.bool,
};

export default ClientList;