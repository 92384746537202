import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/periodActions';
import TrialBalanceUploadPage from "../tb/TrialBalanceUploadPage";
import { withRouter } from "../../common/withRouter";

/**
 * ReportPeriodLoader container component
 * @extends {React.Component}
 */
export class ReportPeriodLoader extends React.Component {  
    /**
     * Invoked immediately after a component mounts
     */
    componentDidMount() {
        if (this.props.periodId && this.props.reit && Array.isArray(this.props.reit.periods)) {
            this.props.actions.getPeriodFromReit(this.props.periodId, this.props.reit);
        }
    }

    /**
     * Triggered when the component is about to receive properties
     * @param {Object} nextProps The properties the component is receiving
     */
    componentDidUpdate(prevProps) {
        if (this.props.reit && Array.isArray(this.props.reit.periods) && prevProps.reit !== this.props.reit) {
            this.props.actions.getPeriodFromReit(this.props.periodId, this.props.reit);
        }
    }
    
    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        //if (this.props.location.endsWith(`/period/${this.props.periodId}`)) {
        //    this.context.router.push(`/client/${this.props.client.id}/reit/${this.props.reit.reitid}/period/${this.props.periodId}/uploadtb/adjusted`);
        //}
        let children = <Outlet /> || <TrialBalanceUploadPage params={{ periodId: this.props.periodId, clientId: this.props.client.id, reitId: this.props.reit.reitid }} />;

        return (<div>{children}</div>);
    }
}

ReportPeriodLoader.contextTypes = {
    router: PropTypes.object
};

ReportPeriodLoader.propTypes = {
    client: PropTypes.shape({
        id: PropTypes.number
    }),
    reit: PropTypes.shape({
        id: PropTypes.number,
        periods: PropTypes.arrayOf(PropTypes.shape({
            reportPeriodID: PropTypes.number.isRequired
        }))
    }),
    periodId: PropTypes.string.isRequired,
    period: PropTypes.shape({
        reportPeriodID: PropTypes.number, 
        description: PropTypes.string,
        status: PropTypes.string
    }),
    actions: PropTypes.object,
    trialBalanceActions: PropTypes.object,
    location: PropTypes.string
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: ownProps.router.params.periodId,
        client: state.client,
        reit: state.reit,
        period: state.period,
        location: ownProps.router.location.pathname
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportPeriodLoader));