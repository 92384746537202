import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromTrialBalanceApi as fetchFromTrialBalance } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Clear duplicate record error message.
 */
export function clearDuplicateMessage() {
    return function (dispatch) {
        dispatch({ type: actions.CLEAR_CHART_DUPLICATE });
    };
}

/**
 * Clear duplicate chart of account record error message.
 */
export function clearDuplicateChartOfAccountMessage() {
    return function (dispatch) {
        dispatch({ type: actions.CLEAR_CHARTOFACCOUNT_DUPLICATE });
    };
}

/**
 * Save a current FDR Template to create a new one.
 * If failure, it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} fdrtemplateid The id of the fdr template.
 * @param {string} templateName The name of the template.
 * @param {bool} isdeleted Whether the template is deleted.
 * @returns {function} A function that returns a Promise.
 */
export function createChart(
    clientID,
    chartName,
    copyExistingChart,
    isDefault,
    copyChartID
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchFromTrialBalance('/chartofaccounts/charts', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                clientID,
                chartName,
                copyExistingChart,
                isDefault,
                copyChartID
            })
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

            //Duplicate Record check
            if (jsonResponse && jsonResponse.errorCode == "DUPLICATE_RECORD_ERROR") {
                return dispatch({ type: actions.SET_CHART_DUPLICATE, response: jsonResponse, [pendingTask]: end });
            }
            else if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.CREATE_CHART_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            // This should be the new ChartID
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.CREATE_CHART_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Fetch all the custom fdr templates for a client. If successful this will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} clientID The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCharts(
    clientID
) {
    return function (dispatch) {
        if (!clientID) {
            return dispatch({
                type: actions.LOAD_CHARTS_SUCCESS,
                charts: []
            });
        }

        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartofaccounts/charts?clientID=${clientID}`)
            .then(response => {
                return response.json();
            }).then(charts => {
                if (actionHelpers.isErrorResponse(charts)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHARTS_FAILURE, charts);
                }

                dispatch({ type: actions.LOAD_CHARTS_SUCCESS, charts, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHARTS_FAILURE, null, error);
            });
    };
}

/**
 * Fetch custom fdr template by templateId. If successful this will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} templateId The id of the template.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChartOfAccountsById(
    chartId
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartofaccounts/charts/chartOfAccounts?chartID=${chartId}`)
            .then(response => {
                return response.json();
            }).then(chartOfAccounts => {
                dispatch({ type: actions.TASK_END, [pendingTask]: end });

                if (actionHelpers.isErrorResponse(chartOfAccounts)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHARTS_FAILURE, chartOfAccounts);
                }
                return chartOfAccounts;
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHARTS_FAILURE, null, error);
            });
    };
}

/**
 * Fetch custom fdr template by templateId. If successful this will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} templateId The id of the template.
 * @returns {function} A function that returns a Promise.
 */
export function updateChartOfAccountsById(
    chartId,
    accountsToUpdate
) {
    let bodyUpdateObject = { AccountsToUpdate: accountsToUpdate };
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartofaccounts/charts/accounts/${chartId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(bodyUpdateObject)
        }).then(response => {
            return response.text();
        }).then(chartOfAccounts => {
            dispatch({ type: actions.TASK_END, [pendingTask]: end });
            return chartOfAccounts;

        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHARTS_FAILURE, null, error);
        });
    };
}

export function updateChartAsDefault(
    chartID
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchFromTrialBalance('/chartofaccounts/updateChartAsDefault', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                chartID
            })
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

            if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPDATE_CHART_AS_DEFAULT_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            // This should be the ChartID
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.UPDATE_CHART_AS_DEFAULT_FAILURE,
                null,
                error,
            );
        });
    };
}

export function updateChartName(
    chartID,
    chartName
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchFromTrialBalance('/chartofaccounts/updateName', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                chartID,
                chartName
            })
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

            //Duplicate Record check
            if (jsonResponse && jsonResponse.errorCode == "DUPLICATE_RECORD_ERROR") {
                return dispatch({ type: actions.SET_CHART_DUPLICATE, response: jsonResponse, [pendingTask]: end });
            }
            else if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPDATE_CHART_NAME_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            // This should be the new ChartID
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.UPDATE_CHART_NAME_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Sync REIT Testing attribute for specific REIT or Property
 * If failure, it will dispatch the SYNC_REIT_TESTSING_ATTRIBUTES_REIT_PROPERTY_FAILURE action.
 * @param {number} reportPeriodId The id of the Report Period.
 * @param {number} reitId The id of the REIT.
 * @param {number} propertyId The id of the Property.
 * @returns {function} A function that returns a Promise.
 */
export function syncREITTestsingAttributesForREITorProperty(
    reportPeriodId, reitId, propertyId
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchFromTrialBalance('/chartAssignment/syncAttributes', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                reportPeriodId,
                reitId,
                propertyId
            })
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

             if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.SYNC_REIT_TESTSING_ATTRIBUTES_REIT_PROPERTY_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.SYNC_REIT_TESTSING_ATTRIBUTES_REIT_PROPERTY_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Sync REIT Testing attribute for REIT and all Properties associted with Report Period Id
 * If failure, it will dispatch the SYNC_REIT_TESTSING_ATTRIBUTES_REPORT_PERIOD action.
 * @param {number} reportPeriodId The id of the Report Period.
 * @returns {function} A function that returns a Promise.
 */
export function syncREITTestsingAttributesByReportPeriodId(
    reportPeriodId
) {    
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchFromTrialBalance(`/chartAssignment/syncAttributesByReportPeriodId/${reportPeriodId}`, {        
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST'
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

            if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.SYNC_REIT_TESTSING_ATTRIBUTES_REPORT_PERIOD,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.SYNC_REIT_TESTSING_ATTRIBUTES_REPORT_PERIOD,
                null,
                error,
            );
        });
    };
}

/**
 * Upload chart of accounts
 * If failure, it will dispatch the UPLOAD_CHART_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} chartId The id of the chart.
 * @param {file} file The excel file to be used for saving chart of accounts.
 * @returns {function} A function that returns a Promise.
 */
export function uploadChartofAccounts(clientId, chartId, file) {

    let formData = new FormData();
    formData.append('clientID', clientId);
    formData.append('chartID', chartId);
    formData.append('file', file);

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchFromTrialBalance('/chartofaccounts/uploadChartOfAccounts', {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (actionHelpers.isErrorResponse(response)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.UPLOAD_CHART_FAILURE,
                        response,
                    );
                }
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return response;
            })
            .catch(error => {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPLOAD_CHART_FAILURE,
                    null,
                    error,
                );
            });
    };
}


/**
 * Save a current FDR Template to create a new one.
 * If failure, it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} fdrtemplateid The id of the fdr template.
 * @param {string} templateName The name of the template.
 * @param {bool} isdeleted Whether the template is deleted.
 * @returns {function} A function that returns a Promise.
 */
export function createChartOfAccount(
    clientID,
    chartID,
    clientAccountCode,
    clientAccountDescription,
    clientAccountCategoryID,
    defaultREITTestingAttributeID
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchFromTrialBalance('/chartofaccounts/addAccount', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                clientID,
                chartID,
                clientAccountCode,
                clientAccountDescription,
                clientAccountCategoryID,
                defaultREITTestingAttributeID
            })
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

            //Duplicate Record check
            if (jsonResponse && jsonResponse.errorCode == "DUPLICATE_RECORD_ERROR") {
                return dispatch({ type: actions.SET_CHARTOFACCOUNT_DUPLICATE, response: jsonResponse, [pendingTask]: end });
            }
            else if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.CREATE_CHARTOFACCOUNT_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            // This should be the new ChartID
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.CREATE_CHARTOFACCOUNT_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Fetch calendar years from client's report periods. If successful this will dispatch the the TASK_END and return calendarYears
 * otherwise it will dispatch the LOAD_CALENDAR_YEARS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchClientCalendarYears(clientId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartofaccounts/calendarYears?clientID=${clientId}`)
            .then(response => {
                return response.json();
            }).then(calendarYears => {
                if (actionHelpers.isErrorResponse(calendarYears)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CALENDAR_YEARS_FAILURE, calendarYears);
                }

                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return calendarYears;
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CALENDAR_YEARS_FAILURE, null, error);
            });
    };
}

/**
 * Fetch reits info for chart assigned for client and calendar year selected. If successful this will dispatch the the TASK_END and return reitsMappings
 * otherwise it will dispatch the LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCalendarYearReitsData(clientID, year) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartAssignment/all/${clientID}/${year}`)
            .then(response => {
                return response.json();
            }).then(reitsMappings => {
                if (actionHelpers.isErrorResponse(reitsMappings)) {
                    actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, reitsMappings);
                    return [];
                }
                let gridArray = reitsMappings.map(mapping => {
                    let processedMappingForGrid = { reitid: mapping.reitid, reitName: mapping.reitName };
                    mapping.periodAssignments.forEach((element, index) => {
                        element.reitid = mapping.reitid;
                        processedMappingForGrid[`Quarter${index + 1}`] = element;
                    });

                    //Adding the chart name for search functionality
                    processedMappingForGrid =
                    {
                        ...processedMappingForGrid,
                        Quarter1Text: processedMappingForGrid.Quarter1.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter1.chartName,
                        Quarter2Text: processedMappingForGrid.Quarter2.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter2.chartName,
                        Quarter3Text: processedMappingForGrid.Quarter3.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter3.chartName,
                        Quarter4Text: processedMappingForGrid.Quarter4.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter4.chartName

                    };

                    processedMappingForGrid.isDirty = { Quarter1: false, Quarter2: false, Quarter3: false, Quarter4: false };
                    processedMappingForGrid.originalState =
                    {
                        Quarter1: processedMappingForGrid.Quarter1,
                        Quarter2: processedMappingForGrid.Quarter2,
                        Quarter3: processedMappingForGrid.Quarter3,
                        Quarter4: processedMappingForGrid.Quarter4
                    };

                    return processedMappingForGrid;
                });

                let reponseWithGridArray = { reitsMappings, gridArray };
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return reponseWithGridArray;
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, null, error);
            });
    };
}

/**
 * Fetch reit properties info for chart assigned for reit and calendar year selected. If successful this will dispatch the the TASK_END and return propertiesMappings
 * otherwise it will dispatch the LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchReitPropertiesById(reitId, year) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartAssignment/single/${reitId}/${year}`)
            .then(response => {
                return response.json();
            }).then(propertiesMappings => {
                if (actionHelpers.isErrorResponse(propertiesMappings)) {
                    actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, propertiesMappings);
                    return [];
                }
                let gridArray = propertiesMappings.map(mapping => {
                    let processedMappingForGrid =
                    {
                        reitid: mapping.reitid ? mapping.reitid : reitId,
                        propertyName: mapping.propertyName ? mapping.propertyName : mapping.reitName,
                        propertyTrackingID: mapping.propertyTrackingID,
                        reitName: mapping.reitName
                    };
                    mapping.periodAssignments.forEach((element, index) => {
                        element.reitid = processedMappingForGrid.reitid;
                        processedMappingForGrid[`Quarter${index + 1}`] = element;
                    });

                    //Change the chartID to -1 on isInheritFromReit to handle record count
                    processedMappingForGrid.Quarter1.chartID = processedMappingForGrid.Quarter1.isInheritFromReit ? -1 : processedMappingForGrid.Quarter1.chartID;
                    processedMappingForGrid.Quarter2.chartID = processedMappingForGrid.Quarter2.isInheritFromReit ? -1 : processedMappingForGrid.Quarter2.chartID;
                    processedMappingForGrid.Quarter3.chartID = processedMappingForGrid.Quarter3.isInheritFromReit ? -1 : processedMappingForGrid.Quarter3.chartID;
                    processedMappingForGrid.Quarter4.chartID = processedMappingForGrid.Quarter4.isInheritFromReit ? -1 : processedMappingForGrid.Quarter4.chartID;
                    //Adding the chart name for search functionality
                    processedMappingForGrid =
                    {
                        ...processedMappingForGrid,
                        Quarter1Text: processedMappingForGrid.Quarter1.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter1.isInheritFromReit ? 'Default(REIT Chart)' : processedMappingForGrid.Quarter1.chartName,
                        Quarter2Text: processedMappingForGrid.Quarter2.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter2.isInheritFromReit ? 'Default(REIT Chart)' : processedMappingForGrid.Quarter2.chartName,
                        Quarter3Text: processedMappingForGrid.Quarter3.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter3.isInheritFromReit ? 'Default(REIT Chart)' : processedMappingForGrid.Quarter3.chartName,
                        Quarter4Text: processedMappingForGrid.Quarter4.periodID == null ? "Period not Created" : processedMappingForGrid.Quarter4.isInheritFromReit ? 'Default(REIT Chart)' : processedMappingForGrid.Quarter4.chartName

                    };

                    processedMappingForGrid.isDirty = { Quarter1: false, Quarter2: false, Quarter3: false, Quarter4: false };
                    processedMappingForGrid.originalState =
                    {
                        Quarter1: processedMappingForGrid.Quarter1,
                        Quarter2: processedMappingForGrid.Quarter2,
                        Quarter3: processedMappingForGrid.Quarter3,
                        Quarter4: processedMappingForGrid.Quarter4
                    };

                    return processedMappingForGrid;
                });

                let responseWithGridArray = { propertiesMappings, gridArray };

                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return responseWithGridArray;
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, null, error);
            });
    };
}

/**
 * Update the reits mappings. If successful this will dispatch the the TASK_END
 * otherwise it will dispatch the LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function updateReitsMappings(mappingsToUpdate) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartAssignment/update`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(mappingsToUpdate)
        }).then(response => {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            }
            else {
                return response.text();
            }
        }).then(updateResponse => {
            if (actionHelpers.isErrorResponse(updateResponse)) {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, updateResponse);
                return [];
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });
            return updateResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, null, error);
        });
    };
}

/**
 * Update the reit property mappings. If successful this will dispatch the the TASK_END
 * otherwise it will dispatch the LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function updateReitPropertiesMappings(mappingsToUpdate) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartAssignment/update`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(mappingsToUpdate)
        })
            .then(response => {
                const contentType = response.headers.get("content-type");

                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                }
                else {
                    return response.text();
                }
            })
            .then(updateResponse => {
                if (actionHelpers.isErrorResponse(updateResponse)) {
                    actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, updateResponse);
                    return [];
                }
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return updateResponse;
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE, null, error);
            });
    };
}

/**
 * Fetch Chart Assignments/Mappings by Report Period Id If successful this will dispatch the the TASK_END and return chartMappings
 * otherwise it will dispatch the LOAD_CHART_MAPPINGS_FAILURE action.
 * @param {number} reportPeriodId The Report Period id.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChartAssignmentsByReportPeriodId(reportPeriodId) {

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance('/chartAssignment/chartMappings?reportPeriodId=' + reportPeriodId).then(response => {
            return response.json();
        }).then(chartMappings => {
            if (actionHelpers.isErrorResponse(chartMappings)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_MAPPINGS_FAILURE, chartMappings);
            }

            dispatch({ type: actions.LOAD_CHART_MAPPINGS_SUCCESS, chartMappings, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CHART_MAPPINGS_FAILURE, null, error);
        });
    };
}
