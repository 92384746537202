import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Outlet, useParams } from "react-router-dom";
import { bindActionCreators } from 'redux';
import * as clientActions from '../../actions/clientActions';

/**
 * The ClientLoader functional component
 * @returns {JSX.Element} The rendered component
 */
function ClientLoader(props) {
    const { clientId } = useParams();

    useEffect(() => {
        props.actions.loadClient(clientId);
    }, []);

    return (
        <div>
            <Outlet />
        </div>
    );
}

ClientLoader.propTypes = {
    actions: PropTypes.object
};

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(clientActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(ClientLoader);
