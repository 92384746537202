import React from "react";
import PropTypes from "prop-types";

import {
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifButton
  } from '@ey-xd/motif-react';

const DeleteFdrTemplateModal = ({
    showDeleteTemplateModal,
    handleYes,
    toggleShowDeleteTemplateModal
}) => {
    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
      );
    return (     
        <MotifModal
            variant="alt"
            show={showDeleteTemplateModal}
            onClose={toggleShowDeleteTemplateModal}
            data-testid="TemplateConfirmEditQuestionModal"
        >
        <MotifModalHeader                
                data-testid="h3EditTemplateQuestion"
                >
          <HeaderWrapper>           
            <span className="ml-2">Delete Custom FDR Template</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
        <h6>
                    Are you sure you want to delete this template?
                </h6>
                <br />
                </MotifModalBody>
          <MotifModalFooter className="pl-0">    
           <MotifButton
            variant="secondary"
            className="right"
            type="button"
            onClick={toggleShowDeleteTemplateModal}
          >
            No
          </MotifButton>
          <MotifButton
              variant="primary"
              className="right"
              type="button"
              onClick={handleYes}
            >
              Yes
            </MotifButton>     
          </MotifModalFooter>       
      </MotifModal>
    );
};

DeleteFdrTemplateModal.propTypes = {
    showDeleteTemplateModal: PropTypes.bool,
    handleYes: PropTypes.func,
    toggleShowDeleteTemplateModal: PropTypes.func
};

export default DeleteFdrTemplateModal;