export default (params) => {
    const dateOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };
    const dateValue = !params.data && params.node.childrenAfterGroup && params.node.childrenAfterGroup.length > 0
        ? params.node.childrenAfterGroup[0].data["lastUpdatedDate"] : null;
    return dateValue ? (new Date(dateValue + 'Z')).toLocaleString('en-US', dateOptions) : '';
}