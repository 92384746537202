import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from "semantic-ui-react";
import { MotifButton, MotifIconButton, MotifIcon, MotifTooltip } from "@ey-xd/motif-react";
import { IconoirAttachment, IconoirChatLines, IconoirClockRotateRight } from '@ey-xd/motif-icon';

export const Tab = (props) => {
    return (
        <li className="nav-item">
            <Popup
                trigger={<a className={`nav-link pt-1 pb-1 ${props.linkClassName || ''} ${props.isActive ? 'active' : ''}`}
                    onClick={! props.isDisabledTab?(event) => {
                        event.preventDefault();
                        props.onClick(props.tabIndex);
                    }:null}>                        
                   
                    <MotifIconButton type="button" onClick={(event) => {
                        event.preventDefault();
                        props.onClick(props.tabIndex);
                       
                    }} disabled={props.isDisabledTab} >
                        {props.iconName == 'history' && <MotifIcon iconFunction={IconoirClockRotateRight} size='24' fill='none' />}
                        {props.iconName == 'comments' && <MotifIcon iconFunction={IconoirChatLines} size='24' fill='none' />}
                        {props.iconName == 'attach' && <MotifIcon iconFunction={IconoirAttachment} size='24' fill='none' />}
                    </MotifIconButton>
                    
                    {props.text}
                </a>}
                content={props.hoverText}
                on={['hover', 'focus']}
                inverted
            />            
        </li>
    )
}

Tab.propTypes = {
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    isActive: PropTypes.bool,
    iconClassName: PropTypes.string,
    linkClassName: PropTypes.string,
    text: PropTypes.string,
    hoverText: PropTypes.string,
    isDisabledTab:PropTypes.bool
};

Tab.defaultProps = {
    isDisabledTab: false
};
