import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_SERVICES_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function customQuestionnaireReducer(state = initialState.customQuestionnaire, action) {
    switch (action.type) {
        case actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_SUCCESS:
            return Object.assign({}, state, {
                templates: action.customQuestionnaireTemplates
            });
        case actions.LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_SUCCESS:
            return Object.assign({}, state, {
                templateQuestions: action.templateQuestions
            });
        case actions.LOAD_CUSTOM_QUESTIONS_SUCCESS:
            return Object.assign({}, state, {
                customQuestionList: action.customQuestionList
            });
        case actions.DELETE_CUSTOM_QUESTION_SUCCESS:
            return Object.assign({}, state, {
                customQuestionList: action.customQuestionList
            });
        default:
            return state;
    }
}