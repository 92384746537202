import React from 'react';
import { MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,MotifIcon,MotifButton } from '@ey-xd/motif-react';
import { IconoirWarningTriangle } from '@ey-xd/motif-icon';
import PropTypes from "prop-types";
import {  reduxForm } from "redux-form";


const DataStewardAssignmentRequiredModal = ({showEngagementAdminForDataStewardAssignmentModal,toggleShowEngagementAdminForDataStewardAssignmentModal,dataStewardAssignmentWarningHeader,dataStewardAssignmentWarningBody
}) => {

    return (
    <MotifModal variant="alt"  show={showEngagementAdminForDataStewardAssignmentModal} onClose={() => toggleShowEngagementAdminForDataStewardAssignmentModal()} data-testid="DataStewardAssignmentRemind">
   <MotifModalHeader headerIcon={<MotifIcon class="motif-fab motif-fab-size-small motif-fab-info modal-info" style={{ color: "#FF9831", backgroundColor: "black"}} iconFunction={IconoirWarningTriangle} size="24" strokeWidth="1.5"  stroke="#FF9831"  fill="transparent"  />} >
        <span className="ml-2">{dataStewardAssignmentWarningHeader}</span>
    </MotifModalHeader>
   <MotifModalBody>
       <span className="checklistActionMsg">{dataStewardAssignmentWarningBody}</span>
     
   </MotifModalBody>
   <MotifModalFooter>
           <MotifButton
              
               variant="primary"
               className="right"
               onClick={() => toggleShowEngagementAdminForDataStewardAssignmentModal()}
           >
               Close
           </MotifButton>
       </MotifModalFooter>
 </MotifModal>
    );
};
DataStewardAssignmentRequiredModal.propTypes = {
    showEngagementAdminForDataStewardAssignmentModal: PropTypes.bool,
    toggleShowEngagementAdminForDataStewardAssignmentModal: PropTypes.func,
    dataStewardAssignmentWarningHeader: PropTypes.string,
    dataStewardAssignmentWarningBody: PropTypes.string
    
};

export default reduxForm({ form: "DataStewardAssignmentRequiredForm", enableReinitialize: true })(DataStewardAssignmentRequiredModal);

