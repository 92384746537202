
import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS action and sets bulk report packages data in the store
 * @param {Object} [state] The bulk report packages data currently in the store, or the initial state of  bulk report packages ETA data
 * @param {Object} action The action to be handled
 * @returns {Object} The bulk report packages ETA data
 */
export default function bulkReportPackageETAReducer(state = initialState.bulkReportPackagesETA, action) {    
    if (action.type == actions.LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS && Array.isArray(action.bulkReportPackagesETA)) { 
        return action.bulkReportPackagesETA;
    } else if (action.type == actions.LOAD_BULK_REPORT_PACKAGES_ETA_FAILURE) {
        return initialState.bulkReportPackagesETA;
    } else {
        return state;
    }
}
