import React from "react";
import { connect } from "react-redux";

/**
 * UnauthorizedUserContainer container component
 * @extends {React.Component}
 */
export class UnauthorizedUserContainer extends React.Component {
    /**
    * Creates a new UnauthorizedUserContainer
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.state = {           
        };       
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {       
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <div>
                <h2>Access Denied. You are not authorized to view this page.</h2>
            </div>
        ) ;
    }
}

UnauthorizedUserContainer.propTypes = {    
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    
    return {       
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedUserContainer);