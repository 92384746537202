import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_REITS_FOR_MO_SUCCESS action and sets an array of REITs (with TB FileNames) in the store
 * @param {Array} [state] The REITs currently in the store, or the initial state of REITs
 * @param {Object} action The action to be handled
 * @returns {Array} The REITs
 */
export default function reitsForMO(state = initialState.reitsForMO, action) {
  switch (action.type) {
    case actions.LOAD_REITS_FOR_MO_SUCCESS:
      return action.reitsForMO;
    default:
      return state;
  }
}
