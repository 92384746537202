import React from 'react';
import PropTypes from 'prop-types';

import { MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader, MotifButton } from '@ey-xd/motif-react';

const QuestionLockedInfoModal = ({
    showInfoModal,
    headerMessage,
    content,
    handleCloseOnQuestionInfoModal
}) => {
    return (
        <MotifModal
            variant="alt"
            show={showInfoModal}
            onClose={handleCloseOnQuestionInfoModal}
            data-testid="closeQuestionLockedInfoModal"
        >
            <MotifModalHeader>
                {headerMessage}
            </MotifModalHeader>
            <MotifModalBody>
                {content}
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton
                    type="button"
                    onClick={handleCloseOnQuestionInfoModal}
                    data-testid="btnCloseQuestionLockedInfoModal"
                >
                    Close
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

QuestionLockedInfoModal.propTypes = {
    showInfoModal: PropTypes.bool,
    headerMessage: PropTypes.string,
    content: PropTypes.string,
    handleCloseOnQuestionInfoModal: PropTypes.func
};

export default QuestionLockedInfoModal;