import PropTypes from "prop-types";
import React from "react";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifIcon } from "@ey-xd/motif-react";
import { IconoirXmarkCircle } from '@ey-xd/motif-icon';

const ClientTypeSwitchingErrorMessageModal = ({ showClientSwitchingErrorMessageModal, handleCancel, confirmationMessage, headerTitle
}) => {
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: headerTitle,
        ariaLabel: headerTitle,
        dataTestid: 'close'
    };

    return (
        <MotifModal variant="alt" show={showClientSwitchingErrorMessageModal} onClose={handleCancel} data-testid="Delete">
            <MotifModalHeader closeButtonProps={closeButtonProps} >
                <HeaderWrapper>
                    <MotifIcon className="mt-2" style={{ color: "#F95D54" }} iconFunction={IconoirXmarkCircle} title="error" size='24' fill='none' />
                    <span className="ml-2 mt-1">{headerTitle}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <span className="deleteActionMsg">{confirmationMessage}</span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="primary" className="right"
                    data-testid="Delete"
                    type="button"
                    onClick={handleCancel}>Close</MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

ClientTypeSwitchingErrorMessageModal.propTypes = {
    showClientSwitchingErrorMessageModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    confirmationMessage: PropTypes.string.isRequired,
};

export default ClientTypeSwitchingErrorMessageModal;