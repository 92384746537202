import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_ARQC_CHECKLIST_SUCCESS action and sets the ARQC checklist in the store
 * @param {Object} [state] The ARQC checklist currently in the store, or the initial state of the ARQC checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function checklistsCounterReducer(state = initialState.checklistCounter, action) {
    if (action.type === actions.CHECKLIST_NAVIGATION_COUNTER_SUCCESS) {
        return action.checklistCounter;
    } else {
        return state;
    }
}