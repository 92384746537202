import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/periodActions";
import ClosePeriodModal from "../../components/reits/ClosePeriodModal";
import * as authActions from '../../actions/authActions';

/**
 * The container that renders ClosePeriodModal
 */
export class ClosePeriodContainer extends React.Component {
    /**
     * Creates a new ClosePeriodContainer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = { isAcknowledged: false };

        this.handleOnCancelAction = this.handleOnCancelAction.bind(this);
        this.toggleIsAcknowledged = this.toggleIsAcknowledged.bind(this);
        this.handleClosePeriodClick = this.handleClosePeriodClick.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        if (this.props.periodId > 0) {
            this.props.actions.fetchPeriodById(this.props.periodId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.periodId !== this.props.periodId || prevProps.status !== this.props.status) {
            this.props.actions.fetchPeriodById(this.props.periodId);
        }
    }

    handleOnCancelAction() {
        this.setState({ isAcknowledged: false}, this.props.toggleShowClosePeriodModal());
    }

    handleClosePeriodClick(periodId, currentStatus) {
        this.setState({ isAcknowledged: false });
        this.props.handleClosePeriod(periodId, currentStatus);
    }

    toggleIsAcknowledged(e) {
        this.setState({ isAcknowledged: !this.state.isAcknowledged });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        let isEngAdminOrSysAdmin = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isEngAdminOrSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations) || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId);
        }

        return (
            <ClosePeriodModal showClosePeriodModal={this.props.showClosePeriodModal} handleClosePeriodClick={this.handleClosePeriodClick} handleCancel={this.handleOnCancelAction} period={this.props.period} toggleIsAcknowledged={this.toggleIsAcknowledged} isAcknowledged={this.state.isAcknowledged} periodId={this.props.periodId} setIsAcknowledgedToFalse={this.setIsAcknowledgedToFalse} pendingTasks={this.props.pendingTasks} isEngAdminOrSysAdmin={isEngAdminOrSysAdmin} />
        );
    }
}

ClosePeriodContainer.propTypes = {
    periodId: PropTypes.number.isRequired,
    handleClosePeriod: PropTypes.func,
    period: PropTypes.object,
    showClosePeriodModal: PropTypes.bool,
    toggleShowClosePeriodModal: PropTypes.func,
    pendingTasks: PropTypes.number,
    currentUserAuthorizations: PropTypes.array,
    status: PropTypes.string,
    actions: PropTypes.object.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: ownProps.periodId,
        handleClosePeriod: ownProps.handleClosePeriod,
        period: state.period,
        showClosePeriodModal: ownProps.showClosePeriodModal,
        toggleShowClosePeriodModal: ownProps.toggleShowClosePeriodModal,
        pendingTasks: state.pendingTasks,
        currentUserAuthorizations: state.currentUserAuthorizations,
        clientId: state.client.id
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ClosePeriodContainer);