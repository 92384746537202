import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * ExternalFooter container component
 * @extends {React.Component}
 */
export class ExternalFooter extends React.Component {
    /**
     * Creates a new External Footer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = { userId: "" };
    }

    render() {
        //Get current year
        let year = (new Date().getFullYear());
        
        return (
            <div className="footer-cont" style={{  textAlign: 'center'}}>
                <span style={{ marginTop: '25px' }}>&copy; {year} Ernst & Young LLP | All Rights Reserved | Content within this application is confidential and meant for EY staff, EY clients and their designates.
                </span>
            </div>            
        );
    }
}

ExternalFooter.propTypes = {    
};

ExternalFooter.contextTypes = {   
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let result = {     
    };

    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {       
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalFooter);
