import React, { Component } from 'react';
import DataRetentionManagementHeader from '../../components/dataRetentionManagement/dataRetentionManagementHeader';
import { reduxForm } from "redux-form";
import { withRouter } from "../../common/withRouter";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import DataRetentionManagementTable from '../../components/dataRetentionManagement/dataRetentionManagementTable';
import { bindActionCreators } from "redux";
import * as authActions from "../../actions/authActions";
import * as dataRetentionManagementActions from '../../actions/dataRetentionManagementActions';

export class DataRetentionManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.handleDataRetentionActions=this.handleDataRetentionActions.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.setGridColumnState = this.setGridColumnState.bind(this);
    this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
    this.handleExportDataDeletionSummary=this.handleExportDataDeletionSummary.bind(this);
    this.state = {
      data: [],
      dataRetentionManagementData: [],
      gridCurrentState: [],
      gridColumnState: [],
      refreshGriddataRetentionManagementData: {
        refreshGridOnCompletedAction: -1
      },
      approvedDates: [],
    };
  }
 
  /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
  componentDidMount() {
    this.getDataRetentionManagementData();
      
     // Fetch authorizations
     this.props.authActions
     .fetchAuthorizationsForCurrentUser(this.props.currentUserId)
     .then((currentUserAuthorizations) => {
       this.setState({ currentUserAuthorizations });
     });  
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dataRetentionManagementData &&
      this.props.dataRetentionManagementData !== prevProps.dataRetentionManagementData
    ) {
      this.setState({ dataRetentionManagementData: this.props.dataRetentionManagementData });
      let updatedData = this.props.dataRetentionManagementData?.filter(data => data.approvedDateTime !== null);
      // MM:DD:YYYY HH:MM AM/PM
      const dateFormat1 = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };

      updatedData = this.props.dataRetentionManagementData?.filter(data => data.approvedDateTime !== null).map((data) => {
        if (data.approvedDateTime !== null && data.approvedDateTime !== undefined) {
          const date = new Date(data.approvedDateTime);
          // use toLocaleString with undefined value to get the local time zone settings
          const formattedDate = (new Date(date + 'Z')).toLocaleString(undefined, dateFormat1);
          return {
            DataPurgeReportPeriodID: data.dataPurgeReportPeriodID,
            LocalTZApproveDateTime: formattedDate,
          };
        }
      });

      this.setState({ approvedDates: updatedData });
    }  
  }

  /**
  * Invoked immediately before unmounting occurs
  * @returns {undefined}
  */
  componentWillUnmount() {
    // On unmount, clear the state to conserve memory
    this.setState({ dataRetentionManagementData: [] });

    // Clearing the redux state for data retention management data
    this.props.dataRetentionManagementActions.fetchDataRetentionManagementData();
  }  

  refreshGrid(selectedData=[]) {  
    this.getDataRetentionManagementData();

    const randomNumber = parseInt(Math.random() * (99999999 - 1)) + 1;
      if (
        selectedData &&
        Array.isArray(selectedData) &&
        selectedData.length > 0
      ) {
        this.setState({
          refreshGriddataRetentionManagementData: {
            refreshGridOnCompletedAction: randomNumber
          },
        });
      } else {
        this.setState({
          refreshGriddataRetentionManagementData: {
            refreshGridOnCompletedAction: randomNumber
          },
        });
      }
  }

  handleDataRetentionActions(statusId) {      
    let selectedData = this.state.gridCurrentState.filter(gridData => gridData.reitSelection)
    .map(gridData => {
        return {
            dataPurgeID: gridData.dataPurgeID,
            reportPeriodID : gridData.reportPeriodID,
            reitid : gridData.reitid,
            dataPurgeReportPeriodID:gridData.dataPurgeReportPeriodID,
            dataPurgeStatusID:statusId
        }
    });
    var requestData = [];
    selectedData.forEach(function (item) {
      requestData.push({
        dataPurgeID: item.dataPurgeID,
        reportPeriodID : item.reportPeriodID,
        reitid : item.reitid,
        dataPurgeReportPeriodID:item.dataPurgeReportPeriodID,
        dataPurgeStatusID:item.statusId     
      });
    });

    const requestDataVal = {
      "clientId": this.props.clientId,
      "statusId": statusId,    
      "updateDataPurgeReportStatusList": selectedData,
  }; 
      this.props.dataRetentionManagementActions.approveDataDeletion(requestDataVal).then(() => {     
        this.refreshGrid(selectedData);
      });
    }

  handleGridChanges(gridRowData) {
    let rowData = [];
    rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
      return {
        reitid: x.reitid,
        reportPeriodID : x.reportPeriodID,
        reitName: x.reitName,
        reportPeriodDescription: x.reportPeriodDescription,
        scheduledDeletionDate: x.scheduledDeletionDate,
        actualDeletionDate: x.actualDeletionDate,
        deletionStatus: x.deletionStatus,
        approvedBy: x.approvedBy,
        approvedDateTime: x.approvedDateTime,
        reitSelection: x.reitSelection,
        dataPurgeReportPeriodID:x.dataPurgeReportPeriodID,
        dataPurgeStatusID:x.dataPurgeStatusID,
        dataPurgeID:x.dataPurgeID,
        stage2DataPurgeStatusID:x.stage2DataPurgeStatusID,
        stage3DataPurgeStatusID:x.stage3DataPurgeStatusID,
      }
    });
    this.setState({ gridCurrentState: rowData });
  }

  /**
      * Set AG-Grid column state   
      * @returns {undefined}
      */
  setGridColumnState() {
    var columnState = localStorage.getItem('REITSuiteDataRetentionManagementColumnState');
    if (columnState) {
      this.setState({ gridColumnState: JSON.parse(columnState) });
    }
    else {
      this.setState({ gridColumnState: [{ colId: '-1' }] });
    }
  }

  getDataRetentionManagementData() {
    this.props.dataRetentionManagementActions.fetchDataRetentionManagementData(
        this.props.clientId,
    );
}
  /**
     * Handle Grid column state chagne
     * @returns {undefined}
     */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
}

  handleExportDataDeletionSummary() {
    this.props.dataRetentionManagementActions.exportDataDeletionSummary(
      this.props.clientId,
      this.state.approvedDates
    );
  }

  render() {
    const { clientId } = this.props;
    return (
      <>
       <DataRetentionManagementHeader
            clientId={clientId}
            gridCurrentState={this.state.gridCurrentState}
            isSystemAdmin={authActions.isSystemAdministrator(this.props.currentUserAuthorizations)}
            isEngagementAdminAndDataSteward={authActions.isEngagementAdministratorAndDataSteward(this.props.currentUserAuthorizations, this.props.clientId)}
            isEngagementUser={authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)}
            handleExportDataDeletionSummary={this.handleExportDataDeletionSummary} 
            handleDataRetentionActions = {this.handleDataRetentionActions}
          />     
        
       <DataRetentionManagementTable
          dataRetentionManagementData={this.state.dataRetentionManagementData}
          handleGridChanges={this.handleGridChanges}
          gridColumnState={this.state.gridColumnState}
          handleGridColumnStateChange={this.handleGridColumnStateChange}
          refreshGriddataRetentionManagementData={this.state.refreshGriddataRetentionManagementData}
        />
        {(this.props.dataRetentionManagementData == null || this.props.dataRetentionManagementData.length === 0) &&
          <div>No REITs and report period found.</div>}
      </>
    );
  }
}

DataRetentionManagementContainer.propTypes = {
  clientId: PropTypes.number,
  currentUserAuthorizations: PropTypes.array,
  actions: PropTypes.object,
  dataRetentionManagementData: PropTypes.array,
  authActions: PropTypes.object,
  currentUserId: PropTypes.string,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  let cid = Number.parseInt(ownProps.router.params.clientId); 
  if (isNaN(cid)) {
    cid = 0;
  }
  return {
      clientId: cid,
      currentUserAuthorizations: state.currentUserAuthorizations,
    dataRetentionManagementData: state.dataRetentionManagementData,
    currentUserId:  state.authentication.currentUser
  };
}
/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    dataRetentionManagementActions: bindActionCreators(dataRetentionManagementActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
  };
}

  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
      reduxForm({ form: "dataRetentionManagementForm", enableReinitialize: true })(DataRetentionManagementContainer)
    )
  )