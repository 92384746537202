import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MotifProgressBar } from "@ey-xd/motif-react";

const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    for (let i = 0; i < a.length; ++i) {

        if (!b.includes(a[i])) return false;
    }

    return true;
};

const previousAnswersChanged = (item, previousChecklistFound) => {

    if (!previousChecklistFound) {
        return false;
    }

    if (item.itemType.toLowerCase() === "arqcstockfmvtable") {
        for (let i = 0; i < item.previousAnswers.length; i++) {
            if (item.selectedAnswers.length < i + 1) {
                return false;
            }

            if (item.selectedAnswers[i].fmvOfStockOwned !== item.previousAnswers[i].fmvOfStockOwned) {
                return true;
            }

            if (item.selectedAnswers[i].percentOfStockOwned !== item.previousAnswers[i].percentOfStockOwned) {
                return true;
            }
        }

        return false;
    }

    if (item.selectedAnswers.length > 0 && !arraysEqual(item.selectedAnswers, item.previousAnswers)) {
        return true;
    }

    if (item.selectedAnswers.length === 0 && !arraysEqual(item.defaultAnswers, item.previousAnswers)) {
        return true;
    }

    return false;
};

// Check if question is Excluded from Counters for all users
const isQuestionExcludedFromCounterForAllUsers = (question) => {
    // Exclude Date Completed question
    if(question.isEnabled === false && question.systemRule && question.systemRule.name === 'DateStamp'
        && question.systemRule.isDisabledOnSystemAnswer
        && question.title.endsWith('Date Completed (Answered by REITSuite when submitted to EY)')) {
            return true;
    }

    return false;
};

const checklistCounters = ({ checklist, disabledSections, isExternal, previousChecklistFound, shouldHideChangedAnswers, isSectionProgress, handlePsqCompletionRequirement }) => {
    if (checklist) {
        let totalQuestions = 0;
        let answeredQuestions = 0;
        let changedQuestions = 0;

        if (checklist.content !== undefined) {
            for (let s = 0; s < checklist.content.length; s++) {
                if (checklist.content[s].items !== undefined && (disabledSections === undefined || disabledSections.find(x => x === checklist.content[s].id) === undefined)) {

                    if (checklist.content[s].type === "ArqcExhibitB") {
                        totalQuestions++;

                        if (checklist.content[s].items !== undefined && checklist.content[s].items.length > 0 && checklist.content[s].items[0].selectedAnswers.length > 0) {
                            answeredQuestions++;
                        }

                        continue;
                    }

                    for (let i = 0; i < checklist.content[s].items.length; i++) {
                        if ((checklist.content[s].items[i].itemType.toLowerCase() === "button"
                            || checklist.content[s].items[i].itemType.toLowerCase() === "menu"
                            || checklist.content[s].items[i].itemType.toLowerCase() === "plaintext"
                            || checklist.content[s].items[i].itemType.toLowerCase() === "psqsection2propertymanager"
                            || checklist.content[s].items[i].itemType.toLowerCase() === "psqsection2additionalservices"
                            || checklist.content[s].items[i].itemType.toLowerCase() === "arqcstockfmvtable"
                            || checklist.content[s].items[i].itemType.toLowerCase() === "arqcexhibitbmortgagetable")
                            && (checklist.content[s].items[i].isExcludedFromCounter === undefined
                                || checklist.content[s].items[i].isExcludedFromCounter === false
                                || isExternal === false)
                            && !isQuestionExcludedFromCounterForAllUsers(checklist.content[s].items[i])) {

                            totalQuestions++;

                            if (previousAnswersChanged(checklist.content[s].items[i], previousChecklistFound)) {
                                changedQuestions++;
                            }

                            if ((checklist.content[s].items[i].selectedAnswers !== undefined
                                && checklist.content[s].items[i].selectedAnswers.length > 0
                                && checklist.content[s].items[i].selectedAnswers.toString().length > 0)
                                || (checklist.content[s].items[i].defaultAnswers !== undefined
                                    && checklist.content[s].items[i].defaultAnswers.length > 0
                                    && checklist.content[s].items[i].defaultAnswers.toString().length > 0)) {
                                answeredQuestions++;

                                if (checklist.content[s].items[i].itemType.toLowerCase() === "arqcstockfmvtable") {
                                    // don't count arqcstockfmvtable question unless
                                    let isAnswered = false;
                                    for (let a = 0; a < checklist.content[s].items[i].selectedAnswers.length; a++) {
                                        if ((checklist.content[s].items[i].selectedAnswers[a].fmvOfStockOwned !== null
                                            && checklist.content[s].items[i].selectedAnswers[a].fmvOfStockOwned !== "")
                                            || (checklist.content[s].items[i].selectedAnswers[a].percentOfStockOwned !== null
                                                && checklist.content[s].items[i].selectedAnswers[a].percentOfStockOwned !== "")) {
                                            isAnswered = true;
                                        }
                                    }

                                    if (!isAnswered) {
                                        answeredQuestions--;
                                    }
                                }
                            }
                        }

                        if (checklist.content[s].items[i].children !== undefined) {
                            for (let c = 0; c < checklist.content[s].items[i].children.length; c++) {
                                let isVisible = false;
                                let item = checklist.content[s].items[i].children[c];
                                let parentItem = checklist.content[s].items[i];
                                if (item.enabledByParentAnswers === undefined || item.enabledByParentAnswers.length === 0) {
                                    isVisible = true;
                                } else {
                                    // determine whether the item is enabled by the parent item
                                    for (let i = 0; i < item.enabledByParentAnswers.length; i++) {
                                        if ((parentItem.selectedAnswers === undefined || parentItem.selectedAnswers.length === 0) &&
                                            parentItem.defaultAnswers.find(x => x === item.enabledByParentAnswers[i]) !== undefined) {
                                            isVisible = true;
                                            break;
                                        } else if (parentItem.selectedAnswers.find(x => x === item.enabledByParentAnswers[i]) !== undefined) {
                                            isVisible = true;
                                            break;
                                        }
                                    }
                                }

                                if ((checklist.content[s].items[i].children[c].itemType.toLowerCase() === "button"
                                    || checklist.content[s].items[i].children[c].itemType.toLowerCase() === "menu"
                                    || checklist.content[s].items[i].children[c].itemType.toLowerCase() === "plaintext"
                                    || checklist.content[s].items[i].children[c].itemType.toLowerCase() === "arqcstockfmvtable")
                                    && (checklist.content[s].items[i].isExcludedFromCounter === undefined
                                        || checklist.content[s].items[i].isExcludedFromCounter === false
                                        || isExternal === false)
                                    && isVisible
                                    && !isQuestionExcludedFromCounterForAllUsers(checklist.content[s].items[i].children[c])) {

                                    totalQuestions++;

                                    if (previousAnswersChanged(checklist.content[s].items[i].children[c], previousChecklistFound)) {
                                        changedQuestions++;
                                    }

                                    if ((checklist.content[s].items[i].children[c].selectedAnswers.length > 0
                                        && checklist.content[s].items[i].children[c].selectedAnswers.toString().length > 0)
                                        || (checklist.content[s].items[i].children[c].defaultAnswers.length > 0
                                            && checklist.content[s].items[i].children[c].defaultAnswers.toString().length > 0)) {
                                        answeredQuestions++;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        let checklistCompletionPercentage = (answeredQuestions/totalQuestions) * 100;

        useEffect(() => {
            if(handlePsqCompletionRequirement) {
                handlePsqCompletionRequirement(checklistCompletionPercentage);
            }
        }, [checklistCompletionPercentage]);

        return (
            <React.Fragment>
            { 
                isSectionProgress ?
                (
                    <div className="ml-auto" data-testid="progressBarSectionNameStatusIndicator">
                        { totalQuestions === 0 
                            ? <MotifProgressBar className="checklist-section-progress-bar invisible" circle={true} value={100}/>
                            : <MotifProgressBar className="checklist-section-progress-bar" circle={true} value={Math.round(answeredQuestions/totalQuestions * 100)}/>
                        }
                    </div>
                )
                :
                (<div className="d-flex checklistPreview">
                        <div>
                            <span className="questionnaireSummary" data-testid="lblQuestionsCompleted">Questions Completed: </span>
                            <span>{answeredQuestions}/{totalQuestions}</span>
                        </div>
                        { !shouldHideChangedAnswers &&
                        <div className="ml-3">
                            <span className="questionnaireSummary" data-testid="lblChangedAnswers">Changed Answers: </span>
                            <span>{changedQuestions}</span>
                        </div>}
                </div>)
            }
            </React.Fragment>
        );
    } else {
        return (<div />);
    }
};

checklistCounters.propTypes = {
    checklist: PropTypes.object,
    disabledSections: PropTypes.array,
    isExternal: PropTypes.bool,
    previousChecklistFound: PropTypes.array,
    shouldHideChangedAnswers: PropTypes.bool,
    isSectionProgress: PropTypes.bool,
    handlePsqCompletionRequirement: PropTypes.func
};

export default checklistCounters;