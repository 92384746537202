import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_ATTACHMENTDETAIL_SUCCESS action and sets client account data in the store
 * @param {Object} [state] The client account data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The client account data
 */
export default function attachmentDetailReducer(state = initialState.attachmentDetails, action) {
    if (action.type == actions.LOAD_ATTACHMENTDETAIL_SUCCESS && Array.isArray(action.attachmentDetails)) {
        return action.attachmentDetails;
    } else if (action.type == actions.LOAD_ATTACHMENTDETAIL_FAILURE) {
        return initialState.attachmentDetails;
    } else {
        return state;
    }
}