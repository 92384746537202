import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_TB_SUMMARY_SUCCESS action and sets the summary in the store
 * @param {Object} [state] The summary currently in the store, or the initial state of the summary
 * @param {Object} action The action to be handled
 * @returns {Object} The summary
 */
export default function trialBalanceSummaryReducer(state = initialState.trialBalanceSummary, action) {
    if (action.type === actions.LOAD_TB_SUMMARY_SUCCESS) {
        return action.trialBalanceSummary;
    } else if (action.type == actions.UNLOAD_PERIOD) {
        return initialState.trialBalanceSummary;
    } else {
        return state;
    }
}