import React from 'react';
import { MotifIcon, MotifIconButton, MotifLabel, MotifTooltip } from '@ey-xd/motif-react';
import { IconoirEditPencil } from '@ey-xd/motif-icon';

export default (props) => {
    const onEditClick = () => {
        props.handleEditClick(props.data);
    };
    let reitName = props.data.reitName;
    let value = props.data.reitTypeID === 1 ? "Mortgage" : "Equity";
    let threshold = props.data.percentageThreshold + "% / $" + props.data.balanceThreshold;
    return (
        <div>
             {(props.column.colId == "reitName" && reitName.length > 25) &&  
                <div>
                    <span>{reitName.substring(0, 25)}</span>
                    <span className="ml-1" style={{ cursor: 'pointer' }}>
                    <MotifTooltip
                        id="relatedReitsTooltip"
                        allowClick
                        allowHover
                        placement="auto"
                        trigger={<span style={{ whiteSpace: 'nowrap' }}>...</span>}
                        variant="alt"
                        style={{ margin: '0px' }}
                        hideCloseButton={true}
                    >
                        <span style={{ margin: '0px', padding: '0px', maxWidth: '1000px' }}>{reitName}</span>
                    </MotifTooltip>
                    </span>
              </div>
            }
            {(props.column.colId == "reitName" && reitName.length <= 25) && 
                reitName
            }
            {(props.column.colId == "type") &&  
                value
            }
            {(props.column.colId == "percentageThreshold") &&  
                threshold
            }
            {(props.column.colId == "action") &&                   
                <div className="justify-content-end align-items-center mt-2">
                <span style={{ width:'50%'} } >
                    <MotifIconButton aria-label="Edit this REIT" type="button"
                        onClick={onEditClick}>
                        <MotifIcon iconFunction={IconoirEditPencil} size='24' fill='none' title="Edit this REIT" />
                    </MotifIconButton>
                </span>
                </div>
            }
        </div>
    )
};