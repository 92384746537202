import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import {
  MotifButton,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from '@ey-xd/motif-react';
import { addAttribute } from '../../common/Attribute';

const CustomPSQTemplatePreviewTemplateValidationMessageModal = ({
  showCustomPSQTemplatePreviewTemplateValidationMessageModal,
  handleSubmit,
  toggleCustomPSQTemplatePreviewTemplateValidationMessageModal,
  validationMessage,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Validation message modal',
    ariaLabel: 'Close Validation message modal',
    dataTestid: 'close',
  };

  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  return (
    <MotifModal
      variant="alt"
      show={showCustomPSQTemplatePreviewTemplateValidationMessageModal}
      onClose={() =>
        toggleCustomPSQTemplatePreviewTemplateValidationMessageModal()
      }
      size="xl"
      data-testid="customPSQTemplatePreviewTemplateValidationMessageModal"
    >
      <MotifModalHeader
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <span className="ml-2">Preview Template</span>
        </HeaderWrapper>
      </MotifModalHeader>

      <MotifModalBody>
        <form onSubmit={handleSubmit} className="auth">
          <span className="checklistActionMsg">{validationMessage}</span>
          <br />
        </form>
      </MotifModalBody>

      <MotifModalFooter>
        <MotifButton
          type="button"
          onClick={() =>
            toggleCustomPSQTemplatePreviewTemplateValidationMessageModal()
          }
          className="right"
          data-testid="Submit"
        >
          OK
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

CustomPSQTemplatePreviewTemplateValidationMessageModal.propTypes = {
  showCustomPSQTemplatePreviewTemplateValidationMessageModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggleCustomPSQTemplatePreviewTemplateValidationMessageModal: PropTypes.func,
  validationMessage: PropTypes.object,
};

export default reduxForm({
  form: 'customPSQTemplatePreviewTemplateValidationMessageModal',
  enableReinitialize: true,
})(CustomPSQTemplatePreviewTemplateValidationMessageModal);
