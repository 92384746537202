import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import * as parsers from "../../scripts/formParsers";
import $ from 'jquery';
import { FileField } from "../shared/formFields";

import {
    MotifFormField,
    MotifSearch,
    MotifLabel,
    MotifToggleSwitch,
    MotifSelect,
    MotifOption,
    MotifButton,
    MotifIconButton,
    MotifIcon,
    MotifInput
} from '@ey-xd/motif-react';

import { IconoirTrash, IconoirDownload } from '@ey-xd/motif-icon';
import { addAttribute } from "../../common/Attribute";

/**
 * CoreItems component
 * @extends {React.Component}
 */
class CoreItems extends React.Component {
    /**
     * Creates a new TrialBalanceDownloadLink
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.state = {tbPurposeIndex : 0, properties: null};
    }

    componentDidMount() {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.properties != this.props.properties){
            this.setState({...this.state, properties: this.props.properties});
        }
        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        let closeStatus = "";
        if (!this.props.canEdit) {
            closeStatus = "- Testing Closed";
        }
        if (!this.props.canReport) {
            closeStatus = "- Reporting Closed";
        }

        const clearFileInput = (type) => {
            switch (type) {
                case "REIT":
                    $('input[name=tbFileToUpload]').val("");
                    break;
                case "PROPERTY":
                    $('input[name=propertyTbFileToUpload]').val("");
                    break;
                case "REPORT":
                    $('input[name=finalReportFile]').val("");
                    break;
            }
        };

        const clearReitFileInput = () => {
            clearFileInput("REIT");
        };

        const clearPropertyFileInput = () => {
            clearFileInput("PROPERTY");
        };

        const clearReportFileInput = () => {
            clearFileInput("REPORT");
        };

        const selectAllText = () => {
            $(document).on('click', 'input[type=text]', function () { this.select(); });
        };

        let propertyTbUploadLabelText = "Property Trial Balance";
        if (this.props.tbMode && this.props.tbMode === 2) {
            propertyTbUploadLabelText = "Consolidated Trial Balance";
        }

        const propTbSelect = v => {
            const tbPurposeIndex = parseInt(v);

            this.setState(
                { tbPurposeIndex },
                () => this.props.handleTrialBalancePurposeDropdownSelected(tbPurposeIndex));
        }
        
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h5>Core Items{closeStatus}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: 'bold', fontSize: '14px', width: '20%' }}>Consolidated Mappings</td>
                                    <td style={{ width: '15%', minWidth: '150px' }} />
                                    <td style={{ width: '1%' }} />
                                    <td style={{ width: '34%' }} />
                                    <td className="tcenter" style={{ width: '15%' }}>
                                        {(this.props.mappingsDateUploaded && <span><b>Updated:</b> <FormattedDate value={this.props.mappingsDateUploaded} /></span>) || null}
                                    </td>
                                    <td className="tright" style={{ width: '15%' }}>
                                        {(this.props.charts && this.props.charts.length > 0) && (this.props.chartMappings && this.props.chartMappings.length > 0) && (<span style={{ marginRight: 45 }}>                                           
                                            <MotifIconButton aria-label="Download Consolidated Mappings" type="button"
                                                onClick={this.props.handleMappingsDownload}>
                                                <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' title="Download Consolidated Mappings" />
                                            </MotifIconButton>
                                        </span>) ||
                                            (<span style={{ marginRight: 45 }}>                                            
                                            <MotifIconButton aria-label="Can't Download Consolidated Mappings"
                                                type="button" 
                                                disabled>
                                                <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' />
                                            </MotifIconButton>
                                            </span>)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold', fontSize: '14px' }}>Consolidated Trial Balance &amp; Mappings</td>
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    <td className="tright">
                                        {(this.props.lastConsolidatedTrialBalance) && (<span>     
                                            <MotifIconButton aria-label="Download Consolidated TB &amp; Mappings" type="button"
                                                onClick={this.props.handleConsolidatedTrialBalanceDownload}>
                                                <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' title="Download Consolidated TB &amp; Mappings" />
                                            </MotifIconButton>

                                            <span style={{ marginRight: 45 }} /> </span>) ||
                                            (<span>                                                
                                                <MotifIconButton aria-label="Can't Download Consolidated TB &amp; Mappings" type="button"
                                                     disabled>
                                                    <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' />
                                                </MotifIconButton>
                                                <span style={{ marginRight: 45 }} /></span>)}
                                    </td>
                                </tr>
                                {this.props.tbMode === 1 && <tr>
                                    <td style={{ fontWeight: 'bold', fontSize: '14px' }}>REIT Trial Balance</td>
                                    <td />
                                    <td />
                                    <td className="text-nowrap">
                                        <div>
                                            <span>
                                                <FileField
                                                    name="tbFileToUpload"
                                                    onChange={this.props.handleTbFileChange}
                                                    className="form-control tbinput"
                                                    disabled={!this.props.canEdit}
                                                            accept=".xlsx" />
                                            </span>
                                            <span>
                                                <MotifButton type="button" onClick={this.props.handleUploadTrialBalance} variant="primary" className="ml-3" disabled={!this.props.tbFile} onMouseUp={clearReitFileInput}>Upload</MotifButton>
                                            </span>
                                        </div>
                                                </td>
                                    <td className="tcenter">
                                        {(this.props.trialBalanceDateUploaded && <span><b>Uploaded:</b> <FormattedDate value={this.props.trialBalanceDateUploaded} /></span>) || null}
                                    </td>
                                    <td className="tright">
                                        {this.props.trialBalanceExists && (<span>                                            
                                            <MotifIconButton aria-label="Download REIT TB" type="button"
                                                onClick={this.props.handleTrialBalanceDownload}>
                                                <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' title="Download REIT TB" />
                                            </MotifIconButton>
                                            {this.props.canEdit === true &&
                                                <MotifIconButton aria-label="Delete REIT TB" type="button"
                                                    onClick={this.props.handleTrialBalanceDelete}>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title="Delete REIT TB" />
                                                </MotifIconButton>
                                                ||
                                                <MotifIconButton aria-label="Can't Delete REIT TB" type="button"
                                                    disabled>
                                                     <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' />
                                                </MotifIconButton>
                                            } </span>) ||
                                            (<span>                                                
                                                <MotifIconButton aria-label="Can't Download REIT TB" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' />
                                                </MotifIconButton>
                                                <MotifIconButton aria-label="Can't Delete REIT TB" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' />
                                                </MotifIconButton>
                                            </span>)}
                                    </td>
                                </tr> || null}
                                <tr>
                                    <td style={{ fontWeight: 'bold', fontSize: '14px', width: '15%'}}>{propertyTbUploadLabelText}
                                        {this.state.properties != null && 
                                            <MotifFormField style={{marginBottom: '50px'}}>
                                                <MotifLabel>Property</MotifLabel>
                                                <MotifSelect
                                                    aria-label="Select property dropdown"
                                                    value={String(this.state.tbPurposeIndex)}
                                                    onChange={propTbSelect}>
                                                    {this.state
                                                        .properties
                                                        .map((prop, idx) =>
                                                            <MotifOption key={idx} value={String(idx)}>
                                                                {prop.propertyName}
                                                            </MotifOption>)
                                                    }
                                                </MotifSelect>
                                            </MotifFormField>}
                                    </td>
                                    <td style={{ fontWeight: 'bold', fontSize: '14px', width: '15%'}}>
                                        <MotifFormField style={{marginTop: '50px'}}>
                                            <MotifLabel>Ownership %</MotifLabel>
                                            <MotifInput style={{ width: '125px'}}
                                                type="text" id="pctOfO"
                                                onFocus={selectAllText}
                                                onChange={this.props.handlePctChanged}
                                                disabled={!this.props.canEdit}
                                                value={parsers.percentageOfOwnership(this.props.percentageOfOwnership.toString())}
                                                hideClearButton />
                                        </MotifFormField>
                                    </td>
                                    <td />
                                    <td className="text-nowrap">
                                        <div>
                                            <span style={{marginTop: '50px'}}>
                                                <FileField
                                                    name="propertyTbFileToUpload"
                                                    onChange={this.props.handlePropertyTbFileChange}
                                                    className="form-control tbinput"
                                                    disabled={!this.props.canEdit}
                                                            accept=".xlsx" />
                                            </span>
                                            <span style={{marginTop: '50px'}}>
                                                <MotifButton type="button" onClick={this.props.handleUploadPropertyTrialBalance}
                                                    variant="primary" className="ml-3" disabled={!this.props.propertyTbFile || this.props.propertyId === null}
                                                    onMouseUp={clearPropertyFileInput}>Upload</MotifButton>
                                            </span>
                                        </div>
                                    </td>
                                    <td className="tcenter">
                                        {(this.props.propertyTrialBalanceDateUploaded && <span style={{marginTop: '50px'}}><b>Uploaded:</b> <FormattedDate value={this.props.propertyTrialBalanceDateUploaded} /></span>) || null}
                                    </td>
                                    <td className="tright">
                                        {this.props.propertyTrialBalanceExists && (<span style={{marginTop: '50px'}}>                                            
                                            <MotifIconButton aria-label="Download Property TB" type="button"
                                                onClick={this.props.handlePropertyTrialBalanceDownload}>
                                                <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' title="Download Property TB" />
                                            </MotifIconButton>
                                            {this.props.canEdit === true &&
                                                <MotifIconButton aria-label="Delete Property TB" type="button"
                                                    onClick={this.props.handlePropertyTrialBalanceDelete}>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title="Delete Property TB" />
                                                </MotifIconButton>
                                                ||                                                                                               
                                                <MotifIconButton aria-label="Can't Delete Property TB" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' />
                                                </MotifIconButton>
                                            }
                                        </span>) ||
                                            (<span style={{marginTop: '50px'}}>
                                                <MotifIconButton aria-label="Can't Download Property TB" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' />
                                                </MotifIconButton>
                                                <MotifIconButton aria-label="Can't Delete Property TB" type="button"
                                                        disabled>
                                                        <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' />
                                                    </MotifIconButton>
                                            </span>)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold', fontSize: '14px' }}>Final Report for the Period</td>
                                    <td />
                                    <td />
                                    <td className="text-nowrap">
                                        <div>
                                            <span>
                                                <FileField
                                                    name="finalReportFile"
                                                    onChange={this.props.handleReportFileChange}
                                                    className="form-control tbinput"
                                                    disabled={!this.props.canReport}
                                                    accept=".docx,.pdf"
                                                        />
                                            </span>
                                            <span>
                                                <MotifButton type="button"
                                                    onClick={this.props.handleUploadReport}
                                                    variant="primary" className="ml-3"
                                                    disabled={!this.props.finalReportFile}
                                                            onMouseUp={clearReportFileInput}>Upload</MotifButton>
                                            </span>
                                        </div>
                                    </td>
                                    <td className="tcenter">
                                        {(this.props.finalReportDateUploaded && <span><b>Uploaded:</b> <FormattedDate value={this.props.finalReportDateUploaded} /></span>) || null}
                                    </td>
                                    <td className="tright">
                                        {this.props.finalReportExists && (<span>                                            
                                            <MotifIconButton aria-label="Download Final Report" type="button"
                                                onClick={this.props.handleReportDownload}>
                                                <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' title="Download Final Report" />
                                            </MotifIconButton>
                                            {this.props.canReport === true &&                                                
                                                <MotifIconButton aria-label="Delete Final Report" type="button"
                                                    onClick={this.props.handleReportDelete}>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title="Delete Final Report" />
                                                </MotifIconButton>
                                                ||
                                                <MotifIconButton aria-label="Can't Delete Final Report" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' />
                                                </MotifIconButton>
                                            } </span>) ||
                                            (<span>
                                               <MotifIconButton aria-label="Can't Download Final Report" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirDownload} size='24' fill='none' />
                                                </MotifIconButton>
                                                <MotifIconButton aria-label="Can't Delete Final Report" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' />
                                                </MotifIconButton>
                                            </span>)}
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ fontWeight: 'bold', fontSize: '14px', width: '20%' }}>Delete All Trial Balance Data for Period</td>
                                    <td style={{ width: '15%', minWidth: '150px' }} />
                                    <td style={{ width: '1%' }} />
                                    <td style={{ width: '34%' }} />
                                    <td className="tcenter" style={{ width: '15%' }}>
                                        
                                    </td>
                                    <td className="tright" style={{ width: '15%' }}>                                        
                                        {(this.props.lastConsolidatedTrialBalance && this.props.canEdit === true) &&
                                            (<span style={{ marginLeft: 52 }}>                                                
                                                <MotifIconButton aria-label="Delete Period TB Data" type="button"
                                                    onClick={this.props.handleAllTrialBalancesDelete}>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title="Delete Period TB Data" />
                                                </MotifIconButton>
                                            </span>) ||
                                            (<span style={{ marginLeft: 52 }}>                                           
                                                <MotifIconButton aria-label="Can't Delete Period TB Data" type="button"
                                                    disabled>
                                                    <MotifIcon iconFunction={IconoirTrash} size='24' fill='none'  />
                                                </MotifIconButton>
                                            </span>)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


CoreItems.propTypes = {
    handleTrialBalanceDownload: PropTypes.func,
    handleReportDownload: PropTypes.func,
    handleTbFileChange: PropTypes.func,
    handleReportFileChange: PropTypes.func,
    trialBalanceExists: PropTypes.bool,
    finalReportExists: PropTypes.bool,
    tbFile: PropTypes.object,
    finalReportFile: PropTypes.object,
    handleUploadTrialBalance: PropTypes.func,
    handleUploadReport: PropTypes.func,
    handleTrialBalanceDelete: PropTypes.func,
    handleReportDelete: PropTypes.func,
    canReport: PropTypes.bool.isRequired,
    trialBalanceDateUploaded: PropTypes.string,
    propertyTrialBalanceDateUploaded: PropTypes.string,
    finalReportDateUploaded: PropTypes.string,
    handleTrialBalancePurposeDropdownSelected: PropTypes.func.isRequired,
    propertyId: PropTypes.number,
    handlePropertyTbFileChange: PropTypes.func,
    propertyTbFile: PropTypes.object,
    handleUploadPropertyTrialBalance: PropTypes.func,
    propertyTrialBalanceExists: PropTypes.bool,
    handlePropertyTrialBalanceDelete: PropTypes.func,
    handlePropertyTrialBalanceDownload: PropTypes.func,
    handleConsolidatedTrialBalanceDownload: PropTypes.func,
    mappingsDateUploaded: PropTypes.string,
    mappingsExist: PropTypes.bool,
    handleMappingsDownload: PropTypes.func,
    handleMappingsDelete: PropTypes.func,
    handleAllTrialBalancesDelete: PropTypes.func,
    percentageOfOwnership: PropTypes.string,
    handlePctChanged: PropTypes.func,
    tbMode: PropTypes.number,
    canEdit: PropTypes.bool,
    lastConsolidatedTrialBalance: PropTypes.object,
    properties: PropTypes.array,
    chartMappings: PropTypes.array,
};

export default CoreItems;
