import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientList from "../../components/clients/ClientList";
import * as clientActions from "../../actions/clientActions";
import * as authActions from "../../actions/authActions";
import ReactivateClientModal from "../../components/clients/ReactivateClientModal";
import * as dataPurgeActions from "../../actions/dataPurgeActions";
import DeactivateClientDisplayErrorMessageModal from "../../components/clients/DeactivateClientDisplayErrorMessageModal";
import * as Constants from '../../constants/other';
import ClientTypeSwitchingConfirmationMessageModal from "../../components/clients/ClientTypeSwitchingConfirmationMessageModal";
import ClientTypeSwitchingErrorMessageModal from "../../components/clients/ClientTypeSwitchingErrorMessageModal";

/**
 * ClientsPage container component
 * @extends {React.Component}
 */
export class ClientsPage extends React.Component {
    /**
        * Creates a new ClientsPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);

        this.state = {
            clientSearchText: "", filteredClients: [], showInactive: false,
            showReactivateClientModal: false, clientData: {}, clientsAreLoaded: false,
            currentClientCheckboxHtmlId: '', gridCurrentState: [], showDeactivateErrorMessageModal: false,
            showClientSwitchingErrorMessageModal: false, showClientSwitchingMessageModal: false
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.reloadClients = this.reloadClients.bind(this);
        this.handleShowInactive = this.handleShowInactive.bind(this);
        this.handleToggleActiveFlag = this.handleToggleActiveFlag.bind(this);
        this.handleReactivateClient = this.handleReactivateClient.bind(this);
        this.toggleShowReactivateClientModal = this.toggleShowReactivateClientModal.bind(this);
        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.toggleShowDeactivateClientModal = this.toggleShowDeactivateClientModal.bind(this);
        this.toggleShowClientSwitchingModal = this.toggleShowClientSwitchingModal.bind(this);
        this.toggleShowClientSwitchingErrorModal = this.toggleShowClientSwitchingErrorModal.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.loadAllClients();
        this.setFilter();
        this.resetGridColumnState();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.clients !== this.props.clients && this.props.clients) {
            const list = Object.assign([], this.props.clients);
            //this.setState({ filteredClients: list });
            this.setFilter(list);
            this.setState({ clientsAreLoaded: true });
        }
    }

    componentWillUnmount() {
        this.setState({ showInactive: false });
    }

    reloadClients(reloadClients) {
        if (reloadClients) {
            this.props.actions.loadAllClients();
        }
    }

    handleSearchChange(e) {
        this.setState({ clientSearchText: e.target.value }, this.setFilter);
    }

    handleClearSearchText(e) {
        this.setState({ clientSearchText: "" }, this.setFilter);
    }

    handleShowInactive(e) {
        this.setState({ showInactive: !this.state.showInactive }, this.setFilter);
    }

    handleToggleActiveFlag(e, clientId, clientName, clientLegalName) {
        
        const clientData = { "id": clientId, "name": clientName, "legalName": clientLegalName };

        if (e.target.checked) {
            this.setState({ clientData: clientData, currentClientCheckboxHtmlId: e.target.id }, this.toggleShowReactivateClientModal());
            return;
        }
        else {
            this.props.dataPurgeActions.getdataPurgeApprovedStatus(clientId).then(
                (responseData) => {
                    if (responseData && responseData.returnCode == 0) { // If there are report periods that have not been approved for deletion as per Data Retention Policy
                        this.setState({ showDeactivateErrorMessageModal: true, currentClientCheckboxHtmlId: e.target.id });
                        return;
                    }
                    this.props.actions.toggleActiveFlagAndReloadClients(clientId);
                });
        }

    }

    toggleShowDeactivateClientModal() {
        document.getElementById(this.state.currentClientCheckboxHtmlId).checked = true;
        this.setState({ currentClientCheckboxHtmlId: '' });
        this.setState({ showDeactivateErrorMessageModal: false });
    }

    toggleShowClientSwitchingModal() {
        // document.getElementById(this.state.currentClientCheckboxHtmlId).checked = true;
        // this.setState({ currentClientCheckboxHtmlId: '' });
        this.setState({ showClientSwitchingMessageModal: false });
    }

    toggleShowClientSwitchingErrorModal() {
        // document.getElementById(this.state.currentClientCheckboxHtmlId).checked = true;
        // this.setState({ currentClientCheckboxHtmlId: '' });
        this.setState({ showClientSwitchingErrorMessageModal: false });
    }

    setFilter(clients) {
        let includeInactive = false;
        let filteredClients = clients || this.props.clients;

        if (this.props.isSysAdmin) {
            includeInactive = this.state.showInactive;
        }

        if (this.state.clientSearchText === "") {
            filteredClients = filteredClients.filter((client) => includeInactive || client.isActive);
        } else {
            filteredClients = filteredClients.filter((client) => client.name.toLowerCase().includes(this.state.clientSearchText.toLowerCase()) && (includeInactive || client.isActive));
        }

        this.setState({ filteredClients: filteredClients });
    }

    handleReactivateClient(clientId) {
        this.props.actions.toggleActiveFlagAndReloadClients(clientId);
        this.toggleShowReactivateClientModal(null, true); // Passing reActivateClient = true to distinguish that this method is called to reactivate the client
    }

    toggleShowReactivateClientModal(periodId, reActivateClient) {
        if (this.state.currentClientCheckboxHtmlId !== '' && !reActivateClient) { // Client that is being currently activated/deactivated
            document.getElementById(this.state.currentClientCheckboxHtmlId).checked = false; // Resetting the checked attribute to 'False' if the user decides not to activate the Client
            this.setState({ currentClientCheckboxHtmlId: '' }); // Resetting the state of currentClientCheckboxHtmlId once the user decides not to activate the Client
        } else if (reActivateClient) {
            this.setState({ currentClientCheckboxHtmlId: '' }); // Resetting the state of currentClientCheckboxHtmlId once the user has rectivated the client
        }

        this.setState({ showReactivateClientModal: !this.state.showReactivateClientModal });
    }

    /**
     * Handle Grid changes
    */
    handleGridChanges(gridRowData, gridRefApi) {
        let rowData = [];
        rowData =
            gridRowData &&
            gridRowData.length &&
            gridRowData.map((x) => {
                return {
                    name: x.name,
                    reitCount: x.reitCount,
                    q1Status: x.q1Status,
                    q2Status: x.q2Status,
                    q3Status: x.q3Status,
                    q4Status: x.q4Status,
                    isActive: x.isActive,
                };
            });

        this.setState({ gridCurrentState: rowData });

        if (gridRefApi) {
            gridRefApi.refreshCells();
        }
    }

    resetGridColumnState() {
        localStorage.removeItem('REITSuiteClientListColumnState');
    }
    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <ClientList clients={this.state.filteredClients}
                    handleSearchChange={this.handleSearchChange}
                    handleClearSearchText={this.handleClearSearchText}
                    clientSearchText={this.state.clientSearchText}
                    handleShowInactive={this.handleShowInactive}
                    showInactive={this.state.showInactive}
                    isSysAdmin={this.props.isSysAdmin}
                    handleToggleActiveFlag={this.handleToggleActiveFlag}
                    clientsAreLoaded={this.state.clientsAreLoaded}
                    handleGridChanges={this.handleGridChanges}
                    currentUserAuthorizations={this.props.currentUserAuthorizations}

                />
                <ReactivateClientModal showReactivateClientModal={this.state.showReactivateClientModal} toggleShowReactivateClientModal={this.toggleShowReactivateClientModal} handleReactivateClient={this.handleReactivateClient} handleCancel={this.toggleShowReactivateClientModal} clientData={this.state.clientData} />

                <DeactivateClientDisplayErrorMessageModal
                    showDeactivateErrorMessageModal={this.state.showDeactivateErrorMessageModal}
                    handleCancel={this.toggleShowDeactivateClientModal}
                    confirmationMessage={Constants.deactivateClientConfirmationMessage}
                    headerTitle="Inactivate Client"
                />
                <ClientTypeSwitchingConfirmationMessageModal
                    showClientSwitchingMessageModal={this.state.showClientSwitchingMessageModal}
                    handleCancel={this.toggleShowClientSwitchingModal}
                    confirmationMessage={Constants.clientSwitchingConfirmationMessage}
                    headerTitle="Confirm Client Type Change"
                />
                <ClientTypeSwitchingErrorMessageModal
                    showClientSwitchingErrorMessageModal={this.state.showClientSwitchingErrorMessageModal}
                    handleCancel={this.toggleShowClientSwitchingErrorModal}
                    confirmationMessage={Constants.clientSwitchingErrorMessage}
                    headerTitle="Client Type Change"
                />
            </React.Fragment>
        );
    }
}

ClientsPage.propTypes = {
    clients: PropTypes.array.isRequired,
    actions: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    isSysAdmin: PropTypes.bool,
    isEngagementAdministrator: PropTypes.bool,
    isEngagementUser: PropTypes.bool,
    dataPurgeActions: PropTypes.object,

};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    const clients = state.clients;
    return {
        clients: clients,
        currentUserAuthorizations: state.currentUserAuthorizations,
        isSysAdmin: Array.isArray(state.currentUserAuthorizations) && authActions.isSystemAdministrator(state.currentUserAuthorizations),
    };

}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(clientActions, dispatch),
        dataPurgeActions: bindActionCreators(dataPurgeActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);