import React from 'react';
import { Link } from 'react-router-dom';
import { MotifBadge, MotifCheckbox, MotifTextLink } from '@ey-xd/motif-react';

export default (props) => {
  const reit = props.node.data;
  const rowIndex = props.node.rowIndex + 2;
  const toggleActiveFlag = (e) => {
    props.handleToggleActiveFlag(e, reit);
}
  
    if (reit !== undefined) {
    return (
        <div>
            {(props.column.colId == "reitName") &&
                <div aria-label="Press ENTER to open this reit." aria-rowindex={rowIndex}>
                    <Link to={'/client/' + reit.clients[0].id +'/reit/' + reit.reitid} className='client-text-link'>
                    <MotifTextLink variant='alt'> {reit.reitName}</MotifTextLink>
                    </Link>
                    {reit.discrepancyCount > 0 && <MotifBadge variant="alt" className="ml-1" value={reit.discrepancyCount} data-testid="badgeDiscrepancyCount" /> || null}   
                    
                </div>
            }
            
            {(props.column.colId == "reportPeriodDescription") &&
                <div aria-label="Press ENTER to open this reit." aria-rowindex={rowIndex}>
                  {reit.periods.length > 0 ? reit.periods[0].reportPeriodDescription : "Not Found"}
                </div>
            }
            
            {(props.column.colId == "reportPeriodStatusDescription") &&
                <div aria-label="Press ENTER to open this reit." aria-rowindex={rowIndex}>
                  {reit.periods.length > 0 ? reit.periods[0].reportPeriodStatusDescription : "Not Found"}                  
                </div>
            }
     
            {( props.column.colId == "isActive" &&
                <div>
                        <MotifCheckbox 
                            id={`activate-client-${reit.reitid}`}
                            name="activate-reit"
                            aria-label="Activate Deactivate reit checkbox"
                            checked={(reit.isActive && "checked") || ""}
                            onChange={toggleActiveFlag}/>
                 </div>
                    ) 
                    || null
               
            } 
        </div>
    );
  } 
};
