import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the LOAD_CHECKLIST_TEMPLATE_SUCCESS action and sets data of custom psq templates to the store
 * @param {Object} [state] The custom psq template data currently in the store, or the initial state of custom psq template details
 * @param {Object} action The action to be handled
 * @returns {Object} The custom psq template data
 */

export default function customPsqTemplateDetailsReducer(
  state = initialState.customPsqTemplateDetails,
  action
) {
  switch (action.type) {
    case actions.LOAD_CHECKLIST_TEMPLATE_SUCCESS:
      return action.checklistTemplateResponse;
    default:
      return state;
  }
}
