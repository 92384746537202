import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_DISTRIBUTION_DETAIL_SUCCESS action and sets a distribution in the store
 * @param {Object} [state] The distribution currently in the store, or the initial state of distribution
 * @param {Object} action The action to be handled
 * @returns {Object} The distribution
 */
export default function distributionDetail(state = initialState.distributionDetails, action) {
    switch (action.type) {
        case actions.LOAD_DISTRIBUTION_DETAIL_SUCCESS:
            return action.distributionDetails;
        case actions.UNLOAD_TESTS:
            return initialState.distributionDetails;
        default:
            return state;
    }
}