import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_EXTERNAL_CHECKLISTS_CLIENTS_SUCCESS action and sets an array of active clients in the store
 * @param {Array} [state] The clients currently in the store, or the initial state of clients
 * @param {Object} action The action to be handled
 * @returns {Array} The clients
 */
export default function externalChecklistsClientsReducer(
  state = initialState.externalChecklists.clients,
  action
) {
  switch (action.type) {
    case actions.LOAD_EXTERNAL_CHECKLISTS_CLIENTS_SUCCESS:
      return action.clients;
    case actions.LOAD_EXTERNAL_CHECKLISTS_CLIENTS_FAILURE:
      return initialState.externalChecklists.clients;
    default:
      return state;
  }
}
