import React from 'react';
import { MotifTabNavigation, MotifTabControl ,MotifToast} from '@ey-xd/motif-react';
import { Link } from 'react-router-dom';
import { reduxForm } from "redux-form";
import { withRouter } from "../../common/withRouter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DataDeletionDiagnosticHeader from '../../components/dataDeletionDiagnostic/DataDeletionDiagnosticHeader';
import * as dataDeletionDiagnosticActions from '../../actions/dataDeletionDiagnosticActions';
import * as authActions from "../../actions/authActions";
import PropTypes from "prop-types";
import DataDeletionDiagnosticTable from '../../components/dataDeletionDiagnostic/dataDeletionDiagnosticTable';
import * as clientActions from "../../actions/clientActions";
import DataDeletionWarningModal from '../../components/dataDeletionDiagnostic/dataDeletionWarningModal';
import { ToastContainer, toast, Slide } from 'react-toastify';
export class DataDeletionDiagnosticPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabBar1: 0,
            calendarYears: [],
            selectedCalanderYear: new Date().getFullYear(),
            gridCurrentState: [],
            isSysAdmin: false,
            clientData: [],
            selectedClients: null,
            dataDeletionDiagnosticData: [],
            gridColumnState: [],
            clientSearchText: "",            
            refreshGriddataDeletionDiagnosticData: {
                refreshGridOnCompletedAction: -1
            },
            showDataDeletionWarningModal: false,
        };
        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleDeletionYearSelection = this.handleDeletionYearSelection.bind(this);
        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.setGridColumnState = this.setGridColumnState.bind(this);
        this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
        this.handleClientSelection = this.handleClientSelection.bind(this);      
        this.handleRunDataDeletionClick = this.handleRunDataDeletionClick.bind(this); 
        this.initiateDataDeletionProcess=this.initiateDataDeletionProcess.bind(this);  
        this.toggleDataDeletionWarningModal=this.toggleDataDeletionWarningModal.bind(this);
        this.toastSystem = React.createRef();
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.checkUserAuthorization();
        this.props.actions.loadAllClients();
    
        // Fetch calendar years for current client
        this.props.dataDeletionDiagnosticActions.fetchDataDeletionCalendarYears().then(fetchedCalendarYears => {
            let defaultCalendarYear = -1;
    
            if (fetchedCalendarYears && fetchedCalendarYears.length > 0) {
                const currentYear = new Date().getFullYear();
                const currentYearExists = fetchedCalendarYears.some(yearObj => yearObj.calendarYear === currentYear);
    
                if (currentYearExists) {
                    defaultCalendarYear = currentYear;
                } else {
                    defaultCalendarYear = fetchedCalendarYears[fetchedCalendarYears.length - 1].calendarYear;
                }
    
                this.setState({
                    calendarYears: fetchedCalendarYears,
                    selectedCalanderYear: defaultCalendarYear
                }, this.getDataDeletionDiagnosticData());
            }
        });
    }

    componentDidUpdate(prevProps) {

        if (this.props.dataDeletionDiagnosticData && this.props.dataDeletionDiagnosticData !== prevProps.dataDeletionDiagnosticData) {
            this.setState({ dataDeletionDiagnosticData: this.props.dataDeletionDiagnosticData });
        }
    
        if (this.props.clients && this.props.clients !== prevProps.clients) {
            this.setState({ clientData: this.props.clients },this.getDataDeletionDiagnosticData());
        }   
       
    }
  
    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });

    }

    //Set current tab index
    handleClick(index) {
        this.setTabBar1(index);
    }

    handleDeletionYearSelection(calendarYear) {
        this.setState({
            selectedCalanderYear: calendarYear
        }, this.getDataDeletionDiagnosticData);
    }  

    handleClientSelection(selectedclients) {
        this.setState({
            selectedClients: selectedclients
        }, this.getDataDeletionDiagnosticData);
    }    

    getDataDeletionDiagnosticData() {
       let requestData = {
           ClientId: (this.state.selectedClients == 'null' || this.state.selectedClients == null || this.state.selectedClients == "") ? null : Number(this.state.selectedClients),
           Year: Number(this.state.selectedCalanderYear),
        };

        this.props.dataDeletionDiagnosticActions.fetchDataDeletionDiagnosticData(requestData);
    }
    handleRunDataDeletionClick() {
        this.toggleDataDeletionWarningModal();
    }

    toggleDataDeletionWarningModal() {
        this.setState({ showDataDeletionWarningModal: !this.state.showDataDeletionWarningModal });
    }

    initiateDataDeletionProcess() {
        this.toggleDataDeletionWarningModal();
        let data = this.state.gridCurrentState;

        // Get the distinct client id from the selected data (assuming only 1 value will be selected)
        let client = [...new Set(
            data.filter(item => item.diagnosticdataSelection === true)
                .map(item => item.clientId)
        )][0];

        // Get the distinct report period ids from the selected data
        let uniqueReportPeriodIds = [...new Set(
            data.filter(item => item.diagnosticdataSelection === true)
                .map(item => item.dataPurgeReportPeriodID)
        )];
        
        // Get the distinct calendar year from the selected data (assuming only 1 value will be selected)
        // as we want the calendar year based on the report period to be passed instead of the selected calendar year from dropdown
        let calendarYear = [...new Set(
            data.filter(item => item.diagnosticdataSelection === true)
                .map(item => item.reportPeriodDescription.split(' ')[1])
        )][0];

        let toastVariant = 'info';
        let dataDeletionMessage = '';

        this.props.dataDeletionDiagnosticActions.runDataDeletion(calendarYear, client, uniqueReportPeriodIds)
            .then(response => {
                if (response.success && response.errorMessage == null) {
                    toastVariant = 'success';
                    dataDeletionMessage = 'Data deletion process initiated successfully';
                } else if (!response.success && response.errorMessage.includes("Deletion already in progress")) {
                    toastVariant = 'info';
                    dataDeletionMessage = "Deletion already in progress. Please wait until the current process completes before initiating another.";
                }
                else if (!response.success && response.errorMessage.length > 0) {
                    toastVariant = 'error';
                    dataDeletionMessage = "An error occurred during the data deletion process.";
                }
            })
            .catch(error => {
                toastVariant = 'error';
                dataDeletionMessage = 'An error occurred during the data deletion process';
            })
            .finally(() => {
                var dataDeletionStatusNotification = toast(
                    (
                        <MotifToast position="right"
                            variant={toastVariant}
                            onClose={() => this.handleDataDeletionNotificationClose(dataDeletionStatusNotification)}
                        >
                            <div>
                                <div className="row"><div className="ml-3 mr-2">
                                    {dataDeletionMessage}
                                </div></div>
                            </div>
                        </MotifToast>
                    )
                    , {
                        autoClose: 5000,
                    });

                this.refreshGrid();
            });

    }

    checkUserAuthorization() {
        let isSysAdmin = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations);
        }
        this.setState({isSysAdmin: isSysAdmin});      
    }

    handleDataDeletionNotificationClose(dataDeletionNotification) {
        if (dataDeletionNotification && toast.isActive(dataDeletionNotification)) {
            toast.dismiss(dataDeletionNotification);
        }
    }


    refreshGrid(selectedData = []) {
        this.getDataDeletionDiagnosticData();

        const randomNumber = parseInt(Math.random() * (99999999 - 1)) + 1;
        if (
            selectedData &&
            Array.isArray(selectedData) &&
            selectedData.length > 0
        ) {
            this.setState({
                refreshGriddataDeletionDiagnosticData: {
                    refreshGridOnCompletedAction: randomNumber
                },
            });
        } else {
            this.setState({
                refreshGriddataDeletionDiagnosticData: {
                    refreshGridOnCompletedAction: randomNumber
                },
            });
        }
    }

    handleGridChanges(gridRowData) {
        let rowData = [];
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {            
            return {
                dataPurgeID: x.dataPurgeID,
                clientName: x.clientName,
                reitName: x.reitName,
                reitid: x.reitid,
                reportPeriodDescription: x.reportPeriodDescription,
                dataPurgeProcessID: x.dataPurgeProcessID,
                approvedBy: x.approvedBy,
                scheduledDeletionDate: x.scheduledDeletionDate,
                actualDeletionDate: x.actualDeletionDate,
                deletionStatus: x.deletionStatus,
                approvedDate: x.approvedDateTime,
                diagnosticdataSelection: x.diagnosticdataSelection,
                dataPurgeReportPeriodID: x.dataPurgeReportPeriodID,
                dataPurgeStatusID: x.dataPurgeStatusID,
                entityName : x.entityName,
                countOfRecordsPurged : x.countOfRecordsPurged,
                dataPurgeStatus :   x.dataPurgeStatus,
                comments : x.comments,
                errorDescription : x.errorDescription,
                clientId: x.clientID,
                dataPurgeReportPeriodStatus:x.dataPurgeReportPeriodStatus,
                dataPurgeEntityDeleteStatus:x.dataPurgeEntityDeleteStatus,
                dataPurgeStartDateTime:x.dataPurgeStartDateTime,
                dataPurgeEndDateTime:x.dataPurgeEndDateTime,
                dataPurgeDurationInSeconds:x.dataPurgeDurationInSeconds,             
            }
        });
        this.setState({ gridCurrentState: rowData });
    }

    setGridColumnState() {
        var columnState = localStorage.getItem('REITSuiteDataDeletionDiagnosticColumnState');
        if (columnState) {
            this.setState({ gridColumnState: JSON.parse(columnState) });
        }
        else {
            this.setState({ gridColumnState: [{ colId: '-1' }] });
        }
    }

    handleGridColumnStateChange(columnState) {
        this.setState({ gridColumnState: columnState });
    }  
   
    render() {
        // Non sys admins are unauthorized to access system admin page
        if (!this.state.isSysAdmin) {
          this.props.router.navigate(`/unauthorized`);
          return <span />;
        }
        return (
          <React.Fragment>
            <h2>System Administrators</h2>
            <MotifTabNavigation variant="default">
              <Link to={`/adminauth`} tabIndex="-1">
                <MotifTabControl role="tab" onClick={() => this.handleClick(1)}>
                  Manage System Administrators
                </MotifTabControl>
              </Link>
              <Link to={`/adminauth/dataDeletionDiagnostic`} tabIndex="-1">
                <MotifTabControl
                  className="motif-active"
                  role="tab"
                  autoFocus
                  onClick={() => this.handleClick(2)}
                >
                  Data Deletion Diagnostic
                </MotifTabControl>
              </Link>
              <Link to={`/adminauth/externalUserDataVisibility`} tabIndex="-1">
                <MotifTabControl                 
                  role="tab"                  
                  onClick={() => this.handleClick(3)}
                >
                  External User Data Visibility Manager
                </MotifTabControl>
              </Link>
           
            </MotifTabNavigation>
            <DataDeletionDiagnosticHeader
              handleDeletionYearSelection={this.handleDeletionYearSelection}
              calendarYears={this.state.calendarYears}
              selectedCalanderYear={this.state.selectedCalanderYear}
              gridCurrentState={this.state.gridCurrentState}
              clientData={this.props.clientData}
              handleClientSelection={this.handleClientSelection}
              clientSearchText={this.state.clientSearchText}
              handleRunDataDeletionClick={this.handleRunDataDeletionClick}

            />
            <DataDeletionDiagnosticTable
              dataDeletionDiagnosticData={this.props.dataDeletionDiagnosticData}
              handleGridChanges={this.handleGridChanges}
              gridColumnState={this.state.gridColumnState}
              handleGridColumnStateChange={this.handleGridColumnStateChange}
              refreshGriddataDeletionDiagnosticData={this.state.refreshGriddataDeletionDiagnosticData}
            />
            <DataDeletionWarningModal 
            showDataDeletionWarningModal={this.state.showDataDeletionWarningModal}
            toggleShowDataDeletionWarningModal={this.toggleDataDeletionWarningModal}
            initiateDataDeletionProcess={this.initiateDataDeletionProcess}
            ></DataDeletionWarningModal>
            {(this.props.dataDeletionDiagnosticData == null ||
              this.props.dataDeletionDiagnosticData.length === 0) && (
              <div>
                No Data Diagnostic Deletion Data found for the selected calendar year
                and client.
              </div>
            )}


                <ToastContainer
                    ref={this.toastSystem}
                    position="bottom-right"
                    closeOnClick={false}
                    pauseOnHover={false}
                    draggable={false}
                    closeButton={false}
                    hideProgressBar={true}
                    transition={Slide}

                />
          </React.Fragment>
        );
      }
    }

DataDeletionDiagnosticPage.propTypes = {
    calendarYears: PropTypes.array,
    currentUserAuthorizations: PropTypes.array,
    actions: PropTypes.object,
    dataDeletionDiagnosticData: PropTypes.array,
    clients: PropTypes.array.isRequired,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    const clients = state.clients;   
    return {
        clients: clients,
        currentUserAuthorizations: state.currentUserAuthorizations,
        dataDeletionDiagnosticData: state.dataDeletionDiagnosticData,
        clientData: clients,
    };
}
/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(clientActions, dispatch),
        dataDeletionDiagnosticActions: bindActionCreators(dataDeletionDiagnosticActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        reduxForm({ form: "dataDeletionDiagnosticPage", enableReinitialize: true })(DataDeletionDiagnosticPage)
    )
);
    