import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifButton, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';

const ClearEndDateForm = ({ handleSubmit, handleCloseForm, initialValues, error, submitting, handleDismissErrorMessage }) => {

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            overflowWrap: 'anywhere'
          }}
        >
          {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Clear End Date Property Modal',
        ariaLabel: 'Close Clear End Date Property Modal',
        dataTestid: 'close',
    };

    return (
        <React.Fragment>
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span>{initialValues.propertyName}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <form onSubmit={handleSubmit} id="clearEndDateForm">
                    {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
                    <input name="id" type="hidden" label="id"/>  
                    {initialValues.propertyTrackingID}
                    <div className="mt-3">
                        <p>Note: This property will be reactivated in all future open periods.</p>
                    </div>
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" type="button" form="clearEndDateForm"
                    onClick={handleCloseForm}>
                    Cancel
                </MotifButton>
                <MotifButton variant="primary" type="submit" form="clearEndDateForm"
                    disabled={submitting}>
                    Clear End Date
                </MotifButton>
            </MotifModalFooter>
        </React.Fragment>
    );
};

ClearEndDateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    property: PropTypes.object,
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    handleCloseForm: PropTypes.func.isRequired,
    handleDismissErrorMessage: PropTypes.func.isRequired
};

export default reduxForm({ form: "clearEndDate" })(ClearEndDateForm);