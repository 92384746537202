import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { NumericFormat } from 'react-number-format';

{/* OBSOLETE - Do not use this class for any new functionality.  Use redux form along with form formatters found in scripts\formFormatters.js instead */ }
const formattedCurrencyInput = ({ amount, className, intl, disabled, handleOnChange, name, maxLength }) => {
    if (disabled) {
        return (
            <input type="text" className={className} value={intl.formatNumber(amount)} name={name} disabled/>);
    }
    else {
        if (maxLength) {
            return (                
                <NumericFormat type="text"
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;                        
                        return formattedValue === "" || floatValue <= 10000000000000000;
                    }}
                    placeholder="0"
                    thousandSeparator={true} disabled={false} value={amount}
                    name={name} onChange={handleOnChange} maxLength={maxLength} />);
        }
        else {
            return (<NumericFormat type="text"
                placeholder="0" 
                thousandSeparator={true} disabled={false} value={amount}
                name={name} onChange={handleOnChange}  />
            );
        }
    }
};

formattedCurrencyInput.propTypes = {
    amount: PropTypes.number.isRequired,
    className: PropTypes.string,
    intl: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    handleOnChange: PropTypes.func,
    name: PropTypes.string.isRequired
};
export default injectIntl(formattedCurrencyInput);