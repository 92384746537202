import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { addAttribute } from '../../common/Attribute';
import {
  MotifFormField,
  MotifSearch,
  MotifLabel,
  MotifToggleSwitch,
  MotifSelect,
  MotifIcon,
  MotifTooltip
} from '@ey-xd/motif-react';
import { IconoirPlusSquare, IconoirMinusSquare, IconoirHelpCircle } from '@ey-xd/motif-icon';
import QuestionsComponent from './QuestionsComponent';

const AvailableQuestions = ({
  handleShowStandardQuestions,
  showStandardQuestions,
  handleSearchQuestion,
  searchQuestionText,
  handleAvailableQuestionsSearchBy,
  selectedAvailableQuestionSearchBy,
  createAvailableQuestionsSearchBy,
  questions,
  handleAddNewChildQuestion,
  handleEditClick,
  handleDeleteClick,
  handleAvailableQuestionDragStart,
  handleAvailableQuestionDragOver,
  handleQuestionDropComponent,
  handleQuestionDropPanel,
  handleSectionQuestionDragStart,
  dropCategory,
  expandAllAccordionsOnAvailableQuestions,
  handleToggleExpandCollapseAllAcordionsOnAvailableQuestions,
  handleOpenAccordionOnAvailableQuestions,
  handleCloseAccordionOnAvailableQuestions,
  visibleQuestionTypes,
  isTemplateEditedByOtherUser,
  handleDownloadAttachmentClick,
  handleClearSearchText
}) => {
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  return (
    <React.Fragment>
      <div
        className="psq-custom-checklist-available-questions-section"
        data-testid="customChecklistAvailableQuestionsSec"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '0.5px solid gray',
            height: '43px',
          }}
        >
          <div className='row w-100'>
            <div className="col-md-11 col-sm-11 d-flex align-items-center">
              {!expandAllAccordionsOnAvailableQuestions && (
                <MotifIcon
                  className="pl-3 expand-collapse-all-available-sections"
                  iconFunction={IconoirPlusSquare} size='24' fill='none' 
                  data-testid="btnExpandAllAccordionsOnAvailableSections"
                  onClick={(e) =>
                    handleToggleExpandCollapseAllAcordionsOnAvailableQuestions(
                      e,
                      'expand'
                    )
                  }
                  title="Expand All"
                />
              )}
              {expandAllAccordionsOnAvailableQuestions && (
                <a style={{ cursor: 'grab' }}>
                  <MotifIcon
                    className="pl-3 expand-collapse-all-available-sections"
                    iconFunction={IconoirMinusSquare} size='24' fill='none' 
                    data-testid="btnCollapseAllAccordionsOnAvailableSections"
                    onClick={(e) =>
                      handleToggleExpandCollapseAllAcordionsOnAvailableQuestions(
                        e,
                        'collapse'
                      )
                    }
                    title="Collapse All"
                  />
                </a>
              )}
              <h5
                className="pl-3 mb-0"
                data-testid="headingAvailableQuestions"
                style={{ marginTop: '-5px' }}
              >
                Available Questions
              </h5>
            </div>
            <div className="col-md-1 col-sm-1 d-flex align-items-center justify-content-end">
              <MotifTooltip
                id="show-available-questions-tooltip"
                allowHover
                placement="auto"
                trigger={
                  <MotifIcon iconFunction={IconoirHelpCircle} size='24' fill='none'  />
                }
                variant="alt"
                hideCloseButton={true}
              >
                <div className='motif-tooltip-content-children'>
                  <div>The below displays all available questions within this client. This includes both REITSuite standard questions, as well as custom questions created within this client.</div>
                  <br/>
                  <div>You can drag and drop available questions to the left in order to add them to your questionnaire.</div>
                  <br/>
                  <div>New questions are created on the left-hand side of the screen.</div>
                  <br/>
                  <div>Questions can be expanded by selecting the "+" icon. This will display details about the expanded question, including any "child" questions associated with it.</div>
                </div>
              </MotifTooltip>
            </div>
          </div>
        </div>
        <div className="psq-custom-checklist-available-questions-section-content">
          <div className="row align-items-center availabelQuestionBody" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="col-md-5 marginTop">
              <React.Fragment>
                <MotifLabel
                  htmlFor="switchStandard"
                  // className="text-right"
                  data-testid="lblStandardQustToggle"
                  style={{
                    display: 'inline-block',
                    // marginRight: '1rem',
                    width: 'auto',
                  }}
                >
                  Display Standard Questions:
                </MotifLabel>
                <MotifFormField
                  style={{ display: 'inline-block', width: 'auto' }}
                >
                  <MotifToggleSwitch
                    aria-label="Display Standard Questions toogle"
                    name="switchStandard"
                    data-testid="btnStandardQustToggle"
                    id="switchStandard"
                    onChange={handleShowStandardQuestions}
                    checked={!!showStandardQuestions}
                  />
                </MotifFormField>
              </React.Fragment>
            </div>
            <div className="col-md-3 mb-5">
              <MotifFormField>
                <MotifLabel
                  aria-label="Select search by"
                  data-testid="lblSearchBy"
                >
                  Search By
                </MotifLabel>
                <MotifSelect
                  role="dropdown"
                  aria-label="Select search by"
                  data-testid="searchBy"
                  onChange={handleAvailableQuestionsSearchBy}
                  value={selectedAvailableQuestionSearchBy}
                  placeholder="Select search by"
                >
                  {createAvailableQuestionsSearchBy()}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="col-md-4 marginTop">
              <MotifFormField>                
                <MotifSearch
                  type="text"
                  items={[]}
                  name="searchQuestion"
                  onChange={handleSearchQuestion}
                  onSelect={handleClearSearchText}
                  value={searchQuestionText}
                  title="Search for Questions"
                  data-testid="txtSearchForQuestion"
                  aria-label="Search for Questions"
                  placeholder="Search for Questions"
                  searchButtonProps={{ tabIndex: '-1' }}
                />
              </MotifFormField>
            </div>
          </div>
          <div className="container-drag marginTop">
            <div
              className="droppable"
              onDragOver={(e) => handleAvailableQuestionDragOver(e)}
              onDrop={(e) => handleQuestionDropPanel(e, 'availablequestion')}
              style={{ minHeight: '385px', height: '100%' }}
            >
              {questions && (
                <QuestionsComponent
                  questions={questions}
                  handleAddNewChildQuestion={handleAddNewChildQuestion}
                  handleDeleteClick={handleDeleteClick}
                  handleEditClick={handleEditClick}
                  handleAvailableQuestionDragStart={
                    handleAvailableQuestionDragStart
                  }
                  handleSectionQuestionDragStart={
                    handleSectionQuestionDragStart
                  }
                  handleQuestionDropComponent={handleQuestionDropComponent}
                  dropCategory={dropCategory}
                  handleOpenAccordion={handleOpenAccordionOnAvailableQuestions}
                  handleCloseAccordion={
                    handleCloseAccordionOnAvailableQuestions
                  }
                  visibleQuestionTypes={visibleQuestionTypes}
                  isTemplateEditedByOtherUser={isTemplateEditedByOtherUser}
                  handleDownloadAttachmentClick={handleDownloadAttachmentClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
AvailableQuestions.propTypes = {
  handleShowStandardQuestions: PropTypes.func.isRequired,
  showStandardQuestions: PropTypes.bool,
  handleSearchQuestion: PropTypes.func.isRequired,
  searchQuestionText: PropTypes.string,
  handleAvailableQuestionsSearchBy: PropTypes.func.isRequired,
  selectedAvailableQuestionSearchBy: PropTypes.string,
  createAvailableQuestionsSearchBy: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      isLocked: PropTypes.bool.isRequired,
      piclistItems: PropTypes.array,
    })
  ).isRequired,
  handleAddNewChildQuestion: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  expandAllAccordionsOnAvailableQuestions: PropTypes.bool,
  handleToggleExpandCollapseAllAcordionsOnAvailableQuestions: PropTypes.func,
  handleOpenAccordionOnAvailableQuestions: PropTypes.func,
  handleCloseAccordionOnAvailableQuestions: PropTypes.func,
  handleAvailableQuestionDragStart: PropTypes.func,
  handleAvailableQuestionDragOver: PropTypes.func,
  handleQuestionDropComponent: PropTypes.func,
  handleQuestionDropPanel: PropTypes.func,
  handleSectionQuestionDragStart: PropTypes.func,
  dropCategory: PropTypes.string,
  visibleQuestionTypes: PropTypes.array,
  handleDownloadAttachmentClick: PropTypes.func,  
  handleClearSearchText: PropTypes.func,
};
export default AvailableQuestions;
