import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReportApi as fetch } from './fetchFromApi';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Uploads the final report.
 * @param {number} periodId The ID of the report period that the report belongs to.
 * @param {any} file The file to upload.
 * @returns {any} A function that returns a Promise.
 */
export function uploadFinalReport(periodId, file) {
    let formData = new FormData();
    formData.append("periodId", periodId);
    formData.append("file", file);
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch('/finalDeliverableReport',
            {
                method: 'POST',
                body: formData
            }).then(response => {
                if (response.ok) {
                    return null;
                } else {
                    return response.json();
                }
            }).then(json => {

                if (json) {
                    actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPLOAD_FINAL_REPORT_FAILURE, json);
                } else {
                    dispatch({ type: actions.UPLOAD_FINAL_REPORT_SUCCESS, [pendingTask]: end });
                }
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPLOAD_FINAL_REPORT_FAILURE, null, error);
            });
    };
}

/**
 * Retrieves uploaded report information.
 * @param {number} periodId The ID of the report period that the report belongs to.
 * @returns {any} A function that returns a Promise.
 */
export function fetchUploadedReport(periodId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/finalDeliverableReport?periodid=${periodId}`).then(response => {
            return response.json();
        }).then(files => {
            if (actionHelpers.isErrorResponse(files)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_FINAL_REPORT_FAILURE, files);
            }

            dispatch({ type: actions.LOAD_FINAL_REPORT_SUCCESS, finalReport: files[0], [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_FINAL_REPORT_FAILURE, null, error);
        });
    };
}

/**
 * Downloads a report.
 * @param {number} periodId The ID of the report period that the report belongs to.
 * @param {any} fileName The filename of the report to download.
 * @returns {any} A Promise.
 */
export function downloadFinalReport(periodId, fileName) {
    return fetch(`/finalDeliverableReport?periodid=${periodId}&fileName=${encodeURIComponent(fileName)}`)
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
}

/**
 * Clears the success message from uploading a report.
 * @returns {any} A function that dispatches an action.
 */
export function clearUploadFinalReportSuccess() {
    return function (dispatch) {
        dispatch({ type: actions.UPLOAD_FINAL_REPORT_CLEAR });
    };
}

/**
 * Deletes a report
 * @param {number} periodId The ID of the report period that the report belongs to.
 * @returns {any} A Promise.
 */
export function deleteFinalReport(periodId) {

    return fetch('/finalDeliverableReport',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({ periodId })
        }).then(response => {
            if (response.ok) {
                return null;
            } else {
                return response.json();
            }
        });
}