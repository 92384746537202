import React ,{useState} from 'react';
import { Field } from 'redux-form';
import { PropTypes } from "prop-types";
import * as formHelpers from '../../scripts/formHelpers';
import { IconoirInfoCircle } from '@ey-xd/motif-icon';
import * as Constants from '../../constants/other';
import { MotifFormField, MotifSearch, MotifButton, MotifIcon, MotifTooltip ,MotifToast} from '@ey-xd/motif-react';

const DataDeletionDiagnosticHeader = ({
  handleDeletionYearSelection,
  calendarYears,
  selectedCalanderYear,
  gridCurrentState,
  clientData,
  handleClientSelection,
  handleRunDataDeletionClick
}) => {
  const [hideInfoMessage, setHideInfoMessage] = useState(false);
  const clientOptions = clientData && clientData.map(r => {
    return { id: r.id, value: r.id, text: r.name };
  });

  if (clientOptions && clientOptions.length > 0) {
    clientOptions.unshift({ id:null, value: null, text: "All" });
  }

  const deletionYearOptions = calendarYears.map(cy => {
    return { key: cy.calendarYear, value: cy.calendarYear, text: cy.calendarYear };
  });

  let dataDeletionButtonStatus = false;
  let disabledInfoMessageOfNoRowSelection = Constants.dataDeletionNoRowSelectionMessage;
  let uniqueClientIds = new Set();
 
  if (gridCurrentState) {
    gridCurrentState.forEach(record => {
      if (record.diagnosticdataSelection === true) {
        uniqueClientIds.add(record.clientId);
      }
    });
  }
 
  if (uniqueClientIds.size ===1) {
    dataDeletionButtonStatus = true;
  }																	
	
  return (
    <>
  <div className="row mt-5">
  <div className="col-md-2"  style={{ maxWidth: '150px' }}>
    <Field
      name="deletionYear"
      label="Deletion Year"
      type="select"
      data-testid="deletionYear"
      onChange={handleDeletionYearSelection}
      options={deletionYearOptions}
      component={formHelpers.SingleSelectComponent}
      defaultValue={selectedCalanderYear}
    />
  </div>
  <div className="col-md-2" style={{ minWidth: '400px' }} >
    <Field
      name="client"                                      
      label="Client"
      placeholder="Select one or more"
      isRequiredField={true}
      options={clientOptions}
      optionText={(r) => r.text}
      filter
      onChange={handleClientSelection}
      optionValue={(r) => r.value}
      component={formHelpers.SingleSelectComponent}
      defaultValue={'null'}
      testingData={{ label: "lblClients", div: "divClients" }}
    />                 
  </div>
  
  <div className="col-md-3" style={{ marginTop: '1.25em', marginLeft: 'auto'}}> 
  <MotifToast  variant="info" data-testid="eyToastMsg" hideclosebutton="true" className="removePadding" >
    <span style={{fontSize:'10px'}}> Note: Failed deletion records will be auto-processed next weekend. Remember to select only one client for immediate deletion.</span>
  </MotifToast>   
</div>
    
  <div className="col-md-2" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2.5em', marginLeft:'20px'}}>
    <MotifButton variant="primary" data-testid="runDataDeletion" onClick={handleRunDataDeletionClick} type="button" className="right" disabled={!dataDeletionButtonStatus}>
      Run Data Deletion
      {!dataDeletionButtonStatus &&
        <MotifTooltip
          placement="auto"
          trigger={<MotifIcon className="ml-2" iconFunction={IconoirInfoCircle} size="24" fill="none" />}>
          {disabledInfoMessageOfNoRowSelection}
        </MotifTooltip>
      }
    </MotifButton>
  </div>  
</div>
    </>
  );
};

DataDeletionDiagnosticHeader.propTypes = {
  handleDeletionYearSelection: PropTypes.func,
  calendarYears: PropTypes.array,
  selectedCalanderYear: PropTypes.string,
  gridCurrentState: PropTypes.array,
  handleClientChange: PropTypes.func,
  handleClientSelection: PropTypes.func,
  clientSearchText: PropTypes.string,
  handleRunDataDeletionClick: PropTypes.func,
};

export default DataDeletionDiagnosticHeader;