import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_EXTERNAL_CHECKLIST_SUCCESS action and sets the extChecklists in the store
 * @param {Object} [state] The extChecklists currently in the store, or the initial state of the extChecklists
 * @param {Object} action The action to be handled
 * @returns {Object} The extChecklists
 */
export default function externalChecklistReducer(state = initialState.extChecklists, action) {
    if (action.type == actions.LOAD_EXTERNAL_CHECKLIST_SUCCESS && Array.isArray(action.extChecklists)) {        
        return action.extChecklists;
    } else if (action.type == actions.LOAD_EXTERNAL_CHECKLIST_FAILURE) {
        return initialState.extChecklists;
    } else {
        return state;
    }
}