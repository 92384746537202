import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles authentication information in the store
 * @param {Object} [state] The authentication object currently in the store, or the initial state of the authentication
 * @param {Object} action The action to be handled
 * @returns {Object} The authentication object
 */
export default function authentication(state = initialState.authentication, action) {
    switch (action.type) {
        case actions.AUTHENTICATION_TIMER_UPDATED:
            return Object.assign({}, state, {
                remainingTime: action.remainingTime
            });
        case actions.SET_CURRENT_USER:
            return Object.assign({}, state, {
                currentUser: action.currentUser
            });
        case actions.SET_ID_TOKEN:
            return Object.assign({}, state, {
                idToken: action.idToken
            });
        case actions.SET_ACCESS_TOKEN:
            return Object.assign({}, state, {
                accessToken: action.accessToken
            });        
        case actions.SET_MSAL_INSTANCE:
            return Object.assign({}, state, {
                msalInstance: action.msalInstance
            });
        default:
            return state;
    }
}