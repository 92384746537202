import { useState, useEffect } from 'react';

 function useWindowDimensions(subtractHeight = 0) {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight - subtractHeight,
    width: window.innerWidth - (window.innerWidth < 680 ? 0 : 47),
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight - subtractHeight,
        width: window.innerWidth - (window.innerWidth < 680 ? 0 : 47),
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
}

export default useWindowDimensions;