import * as actions from '../../actions/actionTypes';
import initialState from '../initialState';

/**
 * This reducer handles the LOAD_CUSTOM_PSQ_TEMPLATE_SUCCESS action and sets data of custom psq templates to the store
 * @param {Object} [state] The custom psq template data currently in the store, or the initial state of client account data
 * @param {Object} action The action to be handled
 * @returns {Object} The custom psq template data
 */

export default function customPsqTemplateReducer(
  state = initialState.selectedCustomPsqTemplate,
  action
) {
  switch (action.type) {
    case actions.LOAD_CUSTOM_PSQ_TEMPLATE_SUCCESS:
      return action.customPsqTemplate;
    default:
      return state;
  }
}
