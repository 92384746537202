import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Header, Button } from "semantic-ui-react";

import mapping from "../../reducers/mappingReducer";
import { Input } from "@progress/kendo-react-inputs";
import ChartAssignmentConfirmationModal from "../chartOfAccountsManager/ChartAssignmentConfirmationModal";
import {
  MotifButton,
  MotifFormField,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";

let globalCharts = [];
let globalDefaultChart = {};

const DropDownCell = (props) => {
  let optionsData = props.optionsData || [];
  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  // Change the label and chart text when isInheritFromReit to 'Default(REIT Chart)'
  let optionValue = dataValue.isInheritFromReit
    ? { label: "Default(REIT Chart)", value: -1 }
    : { label: dataValue.chartName, value: dataValue.chartID };

  // This triggers the itemChange
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e,
      });
    }
  };

  // First line ensures we only show select component if there is a Property
  let showSelect =
    dataValue.propertyID != null &&
    dataValue.periodID != null &&
    !dataValue.isClosed &&
    dataValue.trialBalanceModeID != 2 &&
    !(
      dataValue.periodStatusDescription == "Closed to Testing" ||
      dataValue.periodStatusDescription == "Closed to Reporting" ||
      dataValue.periodStatusDescription == "Complete"
    );

  // First check if no period (show "Period not Created")
  // then check if no property, exclude dataItem.propertyTrackingID null because that means it is a REIT row (show "Property not Created")
  // then check if it is inheriting from REIT (show "Default(REIT Chart)")
  // if none of the above, then show the assigned chart name
  let textValue =
    dataValue.periodID == null
      ? "Period not Created"
      : dataValue.propertyID == null && dataItem.propertyTrackingID != null
      ? "Property not Created"
      : dataValue.isInheritFromReit
      ? "Default(REIT Chart)"
      : dataValue.chartName;
  return (
    <td>
      {showSelect ? <Select
            options={optionsData}
            value={optionValue}
            isClearable={false}
            isSearchable={false}
            menuPortalTarget={document.body}
            styles={{
                control: styles => ({ ...styles, background: dataItem.isDirty[field] ? "rgb(255 242 127)" : "" })
            }}
            onChange={handleChange}
        /> : textValue}
    </td>
  );
};

const ChartSelectionCell = (props) => (
  <DropDownCell
    {...props}
    defaultChart={globalDefaultChart}
    optionsData={globalCharts}
  />
);

const ChartAssignmentPropertyTable = ({
  propertiesArray,
  searchBoxText,
  handlePropertyUpdate,
  chartOfAccounts,
  defaultChart,
  setRowsAffected,
  rowsAffected,
  theme,
}) => {
  let pagerSettings = {
    buttonCount: 4,
    info: true,
    type: "numeric",
    pageSizes: [100, 250, 500],
    previousNext: true,
  };
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 100,
  });
  const [dataResult, setDataResult] = useState(
    process(propertiesArray, dataState)
  );
  const [dataResultOnEditing, setDataResultOnEditing] = useState([]);
  const [showDuplicateAccountsEditModal, setShowDuplicateAccountsEditModal] =
    useState(false);
  const [clientAccountCodeList, setClientAccountCodeList] = useState([]);
  const [
    showChartAssignmentConfirmationModal,
    setShowChartAssignmentConfirmationModal,
  ] = useState(false);
  const [recordsModifiedArray, setRecordsModifiedArray] = useState([]);
  const [propertiesModifiedArray, setPropertiesModifiedArray] =
    useState(propertiesArray);

  useEffect(() => {
    handleSearchBox(searchBoxText);
  }, [searchBoxText]);

  useEffect(() => {
    globalCharts = chartOfAccounts.charts.map((chart) => {
      return { label: chart.chartName, value: chart.chartID };
    });
    globalCharts = [
      { label: "Default(REIT Chart)", value: -1 },
      ...globalCharts,
    ];

    globalDefaultChart = defaultChart;
  }, []);

  useEffect(() => {
    setDataResult(process(propertiesArray, dataState));
    setPropertiesModifiedArray(propertiesArray);
    setRowsAffected([]);
    setRecordsModifiedArray([]);
  }, [propertiesArray]);

  const handleSearchBox = (textToFilter) => {
    let filterConfig = {
      logic: "or",
      filters: [
        { field: "propertyName", operator: "contains", value: textToFilter },
        { field: "Quarter1Text", operator: "contains", value: textToFilter },
        { field: "Quarter2Text", operator: "contains", value: textToFilter },
        { field: "Quarter3Text", operator: "contains", value: textToFilter },
        { field: "Quarter4Text", operator: "contains", value: textToFilter },
      ],
    };
    //This ternary operator alternates bewteen the mappings that we dont want to change for revert functionality and the actual edit data
    setDataResult(
      process(propertiesModifiedArray, { ...dataState, filter: filterConfig })
    );
    setDataState({ ...dataState, filter: filterConfig, skip: 0 });
  };

  const dataStateChange = (event) => {
    setDataResult(process(propertiesModifiedArray, event.dataState));
    setDataState(event.dataState);
  };

  const toggleShowChartAssignmentConfirmationModal = () => {
    setShowChartAssignmentConfirmationModal(
      !showChartAssignmentConfirmationModal
    );
  };

  const handleSaveUpdates = () => {
    let requestFormattedMappings = [];
    rowsAffected.forEach((reitMapping, index) => {
      requestFormattedMappings[index] = {};
      requestFormattedMappings[index].PeriodAssignments = [];
      if (reitMapping.isDirty.Quarter1) {
        requestFormattedMappings[index].PeriodAssignments.push(
          reitMapping.Quarter1
        );
      }
      if (reitMapping.isDirty.Quarter2) {
        requestFormattedMappings[index].PeriodAssignments.push(
          reitMapping.Quarter2
        );
      }
      if (reitMapping.isDirty.Quarter3) {
        requestFormattedMappings[index].PeriodAssignments.push(
          reitMapping.Quarter3
        );
      }
      if (reitMapping.isDirty.Quarter4) {
        requestFormattedMappings[index].PeriodAssignments.push(
          reitMapping.Quarter4
        );
      }
      requestFormattedMappings[index].propertyName = reitMapping.propertyName;
      requestFormattedMappings[index].propertyTrackingID =
        reitMapping.propertyTrackingID;
    });

    setRowsAffected([]);
    handlePropertyUpdate(requestFormattedMappings);
  };

  const itemChange = (event) => {
    const inEditID = event.dataItem.propertyTrackingID;
    const field = event.field || "";
    let value = event.value.value;
    let newChartName = event.value.label;
    let newData = [];

    newData = propertiesModifiedArray.map((mapping) => {
      let newMapping = {};
      if (mapping.propertyTrackingID === inEditID) {
        if (value == mapping.originalState[field].chartID) {
          newMapping = {
            ...mapping,
            [field]: {
              ...mapping[field],
              chartID: value,
              chartName: newChartName,
              isInheritFromReit: value === -1,
            },
            isDirty: { ...mapping.isDirty, [field]: false },
          };
          if (recordsModifiedArray.includes(`${inEditID}${field}`)) {
            let listWithRemovedRecord = recordsModifiedArray.filter(
              (record) => record != `${inEditID}${field}`
            );
            setRecordsModifiedArray(listWithRemovedRecord);
          }
        } else {
          newMapping = {
            ...mapping,
            [field]: {
              ...mapping[field],
              chartID: value,
              chartName: newChartName,
              isInheritFromReit: value === -1,
            },
            isDirty: { ...mapping.isDirty, [field]: true },
          };
          if (!recordsModifiedArray.includes(`${inEditID}${field}`)) {
            setRecordsModifiedArray([
              ...recordsModifiedArray,
              `${inEditID}${field}`,
            ]);
          }
        }
        newMapping[`${field}Text`] = newChartName;
      } else {
        newMapping = mapping;
      }

      return newMapping;
    });

    let affectedRowsCurrentCounter = newData.filter(
      (account) =>
        account.isDirty.Quarter1 === true ||
        account.isDirty.Quarter2 === true ||
        account.isDirty.Quarter3 === true ||
        account.isDirty.Quarter4 === true
    );

    setRowsAffected(affectedRowsCurrentCounter);
    setPropertiesModifiedArray(newData);
    setDataResult(process(newData, dataState));
  };

  return (
    <div className="row marginTop">
      <div className="col-12">
        {
          <div
            style={{
              position: "absolute",
              top: "360px",
              right: "15px",
              zIndex: "1",
              display: "inline",
            }}
          >
            <MotifButton
              variant="primary"
              className="right ml-2 mr-2"
              type="button"
              onClick={toggleShowChartAssignmentConfirmationModal}
              disabled={rowsAffected.length == 0}
            >
              Save
            </MotifButton>
            <MotifButton
              variant="secondary"
              className="right ml-2"
              type="button"
              onClick={() => {
                setRowsAffected([]);
                setRecordsModifiedArray([]);
                setDataResult(process(propertiesArray, dataState));
                setPropertiesModifiedArray(propertiesArray, dataState);
              }}
            >
              Cancel
            </MotifButton>
            <p className="right mr-2 mt-2">
              {recordsModifiedArray.length} record
              {recordsModifiedArray.length === 0 ||
              recordsModifiedArray.length > 1
                ? "s"
                : ""}{" "}
              changed
            </p>
          </div>
        }
        <Grid
          style={{
            height: "400px",
          }}
          pageable={pagerSettings}
          data={dataResult}
          {...dataState}
          onDataStateChange={dataStateChange}
          scrollable={"scrollable"}
          fixedScroll={true}
          onItemChange={itemChange}
        >
          <GridNoRecords>
            {propertiesArray.length == 0
              ? "Click on Chart Management > Click Download Chart of Accounts > Update the downloaded file with the chart's details > Click Upload Chart of Accounts"
              : `No mappings match your search text '${searchBoxText}'`}
          </GridNoRecords>
          <GridColumn field="propertyName" title="REIT/Property" />
          <GridColumn field="Quarter1" title="Q1" cell={ChartSelectionCell} />
          <GridColumn field="Quarter2" title="Q2" cell={ChartSelectionCell} />
          <GridColumn field="Quarter3" title="Q3" cell={ChartSelectionCell} />
          <GridColumn field="Quarter4" title="Q4" cell={ChartSelectionCell} />
        </Grid>
        <ChartAssignmentConfirmationModal
          showChartAssignmentConfirmationModal={
            showChartAssignmentConfirmationModal
          }
          toggleShowChartAssignmentConfirmationModal={
            toggleShowChartAssignmentConfirmationModal
          }
          modifiedMappingsArray={rowsAffected}
          handleConfirm={handleSaveUpdates}
          type={"property"}
          theme={theme}
        />
      </div>
    </div>
  );
};

ChartAssignmentPropertyTable.propTypes = {
  theme: PropTypes.string,
};

export default ChartAssignmentPropertyTable;
