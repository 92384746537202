import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the SET_INTERNAL_USER_MANAGER and SET_EXTERNAL_USER_MANAGER actions and sets the oidc user manager in the store
 * @param {Object} [state] The oidc user manager currently in the store, or the initial state of the oidc user manager
 * @param {Object} action The action to be handled
 * @returns {Object} The oidc user manager
 */
export default function authenticationScopeReducer(state = initialState.authenticationScope, action) {
    switch (action.type) {
        case actions.SET_AUTHENTICATION_SCOPE:
            return action.authenticationScope;
        default:
            return state;
    }
}