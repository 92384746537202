import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MotifTextLink } from '@ey-xd/motif-react';

const ReitsExternalRow = ({ reit, clientId }) => {
    return (
        <tr>
            <td><Link tabIndex='-1' to={`/ext/reits/${clientId}/checklists/${reit.reitId}`}><MotifTextLink variant="alt">{reit.reitName}</MotifTextLink></Link></td>
        </tr>
    );
};

ReitsExternalRow.propTypes = {
    reit: PropTypes.object.isRequired
};

export default ReitsExternalRow;