import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";

import { Field, reduxForm, getFormValues } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import {
    MotifButton, MotifFormField, MotifInput, MotifLabel, MotifSelect, MotifOption, MotifModal, MotifIcon,
    MotifModalBody, MotifModalFooter, MotifModalHeader} from "@ey-xd/motif-react";
import { addAttribute } from "../../common/Attribute";

const SystemAdminUnlockTemplateModal = ({ showSystemAdminUnlockTemplateModal,
    handleSubmit, handleSystemAdminUnlockTemplate, toggleShowSystemAdminUnlockTemplateModal, systemAdminUnlockTemplateMessage, checklistTemplate }) => {

    const checklistTemplateId = checklistTemplate ? checklistTemplate.checklistTemplateId : 0;
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Validation message modal',
        ariaLabel: 'Close Validation message modal',
        dataTestid: 'close'
    };

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    return (
        <MotifModal variant="alt" show={showSystemAdminUnlockTemplateModal} onClose={() => toggleShowSystemAdminUnlockTemplateModal()} size="xl" data-testid="customPSQTemplateValidationMessage">
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Unlock Template</span>
                </HeaderWrapper>
            </MotifModalHeader>

            <MotifModalBody>
                <form onSubmit={handleSubmit} className="auth">
                    <span className="customPSQTemplateActionMsg">
                        {systemAdminUnlockTemplateMessage}
                    </span>
                    <br />
                    <span className="customPSQTemplateActionMsg mt-3">
                        Are you sure you want to unlock the template?
                    </span>
                    <br />
                    <span className="customPSQTemplateActionMsg">Name - {checklistTemplate ? checklistTemplate.checklistTemplateName : ''}</span>
                    <br />
                </form>
            </MotifModalBody>
            
            <MotifModalFooter>
                <MotifButton
                    variant="secondary"
                    type="button"
                    onClick={() => toggleShowSystemAdminUnlockTemplateModal()} className="right" data-testid="btnCancel">
                    Cancel
                </MotifButton>
                <MotifButton type="button" onClick={() => handleSystemAdminUnlockTemplate(checklistTemplateId)} className="right" data-testid="btnUnlock" >
                    Unlock
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

SystemAdminUnlockTemplateModal.propTypes = {
    showSystemAdminUnlockTemplateModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleShowSystemAdminUnlockTemplateModal: PropTypes.func,
    initialValues: PropTypes.object
};

export default reduxForm({ form: "systemAdminUnlockTemplate", enableReinitialize: true })(SystemAdminUnlockTemplateModal);