import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SubmissionError, getFormValues, change } from "redux-form";
import { bindActionCreators } from "redux";
import * as authActions from "../../actions/authActions";
import * as propertyActions from "../../actions/propertyActions";
import * as PeriodActions from "../../actions/periodActions";
import * as trsActions from "../../actions/trsActions";
import * as actionHelpers from "../../scripts/actionHelpers";
import * as commonActions from "../../actions/commonActions";
import PropertyList from "../../components/reits/PropertyList";
import * as formHelpers from "../../scripts/formHelpers";
import PropertyAmenitiesTableContainer from "../shared/PropertyAmenitiesTableContainer";
import * as lookupTypeActions from "../../actions/lookupTypeActions";
import { withRouter } from "../../common/withRouter";

/**
 * PropertiesPage container component
 * @extends {React.Component}
 */
export class PropertiesPage extends React.Component {
  /**
   * Creates a new PropertiesPage
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      propertySearchText: "",
      //filteredProperties: [],
      showForm: false,
      formProperty: {},
      showComponent: false,
      showDeletePropertyModal: false,
      formPurpose: "",
      showSuccess: null,
      canAdd: true,
      canEdit: true,
      isTestingEnabled: false,
      hideSuccessMessage: false,
      periodPropertyCount: 0,
      tbMode: 1,
      selectedPropertyId: null,
      showEndForm: false,

      showClearEndDateForm: false,
      gridCurrentState: [],
    };

    this.closeForm = this.closeForm.bind(this);
    this.showForm = this.showForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
    this.handleDismissSuccessMessage =
      this.handleDismissSuccessMessage.bind(this);
    this.handleNewButtonClick = this.handleNewButtonClick.bind(this);
    this.handleCloseComponent = this.handleCloseComponent.bind(this);
    this.handleOpenComponent = this.handleOpenComponent.bind(this);
    this.handleShowEndForm = this.handleShowEndForm.bind(this);
    this.handleShowClearEndDateForm =
      this.handleShowClearEndDateForm.bind(this);
    this.handleCloseEndForm = this.handleCloseEndForm.bind(this);
    this.handleOpenDeleteProperty = this.handleOpenDeleteProperty.bind(this);
    this.handleYesDeleteProperty = this.handleYesDeleteProperty.bind(this);
    this.handleNoDeleteProperty = this.handleNoDeleteProperty.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
  }

  /**
   * Invoked immediately after a component mounts
   */
  componentDidMount() {
    // Check if state.period is null, if it is populate period
    if (!this.props.period) {
      this.props.periodActions.fetchPeriodById(this.props.periodId);
    }

    this.props.actions.fetchFirstPropertyByPeriod(this.props.periodId);
    this.props.actions.fetchPropertiesByPeriod(this.props.periodId);
    this.props.trsActions.fetchTrsByPeriod(this.props.periodId);
    this.props.lookupTypeActions.fetchPropertyTypes();
    this.resetGridColumnState();
  }

    /**
     * Invoked before a mounted component receives new props.
     * @param {Object} nextProps The properties that the component is receiving
     */
    componentDidUpdate(prevProps) {

        if (prevProps.periodId !== this.props.periodId && this.props.periodId > 0) {
            this.props.actions.fetchPropertiesByPeriod(this.props.periodId);
        }

        if (prevProps.period !== this.props.period) {
            this.setCanAdd(this.props.period);
            this.setCanEdit(this.props.period);
            this.setIsTestingEnabled(this.props.period);
            if (this.props.period && this.props.period.trialBalanceModeID === 2) {
                this.setState({ tbMode: 2 });
            } else {
                this.setState({ tbMode: 1 });
            }
        }

        if (prevProps.properties !== this.props.properties) {
            this.setState({ periodPropertyCount: this.props.properties.length });
        }

        if (this.props.propertyValues && prevProps.propertyValues
            && prevProps.propertyValues.propertyTypeID !== this.props.propertyValues.propertyTypeID
            && !["Mixed Use"].includes(this.props.lookupTypes.propertyTypes.find(p => p.propertyTypeID == this.props.propertyValues.propertyTypeID).propertyTypeDescription)
        ) {
            const newValue = this.props.lookupTypes.propertyTypes.find(p => p.propertyTypeID == this.props.propertyValues.propertyTypeID).propertyTypeDescription;
            const newFormValue = newValue !== 'Not Selected' ? [newValue.replace("Multi-Family", "MultiFamily")] : [];
            this.props.change("property", "psqPropertyTypes", newFormValue);
        }
    }

  handleNewButtonClick() {
    this.setState({
      formProperty: {},
      showForm: true,
      showComponent: false,
      formPurpose: "ADD",
      showSuccess: null,
    });
  }

  handleDismissErrorMessage() {
    this.props.commonActions.clearFormErrors("property");
  }

  handleDismissSuccessMessage() {
    this.setState({ hideSuccessMessage: true });
  }

  setIsTestingEnabled(period) {
    const isEnabled = PeriodActions.isTestingEnabled(period);
    this.setState({ isTestingEnabled: isEnabled });
  }

  setCanAdd(period) {
    const isEnabled = PeriodActions.isTestingEnabled(period);
    this.setState({ canAdd: isEnabled });
  }

  setCanEdit(period) {
    const isEnabled = PeriodActions.isReportingEnabled(period);
    this.setState({ canEdit: isEnabled });
  }

  handleShowEndForm(propertyId) {
    this.props.actions.fetchProperty(propertyId).then(() => {
      let formProperty = Object.assign({}, this.props.property);
      this.setState({
        formProperty: formProperty,
        showComponent: false,
        showEndForm: true,
        showClearEndDateForm: false,
        showSuccess: null,
        formPurpose: "END",
      });
    });
  }

  handleShowClearEndDateForm(propertyId) {
    this.props.actions.fetchProperty(propertyId).then(() => {
      let formProperty = Object.assign({}, this.props.property);
      this.setState({
        formProperty: formProperty,
        showComponent: false,
        showEndForm: false,
        showClearEndDateForm: true,
        showSuccess: null,
        formPurpose: "CLEAR",
      });
    });
  }

  handleCloseEndForm() {
    this.setState({
      showComponent: false,
      showEndForm: false,
      showClearEndDateForm: false,
      showSuccess: null,
      formPurpose: "",
    });
  }

  /**
   * Handle Grid changes   */
  handleGridChanges(gridRowData, gridRefApi) {
    let rowData = [];
    rowData =
      gridRowData &&
      gridRowData.length &&
      gridRowData.map((x) => {
        return {
          propertyName: x.propertyName,
          propertyTypeDescription: x.propertyTypeDescription,
          purchaseDate: x.purchaseDate,
          percentageOfOwnership: x.percentageOfOwnership,
          propertyTrackingID: x.propertyTrackingID,
        };
      });

    this.setState({ gridCurrentState: rowData });

    if (gridRefApi) {
      gridRefApi.refreshCells();
    }
  }

  submitForm(values) {
    if (this.state.formPurpose === "END") {
      return this.endProperty(values);
    }

    if (this.state.formPurpose === "ADD") {
      return this.createProperty(values);
    }

    if (this.state.formPurpose === "CLEAR") {
      return this.clearEndDate(values);
    }

    return this.updateProperty(values);
  }

  clearEndDate(values) {
    if (!values || !values.propertyID) {
      return;
    }

    const propertyId = values.propertyID;

    this.props.commonActions.beginTask();

    return propertyActions
      .clearEndDate(propertyId)
      .then((data) => {
        if (actionHelpers.isErrorResponse(data)) {
          this.props.commonActions.endTask();
          return formHelpers.generateSubmissionError(data);
        }

        this.props.actions.fetchPropertiesByPeriod(this.props.periodId);
        this.props.actions.fetchFirstPropertyByPeriod(this.props.periodId);
        this.handleCloseEndForm();
        this.props.commonActions.endTask();
      })
      .catch((error) => {
        if (error instanceof SubmissionError) {
          this.props.commonActions.endTask();
          throw error;
        }

        this.props.commonActions.endTask();
        return formHelpers.generateSubmissionError();
      });
  }

  endProperty(values) {
    if (!values || !values.endDate) {
      return;
    }

    this.props.commonActions.beginTask();

    let endDate = new Date(values.endDate);
    let currentPeriodStartDate = new Date(this.props.period.startDate);
    let currentPeriodEndDate = new Date(this.props.period.endDate);

    if (endDate > currentPeriodEndDate || endDate < currentPeriodStartDate) {
      let errorResponse = {};
      errorResponse.message =
        "A property end date must be entered in the reporting period containing that date.";
      errorResponse.errorCode = "INVALID_DATE";

      this.props.commonActions.endTask();
      return formHelpers.generateSubmissionError(errorResponse);
    }

    const property = {
      cloningId: values.cloningID,
      endDate: values.endDate,
      id: values.propertyID,
      propertyName: values.propertyName,
      reportPeriodId: values.reportPeriodID,
      trackingId: values.propertyTrackingID,
      propagateChanges: true,
    };

    return propertyActions
      .endProperty(property)
      .then((data) => {
        if (actionHelpers.isErrorResponse(data)) {
          if (data && Array.isArray(data.validationErrors)) {
            for (let index = 0; index < data.validationErrors.length; index++) {
              if (
                data.validationErrors[index].element.toLowerCase() === "name"
              ) {
                data.validationErrors[index].element = "propertyName";
              }
            }
          }

          this.props.commonActions.endTask();
          return formHelpers.generateSubmissionError(data);
        }

        this.props.actions.fetchPropertiesByPeriod(this.props.periodId);
        this.props.actions.fetchFirstPropertyByPeriod(this.props.periodId);
        this.handleCloseEndForm();
        this.props.commonActions.endTask();
      })
      .catch((error) => {
        if (error instanceof SubmissionError) {
          throw error;
        }

        return formHelpers.generateSubmissionError();
      });
  }

  createProperty(values) {
    this.props.commonActions.beginTask();
    let propertyAmenities =
      PropertyAmenitiesTableContainer.convertFromReduxFormValueToPropertyAmenityCollection(
        values.propertyAmenities,
        0
      );

    const property = {
      accountantEmail: !values.accountantEmail
        ? ""
        : values.accountantEmail.trim(),
      accountantName: !values.accountantName
        ? ""
        : values.accountantName.trim(),
      accountantPhone: !values.accountantPhone ? "" : values.accountantPhone,
      propertyClassID: !values.propertyClassID ? "0" : values.propertyClassID,
      cloningId: null,
      locationAddressCity: values.locationAddressCity.trim(),
      locationAddressState: values.locationAddressState,
      locationAddressStreet: values.locationAddressStreet.trim(),
      locationAddressZipCode: values.locationAddressZipCode,
      managementCompany: !values.managementCompany
        ? ""
        : values.managementCompany.trim(),
      managerEmail: !values.managerEmail ? "" : values.managerEmail.trim(),
      managerName: !values.managerName ? "" : values.managerName.trim(),
      managerPhone: !values.managerPhone ? "" : values.managerPhone,
      propertyName: values.propertyName.trim(),
      percentageOfOwnership: values.percentageOfOwnership,
      propertyAmenity: propertyAmenities,
      propertyParkingTypeID: !values.propertyParkingTypeID
        ? "0"
        : values.propertyParkingTypeID,
      propertyTypeID: !values.propertyTypeID ? "0" : values.propertyTypeID,
      psqPropertyTypes: !values.psqPropertyTypes
        ? [0]
        : values.psqPropertyTypes.map((p) =>
            p.replace("Multi-Family", "MultiFamily")
          ),
      purchaseDate: values.purchaseDate,
      endDate: values.endDate,
      purchasePrice: values.purchasePrice,
      rentableSquareFootage: values.rentableSquareFootage,
      reportPeriodId: this.props.periodId,
      tenantTypeId: !values.tenantTypeId ? "0" : values.tenantTypeId,
      propagateProperty: true,
    };
    return propertyActions
      .createProperty(property)
      .then((data) => {
        if (actionHelpers.isErrorResponse(data)) {
          if (data && Array.isArray(data.validationErrors)) {
            for (let index = 0; index < data.validationErrors.length; index++) {
              if (
                data.validationErrors[index].element.toLowerCase() === "name"
              ) {
                data.validationErrors[index].element = "propertyName";
              }
            }
          }

          formHelpers.generateSubmissionError(data);
          this.props.commonActions.endTask();

          return;
        }

        this.props.actions.fetchPropertiesByPeriod(this.props.periodId);
        this.props.actions.fetchFirstPropertyByPeriod(this.props.periodId);
        this.closeForm();
        this.props.commonActions.endTask();
      })
      .catch((error) => {
        if (error instanceof SubmissionError) {
          this.props.commonActions.endTask();
          throw error;
        }

        formHelpers.generateSubmissionError();
        this.props.commonActions.endTask();
      });
  }

  updateProperty(values) {
    this.props.commonActions.beginTask();
    let propertyAmenities =
      PropertyAmenitiesTableContainer.convertFromReduxFormValueToPropertyAmenityCollection(
        values.propertyAmenities,
        values.propertyID
      );

    const property = {
      accountantEmail: values.accountantEmail.trim(),
      accountantName: values.accountantName.trim(),
      accountantPhone: values.accountantPhone,
      propertyClassID: values.propertyClassID,
      cloningId: values.cloningID,
      endDate: values.endDate,
      propertyId: values.propertyID,
      locationAddressCity: values.locationAddressCity.trim(),
      locationAddressState: values.locationAddressState,
      locationAddressStreet: values.locationAddressStreet.trim(),
      locationAddressZipCode: values.locationAddressZipCode,
      managementCompany: values.managementCompany.trim(),
      managerEmail: values.managerEmail.trim(),
      managerName: values.managerName.trim(),
      managerPhone: values.managerPhone,
      propertyName: values.propertyName.trim(),
      percentageOfOwnership: values.percentageOfOwnership,
      propertyAmenity: propertyAmenities,
      propertyParkingTypeID: values.propertyParkingTypeID,
      propertyTypeID: values.propertyTypeID,
      psqPropertyTypes: values.psqPropertyTypes.map((p) =>
        p.replace("Multi-Family", "MultiFamily")
      ) || [0],
      purchaseDate: values.purchaseDate,
      purchasePrice: values.purchasePrice,
      rentableSquareFootage: values.rentableSquareFootage,
      reportPeriodId: values.reportPeriodID,
      tenantTypeId: values.tenantTypeId,
      propagateChanges: true,
    };

    if (!property.rentableSquareFootage) {
      property.rentableSquareFootage = 0;
    }

    if (!property.purchasePrice) {
      property.purchasePrice = 0.0;
    }

    if (!property.percentageOfOwnership) {
      property.percentageOfOwnership = 100.0;
    }

    return propertyActions
      .updateProperty(property)
      .then((data) => {
        if (actionHelpers.isErrorResponse(data)) {
          if (data && Array.isArray(data.validationErrors)) {
            for (let index = 0; index < data.validationErrors.length; index++) {
              if (
                data.validationErrors[index].element.toLowerCase() === "name"
              ) {
                data.validationErrors[index].element = "propertyName";
              }
            }
          }

          formHelpers.generateSubmissionError(data);
          this.props.commonActions.endTask();
          return;
        }

        this.props.actions.fetchPropertiesByPeriod(this.props.periodId);
        this.props.actions.fetchFirstPropertyByPeriod(this.props.periodId);
        this.closeForm();
        this.props.commonActions.endTask();
      })
      .catch((error) => {
        if (error instanceof SubmissionError) {
          this.props.commonActions.endTask();
          throw error;
        }

        formHelpers.generateSubmissionError();
        this.props.commonActions.endTask();
      });
  }

  showForm() {
    this.setState({ showForm: true, formPurpose: "", showSuccess: null });
  }

  closeForm() {
    this.setState({ showForm: false, showSuccess: null });
  }

  handleCloseComponent() {
    this.setState({ showComponent: false, showSuccess: null });
  }

  handleOpenComponent(propertyId) {
    this.props.actions.fetchProperty(propertyId);
    this.setState({ showComponent: true, showForm: false, showSuccess: null });
  }

  handleOpenDeleteProperty(propertyId) {
    this.props.actions.fetchProperty(propertyId).then(() => {
      this.props.actions.fetchCanDeleteProperty(propertyId).then(() =>
        this.setState({
          showDeletePropertyModal: true,
          showForm: false,
          showSuccess: null,
          selectedPropertyId: propertyId,
        })
      );
    });
  }

  handleYesDeleteProperty() {
    this.props.actions
      .deleteProperty(this.state.selectedPropertyId)
      .then(() => {
        this.props.actions.fetchPropertiesByPeriod(this.props.periodId);
        this.setState({
          showDeletePropertyModal: false,
          selectedPropertyId: null,
        });
      });
  }

  handleNoDeleteProperty() {
    this.setState({ showDeletePropertyModal: false, selectedPropertyId: null });
  }

  handleEditButtonClick() {
    let formProperty = Object.assign({}, this.props.property);
    formProperty.propertyAmenities =
      PropertyAmenitiesTableContainer.convertFromPropertyAmenityCollectionToReduxFormValue(
        formProperty.propertyAmenities
      );

    this.setState({
      formProperty: formProperty,
      showForm: true,
      showComponent: false,
      formPurpose: "EDIT",
      showSuccess: null,
    });
  }

  resetGridColumnState() {
    localStorage.removeItem("REITSuitePropertyColumnState");
  }

  render() {
    let property = Object.assign({}, this.props.property);
    property.propertyAmenities =
      PropertyAmenitiesTableContainer.convertFromPropertyAmenityCollectionToReduxFormValue(
        property.propertyAmenities
      );

    return (
      <PropertyList
        period={this.props.period}
        properties={this.props.properties}
        lookupTypes={this.props.lookupTypes}
        deletePropertyDetail={this.props.deletePropertyDetail}
        handleYesDeleteProperty={this.handleYesDeleteProperty}
        handleNoDeleteProperty={this.handleNoDeleteProperty}
        isTestingEnabled={this.state.isTestingEnabled}
        canAdd={this.state.canAdd}
        canEdit={this.state.canEdit}
        property={property}
        formProperty={this.state.formProperty}
        showForm={this.state.showForm}
        handleCloseForm={this.closeForm}
        handleNewButtonClick={this.handleNewButtonClick}
        handleSubmit={this.submitForm}
        formPurpose={this.state.formPurpose}
        handleEditButtonClick={this.handleEditButtonClick}
        handleDeleteButtonClick={this.handleDeleteButtonClick}
        showSuccess={this.props.showSuccess && !this.state.hideSuccessMessage}
        handleDismissErrorMessage={this.handleDismissErrorMessage}
        handleDismissSuccessMessage={this.handleDismissSuccessMessage}
        periodPropertyCount={this.state.periodPropertyCount}
        hideSuccessMessage={this.state.hideSuccessMessage}
        showComponent={this.state.showComponent}
        showDeletePropertyModal={this.state.showDeletePropertyModal}
        handleCloseComponent={this.handleCloseComponent}
        handleOpenComponent={this.handleOpenComponent}
        taxableReitSubsidiaries={this.props.taxableReitSubsidiaries}
        tbMode={this.state.tbMode}
        handleShowEndForm={this.handleShowEndForm}
        showEndForm={this.state.showEndForm}
        showClearEndDateForm={this.state.showClearEndDateForm}
        handleCloseEndForm={this.handleCloseEndForm}
        handleShowClearEndDateForm={this.handleShowClearEndDateForm}
        handleOpenDeleteProperty={this.handleOpenDeleteProperty}
        handleGridChanges={this.handleGridChanges}
      />
    );
  }
}

PropertiesPage.propTypes = {
  reitId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  periodId: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  period: PropTypes.object,
  properties: PropTypes.array,
  property: PropTypes.object,
  taxableReitSubsidiaries: PropTypes.array,
  showSuccess: PropTypes.bool,
  periodActions: PropTypes.object.isRequired,
  trsActions: PropTypes.object.isRequired,
  commonActions: PropTypes.object.isRequired,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    const searchParams = new URLSearchParams(ownProps.router.location.search);
    const showSuccess = searchParams.get('showSuccess');

    return {
        reitId: Number.parseInt(ownProps.router.params.reitId),
        clientId: Number.parseInt(ownProps.router.params.clientId),
        periodId: Number.parseInt(ownProps.router.params.periodId),
        period: state.period,
        properties: state.properties,
        deletePropertyDetail: state.deletePropertyDetail,
        currentUserAuthorizations: state.currentUserAuthorizations,
        isSysAdmin: Array.isArray(state.currentUserAuthorizations) && authActions.isSystemAdministrator(state.currentUserAuthorizations),
        property: state.property,
        showSuccess: showSuccess,
        taxableReitSubsidiaries: state.taxableReitSubsidiaries,
        propertyValues: getFormValues('property')(state),
        lookupTypes: state.lookupTypes
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(propertyActions, dispatch),
    periodActions: bindActionCreators(PeriodActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    trsActions: bindActionCreators(trsActions, dispatch),
    lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch),
    fetchProperty: (propertyId) =>
      propertyActions.fetchProperty(propertyId)(dispatch),
    change: bindActionCreators(change, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertiesPage));
