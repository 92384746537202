import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_PREVIOUS_ARQC_CHECKLIST_SUCCESS action and sets the previous ARQC checklist in the store
 * @param {Object} [state] The previous ARQC checklist currently in the store, or the initial state of the previous ARQC checklist
 * @param {Object} action The action to be handled
 * @returns {Object} The checklist
 */
export default function arqcPreviousChecklistReducer(state = initialState.previousArqcChecklist, action) {
    switch (action.type) {
        case actions.LOAD_PREVIOUS_ARQC_CHECKLIST_SUCCESS:
            if (action.arqcChecklist) {
                return action.arqcChecklist;
            } else {
                return null;
            }
        case actions.UNLOAD_TESTS:
            return initialState.previousArqcChecklist;
        case actions.LOAD_PREVIOUS_ARQC_CHECKLIST_FAILURE:
            return null; // Clear previous checklist on failure
        default:
            return state;
    }
}