import { pendingTask, begin, end, endAll } from '../constants/pendingTask';
import * as actions from './actionTypes';
import {
  fetchFromTrialBalanceApi as fetch,
  buildHeaders,
} from './fetchFromApi';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';
import * as actionHelpers from '../scripts/actionHelpers';
import * as notificationActions from './notificationActions';
import urlJoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';

/**
 * Upload a trial balance file
 * @param {any} periodId The id of the REIT that the trial balance belongs to
 * @param {any} type The trial balance type (Raw or Adjusted)
 * @param {any} file The trial balance file
 * @param {any} trialBalancePurpose The trial balance purpose (Reit = 1 or Property = 2)
 * @param {any} propertyId The trial balance property ID
 * @param {any} percentageOfOwnership The percentage of ownership
 * @param {any} uploadId The upload ID
 * @returns {function} A function that returns a Promise
 */
export function uploadTrialBalance(
  periodId,
  type,
  file,
  trialBalancePurpose,
  propertyId,
  percentageOfOwnership,
  uploadId,
  clientId
) {
  let formData = new FormData();
  formData.append('periodId', periodId);
  formData.append('trialBalanceType', type);
  formData.append('file', file);
  formData.append('trialBalancePurpose', trialBalancePurpose);
  formData.append('uploadId', uploadId);
  formData.append('clientId', clientId);
  if (!percentageOfOwnership) {
    percentageOfOwnership = 100.0;
  }
  formData.append('percentageOfOwnership', percentageOfOwnership * 1);
  if (propertyId > 0) {
    formData.append('propertyId', propertyId);
  }
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch('/trialBalances', {
      method: 'POST',
      body: formData,
    })
      .then(response => {
        if (response.status === 201) {
          dispatch({ type: actions.UPLOAD_TB_SUCCESS, [pendingTask]: end });
          return null;
        }

        return response.json();
      })
      .then(response => {
        if (response === null) {
          return;
        }

        if (actionHelpers.isErrorResponse(response)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPLOAD_TB_FAILURE,
            response,
          );
        }
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPLOAD_TB_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Gets a single trial balance item by it's ID
 * @param {any} id The item id
 * @returns {any} A promise
 */
export function getTrialBalanceItem(id) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/trialBalances/item/' + id)
      .then(response => {
        return response.json();
      })
      .then(trialBalanceItem => {
        if (actionHelpers.isErrorResponse(trialBalanceItem)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_TB_ITEM_FAILURE,
            trialBalanceItem,
          );
        }

        dispatch({
          type: actions.LOAD_TB_ITEM_SUCCESS,
          trialBalanceItem,
          [pendingTask]: end,
        });
        return trialBalanceItem;
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_TB_ITEM_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Dispatches the UPLOAD_TB_CLEAR action
 * @returns {function} A function that dispatches the UPLOAD_TB_CLEAR action
 */
export function clearUploadTbSuccess() {
  return function (dispatch) {
    dispatch({ type: actions.UPLOAD_TB_CLEAR });
  };
}

/**
 * Fetch a list of trial balances by report period
 * @param {number} reportPeriodId The id of the report period
 * @param {any} trialBalanceType The trial balance type
 * @param {any} trialBalancePurpose The trial balance purpose
 * @param {number} propertyId The property ID
 * @param {any} authHeader The auth header
 * @returns {array} An array of trial balances
 */
export function getTrialBalancesByPeriod(
  reportPeriodId,
  trialBalanceType,
  trialBalancePurpose,
  propertyId,
  authHeader,
) {
  let url = '/trialBalances?periodId=' + reportPeriodId;
  if (trialBalanceType) {
    url += '&type=' + trialBalanceType;
  }
  if (trialBalancePurpose) {
    url += '&purpose=' + trialBalancePurpose;
  }
  if (propertyId > 0) {
    url += '&propertyid=' + propertyId;
  }

  return fetch(url, {
    headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
  })
    .then(response => {
      return response.json();
    })
    .catch(error => {
      throw error;
    });
}

/**
 * Gets the last trial balance by period
 * @param {number} reportPeriodId The period ID
 * @param {any} purpose The trial balance purpose
 * @returns {function} A function that returns a Promise.
 */
export function getLastTrialBalanceByPeriod(reportPeriodId, purpose) {
  if (!purpose) {
    purpose = 'consolidated';
  }
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return getTrialBalancesByPeriod(reportPeriodId, 'adjusted', purpose)
      .then(trialBalances => {
        if (actionHelpers.isErrorResponse(trialBalances)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_LAST_TRIAL_BALANCE_FAILURE,
            trialBalances,
          );
        }
        let lastTrialBalance = trialBalances
          .sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate))
          .find(x => true);
        dispatch({
          type: actions.LOAD_LAST_TRIAL_BALANCE_SUCCESS,
          lastTrialBalance: lastTrialBalance,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_LAST_TRIAL_BALANCE_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Gets the last consolidated trial balance by period
 * @param {number} reportPeriodId The period ID
 * @returns {function} A function that returns a Promise.
 */
export function getLastConsolidatedTrialBalanceByPeriod(reportPeriodId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return getTrialBalancesByPeriod(reportPeriodId, 'adjusted', 'consolidated')
      .then(trialBalances => {
        if (actionHelpers.isErrorResponse(trialBalances)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_FAILURE,
            trialBalances,
          );
        }

        let lastConsolidatedTrialBalance = trialBalances
          .sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate))
          .find(x => true);
        dispatch({
          type: actions.LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_SUCCESS,
          lastConsolidatedTrialBalance: lastConsolidatedTrialBalance,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Gets the last property trial balance by period
 * @param {any} reportPeriodId The period ID
 * @param {any} propertyId The property ID
 * @returns {function} A function that returns a Promise.
 */
export function getLastPropertyTrialBalanceByPeriod(
  reportPeriodId,
  propertyId,
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    // Return the reit tb for now.
    return getTrialBalancesByPeriod(
      reportPeriodId,
      'adjusted',
      'property',
      propertyId,
    )
      .then(trialBalances => {
        if (actionHelpers.isErrorResponse(trialBalances)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_LAST_PROPERTY_TRIAL_BALANCE_FAILURE,
            trialBalances,
          );
        }

        let lastPropertyTrialBalance = trialBalances
          .sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate))
          .find(x => true);
        dispatch({
          type: actions.LOAD_LAST_PROPERTY_TRIAL_BALANCE_SUCCESS,
          lastPropertyTrialBalance: lastPropertyTrialBalance,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_LAST_PROPERTY_TRIAL_BALANCE_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch a list of trial balances by report period
 * @param {any} reportPeriodId The id of the report period
 * @returns {array} An array of trial balances
 */
export function getAdjustedTrialBalancesByPeriod(reportPeriodId) {
  return fetch('/trialBalances?periodId=' + reportPeriodId + '&type=adjusted')
    .then(response => {
      return response.json();
    })
    .catch(error => {
      throw error;
    });
}

/**
 * Download the trial balance blob
 * @param {any} trialBalanceId The id of the trial balance
 * @returns {Promise} A promise that downloads a file
 */
export function downloadTrialBalance(trialBalanceId) {
  let url = `/trialbalances/downloadTB?trialBalanceId=${trialBalanceId}`;
  return fetch(url).then(function (response) {
    return response.blob().then(function (blob) {
      const contentDisposition =
        response.headers.get("Content-Disposition");

      const filename =
        contentDisposition.split(';')[1].split('=')[1].trim();

      download(blob, filename);
    });
  });
}

/**
 * Deletes a trial balance
 * @param {any} periodId The period ID
 * @param {any} trialBalancePurpose The trial balance purpose
 * @param {any} propertyId The property ID
 * @returns {Promise} A promise
 */
export function deleteTrialBalance(periodId, trialBalancePurpose, propertyId) {
  let url = '/trialBalances?periodId=' + periodId;
  if (trialBalancePurpose) {
    url += '&purpose=' + trialBalancePurpose;
  }
  if (propertyId > 0) {
    url += '&propertyid=' + propertyId;
  }
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  }).then(response => {
    if (response.ok) {
      return null;
    } else {
      return response.json();
    }
  });
}

/**
 * Get trial balance mappings by period
 * @param {any} reportPeriodId The period ID
 * @param {any} propertyId The property ID (optional)
 * @param {any} filterByProperty Should filter by property ID? (optional)
 * @returns {function} A function that returns a Promise.
 */
export function getMappingsByPeriod(reportPeriodId, propertyId = null, filterByProperty = false) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const propertyIdQuery = propertyId && propertyId > 0 ? `&propertyId=${propertyId}` : '';
    const filterByPropertyQuery = filterByProperty ? '&filterByProperty=true' : '';
    return fetch('/clientaccounts?periodId=' + reportPeriodId + propertyIdQuery + filterByPropertyQuery)
      .then(response => {
        return response.json();
      })
      .then(periodMappings => {
        if (actionHelpers.isErrorResponse(periodMappings)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_PERIOD_MAPPINGS_FAILURE,
            periodMappings,
          );
        }

        return dispatch({
          type: actions.LOAD_PERIOD_MAPPINGS_SUCCESS,
          periodMappings,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_PERIOD_MAPPINGS_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Get the trial balance mapping by ID
 * @param {any} id The client account mapping ID
 * @returns {function} A function that returns a Promise.
 */
export function getMappingById(id) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/clientaccounts/' + id)
      .then(response => {
        return response.json();
      })
      .then(mapping => {
        if (actionHelpers.isErrorResponse(mapping)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_MAPPING_FAILURE,
            mapping,
          );
        }

        dispatch({
          type: actions.LOAD_MAPPING_SUCCESS,
          mapping,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_MAPPING_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Updates an existing client account mapping
 * @param {any} mapping The mapping to update
 * @returns {Promise} A promise
 */
export function updateMapping(mapping) {
  return fetch('/clientaccounts/mapping', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(mapping),
  }).then(response => {
    return response.json();
  });
}

/**
 * Creates a new client account mapping across all REITs for a client
 * @param {any} mapping The mapping to create
 * @returns {Promise} A promise
 */
export function createMappingAllReits(mapping) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch('/clientaccounts/mappingAllReits', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(mapping),
    })
      .then(response => {
        return response.json();
      })
      .then(mappingAllReitsResponse => {
        if (actionHelpers.isErrorResponse(mappingAllReitsResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.CREATE_MAPPING_ALL_REITS_FAILURE,
            mappingAllReitsResponse,
          );
        }

        dispatch({
          type: actions.CREATE_MAPPING_ALL_REITS_SUCCESS,
          mappingAllReitsResponse,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CREATE_MAPPING_ALL_REITS_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Deletes mappings by period ID
 * @param {any} periodId The period ID
 * @returns {Promise} A promise
 */
export function deletePeriodMappings(periodId) {
  let url = '/clientaccounts?periodId=' + periodId;

  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  }).then(response => {
    if (response.ok) {
      return null;
    } else {
      return response.json();
    }
  });
}

/**
 * Downlaods the client account mappings file for a period
 * @param {any} periodId The period ID
 * @returns {Promise} A promise
 */
export function downloadPeriodMappingsFile(periodId) {
  const url = '/clientaccounts/export?periodId=' + periodId;

  return fetch(url).then(function (response) {
    return response.blob().then(function (blob) {
      const contentDisposition = response.headers.get("Content-Disposition");
      const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
      //let fileName = 'PeriodMappings.xlsx';
      download(blob, fileName);
    });
  });
}

/**
 * Uploads a trial balance
 * @param {any} encodedFile The encoded file
 * @param {any} fileName The file name
 * @param {any} uploadId The upload ID
 * @param {any} userName The username
 * @returns {function} A function that returns a Promise.
 */
export function uploadTrialBalanceWithProgress(
  encodedFile,
  fileName,
  uploadId,
  userName,
) {
  return function (dispatch) {
    dispatch({ type: actions.TB_UPLOAD_START, file: { file, uploadId } });

    let arr = encodedFile.split(',');
    let match = arr[0].match(/:(.*?);/);

    if (match == null || arr.length < 2) {
      let notifications = [];
      let content = {
        message: '',
        error: {
          message: `${fileName} failed to upload for the following reason: Unable to read content of this file type.`,
        },
      };
      let notif = {
        id: uploadId,
        userName: userName,
        notificationType: 'TrialBalanceFailure',
        content: JSON.stringify(content),
      };

      notifications.push(notif);

      notificationActions.addOrUpdateNotifications(notifications, userName)(
        dispatch,
      );
      return;
    }

    let mime = match[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let file = new File([u8arr], fileName, { type: mime });

    let formData = new FormData();

    formData.append('file', file);
    formData.append('uploadId', uploadId);

    const req = new XMLHttpRequest();
    const init = buildHeaders();

    const rootApiUrl = process.env.ROOT_TB_API_URL;
    const fullUrl = urlJoin(rootApiUrl, '/trialBalances');

    req.open('POST', fullUrl);

    // Set authorization headers
    if (init && init.headers) {
      for (let property in init.headers) {
        if (Object.prototype.hasOwnProperty.call(init.headers, property)) {
          req.setRequestHeader(property, init.headers[property]);
        }
      }
    }

    let notifications = [];
    let content = {
      message: '',
      error: { message: `${fileName} failed to upload` },
    };
    let notif = {
      id: uploadId,
      userName: userName,
      notificationType: 'TrialBalanceFailure',
      content: JSON.stringify(content),
    };

    notifications.push(notif);

    req.addEventListener('load', e => {
      if (req.status >= 200 && req.status <= 299) {
        dispatch({ type: actions.TB_UPLOAD_COMPLETE, fileName });
      } else {
        let errorResponse = null;
        if (req.response) {
          errorResponse = JSON.parse(req.response);
        }

        if (errorResponse) {
          errorResponse.message = `${fileName} failed to upload for the following reason: ${errorResponse.message
            }`;
          content.error = errorResponse;
          notif.content = JSON.stringify(content);
        }

        notifications = [notif];

        notificationActions.addOrUpdateNotifications(notifications, userName)(
          dispatch,
        );
        dispatch({ type: actions.TB_UPLOAD_FAILED, fileName });
      }
    });

    req.addEventListener('error', e => {
      notificationActions.addOrUpdateNotifications(notifications, userName)(
        dispatch,
      );
      dispatch({ type: actions.TB_UPLOAD_FAILED, fileName });
    });

    req.upload.addEventListener('progress', e => {
      let progress = 0;
      if (e.total !== 0) {
        progress = parseInt((e.loaded / e.total) * 100, 10);
      }

      dispatch({ type: actions.TB_UPLOAD_PROGRESS, fileName, progress });
    });

    req.send(formData);
  };
}

/**
 * Dispatches the TB_UPLOAD_CLEAR action
 * @returns {function} A function that dispatches the TB_UPLOAD_CLEAR action
 */
export function clearUploadTrialBalanceProgress() {
  return function (dispatch) {
    dispatch({ type: actions.TB_UPLOAD_CLEAR });
  };
}

/**
 * Creates notifications for rejected files
 * @param {any} files The rejected files
 * @param {any} userName The username
 * @returns {function} A function that returns a Promise.
 */
export function notifyRejectedFiles(files, userName) {
  return function (dispatch) {
    let notifications = [];
    if (Array.isArray(files)) {
      files.forEach(f => {
        const id = uuidv4();
        let content = {
          message: `${f.file.name
            } is not a trial balance file and will not be processed.`,
        };
        if (f.file.name.toLowerCase().endsWith('.xls')) {
          content = {
            message: `${f.file.name
              } is not a supported excel format and cannot be processed. Only files with .xlsx extension are supported. Please save your spreadsheet as using Excel 2007 or later and try again. `,
          };
        }
        else if (!f.file.name.toLowerCase().endsWith('.xlsx')) {
          content = {
            message: `${f.file.name
              } failed to upload. The format of the file you submitted is not supported. The only supported format for a Trial Balance Template is XLSX. `,
          };
        }

        const notif = {
          id: id,
          userName: userName,
          notificationType: 'TrialBalanceFailure',
          content: JSON.stringify(content),
        };

        notifications.push(notif);
      });
    }

    if (notifications.length > 0) {
      notificationActions.addOrUpdateNotifications(notifications, userName)(
        dispatch,
      );
    }
  };
}

const MAX_PARALLEL_TB_UPLOADS = 5;

/**
 * Adds files to the upload queue
 * @param {any} files The files to add to the queue
 * @param {any} userName The username
 * @returns {function} A function that returns a Promise.
 */
export function addFilesToQueue(files, userName, clientId, notifyStatus) {
  if (typeof notifyStatus !== 'function') {
    notifyStatus = (file, status) => console.log(`${file.name}: ${status}`);
  }

  const uploadId = uuidv4();
  const fileArrayCopy = [...files];

  function sendFiles(dispatch) {
    if (fileArrayCopy.length <= 0) {
      return;
    }

    const data = new FormData();
    data.append('clientId', clientId);
    data.append('uploadId', uploadId);

    const filesForThisBatch = [];

    while (filesForThisBatch.length < MAX_PARALLEL_TB_UPLOADS) {
      const f = fileArrayCopy.shift();

      if (!f) {
        break;
      }

      filesForThisBatch.push(f);
      data.append('files', f);
    }

    filesForThisBatch.forEach(f => notifyStatus(f, 'IN_PROGRESS'));

    fetch('/trialBalances/bulkUpload', {
      method: 'POST',
      body: data
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('File upload failed.');
        }

        filesForThisBatch.forEach(f => notifyStatus(f, 'UPLOADED'));
      })
      .catch(error => {
        console.error(error);
        filesForThisBatch.forEach(f => notifyStatus(f, 'ERROR'));
      })
      .then(() => sendFiles(dispatch));
  }

  return function (dispatch) {
    sendFiles(dispatch);
  };
}

/**
 * Gets the upload queue count
 * @returns {function} A function that returns a Promise.
 */
export function fetchQueueCount() {
  return function (dispatch) {
    return fetch('/trialbalances/queue')
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (actionHelpers.isErrorResponse(data)) {
          dispatch({ type: actions.LOAD_QUEUE_COUNT_FAILURE });
        }

        dispatch({ type: actions.LOAD_QUEUE_COUNT_SUCCESS, count: data });
      })
      .catch(error => {
        dispatch({ type: actions.LOAD_QUEUE_COUNT_FAILURE });
      });
  };
}

/**
 * Downloads the TB Templates for the REIT and all Properties of a client. The template reporting period will be the real-time current quarter, unless a period ID is provided.
 * @param {number} clientId The client ID.
 * @param {bool} showForInactiveREIT A flag to show inactive REITs along with the active.
 * @param {any} periodId The optional period ID to determine the template reporting period and properities of that period.
 * @returns {function} A Promise.
 */
export function downloadTbTemplates(clientId, showForInactiveREIT, reportPeriodId) {
  return function (dispatch) {

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    let url = `/trialbalances/${clientId}/download?showForInactiveREIT=${showForInactiveREIT}`;
    if (reportPeriodId) {
      url += `&reportPeriodId=${reportPeriodId}`;
    }

    return fetch(url).then(function (response) {
      const contentType = response.headers.get("content-type");

      //Check the content type
      //if application/json - Display server error otherwise download the blob
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      }
      else {
        return response.blob().then(function (blob) {
          const contentDisposition =
            response.headers.get("Content-Disposition");

          const filename =
            contentDisposition.split(';')[1].split('=')[1].trim();

          download(blob, decodeURIComponentSafe(filename, 1));
        });
      }
    }).then(response => {
      if (response) {

        let responseReturn = {};
        responseReturn.error = false;
        if (actionHelpers.isErrorResponse(response)) {
          responseReturn.error = true;
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DOWNLOAD_TB_TEMPLATE_FAILURE,
            response,
          );
        }
        return dispatch({ type: actions.TASK_END, [pendingTask]: end });
      }
      else {
        //Download BLOB Success
        return dispatch({ type: actions.TASK_END, [pendingTask]: end });
      }
    });

  };
}


/**
 * Dispatches the SHOW_BULK_UPLOAD_MODAL action
 * @returns {function} A function that dispatches the SHOW_BULK_UPLOAD_MODAL action
 */
export function showBulkUploadModal() {
  return function (dispatch) {
    dispatch({ type: actions.SHOW_BULK_UPLOAD_MODAL });
  };
}

/**
 * Dispatches the HIDE_BULK_UPLOAD_MODAL action
 * @returns {function} A function that dispatches the HIDE_BULK_UPLOAD_MODAL action
 */
export function hideBulkUploadModal() {
  return function (dispatch) {
    dispatch({ type: actions.HIDE_BULK_UPLOAD_MODAL });
  };
}

/**
 * Upload chart of accounts
 * If failure, it will dispatch the UPLOAD_CHART_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} chartId The id of the chart.
 * @param {file} file The excel file to be used for saving chart of accounts.
 * @returns {function} A function that returns a Promise.
 */
export function uploadAccountMappings(clientId, periodId, files) {

  let formData = new FormData();
  formData.append('clientID', clientId);
  formData.append('periodID', periodId);
  files.forEach(file => {
    formData.append('file', file);
  });

  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch('/clientaccounts/uploadAccountMappings', {
      method: 'POST',
      body: formData,
    })
      .then(response => {
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        }
        else {
          return response.text();
        }
      })
      .then(response => {
        let responseReturn = {};
        responseReturn.error = false;
        if (actionHelpers.isErrorResponse(response)) {
          responseReturn.error = true;
          actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPLOAD_CHART_FAILURE,
            response,
          );
          return responseReturn;
        }
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return responseReturn;
      })
      .catch(error => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPLOAD_CHART_FAILURE,
          null,
          error,
        );
      });
  };
}