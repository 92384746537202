import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as distributionActions from "../../actions/distributionsTestActions";
import DistributionTestSummary from "../../components/reits/DistributionTestSummary";
import DistributionDetailTable from "../../components/reits/DistributionDetailTable";
import { getFormValues } from "redux-form";

/**
 * ChecklistDistributionDetailsContainer container component
 * @extends {React.Component}
 */
export class ChecklistDistributionDetailsContainer extends React.Component {
    /**
      * Creates a new ChecklistDistributionDetailsContainer
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);

        this.state = { tbMode: 1 };
    }

    componentDidMount() {
        if (this.props.periodId && this.props.isSectionVisible) {
            if (!this.props.isExternal || this.props.authenticationScope === 'external') {
                this.props.distributionActions.fetchDistributionDetail(this.props.periodId);
                this.props.distributionActions.fetchDistributionTestSummary(this.props.periodId);
                this.props.distributionActions.fetchDistributionTaxableIncome(this.props.periodId);
            }

            if (this.props.isExternal && this.props.checklistId && this.props.isArqc) {
                this.props.distributionActions.fetchExternalDistributionDetailByChecklistId(this.props.periodId, this.props.checklistId, "ARQC");
                this.props.distributionActions.fetchExternalDistributionTestSummaryByChecklistId(this.props.periodId, this.props.checklistId, "ARQC");
                this.props.distributionActions.fetchExternalDistributionTaxableIncomeByChecklistId(this.props.periodId, this.props.checklistId, "ARQC");
            }
        }
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        if (!this.props.distributionTestSummary) {
            return null;
        }

        return (
            <div>
                <DistributionTestSummary distributionTestSummary={this.props.distributionTestSummary}
                    testHeader="Distribution Test Summary" distributionTaxableIncome={this.props.distributionTaxableIncome}
                    distributionTaxableIncomeValue={this.props.distributionTaxableIncome != null ? this.props.distributionTaxableIncome.distributionREITTaxableIncomeAmount : null}
                    distributionDetails={this.props.distributionDetails} totalValue={distributionActions.sumDistributionValues(this.props.distributionDetails)} readOnly={true} isChecklist={true} />
                <DistributionDetailTable distributionDetails={this.props.distributionDetails} readOnly={true} isChecklist={true} />
            </div>
        );
    }
}

ChecklistDistributionDetailsContainer.propTypes = {
    propertyActions: PropTypes.object,
    distributionActions: PropTypes.object,
    periodId: PropTypes.number,
    distributionTestSummary: PropTypes.shape({
        tests: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            isPassing: PropTypes.bool,
            percent: PropTypes.number
        })),
        newAccounts: PropTypes.number,
        deletedAccounts: PropTypes.number
    }),
    distributionDetails: PropTypes.array,
    distributionTaxableIncome: PropTypes.shape({
        reportPeriod: PropTypes.string,
        reportPeriodId: PropTypes.number,
        value: PropTypes.number
    }),
    isSectionVisible: PropTypes.bool,
    checklistId: PropTypes.string,
    isArqc: PropTypes.bool,
    isExternal: PropTypes.bool,
    authenticationScope: PropTypes.string
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: ownProps.periodId,
        checklistId: ownProps.checklistId,
        isSectionVisible: ownProps.isSectionVisible,
        isExternal: ownProps.isExternal,
        distributionDetails: state.distributionDetails,
        distributionTestSummary: state.distributionTestSummary,
        distributionTaxableIncome: state.distributionTaxableIncome,
        isArqc: ownProps.isArqc,
        authenticationScope: state.authenticationScope,
        createEditDistributiontFormValues: getFormValues('distribution')(state)
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        distributionActions: bindActionCreators(distributionActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDistributionDetailsContainer);