import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch all TRS records belonging to the period. If successful this will dispatch the LOAD_TRSES_SUCCESS
 * action, otherwise it will dispatch the LOAD_TRSES_FAILURE action.
 * @param {number} periodId The id of the period that owns the TRS.
 * @param {string} authHeader The authorzation header.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchTrsByPeriod(periodId, authHeader) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/trs?periodId=${periodId}`,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            }).then(response => {
            return response.json();
        }).then(trs => {
            if (actionHelpers.isErrorResponse(trs)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TRSES_FAILURE, trs);
            }

            dispatch({ type: actions.LOAD_TRSES_SUCCESS, trs, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TRSES_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the TRS as an external user by the specified period identifier.
 * @param {number} periodId The id of the period that owns the TRS.
 * @param {string} checklistId The id of the checklist.
 * @param {string} checklistType The checklist type.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalTrsByPeriod(periodId, checklistId, checklistType) {
    return fetchTrsByPeriod(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}


/**
 * Create a new TRS.
 * @param {any} trs The TRS
 * @returns {function} A Promise.
 */
export function createTrs(trs) {
    return fetch('/trs',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(trs)
        }).then(response => {
            if (response.ok) {
                return null;
            }

            return response.json();
    });
}


/**
 * Updates a trs to the REIT API database.
 * @param {any} trs The trs
 * @returns {Promise} A Promise
 * http://localhost:5000/api/trs/3
 */
export function updateTrs(trs) {
    const period = trs.reportPeriodID;
    const payload = {
        "trsDebt": trs.trsDebt,
        "trsEquity": trs.trsEquity,
        "taxableREITSubsidiaryID": trs.taxableREITSubsidiaryID,
        "trsName": trs.trsName,
        "trsValue": trs.trsValue
    };

    return fetch(`/trs/${trs.taxableREITSubsidiaryID}`,
        {
            headers: {
                'Accept':
                "application/json",
                'Content-Type': "application/json"
            },
            method: "PUT",
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                return null;
            }

            return response.json();
        });
}

/**
 * Deletes a trs to the REIT API database.
 * @param {any} trs The trs
 * @returns {Promise} A Promise
 * http://localhost:5000/api/trs/3
 */
export function deleteTrs(trs) {
    const period = trs.reportPeriodID;
    return fetch(`/trs/${trs.taxableREITSubsidiaryID}`,
        {
            headers: {
                'Accept':
                "application/json",
                'Content-Type': "application/json"
            },
            method: "DELETE"
        }).then(response => {
            if (response.ok)
                return null;

            return response.json();
        });
}