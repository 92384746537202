// Comparator for string containing numbers and title, for e.g. "1 - Title 1", "1.1 - Title 2", "1.1.1 - Title 3"
export default (questionTitle1, questionTitle2) => {
    // Get the number & title parts from input
    let nt1 = questionTitle1.split(' - ');
    let nt2 = questionTitle2.split(' - ');

    // Get the numbers from number part
    let nums1 = nt1[0].split('.');
    let nums2 = nt2[0].split('.');

    // Check if any number part is actually a string
    const isNanNT1 = isNaN(nt1[0].replace('.', ''));
    const isNanNT2 = isNaN(nt2[0].replace('.', ''));

    // Check if there are non number strings in both input then compare them on string basis
    if (isNanNT1 && isNanNT2) {
        return (nt1[0] === nt2[0]) ? 0 : (nt1[0] < nt2[0]) ? -1 : 1;
    }
    // Check if there is non number string in one input then move the string to last
    else if (isNanNT1) {
        return 1;
    }
    else if (isNanNT2) {
        return -1;
    }

    // Check if the number part is same for both, then return the result as both match
    if (nt1[0] === nt2[0]) {
        return 0;
    }

    // Check each number from number part and compare and return the result
    while (nums1.length) {
        let result = nums1.shift() - (nums2.shift() || 0);
        if (result) {
            return result;
        }
    }

    // Finaly, return minus if nums1 matches with nums2 and has less number of elements in array
    return -nums2.length;
}