import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";

import { AgGridReact } from 'ag-grid-react';
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem, MotifTable } from "@ey-xd/motif-react";

import CustomHeaderTemplate from '../shared/customHeaders/CustomHeaderTemplate.js';
import BulkRollForwardReitCheckboxHeaderRenderer from "./customRenderers/BulkRollForwardReitCheckboxHeaderRenderer.js";
import BulkRollForwardReitCheckboxCellRenderer from "./customRenderers/BulkRollForwardReitCheckboxCellRenderer.js";
import CustomReitToolTip from "./customRenderers/CustomReitToolTip.js";


const BulkRollForwardTable = ({
    bulkRollForwardData,
    gridColumnState,
    handleGridChanges,
    handleGridColumnStateChange,
}) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    const onHeaderCheckboxChange = (isHeaderCheckboxSelected) => {
        let updatedData = rowDataRef.current;
        if (gridRef && gridRef.current && gridRef.current.api) {
            // Select checkbox for filtered rows only
            gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
                // Invert the selection status for each row
                if (rowNode.level === 0) {
                    updatedData = rowDataRef.current.map(newUd => {
                        if (newUd.isReitActive && newUd.isReitAvailableForSelection) {
                            return {
                                ...newUd,
                                reitSelection: !isHeaderCheckboxSelected
                            };
                        }
                        return newUd;
                    });
                }
            });

            setRowData(updatedData);
            gridRef.current.api.refreshHeader();
        }
    };

    const onCellCheckboxChange = (reitId, rowNode) => {
        let updatedData = rowDataRef.current.map(newUd => {
            if (newUd.reitid === reitId) {
                return {
                    ...newUd,
                    reitSelection: !newUd.reitSelection
                };
            }
            return newUd;
        });

        setRowData(updatedData);        
        gridRef.current.api.refreshHeader();
    };

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            tooltipComponent: CustomReitToolTip,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    const getColumnDefs = () => {
        return [
            {
                field: 'reitSelection', headerName: "", suppressMenu: true,
                headerComponent: BulkRollForwardReitCheckboxHeaderRenderer,
                headerComponentParams: { onHeaderCheckboxChange: onHeaderCheckboxChange },
                cellRenderer: BulkRollForwardReitCheckboxCellRenderer,
                cellRendererParams: { onCellCheckboxChange: onCellCheckboxChange },
                minWidth: 70,
                spanHeaderHeight: true,
                resizable: true
            },
            {
                field: 'reit', headerName: 'REIT', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 500,
                valueGetter: params => {
                    return params.data && params.data.reitName;
                },
                filterValueGetter: params => { return params.data && params.data.reitName; },
                headerComponentParams: { template: CustomHeaderTemplate('reit') },
                resizable: true,
                cellRenderer: CustomReitToolTip,
            },
            {
                field: 'currentPeriod', headerName: 'Current Period', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 300,
                valueGetter: params => {
                    return params.data && params.data.currentPeriod;
                },
                filterValueGetter: params => { return params.data && params.data.currentPeriod; },
                headerComponentParams: { template: CustomHeaderTemplate('currentPeriod') },
                resizable: true
            },
            {
                field: 'rollForwardToPeriod', headerName: 'Roll Forward To', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 300,
                valueGetter: params => {
                    if (params.data && params.data.reitSelection) {
                        return params.data && params.data.rollForwardToPeriod;
                    }

                    return null;
                },
                filterValueGetter: params => {
                    if (params.data && params.data.reitSelection) {
                        return params.data && params.data.rollForwardToPeriod;
                    }

                    return null;
                },
                headerComponentParams: { template: CustomHeaderTemplate('rollForwardToPeriod') },
                resizable: true
            },
            {
                field: 'rollForwardStatus', headerName: 'Roll Forward Status', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 300,
                valueGetter: params => {
                    return params.data && params.data.bulkRollForwardStatusDescription;
                },
                filterValueGetter: params => { return params.data && params.data.bulkRollForwardStatusDescription; },
                headerComponentParams: { template: CustomHeaderTemplate('rollForwardStatus') },
                resizable: true
            },
        ];
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }

        // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
        if (type.indexOf("filterChanged") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }

        if (type.indexOf("rowDataUpdated") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }  
    }

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem('REITSuiteBulkRollForwardColumnState');
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }

        // Set the page size and total pages on the first load        
        handleItemsPerPageChange(100); // Setting it 100 to make sure the default number of items on load is always set to 100
    }

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        let filteredRowData = [];
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            if (currentPage > calcTotalPages) {
                if (calcTotalPages == 0) {
                    setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
                }
                else {
                    setCurrentPage(calcTotalPages);
                }
            }
            const gridCurrentPage = gridRef.current.api.paginationGetCurrentPage() + 1; // Adding 1 since it returns zero based index
            if (currentPage < (gridCurrentPage) && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }

            // Show the expected value as 1 for current page 
            if (currentPage <= 1 &&  gridCurrentPage == 1 && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }    
        }
    }

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {
        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem('REITSuiteBulkRollForwardColumnState');

        // If the column state details are not exists in local storage then save the changed Column state data
        if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
            saveUserPreferenceDetails();
        }
        else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (columnsStateArray && columnsStateArray.length > 0
                && currentColumnState && currentColumnState.length > 0
                && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {
        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem('REITSuiteBulkRollForwardColumnState', JSON.stringify(columnState));
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {
        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
    }, [rowData]);

    useEffect(() => {

        setRowData(bulkRollForwardData);

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.redrawRows();
            gridRef.current.api.refreshHeader();
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [bulkRollForwardData]);

    useEffect(() => {
        //Reset Grid to default column state
        //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
        var localStorageColumnState = localStorage.getItem('REITSuiteBulkRollForwardColumnState');
        if (!localStorageColumnState && gridRef && gridRef.current && gridRef.current.api) {

            gridRef.current.api.setColumnDefs([]);
            gridRef.current.api.setColumnDefs(getColumnDefs());
        }
    }, [gridColumnState]);

    const rowHeight = 76; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return (
        <>
            <div>
                <div className="row mt-5">
                    {bulkRollForwardData && bulkRollForwardData.length > 0 &&
                        <div className="col mt-3 bulk-processmanagement-grid">
                            <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                                <MotifTable zebra={true} compact={true}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    accentedSort={true}
                                    rowSelection="multiple"
                                    suppressRowClickSelection={true}
                                    onGridReady={handleGridReady}
                                    onFirstDataRendered={handleFirstDataRendered}
                                    ref={gridRef}
                                    pagination={true}
                                    suppressPaginationPanel={true}
                                    paginationPageSize="100"
                                    groupDisplayType={'custom'}
                                    groupSelectsChildren={true}
                                    groupDefaultExpanded={0}
                                    onFilterChanged={handleFilterChanged}
                                    data-testid="divReportManagementGrid"
                                />
                            </div>
                            <div className="mt-3">
                                <span className="right mb-3">
                                    <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
                                        <MotifPaginationSelect data-testid="itemsPerPage">
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(50)}>
                                                Show 50
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem selected onClick={() => handleItemsPerPageChange(100)}>
                                                Show 100
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(150)}>
                                                Show 150
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(200)}>
                                                Show 200
                                            </MotifPaginationSelectItem>
                                        </MotifPaginationSelect>
                                    </MotifPagination>
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default BulkRollForwardTable;