import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_SECURITIES_SNAPSHOTS_SUCCESS action and sets an array of REITs in the store
 * @param {Array} [state] The REITs currently in the store, or the initial state of REITs
 * @param {Object} action The action to be handled
 * @returns {Array} The REITs
 */
export default function securitiesSnapshotReducer(state = initialState.securitiesSnapshots, action) {
    switch (action.type) {
        case actions.LOAD_SECURITIES_SNAPSHOTS_SUCCESS:
            return action.securitiesSnapshots;
        case actions.CREATE_SECURITIES_SNAPSHOT_SUCCESS:
            return [
                ...state,
                Object.assign({}, action.securitiesSnapshot)
            ];
        case actions.UPDATE_SECURITIES_SNAPSHOT_SUCCESS:
            return [
                ...state.filter(securitiesSnapshot => securitiesSnapshot.id !== action.securitiesSnapshot.id),
                Object.assign({}, action.securitiesSnapshot)
            ];
        case actions.UNLOAD_TESTS:
            return initialState.securitiesSnapshots;
        default:
            return state;
    }
}