import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Outlet } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as reitActions from '../../actions/reitActions';

/**
 * ReitLoader functional component
 * @param {Object} props - The props passed to the component
 * @returns {JSX.Element} The JSX element representing the component
 */
function ReitLoader(props) {
    const { reitId } = useParams();

    useEffect(() => {
        props.fetchReit(reitId);
    }, []);

    return <div><Outlet /></div>;
}

ReitLoader.propTypes = {
    fetchReit: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchReit: bindActionCreators(reitActions.fetchReit, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(ReitLoader);
