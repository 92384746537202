import * as actions from '../actions/actionTypes';
import initialState from './initialState';

/**
 * This reducer handles the LOAD_CHART_MAPPINGS_SUCCESS action and sets a chart mappings in the store
 * @param {Object} [state] The chart mappings currently in the store, or the initial state of chart mappings
 * @param {Object} action The action to be handled
 * @returns {Object} The mappings
 */
export default function chartMappings(state = initialState.chartMappings, action) {
    switch (action.type) {
        case actions.LOAD_CHART_MAPPINGS_SUCCESS:
            return action.chartMappings || [];
        case actions.LOAD_CHART_MAPPINGS_FAILURE:
            return initialState.chartMappings;
        default:
            return state;
    }
}