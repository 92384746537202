import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_CHECKLIST_FILES_SUCCESS action and sets the ARQC checklist in the store
 * @param {Object} [state] The checklist files currently in the store, or the initial state of the checklist files
 * @param {Object} action The action to be handled
 * @returns {Array} The checklist files
 */
export default function checklistFilesReducer(state = initialState.checklistFiles, action) {
    if (action.type === actions.LOAD_CHECKLIST_FILES_SUCCESS) {
        return action.checklistFiles;
    } else {
        return state;
    }
}