import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { MotifFormField, MotifCheckbox } from "@ey-xd/motif-react";

const ScopedServicesRow = ({ service, selectedServices, handleServiceSelected }) => {
    let isSelected = false;
    let isDisabled = false;

    let getFriendlyName = (string) => {
        string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
        string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
        return string;
    };

    for (let i = 0; i < selectedServices.length; i++) {
        if (selectedServices[i] === service) {
            isSelected = true;

        }
    }
    
    return (
        <tr key={service.id}>
            <td className="services-td">
                <MotifFormField>
                    <MotifCheckbox
                        type="checkbox"
                        name={service}
                        id={service}
                        value={service}
                        checked={isSelected}
                        disabled={isDisabled}
                        onChange={() => handleServiceSelected(service, !isSelected)}>
                        {getFriendlyName(service)}
                    </MotifCheckbox>
                </MotifFormField>
            </td>
        </tr>
    );
};

ScopedServicesRow.propTypes = {
    service: PropTypes.string.isRequired,
    selectedServices: PropTypes.array,
    handleServiceSelected: PropTypes.func.isRequired
};

export default ScopedServicesRow;