import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import ErrorSummaryItem from './ErrorSummaryItem';
import { MotifToast } from '@ey-xd/motif-react';

const ReitSuiteUsersMessage = ({ errorResponse, handleDismiss, expireDate }) => {
    const expireDateObject = new Date(expireDate);
    const todaysDate = new Date();

    if (expireDateObject < todaysDate){
        return null;
    }

    return (
        <React.Fragment>
            <MotifToast variant="warning" onClose={handleDismiss}>
                <p style={{ fontWeight: "bold" }} className='mb-0 pb-0'>New Trial Balance and Account Mapping Import Template</p>
                <p>Please download the latest REITSuite Trial Balance Import template and Account Mapping Import template associated with your clients and REITs. Historical REITSuite import templates are no longer compatible.</p>
            </MotifToast>
        </React.Fragment>
    );
};

ReitSuiteUsersMessage.propTypes = {
    handleDismiss: PropTypes.func
};

export default ReitSuiteUsersMessage;