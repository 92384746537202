import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_SUCCESS action and sets last service provider question id in the store
 * @param {number} [state] The last service provider question id currently in the store, or the initial state of last service provider question id
 * @param {Object} action The action to be handled
 * @returns {number} The last service provider question id
 */
export default function lastServiceProviderQuestionIdReducer(state = initialState.lastServiceProviderQuestionId, action) {
    if (action.type == actions.LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_SUCCESS) {
        return action.lastServiceProviderQuestionId;
    } else if (action.type == actions.LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_FAILURE) {
        return initialState.lastServiceProviderQuestionId;
    } else {
        return state;
    }
}