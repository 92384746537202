import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import EditTrsTable from './EditTrsTable';
import EditTrsForm from './EditTrsForm';
import { MotifButton, MotifModal } from '@ey-xd/motif-react';

const EditTrsComponent = ({ taxableReitSubsidiaries, showForm, handleCloseForm, handleNewButtonClick, handleSubmit, formPurpose, selectedTrs, handleEditButtonClick, handleDeleteButtonClick, canEdit, handleDismissErrorMessage }) => {
    // Demonstrates how to set initial form values for use when editing
    const initialValuesTest = {
        name: ''
    };

    return (
        <div className="edit-trs-component">
            <div className="row">
                <div className="col-md-12 d-flex">
                    <div className="pl-0 col-md-10">
                        <h2>Taxable REIT Subsidiaries {canEdit ? "" : "- Testing Closed"}</h2>
                    </div>

                    <div className="pr-0 col-md-2 align-self-center">
                        <MotifButton variant="primary" className="right" type="button"
                            onClick={handleNewButtonClick}
                            disabled={!canEdit}>
                            New TRS
                        </MotifButton>
                    </div>
                </div>
            </div>
            <div>
                <EditTrsTable taxableReitSubsidiaries={taxableReitSubsidiaries} handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick} canEdit={canEdit} />
            </div>
            <MotifModal show={showForm} area-label="Add or Edit TRS" variant="alt" size="lg" onClose={handleCloseForm}>
                <EditTrsForm onSubmit={handleSubmit} handleCloseForm={handleCloseForm} initialValues={selectedTrs} formPurpose={formPurpose} handleDismissErrorMessage={handleDismissErrorMessage} />
            </MotifModal>
        </div>
    );
};

EditTrsComponent.propTypes = {
    showForm: PropTypes.bool.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    handleNewButtonClick: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    taxableReitSubsidiaries: PropTypes.arrayOf(PropTypes.shape({
        taxableREITSubsidiaryID: PropTypes.number,
        reportPeriodID: PropTypes.number,
        trsName: PropTypes.string,
        trsValue: PropTypes.number,
        trsDebt: PropTypes.number,
        trsEquity: PropTypes.number
    })),
    formPurpose: PropTypes.string,
    selectedTrs: PropTypes.object,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleDeleteButtonClick: PropTypes.func.isRequired,
    canEdit: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func
};

export default EditTrsComponent;