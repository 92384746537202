import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
import { MotifButton,MotifModal,MotifModalHeader,MotifModalBody,MotifModalFooter } from "@ey-xd/motif-react";


const ChangeDefaultChartOfAccountsModal = ({
    showChangeDefaultChartModal,
    handleChangeDefaultChart,
    toggleShowChangeDefaultChartModal    
}) => {
    return (
        <MotifModal aria-label="Set as Default for Client" show={showChangeDefaultChartModal} onClose={toggleShowChangeDefaultChartModal}>
        <MotifModalHeader>Set as Default for Client</MotifModalHeader>
        <MotifModalBody>
                <form>
                <h6>
                    Are you sure you want to change the default chart of accounts for the Client?
                </h6>
                </form>
        </MotifModalBody>
        <MotifModalFooter>
        <MotifButton variant="secondary" className="right" type="button"
                    onClick={toggleShowChangeDefaultChartModal}>
                    No
        </MotifButton>
        <MotifButton variant="primary" className="right ml-2" type="button"
                    onClick={handleChangeDefaultChart}>
                    Yes
        </MotifButton>                
        </MotifModalFooter>

        </MotifModal>           
            );
};

ChangeDefaultChartOfAccountsModal.propTypes = {
    showChangeDefaultChartModal: PropTypes.bool,
    handleChangeDefaultChart: PropTypes.func,
    toggleShowChangeDefaultChartModal: PropTypes.func
};

export default ChangeDefaultChartOfAccountsModal;